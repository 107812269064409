/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'error': {
    width: 16,
    height: 16,
    viewBox: '0 0 13 12.98',
    data: '<g data-name="Layer 2"><g data-name="Outline Icons"><path pid="0" d="M12.5 13H.5a.5.5 0 01-.45-.72l6-12a.52.52 0 01.89 0l6 12a.5.5 0 01-.45.72zM1.31 12h10.38L6.5 1.6z"/><path pid="1" d="M6.5 9.48A.5.5 0 016 9V5.48a.5.5 0 011 0V9a.5.5 0 01-.5.48zM6.5 10a.5.5 0 10.5.5.5.5 0 00-.5-.5z"/></g></g>'
  }
})
