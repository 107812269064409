import { JSONModel, toJSONContext } from '@/store/models/jsonModel';
import { createCounter } from '@/store/utils/counter';
import CountryRelationship, { CountryRelationshipJSON } from '@/store/modules/countries/country-relationship';

import { FAQStatus } from './types';

const availableStatuses = Object.keys(FAQStatus).map((key) => FAQStatus[key]);

const getLocalId = createCounter();
const localIdPrefix = 'local_faq_';

export interface FAQJSON {
  id: number;
  language_id: number;
  language_code: string;
  weight: number;
  status: number;
  is_featured: 1 | 0;
  visible: number;
  question: string;
  answer: string;
  created_at: string;
  updated_at: string;
  translations: {
    question: Record<string, string>;
    answer: Record<string, string>;
  };
  countries: CountryRelationshipJSON[];
}

export function isFAQLocalId(val: any): val is string {
  if (typeof val === 'string' && val.startsWith(localIdPrefix)) {
    return true;
  }

  return false;
}

export default class FAQ extends JSONModel<FAQJSON> {
  public id: string | number = 0;
  public languageId: number = 0;
  public languageCode: string = 'en';
  public weight: number = 0;
  public status: FAQStatus = FAQStatus.Active;
  public isFeatured: boolean = false;
  public visible: number = 1;
  public question: string = '';
  public answer: string = '';
  public createdAt: string = '';
  public updatedAt: string = '';
  public translations: {
    question: Record<string, string>;
    answer: Record<string, string>;
  } = { question: {}, answer: {} };
  public countries: CountryRelationship[] = [];

  public removeOnServer: boolean = false;

  protected _clientProperties = ['translations', 'removeOnServer'];

  constructor() {
    super();

    this.id = localIdPrefix + getLocalId();
  }

  get isLocal() {
    return isFAQLocalId(this.id);
  }

  public fromJSON(json: FAQJSON) {
    if (isFAQLocalId(json.id)) {
      this.id = json.id;
    } else {
      this.id = Number(json.id);
    }

    if (json.language_id) {
      this.languageId = Number(json.language_id);
    }

    if (json.language_code) {
      this.languageCode = String(json.language_code);
    }

    if (json.weight) {
      this.weight = Number(json.weight);
    }

    if (typeof json.status !== 'undefined' && availableStatuses.indexOf(json.status) !== -1) {
      this.status = json.status;
    }

    if (json.is_featured === 1) {
      this.isFeatured = true;
    }

    if (json.visible) {
      this.visible = Number(json.visible);
    }

    if (json.question) {
      this.question = String(json.question);
    }

    if (json.answer) {
      this.answer = String(json.answer);
    }

    if (json.created_at) {
      this.createdAt = String(json.created_at);
    }

    if (json.updated_at) {
      this.updatedAt = String(json.updated_at);
    }

    if (json.translations) {
      this.translations = json.translations;
    }

    if (json.countries) {
      this.countries = json.countries.map((item) => CountryRelationship.fromJSON(item));
    }
  }

  public toJSON(context: toJSONContext = 'server') {
    const { id, ...json } = super.toJSON(context);
    // tslint:disable-next-line: variable-name
    const is_featured: 1 | 0 = this.isFeatured ? 1 : 0;

    return { ...json, is_featured, id: context === 'server' ? (this.isLocal ? 0 : id) : id };
  }
}
