
import { PropOptions } from 'vue';
import mixins from 'vue-typed-mixins';

import CustomInputComponent from '@/components/mixins/custom-input-component';
import FloatingLabel from '@/components/controls/floating-label.vue';
import HighlightedTextarea from '@/components/controls/highlighted-textarea.vue';

// TODO: make label required.

export default mixins(CustomInputComponent).extend({
  name: 'FormInputControl',
  components: {
    FloatingLabel,
    HighlightedTextarea,
  },
  props: {
    iconName: {
      type: String,
      required: false,
    },
    errorLabel: {
      type: String,
      required: false,
    },
    type: {
      type: String,
      default: 'text',
      validator: (type: string) => {
        return ['textarea', 'text', 'url', 'email', 'password', 'search'].indexOf(type) !== -1;
      },
    },
    extraClasses: {
      type: Array,
      default: () => [],
    } as PropOptions<string[]>,
    readOnly: {
      type: Boolean,
      default: false,
    },
    maxLength: {
      type: Number,
      default: -1,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    highlight: {
      type: Array,
      default: () => [],
    } as PropOptions<string[]>,
    lang: {
      type: String,
    },
    autocomplete: {
      type: String,
    },
    autoFocus: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      focused: false,
    };
  },
  computed: {
    classes() {
      return [
        'c-form-element',
        { 'c-icon-wrapper--has-splitter': this.hasIcon },
        { 'c-form-element__error': this.errors.has(this.name) },
      ].concat(this.extraClasses);
    },
    inputSwitch(): string {
      if (this.type === 'textarea') {
        if (this.highlight && this.highlight.length) {
          return 'highlighted-textarea';
        }

        return 'textarea';
      }

      return 'input';
    },
    hasIcon(): boolean {
      return this.iconName ? true : false;
    },
    charsLeft(): number {
      if (!this.value || !this.value.length) {
        return this.maxLength;
      }

      return this.maxLength - this.value.length;
    },
    showMaxLengthMsg(): boolean {
      return this.maxLength > -1 && this.charsLeft <= 20;
    },
    charsLeftCSSClass(): string {
      return this.charsLeft < 0 ? 'c-form-element__error-list' : 'c-form-element__info-list';
    },
    fieldError() {
      return this.errors.items.find((item) => item.field === this.name);
    },
    fieldErrorRule(): string {
      if (!this.fieldError || !this.fieldError.rule) {
        return '';
      }

      return this.fieldError.rule;
    },
    fieldErrorMsg(): string {
      if (!this.fieldError || !this.fieldError.msg) {
        return '';
      }

      if (this.errorLabel) {
        return this.fieldError.msg.replace(this.name, this.errorLabel);
      } else if (this.label) {
        return this.fieldError.msg.replace(this.name, this.label);
      } else {
        return this.fieldError.msg;
      }
    },
    showFieldError(): boolean {
      return this.fieldError && (this.fieldErrorRule !== 'max' || !this.showMaxLengthMsg);
    },
    isEmpty() {
      return !this.value || !this.value.length;
    },
    isActive() {
      return !this.isEmpty || this.type === 'date';
    },
  },
  methods: {
    onBlur() {
      this.$emit('blur');
      this.focused = false;
    },
    onFocus() {
      this.$emit('focus');
      this.focused = true;
    },
  },
  mounted() {
    if (this.$refs.inputEl && this.$refs.inputEl.focus && this.autoFocus) {
      this.$refs.inputEl.focus();
    }
  },
});
