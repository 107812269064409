
import Vue from 'vue';
import { mapState, mapGetters, mapActions } from 'vuex';
import mixins from 'vue-typed-mixins';

import tableViewMixin from '@/components/mixins/table-view';
import CheckboxControl from '@/components/controls/checkbox.vue';
import NotificationBanner from '@/components/blocks/notification-banner.vue';
import CountryName from '@/components/blocks/country-name.vue';
import TableListSkeleton from '@/components/skeletons/table-list.vue';
import Dropdown, { DropdownItems } from '@/components/controls/dropdown.vue';
import PaginationControl from '@/components/controls/pagination.vue';
import PracticesFilter from '@/components/forms/practices-filter.vue';

import { formatNumber } from '@/i18n';

import Practice from '@/store/modules/practices/practice';
import { PracticesActions, PracticeStatus } from '@/store/modules/practices/types';

export default mixins(tableViewMixin).extend({
  name: 'PracticesView',
  components: {
    NotificationBanner,
    TableListSkeleton,
    Dropdown,
    PaginationControl,
    PracticesFilter,
    CountryName,
    CheckboxControl,
  },
  data() {
    return {
      selectedItems: [] as number[],
    };
  },
  computed: {
    ...mapGetters('user/profile', ['isSuperAdmin', 'isAdmin']),
    ...mapState('practices', {
      practices: 'list',
      pagination: 'listPagination',
    }),
    ...mapGetters('practices', {
      isLoading: 'listIsLoading',
      isLoaded: 'listIsLoaded',
      isEmpty: 'listIsEmpty',
    }),
    areAllItemsSelected(): boolean {
      return this.selectedItems.length === this.practices.length;
    },
  },
  methods: {
    formatNumber,
    ...mapActions('practices', {
      removeAction: PracticesActions.DELETE,
      restoreAction: PracticesActions.RESTORE,
    }),
    isPublished(practice: Practice) {
      return practice.status === PracticeStatus.Published;
    },
    dropdownItems(practice: Practice) {
      const items: DropdownItems = {
        edit: {
          label: this.$t('Edit'),
          icon: 'pencil',
        },
      };

      if (practice.status === PracticeStatus.Inactive) {
        items.restore = {
          label: this.$t('Restore'),
          icon: 'restore',
        };
      } else {
        items.remove = {
          label: this.$t('Delete'),
          hasTopBorder: true,
          icon: 'trash',
        };
      }

      return items;
    },

    handleClick(practice: Practice, action: string) {
      if ('edit' === action) {
        this.$router.push({
          name: 'edit-practice',
          params: { id: String(practice.id) },
        });
      }

      if ('restore' === action) {
        const answer = window.confirm(this.$t('Do you really want to restore this practice?'));

        if (answer) {
          this.restoreAction({ id: practice.id });
        }
      }

      if ('remove' === action) {
        const answer = window.confirm(this.$t('Do you really want to delete this practice?'));

        if (answer) {
          this.removeAction({ id: practice.id });
        }
      }
    },

    isSelected(practice: Practice): boolean {
      return this.selectedItems.indexOf(practice.id) !== -1;
    },

    selectItem(practice: Practice) {
      if (this.isSelected(practice)) {
        this.selectedItems = this.selectedItems.filter((item) => item !== practice.id);
        return;
      }

      this.selectedItems.push(practice.id);
    },

    selectAll() {
      if (this.areAllItemsSelected) {
        this.selectedItems = [];
      } else {
        this.selectedItems = this.practices.map((practice: Practice) => practice.id);
      }
    },
  },
});
