import { GetterTree } from 'vuex';
import { RootState, ListStatus } from '@/store/types';
import { SupportState } from '@/store/modules/support/types';
import { createEditableGetters } from '@/store/utils/editable';

const editableGetters = createEditableGetters<SupportState>('faqEditableStatus', 'faqEditable', 'initialFAQEditable');

export const moduleGetters: GetterTree<SupportState, RootState> = {
  faqFeaturedList: (state) => state.faqList.filter((item) => item.isFeatured),

  /**
   * Edit
   */
  faqEditableIsLoading: editableGetters.isLoading,
  faqEditableIsLoaded: editableGetters.isLoaded,
  faqEditableIsSaving: editableGetters.isSaving,
  faqEditableIsSaved: editableGetters.isSaved,
  faqEditableIsFailed: editableGetters.isFailed,
  faqEditableHasUnsavedChanges: editableGetters.hasUnsavedChanges,
};

export default moduleGetters;
