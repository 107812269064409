/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'chevron': {
    width: 14,
    height: 8,
    viewBox: '0 0 14 8',
    data: '<path pid="0" d="M7 0a1 1 0 01.71.29l6 6a1 1 0 01-1.42 1.42L7 2.41l-5.29 5.3A1 1 0 01.29 6.29l6-6A1 1 0 017 0z"/>'
  }
})
