
import Vue from 'vue';
import { mapState, mapGetters, mapActions } from 'vuex';

import NotificationBanner from '@/components/blocks/notification-banner.vue';
import CountryName from '@/components/blocks/country-name.vue';
import SubmitButton from '@/components/controls/submit-button.vue';
import { PrivacyRequestActions } from '@/store/modules/privacy_requests/types';

export default Vue.extend({
  name: 'ViewPrivacyRequestView',
  components: {
    NotificationBanner,
    CountryName,
    SubmitButton,
  },
  computed: {
    ...mapState('privacy_requests', {
      privacyRequest: 'editable',
    }),
    ...mapGetters('privacy_requests', ['editableIsLoaded', 'editableIsSaved', 'editableIsSaving', 'editableIsError']),
    isFullyLoaded(): boolean {
      return this.editableIsLoaded || this.editableIsError || this.editableIsSaved || this.editableIsSaving;
    },
    statusLabel() {
      return this.privacyRequest && this.privacyRequest.status ? this.$t('Pending') : this.$t('Closed');
    },
  },
  methods: {
    ...mapActions('privacy_requests', {
      closeAction: PrivacyRequestActions.DELETE,
    }),
    close() {
      this.closeAction({ id: this.privacyRequest.id });
    },
    download() {
      if (this.isFullyLoaded && this.privacyRequest) {
        window.open(this.privacyRequest.downloadUrl, '_blank');
      }
    },
  },
});
