var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('div',{staticClass:"c-main"},[_c('div',{staticClass:"o-container-fluid"},[_c('div',{staticClass:"c-page-headline"},[_c('div',{staticClass:"c-breadcrumbs c-breadcrumbs--big"},[_c('h3',[_c('router-link',{attrs:{"to":{ name: 'practices' }}},[_vm._v(_vm._s(_vm.$t('Practices')))]),_c('svgicon',{staticClass:"c-icon--margin-right o-svg-right",attrs:{"name":"chevron"}}),_vm._v(" "+_vm._s(_vm.practice.title)+" "),(!_vm.isPublished(_vm.practice))?_c('span',{staticClass:"c-tag c-tag--white"},[_c('span',{staticClass:"c-entry__dot c-entry__dot--error"}),_vm._v(_vm._s(_vm.$t('Deleted')))]):_vm._e()],1),_c('router-link',{staticClass:"c-button c-button--primary",attrs:{"to":{ name: 'edit-practice' }}},[_vm._v(_vm._s(_vm.$t('Edit Practice')))])],1)]),_c('hr',{staticClass:"c-divider"}),_c('notification-banner'),_c('h3',[_vm._v(_vm._s(_vm.$t('Overview')))]),_c('div',{staticClass:"o-row"},[_c('div',{staticClass:"o-col-7@md"},[_c('div',{staticClass:"c-tile"},[_c('div',{staticClass:"c-tile__title"},[_c('router-link',{attrs:{"to":{
                  name: 'locations',
                  query: { practice_id: _vm.practice.id },
                }}},[_c('svgicon',{attrs:{"name":"location"}}),_c('h6',[_vm._v(" "+_vm._s(_vm.$t('Locations'))+" "),_c('span',{staticClass:"u-text-light"},[_vm._v("("+_vm._s(_vm.practice.locationsCount)+")")])])],1),_c('router-link',{staticClass:"c-plus-mini",attrs:{"to":{
                  name: 'add-location',
                  query: { practice_id: _vm.practice.id },
                },"title":_vm.$t('Add Location')}},[_vm._v(" + ")])],1),_c('hr',{staticClass:"c-divider--full-width"}),(_vm.locationsIsLoading)?_c('div',[_vm._v(" "+_vm._s(_vm.$t('Loading...'))+" ")]):(_vm.locationsIsEmpty)?_c('div',[_c('h5',{staticClass:"u-text-center"},[_vm._v(_vm._s(_vm.$t('No locations added.')))])]):(_vm.locationsIsLoaded)?_c('ul',{staticClass:"c-tile__list"},[_vm._l((_vm.locations.slice(0, 2)),function(location){return _c('li',{key:location.id,staticClass:"c-location o-row"},[_c('div',{staticClass:"o-col-4"},[_c('router-link',{attrs:{"to":{
                      name: 'edit-location',
                      params: { id: location.id },
                    }}},[_c('div',{staticClass:"c-location__image-wrapper"},[(location.mapUrl)?_c('img',{attrs:{"src":location.mapUrl + '?fit=crop&crop=focalpoint&w=300&h=200&fp-x=0.5&fp-y=0.5&fp-z=2'}}):_c('img',{attrs:{"src":require("@/assets/images/location-empty.png"),"alt":_vm.$t('No Location')}}),_c('ul',{staticClass:"c-user__avatar-list"},[_vm._l((location.practitioners.slice(0, 3)),function(practitioner){return _c('li',{key:practitioner.id,staticClass:"c-user__avatar"},[_c('figure',{staticClass:"c-user__avatar"},[_c('img',{attrs:{"src":practitioner.avatar,"title":practitioner.title}})])])}),(location.practitioners && location.practitioners.length > 3)?_c('li',{key:"additional",staticClass:"c-user__avatar c-user__avatar-number"},[_vm._v(" +"+_vm._s(location.practitioners.length - 3)+" ")]):_vm._e()],2)])])],1),_c('div',{staticClass:"o-col-8"},[_c('h5',[_c('router-link',{attrs:{"to":{
                        name: 'edit-location',
                        params: { id: location.id },
                      }}},[_c('a',[_vm._v(_vm._s(location.title))])])],1),_c('publish-status-block',{attrs:{"tag":"small","status":location.status,"practice":location.practice}}),(location.excerpt)?_c('p',[_vm._v(_vm._s(location.excerpt))]):_c('em',[_vm._v(" "+_vm._s(_vm.$t('No location description')))])],1)])}),(_vm.practice.locationsCount > 2)?_c('li',[_c('small',[_c('router-link',{attrs:{"to":{
                      name: 'locations',
                      query: { practice_id: _vm.practice.id },
                    }}},[_vm._v(" "+_vm._s(_vm.$t('View all'))+" ")])],1)]):_vm._e()],2):_vm._e()]),_c('div',{staticClass:"c-tile"},[_c('div',{staticClass:"c-tile__title"},[_c('a',{attrs:{"href":"#"}},[_c('svgicon',{attrs:{"name":"products"}}),_c('h6',[_vm._v(" "+_vm._s(_vm.$t('Products'))+" "),_c('span',{staticClass:"u-text-light"},[_vm._v("("+_vm._s(_vm.practice.productsCount)+")")])])],1)]),_c('hr',{staticClass:"c-divider--full-width"}),(_vm.practice.productsCount < 1)?_c('div',[_c('h5',{staticClass:"u-text-center"},[_vm._v(_vm._s(_vm.$t('No products added.')))])]):_c('ul',{staticClass:"o-row c-tile__list"},_vm._l((_vm.practice.products.slice(0, 21)),function(product){return _c('li',{key:product.id,staticClass:"o-col-4@md u-mb-0"},[_c('h6',{staticClass:"u-mb-10"},[_vm._v(_vm._s(product.title))])])}),0),_c('ul',[(_vm.practice.productsCount > 21)?_c('li',[_c('small',[_c('router-link',{attrs:{"to":{ name: 'edit-practice' }}},[_vm._v(" "+_vm._s(_vm.$t('View all'))+" ")])],1)]):_vm._e()])])]),_c('div',{staticClass:"o-col-5@md"},[(_vm.isSuperAdmin)?_c('div',{staticClass:"c-tile"},[_c('div',{staticClass:"c-tile__title"},[_c('router-link',{attrs:{"to":{ name: 'users', query: { practice_id: _vm.practice.id } }}},[_c('svgicon',{attrs:{"name":"user-admin"}}),_c('h6',[_vm._v(" "+_vm._s(_vm.$t('Users'))+" "),_c('span',{staticClass:"u-text-light"},[_vm._v("("+_vm._s(_vm.practice.usersCount)+")")])])],1),_c('router-link',{staticClass:"c-plus-mini",attrs:{"to":{
                  name: 'add-user',
                  query: { practice_id: _vm.practice.id },
                },"title":_vm.$t('Add User')}},[_vm._v(" + ")])],1),_c('hr',{staticClass:"c-divider--full-width"}),(_vm.practice.usersCount < 1)?_c('div',[_c('h5',{staticClass:"u-text-center"},[_vm._v(_vm._s(_vm.$t('No users added.')))])]):_c('ul',{staticClass:"c-tile__list"},[_vm._l((_vm.practice.users.slice(0, 3)),function(user){return _c('li',{key:user.id},[_c('div',{staticClass:"o-row"},[_c('div',{staticClass:"o-col-9"},[_c('router-link',{attrs:{"to":{ name: 'edit-user', params: { id: user.id } }}},[_c('span',{staticClass:"c-user__avatar c-user__avatar--standard u-float-left"},[_c('img',{attrs:{"src":user.avatar}})])]),_c('router-link',{attrs:{"to":{ name: 'edit-user', params: { id: user.id } }}},[_c('a',[_vm._v(_vm._s(user.title))])]),_c('a',{attrs:{"href":'mailto:' + user.email}},[_c('small',{staticClass:"u-mb-0"},[_c('svgicon',{staticClass:"c-icon--margin-right",attrs:{"name":"mail"}}),_vm._v(" "+_vm._s(user.email)+" ")],1)])],1),_c('div',{staticClass:"o-col-3"},[_c('small',{staticClass:"u-text-right u-mb-0"},[_c('router-link',{attrs:{"to":{ name: 'edit-user', params: { id: user.id } }}},[_c('a',{staticClass:"u-text-light"},[_vm._v(_vm._s(_vm.$t('Quick Edit')))])])],1)])])])}),(_vm.practice.usersCount > 2)?_c('li',[_c('small',[_c('router-link',{attrs:{"to":{
                      name: 'users',
                      query: { practice_id: _vm.practice.id },
                    }}},[_vm._v(" "+_vm._s(_vm.$t('View all'))+" ")])],1)]):_vm._e()],2)]):_vm._e(),_c('div',{staticClass:"c-tile"},[_c('div',{staticClass:"c-tile__title"},[_c('router-link',{attrs:{"to":{
                  name: 'practitioners',
                  query: { practice_id: _vm.practice.id },
                }}},[_c('svgicon',{attrs:{"name":"practitioner"}}),_c('h6',[_vm._v(" "+_vm._s(_vm.$t('Practitioners'))+" "),_c('span',{staticClass:"u-text-light"},[_vm._v("("+_vm._s(_vm.practice.practitionersCount)+")")])])],1),_c('router-link',{staticClass:"c-plus-mini",attrs:{"to":{
                  name: 'add-practitioner',
                  query: { practice_id: _vm.practice.id },
                },"title":_vm.$t('Add Practitioner')}},[_vm._v(" + ")])],1),_c('hr',{staticClass:"c-divider--full-width"}),(_vm.practice.practitionersCount < 1)?_c('div',[_c('h5',{staticClass:"u-text-center"},[_vm._v(_vm._s(_vm.$t('No practitioners added.')))])]):_c('ul',{staticClass:"c-tile__list"},[_vm._l((_vm.practice.practitioners.slice(0, 3)),function(practitioner){return _c('li',{key:practitioner.id},[_c('div',{staticClass:"o-row"},[_c('div',{staticClass:"o-col-9"},[_c('router-link',{attrs:{"to":{
                        name: 'edit-practitioner',
                        params: { id: practitioner.id },
                      }}},[_c('span',{staticClass:"c-user__avatar c-user__avatar--standard u-float-left"},[_c('img',{attrs:{"src":practitioner.avatar}})])]),_c('router-link',{attrs:{"to":{
                        name: 'edit-practitioner',
                        params: { id: practitioner.id },
                      }}},[_c('a',[_vm._v(_vm._s(practitioner.title))])]),_c('small',{staticClass:"u-mb-0"},[_vm._v(_vm._s(practitioner.specialty))])],1),_c('div',{staticClass:"o-col-3"},[_c('small',{staticClass:"u-text-right"},[_c('router-link',{attrs:{"to":{
                          name: 'edit-practitioner',
                          params: { id: practitioner.id },
                        }}},[_c('a',{staticClass:"u-text-light"},[_vm._v(_vm._s(_vm.$t('Quick Edit')))])])],1)])])])}),(_vm.practice.practitionersCount > 2)?_c('li',[_c('small',[_c('router-link',{attrs:{"to":{
                      name: 'practitioners',
                      query: { practice_id: _vm.practice.id },
                    }}},[_vm._v(" "+_vm._s(_vm.$t('View all'))+" ")])],1)]):_vm._e()],2)])])])],1)]),_c('router-view')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }