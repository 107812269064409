import { JSONModel } from '@/store/models/jsonModel';

export interface ConsultationStatsCalls {
  total: number;
  month: number;
  daily: {
    calls: number;
    date: string;
  }[];
}

export interface ConsultationStatsTotals {
  week: number;
  month: number;
  year: number;
}

export interface ConsultationStatsJSON {
  calls: ConsultationStatsCalls;
  totals: ConsultationStatsTotals;
}

export default class ConsultationStats extends JSONModel<ConsultationStatsJSON> {
  public calls: ConsultationStatsCalls = {
    total: 0,
    month: 0,
    daily: [],
  };
  public totals: ConsultationStatsTotals = {
    week: 0,
    month: 0,
    year: 0,
  };

  public fromJSON(json: Partial<ConsultationStatsJSON>) {
    if (json.calls) {
      this.calls.total = Number(json.calls.total);
      this.calls.month = Number(json.calls.month);

      if (Array.isArray(json.calls.daily) && json.calls.daily.length) {
        this.calls.daily = json.calls.daily.map(({ calls, date }) => ({
          calls: Number(calls),
          date: String(date),
        }));
      }
    }

    if (json.totals) {
      this.totals.week = Number(json.totals.week);
      this.totals.month = Number(json.totals.month);
      this.totals.year = Number(json.totals.year);
    }
  }
}
