
import Vue from 'vue';
import { mapState, mapActions, mapGetters } from 'vuex';

import { LocationsActions } from '@/store/modules/locations/types';
import { TreatmentActions } from '@/store/modules/treatments/types';
import { PractitionersActions } from '@/store/modules/practitioners/types';

import FormInput from '@/components/controls/form-input.vue';
import CountrySelection from '@/components/controls/country-selection.vue';
import ProvinceSelection from '@/components/controls/province-selection.vue';
import ImageUploadControl from '@/components/controls/image-upload.vue';
import FormInputArray from '@/components/controls/form-input-array.vue';
import FormTreatmentSelectionControl from '@/components/controls/form-treatment-selection.vue';
import PracticeSelectionControl from '@/components/controls/practice-selection.vue';
import TreatmentEnhancementControl from '@/components/controls/treatment-enhancements.vue';
import { PracticesActions } from '@/store/modules/practices/types';
import PractitionerRelationship from '@/store/modules/practitioners/practitioner-relationship';
import { createCounter } from '@/store/utils/counter';

const getWebsiteLocalId = createCounter();
const getConsultationEmailsLocalId = createCounter();

export default Vue.extend({
  name: 'LocationForm',
  inject: {
    $validator: '$validator',
  },
  components: {
    FormInput,
    CountrySelection,
    ProvinceSelection,
    FormInputArray,
    FormTreatmentSelectionControl,
    PracticeSelectionControl,
    TreatmentEnhancementControl,
    ImageUploadControl,
  },
  props: {
    add: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      focusWebsiteItem: -1,
      focusConsultationEmailsItem: -1,
    };
  },
  computed: {
    ...mapState('settings', ['settings']),
    ...mapState('locations', {
      availableCountries: 'availableCountries',
      availableProvinces: 'availableProvinces',
      location: 'editable',
      hasProvinces: 'hasProvinces',
    }),
    ...mapState('treatments', ['treatmentsTree']),
    ...mapState('practitioners', {
      practitioners: 'practitionersHoursList',
    }),
    ...mapGetters('practitioners', {
      practitionersIsLoaded: 'practitionersHoursListIsLoaded',
    }),
    ...mapGetters('treatments', {
      treatmentsIsLoading: 'treatmentsTreeIsLoading',
      treatmentsIsLoaded: 'treatmentsTreeIsLoaded',
      treatmentsIsEmpty: 'treatmentsTreeIsEmpty',
    }),
    ...mapGetters('user/profile', ['isSuperAdmin', 'isAdmin']),
    hasPracticeAssigned() {
      return this.location.practiceId && this.location.practiceId !== 0 ? true : false;
    },
    ownedTreatments() {
      if (!this.hasPracticeAssigned || this.treatmentsIsEmpty) {
        return [];
      }

      return this.treatmentsTree.filter((product) => product.owned === true);
    },
    locationWebsites() {
      return this.location.website && this.location.website.length > 0
        ? this.location.website.map((item) => {
            if (typeof item.id === 'undefined') {
              return { ...item, id: getWebsiteLocalId() };
            }
            return item;
          })
        : [{ id: getWebsiteLocalId(), label: '', link: '' }];
    },

    consultationEmails() {
      return this.location.consultationEmails && this.location.consultationEmails.length > 0
        ? this.location.consultationEmails.map((item) => {
            if (typeof item.id === 'undefined') {
              return { ...item, id: getConsultationEmailsLocalId() };
            }
            return item;
          })
        : [{ id: getConsultationEmailsLocalId(), email: ''}];
    },
  },
  methods: {
    ...mapActions('locations', {
      updateLocation: LocationsActions.UPDATE_EDITABLE,
    }),
    ...mapActions('treatments', {
      loadTreatments: TreatmentActions.LOAD_TREE,
    }),
    ...mapActions('practices', {
      loadPractice: PracticesActions.LOAD_PRACTICE,
    }),
    ...mapActions('practitioners', {
      loadPractitioners: PractitionersActions.LOAD_PRACTITIONERS_HOURS_LIST,
    }),
    handlePracticeChange(practiceId: number) {
      this.updateLocation({
        // Reset practitioner and treatments data
        practitioners: [],
        treatments: [],

        // Set the new practice id
        practiceId,
      });

      this.loadPractice({ id: practiceId });
      this.loadTreatments({ practice_id: practiceId });
      this.updatePractitioners(practiceId);
    },
    updatePractitioners(practiceId: number) {
      this.loadPractitioners({ practiceId, locationId: this.location.id }).then(() => {
        if (this.practitionersIsLoaded) {
          this.updateLocation({
            practitioners: this.practitioners.map((item) => PractitionerRelationship.clone(item)),
          });
        }
      });
    },
    handleCountryChange(countryId) {
      this.updateLocation({
        countryId,
        state: '',
      });
    },
    removeWebsiteItem(id: number) {
      this.updateLocation({
        website: this.locationWebsites.filter((item) => item.id !== id),
      });
    },
    addWebsiteItem() {
      const newItem = { id: getWebsiteLocalId(), label: '', link: '' };
      const website = [...this.locationWebsites, newItem];

      this.focusWebsiteItem = newItem.id;
      this.updateLocation({
        website,
      });
    },
    changeWebsiteItem(id: number, value) {
      const website = [...(this.locationWebsites as { id: number }[])];
      const index = website.findIndex((item) => item.id === id);

      if (index !== -1) {
        website[index] = { ...website[index], ...value };

        this.updateLocation({
          website,
        });
      }
    },

    removeConsultationEmailsItem(id: number) {
      this.updateLocation({
        consultationEmails: this.consultationEmails.filter((item) => item.id !== id),
      });
    },
    addConsultationEmailsItem() {
      const newItem = { id: getConsultationEmailsLocalId(), email: '' };
      const consultationEmails = [...this.consultationEmails, newItem];

      this.focusConsultationEmailsItem = newItem.id;
      this.updateLocation({
        consultationEmails,
      });
    },
    changeConsultationEmailsItem(id: number, value) {
      const consultationEmails = [...(this.consultationEmails as { id: number }[])];
      const index = consultationEmails.findIndex((item) => item.id === id);

      if (index !== -1) {
        consultationEmails[index] = { ...consultationEmails[index], ...value };

        this.updateLocation({
          consultationEmails,
        });
      }
    },
  },
  created() {
    // Update practitioners only when adding a new location otherwise
    // use practitioners returned from get location endpoint
    if (this.location.practiceId && this.add) {
      this.updatePractitioners(this.location.practiceId);
    }
  },
});
