import { ActionsTree, ListStatus, ErrorMessages, RootActions } from '@/store/types';
import { BlogState, BlogActions, BlogMutations } from './types';
import blogAPI from '@/store/utils/blog-api';
import Pagination from '@/store/models/pagination';
import BlogPost from './post';
import BlogTaxonomy from './taxonomy';
import BlogAuthor from './author';
import { enhanceErrorWithMessage } from '@/utils/errors';

export const actions: ActionsTree<BlogState, typeof BlogActions> = {
  [BlogActions.LOAD_LIST]({ commit, dispatch }, { page = 1, category, author } = {}): Promise<void> {
    commit(BlogMutations.SET_STATUS, ListStatus.Loading);

    return blogAPI
      .get('entries', { page, category, author })
      .then((response) => {
        const headerTotal = Number(response.headers['x-wp-total']);
        const lastPage = Number(response.headers['x-wp-totalpages']);
        const total = Number.isFinite(headerTotal) ? headerTotal : 0;

        commit(
          BlogMutations.SET_LIST_PAGINATION,
          Pagination.fromJSON({
            current_page: page,
            last_page: Number.isFinite(lastPage) ? lastPage : 1,
            total,
          }),
        );
        commit(
          BlogMutations.SET_LIST,
          response.data.map((item) => BlogPost.fromJSON(item)),
        );
        commit(BlogMutations.SET_STATUS, ListStatus.Loaded);
      })
      .catch((error) => {
        enhanceErrorWithMessage(error, ErrorMessages.DATA_LOAD_ERROR);

        commit(BlogMutations.SET_STATUS, ListStatus.Failed);
        return dispatch(RootActions.ERROR, error, { root: true });
      });
  },

  [BlogActions.LOAD_CATEGORIES]({ commit, dispatch }): Promise<void> {
    commit(BlogMutations.SET_CATEGORIES_STATUS, ListStatus.Loading);

    return blogAPI
      .get('categories', { per_page: 50 })
      .then((response) => {
        commit(
          BlogMutations.SET_CATEGORIES,
          response.data.map((item) => BlogTaxonomy.fromJSON(item)),
        );
        commit(BlogMutations.SET_CATEGORIES_STATUS, ListStatus.Loaded);
      })
      .catch((error) => {
        enhanceErrorWithMessage(error, ErrorMessages.DATA_LOAD_ERROR);

        commit(BlogMutations.SET_CATEGORIES_STATUS, ListStatus.Failed);
        return dispatch(RootActions.ERROR, error, { root: true });
      });
  },

  [BlogActions.LOAD_AUTHOR]({ commit, dispatch }, { author = '' } = {}): Promise<void> {
    commit(BlogMutations.SET_AUTHOR_STATUS, ListStatus.Loading);

    return blogAPI
      .get(`author/${author}`)
      .then((response) => {
        commit(BlogMutations.SET_AUTHOR, BlogAuthor.fromJSON(response.data));
        commit(BlogMutations.SET_AUTHOR_STATUS, ListStatus.Loaded);
      })
      .catch((error) => {
        enhanceErrorWithMessage(error, ErrorMessages.DATA_LOAD_ERROR);

        commit(BlogMutations.SET_AUTHOR_STATUS, ListStatus.Failed);
        return dispatch(RootActions.ERROR, error, { root: true });
      });
  },

  [BlogActions.LOAD_POST]({ commit, dispatch }, { slug = '' } = {}): Promise<void> {
    commit(BlogMutations.SET_POST_STATUS, ListStatus.Loading);

    return blogAPI
      .get(`entry/${slug}`)
      .then((response) => {
        commit(BlogMutations.SET_POST, BlogPost.fromJSON(response.data));
        commit(BlogMutations.SET_POST_STATUS, ListStatus.Loaded);
      })
      .catch((error) => {
        enhanceErrorWithMessage(error, ErrorMessages.DATA_LOAD_ERROR);

        commit(BlogMutations.SET_POST_STATUS, ListStatus.Failed);
        return dispatch(RootActions.ERROR, error, { root: true });
      });
  },
};

export default actions;
