
import Vue from 'vue';
import { mapState, mapGetters } from 'vuex';

import Practice from '@/store/modules/practices/practice';
import { PracticeStatus } from '@/store/modules/practices/types';
import { PublishStatus } from '@/store/types';

import NotificationBanner from '@/components/blocks/notification-banner.vue';
import PublishStatusBlock from '@/components/blocks/publish-status.vue';

export default Vue.extend({
  name: 'PracticeView',
  components: {
    NotificationBanner,
    PublishStatusBlock,
  },
  computed: {
    ...mapGetters('user/profile', ['isSuperAdmin']),
    ...mapState('practices', {
      practice: 'currentPractice',
    }),
    ...mapState('locations', {
      locations: 'locationsList',
    }),
    ...mapGetters('locations', {
      locationsIsLoading: 'listIsLoading',
      locationsIsLoaded: 'listIsLoaded',
      locationsIsEmpty: 'listIsEmpty',
    }),
  },
  methods: {
    isPublished(practice: Practice) {
      return practice.status === PracticeStatus.Published;
    },
  },
});
