
import Vue from 'vue';
import moment, { Moment } from 'moment';

export default Vue.extend({
  name: 'TimeAgoBlock',
  props: {
    prefix: {
      type: String,
      default: '',
    },
    suffix: {
      type: String,
      default: '',
    },
    time: {
      type: Object,
      default: () => moment(),
    },
  },
  data() {
    return {
      timeAgo: (this.time as Moment).fromNow(),
      intervalId: undefined,
    };
  },
  computed: {
    displayTime() {
      return `${this.prefix}${this.timeAgo}${this.suffix}`;
    },
  },
  methods: {
    updateTime() {
      this.timeAgo = (this.time as Moment).fromNow();
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.updateTime();

      this.intervalId = setInterval(this.updateTime.bind(this), 60 * 1000);
    });
  },
  destroyed() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
      this.intervalId = undefined;
    }
  },
});
