import { ListStatus } from '@/store/types';
import Pagination from '@/store/models/pagination';
import Download from './download';
import Login from './login';
import User from '@/store/models/user';
import Training from './training';

export enum StatisticsActions {
  /**
   * Downloads
   */
  LOAD_DOWNLOADS = 'LOAD_DOWNLOADS',

  /**
   * Logins
   */
  LOAD_LOGINS = 'LOAD_LOGINS',

  /**
   * Users
   */
  LOAD_USERS = 'LOAD_USERS',

  /**
   * Downloads
   */
  LOAD_TRAININGS = 'LOAD_TRAININGS',
}

export enum StatisticsMutations {
  /**
   * Downloads
   */
  SET_DOWNLOADS = 'SET_DOWNLOADS',
  SET_DOWNLOADS_PAGINATION = 'SET_DOWNLOADS_PAGINATION',
  SET_DOWNLOADS_STATUS = 'SET_DOWNLOADS_STATUS',
  SET_TOTAL_DOWNLOADS = 'SET_TOTAL_DOWNLOADS',

  /**
   * Logins
   */
  SET_LOGINS = 'SET_LOGINS',
  SET_LOGINS_PAGINATION = 'SET_LOGINS_PAGINATION',
  SET_LOGINS_STATUS = 'SET_LOGINS_STATUS',

  /**
   * Users
   */
  SET_USERS = 'SET_USERS',
  SET_USERS_PAGINATION = 'SET_USERS_PAGINATION',
  SET_USERS_STATUS = 'SET_USERS_STATUS',

  /**
   * Trainings
   */
  SET_TRAININGS = 'SET_TRAININGS',
  SET_TRAININGS_PAGINATION = 'SET_TRAININGS_PAGINATION',
  SET_TRAININGS_STATUS = 'SET_TRAININGS_STATUS',
  SET_TOTAL_TRAININGS = 'SET_TOTAL_TRAININGS',
}

export interface StatisticsState {
  /**
   * Downloads
   */
  downloads: Download[];
  downloadsStatus: ListStatus;
  downloadsPagination: Pagination;
  totalDownloads: number;

  /**
   * Logins
   */
  logins: Login[];
  loginsStatus: ListStatus;
  loginsPagination: Pagination;

  /**
   * Users
   */
  users: User[];
  usersStatus: ListStatus;
  usersPagination: Pagination;

  /**
   * Trainings
   */
  trainings: Training[];
  trainingsStatus: ListStatus;
  trainingsPagination: Pagination;
  totalTrainings: number;
}
