
import Vue from 'vue';
import { mapState, mapGetters, mapActions } from 'vuex';

import { UserActions, SubmitNewPasswordStatus } from '@/store/modules/user/types';

import FormInput from '@/components/controls/form-input.vue';
import SubmitButton from '@/components/controls/submit-button.vue';
import { NotificationsActions } from '@/store/modules/notifications/types';

export default Vue.extend({
  name: 'LostPasswordForm',
  components: {
    FormInput,
    SubmitButton,
  },
  inject: {
    $validator: '$validator',
  },
  data() {
    return {
      password: '',
      passwordRepeat: '',
    };
  },
  computed: {
    ...mapGetters('user', ['isResetPending', 'isResetSuccess', 'isResetError']),
    ...mapState('user', ['submitNewPasswordStatus']),
    isSubmitPending() {
      return this.submitNewPasswordStatus === SubmitNewPasswordStatus.Pending;
    },
    isSubmitSuccess() {
      return this.submitNewPasswordStatus === SubmitNewPasswordStatus.Success;
    },
    isSubmitFailed() {
      return this.submitNewPasswordStatus === SubmitNewPasswordStatus.Failed;
    },
  },
  methods: {
    ...mapActions('user', {
      submitAction: UserActions.SUBMIT_NEW_PASSWORD,
    }),
    ...mapActions('notifications', {
      showError: NotificationsActions.SHOW_ERROR,
      hideErrors: NotificationsActions.HIDE_NOTIFICATION,
    }),
    submit() {
      return this.$validator.validateAll().then((result) => {
        if (result) {
          this.hideErrors();

          return this.submitAction({ password: this.password });
        }

        this.showError({
          title: this.$t('Error'),
          body: this.$t('Please correct the errors below and try again:'),
          errorList: this.errors.all(),
        });
      });
    },
  },
});
