
import Vue from 'vue';
import { mapGetters, mapActions } from 'vuex';

import { LocationsActions } from '@/store/modules/locations/types';
import SubmitButton from '@/components/controls/submit-button.vue';
import Dropdown, { DropdownItems } from '@/components/controls/dropdown.vue';
import { PracticeStatus } from '@/store/modules/practices/types';
import { ReportsActions } from '@/store/modules/reports/types';
import CountrySelection from '@/components/controls/country-selection.vue';
import ProductSelectionControl from '@/components/controls/product-selection.vue';

export default Vue.extend({
  name: 'PracticesFilterForm',
  components: {
    SubmitButton,
    Dropdown,
    CountrySelection,
    ProductSelectionControl
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    selectedItems: {
      type: Array,
      default: () => [] as number[],
    },
  },
  computed: {
    ...mapGetters('user/profile', ['isSuperAdmin']),
    dropdownItems() {
      const items: DropdownItems = {};

      if (this.isSuperAdmin) {
        items.report = {
          label: this.$t('Generate Report'),
          icon: 'document',
        };
      }

      items.reset = {
        label: this.$t('Reset Filters'),
        icon: 'close',
      };

      if ((this.isSuperAdmin || this.isAdmin) && this.selectedItems && this.selectedItems.length) {
        items.restore = {
          hasTopBorder: true,
          label: this.$t('Restore'),
          count: this.selectedItems.length,
          disabled: !this.selectedItems.length,
        };

        items.remove = {
          label: this.$t('Delete'),
          count: this.selectedItems.length,
          disabled: !this.selectedItems.length,
        };
      }

      return items;
    },
  },
  data() {
    return {
      availableStatuses: {
        [PracticeStatus.Published]: this.$t('Published'),
        [PracticeStatus.Inactive]: this.$t('Deleted'),
      },
      search: '',
      status: '',
      country: 0,
      product: undefined,
    };
  },
  methods: {
    ...mapActions('reports', {
      requestReport: ReportsActions.REQUEST,
    }),
    ...mapActions('practices', {
      deletePractices: LocationsActions.BULK_DELETE,
      restorePractices: LocationsActions.BULK_RESTORE,
    }),
    initFilters(): void {
      this.search = this.$router.currentRoute.query.search || '';
      this.status = this.$router.currentRoute.query.status || '';
      this.country = Number(this.$router.currentRoute.query.country_id) || 0;

      let newProductValue;

      if (this.$router.currentRoute.query.product_id) {
        newProductValue = Number(this.$router.currentRoute.query.product_id);

        if (!newProductValue || Number.isNaN(newProductValue)) {
          newProductValue = undefined;
        }
      }

      if (this.product !== newProductValue) {
        this.product = newProductValue;
      }

    },
    applyFilters(): void {
      const { sort, direction, ...queryParams } = this.$route.query;

      this.$router.push({
        params: {
          page: 1,
        },
        query: {
          ...queryParams,
          status: this.status,
          search: this.search,
          country_id: this.country ? String(this.country) : undefined,
          product_id: this.product,
        },
      });
    },
    handleDropdownClick(action: string): void {
      if ('reset' === action) {
        this.$router.push({
          params: {
            page: 1,
          },
          query: {},
        });
      }

      if ('remove' === action) {
        this.deletePractices({ ids: this.selectedItems });
      }
      if ('restore' === action) {
        this.restorePractices({ ids: this.selectedItems });
      }

      if ('report' === action) {
        this.requestReport();
      }
    },
  },
  created(): void {
    this.initFilters();
  },
  watch: {
    $route(): void {
      this.initFilters();
    },
  },
});
