
import Vue from 'vue';
import { mapGetters } from 'vuex';
import i18n from '@/i18n';

interface ErrorLabels {
  name: string;
  linkMessage: string;
  linkLabel: string;
}

enum Objects {
  Locations = 'locations',
  Practitioners = 'practitioners',
  ResourceCategories = 'categories',
  Resources = 'resources',
  Users = 'users',
  Practices = 'practices',
  Products = 'products',
  Accessories = 'accessories',
  Treatments = 'treatments',
  Enhancements = 'enhancements',
  Blog = 'blog',
  Consultations = 'consultations',
  Dashboard = 'dashboard',
}

type ObjectsRoutes = { [key in Objects]: string[] };
type ObjectsLabels = { [key in Objects]: ErrorLabels };

const objectsRoutes: ObjectsRoutes = {
  locations: ['locations-quick-review', 'edit-location', 'preview-edit-location', 'add-location'],
  practitioners: ['edit-practitioner', 'add-practitioner'],
  categories: ['edit-category', 'add-category'],
  resources: ['add-resource', 'add-resource/add-category', 'resource', 'edit-resource', 'edit-resource/add-category'],
  users: ['add-user', 'edit-user'],
  practices: ['add-practice', 'view-practice', 'edit-practice'],
  products: ['add-product', 'edit-product'],
  accessories: ['edit-accessory', 'add-accessory'],
  treatments: ['edit-treatment', 'add-treatment'],
  enhancements: ['add-enhancement', 'edit-enhancement'],
  blog: ['blog-category', 'blog-author', 'blog-post'],
  consultations: ['view-consultation'],
  dashboard: [],
};

const objectsLabels: ObjectsLabels = {
  locations: {
    name: i18n.t('location'),
    linkMessage: i18n.t('View my'),
    linkLabel: i18n.t('locations'),
  },
  practitioners: {
    name: i18n.t('practitioner'),
    linkMessage: i18n.t('View my'),
    linkLabel: i18n.t('practitioners'),
  },
  categories: {
    name: i18n.t('resource category'),
    linkMessage: i18n.t('View all'),
    linkLabel: i18n.t('resource categories'),
  },
  resources: {
    name: i18n.t('resource'),
    linkMessage: i18n.t('View all'),
    linkLabel: i18n.t('resources'),
  },
  users: {
    name: i18n.t('user'),
    linkMessage: i18n.t('View all'),
    linkLabel: i18n.t('users'),
  },
  practices: {
    name: i18n.t('practice'),
    linkMessage: i18n.t('View all'),
    linkLabel: i18n.t('practices'),
  },
  products: {
    name: i18n.t('product'),
    linkMessage: i18n.t('View all'),
    linkLabel: i18n.t('products'),
  },
  accessories: {
    name: i18n.t('accessory'),
    linkMessage: i18n.t('View all'),
    linkLabel: i18n.t('accessories'),
  },
  treatments: {
    name: i18n.t('treatment'),
    linkMessage: i18n.t('View all'),
    linkLabel: i18n.t('treatments'),
  },
  enhancements: {
    name: i18n.t('treatment enhancement'),
    linkMessage: i18n.t('View all'),
    linkLabel: i18n.t('treatment enhancements'),
  },
  blog: {
    name: i18n.t('blog post'),
    linkMessage: i18n.t('View all'),
    linkLabel: i18n.t('posts'),
  },
  consultations: {
    name: i18n.t('consultation lead'),
    linkMessage: i18n.t('View my'),
    linkLabel: i18n.t('consultation leads'),
  },
  dashboard: {
    name: i18n.t('page'),
    linkMessage: i18n.t('Back to'),
    linkLabel: i18n.t('dashboard'),
  },
};

export default Vue.extend({
  name: 'AccessControlBlock',

  data() {
    return {
      currentRouteName: '',
    };
  },

  computed: {
    ...mapGetters({ accessDenied: 'isForbiddenError' }),

    errorObject(): Objects {
      const keys = Object.keys(objectsRoutes) as Objects[];

      for (const key of keys) {
        const routes = objectsRoutes[key];

        if (this.isRoute(routes)) {
          return key;
        }
      }

      return Objects.Dashboard;
    },

    errorLabels(): ErrorLabels {
      return objectsLabels[this.errorObject];
    },
  },

  methods: {
    isRoute(routes: string | string[]) {
      const names = (Array.isArray(routes) ? routes : [routes])
        // Cast to string
        .map((val) => String(val))
        // Filter empty strings and invalid values
        .filter((val) => !!val);

      if (!names.length || !this.currentRouteName) {
        return false;
      }

      return names.indexOf(this.currentRouteName) > -1;
    },
  },

  watch: {
    $route: {
      // the handler will be called immediately after the start of the observation
      immediate: true,
      handler(to, from) {
        this.currentRouteName = to.name || '';
      },
    },
  },
});
