/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'social/pinterest': {
    width: 18.58,
    height: 24,
    viewBox: '0 0 18.58 24',
    data: '<path pid="0" d="M9.85 0C3.29 0 0 4.7 0 8.61c0 2.37.9 4.48 2.82 5.27a.47.47 0 00.69-.35c.07-.24.22-.85.29-1.1a.68.68 0 00-.2-.77A4 4 0 012.69 9a6.52 6.52 0 016.78-6.6c3.71 0 5.74 2.26 5.74 5.28 0 4-1.76 7.34-4.37 7.34a2.14 2.14 0 01-2.18-2.66c.42-1.75 1.22-3.63 1.22-4.89A1.85 1.85 0 008.02 5.4c-1.47 0-2.66 1.52-2.66 3.57a5.3 5.3 0 00.44 2.18s-1.51 6.34-1.77 7.46a15.32 15.32 0 000 5.24.19.19 0 00.33.08 14.85 14.85 0 002.48-4.51c.17-.62 1-3.79 1-3.79a4 4 0 003.37 1.71c4.43 0 7.44-4 7.44-9.44-.07-4.09-3.53-7.9-8.8-7.9z"/>'
  }
})
