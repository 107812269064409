import { createCRUDEndpoints } from '@/api/factory/crud';
import { UserJSON, UserStatus } from '@/store/models/user';
import httpClient from '../http-client';
import { RequestConfig } from '../types';

type ListParams =
  | {
      title?: string;
      search?: string;
      status?: string | number | UserStatus;
      page?: string | number;
      sort?: string;
      direction?: 'asc' | 'desc';
    }
  | undefined;

export const { list, get, store, remove, restore } = createCRUDEndpoints<UserJSON, ListParams>({
  slug: 'user',
});

export interface SendWelcomeEmailParams {
  id: UserJSON['id'];
  send_to_admin: boolean;
  subject: string;
  body: string;
  button: string;
  help: string;
  reply_to?: string;
}

export function sendWelcomeEmail({ id, ...data }: SendWelcomeEmailParams, config?: RequestConfig) {
  return httpClient.post<{ success: string }>(`user/${id}/welcome`, data, config);
}
