
import Vue from 'vue';

export default Vue.extend({
  name: 'FileUploadControl',
  inject: {
    $validator: '$validator',
  },
  $_veeValidate: {
    name() {
      return this.name;
    },
    value() {
      return this.value;
    },
  },
  data() {
    return {
      file: undefined,
    };
  },
  props: {
    value: {
      type: String,
    },
    name: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    error: {
      type: String,
    },
    required: {
      type: Boolean,
      default: false,
    },
    maxSize: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    fileName() {
      if (this.file && this.file.name) {
        return this.file.name;
      }

      return this.$t('No file selected...');
    },
    maxSizeBytes() {
      if (!this.maxSize) {
        return undefined;
      }

      return this.maxSize * 1000 * 1000;
    },
  },
  methods: {
    triggerUpload() {
      if (this.disabled) {
        return;
      }

      const fileInput: HTMLInputElement = this.$refs.fileInput;
      fileInput.click();
    },
    updateValue(event: Event) {
      if (!event.target) {
        return;
      }

      const target = event.target as HTMLInputElement;

      if (!target.files) {
        this.updateFile(undefined);
        return;
      }

      if (this.maxSizeBytes && this.maxSizeBytes < target.files[0].size) {
        event.preventDefault();

        this.updateFile(undefined);
        this.$emit('error', this.$t('The file may not be greater then %(size)%MB.', { size: this.maxSize }));
        return;
      }

      this.updateFile(target.files[0]);
    },
    updateFile(file?: File) {
      this.file = file;
      this.$emit('change', this.file);
    },
  },
});
