import Vue from 'vue';
import VeeValidate from 'vee-validate';
import { getContentHighlightedWords } from '@/utils/highlight';
import store from '@/store';

VeeValidate.Validator.extend('checkBannedWords', {
  validate: (value: any) => {
    const highlightedWords = getContentHighlightedWords(value, (store.state as any).settings.settings.bannedWords);
    const valid = !highlightedWords.length;

    return {
      valid,
      data: highlightedWords,
    };
  },
});

Vue.use(VeeValidate, { inject: false, delay: 700 });

export default VeeValidate;
