import { ActionTree } from 'vuex';
import { AxiosError } from 'axios';

import router from '@/router';
import i18next from '@/i18n';
import api from '@/api';

import { RootState, EventTypes, RootActions } from '@/store/types';
import {
  UserState,
  UserActions,
  UserMutations,
  SubmitNewPasswordStatus,
  LoginStatus,
} from '@/store/modules/user/types';
import { NotificationsActions } from '@/store/modules/notifications/types';
import { ProfileActions, TERMS_AGREE_KEY } from '@/store/modules/user/profile/types';
import { ReportsActions } from '@/store/modules/reports/types';
import { Profile } from '@/store/modules/user/profile/profile';

const actions: ActionTree<UserState, RootState> = {
  [UserActions.LOGIN]({ dispatch, commit, rootGetters }, { email, password }) {
    commit('LOGIN_PENDING'); // show spinner
    commit(UserMutations.SET_TOKEN, undefined); // Remove token before login

    return api.auth
      .login({ email, password })
      .then((response) => {
        commit(UserMutations.SET_TOKEN, response.data.access_token);

        dispatch('profile/' + ProfileActions.SET_PROFILE, Profile.fromJSON(response.data.user));
        dispatch('reports/' + ReportsActions.START_CHECKING_REQUESTED, undefined, { root: true });
      })
      .then(() => {
        commit(UserMutations.LOGIN_SUCCESS);
        return dispatch(RootActions.DISPATCH_EVENT, { type: EventTypes.LOGIN }, { root: true });
      })
      .then(() => {
        const prevRoute = rootGetters.prevRoute;

        if (prevRoute && prevRoute.to) {
          return router.push({ ...prevRoute.to });
        }

        return router.push({ name: 'dashboard' });
      })
      .catch((error: AxiosError | Error) => {
        let loginError: AxiosError<{ error: string }> | undefined;

        if (api.isAPILoginError(error)) {
          loginError = error;
        }

        commit(UserMutations.LOGIN_FAILED, loginError);
        dispatch(RootActions.DISPATCH_EVENT, { type: EventTypes.LOGIN_FAILED, eventObj: { email } }, { root: true });
      });
  },
  [UserActions.LOGOUT]({ dispatch, commit, getters, state }): Promise<void> {
    if (!getters.isLoggedIn || state.loginStatus === LoginStatus.Pending) {
      return Promise.resolve();
    }

    window.loader(true);
    commit(UserMutations.LOGOUT_PENDING);

    return api.auth
      .logout()
      .then(() => {
        // Remove token
        commit(UserMutations.SET_TOKEN, undefined);
        // Reset user state
        commit(UserMutations.RESET_STORE_STATE);
        localStorage.removeItem(TERMS_AGREE_KEY);

        return dispatch(RootActions.DISPATCH_EVENT, { type: EventTypes.LOGOUT }, { root: true });
      })
      .catch(() => {
        // Catch any error before refreshing tab.
        // TODO: Maybe we should inform a user that logging out for some reason failed.
        // Currently if request failed then after tab refresh the user will still be logged in.
      })
      .then(() => {
        // Refresh tab
        window.location = window.location;
      });
  },

  [UserActions.VERIFY_RESET]({ dispatch, commit }, { token, email }) {
    commit(UserMutations.RESET_PENDING);

    return api.auth
      .verifyResetPasswordToken({ token, email })
      .then((response) => {
        commit(UserMutations.SET_TOKEN, response.data.access_token);
        commit(UserMutations.LOGIN_SUCCESS);
        commit(UserMutations.RESET_SUCCESS);
        return dispatch(RootActions.DISPATCH_EVENT, { type: EventTypes.LOGIN }, { root: true });
      })
      .catch((error) => {
        commit(UserMutations.RESET_FAILED, error);
        dispatch(
          'notifications/' + NotificationsActions.SHOW_ERROR,
          { title: error.response.data.error },
          { root: true },
        );
        return router.push({ name: 'reset' });
      });
  },

  [UserActions.RESET_PASSWORD]({ dispatch, commit }, { email }) {
    commit(UserMutations.RESET_PENDING);
    // Store JWT
    return api.auth
      .requestPasswordReset({ email })
      .then((response) => {
        // TODO: Handle successful send email reset
        commit(UserMutations.RESET_SUCCESS);
        dispatch(
          'notifications/' + NotificationsActions.SHOW_SUCCESS,
          { title: response.data.success },
          { root: true },
        );
      })
      .then(() => {
        return dispatch(
          RootActions.DISPATCH_EVENT,
          { type: EventTypes.RESET_PASSWORD, eventObj: { email } },
          { root: true },
        );
      })
      .catch((error) => {
        commit(UserMutations.RESET_FAILED, error);
      });
  },

  [UserActions.REQUEST_SUPPORT]({ commit }, comment) {
    commit(UserMutations.SUPPORT_PENDING);
    // Refresh the token
    return api.support
      .send({ comment })
      .then(() => {
        commit(UserMutations.SUPPORT_SUCCESS);
      })
      .catch((error) => {
        commit(UserMutations.SUPPORT_FAILED, error);
      });
  },

  [UserActions.IMPERSONATE]({ commit, getters, dispatch }, payload): Promise<void> {
    if (getters.isLoading) {
      return Promise.resolve();
    }

    commit(UserMutations.LOGIN_PENDING);
    commit(UserMutations.CACHE_IMPERSONATE_TOKEN);

    return api.auth
      .impersonate(payload.id)
      .then((response) => {
        // Set the user token
        commit(UserMutations.SET_TOKEN, response.data.access_token);
        commit(UserMutations.LOGIN_SUCCESS);
        return dispatch('profile/' + ProfileActions.SET_PROFILE, Profile.fromJSON(response.data.user));
      })
      .then(() => {
        window.location.pathname = router.match({ name: 'dashboard' }).fullPath;
      });
  },
  [UserActions.END_IMPERSONATION]({ commit, dispatch }): Promise<void> {
    window.loader(true);

    commit(UserMutations.LOGOUT_PENDING);
    return api.auth
      .logout()
      .then(() => {
        // Replace token with previous cached token
        commit(UserMutations.RESTORE_IMPERSONATE_TOKEN);
        commit(UserMutations.LOGIN_SUCCESS);
        // Fetch user profile
        return dispatch('profile/' + ProfileActions.LOAD_PROFILE);
      })
      .catch((error) => {
        // TODO: How should we handle the failure to end the impersonation
        // tslint:disable-next-line: no-console
        console.error(error);
        throw error;
      })
      .then(() => {
        // Refresh tab
        router.push({ name: 'users' }, () => {
          window.location = window.location;
        });
      });
  },
  [UserActions.SUBMIT_NEW_PASSWORD]({ commit, dispatch }, { password }): Promise<void> {
    commit(UserMutations.SET_SUBMIT_NEW_PASSWORD_STATUS, SubmitNewPasswordStatus.Pending);

    return api.auth
      .me()
      .then((response) => api.auth.updateMe({ ...response.data, password }))
      .then(() => {
        dispatch(
          'notifications/' + NotificationsActions.SHOW_SUCCESS,
          {
            body: i18next.t('Your profile was successfully updated.'),
          },
          { root: true },
        );
        commit(UserMutations.SET_SUBMIT_NEW_PASSWORD_STATUS, SubmitNewPasswordStatus.Success);

        router.push({ name: 'dashboard' });
      })
      .catch((error) => {
        commit(UserMutations.SET_SUBMIT_NEW_PASSWORD_STATUS, SubmitNewPasswordStatus.Failed);
        return dispatch(RootActions.ERROR, error, { root: true });
      });
  },
};

export default actions;
