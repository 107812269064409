/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'arrow': {
    width: 15.68,
    height: 15.84,
    viewBox: '0 0 15.68 15.84',
    data: '<path pid="0" d="M10.84 15.84h-6v-8H0L7.84 0l7.84 7.84h-4.84z"/>'
  }
})
