import Vue from 'vue';
import { MutationTree } from 'vuex';

import { PrivacyRequestState, PrivacyRequestMutations } from './types';
import Pagination from '@/store/models/pagination';
import { EditableStatus, ListStatus } from '@/store/types';

import PrivacyRequest from './privacy_request';

export const mutations: MutationTree<PrivacyRequestState> = {
  /**
   * List
   */
  [PrivacyRequestMutations.SET_LIST](state, categories: PrivacyRequest[]) {
    state.list = categories;
  },
  [PrivacyRequestMutations.SET_LIST_PAGINATION](state, pagination: Pagination) {
    state.listPagination = pagination;
  },
  [PrivacyRequestMutations.SET_STATUS](state, status: ListStatus) {
    state.status = status;
  },

  /**
   * Edit
   */
  [PrivacyRequestMutations.SET_EDITABLE](state, category: PrivacyRequest) {
    Vue.set(state, 'editable', category);
  },
  [PrivacyRequestMutations.SET_EDITABLE_STATUS](state, editableStatus: EditableStatus) {
    state.editableStatus = editableStatus;
  },
};

export default mutations;
