/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'trash': {
    width: 1368,
    height: 1496,
    viewBox: '0 0 1368 1496',
    data: '<g data-name="Layer 2"><path pid="0" d="M268 1496c-38 0-69.82-16.69-97.41-51-28.66-35.7-42.59-77.89-42.59-129V344H12c-5 0-7.3-1.59-8.86-3.14S0 337 0 332v-64c0-5 1.59-7.3 3.14-8.86S7 256 12 256h322.3l75.23-179.49c8.54-21.06 23.77-38.68 46.56-53.87C478.63 7.62 501.48 0 524 0h320c22.84 0 45.05 7.41 67.91 22.64s38 32.82 46.56 53.87L1033.7 256H1356c5 0 7.3 1.59 8.86 3.14S1368 263 1368 268v64c0 5-1.59 7.3-3.14 8.86S1361 344 1356 344h-116v968c0 51.24-14 94.16-42.79 131.23-27.58 35.51-59.38 52.77-97.21 52.77zm-52-184a134 134 0 008.29 47.58c5.58 14.75 11.54 25.59 18.21 33.15 6.5 7.39 14.4 15.27 25.5 15.27h832c11.1 0 19-7.88 25.5-15.27 6.67-7.56 12.63-18.41 18.21-33.15A134 134 0 001152 1312V344H216zM522.08 88.39c-11.32 2.26-21.3 8.6-28.86 18.33l-1.63 2.1L429.94 256h507.88l-60.35-147.12-1.68-2.16c-7.57-9.73-17.55-16.07-28.86-18.33L845 88H524zM908 1176c-5 0-7.3-1.59-8.86-3.14S896 1169 896 1164V588c0-5 1.59-7.3 3.14-8.86S903 576 908 576h64c5 0 7.3 1.59 8.86 3.14S984 583 984 588v576c0 5-1.59 7.3-3.14 8.86S977 1176 972 1176zm-256 0c-5 0-7.3-1.59-8.86-3.14S640 1169 640 1164V588c0-5 1.59-7.3 3.14-8.86S647 576 652 576h64c5 0 7.3 1.59 8.86 3.14S728 583 728 588v576c0 5-1.59 7.3-3.14 8.86S721 1176 716 1176zm-256 0c-5 0-7.3-1.59-8.86-3.14S384 1169 384 1164V588c0-5 1.59-7.3 3.14-8.86S391 576 396 576h64c5 0 7.3 1.59 8.86 3.14S472 583 472 588v576c0 5-1.59 7.3-3.14 8.86S465 1176 460 1176z" data-name="Layer 1"/></g>'
  }
})
