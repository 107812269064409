
import Vue, { PropOptions } from 'vue';
import BlogPost from '@/store/modules/blog/post';
import BlogPostMeta from '@/components/blocks/blog-post-meta.vue';

export default Vue.extend({
  name: 'BlogListItemBlock',
  components: {
    BlogPostMeta,
  },
  props: {
    small: {
      type: Boolean,
      default: false,
    },
    related: {
      type: Boolean,
      default: false,
    },
    post: {
      type: Object,
      required: true,
    } as PropOptions<BlogPost>,
  },
});
