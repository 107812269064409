
import '@/icons';
import Vue from 'vue';
import { mapState } from 'vuex';

import SubmitButton from '@/components/controls/submit-button.vue';
import MarkdownRederer from '@/components/blocks/markdown-renderer.vue';
import Legal, { LegalType } from '@/store/modules/settings/legal';

export default Vue.extend({
  name: 'SupportView',
  inject: {
    $validator: '$validator',
  },
  components: {
    SubmitButton,
    MarkdownRederer,
  },
  computed: {
    ...mapState('settings', ['settings']),
    privacyPolicy() {
      if (!this.editableLegals) {
        return;
      }

      return (this.editableLegals as Legal[]).find((item) => item.type === LegalType.Privacy);
    },
  },
});
