
import Vue from 'vue';
import { mapGetters, mapActions, mapState } from 'vuex';
import { PractitionersActions } from '@/store/modules/practitioners/types';
import PracticeSelectionControl from '@/components/controls/practice-selection.vue';
import { LocationsActions } from '@/store/modules/locations/types';

export default Vue.extend({
  name: 'PractitionerLocationsForm',
  components: {
    PracticeSelectionControl,
  },
  props: {
    add: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState('locations', {
      locationsList: 'locationsList',
    }),
    ...mapState('practitioners', {
      practitioner: 'editablePractitioner',
    }),
    ...mapGetters('practitioners', {
      hasPracticeAssigned: 'hasPracticeAssigned',
    }),
    ...mapGetters('locations', ['listIsLoading', 'listIsLoaded', 'listIsError', 'listIsEmpty']),
    ...mapGetters('user/profile', ['isSuperAdmin', 'isAdmin']),
  },
  methods: {
    ...mapActions('practitioners', {
      updatePractitioner: PractitionersActions.UPDATE_EDITABLE,
    }),
    ...mapActions('locations', [LocationsActions.LOAD_LOCATIONS_LIST]),
    isLocationSelected(id: number): boolean {
      if (!this.practitioner.locations) {
        return false;
      }

      return Boolean(this.practitioner.locations.find((location) => location.id === id));
    },
    toggleLocation(id: number): void {
      const locations = [...this.practitioner.locations];
      if (this.isLocationSelected(id)) {
        this.updatePractitioner({
          locations: locations.filter((location) => location.id !== id),
        });
      } else {
        const location = this.locationsList.find((item) => item.id === id);

        if (location) {
          this.updatePractitioner({
            locations: locations.concat(location),
          });
        }
      }
    },
    handlePracticeChange(practiceId: number) {
      this.updatePractitioner({
        // Reset locations data
        locations: [],
        // Set the new practice id
        practiceId,
      });

      // Reload the lists
      this[LocationsActions.LOAD_LOCATIONS_LIST]({ practice_id: practiceId });
    },
  },
});
