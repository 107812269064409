import { ListStatus } from '@/store/types';
import Pagination from '@/store/models/pagination';
import BlogPost from './post';
import BlogTaxonomy from './taxonomy';
import BlogAuthor from './author';

export enum BlogActions {
  /**
   * List
   */
  LOAD_LIST = 'LOAD_LIST',

  /**
   * Categories
   */
  LOAD_CATEGORIES = 'LOAD_CATEGORIES',

  /**
   * Author
   */
  LOAD_AUTHOR = 'LOAD_AUTHOR',

  /**
   * Post
   */
  LOAD_POST = 'LOAD_POST',
}

export enum BlogMutations {
  /**
   * List
   */
  SET_LIST = 'SET_LIST',
  SET_LIST_PAGINATION = 'SET_LIST_PAGINATION',
  SET_STATUS = 'SET_STATUS',

  /**
   * Categories
   */
  SET_CATEGORIES = 'SET_CATEGORIES',
  SET_CATEGORIES_STATUS = 'SET_CATEGORIES_STATUS',

  /**
   * Author
   */
  SET_AUTHOR = 'SET_AUTHOR',
  SET_AUTHOR_STATUS = 'SET_AUTHOR_STATUS',

  /**
   * Post
   */
  SET_POST = 'SET_POST',
  SET_POST_STATUS = 'SET_POST_STATUS',
}

export interface BlogState {
  /**
   * List
   */
  status: ListStatus;
  list: BlogPost[];
  listPagination: Pagination;

  /**
   * Categories
   */
  categories: BlogTaxonomy[];
  categoriesStatus: ListStatus;

  /**
   * Post
   */
  post?: BlogPost;
  postStatus: ListStatus;

  /**
   * Post
   */
  author?: BlogAuthor;
  authorStatus: ListStatus;
}
