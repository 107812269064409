import { ActionTree } from 'vuex';

import api from '@/api';

import Pagination from '@/store/models/pagination';
import User from '@/store/models/user';
import { RootState, ListStatus, RootActions, ErrorMessages } from '@/store/types';

import { StatisticsState, StatisticsActions, StatisticsMutations } from './types';
import Download from './download';
import Login from './login';
import Training from './training';
import { enhanceErrorWithMessage } from '@/utils/errors';

export const actions: ActionTree<StatisticsState, RootState> = {
  async [StatisticsActions.LOAD_DOWNLOADS]({ commit, dispatch }, filters = {}) {
    commit(StatisticsMutations.SET_DOWNLOADS_STATUS, ListStatus.Loading);

    try {
      const res = await api.statistics.getDownloads(filters);
      const downloads = res.data.data.map((item) => Download.fromJSON(item));

      const totalDownloads = downloads
        .map((download) => download.downloads)
        .filter(Boolean)
        .reduce((prev, cur) => prev + cur, 0);

      commit(StatisticsMutations.SET_DOWNLOADS_PAGINATION, Pagination.fromJSON(res.data));
      commit(StatisticsMutations.SET_DOWNLOADS, downloads);
      commit(StatisticsMutations.SET_TOTAL_DOWNLOADS, totalDownloads);
      commit(StatisticsMutations.SET_DOWNLOADS_STATUS, ListStatus.Loaded);
    } catch (error) {
      enhanceErrorWithMessage(error, ErrorMessages.DATA_LOAD_ERROR);

      commit(StatisticsMutations.SET_DOWNLOADS_STATUS, ListStatus.Failed);
      return dispatch(RootActions.ERROR, error, { root: true });
    }
  },
  async [StatisticsActions.LOAD_LOGINS]({ commit, dispatch }, filters = {}) {
    commit(StatisticsMutations.SET_LOGINS_STATUS, ListStatus.Loading);

    try {
      const res = await api.statistics.getLogins(filters);

      commit(StatisticsMutations.SET_LOGINS_PAGINATION, Pagination.fromJSON(res.data));
      commit(
        StatisticsMutations.SET_LOGINS,
        res.data.data.map((item) => Login.fromJSON(item)),
      );
      commit(StatisticsMutations.SET_LOGINS_STATUS, ListStatus.Loaded);
    } catch (error) {
      enhanceErrorWithMessage(error, ErrorMessages.DATA_LOAD_ERROR);

      commit(StatisticsMutations.SET_LOGINS_STATUS, ListStatus.Failed);
      return dispatch(RootActions.ERROR, error, { root: true });
    }
  },
  async [StatisticsActions.LOAD_USERS]({ commit, dispatch }, filters = {}) {
    commit(StatisticsMutations.SET_USERS_STATUS, ListStatus.Loading);

    try {
      const res = await api.statistics.getUsers(filters);

      commit(StatisticsMutations.SET_USERS_PAGINATION, Pagination.fromJSON(res.data));
      commit(
        StatisticsMutations.SET_USERS,
        res.data.data.map((item) => User.fromJSON(item)),
      );
      commit(StatisticsMutations.SET_USERS_STATUS, ListStatus.Loaded);
    } catch (error) {
      enhanceErrorWithMessage(error, ErrorMessages.DATA_LOAD_ERROR);

      commit(StatisticsMutations.SET_USERS_STATUS, ListStatus.Failed);
      return dispatch(RootActions.ERROR, error, { root: true });
    }
  },
  async [StatisticsActions.LOAD_TRAININGS]({ commit, dispatch }, filters = {}) {
    commit(StatisticsMutations.SET_TRAININGS_STATUS, ListStatus.Loading);

    try {
      const res = await api.statistics.getTrainings(filters);
      const trainings = res.data.data.map((item) => Training.fromJSON(item));

      const totalTrainings = trainings
        .map((training) => training.trainings)
        .filter(Boolean)
        .reduce((prev, cur) => prev + cur, 0);

      commit(StatisticsMutations.SET_TRAININGS_PAGINATION, Pagination.fromJSON(res.data));
      commit(StatisticsMutations.SET_TRAININGS, trainings);
      commit(StatisticsMutations.SET_TOTAL_TRAININGS, totalTrainings);
      commit(StatisticsMutations.SET_TRAININGS_STATUS, ListStatus.Loaded);
    } catch (error) {
      enhanceErrorWithMessage(error, ErrorMessages.DATA_LOAD_ERROR);

      commit(StatisticsMutations.SET_TRAININGS_STATUS, ListStatus.Failed);
      return dispatch(RootActions.ERROR, error, { root: true });
    }
  },
};

export default actions;
