
import Vue from 'vue';
import { mapState, mapGetters, mapActions } from 'vuex';

import { getEmailEditorOptions } from '@/utils/email';

import { SettingsActions } from '@/store/modules/settings/types';
import Email from '@/store/modules/settings/email';

import FormInput from '@/components/controls/form-input.vue';
import MarkdownEditor from '@/components/controls/markdown-editor.vue';
import TranslationsControl from '@/components/controls/translations.vue';

export default Vue.extend({
  name: 'SupportSettingsForm',
  inject: {
    $validator: '$validator',
  },
  components: {
    FormInput,
    MarkdownEditor,
    TranslationsControl,
  },
  data(): {
    expandedEmail?: number;
  } {
    return {
      expandedEmail: undefined,
    };
  },
  computed: {
    ...mapState('settings', ['editableEmails', 'emailTranslationsStatus']),
    ...mapGetters('settings', {
      isLoading: 'editableSettingsIsLoading',
      isSaving: 'editableSettingsIsSaving',
    }),
    isFetching() {
      return this.isLoading || this.isSaving;
    },
  },
  methods: {
    ...mapActions('settings', {
      updateEditable: SettingsActions.UPDATE_EDITABLE_EMAIL,
      saveTranslations: SettingsActions.SAVE_EMAIL_TRANSLATIONS,
    }),
    toggleExpand(id: number) {
      if (this.expandedEmail === id) {
        this.expandedEmail = undefined;
      } else {
        this.expandedEmail = id;
      }
    },
    getEditorOptions(email: Email) {
      return getEmailEditorOptions(email);
    },
  },
});
