/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'social/wistia': {
    width: 33.49,
    height: 26.05,
    viewBox: '0 0 33.49 26.05',
    data: '<g data-name="Layer 2"><path pid="0" d="M33.45 10s-.77 1.54-4.65 4a24.66 24.66 0 01-9.48 2.57c-2.38.23-6.75 0-8.63 0s-2.75.4-4.45 2.31L0 25.91h3.8c1.63 0 11.84.59 16.37-.65C34.89 21.22 33.45 10 33.45 10z" _fill="#0d8ce6"/><path pid="1" d="M33.06 5.5c.71-4.32-1.73-5.5-1.73-5.5s.1 3.51-6.33 4.25c-5.76.67-25 .14-25 .14l6.18 7.09c1.67 1.92 2.56 2.14 4.44 2.28a79.41 79.41 0 008.85-.13c3.09-.24 7.53-1.24 10.45-3.52a7.88 7.88 0 003.14-4.61z" _fill="#53b9ff"/></g>'
  }
})
