import { EventStatus } from './types';
import { JSONModel } from '@/store/models/jsonModel';
import { getEnumValue } from '@/utils/enum';
import { createExcerpt } from '@/utils/excerpt';
import { addURLParams } from '@/utils/url';
import EventCategory, { EventCategoryJSON } from './category';

export interface EventJSON {
  id: number;
  wordpress_id: number;
  country_id: number;
  language_id: number;
  language_code: string;
  status: number;
  title: string;
  url: string;
  category?: EventCategoryJSON | null;
  description: string;
  start: string;
  end: string;
  street: string;
  additional: string;
  city: string;
  state: string;
  province_name: string;
  postal_code: string;
  country_code: string;
  country_name: string;
  geocode_date: string;
  longitude: string;
  latitude: string;
  log: string;
  created_at: string;
  updated_at: string;
}

export default class Event extends JSONModel<EventJSON> {
  public id: number = 0;
  public status: EventStatus = EventStatus.Active;
  public title: string = '';
  public _url: string = '';
  public type?: EventCategory;
  public description: string = '';
  public start: string = '';
  public end: string = '';
  public street: string = '';
  public additional: string = '';
  public city: string = '';
  public state: string = '';
  public provinceName: string = '';
  public postalCode: string = '';
  public countryCode: string = '';
  public countryName: string = '';
  public geocodeDate: string = '';
  public longitude: number = 0;
  public latitude: number = 0;
  public createdAt: string = '';
  public updatedAt: string = '';

  public fromJSON(json: Partial<EventJSON>) {
    if (json.id) {
      this.id = Number(json.id);
    }

    const status = getEnumValue<EventStatus>(EventStatus, json.status);
    if (typeof status !== 'undefined') {
      this.status = status;
    }

    if (json.title) {
      this.title = String(json.title);
    }

    if (json.url) {
      this._url = String(json.url);
    }

    if (json.category) {
      this.type = EventCategory.fromJSON(json.category);
    }

    if (json.description) {
      this.description = String(json.description);
    }

    if (json.start) {
      this.start = String(json.start);
    }

    if (json.end) {
      this.end = String(json.end);
    }

    if (json.street) {
      this.street = String(json.street);
    }

    if (json.additional) {
      this.additional = String(json.additional);
    }

    if (json.city) {
      this.city = String(json.city);
    }

    if (json.state) {
      this.state = String(json.state);
    }

    if (json.province_name) {
      this.provinceName = String(json.province_name);
    }

    if (json.postal_code) {
      this.postalCode = String(json.postal_code);
    }

    if (json.country_code) {
      this.countryCode = String(json.country_code);
    }

    if (json.country_name) {
      this.countryName = String(json.country_name);
    }

    if (json.geocode_date) {
      this.geocodeDate = String(json.geocode_date);
    }

    if (json.longitude) {
      this.longitude = Number(json.longitude);
    }

    if (json.latitude) {
      this.latitude = Number(json.latitude);
    }

    if (json.created_at) {
      this.createdAt = String(json.created_at);
    }

    if (json.updated_at) {
      this.updatedAt = String(json.updated_at);
    }
  }

  public get excerpt() {
    return createExcerpt(this.description);
  }

  public get url() {
    return addURLParams(this._url, [
      { key: 'utm_source', value: 'amps' },
      { key: 'utm_medium', value: 'feed' },
    ]);
  }
}
