
import { mapState, mapGetters } from 'vuex';
import mixins from 'vue-typed-mixins';

import PractitionerBlock from '@/components/blocks/practitioner.vue';
import tableViewMixin from '@/components/mixins/table-view';
import PaginationControl from '@/components/controls/pagination.vue';
import CheckboxControl from '@/components/controls/checkbox.vue';
import PractitionersFilterForm from '@/components/forms/practitioners-filter.vue';
import TableListSkeleton from '@/components/skeletons/table-list.vue';
import GridListSkeleton from '@/components/skeletons/grid-list.vue';
import PublishStatusBlock from '@/components/blocks/publish-status.vue';
import CountryName from '@/components/blocks/country-name.vue';
import Country from '@/store/models/country';
import Practitioner from '@/store/modules/practitioners/practitioner';

export default mixins(tableViewMixin).extend({
  name: 'PractitionersListBlock',
  components: {
    PractitionersFilterForm,
    PaginationControl,
    CheckboxControl,
    PractitionerBlock,
    TableListSkeleton,
    GridListSkeleton,
    PublishStatusBlock,
    CountryName,
  },
  data() {
    return {
      gridView: false,
      sort: 'last_name',
      allSelected: false,
      selectedItems: [],
    };
  },
  props: {
    view: {
      type: String,
      default: 'grid',
      validator: (value: string) => {
        return ['grid', 'table'].indexOf(value) !== -1;
      },
    },
  },
  computed: {
    ...mapState(['countries']),
    ...mapState('practitioners', ['practitionersList', 'pagination']),
    ...mapGetters('practitioners', {
      isLoading: 'listIsLoading',
      isLoaded: 'listIsLoaded',
      isEmpty: 'listIsEmpty',
    }),
    ...mapGetters('user/profile', ['isSuperAdmin', 'isAdmin']),
    isGridView(): boolean {
      return this.view === 'grid';
    },
    areAllItemsSelected(): boolean {
      return this.selectedItems.length === this.practitionersList.length;
    },
    countryFilter(): Country | undefined {
      const countryId = Number(this.$route.query.country_id);

      if (Number.isNaN(countryId) || countryId < 1) {
        return;
      }

      return this.countries.find((item) => item.id === countryId);
    },
    countryFilterHasStates(): boolean {
      return this.countryFilter.states && this.countryFilter.states.length > 0;
    },
  },
  methods: {
    isSelected(practitioner: Practitioner): boolean {
      return this.selectedItems.indexOf(practitioner.id) !== -1;
    },
    selectItem(practitioner: Practitioner) {
      if (this.isSelected(practitioner)) {
        this.selectedItems = this.selectedItems.filter((item) => item !== practitioner.id);
        return;
      }

      this.selectedItems.push(practitioner.id);
    },
    selectAll() {
      if (this.areAllItemsSelected) {
        this.selectedItems = [];
      } else {
        this.selectedItems = this.practitionersList.map((practitioner: Practitioner) => practitioner.id);
      }
    },
  },
});
