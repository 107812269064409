
import Vue from 'vue';
import { PublishStatus } from '@/store/types';
import { PracticeStatus } from '@/store/modules/practices/types';

export default Vue.extend({
  name: 'PublishStatusBlock',
  props: {
    tag: {
      type: String,
      default: 'div',
    },
    status: {
      type: Number,
    },
    practice: {
      type: Object,
    },
  },
  computed: {
    isDraft(): boolean {
      return this.status === PublishStatus.Draft;
    },
    isPublished(): boolean {
      return this.status === PublishStatus.Published;
    },
    isPending(): boolean {
      return this.status === PublishStatus.Pending;
    },
    isDeleted(): boolean {
      return this.status === PublishStatus.Deleted;
    },
    isDeclined(): boolean {
      return this.status === PublishStatus.Declined;
    },
    isPracticeDeleted(): boolean {
      return this.isDeleted && typeof this.practice !== 'undefined' && this.practice.status === PracticeStatus.Inactive;
    },
    dotClasses() {
      return [
        'c-entry__dot',
        {
          'c-entry__dot--neutral': this.isDraft,
          'c-entry__dot--success': this.isPublished,
          'c-entry__dot--warning': this.isPending,
          'c-entry__dot--error': this.isDeleted,
        },
      ];
    },
    label() {
      if (this.isPublished) {
        return this.$t('Published');
      }

      if (this.isPending) {
        return this.$t('Pending Review');
      }

      if (this.isPracticeDeleted) {
        return this.$t('Practice Deleted');
      }

      if (this.isDeleted) {
        return this.$t('Deleted');
      }

      if (this.isDeclined) {
        return this.$t('Requires Changes');
      }

      return this.$t('Draft');
    },
  },
});
