import { ListStatus } from '@/store/types';
import Pagination from '@/store/models/pagination';
import Report from './report';

export enum ReportsActions {
  /**
   * List
   */
  LOAD_LIST = 'LOAD_LIST',

  /**
   * Request
   */
  REQUEST = 'REQUEST',
  CHECK_REQUESTED = 'CHECK_REQUESTED',
  START_CHECKING_REQUESTED = 'START_CHECKING_REQUESTED',
}

export enum ReportsMutations {
  /**
   * List
   */
  SET_LIST = 'SET_LIST',
  SET_LIST_STATUS = 'SET_LIST_STATUS',
  SET_LIST_PAGINATION = 'SET_LIST_PAGINATION',

  /**
   * Request
   */
  START_CHECKING_REQUESTED = 'START_CHECKING_REQUESTED',
}

export interface ReportsState {
  /**
   * List
   */
  list: Report[];
  listStatus: ListStatus;
  listPagination: Pagination;

  /**
   * Request
   */
  isReportsCheckRunning: boolean;
}
