import { createCRUDEndpoints } from '@/api/factory/crud';
import Location from '@/store/modules/locations/location';
import Practice from '@/store/modules/practices/practice';
import { PractitionerJSON } from '@/store/modules/practitioners/practitioner';
import { PractitionerRelationshipJSON } from '@/store/modules/practitioners/practitioner-relationship';
import { PublishStatus } from '@/store/types';
import httpClient from '../http-client';
import { RequestConfig } from '../types';

type ListParams =
  | {
      practice_id?: number;
      title?: string;
      search?: string;
      status?: PublishStatus;
      page?: string | number;
      sort?: string;
      direction?: 'asc' | 'desc';
    }
  | undefined;

export const { list, store, remove, restore } = createCRUDEndpoints<PractitionerJSON, ListParams>({
  slug: 'practitioner',
});

export function listLocationPractitioners(
  practiceId: Practice['id'],
  locationId: Location['id'],
  config?: RequestConfig,
) {
  return httpClient.get<PractitionerRelationshipJSON[]>(`practitioners/list/${practiceId}/${locationId}`, config);
}

export type PractitionerRevisionTag = 'latest' | 'published' | 'autosave';

export function get(
  id: PractitionerJSON['id'],
  version: PractitionerRevisionTag = 'published',
  config?: RequestConfig,
) {
  return httpClient.get(`practitioner/${id}/${version}`, config);
}
