import { createCRUDEndpoints } from '@/api/factory/crud';
import { CountryJSON } from '@/store/models/country';
import { CountryStatus } from '@/store/modules/countries/types';
import httpClient from '../http-client';
import { RequestConfig } from '../types';

type ListParams =
  | {
      search?: string;
      status?: string | number | CountryStatus;
      page?: string | number;
      sort?: string;
      direction?: 'asc' | 'desc';
    }
  | undefined;

export const { list, get, remove, restore } = createCRUDEndpoints<CountryJSON, ListParams, 'id', CountryJSON[]>({
  slug: 'country',
});

export function store(countries: Pick<CountryJSON, 'id' | 'admin_email'>[], config?: RequestConfig) {
  return httpClient.post<CountryJSON[]>('countries', countries, config);
}

export function getAddressStates(config?: RequestConfig) {
  return httpClient.get<{
    [key: string]: {
      id: number;
      country: string;
      states: { [key: string]: string };
      admin_email: string[];
    };
  }>('address/states', config);
}
