/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'tag': {
    width: 23.59,
    height: 23.58,
    viewBox: '0 0 23.59 23.58',
    data: '<g data-name="Layer 2"><g data-name="Tag 1"><g data-name="&lt;Group&gt;"><g data-name="&lt;Group&gt;"><path pid="0" data-name="&lt;Compound Path&gt;" d="M9.09 23.59A1.5 1.5 0 018 23.15L.44 15.56a1.5 1.5 0 010-2.12l13-13A1.51 1.51 0 0114.5 0h7.59a1.5 1.5 0 011.5 1.5v7.59a1.51 1.51 0 01-.44 1.06l-13 13a1.5 1.5 0 01-1.06.44zM14.5 1a.5.5 0 00-.35.15l-13 13a.5.5 0 000 .71l7.59 7.59a.5.5 0 00.71 0l13-13a.5.5 0 00.15-.35V1.5a.5.5 0 00-.5-.5z"/></g><g data-name="&lt;Group&gt;"><path pid="1" data-name="&lt;Compound Path&gt;" d="M18.09 8a2.5 2.5 0 112.5-2.5 2.5 2.5 0 01-2.5 2.5zm0-4a1.5 1.5 0 101.5 1.5 1.5 1.5 0 00-1.5-1.5z"/></g></g></g></g>'
  }
})
