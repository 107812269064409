
import Vue from 'vue';
import { mapState, mapGetters, mapActions } from 'vuex';
import Country from '@/store/models/country';
import { SettingsActions } from '@/store/modules/settings/types';

import SelectInput from '@/components/controls/select-input.vue';
import FormInputArray from '@/components/controls/form-input-array.vue';
import SubmitButton from '@/components/controls/submit-button.vue';

export default Vue.extend({
  name: 'CountrySettingsForm',
  inject: {
    $validator: '$validator',
  },
  components: {
    SelectInput,
    FormInputArray,
    SubmitButton,
  },
  data(): {
    expandedCountry?: number | string;
  } {
    return {
      expandedCountry: undefined,
    };
  },
  computed: {
    ...mapState('settings', ['countries', 'editableCountries']),
    ...mapGetters('settings', {
      isLoading: 'editableSettingsIsLoading',
      isSaving: 'editableSettingsIsSaving',
    }),
    isFetching() {
      return this.isLoading || this.isSaving;
    },
  },
  methods: {
    ...mapActions('settings', {
      updateEditableList: SettingsActions.UPDATE_EDITABLE_COUNTRIES,
      updateEditable: SettingsActions.UPDATE_EDITABLE_COUNTRY,
    }),
    toggleExpand(id: number | string) {
      if (this.expandedCountry === id) {
        this.expandedCountry = undefined;
      } else {
        this.expandedCountry = id;
      }
    },
    addNewCountry() {
      const newCountry = new Country();
      this.expandedCountry = newCountry.id;
      this.updateEditableList([...this.editableCountries, newCountry]);
    },
    removeCountry(id: string | number) {
      const answer = window.confirm(this.$t('Do you really want to delete this Country?'));

      if (answer) {
        this.updateEditableList([...this.editableCountries.filter((country) => country.id !== id)]);
      }
    },
    availableCountries(country: Country) {
      const activeCountriesIds = this.editableCountries.map((item: Country) => item.id);
      return this.countries.filter((item: Country) => {
        if (item.id === country.id) {
          return true;
        }

        return activeCountriesIds.indexOf(item.id) === -1;
      });
    },
  },
});
