import Image, { ImageJSON } from '@/store/models/image';
import { JSONModel } from '@/store/models/jsonModel';

export enum TreatmentEnhancementStatus {
  Inactive = 0,
  Active = 1,
}

export interface TreatmentEnhancementJSON {
  id: number;
  title: string;
  description?: string;
  status: 0 | 1;
  tooltip: string;
  image?: ImageJSON | ImageJSON[];
  translations: {
    title: Record<string, string>;
    tooltip: Record<string, string>;
  };
}

export default class TreatmentEnhancement extends JSONModel<TreatmentEnhancementJSON> {
  public id: number = 0;
  public title: string = '';
  public description: string = '';
  public status: TreatmentEnhancementStatus = TreatmentEnhancementStatus.Active;
  public tooltip: string = '';
  public image?: Image;
  public translations: {
    title: Record<string, string>;
    tooltip: Record<string, string>;
  } = { title: {}, tooltip: {} };
  protected _clientProperties = ['translations'];

  public fromJSON(json: TreatmentEnhancementJSON) {
    this.id = Number(json.id);
    this.title = String(json.title);

    if (json.description) {
      this.description = json.description;
    }

    this.status = Number(json.status) === 0 ? 0 : 1;
    this.tooltip = String(json.tooltip);

    if (json.image) {
      this.image = Image.fromJSON(Array.isArray(json.image) ? json.image[0] : json.image);
    }

    if (json.translations) {
      this.translations = json.translations;
    }
  }
}
