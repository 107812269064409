
import Vue from 'vue';
import { mapGetters, mapActions } from 'vuex';

import SubmitButton from '@/components/controls/submit-button.vue';
import ProductSelectionControl from '@/components/controls/product-selection.vue';
import Dropdown, { DropdownItems } from '@/components/controls/dropdown.vue';
import { TreatmentStatus } from '@/store/modules/treatments/treatment';
import { TreatmentActions } from '@/store/modules/treatments/types';

export default Vue.extend({
  name: 'TreatmentsListFilterForm',
  components: {
    SubmitButton,
    ProductSelectionControl,
    Dropdown,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    selectedItems: {
      type: Array,
      default: () => [] as number[],
    },
  },
  data() {
    return {
      availableStatuses: {
        [TreatmentStatus.Inactive]: this.$t('Inactive'),
        [TreatmentStatus.Active]: this.$t('Active'),
      },
      search: '',
      status: '',
      product: undefined,
    };
  },
  computed: {
    ...mapGetters('user/profile', ['isSuperAdmin']),
    dropdownItems() {
      const items: DropdownItems = {};
      items.reset = {
        label: this.$t('Reset Filters'),
        icon: 'close',
      };
      if ((this.isSuperAdmin || this.isAdmin) && this.selectedItems && this.selectedItems.length) {
        items.restore = {
          hasTopBorder: true,
          label: this.$t('Restore'),
          count: this.selectedItems.length,
          disabled: !this.selectedItems.length,
        };

        items.remove = {
          label: this.$t('Delete'),
          count: this.selectedItems.length,
          disabled: !this.selectedItems.length,
        };
      }
      return items;
    },
  },
  methods: {
    ...mapActions('treatments', {
      deleteTreatments: TreatmentActions.BULK_DELETE,
      restoreTreatments: TreatmentActions.BULK_RESTORE,
    }),
    initFilters(): void {
      this.search = this.$router.currentRoute.query.search || '';
      this.status = this.$router.currentRoute.query.status || '';

      let newProductValue;

      if (this.$router.currentRoute.query.product_id) {
        newProductValue = Number(this.$router.currentRoute.query.product_id);

        if (!newProductValue || Number.isNaN(newProductValue)) {
          newProductValue = undefined;
        }
      }

      if (this.product !== newProductValue) {
        this.product = newProductValue;
      }
    },
    applyFilters(): void {
      const { sort, direction, ...queryParams } = this.$route.query;

      this.$router.push({
        params: {
          page: 1,
        },
        query: {
          ...queryParams,
          search: this.search,
          status: this.status,
          product_id: this.product,
        },
      });
    },
    handleDropdownClick(action: string): void {
      if ('reset' === action) {
        this.$router.push({
          params: {
            page: 1,
          },
          query: {},
        });
      }

      if ('remove' === action) {
        this.deleteTreatments({ ids: this.selectedItems });
      }

      if ('restore' === action) {
        this.restoreTreatments({ ids: this.selectedItems });
      }
    },
  },
  created(): void {
    this.initFilters();
  },
  watch: {
    $route(): void {
      this.initFilters();
    },
  },
});
