
import Vue from 'vue';
const generateId = (
  (index = 0) =>
  () =>
    String(index++)
)();

export default Vue.extend({
  inject: {
    $validator: '$validator',
  },
  $_veeValidate: {
    name() {
      return this.name;
    },
    value() {
      return this.value;
    },
    rejectsFalse: true,
  },
  name: 'CheckboxControl',
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      id: 'checkbox_' + generateId(),
    };
  },
  computed: {
    hasError() {
      if (!this.name) {
        return false;
      }
      return this.errors.has(this.name);
    },
    fieldError() {
      if (!this.name) {
        return false;
      }
      return this.errors.items.find((item) => item.field === this.name);
    },
    fieldErrorRule(): string {
      if (!this.fieldError || !this.fieldError.rule) {
        return '';
      }
      return this.fieldError.rule;
    },
    fieldErrorMsg(): string {
      if (!this.fieldError || !this.fieldError.msg) {
        return '';
      }
      return this.fieldError.msg;
    },
    showFieldError(): boolean {
      return Boolean(this.fieldErrorMsg.length);
    },
  },
  methods: {
    update(event) {
      this.$emit('input', event.target.checked);
    },
  },
});
