/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'document': {
    width: 19,
    height: 24,
    viewBox: '0 0 19 24',
    data: '<g data-name="Layer 2"><g data-name="2 Files"><g data-name="&lt;Group&gt;"><g data-name="&lt;Group&gt;"><g data-name="&lt;Group&gt;"><path pid="0" data-name="&lt;Compound Path&gt;" d="M15.5 21H.5a.5.5 0 01-.5-.5v-14a.5.5 0 01.15-.35l6-6A.5.5 0 016.5 0h9a.5.5 0 01.5.5v20a.5.5 0 01-.5.5zM1 20h14V1H6.71L1 6.71z"/></g><g data-name="&lt;Group&gt;"><path pid="1" data-name="&lt;Path&gt;" d="M6.5 7h-6a.5.5 0 010-1H6V.5a.5.5 0 011 0v6a.5.5 0 01-.5.5z"/></g></g><g data-name="&lt;Group&gt;"><path pid="2" data-name="&lt;Path&gt;" d="M18.5 24h-15a.5.5 0 01-.5-.5v-3a.5.5 0 011 0V23h14V4h-2.5a.5.5 0 010-1h3a.5.5 0 01.5.5v20a.5.5 0 01-.5.5z"/></g></g></g></g>'
  }
})
