
import Vue from 'vue';
import FormInput from '@/components/controls/form-input.vue';

export default Vue.extend({
  name: 'ResourceLinkForm',
  inject: {
    $validator: '$validator',
  },
  model: {
    prop: 'link',
  },
  components: {
    FormInput,
  },
  props: {
    link: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    canDelete: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    componentKey() {
      return this.$vnode.key;
    },
    isEmpty() {
      return !this.link.url && !this.link.title;
    },
  },
  methods: {
    emitLink(changes = {}) {
      this.$emit('input', { ...this.link, ...changes });
    },
    updateTitle(title: string) {
      this.emitLink({ title });
    },
    updateUrl(url: string) {
      this.emitLink({ url });
    },
  },
});
