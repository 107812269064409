/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'pulse': {
    width: 24.5,
    height: 20.5,
    viewBox: '0 0 24.5 20.5',
    data: '<g data-name="Layer 2"><path pid="0" d="M12.75 20.5a.75.75 0 01-.72-.63L9.52 4.12 7.47 11a.75.75 0 01-.72.53h-6a.75.75 0 010-1.5h5.44L9 .53A.77.77 0 019.8 0a.75.75 0 01.69.63l2.37 15L15 5.59a.75.75 0 011.43-.12L18.26 10h5.49a.75.75 0 010 1.5h-6a.75.75 0 01-.7-.47L16 8.31l-2.52 11.6a.75.75 0 01-.73.59z" data-name="Outline Icons"/></g>'
  }
})
