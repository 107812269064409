import { JSONModel } from '@/store/models/jsonModel';

export interface ProductRelationshipJSON {
  id: number;
  title: string;
  logo?: string;
  owned?: boolean;
}

export default class ProductRelationship extends JSONModel<ProductRelationshipJSON> {
  public id: number = 0;
  public title: string = '';
  public logo?: string;
  public owned: boolean = true;

  public fromJSON(json: Partial<ProductRelationshipJSON>) {
    this.id = Number(json.id);

    if (json.title) {
      this.title = String(json.title);
    }

    if (json.logo) {
      this.logo = String(json.logo);
    }

    if (typeof json.owned !== 'undefined' && !Boolean(json.owned)) {
      this.owned = false;
    }
  }
}
