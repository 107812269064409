
import Vue from 'vue';
import { mapState, mapGetters } from 'vuex';
import flatten from 'lodash/flatten';
import uniq from 'lodash/uniq';
import mapValues from 'lodash/mapValues';

import { EditableStatus } from '@/store/types';

import NotificationBanner from '@/components/blocks/notification-banner.vue';
import Modal from '@/components/layout/modal.vue';
import LanguagePicker from '@/components/controls/language-picker.vue';
import SubmitButton from '@/components/controls/submit-button.vue';

type TranslationsObject = Record<string, Record<string, string>>;

export default Vue.extend({
  name: 'TranslationsControl',
  $_veeValidate: {
    validator: 'new', // new validator scope
  },
  components: {
    NotificationBanner,
    Modal,
    LanguagePicker,
    SubmitButton,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    initialValue: {
      type: Object as () => TranslationsObject,
      required: true,
    },
    saveStatus: {
      type: String as () => EditableStatus,
      default: EditableStatus.Loaded,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      value: {},
      isModalOpen: false,
      sourceLanguage: 'en',
      targetLanguage: 'en',
    };
  },
  computed: {
    ...mapState(['languages']),
    ...mapState('user/profile', ['currentProfile']),
    ...mapGetters('user/profile', ['isSuperAdmin', 'isAdmin']),
    completedTranslations(): string[] {
      const availableLanguageCodes: string[] = this.languages.map((language) => language.code);

      return uniq(flatten(Object.values(this.value as TranslationsObject).map((item) => Object.keys(item)))).filter(
        (language) => availableLanguageCodes.indexOf(language) !== -1,
      );
    },
    notCompletedTranslations(): string[] {
      return this.languages
        .map((language) => language.code)
        .filter((lang) => this.completedTranslations.indexOf(lang) === -1);
    },
    isSaving() {
      return this.saveStatus === EditableStatus.Saving;
    },
    isSaved() {
      return this.saveStatus === EditableStatus.Saved;
    },
    isFailed() {
      return this.saveStatus === EditableStatus.Failed;
    },
  },
  methods: {
    getLanguageTranslations(language: string) {
      const value = this.value as TranslationsObject;

      return mapValues(value, (field) => {
        return field[language] ?? '';
      });
    },
    autoPickLanguages(userLanguage: string) {
      if (this.completedTranslations.length && this.completedTranslations.indexOf(userLanguage) === -1) {
        this.sourceLanguage = this.completedTranslations[0];
        this.targetLanguage = userLanguage;
      } else {
        this.sourceLanguage = userLanguage;
        this.targetLanguage = this.notCompletedTranslations.find((language) => language !== userLanguage);
      }
    },
    updateValue(key: string, value: string) {
      const translations = { ...this.value };
      translations[key][this.targetLanguage] = value;

      this.value = translations;
    },
    getSlotProps(targetLanguage: string) {
      return {
        source: this.getLanguageTranslations(this.sourceLanguage),
        target: this.getLanguageTranslations(targetLanguage),
        sourceLanguage: this.sourceLanguage,
        targetLanguage,
        activeLanguage: this.targetLanguage,
        update: this.updateValue,
        isSaving: this.isSaving,
        isSaved: this.isSaved,
        isFailed: this.isFailed,
        isActiveLanguage: targetLanguage === this.targetLanguage,
      };
    },
    validateAndUpdateTargetLanguage(language: string) {
      this.$validator.validateAll('translations').then((result) => {
        if (result) {
          this.targetLanguage = language;
          return;
        }

        alert('Please, correct errors before changing the translation target language.');
      });
    },
    save() {
      this.$validator.validateAll('translations').then((result) => {
        if (result) {
          this.$emit('save', this.value);
          return;
        }
      });
    },
    openModal() {
      if (!this.disabled) {
        this.isModalOpen = true;
      }
    },
  },
  watch: {
    'initialValue': {
      handler(value) {
        this.value = { ...value };
        this.isModalOpen = false;
        this.autoPickLanguages(this.sourceLanguage);
      },
      immediate: true,
    },
    'currentProfile.languageCode': {
      handler(value) {
        this.autoPickLanguages(value);
      },
      immediate: true,
    },
  },
});
