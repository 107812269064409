
import Vue from 'vue';
import { mapGetters, mapState, mapActions } from 'vuex';
import { ResourcesMutations, ResourcesActions } from '@/store/modules/resources/types';
import Checkbox from '@/components/controls/checkbox.vue';

export default Vue.extend({
  name: 'ResourceIsFeaturedForm',
  components: {
    Checkbox,
  },
  computed: {
    ...mapState('resources', {
      resource: 'editable',
    }),
    ...mapGetters('resources', {
      isLoading: 'editableIsLoading',
      isSaving: 'editableIsSaving',
    }),
    isFetching() {
      return this.isLoading || this.isSaving;
    },
    featured(): boolean {
      return typeof this.resource !== 'undefined' && Boolean(this.resource.featured) ? true : false;
    },
    training(): boolean {
      return typeof this.resource !== 'undefined' && Boolean(this.resource.training) ? true : false;
    },
  },
  methods: {
    ...mapActions('resources', {
      updateEditable: ResourcesActions.UPDATE_EDITABLE,
    }),
  },
});
