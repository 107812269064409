
import Vue from 'vue';
import { mapGetters, mapActions, mapState } from 'vuex';

import { PublishStatus } from '@/store/types';
import { PractitionersActions } from '@/store/modules/practitioners/types';
import { PracticeStatus } from '@/store/modules/practices/types';
import { ReportsActions } from '@/store/modules/reports/types';

import SubmitButton from '@/components/controls/submit-button.vue';
import PracticeSelectionControl from '@/components/controls/practice-selection.vue';
import Dropdown, { DropdownItems } from '@/components/controls/dropdown.vue';
import CountrySelection from '@/components/controls/country-selection.vue';
import ProvinceSelection from '@/components/controls/province-selection.vue';

export default Vue.extend({
  name: 'PractitionersFilterForm',
  props: {
    selectedItems: {
      type: Array,
      default: () => [] as number[],
    },
  },
  components: {
    SubmitButton,
    PracticeSelectionControl,
    Dropdown,
    CountrySelection,
    ProvinceSelection,
  },
  data() {
    return {
      availableStatuses: {
        [PublishStatus.Pending]: this.$t('Pending review'),
        [PublishStatus.Draft]: this.$t('Draft'),
        [PublishStatus.Published]: this.$t('Published'),
        [PublishStatus.Deleted]: this.$t('Deleted'),
      },
      search: '',
      status: '',
      practice: undefined,
      country: 0,
      state: '',
    };
  },
  computed: {
    ...mapState(['countries']),
    ...mapGetters('practitioners', {
      isLoading: 'listIsLoading',
      isLoaded: 'listIsLoaded',
      isError: 'listIsError',
      isEmpty: 'listIsEmpty',
    }),
    ...mapGetters('user/profile', ['isSuperAdmin', 'isAdmin']),
    dropdownItems() {
      const items: DropdownItems = {};

      if (this.isSuperAdmin) {
        items.report = {
          label: this.$t('Generate Report'),
          icon: 'document',
        };
      }

      items.reset = {
        label: this.$t('Reset Filters'),
        icon: 'close',
      };

      if ((this.isSuperAdmin || this.isAdmin) && this.selectedItems && this.selectedItems.length) {
        items.publish = {
          hasTopBorder: true,
          label: this.$t('Make Published'),
          count: this.selectedItems.length,
          disabled: !this.selectedItems.length,
        };

        items.remove = {
          label: this.$t('Delete'),
          count: this.selectedItems.length,
          disabled: !this.selectedItems.length,
        };
      }

      return items;
    },
    selectedCountryHasStates(): boolean {
      if (!this.country || !this.countries || !this.countries.length) {
        return false;
      }

      const selectedCountry = this.countries.find((item) => item.id === this.country);

      if (!selectedCountry || !selectedCountry.states || !selectedCountry.states.length) {
        return false;
      }

      return true;
    },
  },
  methods: {
    ...mapActions('reports', {
      requestReport: ReportsActions.REQUEST,
    }),
    ...mapActions('practitioners', {
      deletePractitioners: PractitionersActions.BULK_DELETE,
      publishPractitioners: PractitionersActions.BULK_RESTORE,
    }),
    initFilters(): void {
      this.search = this.$router.currentRoute.query.search || '';
      this.status = this.$router.currentRoute.query.status || PracticeStatus.Published;
      this.country = Number(this.$router.currentRoute.query.country_id);
      this.state = this.$router.currentRoute.query.state || '';

      if (Number.isNaN(this.country) || this.country < 1) {
        this.country = 0;
        this.state = '';
      }

      let newPracticeValue;

      if (this.$router.currentRoute.query.practice_id) {
        newPracticeValue = Number(this.$router.currentRoute.query.practice_id);

        if (!newPracticeValue || Number.isNaN(newPracticeValue)) {
          newPracticeValue = undefined;
        }
      }

      if (this.practice !== newPracticeValue) {
        this.practice = newPracticeValue;
      }
    },
    applyFilters(): void {
      const { sort, direction, ...queryParams } = this.$route.query;

      this.$router.push({
        params: {
          page: 1,
        },
        query: {
          ...queryParams,
          search: this.search,
          status: this.status,
          practice_id: this.practice,
          country_id: this.country,
          state: this.state,
        },
      });
    },
    handleDropdownClick(action: string): void {
      if ('reset' === action) {
        this.$router.push({
          params: {
            page: 1,
          },
          query: {},
        });
      }

      if ('report' === action) {
        this.requestReport();
      }

      if ('publish' === action) {
        this.publishPractitioners({ ids: this.selectedItems });
      }

      if ('remove' === action) {
        this.deletePractitioners({ ids: this.selectedItems });
      }
    },
  },
  created(): void {
    this.initFilters();
  },
  watch: {
    $route() {
      this.initFilters();
    },
  },
});
