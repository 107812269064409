import { GetterTree } from 'vuex';

import { RootState, ListStatus } from '@/store/types';
import { ResourcesState, ResourcesStatus } from '@/store/modules/resources/types';
import { createEditableGetters } from '@/store/utils/editable';

const editableGetters = createEditableGetters<ResourcesState>('editableStatus', 'editable', 'initialEditable');

export const moduleGetters: GetterTree<ResourcesState, RootState> = {
  /**
   * List
   */
  listIsLoading: (state) => (state.listStatus === ResourcesStatus.Loading ? true : false),
  listIsLoaded: (state) => (state.listStatus === ResourcesStatus.Loaded ? true : false),
  listIsError: (state) => (state.listStatus === ResourcesStatus.Failed ? true : false),
  listIsEmpty: (state) =>
    !(state.resourcesList && state.resourcesList.length > 0 && state.listStatus !== ResourcesStatus.Loading)
      ? true
      : false,

  /**
   * Featured
   */
  featuredIsLoading: (state) => state.featuredStatus === ResourcesStatus.Loading,
  featuredIsLoaded: (state) => state.featuredStatus === ResourcesStatus.Loaded,
  featuredIsFailed: (state) => state.featuredStatus === ResourcesStatus.Failed,
  featuredIsEmpty: (state, getters) => getters.featuredIsLoaded && state.featured.length < 1,
  featuredIsInitial: (state) => state.featuredStatus === ResourcesStatus.Initial,

  /**
   * View
   */
  currentIsLoading: (state) => (state.currentStatus === ResourcesStatus.Loading ? true : false),
  currentIsLoaded: (state) => (state.currentStatus === ResourcesStatus.Loaded ? true : false),
  currentIsError: (state) => (state.currentStatus === ResourcesStatus.Failed ? true : false),
  hasDownloads: (state) =>
    state.currentResource && state.currentResource.files && state.currentResource.files.length > 1 ? true : false,
  completeAssetTrainingIsLoading: (state) => state.completeAssetTrainingStatus === ListStatus.Loading,
  unresolvedTrainingFiles: (state) =>
    state.currentResource && state.currentResource.training
      ? state.currentResource.files.filter((item) => !item.trainingComplete)
      : [],
  unresolvedTrainingLinks: (state) =>
    state.currentResource && state.currentResource.training
      ? state.currentResource.links.filter((item) => !item.trainingComplete)
      : [],
  areAllTrainingAssetsCompleted: (state, getters) =>
    getters.unresolvedTrainingFiles.length === 0 && getters.unresolvedTrainingLinks.length === 0,

  /**
   * Recently viewed
   */
  recentlyViewedIsLoaded: (state) => state.recentlyViewedStatus === ResourcesStatus.Loaded,
  recentlyViewedIsLoading: (state) => state.recentlyViewedStatus === ResourcesStatus.Loading,
  recentlyViewedIsEmpty: (state, getters) => getters.recentlyViewedIsLoaded && state.recentlyViewed.length < 1,
  recentlyViewedIsFailed: (state) => state.recentlyViewedStatus === ResourcesStatus.Failed,
  /**
   * Edit
   */
  editableIsLoading: editableGetters.isLoading,
  editableIsLoaded: editableGetters.isLoaded,
  editableIsSaving: editableGetters.isSaving,
  editableIsSaved: editableGetters.isSaved,
  editableIsFailed: editableGetters.isFailed,
  editableHasUnsavedChanges: editableGetters.hasUnsavedChanges,
  editableIsFeatured: (state) => typeof state.editable !== 'undefined' && Boolean(state.editable.featured),
};

export default moduleGetters;
