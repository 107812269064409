
import Vue from 'vue';

export default Vue.extend({
  name: 'ModalWithSidebarLayout',
  props: {
    closable: {
      type: Boolean,
      default: true,
    },
    modalClass: {
      default: undefined,
    },
    small: {
      type: Boolean,
      default: false,
    },
    large: {
      type: Boolean,
      default: false,
    },
    hasFooter: {
      type: Boolean,
      default: true,
    },
    isTranslateModal: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    modalClasses() {
      return [
        'c-modal',
        {
          'c-modal--width-md': !this.small && !this.large,
          'c-modal--width-sm': this.small,
          'c-modal--width-lg': this.large,
        },
        this.modalClass,
      ];
    },
  },
  methods: {
    close() {
      if (this.closable) {
        this.$emit('close');
      }
    },
  },
});
