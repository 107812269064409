import Vue from 'vue';
import { MutationTree } from 'vuex';

import { ListStatus, EditableStatus } from '@/store/types';
import Pagination from '@/store/models/pagination';
import { updateEditable } from '@/store/utils/editable';

import { UsersState, UsersMutations } from './types';
import User from '@/store/models/user';

const mutations: MutationTree<UsersState> = {
  /**
   * List
   */
  [UsersMutations.SET_LIST](state, payload: User[]) {
    state.list = payload;
  },
  [UsersMutations.SET_LIST_STATUS](state, payload: ListStatus) {
    state.listStatus = payload;
  },
  [UsersMutations.SET_LIST_PAGINATION](state, payload: Pagination) {
    state.listPagination = payload;
  },

  /**
   * Edit
   */
  [UsersMutations.SET_EDITABLE](state, user: User) {
    Vue.set(state, 'editable', user);
    Vue.set(state, 'initialEditable', User.clone(user));
  },
  [UsersMutations.SET_EDITABLE_STATUS](state, editableStatus: EditableStatus) {
    state.editableStatus = editableStatus;
  },
  [UsersMutations.UPDATE_EDITABLE]: updateEditable<UsersState>('editable'),
  [UsersMutations.SET_SENDING_WELCOME](state, payload: null | boolean) {
    state.sendingWelcome = payload;
  },
  [UsersMutations.UPDATE_INITIAL_EDITABLE]: updateEditable<UsersState>('initialEditable'),
};

export default mutations;
