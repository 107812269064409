import { GetterTree } from 'vuex';
import isEqual from 'lodash/isEqual';
import { RootState, EditableStatus, ListStatus } from '@/store/types';
import { PracticesState } from '@/store/modules/practices/types';

export const moduleGetters: GetterTree<PracticesState, RootState> = {
  listIsLoading: (state) => state.listStatus === ListStatus.Loading,
  listIsLoaded: (state) => state.listStatus === ListStatus.Loaded,
  listIsError: (state) => state.listStatus === ListStatus.Failed,
  listIsEmpty: (state, getters) => getters.listIsLoaded && !state.list.length,
  editableIsLoading: (state) => state.editableStatus === EditableStatus.Loading,
  editableIsLoaded: (state) => state.editableStatus === EditableStatus.Loaded,
  editableIsSaving: (state) => state.editableStatus === EditableStatus.Saving,
  editableIsSaved: (state) => state.editableStatus === EditableStatus.Saved,
  editableIsError: (state) => state.editableStatus === EditableStatus.Failed,
  editableIsReady: (state, getters) => getters.editableIsLoaded || getters.editableIsSaved,
  editableHasUnsavedChanges: (state) => !isEqual(state.editablePractice, state.currentPractice),
  userPracticeIsLoading: (state) => state.userPracticeStatus === ListStatus.Loading,
  userPracticeIsLoaded: (state) => state.userPracticeStatus === ListStatus.Loaded && !!state.userPractice,
  userPracticeIsEmpty: (state) => state.userPracticeStatus === ListStatus.Loaded && !state.userPractice,
  userPracticeIsError: (state) => state.userPracticeStatus === ListStatus.Failed,
  currentPracticeId: (state, getters) =>
    getters.editableIsReady && state.currentPractice ? state.currentPractice.id : undefined,
};

export default moduleGetters;
