
import Vue from 'vue';
import { mapState, mapGetters } from 'vuex';
import mixins from 'vue-typed-mixins';

import { formatNumber } from '@/i18n';

import { ReportStatus, ReportType } from '@/store/modules/reports/report';

import tableViewMixin from '@/components/mixins/table-view';
import NotificationBanner from '@/components/blocks/notification-banner.vue';
import TableListSkeleton from '@/components/skeletons/table-list.vue';
import PaginationControl from '@/components/controls/pagination.vue';
import Timeago from '@/components/blocks/timeago.vue';

export default mixins(tableViewMixin).extend({
  name: 'ReportsView',
  components: {
    NotificationBanner,
    TableListSkeleton,
    PaginationControl,
    Timeago,
  },
  computed: {
    ...mapState('reports', {
      reports: 'list',
      pagination: 'listPagination',
    }),
    ...mapGetters('reports', {
      isLoading: 'listIsLoading',
      isLoaded: 'listIsLoaded',
      isEmpty: 'listIsEmpty',
    }),
    ReportStatus() {
      return ReportStatus;
    },
    ReportStatuses() {
      return {
        [ReportStatus.Processing]: this.$t('Processing'),
        [ReportStatus.Processed]: this.$t('Completed'),
        [ReportStatus.Failed]: this.$t('Failed'),
      };
    },
    ReportTypes() {
      return {
        [ReportType.Practice]: this.$t('Practice'),
        [ReportType.User]: this.$t('User'),
        [ReportType.Location]: this.$t('Location'),
        [ReportType.Practitioner]: this.$t('Practitioner'),
        [ReportType.Resource]: this.$t('Resource'),
        [ReportType.Product]: this.$t('Product'),
        [ReportType.Consultation]: this.$t('Consultation Request'),
        [ReportType.Download]: this.$t('Downloads'),
      };
    },
  },
  methods: {
    formatNumber,
  },
});
