import Vue from 'vue';

export const placeholderRegExp = /(\%\{\d+\}\%)/;

export default Vue.extend({
  name: 'i18n',
  functional: true,
  props: {
    tag: {
      type: String,
      default: 'span',
    },
    translation: {
      type: String,
      required: true,
    },
  },
  render(h, { props, data, children }) {
    const translation = props.translation;
    const tchildren = translation.split(placeholderRegExp).map((placeholder) => {
      if (!placeholder.match(placeholderRegExp)) {
        return placeholder;
      }

      const childIndex = Number(placeholder.substring(2, placeholder.length - 2).trim());
      if (Number.isNaN(childIndex) || !Number.isInteger(childIndex) || children.length < childIndex + 1) {
        return placeholder;
      }

      return children[childIndex];
    });

    return h(props.tag, data, tchildren);
  },
});
