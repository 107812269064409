import actions from './actions';
import mutations from './mutations';
import getters from './getters';
import { PractitionersState, PractitionerSpecialty } from '@/store/modules/practitioners/types';
import { RootState, ListStatus, EditableStatus } from '@/store/types';
import { Module } from 'vuex';
import Pagination from '@/store/models/pagination';

const specialtiesList: PractitionerSpecialty[] = [
  { name: 'None', value: '' },
  { name: 'Anesthesiologist', value: 'Anesthesiologist' },
  { name: 'Cardio Vascular', value: 'Cardio Vascular' },
  { name: 'Cosmetic Surgeon', value: 'Cosmetic Surgeon' },
  { name: 'Dermatologist', value: 'Dermatologist' },
  { name: 'Emergency Medicine', value: 'Emergency Medicine' },
  { name: 'Ent/Facial', value: 'Ent/Facial' },
  { name: 'Family Practice', value: 'Family Practice' },
  { name: 'General Practice', value: 'General Practice' },
  { name: 'General Surgeon', value: 'General Surgeon' },
  { name: 'Internal Medicine', value: 'Internal Medicine' },
  { name: 'OB/GYN', value: 'OB/GYN' },
  { name: 'Plastic Surgeon', value: 'Plastic Surgeon' },
  { name: 'Podiatrist', value: 'Podiatrist' },
  { name: 'Spas/Anti-Aging', value: 'Spas/Anti-Aging' },
  { name: 'Weight Loss', value: 'Weight Loss' },
  { name: 'Other', value: 'Other' },
];

export const getInitialState = (): PractitionersState => ({
  /**
   * List
   */
  listStatus: ListStatus.Loading,
  pagination: new Pagination(),
  practitionersList: [],

  /**
   * Menu List
   */
  menuListStatus: ListStatus.Loading,
  menuPractitionersList: [],
  menuListTotalItems: 0,

  /**
   * Pending list
   */
  pendingListStatus: ListStatus.Loading,
  pendingList: [],
  pendingListTotalItems: 0,

  /**
   * Practitioners Hours list
   */
  practitionersHoursList: [],
  practitionersHoursListStatus: ListStatus.Initial,

  /**
   * Edit
   */
  editableStatus: EditableStatus.Loading,
  specialtiesList,
});

export const state: PractitionersState = getInitialState();

const practitioners: Module<PractitionersState, RootState> = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

export default practitioners;
