
import Vue from 'vue';
import { mapState } from 'vuex';

import i18n from '@/i18n';
import api from '@/api';
import SelectInput from './select-input.vue';
import { prepareResourcesFilters } from '@/store/utils/filters';

export default Vue.extend({
  name: 'ResourcesLanguagePicker',
  inheritAttrs: false,
  inject: ['$validator'],
  data() {
    return {
      languageCounts: {} as Record<string, number>,
      cancelTokenSource: undefined,
      timeoutId: undefined,
    };
  },
  components: {
    SelectInput,
  },
  props: {
    value: {
      type: String,
    },
    label: {
      type: String,
      default: i18n.t('Language'),
    },
    name: {
      type: String,
      default: 'language',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    disabledOptions: {
      type: Array as () => string[],
      default: () => [] as string[],
    },
  },
  computed: {
    ...mapState(['languages']),
  },
  methods: {
    fetchCounts(query: Record<string, any>) {
      if (this.cancelTokenSource) {
        this.cancelTokenSource.cancel();
        this.cancelTokenSource = undefined;
      }

      this.cancelTokenSource = api.getCancelTokenSource();

      const { page, language_code, type, ...params } = prepareResourcesFilters(query);

      // filter languages by route type
      if (this.$route.name === 'clinical-support') {
        params.type = 'clinical-support';
      }

      return api.languages
        .list({ cancelToken: this.cancelTokenSource.token, params })
        .then((response) => {
          const out = {} as Record<string, number>;

          response.data.forEach((item) => {
            out[item.code] = item.asset_count;
          });

          this.languageCounts = out;
        })
        .catch((error) => {
          if (!api.isCancel(error)) {
            throw error;
          }
        });
    },
    debouncedFetchCounts(query: Record<string, any>) {
      if (this.timeoutId) {
        window.clearTimeout(this.timeoutId);
      }

      if (this.cancelTokenSource) {
        this.cancelTokenSource.cancel();
        this.cancelTokenSource = undefined;
      }

      this.timeoutId = window.setTimeout(() => {
        this.timeoutId = undefined;
        this.fetchCounts(query);
      }, 100);
    },
  },
  watch: {
    '$route.query': {
      immediate: true,
      handler(val, oldVal) {
        this.debouncedFetchCounts(val);
      },
    },
  },
});
