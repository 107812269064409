import { ActionTree } from 'vuex';

import api from '@/api';

import { RootState, ErrorMessages, RootActions, EditableStatus, ListStatus } from '@/store/types';
import { SupportState, SupportActions, SupportMutations } from '@/store/modules/support/types';
import FAQ from '@/store/modules/support/faq';

export const actions: ActionTree<SupportState, RootState> = {
  [SupportActions.LOAD_FAQ_LIST]({ commit, dispatch }): Promise<void> {
    commit(SupportMutations.SET_FAQ_LIST_STATUS, ListStatus.Loading);

    return api.settings
      .listFaqs()
      .then((response) => {
        const faqs = response.data.map((faq) => FAQ.fromJSON(faq));
        commit(SupportMutations.SET_FAQ_LIST, faqs);
        commit(SupportMutations.SET_FAQ_LIST_STATUS, ListStatus.Loaded);
      })
      .catch((error) => {
        error.errorMessage = ErrorMessages.DATA_LOAD_ERROR;

        commit(SupportMutations.SET_FAQ_LIST_STATUS, ListStatus.Failed);
        return dispatch(RootActions.ERROR, error, { root: true });
      });
  },

  [SupportActions.LOAD_FAQ_EDITABLE]({ commit, dispatch }): Promise<void> {
    commit(SupportMutations.SET_FAQ_EDITABLE_STATUS, EditableStatus.Loading);

    return api.settings
      .listFaqs()
      .then((response) => response.data)
      .then((faqs) => {
        commit(SupportMutations.SET_FAQ_EDITABLE, { faqs: faqs.map((faq) => FAQ.fromJSON(faq)) });
        commit(SupportMutations.SET_FAQ_EDITABLE_STATUS, EditableStatus.Loaded);
      })
      .catch((error) => {
        error.errorMessage = ErrorMessages.DATA_LOAD_ERROR;

        commit(SupportMutations.SET_FAQ_EDITABLE_STATUS, EditableStatus.Failed);
        return dispatch(RootActions.ERROR, error, { root: true });
      });
  },

  [SupportActions.UPDATE_FAQ_EDITABLE]({ commit }, changes): Promise<void> {
    commit(SupportMutations.UPDATE_FAQ_EDITABLE, changes);
    return Promise.resolve();
  },

  [SupportActions.UPDATE_FAQ_EDITABLE_LIST]({ commit }, payload): Promise<void> {
    commit(SupportMutations.SET_FAQ_EDITABLE, { faqs: payload, updateInitial: false });
    return Promise.resolve();
  },

  [SupportActions.SAVE_FAQ_EDITABLE]({ state, commit }): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (state.faqEditable) {
        return resolve();
      }

      reject(new Error('Invalid data!'));
    })
      .then(async () => {
        commit(SupportMutations.SET_FAQ_EDITABLE_STATUS, EditableStatus.Saving);

        await Promise.all(
          state.faqEditable!.map((faq, index) => {
            if (faq.removeOnServer) {
              if (faq.isLocal) {
                return;
              }

              return api.settings.removeFaq(faq.id as number);
            }

            const data = faq.toJSON('server');
            data.weight = index;

            return api.settings.storeFaq(data);
          }),
        );

        const faqReq = await api.settings.listFaqs();

        commit(SupportMutations.SET_FAQ_EDITABLE, { faqs: faqReq.data.map((faq) => FAQ.fromJSON(faq)) });
        commit(SupportMutations.SET_FAQ_EDITABLE_STATUS, EditableStatus.Saved);
      })
      .catch((error) => {
        commit(SupportMutations.SET_FAQ_EDITABLE_STATUS, EditableStatus.Failed);
        throw error;
      });
  },
  [SupportActions.SAVE_FAQ_TRANSLATIONS](
    { state, commit, dispatch },
    { id, translations }: { id: number; translations: object },
  ): Promise<void> {
    return new Promise<FAQ>((resolve, reject) => {
      if (id && state.faqEditable) {
        const faq = state.faqEditable.find((item) => item.id === id);

        if (faq) {
          return resolve(faq);
        }
      }

      reject(new Error('Invalid data!'));
    })
      .then(async (faq) => {
        commit(SupportMutations.SET_FAQ_TRANSLATIONS_STATUS, EditableStatus.Saving);
        await api.settings.translateFaq(id, translations);
        await dispatch(SupportActions.UPDATE_FAQ_EDITABLE, { id, translations: faq.translations });
        commit(SupportMutations.SET_FAQ_TRANSLATIONS_STATUS, EditableStatus.Saved);
      })
      .catch((error) => {
        error.errorMessage = ErrorMessages.DATA_LOAD_ERROR;

        commit(SupportMutations.SET_FAQ_TRANSLATIONS_STATUS, EditableStatus.Failed);
        return dispatch(RootActions.ERROR, error, { root: true });
      });
  },
};

export default actions;
