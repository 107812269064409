import pickBy from 'lodash/pickBy';
import router from '@/router';
import { isString, isNumber } from '@/utils/is';
import { PublishStatus } from '@/store/types';
import { ResourcesURLFilters, ResourcesRequestFilters } from '@/store/modules/resources/types';
import { UserStatus } from '@/store/models/user';
import { EventStatus } from '@/store/modules/events/types';
import { ConsultationRequestStatus } from '@/store/modules/consultations/types';

export const prepareResourcesFilters = (filters: ResourcesURLFilters = {}): ResourcesRequestFilters => {
  let products: number[] = [];
  let treatments: number[] = [];
  let categories: number[] = [];
  let page: number = 1;
  let search: string | undefined;
  let country: number | undefined;
  let language: string | undefined;

  if (filters.product) {
    products = [Number(filters.product)];
  } else if (filters.products && Array.isArray(filters.products) && filters.products.length) {
    products = filters.products.map(Number).filter(Number.isFinite);
  }

  const {
    treatments: treatmentsFilter,
    categories: categoriesFilter,
    page: requestPage,
    search: searchFilter,
    country: countryFilter,
    language: languageFilter,
    ...otherFilters
  } = filters;

  if (products.length) {
    if (treatmentsFilter) {
      if (isString(treatmentsFilter) || isNumber(treatmentsFilter)) {
        treatments = [Number(treatmentsFilter)];
      } else if (Array.isArray(treatmentsFilter) && treatmentsFilter.length) {
        treatments = treatmentsFilter.map((id) => Number(id));
      }
    }
  }

  if (categoriesFilter) {
    if (isString(categoriesFilter) || isNumber(categoriesFilter)) {
      categories = [Number(categoriesFilter)];
    } else if (Array.isArray(categoriesFilter) && categoriesFilter.length) {
      categories = categoriesFilter.map((id) => Number(id));
    }
  }

  if (requestPage) {
    page = Number(requestPage);

    if (Number.isNaN(page)) {
      page = 1;
    }
  }

  if (searchFilter) {
    search = String(searchFilter).trim();

    if (!search.length) {
      search = undefined;
    }
  }

  if (countryFilter) {
    country = Number(countryFilter);

    if (Number.isNaN(country) && country < 1) {
      country = undefined;
    }
  }

  if (languageFilter) {
    language = String(languageFilter).trim();

    if (!language.length) {
      language = undefined;
    }
  }

  // pickBy will remove all undefined properties from the object
  return pickBy({
    products,
    treatments,
    categories,
    page,
    search,
    country_id: country,
    language_code: language,
    ...otherFilters,
  });
};

export const prepareFilters = (filters: { [key: string]: any } = {}, routeName = '') => {
  if (!routeName) {
    if (router.currentRoute.name) {
      routeName = router.currentRoute.name;
    }
  }

  if ('resources' === routeName) {
    return prepareResourcesFilters(filters);
  }

  if ('practitioners' === routeName) {
    const { status = PublishStatus.Published, ...other } = filters;
    return {
      status: status === 'any' ? '' : status,
      ...other,
    };
  }

  if ('users' === routeName) {
    const { status = UserStatus.Active, ...other } = filters;
    return {
      status: status === 'any' ? '' : status,
      ...other,
    };
  }

  if ('events' === routeName) {
    const { status = EventStatus.Active, ...other } = filters;
    return {
      status: status === 'any' ? '' : status,
      ...other,
    };
  }

  if ('consultations' === routeName) {
    const { type, status = ConsultationRequestStatus.Active, ...other } = filters;
    return {
      ...other,
      status: status === 'any' ? '' : status,
      qualified: type === 'less-qualified' ? false : undefined,
    };
  }

  if ('less-qualified-consultations' === routeName) {
    return {
      ...filters,
      qualified: false,
    };
  }

  return { ...filters };
};

export function prepareStatusFilter<F extends { status?: 'any' | '' | '0' | '1' }>(filters: F) {
  const { status = '1', ...other } = filters;

  return {
    status: status === 'any' ? '' : status,
    ...other,
  };
}
