
import Vue from 'vue';

export default Vue.extend({
  name: 'FloatingLabelControl',
  props: {
    label: {
      type: String,
      required: true,
    },
    for: {
      type: String,
    },
    focused: {
      type: Boolean,
      default: false,
    },
    active: {
      type: Boolean,
      default: false,
    },
  },
});
