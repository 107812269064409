/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'play': {
    width: 20,
    height: 24,
    viewBox: '0 0 56.69 68.03',
    data: '<path pid="0" d="M54.78 29.92L5.46.57A3.72 3.72 0 003.52 0a3.53 3.53 0 00-3.5 3.54v61A3.53 3.53 0 003.52 68a4.12 4.12 0 002-.6l49.26-29.29a5.34 5.34 0 000-8.19z"/>'
  }
})
