import { JSONModel } from '@/store/models/jsonModel';

export interface DownloadJSON {
  id: number;
  asset_id: number;
  asset_type: string;
  display: string;
  fid: number;
  name: string;
  mime: string;
  uri: string;
  size: number;
  title: string;
  alt: string;
  preview: string;
  created_at: string;
  updated_at: string;
  user_id: number;
  asset_title: string;
  event_log_id: number;
  downloads_interval_count: number;
  s3: string;
  thumbnail: string;
  downloads_total_count: number;
  downloads_weekly_count: number;
}

export default class Download extends JSONModel<DownloadJSON> {
  public id: number = 0;
  public assetId: number = 0;
  public title: string = '';
  public assetTitle: string = '';

  public downloads: number = 0;
  public thumbnail?: string;

  public fromJSON(json: Partial<DownloadJSON>) {
    if (json.id) {
      this.id = Number(json.id);
    }

    if (json.asset_id) {
      this.assetId = Number(json.asset_id);
    }

    if (json.title) {
      this.title = String(json.title);
    }

    if (json.asset_title) {
      this.assetTitle = String(json.asset_title);
    }

    if (json.downloads_interval_count) {
      this.downloads = Number(json.downloads_interval_count);
    }

    if (json.thumbnail) {
      this.thumbnail = String(json.thumbnail);
    }
  }
}
