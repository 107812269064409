import { GetterTree } from 'vuex';
import { RootState } from '@/store/types';
import { NotificationType, NotificationsState } from '@/store/modules/notifications/types';

export const moduleGetters: GetterTree<NotificationsState, RootState> = {
  isSuccess: (state) => (state.type === NotificationType.Success ? true : false),
  isNotice: (state) => (state.type === NotificationType.Notice ? true : false),
  isWarning: (state) => (state.type === NotificationType.Warning ? true : false),
  isError: (state) => (state.type === NotificationType.Error ? true : false),
  isVisible: (state) => state.show,
  hasList: (state) => (state.message && state.message.errorList && state.message.errorList.length > 0 ? true : false),
  hasTitle: (state) => (state.message && state.message.title && state.message.title.length > 0 ? true : false),
  hasBody: (state) => (state.message && state.message.body && state.message.body.length > 0 ? true : false),
};

export default moduleGetters;
