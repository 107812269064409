/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'medicine': {
    width: 24.25,
    height: 20.25,
    viewBox: '0 0 24.25 20.25',
    data: '<g data-name="Layer 2"><g data-name="Outline Icons"><path pid="0" d="M13.63 17.25h-3a.63.63 0 01-.62-.62v-2.38H7.63a.63.63 0 01-.63-.62v-3a.63.63 0 01.63-.63H10V7.63a.63.63 0 01.63-.63h3a.63.63 0 01.63.63V10h2.38a.63.63 0 01.63.63v3a.63.63 0 01-.62.63h-2.4v2.38a.63.63 0 01-.62.61zM11.25 16H13v-2.37a.63.63 0 01.63-.62H16v-1.76h-2.37a.63.63 0 01-.62-.62V8.25h-1.76v2.38a.63.63 0 01-.62.63H8.25V13h2.38a.63.63 0 01.63.63z"/><path pid="1" d="M21.63 20.25h-19A2.63 2.63 0 010 17.63v-12A2.63 2.63 0 012.63 3h19a2.63 2.63 0 012.63 2.63v12a2.63 2.63 0 01-2.63 2.62zm-19-16a1.38 1.38 0 00-1.38 1.38v12A1.38 1.38 0 002.63 19h19A1.38 1.38 0 0023 17.63v-12a1.38 1.38 0 00-1.37-1.37z"/><path pid="2" d="M15.63 4.25a.63.63 0 01-.63-.62v-1.5a.88.88 0 00-.87-.87h-4a.88.88 0 00-.87.88v1.5A.63.63 0 018 3.63v-1.5A2.13 2.13 0 0110.13 0h4a2.13 2.13 0 012.13 2.13v1.5a.63.63 0 01-.63.62z"/></g></g>'
  }
})
