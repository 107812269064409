import { JSONModel, toJSONContext } from '@/store/models/jsonModel';
import { PracticeStatus } from './types';
import Image, { ImageJSON } from '@/store/models/image';
import Taxonomy, { TaxonomyJSON } from '@/store/models/taxonomy';
import UserRelationship, { UserRelationshipJSON } from '@/store/modules/users/user-relationship';
import ProductRelationship, { ProductRelationshipJSON } from '@/store/modules/products/product-relationship';
import LocationRelationship, { LocationRelationshipJSON } from '@/store/modules/locations/location-relationship';
import PractitionerRelationship, {
  PractitionerRelationshipJSON,
} from '@/store/modules/practitioners/practitioner-relationship';
import { PracticeRelationshipJSON } from './practice-relationship';

const availableStatuses: number[] = Object.keys(PracticeStatus).map((key) => PracticeStatus[key]);

export interface PracticeJSON extends PracticeRelationshipJSON {
  description?: string;
  created_at: string;
  updated_at: string;
  index_pending: number;
  products_count: number;
  users_count: number;
  practitioners_count: number;
  listings_count: number;
  users: UserRelationshipJSON[];
  listings?: LocationRelationshipJSON[];
  products: ProductRelationshipJSON[];
  enhancements?: TaxonomyJSON[];
  accessories?: TaxonomyJSON[];
  practitioners?: PractitionerRelationshipJSON[];
  logo?: ImageJSON | ImageJSON[];
  resource_products: ProductRelationshipJSON[];
  country_id: number;
  account_id: string;
  liine_tracking: number;
}

export default class Practice extends JSONModel<PracticeJSON> {
  public id: number = 0;
  public status: PracticeStatus = PracticeStatus.Published;
  public title: string = '';
  public description: string = '';
  public createdAt: string = '';
  public updatedAt: string = '';
  public pending: number = 0;
  public productsCount: number = 0;
  public usersCount: number = 0;
  public practitionersCount: number = 0;
  public locationsCount: number = 0;
  public users: UserRelationship[] = [];
  public products: ProductRelationship[] = [];
  public locations: LocationRelationship[] = [];
  public enhancements: Taxonomy[] = [];
  public accessories: Taxonomy[] = [];
  public practitioners: PractitionerRelationship[] = [];
  public logo?: Image;
  public resourceProducts: ProductRelationship[] = [];
  public countryId: number = 0;
  public liineTracking: number = 0;
  public accountId: string = '';

  public get thumbnail(): string | undefined {
    if (this.logo && this.logo.thumbnail) {
      return this.logo.thumbnail;
    }

    return undefined;
  }

  public toJSON(context: toJSONContext = 'server'): PracticeJSON {
    const productsIds = this.products.map(({ id }) => id);

    return {
      id: this.id,
      status: this.status,
      title: this.title,
      description: this.description,
      created_at: this.createdAt,
      updated_at: this.updatedAt,
      index_pending: this.pending,
      products_count: this.productsCount,
      users_count: this.usersCount,
      practitioners_count: this.practitionersCount,
      listings_count: this.locationsCount,
      users: this.users.map((item) => item.toJSON(context)),
      products: this.products.map((item) => item.toJSON(context)),
      listings: this.locations.map((item) => item.toJSON(context)),
      enhancements: this.enhancements.map((item) => item.toJSON(context)),
      accessories: this.accessories.map((item) => item.toJSON(context)),
      practitioners: this.practitioners.map((item) => item.toJSON(context)),
      logo: this.logo ? this.logo.toJSON(context) : undefined,
      resource_products: this.resourceProducts
        // Filter out products from products field.
        .filter(({ id }) => productsIds.indexOf(id) === -1)
        .map((item) => item.toJSON(context)),
      country_id: this.countryId,
      account_id: this.accountId,
      liine_tracking: this.liineTracking,
    };
  }

  public fromJSON(json: Partial<PracticeJSON>) {
    this.id = Number(json.id);

    if (typeof json.status !== 'undefined' && availableStatuses.indexOf(json.status) !== -1) {
      this.status = json.status;
    }

    this.title = String(json.title);

    if (json.description) {
      this.description = String(json.description);
    }

    this.createdAt = String(json.created_at);
    this.updatedAt = String(json.updated_at);

    this.pending = Number(json.index_pending);
    this.productsCount = Number(json.products_count);
    this.usersCount = Number(json.users_count);
    this.practitionersCount = Number(json.practitioners_count);
    this.locationsCount = Number(json.listings_count);

    if (json.users && Array.isArray(json.users) && json.users.length) {
      this.users = json.users.map((item: object) => UserRelationship.fromJSON(item));
    }

    if (json.practitioners && Array.isArray(json.practitioners) && json.practitioners.length) {
      this.practitioners = json.practitioners.map((item: object) => PractitionerRelationship.fromJSON(item));
    }

    if (json.listings && Array.isArray(json.listings) && json.listings.length) {
      this.locations = json.listings.map((item: object) => LocationRelationship.fromJSON(item));
    }

    if (json.products && Array.isArray(json.products) && json.products.length) {
      this.products = json.products.map((item: object) => ProductRelationship.fromJSON(item));
    }

    if (json.enhancements && Array.isArray(json.enhancements) && json.enhancements.length) {
      this.enhancements = json.enhancements.map((item: object) => Taxonomy.fromJSON(item));
    }

    if (json.accessories && Array.isArray(json.accessories) && json.accessories.length) {
      this.accessories = json.accessories.map((item: object) => Taxonomy.fromJSON(item));
    }

    if (json.logo) {
      this.logo = Image.fromJSON(Array.isArray(json.logo) ? json.logo[0] : json.logo);
    }

    if (json.resource_products && Array.isArray(json.resource_products) && json.resource_products.length) {
      this.resourceProducts = json.resource_products.map((item: object) => ProductRelationship.fromJSON(item));
    }

    if (json.country_id) {
      this.countryId = Number(json.country_id);
    }

    if (json.liine_tracking) {
      this.liineTracking = Number(json.liine_tracking);
    }

    if (json.account_id) {
      this.accountId = String(json.account_id);
    }
  }
}
