
import Vue from 'vue';

import FormInput from '@/components/controls/form-input.vue';
import SelectInput from '@/components/controls/select-input.vue';
import CountrySelection from '@/components/controls/country-selection.vue';
import SubmitButton from '@/components/controls/submit-button.vue';

export default Vue.extend({
  name: 'GDPRForm',
  $_veeValidate: {
    validator: 'new', // new validator scope
  },
  components: {
    FormInput,
    SelectInput,
    CountrySelection,
    SubmitButton,
  },
  props: {
    isSuccess: {
      type: Boolean,
    },
    isPending: {
      type: Boolean,
    },
    isFailed: {
      type: Boolean,
    },
    submitError: {
      type: String,
    },
  },
  data() {
    return {
      country_id: 0,
      type: 'Privacy rights request',
      details: '',
      hasFormError: false,
      formError: '',
    };
  },
  computed: {
    hasError() {
      return (this.submitError && this.submitError.length) || this.hasFormError;
    },
    error() {
      return this.submitError && this.submitError.length
        ? this.submitError
        : this.hasFormError
        ? this.$t('Please correct the errors noted next to each field and try again.')
        : '';
    },
  },
  methods: {
    submit() {
      this.$validator.validateAll().then((result) => {
        this.hasFormError = false;

        if (result) {
          this.$emit('submit', {
            country_id: this.country_id,
            type: this.type,
            details: this.details,
          });
        } else {
          this.hasFormError = true;
        }
      });
    },
  },
});
