
import Vue from 'vue';
import { mapState, mapGetters, mapActions } from 'vuex';

import ProductSelection from '@/components/controls/product-selection.vue';
import Modal from '@/components/layout/modal.vue';
import FormInput from '@/components/controls/form-input.vue';
import SubmitButton from '@/components/controls/submit-button.vue';
import NotificationBanner from '@/components/blocks/notification-banner.vue';
import Autocomplete, { AutocompleteOption } from '@/components/controls/autocomplete.vue';
import TranslationsControl from '@/components/controls/translations.vue';

import { ProductsActions } from '@/store/modules/products/types';
import ProductRelationship from '@/store/modules/products/product-relationship';
import Taxonomy from '@/store/models/taxonomy';
import { EditableStatus } from '@/store/types';

export default Vue.extend({
  name: 'EditAccessoryView',
  components: {
    Modal,
    FormInput,
    SubmitButton,
    NotificationBanner,
    ProductSelection,
    Autocomplete,
    TranslationsControl,
  },
  props: {
    add: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      productsInput: '',
      treatmentsInput: '',
    };
  },
  computed: {
    ...mapGetters(['isDirect']),
    ...mapState('products', {
      translationsStatus: 'accessoryTranslationsStatus',
      accessory: 'accessory',
      productsTree: 'tree',
    }),
    ...mapGetters('products', {
      isLoading: 'accessoryIsLoading',
      isLoaded: 'accessoryIsLoaded',
      isSaving: 'accessoryIsSaving',
      isSaved: 'accessoryIsSaved',
      isFailed: 'accessoryIsFailed',
      hasUnsavedChanges: 'accessoryHasUnsavedChanges',
    }),
    ...mapState('treatments', {
      treatments: 'allTreatments',
    }),
    getProducts(): AutocompleteOption[] {
      let productId = 0;

      if (this.accessory.product) {
        productId = this.accessory.product.id;
      }

      return this.productsTree
        .filter((product) => productId !== product.id)
        .map((product) => ({
          label: product.title,
          value: product.id,
        }))
        .filter((option) => option.label.toLowerCase().indexOf(this.productsInput.toLowerCase()) > -1);
    },
    getTreatments(): AutocompleteOption[] {
      const ids = this.accessory.treatments.map(({ id }) => id);

      return this.treatments
        .filter(({ id }) => ids.indexOf(id) === -1)
        .map(({ id, title }) => ({
          label: title,
          value: id,
        }))
        .filter(({ label }) => label.toLowerCase().indexOf(this.treatmentsInput.toLowerCase()) > -1);
    },
  },
  methods: {
    ...mapActions('products', {
      updateAccessory: ProductsActions.UPDATE_ACCESSORY,
      saveAccessory: ProductsActions.SAVE_ACCESSORY,
      saveTranslations: ProductsActions.SAVE_ACCESSORY_TRANSLATIONS,
    }),
    close(): void {
      if (this.hasUnsavedChanges) {
        const answer = window.confirm(this.$t('Do you really want to leave? You have unsaved changes!'));

        if (answer) {
          this.goBack();
        }
      } else {
        this.goBack();
      }
    },
    goBack(): void {
      if (this.isDirect) {
        this.$router.push({
          name: 'accessories',
        });
      } else {
        this.$router.back();
      }
    },
    selectProduct(productId?: number) {
      let product;

      if (productId) {
        product = this.productsTree.find((item) => item.id === productId);
      }

      if (!product) {
        this.updateAccessory({ product: null });
        return;
      }

      this.updateAccessory({
        product: ProductRelationship.fromJSON(product.toJSON()),
      });
    },
    addTreatment(option: AutocompleteOption) {
      const treatments = [...this.accessory.treatments];
      const treatment = this.treatments.find((item) => item.id === option.value);
      if (!treatment) {
        return;
      }

      treatments.push(Taxonomy.fromJSON(treatment.toJSON()));

      this.updateAccessory({ treatments });
    },
    removeTreatment(treatmentId: number) {
      const treatments = this.accessory.treatments.filter(({ id }) => id !== treatmentId);

      this.updateAccessory({ treatments });
    },
  },
});
