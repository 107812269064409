/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'pencil': {
    width: 23.9,
    height: 23.89,
    viewBox: '0 0 23.9 23.89',
    data: '<path pid="0" d="M23.76 5.34L18.55.13a.5.5 0 00-.68 0L2.23 15.78a.34.34 0 00-.11.18l-2.1 7.32a.48.48 0 00.46.66h.13l7.34-2.15a.72.72 0 00.19-.11L23.76 6.02a.48.48 0 000-.68zm-16 15.31l-4.52-4.54 10.8-10.8 4.54 4.54zm-5-3.62l4.07 4.06-5.65 1.63zm16.49-7.85l-4.54-4.54 3.5-3.5 4.54 4.54z"/>'
  }
})
