import { JSONModel } from '@/store/models/jsonModel';

export interface LoginJSON {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  logins: number;
  practice_id: number;
  practice_title: string;
}

export default class Login extends JSONModel<LoginJSON> {
  public id: number = 0;
  public userId: number = 0;
  public userEmail: string = '';
  public firstName: string = '';
  public lastName: string = '';
  public logins: number = 0;
  public practiceId: number = 0;
  public practiceTitle: string = '';

  get fullName(): string {
    const name = [this.firstName, this.lastName].filter((val) => Boolean(val)).join(' ');

    if (name) {
      return name;
    }

    if (this.userEmail) {
      return this.userEmail;
    }

    return String(this.userId);
  }

  public fromJSON(json: Partial<LoginJSON>) {
    if (json.id) {
      this.id = Number(json.id);
      this.userId = Number(json.id);
    }

    if (json.email) {
      this.userEmail = String(json.email);
    }

    if (json.first_name) {
      this.firstName = String(json.first_name);
    }

    if (json.last_name) {
      this.lastName = String(json.last_name);
    }

    if (json.logins) {
      this.logins = Number(json.logins);
    }

    if (json.practice_id) {
      this.practiceId = Number(json.practice_id);
    }

    if (json.practice_title) {
      this.practiceTitle = String(json.practice_title);
    }
  }
}
