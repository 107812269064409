import config from '@/config';

export const prepareAPIUrl = (url: string) => {
  if (url.indexOf('/api') === 0) {
    return config.API_HOSTNAME + url.substring(4);
  }

  return url;
};

export const fixLineEndings = (value: string) => {
  const windowsLineEndings = new RegExp('\r\n', 'g');

  return value.replace(windowsLineEndings, '\n');
};
