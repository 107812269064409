/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'application': {
    width: 24,
    height: 19,
    viewBox: '0 0 24 19',
    data: '<g data-name="Layer 2"><g data-name="Outline Icons"><path pid="0" d="M21.5 19h-19A2.5 2.5 0 010 16.5v-14A2.5 2.5 0 012.5 0h19A2.5 2.5 0 0124 2.5v14a2.5 2.5 0 01-2.5 2.5zM2.5 1A1.5 1.5 0 001 2.5v14A1.5 1.5 0 002.5 18h19a1.5 1.5 0 001.5-1.5v-14A1.5 1.5 0 0021.5 1z"/><path pid="1" d="M23.5 6H.5a.5.5 0 010-1h23a.5.5 0 010 1zM4 4a1 1 0 111-1 1 1 0 01-1 1zm0-1zM7 4a1 1 0 111-1 1 1 0 01-1 1zm0-1zM10 4a1 1 0 111-1 1 1 0 01-1 1zm0-1z"/></g></g>'
  }
})
