import axios from 'axios';
import isArray from 'lodash/isArray';
import isObject from 'lodash/isObject';
import mapValues from 'lodash/mapValues';
import isPlainObject from 'lodash/isPlainObject';
import isNull from 'lodash/isNull';
import { addBreadcrumb, Severity } from '@sentry/browser';
import { Store } from 'vuex';

import config from '@/config';
import { isAxiosError, isCancel } from './utils';

const mapNullToUndefined = (value: unknown): any => {
  if (isArray(value)) {
    return value.map(mapNullToUndefined);
  }

  if (isObject(value) && isPlainObject(value)) {
    return mapValues(value, mapNullToUndefined);
  }

  if (isNull(value)) {
    return undefined;
  }

  return value;
};

const httpClient = axios.create({
  baseURL: config.API_HOSTNAME,
  responseType: 'json',
});

httpClient.defaults.headers.common = {
  'Authorization': '',
  'Content-Type': 'application/json',
  'Accept': 'application/json',
};

httpClient.interceptors.request.use((value) => {
  const { url, data, method, params } = value;

  addBreadcrumb({
    category: 'api-request',
    message: 'API Request made',
    data: {
      url,
      data,
      method,
      params,
    },
    level: Severity.Info,
  });

  return value;
});

httpClient.interceptors.response.use(
  (value) => mapNullToUndefined(value),
  (err) => {
    throw mapNullToUndefined(err);
  },
);

export default httpClient;

export function addDispatchStoreErrorInterceptor(store: Store<any>) {
  httpClient.interceptors.response.use(
    (response) => response,
    (error) => {
      // Don't display error message for canceled promise.
      if (isCancel(error)) {
        return Promise.reject(error);
      }

      if (isAxiosError(error)) {
        // 401 errors are handled by refresh token interceptor
        if (error.response && error.response.status === 401) {
          return Promise.reject(error);
        }

        if (error.config && error.config.url) {
          // Ignore background requests
          if (error.config.url.endsWith('/autosave') || error.config.url.includes('/reports/')) {
            return Promise.reject(error);
          }
        }
      }

      // Let know main store about this error.
      store.dispatch('ERROR', error);
      return Promise.reject(error);
    },
  );
}
