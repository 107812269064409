import { ListStatus, EditableStatus } from '@/store/types';
import Country from '@/store/models/country';

import Settings from './settings';
import Legal from './legal';
import Email from './email';

export const TOP_BANNER_STORAGE_PREFIX: string = 'topBannerClosed';

export enum SettingsActions {
  /**
   * Top Banner
   */
  CLOSE_TOP_BANNER = 'CLOSE_TOP_BANNER',
  SAVE_TOP_BANNER_TRANSLATIONS = 'SAVE_TOP_BANNER_TRANSLATIONS',

  /**
   * Settings
   */
  LOAD_SETTINGS = 'LOAD_SETTINGS',
  LOAD_EDITABLE_SETTINGS = 'LOAD_EDITABLE_SETTINGS',
  UPDATE_EDITABLE_SETTINGS = 'UPDATE_EDITABLE_SETTINGS',
  SAVE_EDITABLE_SETTINGS = 'SAVE_EDITABLE_SETTINGS',

  /**
   * Countries
   */
  UPDATE_EDITABLE_COUNTRIES = 'UPDATE_EDITABLE_COUNTRIES',
  UPDATE_EDITABLE_COUNTRY = 'UPDATE_EDITABLE_COUNTRY',

  /**
   * Legal
   */
  UPDATE_EDITABLE_LEGAL_BODY = 'UPDATE_EDITABLE_LEGAL_BODY',
  SAVE_LEGAL_TRANSLATIONS = 'SAVE_LEGAL_TRANSLATIONS',

  /**
   * Email Notifications
   */
  UPDATE_EDITABLE_EMAIL = 'UPDATE_EDITABLE_EMAIL',
  SAVE_EMAIL_TRANSLATIONS = 'SAVE_EMAIL_TRANSLATIONS',
}

export enum SettingsMutations {
  /**
   * Top Banner
   */
  SET_TOP_BANNER_CLOSURE = 'SET_TOP_BANNER_CLOSURE',
  UPDATE_EDITABLE_TOP_BANNER = 'UPDATE_EDITABLE_TOP_BANNER',
  SET_TOP_BANNER_TRANSLATIONS_STATUS = 'SET_TOP_BANNER_TRANSLATIONS_STATUS',

  /**
   * Settings
   */
  SET_SETTINGS = 'SET_SETTINGS',
  SET_SETTINGS_STATUS = 'SET_SETTINGS_STATUS',
  SET_EDITABLE_SETTINGS = 'SET_EDITABLE_SETTINGS',
  SET_INITIAL_EDITABLE_SETTINGS = 'SET_INITIAL_EDITABLE_SETTINGS',
  SET_EDITABLE_SETTINGS_STATUS = 'SET_EDITABLE_SETTINGS_STATUS',

  /**
   * Countries
   */
  SET_COUNTRIES = 'SET_COUNTRIES',
  SET_EDITABLE_COUNTRIES = 'SET_EDITABLE_COUNTRIES',
  UPDATE_EDITABLE_COUNTRY = 'UPDATE_EDITABLE_COUNTRY',

  /**
   * Legals
   */
  SET_EDITABLE_LEGALS = 'SET_EDITABLE_LEGALS',
  SET_INITIAL_EDITABLE_LEGALS = 'SET_INITIAL_EDITABLE_LEGALS',
  UPDATE_EDITABLE_LEGAL_BODY = 'UPDATE_EDITABLE_LEGAL_BODY',
  SET_LEGAL_TRANSLATIONS_STATUS = 'SET_LEGAL_TRANSLATIONS_STATUS',

  /**
   * Emails
   */
  SET_EMAILS = 'SET_EMAILS',
  SET_EDITABLE_EMAILS = 'SET_EDITABLE_EMAILS',
  SET_INITIAL_EDITABLE_EMAILS = 'SET_INITIAL_EDITABLE_EMAILS',
  UPDATE_EDITABLE_EMAIL = 'UPDATE_EDITABLE_EMAIL',
  SET_EMAIL_TRANSLATIONS_STATUS = 'SET_EMAIL_TRANSLATIONS_STATUS',
}

export interface SettingsState {
  /**
   * Top Banner
   */
  topBannerClosed: string;

  /**
   * Settings
   */
  settings: Settings;
  settingsStatus: ListStatus;
  editableSettings?: Settings;
  initialEditableSettings?: Settings;
  editableSettingsStatus: EditableStatus;
  topBannerTranslationsStatus: EditableStatus;

  /**
   * Countries
   */
  countries: Country[];
  editableCountries?: Country[];

  /**
   * Legals
   */
  editableLegals?: Legal[];
  initialEditableLegals?: Legal[];
  legalTranslationsStatus: EditableStatus;

  /**
   * Email Notifications
   */
  emails: Email[];
  editableEmails?: Email[];
  initialEditableEmails?: Email[];
  emailTranslationsStatus: EditableStatus;
}
