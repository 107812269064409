
import Vue from 'vue';
import { mapState, mapGetters, mapActions } from 'vuex';
import EasyMDE from 'easymde';

import 'easymde/dist/easymde.min.css';

import Legal, { LegalType } from '@/store/modules/settings/legal';
import { SettingsActions } from '@/store/modules/settings/types';

import MarkdownEditor from '@/components/controls/markdown-editor.vue';
import TranslationsControl from '@/components/controls/translations.vue';

function _toggleHeading(cm, direction, size) {
  if (/editor-preview-active/.test(cm.getWrapperElement().lastChild.className)) return;

  const startPoint = cm.getCursor('start');
  const endPoint = cm.getCursor('end');

  for (let i = startPoint.line; i <= endPoint.line; i++) {
    let text = cm.getLine(i);
    const currHeadingLevel = text.search(/[^#]/);

    if (direction !== undefined) {
      if (currHeadingLevel <= 0) {
        if (direction === 'bigger') {
          text = '###### ' + text;
        } else {
          text = '# ' + text;
        }
      } else if (currHeadingLevel === 6 && direction === 'smaller') {
        text = text.substr(7);
      } else if (currHeadingLevel === 1 && direction === 'bigger') {
        text = text.substr(2);
      } else {
        if (direction === 'bigger') {
          text = text.substr(1);
        } else {
          text = '#' + text;
        }
      }
    } else {
      const headingPrefix = '#'.repeat(size);

      if (currHeadingLevel <= 0) {
        text = headingPrefix + ' ' + text;
      } else if (currHeadingLevel === size) {
        text = text.substr(currHeadingLevel + 1);
      } else {
        text = headingPrefix + ' ' + text.substr(currHeadingLevel + 1);
      }
    }

    cm.replaceRange(
      text,
      {
        line: i,
        ch: 0,
      },
      {
        line: i,
        ch: 99999999999999,
      },
    );
  }
  cm.focus();
}

function _replaceSelection(cm: CodeMirror.Editor, active: boolean, startEnd: [string, string], url: string) {
  if (/editor-preview-active/.test((cm.getWrapperElement()!.lastChild! as HTMLElement).className)) {
    return;
  }

  let text;
  let startTag = startEnd[0];
  let endTag = startEnd[1];
  const startPoint = cm.getCursor('start');
  const endPoint = cm.getCursor('end');

  if (url) {
    startTag = startTag.replace('#url#', url);
    endTag = endTag.replace('#url#', url);
  }

  if (active) {
    const lineText = cm.getLine(startPoint.line);
    const beforeText = lineText.slice(0, startPoint.ch - startTag.length);
    const rawText = lineText.slice(startPoint.ch, endPoint.ch);
    const afterText = lineText.slice(endPoint.ch + endTag.length);

    cm.replaceRange(
      beforeText + rawText + afterText,
      {
        line: startPoint.line,
        ch: 0,
      },
      {
        line: startPoint.line,
        ch: lineText.length,
      },
    );

    startPoint.ch = beforeText.length;
    if (startPoint !== endPoint) {
      endPoint.ch = beforeText.length + rawText.length;
    }
  } else {
    text = cm.getSelection();
    cm.replaceSelection(startTag + text + endTag);

    startPoint.ch += startTag.length;
    if (startPoint !== endPoint) {
      endPoint.ch += startTag.length;
    }
  }

  cm.setSelection(startPoint, endPoint);
  cm.focus();
}

function drawLink(editor: EasyMDE, link: string = 'https://') {
  const cm = editor.codemirror;
  const stat = (editor as any).getState();
  const options = (editor as any).options;

  _replaceSelection(cm, stat.link, options.insertTexts.link, link);
}

export default Vue.extend({
  name: 'LegalSettingsForm',
  inject: {
    $validator: '$validator',
  },
  components: {
    MarkdownEditor,
    TranslationsControl,
  },
  props: {
    isActive: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapState('settings', ['editableLegals', 'legalTranslationsStatus']),
    ...mapGetters('settings', {
      isLoading: 'editableSettingsIsLoading',
      isSaving: 'editableSettingsIsSaving',
    }),
    isFetching() {
      return this.isLoading || this.isSaving;
    },
    modalLegal() {
      if (!this.editableLegals) {
        return;
      }

      return (this.editableLegals as Legal[]).find((item) => item.type === LegalType.Modal);
    },
    bannerLegal() {
      if (!this.editableLegals) {
        return;
      }

      return (this.editableLegals as Legal[]).find((item) => item.type === LegalType.Banner);
    },
    privacyPolicy() {
      if (!this.editableLegals) {
        return;
      }

      return (this.editableLegals as Legal[]).find((item) => item.type === LegalType.Privacy);
    },
    sharedEditorOptions(): Partial<EasyMDE.Options> {
      return {
        sideBySideFullscreen: false,
        spellChecker: false,
        status: false,
        toolbar: ['bold', 'italic', 'heading-3', '|', 'unordered-list', 'link', '|', 'clean-block', 'preview', 'guide'],
      };
    },
    modalType() {
      return LegalType.Modal;
    },
    bannerType() {
      return LegalType.Banner;
    },
    privacyType() {
      return LegalType.Privacy;
    },
  },
  methods: {
    ...mapActions('settings', {
      updateLegalBody: SettingsActions.UPDATE_EDITABLE_LEGAL_BODY,
      saveTranslations: SettingsActions.SAVE_LEGAL_TRANSLATIONS,
    }),
    getEditorOptions(type: LegalType): Partial<EasyMDE.Options> {
      let toolbar: EasyMDE.Options['toolbar'];

      if (type === LegalType.Modal) {
        const heading: EasyMDE.ToolbarIcon = {
          name: 'heading',
          action: EasyMDE.toggleHeading3,
          className: 'fa fa-header',
          title: this.$t('Header'),
        };
        toolbar = ['bold', 'italic', heading, '|', 'unordered-list', 'link', '|', 'clean-block', 'preview', 'guide'];
      } else {
        const heading: EasyMDE.ToolbarIcon = {
          name: 'heading',
          action: (editor) => {
            _toggleHeading(editor.codemirror, undefined, 5);
          },
          className: 'fa fa-header',
          title: this.$t('Header'),
        };
        const showModalLink: EasyMDE.ToolbarIcon = {
          name: 'link',
          action: (editor) => {
            drawLink(editor, '#showModal');
          },
          className: 'fa fa-clone',
          title: this.$t('Insert show modal link'),
        };

        toolbar = [
          'bold',
          'italic',
          heading,
          '|',
          'unordered-list',
          'link',
          showModalLink,
          '|',
          'clean-block',
          'preview',
          'guide',
        ];
      }

      return {
        sideBySideFullscreen: false,
        spellChecker: false,
        status: false,
        toolbar,
      };
    },
  },
});
