/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'products': {
    width: 39,
    height: 33,
    viewBox: '0 0 39 33',
    data: '<g data-name="Layer 2"><g data-name="New icons"><path pid="0" d="M15.85 15H2.15A2.18 2.18 0 010 12.79V2.21A2.18 2.18 0 012.15 0h13.7A2.18 2.18 0 0118 2.21v10.58A2.18 2.18 0 0115.85 15zM3 12h12V3H3zM36.85 15h-13.7A2.18 2.18 0 0121 12.79V2.21A2.18 2.18 0 0123.15 0h13.7A2.18 2.18 0 0139 2.21v10.58A2.18 2.18 0 0136.85 15zM24 12h12V3H24zM15.85 33H2.15A2.18 2.18 0 010 30.79V20.21A2.18 2.18 0 012.15 18h13.7A2.18 2.18 0 0118 20.21v10.58A2.18 2.18 0 0115.85 33zM3 30h12v-9H3zM36.85 33h-13.7A2.18 2.18 0 0121 30.79V20.21A2.18 2.18 0 0123.15 18h13.7A2.18 2.18 0 0139 20.21v10.58A2.18 2.18 0 0136.85 33zM24 30h12v-9H24z"/></g></g>'
  }
})
