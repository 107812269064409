import Vue from 'vue';
import { LocationsState, LocationsMutations } from '@/store/modules/locations/types';
import { MutationsTree, ListStatus, EditableStatus } from '@/store/types';
import Location from './location';
import Pagination from '@/store/models/pagination';
import { updateEditable } from '@/store/utils/editable';
import { CancelTokenSource } from 'axios';

export const mutations: MutationsTree<LocationsState, typeof LocationsMutations> = {
  /**
   * List
   */
  [LocationsMutations.SET_LIST_STATUS](state, status: ListStatus) {
    state.listStatus = status;
  },
  [LocationsMutations.SET_LIST](state, payload: Location[]) {
    state.locationsList = payload;
  },
  [LocationsMutations.SET_LIST_PAGINATION](state, payload: Pagination) {
    state.pagination = payload;
  },

  /**
   * Menu list
   */
  [LocationsMutations.SET_MENU_LIST_STATUS](state, status: ListStatus) {
    state.menuListStatus = status;
  },
  [LocationsMutations.SET_MENU_LIST](state, payload: Location[]) {
    state.menuLocationsList = payload;
  },
  [LocationsMutations.SET_MENU_LIST_TOTAL](state, payload: number) {
    state.menuListTotalItems = payload;
  },

  /**
   * Pending list
   */
  [LocationsMutations.SET_PENDING_LIST_STATUS](state, status: ListStatus) {
    state.pendingListStatus = status;
  },
  [LocationsMutations.SET_PENDING_LIST](state, payload: Location[]) {
    state.pendingList = payload;
  },
  [LocationsMutations.SET_PENDING_LIST_TOTAL](state, payload: number) {
    state.pendingListTotalItems = payload;
  },

  /**
   * Published list
   */
  [LocationsMutations.SET_PUBLISHED_LIST_STATUS](state, status: ListStatus) {
    state.publishedListStatus = status;
  },
  [LocationsMutations.SET_PUBLISHED_LIST_TOTAL](state, payload: number) {
    state.publishedListTotalItems = payload;
  },

  /**
   * Edit
   */
  [LocationsMutations.SET_EDITABLE](state, payload: Location) {
    Vue.set(state, 'editable', payload);
    Vue.set(state, 'initialEditable', Location.clone(payload));
  },
  [LocationsMutations.SET_EDITABLE_STATUS](state, editableStatus: EditableStatus) {
    state.editableStatus = editableStatus;
  },
  [LocationsMutations.UPDATE_EDITABLE]: updateEditable<LocationsState>('editable'),
  [LocationsMutations.UPDATE_INITIAL_EDITABLE]: updateEditable<LocationsState>('initialEditable'),
  [LocationsMutations.SET_AUTOSAVE](state, payload: Location) {
    Vue.set(state, 'autosaveEditable', payload);
  },
  [LocationsMutations.SET_AUTOSAVE_CANCEL_TOKEN](state, payload: CancelTokenSource) {
    state.autosaveCancelToken = payload;
  },
  [LocationsMutations.SET_AUTOSAVE_STATUS](state, payload: EditableStatus) {
    state.autosaveStatus = payload;
  },
  [LocationsMutations.SET_AUTOSAVE_TIMEOUT](state, payload?: number) {
    state.autosaveTimeout = payload;
  },
  [LocationsMutations.SET_HAS_AUTOSAVE](state, payload: boolean) {
    state.hasAutosave = payload;
  },

  /**
   * Preview flag
   */
  [LocationsMutations.SET_IS_PREVIEW_FROM_EDIT_SCREEN](state) {
    state.isPreviewFromEditScreen = true;
  },

  /**
   * Quick Review
   */
  [LocationsMutations.SET_QUICK_REVIEW_STATUS](state, status: EditableStatus) {
    state.quickReviewStatus = status;
  },
  [LocationsMutations.SET_QUICK_REVIEW_LOCATION](
    state,
    { published, latest }: { published?: Location; latest?: Location } = {},
  ) {
    Vue.set(state, 'quickReviewLatestEditable', latest);
    Vue.set(state, 'quickReviewPublishedEditable', published);
  },
  [LocationsMutations.SET_QUICK_REVIEW_TOTAL](state, total) {
    state.quickReviewTotal = total;
  },
};

export default mutations;
