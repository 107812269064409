
import Vue from 'vue';
import { mapState, mapGetters, mapActions } from 'vuex';

import { RootActions, EventTypes } from '@/store/types';
import ResourceFile from '@/store/modules/resources/file';
import ResourceLink from '@/store/modules/resources/link';

import ResourceAssetCompleteTrainingForm from '@/components/forms/resource-asset-complete-training.vue';

export default Vue.extend({
  name: 'ResourceLinkBlock',
  components: {
    ResourceAssetCompleteTrainingForm,
  },
  props: ['link', 'parentThumbnail'],
  computed: {
    ...mapGetters('user/profile', ['isSuperAdmin', 'isAdmin']),
    ...mapState('resources', {
      resource: 'currentResource',
    }),
    thumbnail() {
      return this.parentThumbnail;
    },
    isTraining(): boolean {
      if (this.isSuperAdmin || this.isAdmin) {
        return false;
      }

      return typeof this.resource !== 'undefined' && this.resource.training === 1;
    },
    areAllFilesResolved() {
      if (!this.isTraining) {
        return true;
      }

      const unresolvedFile = (this.resource.files as ResourceFile[]).find((item) => !item.trainingComplete);

      return typeof unresolvedFile === 'undefined';
    },
    unresolvedLinks(): ResourceLink[] {
      if (!this.isTraining) {
        return [];
      }

      return this.resource.links.filter((item) => !item.trainingComplete);
    },
    showCompleteTrainingCheckbox(): boolean {
      if (!this.isTraining) {
        return false;
      }

      if (!this.areAllFilesResolved) {
        return false;
      }

      // If link training is completed.
      if (this.link.trainingComplete) {
        return true;
      }

      if (!this.unresolvedLinks.length) {
        return true;
      }

      return this.unresolvedLinks[0].id === this.link.id;
    },
    canOpen(): boolean {
      if (!this.isTraining) {
        return true;
      }

      if(
        this.resource.files.length === 1 &&
        this.resource.files[0].assetId === 0 &&
        this.resource.files[0].fid === 0 &&
        this.resource.files[0].id === 0
      ){
        return true;
      }

      if (this.showCompleteTrainingCheckbox) {
        return true;
      }

      return false;
    },
  },
  methods: {
    ...mapActions([RootActions.DISPATCH_EVENT]),
    open(event) {
      if (!this.canOpen) {
        event.preventDefault();
        return;
      }

      this[RootActions.DISPATCH_EVENT]({
        type: EventTypes.DOWNLOAD_ALL,
        eventObj: { resource: this.resource, link: this.link },
      });
    },
  },
});
