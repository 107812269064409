var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{class:[
    'c-progress-top',
    {
      'c-progress-top--active': _vm.isActive,
      'c-progress-top--complete': _vm.isComplete,
    },
  ]},[_c('span',{style:({ width: _vm.loadProgress + '%' })})])
}
var staticRenderFns = []

export { render, staticRenderFns }