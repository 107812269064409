/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'download': {
    width: 27.5,
    height: 20.5,
    viewBox: '0 0 27.5 20.5',
    data: '<g data-name="Layer 2"><g data-name="Layer 1"><g data-name="Layer 2"><g data-name="Group"><g data-name="Group 2"><path pid="0" d="M21.06 20.25H6.44C3 20.25.25 17.78.25 14.75v-9c0-3 2.78-5.5 6.19-5.5h14.62c3.41 0 6.19 2.47 6.19 5.5v9c0 3.03-2.78 5.5-6.19 5.5zm-14.62-19a4.81 4.81 0 00-5.06 4.5v9a4.81 4.81 0 005.06 4.5h14.62a4.81 4.81 0 005.06-4.5v-9a4.81 4.81 0 00-5.06-4.5z"/><path pid="1" d="M21.06 20.5H6.44C2.89 20.5 0 17.92 0 14.75v-9C0 2.58 2.89 0 6.44 0h14.62c3.55 0 6.44 2.58 6.44 5.75v9c0 3.17-2.89 5.75-6.44 5.75zM6.44.5C3.16.5.5 2.85.5 5.75v9C.5 17.65 3.16 20 6.44 20h14.62c3.28 0 5.94-2.35 5.94-5.25v-9C27 2.85 24.34.5 21.06.5zm14.63 19H6.44a5.06 5.06 0 01-5.31-4.75v-9A5.06 5.06 0 016.44 1h14.62a5.06 5.06 0 015.31 4.75v9a5.06 5.06 0 01-5.31 4.75zM6.44 1.5a4.56 4.56 0 00-4.81 4.25v9A4.56 4.56 0 006.44 19h14.62a4.56 4.56 0 004.81-4.25v-9a4.56 4.56 0 00-4.81-4.25z"/></g></g><g data-name="Layer 2"><g data-name="Isolation Mode"><path pid="2" d="M13.34 14.68V5.76a.74.74 0 01.74-.76.77.77 0 01.74.74v8.92a.74.74 0 01-1.49 0z"/><path pid="3" d="M10.36 11.69a.74.74 0 011.26-.52l2.44 2.45 2.47-2.44a.74.74 0 011.12 1l-.08.08-3 3a.74.74 0 01-1.05 0l-3-3a.74.74 0 01-.16-.57z"/></g></g></g></g></g>'
  }
})
