/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'grid': {
    width: 13,
    height: 11,
    viewBox: '0 0 13 11',
    data: '<g data-name="Layer 2"><g data-name="Dial Pad"><g data-name="&lt;Group&gt;"><path pid="0" data-name="&lt;Compound Path&gt;" d="M4.5 5h-3A1.5 1.5 0 010 3.5v-2A1.5 1.5 0 011.5 0h3A1.5 1.5 0 016 1.5v2A1.5 1.5 0 014.5 5zm-3-4a.5.5 0 00-.5.5v2a.5.5 0 00.5.5h3a.5.5 0 00.5-.5v-2a.5.5 0 00-.5-.5z"/></g><g data-name="&lt;Group&gt;"><path pid="1" data-name="&lt;Compound Path&gt;" d="M11.5 5h-3A1.5 1.5 0 017 3.5v-2A1.5 1.5 0 018.5 0h3A1.5 1.5 0 0113 1.5v2A1.5 1.5 0 0111.5 5zm-3-4a.5.5 0 00-.5.5v2a.5.5 0 00.5.5h3a.5.5 0 00.5-.5v-2a.5.5 0 00-.5-.5z"/></g><g data-name="&lt;Group&gt;"><path pid="2" data-name="&lt;Compound Path&gt;" d="M4.5 11h-3A1.5 1.5 0 010 9.5v-2A1.5 1.5 0 011.5 6h3A1.5 1.5 0 016 7.5v2A1.5 1.5 0 014.5 11zm-3-4a.5.5 0 00-.5.5v2a.5.5 0 00.5.5h3a.5.5 0 00.5-.5v-2a.5.5 0 00-.5-.5z"/></g><g data-name="&lt;Group&gt;"><path pid="3" data-name="&lt;Compound Path&gt;" d="M11.5 11h-3A1.5 1.5 0 017 9.5v-2A1.5 1.5 0 018.5 6h3A1.5 1.5 0 0113 7.5v2a1.5 1.5 0 01-1.5 1.5zm-3-4a.5.5 0 00-.5.5v2a.5.5 0 00.5.5h3a.5.5 0 00.5-.5v-2a.5.5 0 00-.5-.5z"/></g></g></g>'
  }
})
