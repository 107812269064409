import { JSONModel } from '@/store/models/jsonModel';

export interface EventCategoryJSON {
  id: number;
  name: string;
  slug: string;
}

export default class EventCategory extends JSONModel<EventCategoryJSON> {
  public id: number = 0;
  public name: string = '';

  public _slug: string = '';

  public get slug(): string {
    if (this._slug) {
      return this._slug;
    }

    return this.name.toLowerCase().replace(/[^a-z]+/g, '-');
  }

  public fromJSON(json: Partial<EventCategoryJSON>) {
    if (json.id) {
      this.id = Number(json.id);
    }

    if (json.name) {
      this.name = String(json.name);
    }

    if (json.slug) {
      this._slug = String(json.slug);
    }
  }
}
