
import Vue from 'vue';
import { mapGetters, mapState, mapActions } from 'vuex';

import { formatNumber } from '@/i18n';

import FormInput from '@/components/controls/form-input.vue';
import SelectInput from '@/components/controls/select-input.vue';
import SubmitButton from '@/components/controls/submit-button.vue';
import NotificationBanner from '@/components/blocks/notification-banner.vue';
import Publish from '@/components/forms/publish.vue';
import ImageUploadControl from '@/components/controls/image-upload.vue';
import PracticeSelectionControl from '@/components/controls/practice-selection.vue';
import Autocomplete, { AutocompleteOption } from '@/components/controls/autocomplete.vue';
import LanguagePicker from '@/components/controls/language-picker.vue';
import SendWelcomeEmailModal from '@/components/forms/send-welcome-email-modal.vue';
import Modal from '@/components/layout/modal.vue';

import { UserStatus, UserStatuses } from '@/store/models/user';
import { UsersActions } from '@/store/modules/users/types';
import { ListStatus } from '@/store/types';
import { UserRole } from '@/store/modules/user/profile/types';
import { PracticeStatus } from '@/store/modules/practices/types';
import Country from '@/store/models/country';
import { UserActions } from '@/store/modules/user/types';

export default Vue.extend({
  name: 'EditUserView',
  inject: {
    $validator: '$validator',
  },
  props: {
    add: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    FormInput,
    SelectInput,
    SubmitButton,
    NotificationBanner,
    Publish,
    ImageUploadControl,
    PracticeSelectionControl,
    Autocomplete,
    LanguagePicker,
    SendWelcomeEmailModal,
    Modal,
  },
  data() {
    return {
      repeatedPassword: '',
      countryInput: '',
      isSendWelcomeMailModalOpen: false,
      isWelcomeMailPromptOpen: false,
    };
  },
  computed: {
    UserRole() {
      return UserRole;
    },
    ...mapState('user/profile', {
      currentUser: 'currentProfile',
    }),
    ...mapGetters('user/profile', ['isSuperAdmin', 'isAdmin']),
    ...mapState(['countries', 'countriesStatus']),
    ...mapState('users', {
      user: 'editable',
      editableStatus: 'editableStatus',
    }),
    ...mapGetters('users', {
      isLoading: 'editableIsLoading',
      isSaving: 'editableIsSaving',
      isSaved: 'editableIsSaved',
      isFailed: 'editableIsFailed',
      isFetching: 'editableIsFetching',
      hasUnsavedChanges: 'editableHasUnsavedChanges',
    }),
    isInactive() {
      return this.user.status === UserStatus.Inactive;
    },
    isActive() {
      return this.user.status === UserStatus.Active;
    },
    isWaitingActivation() {
      return this.user.status === UserStatus.WaitingActivation;
    },
    hasPracticeDeleted() {
      return (
        this.user.status === UserStatus.Inactive &&
        this.user.practice &&
        this.user.practice.status === PracticeStatus.Inactive
      );
    },
    userStatus() {
      if (this.hasPracticeDeleted) {
        return this.$t('Practice Deleted');
      }

      return UserStatuses[this.user.status];
    },
    countriesOptions(): AutocompleteOption[] {
      const countriesIds = this.user.countries.map(({ id }) => id);

      return this.countries
        .filter((country) => {
          return countriesIds.indexOf(country.id) === -1;
        })
        .map((country) => ({
          label: country.name,
          value: country.id,
        }))
        .filter((option) => option.label.toLowerCase().indexOf(this.countryInput.toLowerCase()) > -1);
    },
    areCountriesLoaded(): boolean {
      return this.countriesStatus === ListStatus.Loaded;
    },
    isCurrentUser(): boolean {
      return this.currentUser.id === this.user.id;
    },
  },
  methods: {
    formatNumber,
    ...mapActions('users', {
      updateUser: UsersActions.UPDATE_EDITABLE,
      save: UsersActions.SAVE_EDITABLE,
      removeUser: UsersActions.DELETE,
      restoreUser: UsersActions.RESTORE,
    }),
    ...mapActions('user', {
      impersonateAction: UserActions.IMPERSONATE,
    }),
    addCountry(option: AutocompleteOption) {
      const country = this.countries.find(({ id }) => id === option.value);

      if (country) {
        this.updateUser({
          countries: [
            ...this.user.countries,
            Country.fromJSON({
              id: country.id,
              code: country.code,
              name: country.name,
            }),
          ],
        });
      }
    },
    removeCountry(countryId: number) {
      this.updateUser({
        countries: [...this.user.countries].filter(({ id }) => id !== countryId),
      });
    },
    remove() {
      this.removeUser({ id: this.user.id });
    },
    restore() {
      this.restoreUser({ id: this.user.id });
    },
    impersonate() {
      this.impersonateAction({ id: this.user.id });
    },
    closeWelcomeMailPrompt() {
      const { open_welcome_email_modal, ...query } = this.$route.query;

      this.isWelcomeMailPromptOpen = false;
      this.$router.replace({ query });
    },
    openWelcomeMailModal() {
      this.isSendWelcomeMailModalOpen = true;

      this.closeWelcomeMailPrompt();
    },
  },
  watch: {
    '$route.query.open_welcome_email_modal': {
      handler(value, prevValue) {
        if (value !== prevValue) {
          if (value === '1') {
            this.isWelcomeMailPromptOpen = true;
          } else {
            this.isWelcomeMailPromptOpen = false;
          }
        }
      },
      immediate: true,
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.hasUnsavedChanges) {
      const answer = window.confirm(this.$t('Do you really want to leave? You have unsaved changes!'));

      if (!answer) {
        next(false);
        return;
      }
    }

    next();
  },
});
