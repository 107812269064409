import { JSONModel } from '@/store/models/jsonModel';
import { fixLineEndings } from '@/utils/api';
import { PublishStatus } from '@/store/types';

const availableStatuses: number[] = Object.keys(PublishStatus).map((key) => PublishStatus[key]);

export interface LocationRelationshipJSON {
  id: number;
  title: string;
  excerpt: string;
  status: number;
  map_url?: string;
}

export default class LocationRelationship extends JSONModel<LocationRelationshipJSON> {
  public id: number = 0;
  public title: string = '';
  public excerpt: string = '';
  public status: PublishStatus = PublishStatus.Deleted;
  public mapUrl?: string;

  public fromJSON(json: Partial<LocationRelationshipJSON>) {
    if (json.id) {
      this.id = Number(json.id);
    }

    if (json.title) {
      this.title = String(json.title);
    }

    if (json.excerpt) {
      this.excerpt = fixLineEndings(String(json.excerpt));
    }

    if (typeof json.status !== 'undefined' && availableStatuses.indexOf(json.status) !== -1) {
      this.status = json.status;
    }

    if (json.map_url) {
      this.mapUrl = String(json.map_url);
    }
  }
}
