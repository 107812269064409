
import Vue, { ComputedOptions } from 'vue';
import { mapState, mapGetters, mapActions } from 'vuex';
import Draggable from 'vuedraggable';
import EasyMDE from 'easymde';

import config from '@/config';

import { SupportActions } from '@/store/modules/support/types';
import FAQ from '@/store/modules/support/faq';

import FormInput from '@/components/controls/form-input.vue';
import Checkbox from '@/components/controls/checkbox.vue';
import MarkdownEditor from '@/components/controls/markdown-editor.vue';
import TranslationsControl from '@/components/controls/translations.vue';
import SubmitButton from '@/components/controls/submit-button.vue';
import CountriesSelection from '@/components/controls/countries-selection.vue';

export default Vue.extend({
  name: 'SupportSettingsForm',
  inject: {
    $validator: '$validator',
  },
  components: {
    Draggable,
    FormInput,
    MarkdownEditor,
    TranslationsControl,
    SubmitButton,
    Checkbox,
    CountriesSelection,
  },
  data(): {
    expandedFaq?: number | string;
  } {
    return {
      expandedFaq: undefined,
    };
  },
  computed: {
    ...mapState('support', ['faqEditable', 'faqTranslationsStatus']),
    ...mapGetters('settings', {
      isLoading: 'editableSettingsIsLoading',
      isSaving: 'editableSettingsIsSaving',
    }),
    isFetching() {
      return this.isLoading || this.isSaving;
    },
    faqs: {
      get() {
        return (this.faqEditable as FAQ[]).filter((item) => !item.removeOnServer);
      },
      set(value) {
        this.updateEditableList(value);
      },
    } as ComputedOptions<FAQ[]>,
    editorOptions(): EasyMDE.Options {
      return {
        sideBySideFullscreen: false,
        spellChecker: false,
        status: false,
        toolbar: ['bold', 'italic', 'heading-3', '|', 'unordered-list', 'link', '|', 'clean-block', 'preview', 'guide'],
      };
    },
    areTranslatableFAQsEnabled() {
      return config.FEATURES.translatableFAQs === true;
    },
  },
  methods: {
    ...mapActions('support', {
      updateEditable: SupportActions.UPDATE_FAQ_EDITABLE,
      updateEditableList: SupportActions.UPDATE_FAQ_EDITABLE_LIST,
      saveTranslations: SupportActions.SAVE_FAQ_TRANSLATIONS,
    }),
    toggleExpand(id: string | number) {
      if (this.expandedFaq === id) {
        this.expandedFaq = undefined;
      } else {
        this.expandedFaq = id;
      }
    },
    addNewFAQ() {
      const newFAQ = new FAQ();
      this.expandedFaq = newFAQ.id;
      this.updateEditableList([...this.faqEditable, newFAQ]);
    },
    removeFAQ(id: string | number) {
      const answer = window.confirm(this.$t('Do you really want to delete this FAQ?'));

      if (answer) {
        this.updateEditable({ id, removeOnServer: true });
      }
    },
  },
});
