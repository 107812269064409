
import Vue from 'vue';
import { mapGetters, mapState } from 'vuex';

export default Vue.extend({
  name: 'MainFooterLayout',
  computed: {
    ...mapState('user/profile', {
      currentUser: 'currentProfile',
    }),
    ...mapGetters('user/profile', ['isSuperAdmin', 'isAdmin', 'isUser', 'isInNorthAmerica']),
  },
});
