import actions from './actions';
import mutations from './mutations';
import getters from './getters';
import { Module } from 'vuex';
import { RootState } from '@/store/types';
import { NotificationsState } from '@/store/modules/notifications/types';

export const getInitialState = (): NotificationsState => ({
  type: undefined,
  message: {
    title: '',
    body: '',
  },
  show: false,
  snackbars: [],
});

export const state: NotificationsState = getInitialState();

const notifications: Module<NotificationsState, RootState> = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

export default notifications;
