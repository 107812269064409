import { JSONModel } from './jsonModel';
import { Paginated } from '@/api/types';

export type PaginationJSON = Paginated<any>;

export default class Pagination extends JSONModel<PaginationJSON> {
  public currentPage: number = 1;
  public lastPage: number = 1;
  public perPage: number = 15;
  public total: number = 0;
  public from: number = 0;
  public to: number = 0;

  public fromJSON(json: PaginationJSON) {
    if (json.current_page) {
      this.currentPage = Number(json.current_page);
    }

    if (json.last_page) {
      this.lastPage = Number(json.last_page);
    }

    if (json.per_page) {
      this.perPage = Number(json.per_page);
    }

    if (json.total) {
      this.total = Number(json.total);
    }

    if (json.from) {
      this.from = Number(json.from);
    }

    if (json.to) {
      this.to = Number(json.to);
    }
  }
}
