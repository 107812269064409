var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('form',[(_vm.isSuperAdmin || _vm.isAdmin)?_c('div',{staticClass:"c-form--featured c-divider"},[_c('h5',[_vm._v(_vm._s(_vm.$t('Select a Practice:')))]),_c('practice-selection-control',{staticClass:"c-search__form",attrs:{"value":_vm.practitioner.practiceId},on:{"input":_vm.handlePracticeChange}})],1):_vm._e(),_c('h5',[_vm._v(_vm._s(_vm.$t('Locations')))]),(!_vm.hasPracticeAssigned)?_c('p',[_vm._v(" "+_vm._s(_vm.$t('Please select a practice for this location first.'))+" ")]):(_vm.listIsLoading)?_c('p',[_vm._v(_vm._s(_vm.$t('Loading locations...')))]):(_vm.listIsEmpty)?_c('div',[_c('p',[_vm._v(_vm._s(_vm.$t("You don't have any locations added yet.")))]),_c('router-link',{staticClass:"c-button c-button--primary-transparent",attrs:{"to":{ name: 'add-location' }}},[_vm._v(" "+_vm._s(_vm.$t('Add a Location'))+" ")])],1):_vm._l((_vm.locationsList),function(location){return _c('div',{key:location.id,staticClass:"c-tile c-tile--item"},[_c('div',{class:[
        'c-form-element',
        'c-checkbox-wrapper',
        {
          'u-flex': _vm.isSuperAdmin || _vm.isAdmin,
          'u-justify-between': _vm.isSuperAdmin || _vm.isAdmin,
          'is-selected': _vm.isLocationSelected(location.id),
        },
      ]},[_c('input',{attrs:{"type":"checkbox","id":'location-checkbox-' + location.id},domProps:{"checked":_vm.isLocationSelected(location.id)},on:{"click":function($event){return _vm.toggleLocation(location.id)}}}),_c('label',{attrs:{"for":'location-checkbox-' + location.id}},[_vm._v(_vm._s(location.title))]),(_vm.isSuperAdmin || _vm.isAdmin)?_c('router-link',{class:'c-edit-link c-edit-link--hidden',attrs:{"to":{ name: 'edit-location', params: { id: location.id } }}},[_vm._v(_vm._s(_vm.$t('edit')))]):_vm._e()],1)])})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }