import { GetterTree } from 'vuex';

import { RootState, ListStatus } from '@/store/types';
import { ReportsState } from './types';

export const moduleGetters: GetterTree<ReportsState, RootState> = {
  /**
   * List
   */
  listIsLoading: (state) => state.listStatus === ListStatus.Loading,
  listIsLoaded: (state) => state.listStatus === ListStatus.Loaded,
  listIsError: (state) => state.listStatus === ListStatus.Failed,
  listIsEmpty: (state, getters) => getters.listIsLoaded && !state.list.length,
};

export default moduleGetters;
