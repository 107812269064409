
import Vue, { PropOptions } from 'vue';
import EasyMDE from 'easymde';

import 'easymde/dist/easymde.min.css';

export default Vue.extend({
  name: 'MarkdownEditor',
  props: {
    value: {
      type: String,
      default: '',
    },
    options: {
      type: Object,
      default: () => ({}),
    } as PropOptions<Omit<EasyMDE.Options, 'element' | 'initialValue'>>,
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      editor: undefined as EasyMDE | undefined,
      isTouched: false,
    };
  },
  methods: {
    getEditorOptions(initialValue: string) {
      const isDisabled = this.disabled;
      const options: EasyMDE.Options = {
        ...this.options,
        element: this.$refs.input,
        initialValue,
      };

      if (isDisabled) {
        options.toolbar = false;
      }

      return options;
    },
    emitInput() {
      const editor: EasyMDE | undefined = this.editor;

      if (!editor) {
        return;
      }

      this.$emit('input', editor.value());
    },
    removeEditor() {
      const editor: EasyMDE | undefined = this.editor;

      if (!editor) {
        return;
      }

      const cm = editor.codemirror as CodeMirror.EditorFromTextArea;
      const cmWrapper = cm.getWrapperElement();
      const wrapper = cmWrapper.parentNode;

      wrapper!.parentNode!.insertBefore(cmWrapper, wrapper);
      cm.toTextArea();
      wrapper!.parentNode!.removeChild(wrapper!);

      this.editor = undefined;
    },
    initEditor() {
      let editor: EasyMDE | undefined = this.editor;
      let value = this.value;

      if (editor) {
        if (this.isTouched) {
          value = editor.value();
        }
        this.removeEditor();
      }

      editor = new EasyMDE(this.getEditorOptions(value));
      editor.codemirror.setOption('readOnly', this.disabled);

      this.editor = editor;

      editor.codemirror.on('change', () => {
        this.isTouched = true;
        this.emitInput();
      });
    },
  },
  mounted() {
    this.initEditor();
  },
  beforeDestroy() {
    this.emitInput();
    this.removeEditor();
    this.editor = undefined;
  },
  watch: {
    disabled: {
      handler(isDisabled, prevValue) {
        this.initEditor();
      },
    },
  },
});
