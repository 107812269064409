/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'rss': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M.5 0a.5.5 0 00-.5.5v4a.5.5 0 00.5.5A18.52 18.52 0 0119 23.5a.5.5 0 00.5.5h4a.5.5 0 00.5-.5A23.52 23.52 0 00.5 0z"/><path pid="1" d="M.5 7a.5.5 0 00-.5.5v4a.5.5 0 00.5.5A11.51 11.51 0 0112 23.5a.5.5 0 00.5.5h4a.5.5 0 00.5-.5A16.52 16.52 0 00.5 7z"/><path pid="2" d="M3.5 17A3.5 3.5 0 107 20.5 3.5 3.5 0 003.5 17z"/>'
  }
})
