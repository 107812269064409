export const getEnumKeys = (obj: object) => {
  return Object.keys(obj);
};

export const getEnumValues = (obj: object) => {
  return Object.keys(obj).map((key) => obj[key]);
};

export const hasEnumValue = (obj: object, value: any) => {
  const values = getEnumValues(obj);

  return typeof value !== 'undefined' && values.indexOf(value) !== -1;
};

export const getEnumKeyFromValue = (obj: object, value: any): string | undefined => {
  const keys = getEnumKeys(obj);

  for (const key of keys) {
    if (value === obj[key]) {
      return key;
    }
  }

  return undefined;
};

export const getEnumValue = <T>(obj: { [key: number]: any }, value: any) => {
  const key = getEnumKeyFromValue(obj, value);

  if (!key) {
    return undefined;
  }

  return obj[key] as T;
};
