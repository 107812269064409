import Vue from 'vue';
import { MutationTree } from 'vuex';

import { CategoryState, CategoryMutations } from '@/store/modules/categories/types';
import Pagination from '@/store/models/pagination';
import { EditableStatus, ListStatus } from '@/store/types';

import Category from './category';

export const mutations: MutationTree<CategoryState> = {
  /**
   * List
   */
  [CategoryMutations.SET_LIST](state, categories: Category[]) {
    state.list = categories;
  },
  [CategoryMutations.SET_LIST_PAGINATION](state, pagination: Pagination) {
    state.listPagination = pagination;
  },
  [CategoryMutations.SET_STATUS](state, status: ListStatus) {
    state.status = status;
  },
  /**
   * All categories
   */
  [CategoryMutations.SET_ALL](state, categories: Category[]) {
    state.allList = categories;
  },
  [CategoryMutations.SET_ALL_FILTERS](state, data) {
    state.allFilters = data;
  },
  [CategoryMutations.SET_ALL_STATUS](state, status: ListStatus) {
    state.allStatus = status;
  },

  /**
   * Edit
   */
  [CategoryMutations.SET_EDITABLE](state, category: Category) {
    Vue.set(state, 'editable', category);
    Vue.set(state, 'initialEditable', Category.clone(category));
  },
  [CategoryMutations.SET_EDITABLE_STATUS](state, editableStatus: EditableStatus) {
    state.editableStatus = editableStatus;
  },
  [CategoryMutations.UPDATE_EDITABLE](state, payload) {
    Object.keys(payload).forEach((key) => {
      // If property is already set and it's reactive
      if (state.editable!.hasOwnProperty(key)) {
        state.editable![key] = payload[key];
      } else {
        // Add new reactive property to the object
        Vue.set(state.editable!, key, payload[key]);
      }
    });
  },
  [CategoryMutations.SET_TRANSLATIONS_STATUS](state, translationsStatus: EditableStatus) {
    state.translationsStatus = translationsStatus;
  },
};

export default mutations;
