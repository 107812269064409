/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'file-text': {
    width: 1496.5,
    height: 1752.5,
    viewBox: '0 0 1496.5 1752.5',
    data: '<g data-name="Layer 2"><path pid="0" d="M76.25 1752.25c-21.34 0-39-7.24-53.86-22.14S.25 1697.59.25 1676.25v-1600c0-21.34 7.24-39 22.14-53.86S54.91.25 76.25.25h896c23.89 0 50.91 6.21 80.31 18.46s52.83 27 69.55 43.68l312 312c16.72 16.72 31.42 40.12 43.68 69.55s18.46 56.42 18.46 80.31v1152c0 21.34-7.24 39-22.14 53.86s-32.52 22.14-53.86 22.14zm12-88h1320v-1064h-436c-21.34 0-39-7.24-53.86-22.14s-22.14-32.52-22.14-53.86v-436h-808zm896-1152h426.92l-17.86-41.79c-6.24-14.61-13.09-25.52-20.92-33.35l-313-313c-7.83-7.83-18.74-14.68-33.35-20.92l-41.79-17.86zm-588 856c-5 0-7.23-1.51-8.86-3.14s-3.14-3.83-3.14-8.86v-64c0-5 1.51-7.23 3.14-8.86s3.83-3.14 8.86-3.14h704c5 0 7.23 1.51 8.86 3.14s3.14 3.83 3.14 8.86v64c0 5-1.51 7.23-3.14 8.86s-3.83 3.14-8.86 3.14zm0-256c-5 0-7.23-1.51-8.86-3.14s-3.14-3.83-3.14-8.86v-64c0-5 1.51-7.23 3.14-8.86s3.83-3.14 8.86-3.14h704c5 0 7.23 1.51 8.86 3.14s3.14 3.83 3.14 8.86v64c0 5-1.51 7.23-3.14 8.86s-3.83 3.14-8.86 3.14zm0-256c-5 0-7.23-1.51-8.86-3.14s-3.14-3.83-3.14-8.86v-64c0-5 1.51-7.23 3.14-8.86s3.83-3.14 8.86-3.14h704c5 0 7.23 1.51 8.86 3.14s3.14 3.83 3.14 8.86v64c0 5-1.51 7.23-3.14 8.86s-3.83 3.14-8.86 3.14z" _stroke="#000" stroke-miterlimit="10" stroke-width=".5" data-name="Layer 1"/></g>'
  }
})
