import { JSONModel } from '@/store/models/jsonModel';

export enum PublicSiteStatus {
  Inactive = 0,
  Active = 1,
}

export interface PublicSiteJSON {
  id: number;
  title: string;
  url: string;
  status: 0 | 1;
  created_at: string;
  updated_at: string;
}

const availableStatuses = Object.keys(PublicSiteStatus).map((key) => PublicSiteStatus[key]);

export default class PublicSite extends JSONModel<PublicSiteJSON> {
  public id: number = 0;
  public status: PublicSiteStatus = PublicSiteStatus.Inactive;
  public title: string = '';
  public url: string = '';
  public createdAt?: string;
  public updatedAt?: string;

  public fromJSON(json: Partial<PublicSiteJSON>) {
    this.id = Number(json.id);

    if (typeof json.status !== 'undefined' && availableStatuses.indexOf(json.status) !== -1) {
      this.status = json.status;
    }

    if (json.title) {
      this.title = String(json.title);
    }

    if (json.url) {
      this.url = String(json.url);
    }

    if (json.created_at) {
      this.createdAt = String(json.created_at);
    }

    if (json.updated_at) {
      this.updatedAt = String(json.updated_at);
    }
  }
}
