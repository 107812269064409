import { ListStatus, MutationsTree } from '@/store/types';
import Pagination from '@/store/models/pagination';
import { BlogState, BlogMutations } from './types';
import BlogPost from './post';
import BlogTaxonomy from './taxonomy';
import BlogAuthor from './author';

export const mutations: MutationsTree<BlogState, typeof BlogMutations> = {
  /**
   * List
   */
  [BlogMutations.SET_LIST](state, posts: BlogPost[]) {
    state.list = posts;
  },
  [BlogMutations.SET_LIST_PAGINATION](state, pagination: Pagination) {
    state.listPagination = pagination;
  },
  [BlogMutations.SET_STATUS](state, status: ListStatus) {
    state.status = status;
  },

  /**
   * Categories
   */
  [BlogMutations.SET_CATEGORIES](state, categories: BlogTaxonomy[]) {
    state.categories = categories;
  },
  [BlogMutations.SET_CATEGORIES_STATUS](state, status: ListStatus) {
    state.categoriesStatus = status;
  },

  /**
   * Author
   */
  [BlogMutations.SET_AUTHOR](state, author?: BlogAuthor) {
    state.author = author;
  },
  [BlogMutations.SET_AUTHOR_STATUS](state, status: ListStatus) {
    state.authorStatus = status;
  },

  /**
   * Post
   */
  [BlogMutations.SET_POST](state, post?: BlogPost) {
    state.post = post;
  },
  [BlogMutations.SET_POST_STATUS](state, status: ListStatus) {
    state.postStatus = status;
  },
};

export default mutations;
