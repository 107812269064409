
import Vue from 'vue';
import { mapState, mapActions, mapGetters } from 'vuex';

import Checkbox from '@/components/controls/checkbox.vue';
import { ResourcesActions } from '@/store/modules/resources/types';
import Category from '@/store/modules/categories/category';
import FilterSkeleton from '@/components/skeletons/categories-filter-form.vue';
import { Resource } from '@/store/modules/resources/resource';
import Taxonomy, { TaxonomyJSON } from '@/store/models/taxonomy';

export default Vue.extend({
  name: 'ResourceCategoriesForm',
  components: {
    Checkbox,
    FilterSkeleton,
  },
  props: {
    add: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState('resources', {
      resource: 'editable',
    }),
    ...mapState('categories', {
      categories: 'allList',
    }),
    ...mapGetters('resources', {
      isLoading: 'editableIsLoading',
      isSaving: 'editableIsSaving',
    }),
    ...mapGetters('categories', {
      isCategoriesLoading: 'isAllLoading',
    }),
    resourceCategories(): number[] {
      const resource: Resource = this.resource;
      if (!resource || !resource.categories || !resource.categories.length) {
        return [];
      }

      return resource.categories.map((cat) => cat.id);
    },
    isFetching() {
      return this.isLoading || this.isSaving;
    },
    addCategoryRoute() {
      const parentRouteName = (this.$route.name == 'clinical-support-add-resource' || this.$route.name == 'clinical-support-edit-resource') ? 'clinical-support-' : '';
      return {
        name: `${parentRouteName}${this.add ? 'add' : 'edit'}-resource/add-category`,
      };
    },
  },
  methods: {
    ...mapActions('resources', {
      updateResource: ResourcesActions.UPDATE_EDITABLE,
    }),
    isSelected(category: Category): number {
      return this.resourceCategories.indexOf(category.id) + 1;
    },
    toggle(category: Category) {
      const resource: Resource = this.resource;
      let categories: Taxonomy[] = [];
      if (resource.categories) {
        categories = [...resource.categories];
      }

      const index = this.isSelected(category);

      if (index) {
        categories.splice(index - 1, 1);
      } else {
        categories.push(
          Taxonomy.fromJSON({
            id: category.id,
            title: category.title,
          } as TaxonomyJSON),
        );
      }

      this.updateResource({ categories });
    },
  },
});
