import { JSONModel } from '@/store/models/jsonModel';

export interface BlogTaxonomyJSON {
  id: number;
  name: string;
  slug: string;
}

export default class BlogTaxonomy extends JSONModel<BlogTaxonomyJSON> {
  public id: number = 0;
  public name: string = '';
  public slug: string = '';

  public fromJSON(json: BlogTaxonomyJSON) {
    if (json.id) {
      this.id = Number(json.id);
    }

    if (json.name) {
      this.name = String(json.name);
    }

    if (json.slug) {
      this.slug = String(json.slug);
    }
  }
}
