
import Vue from 'vue';
import { mapState, mapGetters } from 'vuex';
import BlogList from '@/components/blocks/blog-list.vue';
import { formatNumber } from '@/i18n';

export default Vue.extend({
  name: 'BlogAuthorView',
  components: {
    BlogList,
  },
  computed: {
    ...mapState('blog', {
      pagination: 'listPagination',
      author: 'author',
    }),
    ...mapGetters('blog', ['isLoaded']),
  },
  methods: {
    formatNumber,
  },
});
