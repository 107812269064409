import { EditableStatus, ListStatus } from '@/store/types';
import Practice from './practice';
import Pagination from '@/store/models/pagination';

export enum PracticesActions {
  /**
   * List
   */
  LOAD_LIST = 'LOAD_LIST',

  /**
   * View & Edit
   */
  LOAD_PRACTICE = 'LOAD_PRACTICE',
  UPDATE_EDITABLE = 'UPDATE_EDITABLE',
  SAVE_EDITABLE = 'SAVE_EDITABLE',
  DELETE = 'DELETE',
  RESTORE = 'RESTORE',

  /**
   * Bulk actions
   */
  BULK_DELETE = 'BULK_DELETE',
  BULK_RESTORE = 'BULK_RESTORE',

  /**
   * User practice
   */
  LOAD_USER_PRACTICE = 'LOAD_USER_PRACTICE',
}

export enum PracticesMutations {
  /**
   * List
   */
  SET_LIST = 'SET_LIST',
  SET_LIST_PAGINATION = 'SET_LIST_PAGINATION',
  SET_LIST_STATUS = 'SET_LIST_STATUS',

  /**
   * View & Edit
   */
  SET_PRACTICE = 'SET_PRACTICE',
  UPDATE_INITIAL_EDITABLE = 'UPDATE_INITIAL_EDITABLE',
  UPDATE_EDITABLE = 'UPDATE_EDITABLE',
  SET_EDITABLE_STATUS = 'SET_EDITABLE_STATUS',

  /**
   * User practice
   */
  SET_USER_PRACTICE = 'SET_USER_PRACTICE',
  SET_USER_PRACTICE_STATUS = 'SET_USER_PRACTICE_STATUS',
}

export enum PracticeStatus {
  Inactive,
  Published,
}

export interface PracticesState {
  /**
   * List
   */
  list: Practice[];
  listStatus: ListStatus;
  listPagination: Pagination;

  /**
   * Editable practice
   */
  editableStatus: EditableStatus;
  currentPractice?: Practice;
  editablePractice?: Practice;

  /**
   * User practice
   */
  userPracticeStatus: ListStatus;
  userPractice?: Practice;
}
