import { MutationTree } from 'vuex';
import { NotificationsState, NotificationsMutations } from '@/store/modules/notifications/types';
import { Snackbar } from './snackbar';

export const mutations: MutationTree<NotificationsState> = {
  [NotificationsMutations.SET_NOTIFICATION](state, { message, type }) {
    state.message = message;
    state.type = type;
  },
  [NotificationsMutations.SET_VISIBILITY](state, show: boolean) {
    state.show = show;
  },
  [NotificationsMutations.ADD_SNACKBAR](state, snackbar: Snackbar) {
    state.snackbars.push(snackbar);
  },
  [NotificationsMutations.REMOVE_SNACKBAR](state, snackbar: Snackbar) {
    state.snackbars = state.snackbars.filter((val) => val.id !== snackbar.id);
  },
};

export default mutations;
