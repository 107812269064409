import { ActionTree } from 'vuex';

import api from '@/api';
import i18next from '@/i18n';
import router from '@/router';
import { RootState, RootActions, ErrorMessages, ListStatus, EditableStatus } from '@/store/types';
import { PracticesState, PracticesMutations, PracticesActions, PracticeStatus } from '@/store/modules/practices/types';
import Practice, { PracticeJSON } from '@/store/modules/practices/practice';
import Pagination from '@/store/models/pagination';
import { NotificationsActions } from '@/store/modules/notifications/types';
import { prepareFilters } from '@/store/utils/filters';
import { enhanceErrorWithMessage } from '@/utils/errors';

export const actions: ActionTree<PracticesState, RootState> = {
  [PracticesActions.LOAD_LIST]({ commit, dispatch }, filters = {}): Promise<void> {
    commit(PracticesMutations.SET_LIST_STATUS, ListStatus.Loading);

    return api.practices
      .list(prepareFilters(filters, 'practices'))
      .then((response) => {
        commit(PracticesMutations.SET_LIST_PAGINATION, Pagination.fromJSON(response.data));
        commit(
          PracticesMutations.SET_LIST,
          response.data.data.map((item) => Practice.fromJSON(item)),
        );
        commit(PracticesMutations.SET_LIST_STATUS, ListStatus.Loaded);
      })
      .catch((error) => {
        enhanceErrorWithMessage(error, ErrorMessages.DATA_LOAD_ERROR);
        commit(PracticesMutations.SET_LIST_STATUS, ListStatus.Failed);
        return dispatch(RootActions.ERROR, error, { root: true });
      });
  },
  [PracticesActions.LOAD_USER_PRACTICE]({ commit, dispatch }, { id } = {}): Promise<void> {
    commit(PracticesMutations.SET_USER_PRACTICE_STATUS, ListStatus.Loading);

    if (!id) {
      commit(PracticesMutations.SET_USER_PRACTICE, undefined);
      commit(PracticesMutations.SET_USER_PRACTICE_STATUS, ListStatus.Loaded);

      return Promise.resolve();
    }

    return api.practices
      .get(id)
      .then((response) => {
        commit(PracticesMutations.SET_USER_PRACTICE, Practice.fromJSON(response.data));
        commit(PracticesMutations.SET_USER_PRACTICE_STATUS, ListStatus.Loaded);
      })
      .catch((error) => {
        enhanceErrorWithMessage(error, ErrorMessages.DATA_LOAD_ERROR);
        commit(PracticesMutations.SET_USER_PRACTICE_STATUS, ListStatus.Failed);
        return dispatch(RootActions.ERROR, error, { root: true });
      });
  },
  [PracticesActions.LOAD_PRACTICE]({ commit, dispatch }, { id } = {}): Promise<void> {
    commit(PracticesMutations.SET_EDITABLE_STATUS, EditableStatus.Loading);

    if (!id) {
      commit(PracticesMutations.SET_PRACTICE, new Practice());
      commit(PracticesMutations.SET_EDITABLE_STATUS, EditableStatus.Loaded);

      return Promise.resolve();
    }

    return api.practices
      .get(id)
      .then((response) => {
        commit(PracticesMutations.SET_PRACTICE, Practice.fromJSON(response.data));
        commit(PracticesMutations.SET_EDITABLE_STATUS, EditableStatus.Loaded);
      })
      .catch((error) => {
        enhanceErrorWithMessage(error, ErrorMessages.DATA_LOAD_ERROR);
        commit(PracticesMutations.SET_EDITABLE_STATUS, EditableStatus.Failed);
        return dispatch(RootActions.ERROR, error, { root: true });
      });
  },
  [PracticesActions.SAVE_EDITABLE]({ dispatch, commit, state }): Promise<void> {
    commit(PracticesMutations.SET_EDITABLE_STATUS, EditableStatus.Saving);

    if (!state.editablePractice) {
      return Promise.reject(new Error('Internal Error: Editable practice not available.'));
    }

    const practice: PracticeJSON = state.editablePractice.toJSON();
    const isUpdate = Boolean(practice.id);
    let successMsg = i18next.t('A new practice was added successfully.');

    if (isUpdate) {
      successMsg = i18next.t('The practice was successfully updated.');
    }

    return api.practices
      .store(practice)
      .then((response): Promise<Practice> => {
        const savedPractice = Practice.fromJSON(response.data);
        commit(PracticesMutations.SET_PRACTICE, savedPractice);
        commit(PracticesMutations.SET_EDITABLE_STATUS, EditableStatus.Saved);

        if (!isUpdate) {
          return new Promise<Practice>((resolve, reject) => {
            if (!savedPractice.id) {
              return reject(new Error('Invalid data.'));
            }

            router.replace(
              {
                name: 'edit-practice',
                params: {
                  id: String(savedPractice.id),
                },
              },
              () => resolve(savedPractice),
              (error: any) => reject(error),
            );
          });
        }

        return Promise.resolve(savedPractice);
      })
      .then((savedPractice: Practice) => {
        return dispatch(
          'notifications/' + NotificationsActions.SHOW_SUCCESS,
          {
            body: successMsg,
            visit: {
              link: {
                name: 'view-practice',
                params: {
                  id: savedPractice.id,
                },
              },
              label: i18next.t('View practice'),
            },
          },
          { root: true },
        );
      })
      .catch((error) => {
        enhanceErrorWithMessage(error, ErrorMessages.PRACTICE_SAVE_ERROR);
        commit(PracticesMutations.SET_EDITABLE_STATUS, EditableStatus.Failed);
        return dispatch(RootActions.ERROR, error, { root: true });
      });
  },

  [PracticesActions.UPDATE_EDITABLE]({ commit }, changes): Promise<void> {
    commit(PracticesMutations.UPDATE_EDITABLE, changes);
    return Promise.resolve();
  },

  [PracticesActions.DELETE]({ commit, dispatch }, { id = 0 }: { id?: number } = {}): Promise<void> {
    const isEdit = router.currentRoute.name === 'edit-practice';
    if (isEdit) {
      commit(PracticesMutations.SET_EDITABLE_STATUS, EditableStatus.Saving);
    } else {
      commit(PracticesMutations.SET_LIST_STATUS, ListStatus.Loading);
    }

    return new Promise<void>((resolve, reject) => {
      if (!id) {
        return reject(new Error('Invalid data!'));
      }

      resolve();
    })
      .then(() => api.practices.remove(id))
      .then(() => {
        if (isEdit) {
          commit(PracticesMutations.UPDATE_INITIAL_EDITABLE, {
            status: PracticeStatus.Inactive,
          });
          commit(PracticesMutations.UPDATE_EDITABLE, {
            status: PracticeStatus.Inactive,
          });
          commit(PracticesMutations.SET_EDITABLE_STATUS, EditableStatus.Saved);
        } else {
          dispatch(PracticesActions.LOAD_LIST, {
            ...router.currentRoute.params,
            ...router.currentRoute.query,
          });
        }
      })
      .then(() => {
        return dispatch(
          'notifications/' + NotificationsActions.SHOW_SUCCESS,
          {
            body: i18next.t('The practice was deleted successfully.'),
          },
          { root: true },
        );
      })
      .catch((error) => {
        enhanceErrorWithMessage(error, ErrorMessages.DATA_LOAD_ERROR);

        if (isEdit) {
          commit(PracticesMutations.SET_EDITABLE_STATUS, EditableStatus.Failed);
        } else {
          commit(PracticesMutations.SET_LIST_STATUS, ListStatus.Failed);
        }

        return dispatch(RootActions.ERROR, error, { root: true });
      });
  },
  [PracticesActions.RESTORE]({ commit, dispatch }, { id = 0 }: { id?: number } = {}): Promise<void> {
    const isEdit = router.currentRoute.name === 'edit-practice';
    if (isEdit) {
      commit(PracticesMutations.SET_EDITABLE_STATUS, EditableStatus.Saving);
    } else {
      commit(PracticesMutations.SET_LIST_STATUS, ListStatus.Loading);
    }

    return new Promise<void>((resolve, reject) => {
      if (!id) {
        return reject(new Error('Invalid data!'));
      }

      resolve();
    })
      .then(() => api.practices.restore(id))
      .then(() => {
        if (isEdit) {
          commit(PracticesMutations.UPDATE_INITIAL_EDITABLE, {
            status: PracticeStatus.Published,
          });
          commit(PracticesMutations.UPDATE_EDITABLE, {
            status: PracticeStatus.Published,
          });
          commit(PracticesMutations.SET_EDITABLE_STATUS, EditableStatus.Saved);
        } else {
          dispatch(PracticesActions.LOAD_LIST, {
            ...router.currentRoute.params,
            ...router.currentRoute.query,
          });
        }
      })
      .then(() =>
        dispatch(
          'notifications/' + NotificationsActions.SHOW_SUCCESS,
          {
            body: i18next.t('The practice was restored successfully.'),
            visit: {
              link: {
                name: 'view-practice',
                params: {
                  id,
                },
              },
              label: i18next.t('View practice'),
            },
          },
          { root: true },
        ),
      )
      .catch((error) => {
        enhanceErrorWithMessage(error, ErrorMessages.DATA_LOAD_ERROR);

        if (isEdit) {
          commit(PracticesMutations.SET_EDITABLE_STATUS, EditableStatus.Failed);
        } else {
          commit(PracticesMutations.SET_LIST_STATUS, ListStatus.Failed);
        }

        return dispatch(RootActions.ERROR, error, { root: true });
      });
  },
  [PracticesActions.BULK_RESTORE]({ commit, dispatch }, { ids = [] }: { ids: number[] } = { ids: [] }): Promise<void> {
    commit(PracticesMutations.SET_LIST_STATUS, ListStatus.Loading);

    return new Promise<void>((resolve, reject) => {
      if (!ids || !ids.length) {
        return reject(new Error('Invalid data!'));
      }

      resolve();
    })
      .then(() => Promise.all(ids.map(async (id) => api.practices.restore(id))))
      .then(() => {
        dispatch(PracticesActions.LOAD_LIST, {
          ...router.currentRoute.params,
          ...router.currentRoute.query,
        });

        return dispatch(
          'notifications/' + NotificationsActions.SHOW_SUCCESS,
          {
            body: i18next.t('%(count)% practice was restored successfully', { count: ids.length }),
          },
          { root: true },
        );
      })
      .catch((error) => {
        enhanceErrorWithMessage(error, ErrorMessages.DATA_LOAD_ERROR);

        commit(PracticesMutations.SET_LIST_STATUS, ListStatus.Failed);

        return dispatch(RootActions.ERROR, error, { root: true });
      });
  },
  [PracticesActions.BULK_DELETE]({ commit, dispatch }, { ids = [] }: { ids: number[] } = { ids: [] }): Promise<void> {
    commit(PracticesMutations.SET_LIST_STATUS, ListStatus.Loading);

    return new Promise<void>((resolve, reject) => {
      if (!ids || !ids.length) {
        return reject(new Error('Invalid data!'));
      }

      resolve();
    })
      .then(() => Promise.all(ids.map((id) => api.practices.remove(id))))
      .then(() => {
        dispatch(PracticesActions.LOAD_LIST, {
          ...router.currentRoute.params,
          ...router.currentRoute.query,
        });

        return dispatch(
          'notifications/' + NotificationsActions.SHOW_SUCCESS,
          {
            body: i18next.t('%(count)% practice was deleted successfully', { count: ids.length }),
          },
          { root: true },
        );
      })
      .catch((error) => {
        enhanceErrorWithMessage(error, ErrorMessages.DATA_LOAD_ERROR);

        commit(PracticesMutations.SET_LIST_STATUS, ListStatus.Failed);

        return dispatch(RootActions.ERROR, error, { root: true });
      });
  },
};

export default actions;
