import { CategoryStatus } from './types';
import { JSONModel } from '@/store/models/jsonModel';

const availableStatuses = Object.keys(CategoryStatus).map((key) => CategoryStatus[key]);

export interface CategoryJSON {
  id?: number;
  status?: number;
  title?: string;
  description?: string;
  count?: number;
  type?: string;
  downloads_total_count: number;
  downloads_weekly_count: number;
  translations: {
    title: Record<string, string>;
    description: Record<string, string>;
  };
}

export default class Category extends JSONModel<CategoryJSON> {
  public id: number = 0;
  public status: CategoryStatus = CategoryStatus.Active;
  public title: string = '';
  public type: string = '';
  public description: string = '';
  public count: number = 0;
  public downloadsTotalCount: number = 0;
  public downloadsWeeklyCount: number = 0;
  public translations: {
    title: Record<string, string>;
    description: Record<string, string>;
  } = { title: {}, description: {} };

  protected _clientProperties = ['translations'];

  public fromJSON(json: CategoryJSON) {
    this.id = Number(json.id);
    if (typeof json.status !== 'undefined' && availableStatuses.indexOf(json.status) !== -1) {
      this.status = json.status;
    }
    this.title = String(json.title);
    this.type = String(json.type);
    this.description = String(json.description);
    this.count = Number(json.count);

    if (json.downloads_total_count) {
      this.downloadsTotalCount = Number(json.downloads_total_count);
    }

    if (json.downloads_weekly_count) {
      this.downloadsWeeklyCount = Number(json.downloads_weekly_count);
    }

    if (json.translations) {
      this.translations = json.translations;
    }
  }
}
