import httpClient from '../http-client';
import { RequestConfig } from '../types';

export function personalize(
  payload: {
    product: string;
    template: string;
    headline: string | null;
    model_gender: string | null;
    model_age: string | null;
    model_skin: string | null;
    model_body: string | null;
    use_ba_images: string;
    event_content: {
      presentations: 'yes' | 'no';
      live_demonstrations: 'yes' | 'no';
      light_bites_and_refreshments: 'yes' | 'no';
      special_event_pricing: 'yes' | 'no';
      raffle_prices_giveaways_more: 'yes' | 'no';
    };
    event_date: string | null;
    event_location: string | null;
    discount: string | null;
    address: string | null;
    phone: string | null;
    call_to_action: string | null;
    logo: {
      id: number;
    } | null;
  },
  config?: RequestConfig,
) {
  return httpClient.post<{}>(`personalize`, payload, config);
}
