import { Module } from 'vuex';

import { RootState, EditableStatus, ListStatus } from '@/store/types';
import { SupportState } from '@/store/modules/support/types';

import actions from './actions';
import mutations from './mutations';
import getters from './getters';

export const getInitialState = (): SupportState => ({
  /**
   * List
   */
  faqList: [],
  faqListStatus: ListStatus.Loading,

  /**
   * Edit
   */
  faqEditableStatus: EditableStatus.Loading,
  faqTranslationsStatus: EditableStatus.Loaded,
});

export const state: SupportState = getInitialState();

const categories: Module<SupportState, RootState> = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

export default categories;
