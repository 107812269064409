
import Vue from 'vue';
import { mapActions, mapState, mapGetters } from 'vuex';

import { formatDate } from '@/i18n';

import { LocationsActions } from '@/store/modules/locations/types';
import { TreatmentActions } from '@/store/modules/treatments/types';
import { PractitionersActions } from '@/store/modules/practitioners/types';
import { Priority, PublishStatus, EditableStatus } from '@/store/types';
import Location from '@/store/modules/locations/location';
import PractitionerRelationship from '@/store/modules/practitioners/practitioner-relationship';

// import LocationRankForm from '@/components/forms/location-rank.vue';
// import LocationStarForm from '@/components/forms/location-star.vue';
import LocationPractitionersForm from '@/components/forms/location-practitioners.vue';
import PractitionerHoursForm from '@/components/forms/practitioner-hours.vue';
import LocationSocialLinksForm from '@/components/forms/location-social-links.vue';
import Publish from '@/components/forms/publish.vue';
import NotificationBanner from '@/components/blocks/notification-banner.vue';
import Notification from '@/components/blocks/notification.vue';
import LocationForm from '@/components/forms/location.vue';
import LocationReviewForm from '@/components/forms/location-review.vue';
import SubmitButton from '@/components/controls/submit-button.vue';
import Checkbox from '@/components/controls/checkbox.vue';

export default Vue.extend({
  name: 'EditLocationView',
  $_veeValidate: {
    validator: 'new',
  },
  components: {
    LocationForm,
    // LocationRankForm,
    // LocationStarForm,
    LocationPractitionersForm,
    LocationSocialLinksForm,
    Publish,
    NotificationBanner,
    Notification,
    PractitionerHoursForm,
    LocationReviewForm,
    SubmitButton,
    Checkbox,
  },
  props: {
    add: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      Priority,
      EditableStatus,
      showPractitionerHoursForm: undefined,
      explanation: '',
      isDeclining: false,
      isReviewError: false,
    };
  },
  computed: {
    formTitle() {
      return this.add ? this.$t('Add Location') : this.$t('Edit Location');
    },
    ...mapGetters('user/profile', ['isSuperAdmin', 'isAdmin']),
    ...mapState('locations', {
      editableLocation: 'editable',
      editableStatus: 'editableStatus',
      locationHasAutosave: 'hasAutosave',
    }),
    ...mapGetters('locations', {
      locationIsLoading: 'editableIsLoading',
      locationIsLoaded: 'editableIsLoaded',
      locationIsError: 'editableIsError',
      locationIsSaving: 'editableIsSaving',
      locationIsSaved: 'editableIsSaved',
      locationHasUnsavedChanges: 'editableHasUnsavedChanges',
      statusLabel: 'editableStatusLabel',
      isApprovingPending: 'editableIsApprovingPending',
      isDecliningPending: 'editableIsDecliningPending',
    }),
    isFetching(): boolean {
      return this.locationIsLoading || this.locationIsSaving;
    },
    isFullyLoaded(): boolean {
      return this.locationIsLoaded || this.locationIsSaving || this.locationIsSaved || this.locationIsError;
    },
    isInactive(): boolean {
      return this.editableLocation.status === PublishStatus.Deleted;
    },
    isPublished(): boolean {
      return this.editableLocation.status === PublishStatus.Published;
    },
    isDraft(): boolean {
      return this.editableLocation.status === PublishStatus.Draft;
    },
    isPending(): boolean {
      return this.editableLocation.status === PublishStatus.Pending;
    },
    isDeclined(): boolean {
      return this.editableLocation.status === PublishStatus.Declined;
    },
    canPublish(): boolean {
      return this.add || this.isAdmin || this.isSuperAdmin;
    },
    saveLabel(): string {
      if (this.canPublish && !this.isPublished) {
        return this.$t('Publish');
      }

      return this.$t('Update');
    },
    modalPractitioner() {
      if (!this.showPractitionerHoursForm || !this.editableLocation || !this.editableLocation.practitioners) {
        return undefined;
      }

      return this.editableLocation.practitioners.find((item) => item.id === this.showPractitionerHoursForm);
    },
  },
  methods: {
    formatDate,
    ...mapActions('locations', {
      saveLocation: LocationsActions.SAVE_EDITABLE,
      updateLocation: LocationsActions.UPDATE_EDITABLE,
      removeLocation: LocationsActions.DELETE,
      restoreLocation: LocationsActions.RESTORE,
    }),
    save({ isDraft = false } = {}): Promise<void> {
      if (isDraft) {
        return this.updateLocation({ status: PublishStatus.Draft }).then(() => this.saveLocation());
      }

      if (this.isAdmin || this.isSuperAdmin) {
        return this.updateLocation({
          status: PublishStatus.Published,
        }).then(() => this.saveLocation());
      }

      return this.saveLocation();
    },
    preview(): void {
      if (this.editableLocation && this.editableLocation.id) {
        this.$router.push({
          name: 'preview-edit-location',
          params: { id: this.editableLocation.id },
        });
      }
    },
    remove(): void {
      this.removeLocation({ id: this.editableLocation.id });
    },
    restore(): void {
      this.restoreLocation({ id: this.editableLocation.id });
    },
    updatePractitionerHours(workingHours): void {
      if (!this.modalPractitioner) {
        return;
      }

      const practitioners = this.editableLocation.practitioners.map((item) => PractitionerRelationship.clone(item));
      const practitioner = practitioners.find((item) => item.id === this.modalPractitioner.id);
      practitioner.hours = workingHours;

      this.updateLocation({ practitioners });
    },
    decline(): void {
      this.isDeclining = true;
      this.isReviewError = false;

      this.$nextTick(() => {
        this.$validator.validateAll().then((result) => {
          if (result) {
            this.updateLocation({
              status: PublishStatus.Declined,
              explanation: this.explanation,
            }).then(() => this.saveLocation());
          } else {
            this.isReviewError = true;
          }
        });
      });
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.locationHasUnsavedChanges) {
      const answer = window.confirm(this.$t('Do you really want to leave? You have unsaved changes!'));
      if (answer) {
        next();
      } else {
        next(false);
      }
    } else {
      next();
    }
  },
});
