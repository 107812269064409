var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{class:[
    'c-form-field',
    {
      'c-form__floating-label--active': _vm.focused || _vm.active,
      'c-form__floating-label--focus': _vm.focused,
    },
  ]},[_c('label',{staticClass:"c-form__floating-label",attrs:{"for":this.for}},[_vm._v(" "+_vm._s(_vm.label)+" ")]),_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }