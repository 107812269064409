import { RawLocation, Route } from 'vue-router';
import { Action, Mutation } from 'vuex';
import Country from './models/country';
import Language from './models/language';
import i18next from '@/i18n';

export enum ErrorCodes {
  NETWORK_ERROR = 'NETWORK_ERROR',
  FORBIDDEN_ERROR = 'FORBIDDEN_ERROR',
  DATA_ERROR = 'DATA_ERROR',
  LOCATION_SAVE_ERROR = 'LOCATION_SAVE_ERROR',
  PRACTITIONER_SAVE_ERROR = 'PRACTITIONER_SAVE_ERROR',
  PROFILE_SAVE_ERROR = 'PROFILE_SAVE_ERROR',
  PRACTICE_SAVE_ERROR = 'PRACTICE_SAVE_ERROR',
  DATA_LOAD_ERROR = 'DATA_LOAD_ERROR',
  UNHANDLED_ERROR = 'UNHANDLED_ERROR',
}

export enum EventTypes {
  PAGE_CHANGE = 'PAGE_CHANGE',
  EXTERNAL_LINK = 'EXTERNAL_LINK',
  LOGIN = 'LOGIN',
  SESSION = 'SESSION',
  LOGIN_FAILED = 'LOGIN_FAILED',
  RESET_PASSWORD = 'RESET_PASSWORD',
  LOGOUT = 'LOGOUT',
  SEARCH = 'SEARCH',
  DOWNLOAD = 'DOWNLOAD',
  DOWNLOAD_ALL = 'DOWNLOAD_ALL',
  UPDATE_PROFILE = 'UPDATE_PROFILE',
  UPDATE_PRACTICE = 'UPDATE_PRACTICE',
  UPDATE_LOCATION = 'UPDATE_LOCATION',
  UPDATE_PRACTITIONER = 'UPDATE_PRACTITIONER',
  WATCHED_VIDEO = 'WATCHED_VIDEO',
  ENCOUNTERED_ERROR = 'ENCOUNTERED_ERROR',
  VIDEO_PLAY = 'VIDEO_PLAY',
  PERSONALIZE = 'PERSONALIZE',
}

export const ErrorMessages = {
  get NETWORK_ERROR() {
    return i18next.t(
      'Connection to the server timed out. Please try refreshing the page or check again at a later time.',
    );
  },
  get DATA_ERROR() {
    return i18next.t('There was a problem loading or saving your data. If this persists, please contact support.');
  },
  get LOCATION_SAVE_ERROR() {
    return i18next.t("We couldn't save your location. If this persists, please contact support.");
  },
  get PRACTITIONER_SAVE_ERROR() {
    return i18next.t("We couldn't save your practitioner. If this persists, please contact support.");
  },
  get PROFILE_SAVE_ERROR() {
    return i18next.t("We couldn't save your profile. If this persists, please contact support.");
  },
  get PRACTICE_SAVE_ERROR() {
    return i18next.t("We couldn't save your practice. If this persists, please contact support.");
  },
  get DATA_LOAD_ERROR() {
    return i18next.t("We're having a problem loading what you asked for. If this persist, please contact support.");
  },
};

export enum RootActions {
  ERROR = 'ERROR',
  OPEN_MODAL = 'OPEN_MODAL',
  CLOSE_MODAL = 'CLOSE_MODAL',
  CLEAR_ERRORS = 'CLEAR_ERRORS',

  /**
   * Dispatch
   */
  DISPATCH_EVENT = 'DISPATCH_EVENT',
  DISPATCH_ROUTER_ACTION = 'DISPATCH_ROUTER_ACTION',

  /**
   * Language
   */
  SET_LANGUAGE = 'SET_LANGUAGE',

  /**
   * Load
   */
  LOAD_USER_DATA = 'LOAD_USER_DATA',
  LOAD_PROGRESS_INCREMENT = 'LOAD_PROGRESS_INCREMENT',
  LOAD_PROGRESS_DECREMENT = 'LOAD_PROGRESS_DECREMENT',
  LOAD_PROGRESS_COMPLETE = 'LOAD_PROGRESS_COMPLETE',
  LOAD_PROGRESS_RESET = 'LOAD_PROGRESS_RESET',
  LOAD_COUNTRIES = 'LOAD_COUNTRIES',
  LOAD_LANGUAGES = 'LOAD_LANGUAGES',

  /**
   * Route
   */
  PAGE_CHANGE = 'PAGE_CHANGE',

  /**
   * Enter
   */
  ROUTE_ENTER = 'ROUTE_ENTER',

  /**
   * Auth
   */
  ROUTE_ENTER_LOGIN = 'ROUTE_ENTER_LOGIN',

  /**
   * Dashboard
   */
  ROUTE_ENTER_DASHBOARD = 'ROUTE_ENTER_DASHBOARD',

  /**
   * My Practice
   */
  ROUTE_ENTER_PRACTICE = 'ROUTE_ENTER_PRACTICE',

  /**
   * Locations
   */
  ROUTE_ENTER_LOCATIONS = 'ROUTE_ENTER_LOCATIONS',
  ROUTE_ENTER_EDIT_LOCATION = 'ROUTE_ENTER_EDIT_LOCATION',
  ROUTE_ENTER_PREVIEW_EDIT_LOCATION = 'ROUTE_ENTER_PREVIEW_EDIT_LOCATION',
  ROUTE_ENTER_ADD_LOCATION = 'ROUTE_ENTER_ADD_LOCATION',
  ROUTE_ENTER_LOCATIONS_QUICK_REVIEW = 'ROUTE_ENTER_LOCATIONS_QUICK_REVIEW',

  /**
   * Practitioners
   */
  ROUTE_ENTER_PRACTITIONERS = 'ROUTE_ENTER_PRACTITIONERS',
  ROUTE_ENTER_EDIT_PRACTITIONER = 'ROUTE_ENTER_EDIT_PRACTITIONER',
  ROUTE_ENTER_ADD_PRACTITIONER = 'ROUTE_ENTER_ADD_PRACTITIONER',

  /**
   * Resources
   */
  ROUTE_ENTER_RESOURCES = 'ROUTE_ENTER_RESOURCES',
  ROUTE_ENTER_ADD_RESOURCE = 'ROUTE_ENTER_ADD_RESOURCE',
  ROUTE_ENTER_RESOURCE = 'ROUTE_ENTER_RESOURCE',
  ROUTE_ENTER_EDIT_RESOURCE = 'ROUTE_ENTER_EDIT_RESOURCE',
  ROUTE_ENTER_RESOURCE_DOWNLOADS = 'ROUTE_ENTER_RESOURCE_DOWNLOADS',

  /**
   * Categories
   */
  ROUTE_ENTER_CATEGORIES = 'ROUTE_ENTER_CATEGORIES',
  ROUTE_ENTER_EDIT_CATEGORY = 'ROUTE_ENTER_EDIT_CATEGORY',
  ROUTE_ENTER_ADD_CATEGORY = 'ROUTE_ENTER_ADD_CATEGORY',
  ROUTE_ENTER_ADD_RESOURCE_ADD_CATEGORY = 'ROUTE_ENTER_ADD_RESOURCE_ADD_CATEGORY',
  ROUTE_ENTER_EDIT_RESOURCE_ADD_CATEGORY = 'ROUTE_ENTER_EDIT_RESOURCE_ADD_CATEGORY',

  /**
   * Clinical Support
   */
  ROUTE_ENTER_CLINICAL_SUPPORT = 'ROUTE_ENTER_CLINICAL_SUPPORT',
  ROUTE_ENTER_CLINICAL_SUPPORT_CATEGORIES = 'ROUTE_ENTER_CLINICAL_SUPPORT_CATEGORIES',
  ROUTE_ENTER_CLINICAL_SUPPORT_EDIT_CATEGORY = 'ROUTE_ENTER_CLINICAL_SUPPORT_EDIT_CATEGORY',
  ROUTE_ENTER_CLINICAL_SUPPORT_ADD_CATEGORY = 'ROUTE_ENTER_CLINICAL_SUPPORT_ADD_CATEGORY',
  ROUTE_ENTER_CLINICAL_SUPPORT_DOWNLOADS = 'ROUTE_ENTER_CLINICAL_SUPPORT_DOWNLOADS',
  ROUTE_ENTER_CLINICAL_SUPPORT_RESOURCE = 'ROUTE_ENTER_CLINICAL_SUPPORT_RESOURCE',
  ROUTE_ENTER_CLINICAL_SUPPORT_ADD_RESOURCE = 'ROUTE_ENTER_CLINICAL_SUPPORT_ADD_RESOURCE',
  ROUTE_ENTER_CLINICAL_SUPPORT_EDIT_RESOURCE = 'ROUTE_ENTER_CLINICAL_SUPPORT_EDIT_RESOURCE',
  ROUTE_ENTER_CLINICAL_SUPPORT_ADD_RESOURCE_ADD_CATEGORY = 'ROUTE_ENTER_CLINICAL_SUPPORT_ADD_RESOURCE_ADD_CATEGORY',
  ROUTE_ENTER_CLINICAL_SUPPORT_EDIT_RESOURCE_ADD_CATEGORY = 'ROUTE_ENTER_CLINICAL_SUPPORT_EDIT_RESOURCE_ADD_CATEGORY',

  /**
   * Users
   */
  ROUTE_ENTER_USERS = 'ROUTE_ENTER_USERS',
  ROUTE_ENTER_EDIT_USER = 'ROUTE_ENTER_EDIT_USER',
  ROUTE_ENTER_ADD_USER = 'ROUTE_ENTER_ADD_USER',

  /**
   * Practices
   */
  ROUTE_ENTER_PRACTICES = 'ROUTE_ENTER_PRACTICES',
  ROUTE_ENTER_VIEW_PRACTICE = 'ROUTE_ENTER_VIEW_PRACTICE',
  ROUTE_ENTER_EDIT_PRACTICE = 'ROUTE_ENTER_EDIT_PRACTICE',
  ROUTE_ENTER_ADD_PRACTICE = 'ROUTE_ENTER_ADD_PRACTICE',

  /**
   * Products
   */
  ROUTE_ENTER_PRODUCTS = 'ROUTE_ENTER_PRODUCTS',
  ROUTE_ENTER_ADD_PRODUCT = 'ROUTE_ENTER_ADD_PRODUCT',
  ROUTE_ENTER_EDIT_PRODUCT = 'ROUTE_ENTER_EDIT_PRODUCT',

  /**
   * Accessories
   */
  ROUTE_ENTER_ACCESSORIES = 'ROUTE_ENTER_ACCESSORIES',
  ROUTE_ENTER_ADD_ACCESSORY = 'ROUTE_ENTER_ADD_ACCESSORY',
  ROUTE_ENTER_EDIT_ACCESSORY = 'ROUTE_ENTER_EDIT_ACCESSORY',

  /**
   * Treatments
   */
  ROUTE_ENTER_TREATMENTS = 'ROUTE_ENTER_TREATMENTS',
  ROUTE_ENTER_ADD_TREATMENT = 'ROUTE_ENTER_ADD_TREATMENT',
  ROUTE_ENTER_EDIT_TREATMENT = 'ROUTE_ENTER_EDIT_TREATMENT',

  /**
   * Treatment Enhancements
   */
  ROUTE_ENTER_ENHANCEMENTS = 'ROUTE_ENTER_ENHANCEMENTS',
  ROUTE_ENTER_ADD_ENHANCEMENT = 'ROUTE_ENTER_ADD_ENHANCEMENT',
  ROUTE_ENTER_EDIT_ENHANCEMENT = 'ROUTE_ENTER_EDIT_ENHANCEMENT',

  /**
   * Public Treatments
   */
  ROUTE_ENTER_PUBLIC_TREATMENTS = 'ROUTE_ENTER_PUBLIC_TREATMENTS',
  ROUTE_ENTER_ADD_PUBLIC_TREATMENT = 'ROUTE_ENTER_ADD_PUBLIC_TREATMENT',
  ROUTE_ENTER_EDIT_PUBLIC_TREATMENT = 'ROUTE_ENTER_EDIT_PUBLIC_TREATMENT',

  /**
   * Public Sites
   */
  ROUTE_ENTER_PUBLIC_SITES = 'ROUTE_ENTER_PUBLIC_SITES',
  ROUTE_ENTER_ADD_PUBLIC_SITE = 'ROUTE_ENTER_ADD_PUBLIC_SITE',
  ROUTE_ENTER_EDIT_PUBLIC_SITE = 'ROUTE_ENTER_EDIT_PUBLIC_SITE',

  /**
   * Reports
   */
  ROUTE_ENTER_REPORTS = 'ROUTE_ENTER_REPORTS',

  /**
   * Blog
   */
  ROUTE_ENTER_BLOG = 'ROUTE_ENTER_BLOG',
  ROUTE_ENTER_BLOG_CATEGORY = 'ROUTE_ENTER_BLOG_CATEGORY',
  ROUTE_ENTER_BLOG_AUTHOR = 'ROUTE_ENTER_BLOG_AUTHOR',
  ROUTE_ENTER_BLOG_POST = 'ROUTE_ENTER_BLOG_POST',

  /**
   * Settings
   */
  ROUTE_ENTER_SETTINGS = 'ROUTE_ENTER_SETTINGS',
  ROUTE_ENTER_BANNED_WORDS = 'ROUTE_ENTER_BANNED_WORDS',

  /**
   * Consultations
   */
  ROUTE_ENTER_CONSULTATIONS = 'ROUTE_ENTER_CONSULTATIONS',
  ROUTE_ENTER_VIEW_CONSULTATION = 'ROUTE_ENTER_VIEW_CONSULTATION',

  /**
   * Support
   */
  ROUTE_ENTER_SUPPORT = 'ROUTE_ENTER_SUPPORT',
  ROUTE_ENTER_GDPR_INFORMATION_REQUEST = 'ROUTE_ENTER_GDPR_INFORMATION_REQUEST',

  /**
   * Privacy requests
   */
  ROUTE_ENTER_PRIVACY_REQUESTS = 'ROUTE_ENTER_PRIVACY_REQUESTS',
  ROUTE_ENTER_VIEW_PRIVACY_REQUEST = 'ROUTE_ENTER_VIEW_PRIVACY_REQUEST',
}

export enum RootMutations {
  STORE_ERROR = 'STORE_ERROR',
  OPEN_MODAL = 'OPEN_MODAL',
  CLOSE_MODAL = 'CLOSE_MODAL',
  CLEAR_ERRORS = 'CLEAR_ERRORS',
  DISPATCH_EVENT = 'DISPATCH_EVENT',
  SET_LOAD_PROGRESS = 'SET_LOAD_PROGRESS',
  STORE_HISTORY_ENTRY = 'STORE_HISTORY_ENTRY',
  SET_COUNTRIES = 'SET_COUNTRIES',
  SET_COUNTRIES_STATUS = 'SET_COUNTRIES_STATUS',
  SET_ROUTE = 'SET_ROUTE',
  SET_LANGUAGES = 'SET_LANGUAGES',
  SET_LANGUAGES_STATUS = 'SET_LANGUAGES_STATUS',
}

export interface ApplicationError {
  code: ErrorCodes;
  message: string;
  errorObj?: any;
}

export interface ModalState {
  open: boolean;
  contents: string;
}

export interface ApplicationEvent {
  type: EventTypes;
  eventObj?: any;
}

export enum Priority {
  platinum = 'platinum',
  gold = 'gold',
  silver = 'silver',
  blacklisted = 'blacklisted',
}

export enum ListStatus {
  Initial = 'Initial',
  Loading = 'Loading',
  Loaded = 'Loaded',
  Failed = 'Failed',
}

export enum EditableStatus {
  Loading = 'Loading',
  Loaded = 'Loaded',
  Saving = 'Saving',
  Saved = 'Saved',
  Failed = 'Failed',
}

export enum PublishStatus {
  Deleted = 0,
  Published = 1,
  Pending = 2,
  Draft = 3,
  Declined = 4,
}

export interface HistoryEntry {
  from: Route;
  to: Route;
  timestamp: number;
}

export interface RootState {
  version: string;
  modal: ModalState;
  loadProgress: number;
  errors: ApplicationError[];
  appEvents: ApplicationEvent[];
  history: HistoryEntry[];
  countries: Country[];
  countriesStatus: ListStatus;
  languages: Language[];
  languagesStatus: ListStatus;
  isIOS: boolean;
  route?: Route;
}

export interface RouterDispatchedActionObject {
  next?: RawLocation;
}

export type RouterDispatchedActionPromiseValue = void | RouterDispatchedActionObject;

export type RouterDispatchedActionPromise = Promise<RouterDispatchedActionPromiseValue>;

export type ActionsTree<S, A = {}> = {
  [key in keyof A]: Action<S, RootState>;
};

export type MutationsTree<S, M = {}> = {
  [key in keyof M]: Mutation<S>;
};
