import Vue from 'vue';
import { MutationTree } from 'vuex';

import { EditableStatus, ListStatus } from '@/store/types';

import { ResourcesState, ResourcesMutations, ResourcesStatus } from './types';
import ResourceFile from '@/store/modules/resources/file';
import ResourceLink from '@/store/modules/resources/link';
import { Resource } from './resource';

export const mutations: MutationTree<ResourcesState> = {
  /**
   * List
   */
  [ResourcesMutations.SET_RESOURCES](state, payload: { data: Resource[]; categories: number[]; featured: Resource[] }) {
    state.resourcesList = payload.data;
    state.categories = payload.categories;
    state.featured = payload.featured;
  },
  [ResourcesMutations.SET_RESOURCES_FILTERS](state, payload) {
    Vue.set(state, 'resourcesListFilters', payload);
  },
  [ResourcesMutations.SET_PAGINATION](state, data) {
    state.pagination = data;
  },

  /**
   * Featured list
   */
  [ResourcesMutations.SET_FEATURED_RESOURCES](state, data: Resource[]) {
    state.featured = data;
  },
  [ResourcesMutations.SET_FEATURED_RESOURCES_STATUS](state, data) {
    state.featuredStatus = data;
  },
  [ResourcesMutations.SET_FEATURED_RESOURCES_FILTERS](state, data) {
    state.featuredFilters = data;
  },

  /**
   * Edit
   */
  [ResourcesMutations.SET_EDITABLE_STATUS](state, payload: EditableStatus) {
    state.editableStatus = payload;
  },
  [ResourcesMutations.SET_EDITABLE](state, payload?: Resource) {
    // Make sure that editable and initialEditable are reactive
    Vue.set(state, 'editable', payload);
    Vue.set(state, 'initialEditable', payload?.clone());
  },
  [ResourcesMutations.UPDATE_EDITABLE](state, payload) {
    Object.keys(payload).forEach((key) => {
      // If property is already set and it's reactive
      if (state.editable!.hasOwnProperty(key)) {
        state.editable![key] = payload[key];
      } else {
        // Add new reactive property to the object
        Vue.set(state.editable!, key, payload[key]);
      }
    });
  },

  /**
   * TODO: categorize resources mutation tree
   */

  [ResourcesMutations.SET_CURRENT_RESOURCE](state, data: Resource) {
    Vue.set(state, 'currentResource', data);
  },
  [ResourcesMutations.SET_RELATED_RESOURCE](state, data: Resource[]) {
    state.related = data;
  },
  [ResourcesMutations.CLEAR_RESOURCES](state) {
    state.resourcesList = [];
    state.related = [];
    state.categories = [];
  },
  /**
   * LOAD STATUSES
   */
  [ResourcesMutations.LIST_LOADING_FAILED](state, error) {
    state.listStatus = ResourcesStatus.Failed;
  },
  [ResourcesMutations.LIST_LOADED](state) {
    state.listStatus = ResourcesStatus.Loaded;
  },
  [ResourcesMutations.LIST_LOADING](state) {
    state.listStatus = ResourcesStatus.Loading;
  },
  [ResourcesMutations.CURRENT_LOADING_FAILED](state) {
    state.currentStatus = ResourcesStatus.Failed;
  },
  [ResourcesMutations.CURRENT_LOADED](state) {
    state.currentStatus = ResourcesStatus.Loaded;
  },
  [ResourcesMutations.CURRENT_LOADING](state) {
    state.currentStatus = ResourcesStatus.Loading;
  },
  [ResourcesMutations.RELATED_LOADING_FAILED](state) {
    state.relatedStatus = ResourcesStatus.Failed;
  },
  [ResourcesMutations.RELATED_LOADED](state) {
    state.relatedStatus = ResourcesStatus.Loaded;
  },
  [ResourcesMutations.RELATED_LOADING](state) {
    state.relatedStatus = ResourcesStatus.Loading;
  },
  [ResourcesMutations.PERSONALIZE_LOADING_FAILED](state) {
    state.personalizeStatus = ResourcesStatus.Failed;
  },
  [ResourcesMutations.PERSONALIZE_LOADED](state) {
    state.personalizeStatus = ResourcesStatus.Loaded;
  },
  [ResourcesMutations.PERSONALIZE_LOADING](state) {
    state.personalizeStatus = ResourcesStatus.Loading;
  },
  /**
   * Recently viewed resources
   */
  [ResourcesMutations.SET_RECENTLY_VIEWED](state, data: number[]) {
    state.recentlyViewed = data;
  },
  [ResourcesMutations.RECENTLY_VIEWED_RESOURCES_LOADING](state) {
    state.recentlyViewedStatus = ResourcesStatus.Loading;
  },
  [ResourcesMutations.RECENTLY_VIEWED_RESOURCES_LOADED](state, data: Resource[]) {
    state.recentlyViewedStatus = ResourcesStatus.Loaded;
    state.recentlyViewedResources = data;
  },
  [ResourcesMutations.RECENTLY_VIEWED_RESOURCES_FAILED](state, error) {
    state.recentlyViewedStatus = ResourcesStatus.Failed;
  },
  [ResourcesMutations.SET_IS_RESOURCE_DIRECTLY_ACCESSED](state, data: boolean) {
    state.isResourceAccessedDirectly = data;
  },
  [ResourcesMutations.SET_LASER_HIGHLIGHTED_STATUS](state, status: ListStatus) {
    state.laserHighlightedStatus = status;
  },
  [ResourcesMutations.SET_LASER_HIGHLIGHTED_RESOURCES](state, payload: Resource[]) {
    state.laserHighlightedItems = payload;
  },
  [ResourcesMutations.SET_LASER_CATEGORY_ID](state, payload?: number) {
    state.laserCategoryId = payload;
  },
  [ResourcesMutations.SET_TRAINING_RESOURCES_STATUS](state, status: ListStatus) {
    state.trainingResourcesStatus = status;
  },
  [ResourcesMutations.SET_TRAINING_RESOURCES](state, payload: Resource[]) {
    state.trainingResourcesItems = payload;
  },

  /**
   * Resource trainings
   */
  [ResourcesMutations.SET_COMPLETE_ASSET_TRAINING](
    state,
    { assetType, assetId, value }: { assetType: 'file' | 'link'; assetId: number; value: boolean },
  ) {
    if (!state.currentResource) {
      return;
    }

    let asset: ResourceFile | ResourceLink | undefined;

    if (assetType === 'link') {
      asset = state.currentResource.links.find((item) => item.id === assetId);
    } else {
      asset = state.currentResource.files.find((item) => item.id === assetId);
    }

    if (!asset) {
      return;
    }

    Vue.set(asset, 'trainingComplete', value);
  },
  [ResourcesMutations.SET_COMPLETE_ASSET_TRAINING_STATUS](state, payload: ListStatus) {
    state.completeAssetTrainingStatus = payload;
  },
};

export default mutations;
