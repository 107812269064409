export const toggleElementClass = (el: any, className: string) => {
  if (el.classList) {
    el.classList.toggle(className);
  } else {
    const classes = el.className.split(' ');
    const existingIndex = classes.indexOf(className);

    if (existingIndex >= 0) {
      classes.splice(existingIndex, 1);
    } else {
      classes.push(className);
    }

    el.className = classes.join(' ');
  }
};

export const removeElementClass = (el: any, className: string) => {
  if (el.classList) {
    el.classList.remove(className);
  } else {
    const classes = el.className.split(' ');
    const index = classes.indexOf(className);
    classes.splice(index, 1);
    el.className = classes.join(' ');
  }
};

export const addElementClass = (el: any, className: string) => {
  if (el.classList) {
    el.classList.add(className);
  } else {
    const classes = el.className.split(' ');
    if (!classes.indexOf(className)) {
      classes.push(className);
    }
    el.className = classes.join(' ');
  }
};

export const removeBodyClass = (className: string) => {
  removeElementClass(document.body, className);
};

export const addBodyClass = (className: string) => {
  addElementClass(document.body, className);
};

export const toggleBodyClass = (className: string) => {
  toggleElementClass(document.body, className);
};
