
import Vue from 'vue';
import { mapActions, mapState } from 'vuex';

import { SendWelcomeEmailParams } from '@/api/endpoints/users';
import { getEmailEditorOptions } from '@/utils/email';

import { UsersActions } from '@/store/modules/users/types';
import Email from '@/store/modules/settings/email';

import FormInput from '@/components/controls/form-input.vue';
import Modal from '@/components/layout/modal.vue';
import MarkdownEditor from '@/components/controls/markdown-editor.vue';
import SubmitButton from '@/components/controls/submit-button.vue';

export default Vue.extend({
  name: 'SendWelcomeEmailModal',
  props: {
    id: {
      type: Number,
      required: true,
    },
    languageCode: {
      type: String,
      required: true,
    },
  },
  $_veeValidate: {
    validator: 'new',
  },
  components: {
    FormInput,
    Modal,
    MarkdownEditor,
    SubmitButton,
  },
  data() {
    return {
      subject: '',
      body: '',
      button: '',
      help: '',
      sentTo: undefined as undefined | 'user' | 'admin',
      replyTo: undefined as undefined | string,
    };
  },
  computed: {
    ...mapState('users', {
      isSendingWelcome: 'sendingWelcome',
    }),
    ...mapState('settings', ['emails']),
    welcomeEmail() {
      return (this.emails as Email[]).find((email) => email.key === 'welcome');
    },
    editorOptions() {
      return getEmailEditorOptions(this.welcomeEmail);
    },
  },
  methods: {
    ...mapActions('users', {
      sendWelcomeMail: UsersActions.SEND_WELCOME_MAIL,
    }),
    getSendWelcomeEmailParams(sendToAdmin: boolean): SendWelcomeEmailParams {
      return {
        id: this.id,
        send_to_admin: sendToAdmin,
        subject: this.subject,
        body: this.body,
        button: this.button,
        help: this.help,
        reply_to: this.replyTo,
      };
    },
    sendToAdmin() {
      this.sentTo = 'admin';
      this.sendWelcomeMail(this.getSendWelcomeEmailParams(true)).then(() => this.$emit('close'));
    },
    sendToUser() {
      this.sentTo = 'user';
      this.sendWelcomeMail(this.getSendWelcomeEmailParams(false)).then(() => this.$emit('close'));
    },
  },
  watch: {
    languageCode: {
      handler(value: string, prevValue: string) {
        const email = this.welcomeEmail as Email | undefined;

        if (!email) {
          return;
        }

        this.subject =
          typeof email.translations.subject[value] !== 'undefined' ? email.translations.subject[value] : email.subject;
        this.body = typeof email.translations.body[value] !== 'undefined' ? email.translations.body[value] : email.body;
        this.button =
          typeof email.translations.button[value] !== 'undefined' ? email.translations.button[value] : email.button;
        this.help = typeof email.translations.help[value] !== 'undefined' ? email.translations.help[value] : email.help;
      },
      immediate: true,
    },
  },
});
