import Vue from 'vue';
import { MutationsTree, EditableStatus } from '@/store/types';
import { SettingsState, SettingsMutations } from './types';
import { updateEditable } from '@/store/utils/editable';
import Country from '@/store/models/country';
import Legal, { LegalType } from './legal';
import Email from './email';

const mutations: MutationsTree<SettingsState, typeof SettingsMutations> = {
  /**
   * Top Banner
   */
  [SettingsMutations.SET_TOP_BANNER_CLOSURE](state, hash) {
    state.topBannerClosed = hash;
  },
  [SettingsMutations.UPDATE_EDITABLE_TOP_BANNER](state, payload) {
    Object.keys(payload).forEach((key) => {
      // Add new reactive property to the object
      Vue.set(state.settings.topBanner, key, payload[key]);
    });
  },
  [SettingsMutations.SET_TOP_BANNER_TRANSLATIONS_STATUS](state, status: EditableStatus) {
    state.topBannerTranslationsStatus = status;
  },

  /**
   * Settings
   */
  [SettingsMutations.SET_SETTINGS](state, settings) {
    state.settings = settings;
  },
  [SettingsMutations.SET_SETTINGS_STATUS](state, status) {
    state.settingsStatus = status;
  },
  [SettingsMutations.SET_EDITABLE_SETTINGS_STATUS](state, status) {
    state.editableSettingsStatus = status;
  },
  [SettingsMutations.SET_EDITABLE_SETTINGS]: updateEditable<SettingsState>('editableSettings'),
  [SettingsMutations.SET_INITIAL_EDITABLE_SETTINGS]: updateEditable<SettingsState>('initialEditableSettings'),

  /**
   * Countries
   */
  [SettingsMutations.SET_COUNTRIES](state, payload: Country[]) {
    Vue.set(state, 'countries', payload);
  },
  [SettingsMutations.SET_EDITABLE_COUNTRIES](state, payload: Country[]) {
    Vue.set(state, 'editableCountries', payload);
  },
  [SettingsMutations.UPDATE_EDITABLE_COUNTRY](
    state,
    { id, adminEmail, countryId }: { id: Country['id']; adminEmail?: Country['adminEmail']; countryId?: number },
  ) {
    if (!state.editableCountries) {
      throw new Error('Invalid data!');
    }

    const country = state.editableCountries.find((item) => item.id === id);

    if (!country) {
      throw new Error('Country does not exist.');
    }

    if (countryId) {
      const sourceCountry = state.countries.find((item) => item.id === countryId);

      if (!sourceCountry) {
        throw new Error('Choosen country does not exist.');
      }

      // Apply all fields from source country to target country object
      country.id = sourceCountry.id;
      country.name = sourceCountry.name;
      country.adminEmail = sourceCountry.adminEmail;
      country.status = 1;
    }

    if (adminEmail) {
      country.adminEmail = adminEmail;
    }
  },

  /**
   * Legals
   */
  [SettingsMutations.SET_EDITABLE_LEGALS]: updateEditable<SettingsState>('editableLegals'),
  [SettingsMutations.SET_INITIAL_EDITABLE_LEGALS]: updateEditable<SettingsState>('initialEditableLegals'),
  [SettingsMutations.UPDATE_EDITABLE_LEGAL_BODY](state, payload: { type: LegalType; body: string }) {
    if (!state.editableLegals) {
      throw new Error('Invalid use of update editable legal body mutation.');
    }

    const existingLegal = state.editableLegals.find((item) => item.type === payload.type);

    if (existingLegal) {
      Vue.set(existingLegal, 'body', payload.body);
    } else {
      const newLegal = new Legal();

      newLegal.fromJSON({ type: payload.type, body: payload.body });

      state.editableLegals.push(newLegal);
    }
  },
  [SettingsMutations.SET_LEGAL_TRANSLATIONS_STATUS](state, translationsStatus: EditableStatus) {
    state.legalTranslationsStatus = translationsStatus;
  },

  /**
   * Emails
   */
  [SettingsMutations.SET_EMAILS](state, payload: Email[]) {
    state.emails = payload;
  },
  [SettingsMutations.SET_EDITABLE_EMAILS]: updateEditable<SettingsState>('editableEmails'),
  [SettingsMutations.SET_INITIAL_EDITABLE_EMAILS]: updateEditable<SettingsState>('initialEditableEmails'),
  [SettingsMutations.UPDATE_EDITABLE_EMAIL](state, payload: Partial<Email> & { id: number }) {
    if (!state.editableEmails) {
      throw new Error('Invalid use of update editable email notification mutation.');
    }

    const email = state.editableEmails.find((item) => item.id === payload.id);

    if (!email) {
      throw new Error('Invalid input data.');
    }

    Object.keys(payload)
      .filter((key) => key !== 'id')
      .forEach((key) => {
        // Add new reactive property to the object
        Vue.set(email, key, payload[key]);
      });
  },
  [SettingsMutations.SET_EMAIL_TRANSLATIONS_STATUS](state, translationsStatus: EditableStatus) {
    state.emailTranslationsStatus = translationsStatus;
  },
};

export default mutations;
