import { JSONModel } from '@/store/models/jsonModel';

export interface UserRelationshipJSON {
  id: number;
  title: string;
  email: string;
  thumbnail?: string;
}

export default class UserRelationship extends JSONModel<UserRelationshipJSON> {
  public id: number = 0;
  public title: string = '';
  public email: string = '';
  public thumbnail?: string;

  public fromJSON(json: UserRelationshipJSON) {
    this.id = Number(json.id);
    this.title = String(json.title);
    this.email = String(json.email);

    if (json.thumbnail) {
      this.thumbnail = String(json.thumbnail);
    }
  }

  public get avatar() {
    if (this.thumbnail) {
      return this.thumbnail + '?fit=facearea&facepad=3&w=150&h=150';
    }

    return require('@/assets/images/profile-empty-150.png');
  }
}
