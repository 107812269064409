import { ResourceJSON } from '@/store/modules/resources/resource';
import { createCRUDEndpoints } from '../factory/crud';
import httpClient from '../http-client';
import { Paginated, RequestConfig } from '../types';

export interface ListParams {
  products?: number[];
  treatments?: number[];
  categories?: number[];
  page?: number;
  [key: string]: any;
}

export interface PersonalizationResponse {
  url: string;
}

export interface ListResources extends Paginated<ResourceJSON[]> {
  featured?: ResourceJSON[];
  categories?: number[];
}

export const { list, get, store, remove, restore } = createCRUDEndpoints<ResourceJSON, ListParams, 'id', ListResources>(
  {
    slug: 'asset',
  },
);

export function listFeatured(params: { products: number[], type?: string, language_code?: string }, config?: RequestConfig) {
  return httpClient.get<ResourceJSON[]>('assets/featured', { params, ...config });
}

export function listTrainingResources(params?: { limit?: number }, config?: RequestConfig) {
  return httpClient.get<ResourceJSON[]>('assets/training', { params, ...config });
}

export function personalize(resourceId: ResourceJSON['id'], config?: RequestConfig) {
  return httpClient.get<PersonalizationResponse>(`asset/${resourceId}/personalize`, config);
}

export function listRelated(resourceId: ResourceJSON['id'], config?: RequestConfig) {
  return httpClient.get<Paginated<ResourceJSON[]>>(`asset/${resourceId}/related`, config);
}

export function completeTraining(
  { resourceId, assetType, assetId }: { resourceId: ResourceJSON['id']; assetType: 'file' | 'link'; assetId: number },
  config?: RequestConfig,
) {
  return httpClient.post<undefined>(`asset/${resourceId}/${assetType}/${assetId}/training`, config);
}

export function search(input: string, config?: RequestConfig) {
  return httpClient.get<{ frequency: number; word: string }[]>(`search/autocomplete/${input}/asset`, config);
}
