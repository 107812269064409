import { JSONModel } from '@/store/models/jsonModel';

export interface TrainingJSON {
  id: number;
  asset_id: number;
  asset_type: string;
  display: string;
  fid: number;
  weight: number;
  name: string;
  mime: string;
  uri: string;
  size: number;
  title: string;
  alt: string;
  preview: string;
  store_url: string;
  store_text: string;
  created_at: string;
  updated_at: string;
  user_id: number;
  asset_title: string;
  trainings_count: number;
  s3: string;
  thumbnail: string;
  downloads_total_count: number;
  downloads_weekly_count: number;
  trainings_total_count: number;
  trainings_weekly_count: number;
}

export default class Training extends JSONModel<TrainingJSON> {
  public id: number = 0;
  public assetId: number = 0;
  public title: string = '';
  public assetTitle: string = '';
  public trainings: number = 0;
  public thumbnail?: string;

  public fromJSON(json: Partial<TrainingJSON>) {
    if (json.id) {
      this.id = Number(json.id);
    }

    if (json.asset_id) {
      this.assetId = Number(json.asset_id);
    }

    if (json.title) {
      this.title = String(json.title);
    }

    if (json.asset_title) {
      this.assetTitle = String(json.asset_title);
    }

    if (json.trainings_count) {
      this.trainings = Number(json.trainings_count);
    }

    if (json.thumbnail) {
      this.thumbnail = String(json.thumbnail);
    }
  }
}
