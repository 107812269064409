
import Vue from 'vue';
import { mapGetters, mapActions, mapState } from 'vuex';

import { UserActions, LoginError } from '@/store/modules/user/types';

import FormInput from '@/components/controls/form-input.vue';
import SubmitButton from '@/components/controls/submit-button.vue';
import i18n from '@/components/i18n';

export default Vue.extend({
  name: 'LoginForm',
  components: {
    FormInput,
    SubmitButton,
    i18n,
  },
  inject: {
    $validator: '$validator',
  },
  data() {
    return {
      email: '',
      password: '',
      LoginError,
    };
  },
  computed: {
    ...mapState('user', ['loginErrorMessage']),
    ...mapGetters('user', ['isPending', 'isSuccess', 'isError']),
  },
  methods: {
    ...mapActions('user', [UserActions.LOGIN]),
    login(): void {
      const { email, password } = this;
      this[UserActions.LOGIN]({ email, password });
    },
  },
});
