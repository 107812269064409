/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'social/yelp': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g _fill="#303c42"><path pid="0" d="M9.2 15.46c.49-.21 1.3-.56 1.3-1.46s-.82-1.25-1.3-1.46l-.72-.29c-.76-.31-1.8-.73-2.76-1.19C4.24 10.33 3 11.08 3 12.5v3a1.6 1.6 0 001.58 1.75 2.62 2.62 0 001.14-.3c1-.47 2-.89 2.76-1.19zM12.68 18.77c0-.32 0-.58-.06-.78 0-.53-.13-1.41-1-1.69s-1.33.26-1.79.79l-.5.59c-.53.63-1.25 1.49-2 2.25a1.94 1.94 0 00-.68 1.85A1.73 1.73 0 007.89 23l2.85.93a2.12 2.12 0 00.65.11 1.54 1.54 0 001.19-.51 2.08 2.08 0 00.42-1.78c-.18-1.05-.26-2.17-.32-2.98zM19.26 16c-1.05-.18-2.14-.46-2.93-.65l-.76-.18a3.68 3.68 0 00-.82-.12 1.26 1.26 0 00-1.09.53 1.7 1.7 0 00.2 1.95l.41.66c.43.69 1 1.64 1.53 2.59A1.9 1.9 0 0017.45 22a1.76 1.76 0 001.4-.79l1.76-2.43a1.75 1.75 0 00.28-1.67A1.94 1.94 0 0019.26 16zM14.75 13.94a3.68 3.68 0 00.82-.12l.76-.18c.79-.2 1.88-.47 2.93-.65a1.94 1.94 0 001.63-1.1 1.75 1.75 0 00-.28-1.67l-1.76-2.43a1.76 1.76 0 00-1.4-.79 1.9 1.9 0 00-1.65 1.22c-.5.94-1.1 1.89-1.53 2.59l-.41.66a1.7 1.7 0 00-.2 1.95 1.26 1.26 0 001.09.52zM9.73 11.3a1.5 1.5 0 002.77-.88l-.5-9A1.49 1.49 0 0011.41.3a1.51 1.51 0 00-1.23-.3L5.67 1a1.5 1.5 0 00-.95 2.26z"/></g>'
  }
})
