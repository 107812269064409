/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'folder-open': {
    width: 2048,
    height: 1792,
    viewBox: '0 0 2048 1792',
    data: '<path pid="0" d="M1845 931q0-35-53-35H704q-40 0-85.5 21.5T547 970l-294 363q-18 24-18 40 0 35 53 35h1088q40 0 86-22t71-53l294-363q18-22 18-39zM704 768h768V608q0-40-28-68t-68-28H800q-40 0-68-28t-28-68v-64q0-40-28-68t-68-28H288q-40 0-68 28t-28 68v853l256-315q44-53 116-87.5T704 768zm1269 163q0 62-46 120l-295 363q-43 53-116 87.5t-140 34.5H288q-92 0-158-66t-66-158V352q0-92 66-158t158-66h320q92 0 158 66t66 158v32h544q92 0 158 66t66 158v160h192q54 0 99 24.5t67 70.5q15 32 15 68z"/>'
  }
})
