import Vue from 'vue';
import {
  RootState,
  RootMutations,
  ApplicationError,
  ApplicationEvent,
  HistoryEntry,
  MutationsTree,
  ListStatus,
} from '@/store/types';
import Country from './models/country';
import { Route } from 'vue-router';
import Language from './models/language';

export const mutations: MutationsTree<RootState, typeof RootMutations> = {
  [RootMutations.STORE_ERROR](state, error: ApplicationError) {
    state.errors.push(error);
  },
  [RootMutations.OPEN_MODAL](state, contents: string) {
    state.modal.open = true;
    state.modal.contents = contents;
  },
  [RootMutations.CLOSE_MODAL](state) {
    state.modal.open = false;
    state.modal.contents = '';
  },
  [RootMutations.CLEAR_ERRORS](state) {
    state.errors = [];
  },
  [RootMutations.DISPATCH_EVENT](state, data: ApplicationEvent) {
    state.appEvents.push(data);
  },
  [RootMutations.SET_LOAD_PROGRESS](state, progress) {
    state.loadProgress = progress;
  },
  [RootMutations.STORE_HISTORY_ENTRY](state, payload: HistoryEntry) {
    const arr = state.history.slice(0, 99);
    arr.unshift(payload);
    state.history = arr;
  },
  [RootMutations.SET_COUNTRIES](state, payload: Country[]) {
    state.countries = payload;
  },
  [RootMutations.SET_COUNTRIES_STATUS](state, payload: ListStatus) {
    state.countriesStatus = payload;
  },
  [RootMutations.SET_ROUTE](state, payload?: Route) {
    state.route = payload;
  },
  [RootMutations.SET_LANGUAGES](state, payload: Language[]) {
    Vue.set(state, 'languages', payload);
  },
  [RootMutations.SET_LANGUAGES_STATUS](state, payload: ListStatus) {
    state.languagesStatus = payload;
  },
};

export default mutations;
