import { UserRole } from '@/store/modules/user/profile/types';
import Image, { ImageJSON } from '@/store/models/image';
import { JSONModel, toJSONContext } from '@/store/models/jsonModel';
import { getEnumValue } from '@/utils/enum';
import PracticeRelationship, { PracticeRelationshipJSON } from '@/store/modules/practices/practice-relationship';
import Country, { CountryJSON } from '@/store/models/country';
import i18next from '@/i18n';

export enum UserStatus {
  Inactive = 0,
  Active = 1,
  WaitingActivation = 2,
}

export const UserStatuses = {
  [UserStatus.Inactive]: i18next.t('Inactive'),
  [UserStatus.Active]: i18next.t('Active'),
  [UserStatus.WaitingActivation]: i18next.t('Waiting Activation'),
};

export interface UserJSON {
  id: number;
  status: number;
  name: string;
  email: string;
  first_name: string;
  last_name: string;
  login: string;
  created_at: string;
  updated_at: string;
  login_count: number;
  role: string;
  practice: PracticeRelationshipJSON | null;
  image: ImageJSON | ImageJSON[] | null;
  password?: string;
  countries: CountryJSON[];
  login_monthly_count: number;
  language_code: string;
}

export default class User extends JSONModel<UserJSON> {
  public id: number = 0;
  public status: UserStatus = UserStatus.Active;
  public name: string = '';
  public email: string = '';
  public firstName: string = '';
  public lastName: string = '';
  public login: string = '';
  public createdAt: string = '';
  public updatedAt: string = '';
  public loginCount: number = 0;
  public role: UserRole = UserRole.User;
  public practice: PracticeRelationship = new PracticeRelationship();
  public image: Image | null = null;
  public password: string = '';
  public countries: Country[] = [];
  public loginMonthlyCount: number = 0;
  public languageCode: string = 'en';

  get fullName(): string {
    const name = [this.firstName, this.lastName].filter((val) => Boolean(val)).join(' ');

    if (name) {
      return name;
    }

    if (this.email) {
      return this.email;
    }

    return this.name;
  }

  get practiceName(): string {
    return this.practice && this.practice.title ? this.practice.title : '';
  }

  public fromJSON(json: Partial<UserJSON>) {
    if (json.id) {
      this.id = Number(json.id);
    }

    const status = getEnumValue<UserStatus>(UserStatus, json.status);
    if (typeof status !== 'undefined') {
      this.status = status;
    }

    if (json.name) {
      this.name = String(json.name);
    }

    if (json.email) {
      this.email = String(json.email);
    }

    if (json.first_name) {
      this.firstName = String(json.first_name);
    }

    if (json.last_name) {
      this.lastName = String(json.last_name);
    }

    if (json.login) {
      this.login = String(json.login);
    }

    if (json.created_at) {
      this.createdAt = String(json.created_at);
    }

    if (json.updated_at) {
      this.updatedAt = String(json.updated_at);
    }

    if (json.login_count) {
      this.loginCount = Number(json.login_count);
    }

    const role = getEnumValue<UserRole>(UserRole, json.role);
    if (role) {
      this.role = role;
    }

    if (json.practice) {
      this.practice = PracticeRelationship.fromJSON(json.practice);
    }

    if (json.image) {
      this.image = Image.fromJSON(Array.isArray(json.image) ? json.image[0] : json.image);
    }

    if (json.countries && json.countries.length) {
      this.countries = json.countries.map((item) => Country.fromJSON(item));
    }

    if (json.login_monthly_count) {
      this.loginMonthlyCount = Number(json.login_monthly_count);
    }

    if (json.language_code) {
      this.languageCode = String(json.language_code);
    }
  }

  public toJSON(context: toJSONContext = 'server') {
    const json = super.toJSON(context);

    // Remove password if not defined
    if (!json.password || !json.password.length) {
      json.password = undefined;
    }

    return json;
  }
}
