import i18next from '@/i18n';
import api from '@/api';

import { ActionsTree, ListStatus, ErrorMessages, RootActions, EditableStatus } from '@/store/types';
import Country from '@/store/models/country';
import { NotificationsActions } from '@/store/modules/notifications/types';
import { SupportActions } from '@/store/modules/support/types';
import { CountryStatus } from '@/store/modules/countries/types';

import { SettingsState, SettingsActions, SettingsMutations, TOP_BANNER_STORAGE_PREFIX } from './types';
import Settings, { SettingsJSON } from './settings';
import Legal from './legal';
import Email from './email';
import { enhanceErrorWithMessage } from '@/utils/errors';

export const actions: ActionsTree<SettingsState, typeof SettingsActions> = {
  /**
   * Top Banner
   */
  [SettingsActions.CLOSE_TOP_BANNER]({ state, commit, rootGetters }) {
    const userId = rootGetters['user/profile/userId'];

    const key = `${TOP_BANNER_STORAGE_PREFIX}_${userId}`;
    const currentHash = state.settings.topBanner.hash;
    localStorage.setItem(key, currentHash);
    commit(SettingsMutations.SET_TOP_BANNER_CLOSURE, currentHash);
  },
  async [SettingsActions.SAVE_TOP_BANNER_TRANSLATIONS](
    { commit, dispatch },
    translations: Partial<SettingsJSON['top_banner']['translations']>,
  ) {
    commit(SettingsMutations.SET_TOP_BANNER_TRANSLATIONS_STATUS, EditableStatus.Saving);
    try {
      const response = await api.settings.translate({
        top_banner: { translations },
      });
      commit(SettingsMutations.UPDATE_EDITABLE_TOP_BANNER, { translations: response.data.top_banner.translations });
      commit(SettingsMutations.SET_TOP_BANNER_TRANSLATIONS_STATUS, EditableStatus.Saved);
    } catch (error) {
      enhanceErrorWithMessage(error, ErrorMessages.DATA_LOAD_ERROR);

      commit(SettingsMutations.SET_TOP_BANNER_TRANSLATIONS_STATUS, EditableStatus.Failed);
      return dispatch(RootActions.ERROR, error, { root: true });
    }
  },

  /**
   * Settings
   */
  async [SettingsActions.LOAD_SETTINGS]({ commit, dispatch, rootGetters }) {
    commit(SettingsMutations.SET_SETTINGS_STATUS, ListStatus.Loading);

    // Fetch banner closure from local storage
    const userId = rootGetters['user/profile/userId'];
    const isSuperAdmin = rootGetters['user/profile/isSuperAdmin'];
    const key = `${TOP_BANNER_STORAGE_PREFIX}_${userId}`;
    const local = localStorage.getItem(key);
    commit(SettingsMutations.SET_TOP_BANNER_CLOSURE, local);

    try {
      const [countries, settings, emails] = await Promise.all([
        api.countries.list(),
        api.settings.get(),
        isSuperAdmin ? api.settings.getEmails().then((res) => res.data) : Promise.resolve([]),
      ]);

      commit(
        SettingsMutations.SET_COUNTRIES,
        countries.data.map((item) => Country.fromJSON(item)),
      );
      commit(SettingsMutations.SET_SETTINGS, Settings.fromJSON(settings.data));
      commit(
        SettingsMutations.SET_EMAILS,
        emails.map((item) => Email.fromJSON(item)),
      );
      commit(SettingsMutations.SET_SETTINGS_STATUS, ListStatus.Loaded);
    } catch (error) {
      enhanceErrorWithMessage(error, ErrorMessages.DATA_LOAD_ERROR);

      commit(SettingsMutations.SET_SETTINGS_STATUS, ListStatus.Failed);
      return dispatch(RootActions.ERROR, error, { root: true });
    }
  },

  async [SettingsActions.LOAD_EDITABLE_SETTINGS]({ commit, dispatch }) {
    commit(SettingsMutations.SET_EDITABLE_SETTINGS_STATUS, EditableStatus.Loading);

    try {
      // Countries
      const countries = await api.countries.list();
      commit(
        SettingsMutations.SET_COUNTRIES,
        countries.data.map((item) => Country.fromJSON(item)),
      );
      const countriesRes = await api.countries.list({
        status: CountryStatus.Active,
      });
      commit(
        SettingsMutations.SET_EDITABLE_COUNTRIES,
        countriesRes.data.map((item) => Country.fromJSON(item)),
      );

      // Legals
      const legalRes = await api.settings.getLegals();
      commit(
        SettingsMutations.SET_EDITABLE_LEGALS,
        legalRes.data.map((item) => Legal.fromJSON(item)),
      );
      commit(
        SettingsMutations.SET_INITIAL_EDITABLE_LEGALS,
        legalRes.data.map((item) => Legal.fromJSON(item)),
      );

      // Emails
      const emailRes = await api.settings.getEmails();
      commit(
        SettingsMutations.SET_EDITABLE_EMAILS,
        emailRes.data.map((item) => Email.fromJSON(item)),
      );
      commit(
        SettingsMutations.SET_INITIAL_EDITABLE_EMAILS,
        emailRes.data.map((item) => Email.fromJSON(item)),
      );

      // Other settings
      const response = await api.settings.get();
      commit(SettingsMutations.SET_EDITABLE_SETTINGS, Settings.fromJSON(response.data));
      commit(SettingsMutations.SET_INITIAL_EDITABLE_SETTINGS, Settings.fromJSON(response.data));
      commit(SettingsMutations.SET_EDITABLE_SETTINGS_STATUS, EditableStatus.Loaded);
    } catch (error) {
      enhanceErrorWithMessage(error, ErrorMessages.DATA_LOAD_ERROR);

      commit(SettingsMutations.SET_EDITABLE_SETTINGS_STATUS, EditableStatus.Failed);
      return dispatch(RootActions.ERROR, error, { root: true });
    }
  },

  async [SettingsActions.SAVE_EDITABLE_SETTINGS]({ commit, dispatch, state }) {
    commit(SettingsMutations.SET_EDITABLE_SETTINGS_STATUS, EditableStatus.Saving);

    try {
      if (!state.editableSettings || !state.editableCountries || !state.editableLegals || !state.editableEmails) {
        throw new Error('Settings are not loaded!');
      }

      // Save active countries first
      await api.countries.store(
        state.editableCountries
          .filter((item) => !item.isLocal)
          .map((item) => {
            const { id, admin_email } = item.toJSON();

            return {
              id,
              admin_email,
            };
          }),
      );
      dispatch(RootActions.LOAD_COUNTRIES, { force: true }, { root: true });
      const countries = await api.countries.list();
      commit(
        SettingsMutations.SET_COUNTRIES,
        countries.data.map((item) => Country.fromJSON(item)),
      );
      const countriesRes = await api.countries.list({
        status: CountryStatus.Active,
      });
      commit(
        SettingsMutations.SET_EDITABLE_COUNTRIES,
        countriesRes.data.map((item) => Country.fromJSON(item)),
      );

      // Save legals
      await Promise.all(state.editableLegals.map((legal) => api.settings.storeLegal(legal.toJSON('server'))));

      // Reload legals
      const legalRes = await api.settings.getLegals();
      commit(
        SettingsMutations.SET_EDITABLE_LEGALS,
        legalRes.data.map((item) => Legal.fromJSON(item)),
      );
      commit(
        SettingsMutations.SET_INITIAL_EDITABLE_LEGALS,
        legalRes.data.map((item) => Legal.fromJSON(item)),
      );

      // Save emails
      await Promise.all(state.editableEmails.map((email) => api.settings.storeEmail(email.toJSON('server'))));

      // Reload emails
      const emailRes = await api.settings.getEmails();
      commit(
        SettingsMutations.SET_EDITABLE_EMAILS,
        emailRes.data.map((item) => Email.fromJSON(item)),
      );
      commit(
        SettingsMutations.SET_INITIAL_EDITABLE_EMAILS,
        emailRes.data.map((item) => Email.fromJSON(item)),
      );

      // Save support page settings
      await dispatch('support/' + SupportActions.SAVE_FAQ_EDITABLE, undefined, { root: true });

      // Save all other settings
      const response = await api.settings.store(state.editableSettings.toJSON('server'));
      commit(SettingsMutations.SET_EDITABLE_SETTINGS, Settings.fromJSON(response.data));
      commit(SettingsMutations.SET_INITIAL_EDITABLE_SETTINGS, Settings.fromJSON(response.data));
      commit(SettingsMutations.SET_EDITABLE_SETTINGS_STATUS, EditableStatus.Saved);

      commit(SettingsMutations.SET_SETTINGS, Settings.fromJSON(response.data));
      commit(SettingsMutations.SET_SETTINGS_STATUS, ListStatus.Loaded);

      return dispatch(
        'notifications/' + NotificationsActions.SHOW_SUCCESS,
        {
          body: i18next.t('The settings were saved successfully.'),
        },
        { root: true },
      );
    } catch (error) {
      enhanceErrorWithMessage(error, ErrorMessages.DATA_LOAD_ERROR);

      commit(SettingsMutations.SET_EDITABLE_SETTINGS_STATUS, EditableStatus.Failed);
      return dispatch(RootActions.ERROR, error, { root: true });
    }
  },

  async [SettingsActions.UPDATE_EDITABLE_SETTINGS]({ commit }, changes) {
    commit(SettingsMutations.SET_EDITABLE_SETTINGS, changes);
  },

  /**
   * Countries
   */
  async [SettingsActions.UPDATE_EDITABLE_COUNTRIES]({ commit }, countries) {
    commit(SettingsMutations.SET_EDITABLE_COUNTRIES, countries);
  },
  async [SettingsActions.UPDATE_EDITABLE_COUNTRY]({ commit }, payload) {
    commit(SettingsMutations.UPDATE_EDITABLE_COUNTRY, payload);
  },

  /**
   * Legal
   */
  async [SettingsActions.UPDATE_EDITABLE_LEGAL_BODY]({ commit }, payload) {
    commit(SettingsActions.UPDATE_EDITABLE_LEGAL_BODY, payload);
  },

  [SettingsActions.SAVE_LEGAL_TRANSLATIONS]({ commit, dispatch }, { legalId, translations }): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (legalId) {
        return resolve();
      }

      reject(new Error('Invalid data!'));
    })
      .then(() => {
        commit(SettingsMutations.SET_LEGAL_TRANSLATIONS_STATUS, EditableStatus.Saving);
        return api.settings.translateLegal(legalId, translations);
      })
      .then(async () => {
        const legalRes = await api.settings.getLegals();
        commit(
          SettingsMutations.SET_EDITABLE_LEGALS,
          legalRes.data.map((item) => Legal.fromJSON(item)),
        );
        commit(
          SettingsMutations.SET_INITIAL_EDITABLE_LEGALS,
          legalRes.data.map((item) => Legal.fromJSON(item)),
        );

        commit(SettingsMutations.SET_LEGAL_TRANSLATIONS_STATUS, EditableStatus.Saved);
      })
      .catch((error) => {
        error.errorMessage = ErrorMessages.DATA_LOAD_ERROR;

        commit(SettingsMutations.SET_LEGAL_TRANSLATIONS_STATUS, EditableStatus.Failed);
        return dispatch(RootActions.ERROR, error, { root: true });
      });
  },
  /**
   * Email Notifications
   */
  async [SettingsActions.UPDATE_EDITABLE_EMAIL]({ commit }, payload) {
    commit(SettingsActions.UPDATE_EDITABLE_EMAIL, payload);
  },

  [SettingsActions.SAVE_EMAIL_TRANSLATIONS]({ commit, dispatch }, { id, translations }): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (id) {
        return resolve();
      }

      reject(new Error('Invalid data!'));
    })
      .then(() => {
        commit(SettingsMutations.SET_EMAIL_TRANSLATIONS_STATUS, EditableStatus.Saving);
        return api.settings.translateEmail(id, translations);
      })
      .then(async () => {
        const emailRes = await api.settings.getEmails();
        commit(
          SettingsMutations.SET_EDITABLE_EMAILS,
          emailRes.data.map((item) => Email.fromJSON(item)),
        );
        commit(
          SettingsMutations.SET_INITIAL_EDITABLE_EMAILS,
          emailRes.data.map((item) => Email.fromJSON(item)),
        );

        commit(SettingsMutations.SET_EMAIL_TRANSLATIONS_STATUS, EditableStatus.Saved);
      })
      .catch((error) => {
        error.errorMessage = ErrorMessages.DATA_LOAD_ERROR;

        commit(SettingsMutations.SET_EMAIL_TRANSLATIONS_STATUS, EditableStatus.Failed);
        return dispatch(RootActions.ERROR, error, { root: true });
      });
  },
};

export default actions;
