import { GetterTree } from 'vuex';

import { ListStatus, RootState } from '@/store/types';
import { ProfileState, UserRole } from '@/store/modules/user/profile/types';
import CountryRelationship from '@/store/modules/countries/country-relationship';
import { createEditableGetters } from '@/store/utils/editable';

const editableGetters = createEditableGetters<ProfileState>(
  'editableStatus',
  'editableProfile',
  'initialEditableProfile',
);

export const moduleGetters: GetterTree<ProfileState, RootState> = {
  /**
   * Current profile aliases
   */
  currentProfile: (state) => state.currentProfile,
  displayName: (state) => state.currentProfile.displayName,
  profileImage: (state) => state.currentProfile.thumbnail,
  firstName: (state) => state.currentProfile.firstName,
  products: (state) => state.currentProfile.products,
  userId: (state) => state.currentProfile.id,
  role: (state) => state.currentProfile.role,
  blogToken: (state) => state.currentProfile.blogToken,
  agreeUpgrade: (state) => state.currentProfile.agreedToUpgrade === 1,
  isImpersonating: (state) => state.currentProfile.impersonating === 1,
  countries: (state) => (state.currentProfile.practice ? state.currentProfile.practice.countries : []),

  /**
   * Current profile roles
   */
  isSuperAdmin: (state) => state.currentProfile.role === UserRole.SuperAdmin,
  isAdmin: (state) => state.currentProfile.role === UserRole.Admin,
  isClinical: (state) => state.currentProfile.role === UserRole.Clinical,
  isSales: (state) => state.currentProfile.role === UserRole.Sales,
  isUser: (state) => state.currentProfile.role === UserRole.User,

  /**
   * Current profile practice
   */
  practiceId: (state, getters) =>
    getters.isUser && state.currentProfile.practice && state.currentProfile.practice.id
      ? state.currentProfile.practice.id
      : undefined,
  hasPractice: (state, getters) => getters.practiceId > 0,
  isUserWithoutPractice: (state, getters) => getters.isLoaded && getters.isUser && !getters.practiceId,

  /**
   * View
   */
  isInitial: (state) => state.status === ListStatus.Initial,
  isLoading: (state) => state.status === ListStatus.Loading,
  isLoaded: (state) => state.status === ListStatus.Loaded,
  isError: (state) => state.status === ListStatus.Failed,
  showTermsModal: (state, getters) =>
    getters.isLoaded &&
    getters.isUser &&
    !getters.isImpersonating &&
    (!state.currentProfile.termsAgree || !!state.show_terms_agree_modal),
  showTermsBanner: (state, getters) =>
    getters.isLoaded &&
    getters.isUser &&
    !getters.isImpersonating &&
    state.currentProfile.termsAgree &&
    !state.show_terms_agree_modal &&
    !state.local_terms_agree,
  isInNorthAmerica: (state, getters) =>
    Boolean(
      (getters.countries as CountryRelationship[]).find(
        (country) => ['us', 'ca'].indexOf(country.code.toLowerCase()) !== -1,
      ),
    ),
  isInCA: (state, getters) =>
    Boolean(
      (getters.countries as CountryRelationship[]).find(
        (country) => ['ca'].indexOf(country.code.toLowerCase()) !== -1,
      ),
    ),
  isInUK: (state, getters) =>
    Boolean(
      (getters.countries as CountryRelationship[]).find(
        (country) => ['gb', 'ie'].indexOf(country.code.toLowerCase()) !== -1,
      ),
    ),

  /**
   * Editable
   */
  editableIsLoading: editableGetters.isLoading,
  editableIsLoaded: editableGetters.isLoaded,
  editableIsSaving: editableGetters.isSaving,
  editableIsSaved: editableGetters.isSaved,
  editableIsFailed: editableGetters.isFailed,
  editableIsFetching: editableGetters.isFetching,
  editableHasUnsavedChanges: editableGetters.hasUnsavedChanges,
};

export default moduleGetters;
