import { JSONModel } from '@/store/models/jsonModel';

export interface EmailJSON {
  id: number;
  language_id: number;
  language_code: string;
  key: string;
  name: string;
  status: number;
  subject: string;
  body: string;
  button: string;
  help: string;
  created_at: string;
  updated_at: string;
  translations: {
    subject: Record<string, string>;
    body: Record<string, string>;
    button: Record<string, string>;
    help: Record<string, string>;
  };
  substitutions: Record<string, EmailSubstitution>;
}

export interface EmailSubstitution {
  key: string;
  readable: string;
  description: string;
}

export default class Email extends JSONModel<EmailJSON> {
  public id: number = 0;
  public languageId: number = 0;
  public languageCode: string = '';
  public key: string = '';
  public name: string = '';
  public status: number = 1;
  public subject: string = '';
  public body: string = '';
  public button: string = '';
  public help: string = '';
  public createdAt: string = '';
  public updatedAt: string = '';
  public translations: {
    subject: Record<string, string>;
    body: Record<string, string>;
    button: Record<string, string>;
    help: Record<string, string>;
  } = {
    subject: {},
    body: {},
    button: {},
    help: {},
  };
  public substitutions: Record<string, EmailSubstitution> = {};
  protected _clientProperties = ['translations'];

  public fromJSON(json: Partial<EmailJSON>) {
    if (json.id) {
      this.id = Number(json.id);
    }

    if (json.language_id) {
      this.languageId = Number(json.language_id);
    }

    if (json.language_code) {
      this.languageCode = String(json.language_code);
    }

    if (json.key) {
      this.key = String(json.key);
    }

    if (json.name) {
      this.name = String(json.name);
    }

    if (json.status) {
      this.status = Number(json.status);
    }

    if (json.subject) {
      this.subject = String(json.subject);
    }

    if (json.body) {
      this.body = String(json.body);
    }

    if (json.button) {
      this.button = String(json.button);
    }

    if (json.help) {
      this.help = String(json.help);
    }

    if (json.created_at) {
      this.createdAt = String(json.created_at);
    }

    if (json.updated_at) {
      this.updatedAt = String(json.updated_at);
    }

    if (json.translations) {
      this.translations = json.translations;
    }

    if (json.substitutions) {
      this.substitutions = json.substitutions;
    }
  }
}
