
import Vue from 'vue';
import { mapGetters } from 'vuex';

import AdminDashboard from './admin.vue';
import UserDashboard from './user.vue';

export default Vue.extend({
  name: 'DashboardVue',
  components: {
    AdminDashboard,
    UserDashboard,
  },
  computed: {
    ...mapGetters('user/profile', ['isSuperAdmin', 'isAdmin']),
  },
});
