import { EditableStatus, ListStatus } from '@/store/types';
import FAQ from './faq';

export enum SupportActions {
  LOAD_FAQ_LIST = 'LOAD_FAQ_LIST',

  /**
   * Edit
   */
  LOAD_FAQ_EDITABLE = 'LOAD_FAQ_EDITABLE',
  SAVE_FAQ_EDITABLE = 'SAVE_FAQ_EDITABLE',
  UPDATE_FAQ_EDITABLE = 'UPDATE_FAQ_EDITABLE',
  UPDATE_FAQ_EDITABLE_LIST = 'UPDATE_FAQ_EDITABLE_LIST',
  SAVE_FAQ_TRANSLATIONS = 'SAVE_FAQ_TRANSLATIONS',
}

export enum SupportMutations {
  SET_FAQ_LIST = 'SET_FAQ_LIST',
  SET_FAQ_LIST_STATUS = 'SET_FAQ_LIST_STATUS',

  /**
   * Edit
   */
  SET_FAQ_EDITABLE = 'SET_FAQ_EDITABLE',
  UPDATE_FAQ_EDITABLE = 'UPDATE_FAQ_EDITABLE',
  SET_FAQ_EDITABLE_STATUS = 'SET_FAQ_EDITABLE_STATUS',
  SET_FAQ_TRANSLATIONS_STATUS = 'SET_FAQ_TRANSLATIONS_STATUS',
}

export enum FAQStatus {
  Inactive,
  Active,
}

export interface SupportState {
  /**
   * List
   */
  faqList: FAQ[];
  faqListStatus: ListStatus;

  /**
   * Edit
   */
  faqEditable?: FAQ[];
  initialFAQEditable?: FAQ[];
  faqEditableStatus: EditableStatus;
  faqTranslationsStatus: EditableStatus;
}
