
import Vue from 'vue';
import { mapState, mapActions, mapGetters } from 'vuex';
import { PractitionersActions } from '@/store/modules/practitioners/types';
import PractitionerForm from '@/components/forms/practitioner.vue';
import PractitionerLocationsForm from '@/components/forms/practitioner-locations.vue';
import NotificationBanner from '@/components/blocks/notification-banner.vue';
import Publish from '@/components/forms/publish.vue';
import { PublishStatus } from '@/store/types';

export default Vue.extend({
  name: 'EditPractitionerView',
  $_veeValidate: {
    validator: 'new',
  },
  components: {
    PractitionerForm,
    PractitionerLocationsForm,
    NotificationBanner,
    Publish,
  },
  props: {
    add: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    formTitle() {
      return this.add ? this.$t('Add Practitioner') : this.$t('Edit Practitioner');
    },
    ...mapGetters('user/profile', ['isSuperAdmin', 'isAdmin']),
    ...mapState('practitioners', {
      editablePractitioner: 'editablePractitioner',
      editableStatus: 'editableStatus',
    }),
    ...mapGetters('practitioners', {
      practitionerIsLoading: 'editableIsLoading',
      practitionerIsLoaded: 'editableIsLoaded',
      practitionerIsSaving: 'editableIsSaving',
      practitionerIsSaved: 'editableIsSaved',
      practitionerHasUnsavedChanges: 'editableHasUnsavedChanges',
      statusLabel: 'editableStatusLabel',
    }),
    isFullyLoaded() {
      return this.practitionerIsLoaded || this.practitionerIsSaving || this.practitionerIsSaved;
    },
    isFetching() {
      return this.practitionerIsLoading || this.practitionerIsSaving;
    },
    isInactive() {
      return this.editablePractitioner.status === PublishStatus.Deleted;
    },
    isPublished() {
      return this.editablePractitioner.status === PublishStatus.Published;
    },
    isDraft() {
      return this.editablePractitioner.status === PublishStatus.Draft;
    },
    canPublish() {
      return this.add || this.isAdmin || this.isSuperAdmin;
    },
    saveLabel() {
      if (this.canPublish && !this.isPublished) {
        return this.$t('Publish');
      }

      return this.$t('Update');
    },
  },
  methods: {
    ...mapActions('practitioners', {
      savePractitioner: PractitionersActions.SAVE_EDITABLE,
      updatePractitioner: PractitionersActions.UPDATE_EDITABLE,
      removePractitioner: PractitionersActions.DELETE,
      restorePractitioner: PractitionersActions.RESTORE,
    }),
    save({ isDraft = false } = {}) {
      if (isDraft) {
        return this.updatePractitioner({
          status: PublishStatus.Draft,
        }).then(() => this.savePractitioner());
      }

      if (this.isAdmin || this.isSuperAdmin) {
        return this.updatePractitioner({
          status: PublishStatus.Published,
        }).then(() => this.savePractitioner());
      }

      return this.updatePractitioner({
        status: PublishStatus.Pending,
      }).then(() => this.savePractitioner());
    },
    remove() {
      this.removePractitioner({ id: this.editablePractitioner.id });
    },
    restore() {
      this.restorePractitioner({ id: this.editablePractitioner.id });
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.practitionerHasUnsavedChanges) {
      const answer = window.confirm(this.$t('Do you really want to leave? You have unsaved changes!'));
      if (answer) {
        next();
      } else {
        next(false);
      }
    } else {
      next();
    }
  },
});
