import Vue from 'vue';
import { MutationTree } from 'vuex';

import { ProfileState, ProfileMutations, TERMS_AGREE_KEY } from '@/store/modules/user/profile/types';
import { updateEditable } from '@/store/utils/editable';
import { Profile } from './profile';
import { EditableStatus, ListStatus } from '@/store/types';

export const mutations: MutationTree<ProfileState> = {
  [ProfileMutations.SET_CURRENT_PROFILE](state, data: Profile) {
    Vue.set(state, 'currentProfile', data);
  },
  [ProfileMutations.SET_EDITABLE_PROFILE](state, data: Profile) {
    Vue.set(state, 'editableProfile', data);
    Vue.set(state, 'initialEditableProfile', data.clone());
  },
  [ProfileMutations.UPDATE_EDITABLE_PROFILE]: updateEditable('editableProfile'),
  [ProfileMutations.SET_CURRENT_PROFILE_STATUS](state, status: ListStatus) {
    state.status = status;
  },
  [ProfileMutations.SET_EDITABLE_PROFILE_STATUS](state, status: EditableStatus) {
    state.editableStatus = status;
  },
  [ProfileMutations.SET_TERMS_AGREE](state, payload: 0 | 1) {
    state.currentProfile.termsAgree = payload;

    if (state.editableProfile) {
      state.editableProfile.termsAgree = payload;
    }

    state.local_terms_agree = payload;
    state.show_terms_agree_modal = 0;

    if (payload) {
      localStorage.setItem(TERMS_AGREE_KEY, 'true');
    } else {
      localStorage.removeItem(TERMS_AGREE_KEY);
    }
  },
  [ProfileMutations.SHOW_TERMS_AGREE_MODAL](state) {
    state.show_terms_agree_modal = 1;
  },
};

export default mutations;
