import { createCRUDEndpoints } from '@/api/factory/crud';
import { AccessoryJSON } from '@/store/modules/products/accessory';
import { AccessoryTreeItemJSON } from '@/store/modules/products/accessory-tree';
import { PublishStatus } from '@/store/types';
import { createTranslateEndpoint } from '../factory/translate';
import httpClient from '../http-client';
import { RequestConfig } from '../types';

type ListParams =
  | {
      title?: string;
      search?: string;
      status?: PublishStatus;
      page?: string | number;
      sort?: string;
      direction?: 'asc' | 'desc';
    }
  | undefined;

export const { list, get, store, remove, restore } = createCRUDEndpoints<AccessoryJSON, ListParams>({
  slug: 'accessory',
});

export const translate = createTranslateEndpoint<AccessoryJSON>({ slug: 'accessory' });

export function listTree(config?: RequestConfig) {
  return httpClient.get<AccessoryTreeItemJSON[]>('accessories/tree', config);
}
