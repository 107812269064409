
import Vue from 'vue';

export default Vue.extend({
  name: 'ModalFormLayout',
  props: {
    form: {
      type: Function,
    },
  },
});
