import { JSONModel } from '@/store/models/jsonModel';
import { PracticeStatus } from './types';

const availableStatuses: number[] = Object.keys(PracticeStatus).map((key) => PracticeStatus[key]);

export interface PracticeRelationshipJSON {
  id: number;
  status: number;
  title: string;
  country_id: number;
}

export default class PracticeRelationship extends JSONModel<PracticeRelationshipJSON> {
  public id: number = 0;
  public status: PracticeStatus = PracticeStatus.Published;
  public title: string = '';
  public country_id: number = 0;

  public fromJSON(json: Partial<PracticeRelationshipJSON>) {
    this.id = Number(json.id);
    this.country_id = Number(json.country_id);

    if (typeof json.status !== 'undefined' && availableStatuses.indexOf(json.status) !== -1) {
      this.status = json.status;
    }

    if (json.title) {
      this.title = String(json.title);
    }
  }
}
