import Vue from 'vue';
import { MutationTree } from 'vuex';

import { ListStatus, EditableStatus } from '@/store/types';
import Pagination from '@/store/models/pagination';
import { updateEditable } from '@/store/utils/editable';

import { ConsultationRequestsState, ConsultationRequestsMutations } from './types';
import ConsultationRequest from './consultation-request';
import ConsultationStats from './stats';

const mutations: MutationTree<ConsultationRequestsState> = {
  /**
   * List
   */
  [ConsultationRequestsMutations.SET_LIST](state, payload: ConsultationRequest[]) {
    state.list = payload;
  },
  [ConsultationRequestsMutations.SET_LIST_STATUS](state, payload: ListStatus) {
    state.listStatus = payload;
  },
  [ConsultationRequestsMutations.SET_LIST_PAGINATION](state, payload: Pagination) {
    state.listPagination = payload;
  },
  [ConsultationRequestsMutations.SET_LESS_QUALIFIED_LIST](state, payload: ConsultationRequest[]) {
    state.lessQualifiedList = payload;
  },
  [ConsultationRequestsMutations.SET_LESS_QUALIFIED_LIST_STATUS](state, payload: ListStatus) {
    state.lessQualifiedListStatus = payload;
  },
  [ConsultationRequestsMutations.SET_LESS_QUALIFIED_LIST_PAGINATION](state, payload: Pagination) {
    state.lessQualifiedListPagination = payload;
  },

  /**
   * Edit
   */
  [ConsultationRequestsMutations.SET_EDITABLE](state, consultationRequest: ConsultationRequest) {
    Vue.set(state, 'editable', consultationRequest);
    Vue.set(state, 'initialEditable', ConsultationRequest.clone(consultationRequest));
  },
  [ConsultationRequestsMutations.SET_EDITABLE_STATUS](state, editableStatus: EditableStatus) {
    state.editableStatus = editableStatus;
  },
  [ConsultationRequestsMutations.UPDATE_EDITABLE]: updateEditable<ConsultationRequestsState>('editable'),
  [ConsultationRequestsMutations.UPDATE_INITIAL_EDITABLE]: updateEditable<ConsultationRequestsState>('initialEditable'),

  /**
   * User stats
   */
  [ConsultationRequestsMutations.SET_USER_STATS](state, payload: ConsultationStats) {
    Vue.set(state, 'userStats', payload);
  },
  [ConsultationRequestsMutations.SET_USER_STATS_STATUS](state, payload: ListStatus) {
    state.userStatsStatus = payload;
  },
};

export default mutations;
