import { createCRUDEndpoints } from '@/api/factory/crud';
import { ReportJSON, ReportType } from '@/store/modules/reports/report';
import { PublishStatus } from '@/store/types';
import httpClient from '../http-client';
import { RequestConfig } from '../types';

type ListParams =
  | {
      title?: string;
      search?: string;
      status?: PublishStatus;
      page?: string | number;
      sort?: string;
      direction?: 'asc' | 'desc';
    }
  | undefined;

export const { list } = createCRUDEndpoints<ReportJSON, ListParams>({
  slug: 'report',
});

export function request(
  reportType: ReportType,
  filters: Record<string | number, number | string | (number | string)[]>,
) {
  return httpClient.get<{ success?: 0 | 1; report?: ReportJSON }>(`report/create/${reportType}`, { params: filters });
}

export function get(ids: number[], config?: RequestConfig) {
  return httpClient.get<ReportJSON[] | undefined>(`reports/${ids.join(',')}`, config);
}
