import { JSONModel } from './jsonModel';

export interface LanguageJSON {
  id: number;
  code: string;
  name: string;
  description: string;
  status: 0 | 1;
  asset_count: number;
}

export default class Language extends JSONModel<LanguageJSON> {
  public id: number = 0;
  public code: string = '';
  public name: string = '';
  public description: string = '';
  public status: 0 | 1 = 0;
  public assetCount: number = 0;

  public fromJSON(json: Partial<LanguageJSON>) {
    if (json.id) {
      this.id = Number(json.id);
    }

    if (json.code) {
      this.code = String(json.code);
    }

    if (json.name) {
      this.name = String(json.name);
    }

    if (json.description) {
      this.description = String(json.description);
    }

    if (json.status && (json.status === 1 || json.status === '1')) {
      this.status = 1;
    }

    if (json.asset_count) {
      this.assetCount = Number(json.asset_count);
    }
  }
}
