import Vue from 'vue';
export const tableViewMixin = Vue.extend({
  data() {
    return {
      sort: 'title',
      direction: 'DESC',
    };
  },
  props: {
    paginated: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    hasMorePages(): boolean {
      return this.pagination.totalItems > this.pagination.pageSize ? true : false;
    },
  },
  methods: {
    handlePageRequest(pageNumber: number): void {
      this.$router.push({
        params: { page: pageNumber },
        query: { ...this.$route.query },
      });
    },
    initSort(): void {
      this.sort = this.$router.currentRoute.query.sort || '';
      this.direction = this.$router.currentRoute.query.direction || '';
    },
    applySort(field: string): void {
      if (this.paginated) {
        if (this.isCurrentSort(field)) {
          this.direction = this.toggleDirection(this.direction);
        } else {
          this.sort = field;
          this.direction = 'DESC';
        }
        this.$router.push({
          params: {
            page: 1,
          },
          query: {
            ...this.$route.query,
            sort: this.sort,
            direction: this.direction,
          },
        });
      }
    },
    isCurrentSort(field: string): boolean {
      return this.sort === field ? true : false;
    },
    toggleDirection(direction: string): string {
      if (direction === 'ASC') {
        return 'DESC';
      } else {
        return 'ASC';
      }
    },
    columnClasses(column: string): string[] {
      const classes: string[] = ['has-sort'];

      if (this.isCurrentSort(column)) {
        classes.push('is-selected');
        classes.push(this.direction);
      }

      return classes;
    },
  },
  created() {
    this.initSort();
  },
  watch: {
    $route() {
      this.initSort();
    },
  },
});

export default tableViewMixin;
