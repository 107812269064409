/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'social/facebook': {
    width: 13,
    height: 24,
    viewBox: '0 0 13 24',
    data: '<path pid="0" d="M9.5 4h3a.5.5 0 00.5-.5v-3a.5.5 0 00-.5-.5h-3A5.51 5.51 0 004 5.5V9H.5a.5.5 0 00-.5.5v3a.5.5 0 00.5.5H4v10.5a.5.5 0 00.5.5h3a.5.5 0 00.5-.5V13h3.5a.51.51 0 00.48-.34l1-3a.52.52 0 00-.07-.45.52.52 0 00-.41-.21H8V5.5A1.5 1.5 0 019.5 4z"/>'
  }
})
