import Vue from 'vue';
import { addURLParams } from '@/utils/url';

// Reusable size/crop imgix parameters
export const imageSizeMixin = Vue.extend({
  methods: {
    sizedPractitionerProfileImage(url: string): string {
      return addURLParams(url, {
        fit: 'facearea',
        facepad: '10',
        w: '150',
        h: '150',
      });
    },
    sizedPractitionerProfileImageLarge(url: string): string {
      return addURLParams(url, {
        fit: 'facearea',
        facepad: '10',
        w: '800',
        h: '400',
        q: '20',
      });
    },
    sizedLocationMap(url: string): string {
      return addURLParams(url, {
        fit: 'crop',
        w: '800',
        h: '400',
        q: '20',
      });
    },
    sizedUserProfileImage(url: string): string {
      return addURLParams(url, {
        fit: 'facearea',
        facepad: '3',
        w: '150',
        h: '150',
      });
    },
    sizedResourceThumbnail(url: string): string {
      return addURLParams(url, {
        'w': '480',
        'h': '660',
        'fit': 'crop',
        'crop': 'focalpoint',
        'fp-x': '0',
        'fp-y': '0',
      });
    },
    sizedResourcePreview(url: string): string {
      return addURLParams(url, {
        'w': '480',
        'h': '660',
        'fit': 'crop',
        'crop': 'focalpoint',
        'fp-x': '0',
        'fp-y': '0',
      });
    },
    sizedPracticeLogo(url: string): string {
      return addURLParams(url, {
        'w': '480',
        'fit': 'crop',
        'crop': 'focalpoint',
        'fp-x': '0',
        'fp-y': '0',
      });
    },
  },
});

export default imageSizeMixin;
