import { ActionTree } from 'vuex';

import router from '@/router';
import i18next from '@/i18n';
import api from '@/api';

import { RootState, ListStatus, RootActions, ErrorMessages, EditableStatus } from '@/store/types';
import Pagination from '@/store/models/pagination';
import { NotificationsActions } from '@/store/modules/notifications/types';
import { prepareStatusFilter } from '@/store/utils/filters';
import { EventsState, EventsActions, EventsMutations, EventStatus } from './types';
import Event from './event';
import { enhanceErrorWithMessage } from '@/utils/errors';

export const actions: ActionTree<EventsState, RootState> = {
  async [EventsActions.LOAD_LIST]({ commit, dispatch }, filters = {}) {
    commit(EventsMutations.SET_LIST_STATUS, ListStatus.Loading);

    try {
      const res = await api.events.list(prepareStatusFilter(filters));

      commit(EventsMutations.SET_LIST_PAGINATION, Pagination.fromJSON(res.data));
      commit(
        EventsMutations.SET_LIST,
        res.data.data.map((item) => Event.fromJSON(item)),
      );
      commit(EventsMutations.SET_LIST_STATUS, ListStatus.Loaded);
    } catch (error) {
      enhanceErrorWithMessage(error, ErrorMessages.DATA_LOAD_ERROR);

      commit(EventsMutations.SET_LIST_STATUS, ListStatus.Failed);
      return dispatch(RootActions.ERROR, error, { root: true });
    }
  },
  [EventsActions.LOAD_EDITABLE]({ commit, dispatch }, { id } = {}): Promise<void> {
    commit(EventsMutations.SET_EDITABLE_STATUS, EditableStatus.Loading);

    if (!id) {
      const event = new Event();

      commit(EventsMutations.SET_EDITABLE, event);
      commit(EventsMutations.SET_EDITABLE_STATUS, EditableStatus.Loaded);
      return Promise.resolve();
    }

    return api.events
      .get(id)
      .then((response) => {
        commit(EventsMutations.SET_EDITABLE, Event.fromJSON(response.data));
        commit(EventsMutations.SET_EDITABLE_STATUS, EditableStatus.Loaded);
      })
      .catch((error) => {
        enhanceErrorWithMessage(error, ErrorMessages.DATA_LOAD_ERROR);

        commit(EventsMutations.SET_EDITABLE_STATUS, EditableStatus.Failed);
        return dispatch(RootActions.ERROR, error, { root: true });
      });
  },

  [EventsActions.UPDATE_EDITABLE]({ commit }, changes): Promise<void> {
    commit(EventsMutations.UPDATE_EDITABLE, changes);
    return Promise.resolve();
  },

  [EventsActions.SAVE_EDITABLE]({ state, commit, dispatch }): Promise<void> {
    let isUpdate: boolean;
    let successMsg: string;

    return new Promise<void>((resolve, reject) => {
      if (state.editable) {
        return resolve();
      }

      reject(new Error('Invalid data!'));
    })
      .then(() => {
        commit(EventsMutations.SET_EDITABLE_STATUS, EditableStatus.Saving);

        isUpdate = Boolean(state.editable!.id);
        successMsg = i18next.t('A new event was added successfully.');

        if (isUpdate) {
          successMsg = i18next.t('The event was successfully updated.');
        }
      })
      .then(() => api.events.store(state.editable!.toJSON()))
      .then((response) => response.data)
      .then((response: object): Promise<Event> => {
        const event = Event.fromJSON(response);

        commit(EventsMutations.SET_EDITABLE, event);
        commit(EventsMutations.SET_EDITABLE_STATUS, EditableStatus.Saved);

        if (!isUpdate) {
          return new Promise((resolve, reject) => {
            if (!event.id) {
              return reject(new Error('Invalid data.'));
            }

            router.replace(
              {
                name: 'edit-event',
                params: {
                  id: String(event.id),
                },
              },
              () => resolve(event),
              (error: any) => reject(error),
            );
          });
        }

        return Promise.resolve(event);
      })
      .then(() => {
        return dispatch(
          'notifications/' + NotificationsActions.SHOW_SUCCESS,
          {
            body: successMsg,
          },
          { root: true },
        );
      })
      .catch((error) => {
        enhanceErrorWithMessage(error, ErrorMessages.DATA_LOAD_ERROR);

        if (error && error.response && error.response.data && error.response.data.errors) {
          const errorValues = Object.keys(error.response.data.errors)
            // Grab object values
            .map((key) => error.response.data.errors[key]);

          const errorStrings: string[] = []
            .concat(...errorValues)
            // Filter out empty strings
            .filter(Boolean);

          if (errorStrings.length) {
            enhanceErrorWithMessage(error, errorStrings.join('\n'));
          }
        }

        commit(EventsMutations.SET_EDITABLE_STATUS, EditableStatus.Failed);
        return dispatch(RootActions.ERROR, error, { root: true });
      });
  },
  [EventsActions.DELETE]({ commit, dispatch }, { id = 0 }: { id?: number } = {}): Promise<void> {
    commit(EventsMutations.SET_EDITABLE_STATUS, EditableStatus.Saving);

    return new Promise<void>((resolve, reject) => {
      if (!id) {
        return reject(new Error('Invalid data!'));
      }

      resolve();
    })
      .then(() => api.events.remove(id))
      .then(() => {
        commit(EventsMutations.UPDATE_INITIAL_EDITABLE, {
          status: EventStatus.Inactive,
        });
        commit(EventsMutations.UPDATE_EDITABLE, {
          status: EventStatus.Inactive,
        });
        commit(EventsMutations.SET_EDITABLE_STATUS, EditableStatus.Saved);
      })
      .then(() => {
        return dispatch(
          'notifications/' + NotificationsActions.SHOW_SUCCESS,
          {
            body: i18next.t('The event was deleted successfully.'),
          },
          { root: true },
        );
      })
      .catch((error) => {
        enhanceErrorWithMessage(error, ErrorMessages.DATA_LOAD_ERROR);

        commit(EventsMutations.SET_EDITABLE_STATUS, EditableStatus.Failed);
        return dispatch(RootActions.ERROR, error, { root: true });
      });
  },
  [EventsActions.RESTORE]({ commit, dispatch }, { id = 0 }: { id?: number } = {}): Promise<void> {
    const isEdit = router.currentRoute.name === 'edit-event';
    if (isEdit) {
      commit(EventsMutations.SET_EDITABLE_STATUS, EditableStatus.Saving);
    } else {
      commit(EventsMutations.SET_LIST_STATUS, ListStatus.Loading);
    }

    return new Promise<void>((resolve, reject) => {
      if (!id) {
        return reject(new Error('Invalid data!'));
      }

      resolve();
    })
      .then(() => api.events.restore(id))
      .then(() => {
        if (isEdit) {
          commit(EventsMutations.UPDATE_INITIAL_EDITABLE, {
            status: EventStatus.Active,
          });
          commit(EventsMutations.UPDATE_EDITABLE, {
            status: EventStatus.Active,
          });
          commit(EventsMutations.SET_EDITABLE_STATUS, EditableStatus.Saved);
        } else {
          dispatch(EventsActions.LOAD_LIST, {
            ...router.currentRoute.params,
            ...router.currentRoute.query,
          });
        }
      })
      .then(() =>
        dispatch(
          'notifications/' + NotificationsActions.SHOW_SUCCESS,
          {
            body: i18next.t('The event was restored successfully.'),
          },
          { root: true },
        ),
      )
      .catch((error) => {
        enhanceErrorWithMessage(error, ErrorMessages.DATA_LOAD_ERROR);

        if (isEdit) {
          commit(EventsMutations.SET_EDITABLE_STATUS, EditableStatus.Failed);
        } else {
          commit(EventsMutations.SET_LIST_STATUS, ListStatus.Failed);
        }

        return dispatch(RootActions.ERROR, error, { root: true });
      });
  },
  [EventsActions.BULK_RESTORE]({ commit, dispatch }, { ids = [] }: { ids: number[] } = { ids: [] }): Promise<void> {
    commit(EventsMutations.SET_LIST_STATUS, ListStatus.Loading);

    return new Promise<void>((resolve, reject) => {
      if (!ids || !ids.length) {
        return reject(new Error('Invalid data!'));
      }

      resolve();
    })
      .then(() => Promise.all(ids.map((id) => api.events.restore(id))))
      .then(() => {
        dispatch(EventsActions.LOAD_LIST, {
          ...router.currentRoute.params,
          ...router.currentRoute.query,
        });

        return dispatch(
          'notifications/' + NotificationsActions.SHOW_SUCCESS,
          {
            body: i18next.t('%(count)% event was restored successfully', { count: ids.length }),
          },
          { root: true },
        );
      })
      .catch((error) => {
        enhanceErrorWithMessage(error, ErrorMessages.DATA_LOAD_ERROR);

        commit(EventsMutations.SET_LIST_STATUS, ListStatus.Failed);

        return dispatch(RootActions.ERROR, error, { root: true });
      });
  },
  [EventsActions.BULK_DELETE]({ commit, dispatch }, { ids = [] }: { ids: number[] } = { ids: [] }): Promise<void> {
    commit(EventsMutations.SET_LIST_STATUS, ListStatus.Loading);

    return new Promise<void>((resolve, reject) => {
      if (!ids || !ids.length) {
        return reject(new Error('Invalid data!'));
      }

      resolve();
    })
      .then(() => Promise.all(ids.map((id) => api.events.remove(id))))
      .then(() => {
        dispatch(EventsActions.LOAD_LIST, {
          ...router.currentRoute.params,
          ...router.currentRoute.query,
        });

        return dispatch(
          'notifications/' + NotificationsActions.SHOW_SUCCESS,
          {
            body: i18next.t('%(count)% event was deleted successfully', { count: ids.length }),
          },
          { root: true },
        );
      })
      .catch((error) => {
        enhanceErrorWithMessage(error, ErrorMessages.DATA_LOAD_ERROR);

        commit(EventsMutations.SET_LIST_STATUS, ListStatus.Failed);

        return dispatch(RootActions.ERROR, error, { root: true });
      });
  },
};

export default actions;
