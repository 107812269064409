/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'images': {
    width: 16,
    height: 16,
    viewBox: '0 0 22 22',
    data: '<path pid="0" d="M3.9 14.8l-.9-.5 3-5c.2-.2.3-.3.5-.3s.3.1.4.2l2 2.4L11 6.8c.1-.2.3-.3.5-.3s.4.1.4.3l3 7.5-.9.4-2.5-6.4-2 4.4c-.1.3-.4.4-.7.2-.1 0-.1-.1-.2-.1l-2-2.5-2.7 4.5z"/><path pid="1" d="M17.5 18H.5c-.3 0-.5-.2-.5-.5V.5C0 .2.2 0 .5 0h17c.3 0 .5.2.5.5v17c0 .3-.2.5-.5.5zM1 17h16V1H1v16z"/><path pid="2" d="M20.1 22l-15-1.4c-.3 0-.5-.3-.5-.5s.3-.5.5-.5L19.7 21 21 7l-1.5-.1c-.3 0-.5-.3-.5-.5 0-.3.3-.5.5-.5l2 .2c.3 0 .5.3.5.5l-1.4 15c0 .2-.2.4-.5.4z"/><path pid="3" d="M.5 14h17v1H.5zM5 7c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2zm0-3c-.6 0-1 .4-1 1s.4 1 1 1 1-.4 1-1-.4-1-1-1z"/>'
  }
})
