import { JSONModel } from './jsonModel';

export interface TaxonomyJSON {
  id?: number;
  title?: string;
}
export default class Taxonomy extends JSONModel<TaxonomyJSON> {
  public id: number = 0;
  public title: string = '';

  public fromJSON(json: TaxonomyJSON) {
    this.id = Number(json.id);
    this.title = String(json.title);
  }
}
