
import Vue from 'vue';
import { mapState } from 'vuex';

export default Vue.extend({
  data() {
    return {
      bannerStyle: {
        width: '100%',
        padding: '10px 20px',
        background: 'black',
        color: 'white',
        marginBottom: '10px',
        borderRadius: '4px',
      },
    };
  },
  computed: {
    ...mapState('resources', ['laserCategoryId']),
  },
});
