import { JSONModel } from './jsonModel';

export interface CountryStateJSON {
  code: string;
  name: string;
}

export default class CountryState extends JSONModel<CountryStateJSON> {
  public code: string = '';
  public name: string = '';

  public fromJSON(json: Partial<CountryStateJSON>) {
    if (json.code) {
      this.code = String(json.code);
    }

    if (json.name) {
      this.name = String(json.name);
    }
  }
}
