import { GetterTree } from 'vuex';

import { RootState, ListStatus } from '@/store/types';
import { SettingsState } from './types';
import { createEditableGetters } from '@/store/utils/editable';

const {
  isLoading: editableSettingsIsLoading,
  isLoaded: editableSettingsIsLoaded,
  isSaving: editableSettingsIsSaving,
  isSaved: editableSettingsIsSaved,
  isFailed: editableSettingsIsFailed,
  hasUnsavedChanges: editableSettingsHasUnsavedChanges,
} = createEditableGetters<SettingsState>('editableSettingsStatus', 'editableSettings', 'initialEditableSettings');

export const moduleGetters: GetterTree<SettingsState, RootState> = {
  /**
   * List
   */
  settingsIsLoading: (state) => (state.settingsStatus === ListStatus.Loading ? true : false),
  settingsIsLoaded: (state) => (state.settingsStatus === ListStatus.Loaded ? true : false),
  settingsIsError: (state) => (state.settingsStatus === ListStatus.Failed ? true : false),

  showTopBanner: (state) => {
    if (state.settingsStatus === ListStatus.Loaded) {
      if (Boolean(state.settings.topBanner.status)) {
        if (state.settings.topBanner.hash !== state.topBannerClosed) {
          return true;
        }
      }
    }
    return false;
  },

  /**
   * Editable
   */
  editableSettingsIsLoading,
  editableSettingsIsLoaded,
  editableSettingsIsSaving,
  editableSettingsIsSaved,
  editableSettingsIsFailed,
  editableSettingsHasUnsavedChanges,

  topBanner: (state) => state.settings.topBanner,
  topBannerShow: (state) => (state.settings.topBanner.status ? true : false),

  activeCountries: (state) => state.countries.filter((item) => item.status === 1),
};

export default moduleGetters;
