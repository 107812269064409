
import Vue from 'vue';
import UserWidget from '@/components/blocks/user-widget.vue';
import FeaturedResourcesWidget from '@/components/blocks/featured-resources-widget.vue';
import FaqWidget from '@/components/blocks/faq-widget.vue';
import ContactWidget from '@/components/blocks/contact-widget.vue';
import ConsultationsWidget from '@/components/blocks/consultations-widget.vue';
import EventsWidget from '@/components/blocks/events-widget.vue';
import LaserResourcesBannerWidget from '@/components/blocks/laser-resources-banner-widget.vue';
import TrainingResourcesBannerWidget from '@/components/blocks/training-resources-banner-widget.vue';

export default Vue.extend({
  name: 'DashboardView',
  components: {
    UserWidget,
    FeaturedResourcesWidget,
    FaqWidget,
    ContactWidget,
    ConsultationsWidget,
    EventsWidget,
    LaserResourcesBannerWidget,
    TrainingResourcesBannerWidget,
  },
});
