/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'close': {
    width: 19.05,
    height: 19.04,
    viewBox: '0 0 19.05 19.04',
    data: '<path pid="0" d="M-.004 2.074L2.068.002l16.97 16.97-2.072 2.073z"/><path pid="1" d="M.003 16.973L16.973.003l2.072 2.071-16.97 16.97z"/>'
  }
})
