import { JSONModel } from '@/store/models/jsonModel';
import { Priority } from '@/store/types';
import CountryRelationship, { CountryRelationshipJSON } from '@/store/modules/countries/country-relationship';

export interface UserPracticeJSON {
  id: number;
  title: string;
  products: Record<string, string>;
  ads: Record<string, string>;
  hasSculpsure: boolean;
  priority: Priority;
  countries: CountryRelationshipJSON[];
}

export default class UserPractice extends JSONModel<UserPracticeJSON> {
  public id: number = 0;
  public title: string = '';
  public products: Record<string, string> = {};
  public ads: Record<string, string> = {};
  public hasSculpsure: boolean = false;
  public priority: Priority = Priority.silver;
  public countries: CountryRelationship[] = [];

  public fromJSON(json: Partial<UserPracticeJSON>) {
    if (json.id) {
      this.id = Number(json.id);
    }

    if (json.title) {
      this.title = String(json.title);
    }

    if (json.products) {
      this.products = json.products;
    }

    if (json.ads) {
      this.ads = json.ads;
    }

    if (json.hasSculpsure) {
      this.hasSculpsure = json.hasSculpsure;
    }

    if (json.priority) {
      this.priority = json.priority;
    }

    if (json.countries && Array.isArray(json.countries)) {
      this.countries = json.countries.map((item) => CountryRelationship.fromJSON(item));
    }
  }
}
