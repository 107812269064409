import { Module } from 'vuex';

import { ListStatus, RootState } from '@/store/types';
import Pagination from '@/store/models/pagination';

import { StatisticsState } from './types';
import actions from './actions';
import mutations from './mutations';
import getters from './getters';

const getInitialState = (): StatisticsState => ({
  /**
   * Downloads
   */
  downloads: [],
  downloadsPagination: new Pagination(),
  downloadsStatus: ListStatus.Loading,
  totalDownloads: 0,

  /**
   * Logins
   */
  logins: [],
  loginsPagination: new Pagination(),
  loginsStatus: ListStatus.Loading,

  /**
   * Users
   */
  users: [],
  usersPagination: new Pagination(),
  usersStatus: ListStatus.Loading,

  /**
   * Trainings
   */
  trainings: [],
  trainingsPagination: new Pagination(),
  trainingsStatus: ListStatus.Loading,
  totalTrainings: 0,
});

const state = getInitialState();

const module: Module<StatisticsState, RootState> = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};

export default module;
