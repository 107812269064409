import Vue from 'vue';
import Vuex, { StoreOptions } from 'vuex';
import { RootState, ListStatus } from './types';
import actions from './actions';
import mutations from './mutations';
import getters from './getters';
import user from './modules/user';
import resources from './modules/resources';
import notifications from './modules/notifications';
import locations from './modules/locations';
import practitioners from './modules/practitioners';
import categories from './modules/categories';
import treatments from './modules/treatments';
import practices from './modules/practices';
import products from './modules/products';
import reports from './modules/reports';
import users from './modules/users';
import blog from './modules/blog';
import settings from './modules/settings';
import consultations from './modules/consultations';
import events from './modules/events';
import statistics from './modules/statistics';
import support from './modules/support';
import privacy_requests from './modules/privacy_requests';
import config from '@/config';
import progressIncrementPlugin from './plugins/progress-increment';

Vue.use(Vuex);

const state: RootState = {
  version: config.BUILD,
  errors: [],
  appEvents: [],
  loadProgress: 0,
  modal: {
    open: false,
    contents: '',
  },
  history: [],
  countries: [],
  countriesStatus: ListStatus.Initial,
  isIOS: Boolean(navigator.platform) && /iPad|iPhone|iPod/.test(navigator.platform),
  route: undefined,
  languages: [],
  languagesStatus: ListStatus.Initial,
};

const store: StoreOptions<RootState> = {
  state,
  getters,
  actions,
  mutations,
  plugins: [progressIncrementPlugin],
  modules: {
    user,
    resources,
    notifications,
    locations,
    practitioners,
    categories,
    treatments,
    practices,
    products,
    reports,
    users,
    blog,
    settings,
    consultations,
    events,
    statistics,
    support,
    privacy_requests,
  },
};

export default new Vuex.Store<RootState>(store);
