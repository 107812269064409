import i18n, { formatList } from '@/i18n';
import { MessageGenerator } from 'vee-validate';

export const formatFileSize = (size: number | string) => {
  const units = ['Byte', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const threshold = 1024;
  size = Number(size) * threshold;
  const i = size === 0 ? 0 : Math.floor(Math.log(size) / Math.log(threshold));
  const j = Math.pow(threshold, i);
  const unitSize = (size / j).toFixed(2);
  return `${unitSize} ${units[i]}`;
};

export const messages: { [key: string]: MessageGenerator } = {
  _default: (field) => i18n.t('The %(field)% value is not valid', { field }),
  after: (field, [target, inclusion]) => {
    if (inclusion) {
      return i18n.t('The %(field)% must be after or equal to %(target)%', { field, target });
    }
    return i18n.t('The %(field)% must be after %(target)%', { field, target });
  },
  alpha: (field) => i18n.t('The %(field)% field may only contain alphabetic characters', { field }),
  alpha_dash: (field) =>
    i18n.t('The %(field)% field may contain alpha-numeric characters as well as dashes and underscores', { field }),
  alpha_num: (field) => i18n.t('The %(field)% field may only contain alpha-numeric characters', { field }),
  alpha_spaces: (field) =>
    i18n.t('The %(field)% field may only contain alphabetic characters as well as spaces', { field }),
  before: (field, [target, inclusion]) => {
    if (inclusion) {
      return i18n.t('The %(field)% must be before or equal to %(target)%', { field, target });
    }
    return i18n.t('The %(field)% must be before %(target)%', { field, target });
  },
  between: (field, [min, max]) =>
    i18n.t('The %(field)% field must be between %(min)% and %(max)%', { field, min, max }),
  confirmed: (field) => i18n.t('The %(field)% confirmation does not match', { field }),
  credit_card: (field) => i18n.t('The %(field)% field is invalid', { field }),
  date_between: (field, [min, max]) => i18n.t('The %(field)% must be between %(min)% and %(max)%', { field, min, max }),
  date_format: (field, [format]) => i18n.t('The %(field)% must be in the format %(format)%', { field, format }),
  decimal: (field, [decimals = '*'] = []) => {
    if (!decimals || decimals === '*') {
      return i18n.t('The %(field)% field must be numeric and may contain decimal points', { field });
    }

    return i18n.t('The %(field)% field must be numeric and may contain %(decimals)% decimal points', {
      field,
      decimals,
    });
  },
  digits: (field, [length]) =>
    i18n.t('The %(field)% field must be numeric and contains exactly %(length)% digits', { field, length }),
  dimensions: (field, [width, height]) =>
    i18n.t('The %(field)% field must be %(width)% pixels by %(height)% pixels', { field, width, height }),
  email: (field) => i18n.t('The %(field)% field must be a valid email', { field }),
  excluded: (field) => i18n.t('The %(field)% field must be a valid value', { field }),
  ext: (field) => i18n.t('The %(field)% field must be a valid file', { field }),
  image: (field) => i18n.t('The %(field)% field must be an image', { field }),
  included: (field) => i18n.t('The %(field)% field must be a valid value', { field }),
  integer: (field) => i18n.t('The %(field)% field must be an integer', { field }),
  ip: (field) => i18n.t('The %(field)% field must be a valid ip address', { field }),
  ip_or_fqdn: (field) => i18n.t('The %(field)% field must be a valid ip address or FQDN', { field }),
  length: (field, [length, max]) => {
    if (max) {
      return i18n.t('The %(field)% length must be between %(length)% and %(max)%', { field, length, max });
    }

    return i18n.t('The %(field)% length must be %(length)%', { field, length });
  },
  max: (field, [length]) =>
    i18n.t('The %(field)% field may not be greater than %(length)% characters', { field, length }),
  max_value: (field, [max]) => i18n.t('The %(field)% field must be %(max)% or less', { field, max }),
  mimes: (field) => i18n.t('The %(field)% field must have a valid file type', { field }),
  min: (field, [length]) => i18n.t('The %(field)% field must be at least %(length)% characters', { field, length }),
  min_value: (field, [min]) => i18n.t('The %(field)% field must be %(min)% or more', { field, min }),
  numeric: (field) => i18n.t('The %(field)% field may only contain numeric characters', { field }),
  regex: (field) => i18n.t('The %(field)% field format is invalid', { field }),
  required: (field) => i18n.t('The %(field)% field is required', { field }),
  required_if: (field, [target]) =>
    i18n.t('The %(field)% field is required when the %(target)% field has this value', { field, target }),
  size: (field, [size]) =>
    i18n.t('The %(field)% size must be less than %(size)%', { field, size: formatFileSize(size) }),
  url: (field) => i18n.t('The %(field)% field is not a valid URL', { field }),
  checkBannedWords: (field, params, data) => {
    return i18n.t('Please remove the following words: %(bannedWords)%.', {
      bannedWords: formatList(data.map((item: string) => `<strong>${item}</strong>`)),
      interpolation: { escapeValue: false },
    });
  },
};
