
import Vue, { PropOptions } from 'vue';
import { mapState, mapGetters } from 'vuex';
import mixins from 'vue-typed-mixins';

import CustomInputComponent from '@/components/mixins/custom-input-component';

export default mixins(CustomInputComponent).extend({
  name: 'TreatmentEnhancementsControl',
  props: {
    value: {
      type: Array,
      default: () => [],
    } as PropOptions,
  },
  data() {
    return {};
  },
  computed: {
    ...mapState('treatments', ['enhancementList']),
    ...mapGetters('treatments', ['enhancementListIsLoading', 'enhancementListIsLoaded', 'enhancementListIsEmpty']),
  },
  methods: {
    handleInput({ target }: { target: HTMLInputElement }): void {
      const enhancementId = Number(target.value);
      const enhancement = this.enhancementList.find((item) => item.id === enhancementId);

      if (target.checked) {
        this.$emit('input', [...this.value, enhancement]);
      } else {
        this.$emit(
          'input',
          this.value.filter((item) => item.id !== enhancement.id),
        );
      }
    },
    isSelected(id: number): boolean {
      return this.value && this.value.find((enhancement) => enhancement.id === id);
    },
  },
});
