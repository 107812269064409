
import Vue from 'vue';
import { mapState, mapActions, mapGetters } from 'vuex';

import Checkbox from '@/components/controls/checkbox.vue';
import { ResourcesActions } from '@/store/modules/resources/types';
import SelectInput from '@/components/controls/select-input.vue';

export default Vue.extend({
  name: 'ResourcePersonalizationForm',
  components: {
    Checkbox,
    SelectInput,
  },
  computed: {
    ...mapState('resources', {
      resource: 'editable',
    }),
    ...mapGetters('resources', {
      isLoading: 'editableIsLoading',
      isSaving: 'editableIsSaving',
    }),
  },
  methods: {
    ...mapActions('resources', {
      updateResource: ResourcesActions.UPDATE_EDITABLE,
    }),
  },
});
