import { Module } from 'vuex';

import { RootState, EditableStatus, ListStatus } from '@/store/types';
import Pagination from '@/store/models/pagination';
import { PrivacyRequestState } from '@/store/modules/privacy_requests/types';

import actions from './actions';
import mutations from './mutations';
import getters from './getters';

export const getInitialState = (): PrivacyRequestState => ({
  /**
   * List
   */
  status: ListStatus.Loading,
  list: [],
  listPagination: new Pagination(),

  /**
   * Edit
   */
  editableStatus: EditableStatus.Loading,
});

export const state: PrivacyRequestState = getInitialState();

const categories: Module<PrivacyRequestState, RootState> = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

export default categories;
