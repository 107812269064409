
import Vue, { PropOptions } from 'vue';

import { genTimeChoices, genWeekDays } from '@/utils/time';

import Modal from '@/components/layout/modal.vue';
import FormInput from '@/components/controls/form-input.vue';
import SubmitButton from '@/components/controls/submit-button.vue';
import { PractitionerWorkingHour } from '@/store/modules/practitioners/practitioner-relationship';

const getWorkingHours = (hours) =>
  genWeekDays().map((dayLabel, day) => {
    const workingHour = hours.find((item) => item.day === day);
    let start = '';
    let end = '';
    let notes = '';

    if (workingHour) {
      if (workingHour.start) {
        start = workingHour.start;
      }

      if (workingHour.end) {
        end = workingHour.end;
      }

      if (workingHour.notes) {
        notes = workingHour.notes;
      }
    }

    return {
      day,
      start,
      end,
      notes,
    };
  });

export default Vue.extend({
  name: 'PractitionerHoursForm',
  props: {
    hours: {
      type: Array,
      default: () => [],
    } as PropOptions<PractitionerWorkingHour[]>,
  },
  components: {
    Modal,
    FormInput,
    SubmitButton,
  },
  data() {
    return {
      workingHours: getWorkingHours(this.hours),
      weekDays: genWeekDays(),
    };
  },
  computed: {
    isMondayDefined() {
      if (this.workingHours[0] && this.workingHours[0].start && this.workingHours[0].end) {
        return true;
      }

      return false;
    },
  },
  methods: {
    close() {
      this.$emit('close');
    },
    submit() {
      this.$emit(
        'change',
        this.workingHours.filter((workingDay) => Boolean(workingDay.start) && Boolean(workingDay.end)),
      );
      this.close();
    },
    updateTime(day: number, { start, end, notes }: { start?: string; end?: string; notes?: string }) {
      const workingHours = [...this.workingHours];

      if (typeof start !== 'undefined') {
        workingHours[day].start = start;
      }

      if (typeof end !== 'undefined') {
        workingHours[day].end = end;
      }

      if (typeof notes !== 'undefined') {
        workingHours[day].notes = notes;
      }

      this.workingHours = workingHours;
    },
    genTimeChoices,
    startChoices(day) {
      const workingDay = this.workingHours[day];

      if (workingDay.end) {
        return this.genTimeChoices(30, undefined, this.$moment(workingDay.end, 'HH:mm:ss'));
      }

      return this.genTimeChoices();
    },
    endChoices(day) {
      const workingDay = this.workingHours[day];

      if (workingDay.start) {
        return this.genTimeChoices(30, this.$moment(workingDay.start, 'HH:mm:ss').add(30, 'minutes'));
      }

      return this.genTimeChoices();
    },
    applyMondayToAll() {
      if (!this.isMondayDefined) {
        return;
      }

      for (let i = 1; i < 5; i++) {
        this.updateTime(i, { ...this.workingHours[0] });
      }
    },
  },
  watch: {
    hours: {
      handler(value) {
        this.workingHours = getWorkingHours(value);
      },
    },
  },
});
