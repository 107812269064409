
import Vue from 'vue';
import { mapState, mapGetters } from 'vuex';
import PractitionersList from '@/components/blocks/practitioners-list.vue';
import NotificationBanner from '@/components/blocks/notification-banner.vue';
import ViewToggleControl from '@/components/controls/view-toggle.vue';
import { formatNumber } from '@/i18n';

export default Vue.extend({
  name: 'PractitionersView',
  components: {
    PractitionersList,
    NotificationBanner,
    ViewToggleControl,
  },
  data() {
    return {
      listView: 'grid',
    };
  },
  computed: {
    isGridView() {
      return this.listView === 'grid' ? true : false;
    },
    hasMorePractitioners() {
      return this.pagination.total > 8 ? true : false;
    },
    ...mapState('practitioners', ['pagination']),
    ...mapGetters('practitioners', ['listIsLoading', 'listIsLoaded']),
    ...mapGetters('user/profile', ['isSuperAdmin', 'isAdmin']),
    totalItems(): string {
      if (this.listIsLoaded) {
        return formatNumber(this.pagination.total);
      }

      return '0';
    },
  },
  methods: {
    defaultView(numberOfItems) {
      return this.isSuperAdmin || this.isAdmin || numberOfItems > 5 ? 'table' : 'grid';
    },
    initDefaults() {
      this.listView = this.defaultView(this.pagination.total);
    },
  },
  watch: {
    listIsLoaded: 'initDefaults',
  },
  mounted() {
    this.initDefaults();
  },
});
