
import Vue from 'vue';
import { mapState, mapGetters, mapActions } from 'vuex';
import values from 'lodash/values';
import Draggable from 'vuedraggable';

import { ResourcesActions } from '@/store/modules/resources/types';
import ResourceFile from '@/store/modules/resources/file';
import ResourceLink from '@/store/modules/resources/link';
import FormInput from '@/components/controls/form-input.vue';
import ImageUploadControl from '@/components/controls/image-upload.vue';
import ResourceFileForm from '@/components/forms/resource-file.vue';
import ResourceLinkForm from '@/components/forms/resource-link.vue';
import Autocomplete, { AutocompleteOption } from '@/components/controls/autocomplete.vue';
import Checkbox from '@/components/controls/checkbox.vue';
import Taxonomy from '@/store/models/taxonomy';

export default Vue.extend({
  name: 'ResourceForm',
  components: {
    Draggable,
    FormInput,
    ImageUploadControl,
    ResourceFileForm,
    ResourceLinkForm,
    Autocomplete,
    Checkbox,
  },
  inject: {
    $validator: '$validator',
  },
  props: {
    add: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      productsInput: '',
      treatmentsInput: '',
    };
  },
  computed: {
    ...mapState('resources', {
      resource: 'editable',
    }),
    ...mapState('products', {
      productsTree: 'tree',
    }),
    ...mapGetters('resources', {
      isLoading: 'editableIsLoading',
      isSaving: 'editableIsSaving',
    }),
    isFetching() {
      return this.isLoading || this.isSaving;
    },
    files: {
      get(): ResourceFile[] {
        if (this.resource && this.resource.files && this.resource.files.length) {
          return this.resource.files;
        }

        return [new ResourceFile()];
      },
      set(files: ResourceFile[]) {
        this.updateResource({
          files: files.map((item, index) => ({ ...item, weight: index })),
        });
      },
    },
    links: {
      get(): ResourceLink[] {
        if (this.resource && this.resource.links && this.resource.links.length) {
          return this.resource.links;
        }

        return [new ResourceLink()];
      },
      set(links: ResourceLink[]) {
        this.updateResource({
          links: links.map((item, index) => ({ ...item, weight: index })),
        });
      },
    },
    getProducts(): AutocompleteOption[] {
      const productsIds = this.resource.products.map((product) => product.id);
      return this.productsTree
        .filter((product) => productsIds.indexOf(product.id) === -1)
        .map((product) => ({
          label: product.title,
          value: product.id,
        }))
        .filter((option) => option.label.toLowerCase().indexOf(this.productsInput.toLowerCase()) > -1);
    },
    allTreatments() {
      const productsIds = this.resource.products.map((product) => product.id);
      const initial: { [key: number]: Taxonomy } = {};

      return values(
        this.productsTree
          .filter((product) => productsIds.indexOf(product.id) !== -1)
          .map((product) => product.treatments)
          .reduce((res, treatments) => {
            treatments.forEach((treatment) => {
              res[treatment.id] = treatment;
            });

            return res;
          }, initial),
      );
    },
    getTreatments(): Taxonomy[] {
      const treatmentsIds = this.selectedTreatments.map((treatment) => treatment.id);
      return this.allTreatments.filter((treatment) => treatmentsIds.indexOf(treatment.id) === -1);
    },
    getTreatmentsOptions(): AutocompleteOption[] {
      return this.getTreatments
        .map((treatment) => ({
          label: treatment.title,
          value: treatment.id,
        }))
        .filter((option) => option.label.toLowerCase().indexOf(this.treatmentsInput.toLowerCase()) > -1);
    },
    hasTreatments(): boolean {
      return Boolean(this.allTreatments.length);
    },
    hasTreatmentsOptions(): boolean {
      return Boolean(this.getTreatments.length);
    },
    selectedTreatments(): Taxonomy[] {
      const availableTreatmentsIds = this.allTreatments.map((treatment) => treatment.id);

      return this.resource.treatments.filter((treatment) => availableTreatmentsIds.indexOf(treatment.id) !== -1);
    },
  },
  methods: {
    ...mapActions('resources', {
      updateResource: ResourcesActions.UPDATE_EDITABLE,
    }),

    /**
     * Files
     */
    addFile() {
      const files: ResourceLink[] = [...this.files, new ResourceFile()];
      this.updateResource({ files });
    },
    removeFile(file: ResourceFile) {
      let files: ResourceFile[];

      if (file.id) {
        files = this.files.filter((item) => item.id !== file.id);
      } else {
        files = this.files.filter((item) => item.localId !== file.localId);
      }

      this.updateResource({ files });
    },
    handleFileChange(index, file) {
      const files: ResourceFile[] = [...this.files];
      files[index] = file;

      this.updateResource({ files });
    },

    /**
     * Links
     */
    addLink() {
      const links: ResourceLink[] = [...this.links, new ResourceLink()];
      this.updateResource({ links });
    },
    removeLink(link: ResourceLink) {
      let links: ResourceLink[];

      if (link.id) {
        links = this.links.filter((item) => item.id !== link.id);
      } else {
        links = this.links.filter((item) => item.localId !== link.localId);
      }

      this.updateResource({ links });
    },
    handleLinkChange(index, link) {
      const links: ResourceLink[] = [...this.links];
      links[index] = link;

      this.updateResource({ links });
    },

    /**
     * Products
     */
    addProduct(option: AutocompleteOption) {
      const products = [...this.resource.products];
      const product = this.productsTree.find((item) => item.id === option.value);
      if (!product) {
        return;
      }

      products.push(product);

      this.updateResource({ products });
    },
    removeProduct(productId: number) {
      const products = this.resource.products.filter((product) => product.id !== productId);

      this.updateResource({ products });
    },

    /**
     * Treatments
     */
    addTreatment(option: AutocompleteOption) {
      const treatments = [...this.resource.treatments];
      const treatment = this.allTreatments.find((item) => item.id === option.value);
      if (!treatment) {
        return;
      }

      treatments.push(treatment);

      this.updateResource({ treatments });
    },
    removeTreatment(treatmentId: number) {
      const treatments = this.selectedTreatments.filter((treatment) => treatment.id !== treatmentId);

      this.updateResource({ treatments });
    },
  },
});
