import { GetterTree } from 'vuex';

import { RootState, ListStatus } from '@/store/types';
import { createEditableGetters } from '@/store/utils/editable';
import { ProductsState } from './types';

const {
  isLoading: editableIsLoading,
  isLoaded: editableIsLoaded,
  isSaving: editableIsSaving,
  isSaved: editableIsSaved,
  isFailed: editableIsFailed,
  isFetching: editableIsFetching,
  hasUnsavedChanges: editableHasUnsavedChanges,
} = createEditableGetters<ProductsState>('editableStatus', 'editable', 'initialEditable');

const {
  isLoading: accessoryIsLoading,
  isLoaded: accessoryIsLoaded,
  isSaving: accessoryIsSaving,
  isSaved: accessoryIsSaved,
  isFailed: accessoryIsFailed,
  isFetching: accessoryIsFetching,
  hasUnsavedChanges: accessoryHasUnsavedChanges,
} = createEditableGetters<ProductsState>('accessoryStatus', 'accessory', 'initialAccessory');

export const moduleGetters: GetterTree<ProductsState, RootState> = {
  /**
   * List
   */
  listIsLoading: (state) => state.listStatus === ListStatus.Loading,
  listIsLoaded: (state) => state.listStatus === ListStatus.Loaded,
  listIsError: (state) => state.listStatus === ListStatus.Failed,
  listIsEmpty: (state, getters) => getters.listIsLoaded && !state.list.length,

  /**
   * Edit
   */
  editableIsLoading,
  editableIsLoaded,
  editableIsSaving,
  editableIsSaved,
  editableIsFailed,
  editableIsFetching,
  editableHasUnsavedChanges,

  /**
   * Accessories list
   */
  accessoriesListIsLoading: (state) => state.accessoriesListStatus === ListStatus.Loading,
  accessoriesListIsLoaded: (state) => state.accessoriesListStatus === ListStatus.Loaded,
  accessoriesListIsError: (state) => state.accessoriesListStatus === ListStatus.Failed,
  accessoriesListIsEmpty: (state, getters) => getters.accessoriesListIsLoaded && !state.accessoriesList.length,

  /**
   * Accessories Edit
   */
  accessoryIsLoading,
  accessoryIsLoaded,
  accessoryIsSaving,
  accessoryIsSaved,
  accessoryIsFailed,
  accessoryIsFetching,
  accessoryHasUnsavedChanges,
};

export default moduleGetters;
