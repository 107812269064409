/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'social/twitter': {
    width: 23,
    height: 18.89,
    viewBox: '0 0 23 18.89',
    data: '<path pid="0" d="M22.87 2.31a.5.5 0 00-.6-.11 4.24 4.24 0 01-.64.23 4.45 4.45 0 00.76-1.46.48.48 0 00-.2-.54.5.5 0 00-.57 0 10.36 10.36 0 01-2.4.94 5 5 0 00-6.31-.49 5 5 0 00-2.16 4.57A11.83 11.83 0 012.5 1a.52.52 0 00-.43-.18.49.49 0 00-.39.24 4.84 4.84 0 00-.56 3.76 5.25 5.25 0 00.87 1.81 2.29 2.29 0 01-.51-.32.49.49 0 00-.53-.07.52.52 0 00-.29.45 5.18 5.18 0 002.67 4.45 5.27 5.27 0 01-.64-.14.5.5 0 00-.53.15.49.49 0 00-.08.53 5.49 5.49 0 003.79 3.1A8.87 8.87 0 01.6 15.87a.49.49 0 00-.54.34.52.52 0 00.23.59 15 15 0 007.39 2.09 12.68 12.68 0 007-2.14c4-2.63 6.43-7.35 6.09-11.58a9.43 9.43 0 002.17-2.26.5.5 0 00-.07-.6z"/>'
  }
})
