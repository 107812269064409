import { Snackbar } from './snackbar';

export enum NotificationType {
  Success,
  Notice,
  Warning,
  Error,
}

export enum NotificationsActions {
  SHOW_SUCCESS = 'SHOW_SUCCESS',
  SHOW_NOTICE = 'SHOW_NOTICE',
  SHOW_WARNING = 'SHOW_WARNING',
  SHOW_ERROR = 'SHOW_ERROR',
  SHOW_NOTIFICATION = 'SHOW_NOTIFICATION',
  HIDE_NOTIFICATION = 'HIDE_NOTIFICATION',
  ADD_SNACKBAR = 'ADD_SNACKBAR',
  REMOVE_SNACKBAR = 'REMOVE_SNACKBAR',
}

export enum NotificationsMutations {
  SET_NOTIFICATION = 'SET_NOTIFICATION',
  SET_VISIBILITY = 'SET_VISIBILITY',
  ADD_SNACKBAR = 'ADD_SNACKBAR',
  REMOVE_SNACKBAR = 'REMOVE_SNACKBAR',
}

export interface Message {
  title?: string;
  body?: string;
  errorList?: string[];
}

export interface NotificationsState {
  message?: Message;
  type?: NotificationType;
  show: boolean;

  snackbars: Snackbar[];
}
