import { fixLineEndings } from '@/utils/api';
import { createExcerpt } from '@/utils/excerpt';
import { getEnumValue } from '@/utils/enum';

import Image, { ImageJSON } from '@/store/models/image';
import { JSONModel, toJSONContext } from '@/store/models/jsonModel';
import PracticeRelationship, { PracticeRelationshipJSON } from '@/store/modules/practices/practice-relationship';
import Taxonomy, { TaxonomyJSON } from '@/store/models/taxonomy';
import Country, { CountryJSON } from '@/store/models/country';
import { Priority, PublishStatus } from '@/store/types';
import PractitionerRelationship, {
  PractitionerRelationshipJSON,
} from '@/store/modules/practitioners/practitioner-relationship';
import UserRelationship, { UserRelationshipJSON } from '@/store/modules/users/user-relationship';

import { StarStatus } from './types';

export interface LocationJSON {
  id: number;
  practice_id: number;
  country_id: number;
  // featured: number;
  // language_id: number;
  // language_code: string;
  status: number;
  priority: string;
  public_visible: boolean;
  star: number;
  // visible: number;
  title: string;
  // nid: number;
  description: string;
  // message?: string;
  website: (string | { id?; label: string; link: string })[];
  // name: string;
  street: string;
  additional: string;
  city: string;
  state: string;
  postal_code: string;
  phone: string;
  // country_code: string;
  // country_name: string;
  // province_name: string;
  // geocode_date: string;
  // longitude: string;
  // latitude: string;
  map_url: string;
  twitter?: string;
  facebook?: string;
  instagram?: string;
  linkedin?: string;
  pinterest?: string;
  realself?: string;
  youtube?: string;
  yelp?: string;
  tiktok?: string;
  // notes?: string;
  consultation_emails: (string | { id?; email: string })[];
  // log: string;
  // path?: string;
  // sculpsure_url?: any;
  created_at: string;
  updated_at: string;
  deleted_at?: string;
  // index_pending: number;
  autosave: number;
  practice: PracticeRelationshipJSON;
  practitioners: PractitionerRelationshipJSON[];
  logo: ImageJSON | ImageJSON[];
  treatments: TaxonomyJSON[];
  enhancements: TaxonomyJSON[];
  country: CountryJSON;
  explanation: string | null;
  version_author: UserRelationshipJSON;
}

export default class Location extends JSONModel<LocationJSON> {
  public id: number = 0;
  public practiceId: number = 0;
  public countryId: number = 0;
  public status: number = PublishStatus.Pending;
  public publicVisible: boolean = true;
  public priority: Priority = Priority.gold;
  public star: StarStatus = StarStatus.NotStarred;
  public title: string = '';
  public description: string = '';
  public website: { id?; label: string; link: string }[] = [];
  public street: string = '';
  public additional: string = '';
  public city: string = '';
  public state: string = '';
  public postalCode: string = '';
  public phone: string = '';
  public mapUrl?: string;
  public twitter: string = '';
  public facebook: string = '';
  public instagram: string = '';
  public linkedin: string = '';
  public pinterest: string = '';
  public realself: string = '';
  public youtube: string = '';
  public yelp: string = '';
  public tiktok: string = '';
  public consultationEmails: { id?; email: string }[] = [];
  public createdAt: string = '';
  public updatedAt: string = '';
  public deletedAt: string = '';
  public autosave: number = 0;
  public practice?: PracticeRelationship;
  public practitioners: PractitionerRelationship[] = [];
  public logo?: Image;
  public treatments: Taxonomy[] = [];
  public enhancements: Taxonomy[] = [];
  public country?: Country;
  public explanation: string | null = null;
  public versionAuthor?: UserRelationship;

  protected _clientProperties = ['autosave', 'mapUrl', 'createdAt', 'updatedAt', 'deletedAt'];

  public get humanReadableAddress() {
    return [this.street, this.city, this.state, this.country ? this.country.name : undefined]
      .filter(Boolean)
      .map((item) => {
        if (item) {
          return item.trim();
        }

        return item;
      })
      .join(', ');
  }

  public fromJSON(json: Partial<LocationJSON>) {
    if (json.id) {
      this.id = Number(json.id);
    }

    if (json.practice_id) {
      this.practiceId = Number(json.practice_id);

      if (json.practice) {
        this.practice = PracticeRelationship.fromJSON(json.practice);
      }
    }

    if (json.country_id) {
      this.countryId = Number(json.country_id);

      if (json.country) {
        this.country = Country.fromJSON(json.country);
      }
    }

    const status = getEnumValue<PublishStatus>(PublishStatus, Number(json.status));
    if (typeof status !== 'undefined') {
      this.status = status;
    }

    const priority = getEnumValue<Priority>(Priority, String(json.priority));
    if (typeof priority !== 'undefined') {
      this.priority = priority;
    }

    const publicVisible = Boolean(json.public_visible);
    if (typeof publicVisible !== 'undefined') {
      this.publicVisible = publicVisible;
    }

    const star = getEnumValue<StarStatus>(StarStatus, Number(json.star));
    if (typeof star !== 'undefined') {
      this.star = star;
    }

    if (json.title) {
      this.title = String(json.title);
    }

    if (json.description) {
      this.description = fixLineEndings(String(json.description));
    }

    if (json.website && Array.isArray(json.website)) {
      this.website = json.website.filter(Boolean).map((item) => {
        if (typeof item === 'string') {
          return {
            label: '',
            link: item,
          };
        }

        return {
          label: item.label,
          link: item.link,
        };
      });
    }

    if (json.street) {
      this.street = String(json.street);
    }

    if (json.additional) {
      this.additional = String(json.additional);
    }

    if (json.city) {
      this.city = String(json.city);
    }

    if (json.state) {
      this.state = String(json.state);
    }

    if (json.postal_code) {
      this.postalCode = String(json.postal_code);
    }

    if (json.phone) {
      this.phone = String(json.phone);
    }

    if (json.map_url) {
      this.mapUrl = String(json.map_url);
    }

    if (json.map_url) {
      this.mapUrl = String(json.map_url);
    }

    if (json.twitter) {
      this.twitter = String(json.twitter);
    }

    if (json.facebook) {
      this.facebook = String(json.facebook);
    }

    if (json.instagram) {
      this.instagram = String(json.instagram);
    }

    if (json.linkedin) {
      this.linkedin = String(json.linkedin);
    }

    if (json.pinterest) {
      this.pinterest = String(json.pinterest);
    }

    if (json.realself) {
      this.realself = String(json.realself);
    }

    if (json.youtube) {
      this.youtube = String(json.youtube);
    }

    if (json.yelp) {
      this.yelp = String(json.yelp);
    }

    if (json.tiktok) {
      this.tiktok = String(json.tiktok);
    }

    if (json.consultation_emails && Array.isArray(json.consultation_emails)) {
      this.consultationEmails = json.consultation_emails.filter(Boolean).map((item) => {
        if (typeof item === 'string') {
          return {
            email: item,
          };
        }

        return {
          email: item.email,
        };
      });
    }

    if (json.created_at) {
      this.createdAt = String(json.created_at);
    }

    if (json.updated_at) {
      this.updatedAt = String(json.updated_at);
    }

    if (json.deleted_at) {
      this.deletedAt = String(json.deleted_at);
    }

    if (json.autosave) {
      this.autosave = Number(json.autosave);
    }

    if (json.practitioners && Array.isArray(json.practitioners)) {
      this.practitioners = json.practitioners.map((item) => PractitionerRelationship.fromJSON(item));
    }

    if (json.logo) {
      this.logo = Image.fromJSON(Array.isArray(json.logo) ? json.logo[0] : json.logo);
    }

    if (json.treatments && Array.isArray(json.treatments)) {
      this.treatments = json.treatments.map((item) => Taxonomy.fromJSON(item));
    }

    if (json.enhancements && Array.isArray(json.enhancements)) {
      this.enhancements = json.enhancements.map((item) => Taxonomy.fromJSON(item));
    }

    if (json.explanation) {
      this.explanation = String(json.explanation);
    }

    if (json.version_author && json.version_author.id) {
      this.versionAuthor = UserRelationship.fromJSON(json.version_author);
    }
  }

  public toJSON(context: toJSONContext = 'server') {
    const json = super.toJSON(context);

    if (json.website) {
      json.website = this.website.filter((item) => {
        return Boolean(item.link) || Boolean(item.label);
      });

      json.website.map((item) => {
        if (typeof item !== 'string') {
          delete item.id;
        }
        return item;
      });
    }

    if (json.consultation_emails) {
      json.consultation_emails = this.consultationEmails.filter((item) => {
        return Boolean(item.email);
      });

      json.consultation_emails.map((item) => {
        if (typeof item !== 'string') {
          delete item.id;
        }
        return item;
      });
    }

    return json;
  }

  public get excerpt() {
    return createExcerpt(this.description);
  }
}
