import Vue from 'vue';
import { ProductsState, ProductsMutations } from './types';
import Product from './product';
import { ListStatus, EditableStatus, MutationsTree } from '@/store/types';
import Pagination from '@/store/models/pagination';
import { updateEditable } from '@/store/utils/editable';
import Accessory from './accessory';
import AccessoryTree from './accessory-tree';

const mutations: MutationsTree<ProductsState, typeof ProductsMutations> = {
  /**
   * List
   */
  [ProductsMutations.SET_LIST](state, payload: Product[]) {
    state.list = payload;
  },
  [ProductsMutations.SET_LIST_STATUS](state, payload: ListStatus) {
    state.listStatus = payload;
  },
  [ProductsMutations.SET_LIST_PAGINATION](state, payload: Pagination) {
    state.listPagination = payload;
  },

  /**
   * Tree
   */
  [ProductsMutations.SET_TREE](state, payload: Product[]) {
    state.tree = payload;
  },
  [ProductsMutations.SET_TREE_STATUS](state, payload: ListStatus) {
    state.treeStatus = payload;
  },

  /**
   * Edit
   */
  [ProductsMutations.SET_EDITABLE](state, product: Product) {
    Vue.set(state, 'editable', product);
    Vue.set(state, 'initialEditable', Product.clone(product));
  },
  [ProductsMutations.SET_EDITABLE_STATUS](state, editableStatus: EditableStatus) {
    state.editableStatus = editableStatus;
  },
  [ProductsMutations.UPDATE_EDITABLE]: updateEditable<ProductsState>('editable'),
  [ProductsMutations.UPDATE_INITIAL_EDITABLE]: updateEditable<ProductsState>('initialEditable'),
  [ProductsMutations.SET_TRANSLATIONS_STATUS](state, translationsStatus: EditableStatus) {
    state.translationsStatus = translationsStatus;
  },

  /**
   * Accessories list
   */
  [ProductsMutations.SET_ACCESSORIES_LIST](state, payload: Accessory[]) {
    state.accessoriesList = payload;
  },
  [ProductsMutations.SET_ACCESSORIES_LIST_STATUS](state, payload: ListStatus) {
    state.accessoriesListStatus = payload;
  },
  [ProductsMutations.SET_ACCESSORIES_LIST_PAGINATION](state, payload: Pagination) {
    state.accessoriesListPagination = payload;
  },

  /**
   * Accessories tree
   */
  [ProductsMutations.SET_ACCESSORIES_TREE](state, payload: AccessoryTree[]) {
    state.accessoriesTree = payload;
  },
  [ProductsMutations.SET_ACCESSORIES_TREE_STATUS](state, payload: ListStatus) {
    state.accessoriesTreeStatus = payload;
  },

  /**
   * Accessories Edit
   */
  [ProductsMutations.SET_ACCESSORY](state, accessory: Accessory) {
    Vue.set(state, 'accessory', accessory);
    Vue.set(state, 'initialAccessory', Accessory.clone(accessory));
  },
  [ProductsMutations.SET_ACCESSORY_STATUS](state, editableStatus: EditableStatus) {
    state.accessoryStatus = editableStatus;
  },
  [ProductsMutations.UPDATE_ACCESSORY]: updateEditable<ProductsState>('accessory'),
  [ProductsMutations.UPDATE_INITIAL_ACCESSORY]: updateEditable<ProductsState>('initialAccessory'),
  [ProductsMutations.SET_ACCESSORY_TRANSLATIONS_STATUS](state, translationsStatus: EditableStatus) {
    state.accessoryTranslationsStatus = translationsStatus;
  },
};

export default mutations;
