var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{class:[
    'c-highlighted-textarea',
    {
      'is-disabled': _vm.$attrs.disabled,
      'is-focused': _vm.focused,
      'is-hovered': _vm.hovered,
    },
  ],on:{"mouseenter":function($event){_vm.hovered = true},"mouseleave":function($event){_vm.hovered = false}}},[_c('div',{staticClass:"c-highlighted-textarea__backdrop",style:({ transform: `translateY(-${_vm.scroll}px)` })},[_c('div',{style:({ padding: _vm.isIOS ? '0 3px' : '0' })},_vm._l((_vm.backdropContent),function(item,index){return _c('span',{key:index,class:{ 'is-highlighted': item.highlight }},[_vm._v(_vm._s(item.value))])}),0)]),_c('textarea',_vm._g(_vm._b({ref:"textarea",staticClass:"c-highlighted-textarea__textarea",domProps:{"value":_vm.value},on:{"&scroll":function($event){return _vm.adjustScroll.apply(null, arguments)},"input":_vm.adjustHeight,"focus":_vm.onFocus,"blur":_vm.onBlur}},'textarea',_vm.$attrs,false),_vm.$listeners))])
}
var staticRenderFns = []

export { render, staticRenderFns }