/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'megaphone': {
    width: 9.6,
    height: 8.6,
    viewBox: '0 0 9.6 8.6',
    data: '<g data-name="Layer 2"><g data-name="Isolation Mode"><path pid="0" d="M9.3 8.6a.3.3 0 01-.3-.3v-8a.3.3 0 11.6 0v8a.3.3 0 01-.3.3z"/><path pid="1" d="M9.3 7.6h-.07l-9-2A.3.3 0 010 5.3v-2A.3.3 0 01.23 3l9-2a.29.29 0 01.25.06.3.3 0 01.11.23v6a.3.3 0 01-.3.3zM.6 5.06L9 6.93V1.67L.6 3.54z"/><path pid="2" d="M.3 5.6a.3.3 0 01-.3-.3V3.38a.3.3 0 01.6 0V5.3a.3.3 0 01-.3.3zM3.8 8.6A1.8 1.8 0 012 6.8V5.74a.3.3 0 01.6 0V6.8a1.2 1.2 0 002.4 0v-.39a.3.3 0 01.6 0v.39a1.8 1.8 0 01-1.8 1.8z"/></g></g>'
  }
})
