import { ProfileJSON } from '@/store/modules/user/profile/profile';
import httpClient from '../http-client';
import { RequestConfig } from '../types';

export interface LoginResponse {
  authenticated: true;
  access_token: string;
  token_type: 'bearer';
  expires_in: number;
  user: ProfileJSON;
}

export function login(data: { email: string; password: string }, config?: RequestConfig) {
  return httpClient.post<LoginResponse>('auth/login', data, config);
}

export function me(config?: RequestConfig) {
  return httpClient.get<ProfileJSON>('auth/me', config);
}

export function updateMe(data: Partial<Omit<ProfileJSON, 'id'> & { password?: string }>, config?: RequestConfig) {
  return httpClient.post<ProfileJSON>('auth/me', data, config);
}

export function logout(config?: RequestConfig) {
  return httpClient.get<undefined>('auth/logout', config);
}

export function verifyResetPasswordToken(params: { token: string; email: string }, config?: RequestConfig) {
  return httpClient.get<{ access_token: string }>('auth/password', { params, ...config });
}

export function requestPasswordReset(data: { email: string }, config?: RequestConfig) {
  return httpClient.post<{ success: string }>('notification/reset', data, config);
}

export function check(config?: RequestConfig) {
  return httpClient.get<LoginResponse>('auth/check', config);
}

export function refresh(config?: RequestConfig) {
  return httpClient.get<LoginResponse>('auth/refresh', config);
}

export function impersonate(userId: ProfileJSON['id'], config?: RequestConfig) {
  return httpClient.get<LoginResponse>(`auth/impersonate/${userId}`, config);
}
