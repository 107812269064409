
import { mapGetters, mapState } from 'vuex';
import mixins from 'vue-typed-mixins';

import { formatNumber } from '@/i18n';
import imageSizeMixin from '@/components/mixins/image-size';

export default mixins(imageSizeMixin).extend({
  name: 'UserWidgetBlock',
  computed: {
    /**
     * --- USER PROFILE ---
     */
    ...mapGetters('user/profile', ['displayName', 'profileImage', 'hasPractice']),
    /**
     * --- PRACTITIONERS ---
     */
    ...mapState('practitioners', {
      totalPractitionersItems: 'menuListTotalItems',
    }),
    /**
     * --- LOCATIONS ---
     */
    ...mapState('locations', {
      pendingLocations: 'pendingList',
      pendingLocationsTotalItems: 'pendingListTotalItems',
      publishedLocationsTotalItems: 'publishedListTotalItems',
    }),
    ...mapGetters('locations', {
      pendingLocationsIsLoaded: 'pendingListIsLoaded',
    }),
    /**
     * --- PRACTICES ---
     */
    ...mapState('practices', ['userPractice']),
    ...mapGetters('practices', ['userPracticeIsLoaded']),
    /**
     * --- COMPONENT ---
     */
    sizedProfileImage(): string {
      return this.sizedUserProfileImage(this.profileImage);
    },
    hasPending(): boolean {
      return this.pendingLocationsIsLoaded && this.pendingLocationsTotalItems > 0;
    },
  },
  methods: {
    formatNumber,
  },
});
