
import '@/icons';
import Vue from 'vue';

import api from '@/api';

import GdprForm from '@/components/forms/gdpr-form.vue';

export default Vue.extend({
  name: 'GDPRInformationRequestPage',
  components: {
    GdprForm,
  },
  data() {
    return {
      hasError: false,
      error: '',
      isPending: false,
      isSuccess: false,
      isFailed: false,
    };
  },
  methods: {
    requestInformation(data: { country_id: number; type: string; details: string }) {
      this.isPending = true;
      this.isFailed = false;
      this.hasError = false;

      return api.privacy_requests
        .store(data)
        .then(() => {
          this.isPending = false;
          this.isSuccess = true;
        })
        .catch((error) => {
          // tslint:disable-next-line: no-console
          console.error(error);
          this.error = this.$t('Something has gone wrong. If this persists, email us at %(email)%.', {
            email: '<a href="mailto:ampssupport@cynosure.com">ampssupport@cynosure.com</a>',
            interpolation: { escapeValue: false },
          });
          this.isPending = false;
          this.hasError = true;
          this.isFailed = true;
        });
    },
  },
});
