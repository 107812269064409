import { ListStatus, EditableStatus } from '@/store/types';
import Pagination from '@/store/models/pagination';
import Practitioner from './practitioner';
import PractitionerRelationship from './practitioner-relationship';

export enum PractitionersActions {
  /**
   * Lists
   */
  LOAD_PRACTITIONERS_LIST = 'LOAD_PRACTITIONERS_LIST',
  LOAD_PRACTITIONERS_MENU_LIST = 'LOAD_PRACTITIONERS_MENU_LIST',
  LOAD_PENDING_LIST = 'LOAD_PENDING_LIST',
  LOAD_PRACTITIONERS_HOURS_LIST = 'LOAD_PRACTITIONERS_HOURS_LIST',

  /**
   * Edit
   */
  LOAD_PRACTITIONER = 'LOAD_PRACTITIONER',
  UPDATE_EDITABLE = 'UPDATE_EDITABLE',
  SAVE_EDITABLE = 'SAVE_EDITABLE',
  DELETE = 'DELETE',
  RESTORE = 'RESTORE',

  /**
   * Bulk
   */
  BULK_DELETE = 'BULK_DELETE',
  BULK_RESTORE = 'BULK_RESTORE',
}

export enum PractitionersMutations {
  /**
   * List
   */
  SET_LIST = 'SET_LIST',
  SET_LIST_PAGINATION = 'SET_LIST_PAGINATION',
  SET_LIST_STATUS = 'SET_LIST_STATUS',

  /**
   * Menu list
   */
  SET_MENU_LIST = 'SET_MENU_LIST',
  SET_MENU_LIST_TOTAL = 'SET_MENU_LIST_TOTAL',
  SET_MENU_LIST_STATUS = 'SET_MENU_LIST_STATUS',

  /**
   * Pending list
   */
  SET_PENDING_LIST = 'SET_PENDING_LIST',
  SET_PENDING_LIST_TOTAL = 'SET_PENDING_LIST_TOTAL',
  SET_PENDING_LIST_STATUS = 'SET_PENDING_LIST_STATUS',

  /**
   * Practitioners Hours List
   */
  SET_PRACTITIONERS_HOURS_LIST = 'SET_PRACTITIONERS_HOURS_LIST',
  SET_PRACTITIONERS_HOURS_LIST_STATUS = 'SET_PRACTITIONERS_HOURS_LIST_STATUS',

  /**
   * Edit
   */
  SET_EDITABLE = 'SET_EDITABLE',
  SET_EDITABLE_STATUS = 'SET_EDITABLE_STATUS',
  UPDATE_EDITABLE = 'UPDATE_EDITABLE',
  UPDATE_INITIAL_EDITABLE = 'UPDATE_INITIAL_EDITABLE',
}

export interface PractitionerSpecialty {
  name: string;
  value: string;
}

export interface PractitionersState {
  /**
   * List
   */
  practitionersList: Practitioner[];
  listStatus: ListStatus;
  pagination: Pagination;

  /**
   * Pending list
   */
  pendingListTotalItems: number;
  pendingList: Location[];
  pendingListStatus: ListStatus;

  /**
   * Menu list
   */
  menuPractitionersList: Practitioner[];
  menuListStatus: ListStatus;
  menuListTotalItems: number;

  /**
   * Practitioners Hours List
   */
  practitionersHoursList: PractitionerRelationship[];
  practitionersHoursListStatus: ListStatus;

  /**
   * Edit
   */
  editableStatus: EditableStatus;
  editablePractitioner?: Practitioner;
  initialEditable?: Practitioner;
  specialtiesList: PractitionerSpecialty[];
}

export interface PractitionersListOptions {
  practice_id: number;
  page?: number;
  search?: string;
  sort?: string;
  direction?: string;
  status?: string;
  state?: string;
  country_id?: string;
}
