import { GetterTree } from 'vuex';
import { RootState, ErrorCodes } from '@/store/types';

export const rootGetters: GetterTree<RootState, RootState> = {
  hasErrors: (state) => state.errors && state.errors.length > 0,
  routeIsLoading: (state) => state.loadProgress > 0 && state.loadProgress < 100,
  latestError: (state) => state.errors[state.errors.length - 1],
  showSystemBanner: (state, getters) => (getters.latestError && getters.isNetworkError ? true : false),
  isNetworkError: (state, getters) =>
    getters.latestError && getters.latestError.code === ErrorCodes.NETWORK_ERROR ? true : false,
  isForbiddenError: (state, getters) =>
    getters.latestError && getters.latestError.code === ErrorCodes.FORBIDDEN_ERROR ? true : false,
  currentRoute: (state) => state.history[0],
  prevRoute: (state) => (state.history.length > 1 ? state.history[1] : undefined),
  isDirect: (state) => state.history.length < 2,
  languageName: (state) => (languageCode: string) => {
    const language = state.languages.find((item) => item.code === languageCode);

    if (!language) {
      return undefined;
    }

    return language.name;
  },
};

export default rootGetters;
