import { GetterTree } from 'vuex';

import { RootState, ListStatus } from '@/store/types';

import { StatisticsState } from './types';

export const moduleGetters: GetterTree<StatisticsState, RootState> = {
  /**
   * Downloads
   */
  downloadsIsLoading: (state) => state.downloadsStatus === ListStatus.Loading,
  downloadsIsLoaded: (state) => state.downloadsStatus === ListStatus.Loaded,
  downloadsIsError: (state) => state.downloadsStatus === ListStatus.Failed,
  downloadsIsEmpty: (state, getters) => getters.downloadsIsLoaded && !state.downloads.length,

  /**
   * Logins
   */
  loginsIsLoading: (state) => state.loginsStatus === ListStatus.Loading,
  loginsIsLoaded: (state) => state.loginsStatus === ListStatus.Loaded,
  loginsIsError: (state) => state.loginsStatus === ListStatus.Failed,
  loginsIsEmpty: (state, getters) => getters.loginsIsLoaded && !state.logins.length,

  /**
   * Users
   */
  usersIsLoading: (state) => state.usersStatus === ListStatus.Loading,
  usersIsLoaded: (state) => state.usersStatus === ListStatus.Loaded,
  usersIsError: (state) => state.usersStatus === ListStatus.Failed,
  usersIsEmpty: (state, getters) => getters.usersIsLoaded && !state.users.length,

  /**
   * Trainings
   */
  trainingsIsLoading: (state) => state.trainingsStatus === ListStatus.Loading,
  trainingsIsLoaded: (state) => state.trainingsStatus === ListStatus.Loaded,
  trainingsIsError: (state) => state.trainingsStatus === ListStatus.Failed,
  trainingsIsEmpty: (state, getters) => getters.trainingsIsLoaded && !state.trainings.length,
};

export default moduleGetters;
