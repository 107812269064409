
import Vue from 'vue';

interface PaginationPageItem {
  key: string;
  isDots: boolean;
  page: number;
}

export default Vue.extend({
  name: 'PaginationControl',
  props: {
    currentPage: Number,
    lastPage: Number,
    scrollTo: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      firstPage: 1,
      midItems: 3,
    };
  },
  computed: {
    /**
     * ## Examples
     * ---
     * - 1
     * - 1, **2**
     * - 1, **2**, 3
     * - 1, 2, **3**, 4, 5
     * - 1, ..., 3, **4**, 5
     * - 1, **2**, 3, ..., 5
     * - 1, ..., 3, **4**, 5, ..., 7
     */
    items(): PaginationPageItem[] {
      if (this.lastPage < 2) {
        return [];
      }

      const numMidSideItems = Math.max(0, Math.floor((this.midItems - 1) / 2));
      const numMidLeftItems = Math.max(0, Math.min(numMidSideItems, this.currentPage - this.firstPage - 1));
      const numMidRightItems = Math.max(0, Math.min(numMidSideItems, this.lastPage - this.currentPage - 1));
      const numLeftDots = this.lastPage > this.midItems && numMidLeftItems > 0 ? 1 : 0;
      const numRightDots = this.lastPage > this.midItems && numMidRightItems > 0 ? 1 : 0;
      const numItems = 1 + numLeftDots + numMidLeftItems + 1 + numMidRightItems + numRightDots + 1;

      const midLeftItems: number[] = Array.from(new Array(numMidLeftItems)).map(
        (val, index) => this.currentPage - (numMidLeftItems - index),
      );
      const midRightItems: number[] = Array.from(new Array(numMidRightItems)).map(
        (val, index) => this.currentPage + index + 1,
      );

      const midItems = [...midLeftItems, this.currentPage, ...midRightItems].map(
        (page: number): PaginationPageItem => ({
          key: `page-${page}`,
          isDots: false,
          page,
        }),
      );

      const leftDots: PaginationPageItem[] = !numLeftDots
        ? []
        : [
            {
              key: 'left-dots',
              isDots: true,
              page: 0,
            },
          ];

      const rightDots: PaginationPageItem[] = !numRightDots
        ? []
        : [
            {
              key: 'right-dots',
              isDots: true,
              page: 0,
            },
          ];

      const firstPage: PaginationPageItem[] =
        this.currentPage === this.firstPage
          ? []
          : [
              {
                key: 'first-page',
                isDots: false,
                page: this.firstPage,
              },
            ];

      const lastPage: PaginationPageItem[] =
        this.currentPage === this.lastPage
          ? []
          : [
              {
                key: 'last-page',
                isDots: false,
                page: this.lastPage,
              },
            ];

      return [...firstPage, ...leftDots, ...midItems, ...rightDots, ...lastPage];
    },
  },
  methods: {
    getRouterLinkToParam(page: number) {
      return {
        params: {
          page,
        },
        hash: this.scrollTo ? `#${this.scrollTo}` : undefined,
        query: {
          ...this.$route.query,
        },
      };
    },
  },
});
