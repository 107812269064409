import { ListStatus, EditableStatus } from '@/store/types';
import Pagination from '@/store/models/pagination';
import Event from './event';
import i18next from '@/i18n';

export enum EventStatus {
  Inactive = 0,
  Active = 1,
}

export const EventStatuses = {
  [EventStatus.Inactive]: i18next.t('Inactive'),
  [EventStatus.Active]: i18next.t('Active'),
};

export enum EventsActions {
  /**
   * List
   */
  LOAD_LIST = 'LOAD_LIST',

  /**
   * Edit
   */
  LOAD_EDITABLE = 'LOAD_EDITABLE',
  UPDATE_EDITABLE = 'UPDATE_EDITABLE',
  SAVE_EDITABLE = 'SAVE_EDITABLE',

  /**
   * Actions
   */
  DELETE = 'DELETE',
  RESTORE = 'RESTORE',

  /**
   * Bulk actions
   */
  BULK_DELETE = 'BULK_DELETE',
  BULK_RESTORE = 'BULK_RESTORE',
}

export enum EventsMutations {
  /**
   * List
   */
  SET_LIST = 'SET_LIST',
  SET_LIST_STATUS = 'SET_LIST_STATUS',
  SET_LIST_PAGINATION = 'SET_LIST_PAGINATION',

  /**
   * Edit
   */
  SET_EDITABLE = 'SET_EDITABLE',
  SET_EDITABLE_STATUS = 'SET_EDITABLE_STATUS',
  UPDATE_EDITABLE = 'UPDATE_EDITABLE',
  UPDATE_INITIAL_EDITABLE = 'UPDATE_INITIAL_EDITABLE',
}

export interface EventsState {
  /**
   * List
   */
  listStatus: ListStatus;
  listPagination: Pagination;
  list: Event[];

  /**
   * Edit
   */
  editable?: Event;
  initialEditable?: Event;
  editableStatus: EditableStatus;
}
