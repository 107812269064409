
import Vue, { PropOptions } from 'vue';
import { mapState, mapGetters } from 'vuex';
import FilterSkeleton from '@/components/skeletons/categories-filter-form.vue';

export default Vue.extend({
  name: 'CategoriesFilterForm',
  components: {
    FilterSkeleton,
  },
  props: {
    categoriesFilter: {
      type: Array,
    } as PropOptions,
  },
  computed: {
    selectedCategories: {
      get() {
        return this.categoriesFilter;
      },
      set(selected) {
        this.$emit('input', { categories: selected });
      },
    },
    ...mapState('user/profile', {
      currentUser: 'currentProfile',
    }),
    ...mapState('categories', {
      list: 'allList',
    }),
    ...mapGetters('categories', {
      isLoading: 'isAllLoading',
    }),
  },
  methods: {
    isSelected(id: number): boolean {
      return this.categoriesFilter.find((category) => category === id) ? true : false;
    },
  },
});
