import { GetterTree } from 'vuex';
import { RootState, ListStatus } from '@/store/types';
import { BlogState } from './types';

export const moduleGetters: GetterTree<BlogState, RootState> = {
  /**
   * List
   */
  isLoading: (state) => state.status === ListStatus.Loading,
  isLoaded: (state) => state.status === ListStatus.Loaded,
  isError: (state) => state.status === ListStatus.Failed,
  isEmpty: (state, getters) => getters.isLoaded && !state.list.length,

  /**
   * Categories
   */
  categoriesIsLoading: (state) => state.categoriesStatus === ListStatus.Loading,
  categoriesIsLoaded: (state) => state.categoriesStatus === ListStatus.Loaded,
  categoriesIsError: (state) => state.categoriesStatus === ListStatus.Failed,
  categoriesIsEmpty: (state, getters) => getters.categoriesIsLoaded && !state.categories.length,

  /**
   * Author
   */
  authorIsLoading: (state) => state.authorStatus === ListStatus.Loading,
  authorIsLoaded: (state) => state.authorStatus === ListStatus.Loaded,
  authorIsError: (state) => state.authorStatus === ListStatus.Failed,
  authorIsEmpty: (state, getters) => getters.authorIsLoaded && !state.categories.length,

  /**
   * Post
   */
  postIsLoading: (state) => state.postStatus === ListStatus.Loading,
  postIsLoaded: (state) => state.postStatus === ListStatus.Loaded,
  postIsError: (state) => state.postStatus === ListStatus.Failed,
  postIsEmpty: (state, getters) => getters.postIsLoaded && !state.categories.length,
};

export default moduleGetters;
