import { ActionTree } from 'vuex';

import router from '@/router';
import i18next from '@/i18n';
import api from '@/api';

import { RootState, ListStatus, RootActions, ErrorMessages, EditableStatus } from '@/store/types';
import Pagination from '@/store/models/pagination';
import { NotificationsActions } from '@/store/modules/notifications/types';
import { prepareFilters } from '@/store/utils/filters';
import PracticeRelationship, { PracticeRelationshipJSON } from '@/store/modules/practices/practice-relationship';

import ConsultationStats from './stats';
import ConsultationRequest from './consultation-request';
import {
  ConsultationRequestsState,
  ConsultationsActions,
  ConsultationRequestsMutations,
  ConsultationRequestStatus,
} from './types';
import { enhanceErrorWithMessage } from '@/utils/errors';

export const actions: ActionTree<ConsultationRequestsState, RootState> = {
  async [ConsultationsActions.LOAD_LIST]({ commit, dispatch }, filters = {}) {
    commit(ConsultationRequestsMutations.SET_LIST_STATUS, ListStatus.Loading);

    try {
      const res = await api.consultations.list(prepareFilters(filters, 'consultations'));
      commit(ConsultationRequestsMutations.SET_LIST_PAGINATION, Pagination.fromJSON(res.data));
      commit(
        ConsultationRequestsMutations.SET_LIST,
        res.data.data.map((item) => ConsultationRequest.fromJSON(item)),
      );
      commit(ConsultationRequestsMutations.SET_LIST_STATUS, ListStatus.Loaded);
    } catch (error) {
      enhanceErrorWithMessage(error, ErrorMessages.DATA_LOAD_ERROR);

      commit(ConsultationRequestsMutations.SET_LIST_STATUS, ListStatus.Failed);
      return dispatch(RootActions.ERROR, error, { root: true });
    }
  },
  async [ConsultationsActions.LOAD_LESS_QUALIFIED_LIST]({ commit, dispatch }, filters = {}) {
    commit(ConsultationRequestsMutations.SET_LESS_QUALIFIED_LIST_STATUS, ListStatus.Loading);

    try {
      const res = await api.consultations.list(prepareFilters(filters, 'less-qualified-consultations'));
      commit(ConsultationRequestsMutations.SET_LESS_QUALIFIED_LIST_PAGINATION, Pagination.fromJSON(res.data));
      commit(
        ConsultationRequestsMutations.SET_LESS_QUALIFIED_LIST,
        res.data.data.map((item) => ConsultationRequest.fromJSON(item)),
      );
      commit(ConsultationRequestsMutations.SET_LESS_QUALIFIED_LIST_STATUS, ListStatus.Loaded);
    } catch (error) {
      enhanceErrorWithMessage(error, ErrorMessages.DATA_LOAD_ERROR);

      commit(ConsultationRequestsMutations.SET_LESS_QUALIFIED_LIST_STATUS, ListStatus.Failed);
      return dispatch(RootActions.ERROR, error, { root: true });
    }
  },
  [ConsultationsActions.LOAD_EDITABLE]({ commit, dispatch }, { id, practice_id } = {}): Promise<void> {
    commit(ConsultationRequestsMutations.SET_EDITABLE_STATUS, EditableStatus.Loading);

    if (!id) {
      const consultationRequest = new ConsultationRequest();
      const practiceId = Number(practice_id);

      if (practiceId && Number.isFinite(practiceId)) {
        return api.practices
          .get(practiceId)
          .then((res) => {
            consultationRequest.practice = PracticeRelationship.fromJSON(res.data as PracticeRelationshipJSON);

            commit(ConsultationRequestsMutations.SET_EDITABLE, consultationRequest);
            commit(ConsultationRequestsMutations.SET_EDITABLE_STATUS, EditableStatus.Loaded);
          })
          .catch((error) => {
            enhanceErrorWithMessage(error, ErrorMessages.DATA_LOAD_ERROR);

            commit(ConsultationRequestsMutations.SET_EDITABLE_STATUS, EditableStatus.Failed);
            return dispatch(RootActions.ERROR, error, { root: true });
          });
      }

      commit(ConsultationRequestsMutations.SET_EDITABLE, consultationRequest);
      commit(ConsultationRequestsMutations.SET_EDITABLE_STATUS, EditableStatus.Loaded);
      return Promise.resolve();
    }

    return api.consultations
      .get(id)
      .then((response) => response.data)
      .then((response) => {
        commit(ConsultationRequestsMutations.SET_EDITABLE, ConsultationRequest.fromJSON(response));
        commit(ConsultationRequestsMutations.SET_EDITABLE_STATUS, EditableStatus.Loaded);
      })
      .catch((error) => {
        enhanceErrorWithMessage(error, ErrorMessages.DATA_LOAD_ERROR);

        commit(ConsultationRequestsMutations.SET_EDITABLE_STATUS, EditableStatus.Failed);
        return dispatch(RootActions.ERROR, error, { root: true });
      });
  },

  [ConsultationsActions.UPDATE_EDITABLE]({ commit }, changes): Promise<void> {
    commit(ConsultationRequestsMutations.UPDATE_EDITABLE, changes);
    return Promise.resolve();
  },

  [ConsultationsActions.SAVE_EDITABLE]({ state, commit, dispatch }): Promise<void> {
    let isUpdate: boolean;
    let successMsg: string;

    return new Promise<void>((resolve, reject) => {
      if (state.editable) {
        return resolve();
      }

      reject(new Error('Invalid data!'));
    })
      .then(() => {
        commit(ConsultationRequestsMutations.SET_EDITABLE_STATUS, EditableStatus.Saving);

        isUpdate = Boolean(state.editable!.id);
        successMsg = i18next.t('A new consultation request was added successfully.');

        if (isUpdate) {
          successMsg = i18next.t('The consultation request was successfully updated.');
        }
      })
      .then(() => api.consultations.store(state.editable!.toJSON()))
      .then((response) => response.data)
      .then((response: object): Promise<ConsultationRequest> => {
        const consultationRequest = ConsultationRequest.fromJSON(response);

        commit(ConsultationRequestsMutations.SET_EDITABLE, consultationRequest);
        commit(ConsultationRequestsMutations.SET_EDITABLE_STATUS, EditableStatus.Saved);

        if (!isUpdate) {
          return new Promise((resolve, reject) => {
            if (!consultationRequest.id) {
              return reject(new Error('Invalid data.'));
            }

            router.replace(
              {
                name: 'view-consultation',
                params: {
                  id: String(consultationRequest.id),
                },
              },
              () => resolve(consultationRequest),
              (error: any) => reject(error),
            );
          });
        }

        return Promise.resolve(consultationRequest);
      })
      .then(() => {
        return dispatch(
          'notifications/' + NotificationsActions.SHOW_SUCCESS,
          {
            body: successMsg,
          },
          { root: true },
        );
      })
      .catch((error) => {
        enhanceErrorWithMessage(error, ErrorMessages.DATA_LOAD_ERROR);

        if (error && error.response && error.response.data && error.response.data.errors) {
          const errorValues = Object.keys(error.response.data.errors)
            // Grab object values
            .map((key) => error.response.data.errors[key]);

          const errorStrings: string[] = []
            .concat(...errorValues)
            // Filter out empty strings
            .filter(Boolean);

          if (errorStrings.length) {
            enhanceErrorWithMessage(error, errorStrings.join('\n'));
          }
        }

        commit(ConsultationRequestsMutations.SET_EDITABLE_STATUS, EditableStatus.Failed);
        return dispatch(RootActions.ERROR, error, { root: true });
      });
  },
  [ConsultationsActions.DELETE]({ commit, dispatch }, { id = 0 }: { id?: number } = {}): Promise<void> {
    commit(ConsultationRequestsMutations.SET_EDITABLE_STATUS, EditableStatus.Saving);

    return new Promise<void>((resolve, reject) => {
      if (!id) {
        return reject(new Error('Invalid data!'));
      }

      resolve();
    })
      .then(() => api.consultations.remove(id))
      .then(() => {
        commit(ConsultationRequestsMutations.UPDATE_INITIAL_EDITABLE, {
          status: ConsultationRequestStatus.Inactive,
        });
        commit(ConsultationRequestsMutations.UPDATE_EDITABLE, {
          status: ConsultationRequestStatus.Inactive,
        });
        commit(ConsultationRequestsMutations.SET_EDITABLE_STATUS, EditableStatus.Saved);
      })
      .then(() => {
        return dispatch(
          'notifications/' + NotificationsActions.SHOW_SUCCESS,
          {
            body: i18next.t('The consultation request was deleted successfully.'),
          },
          { root: true },
        );
      })
      .catch((error) => {
        enhanceErrorWithMessage(error, ErrorMessages.DATA_LOAD_ERROR);

        commit(ConsultationRequestsMutations.SET_EDITABLE_STATUS, EditableStatus.Failed);
        return dispatch(RootActions.ERROR, error, { root: true });
      });
  },
  [ConsultationsActions.COMPLETE_LESS_QUALIFIED](
    { state, commit, dispatch },
    { id = 0 }: { id?: number } = {},
  ): Promise<void> {
    let itemIndex: number;
    let item: ConsultationRequest;

    return new Promise<void>((resolve, reject) => {
      if (!id) {
        return reject(new Error('Invalid data!'));
      }

      resolve();
    })
      .then(() => {
        itemIndex = state.lessQualifiedList.findIndex((record) => record.id === id);
        if (itemIndex < 0) {
          throw new Error('Invalid data!');
        }
        item = state.lessQualifiedList[itemIndex];

        commit(ConsultationRequestsMutations.SET_LESS_QUALIFIED_LIST, [
          ...state.lessQualifiedList.slice(0, itemIndex),
          ...state.lessQualifiedList.slice(itemIndex + 1),
        ]);
        const pagination = Pagination.clone(state.lessQualifiedListPagination);
        pagination.total -= 1;
        commit(ConsultationRequestsMutations.SET_LIST_PAGINATION, pagination);
      })
      .then(() => api.consultations.remove(id))
      .catch((error) => {
        enhanceErrorWithMessage(error, ErrorMessages.DATA_LOAD_ERROR);

        commit(ConsultationRequestsMutations.SET_LESS_QUALIFIED_LIST, [
          ...state.lessQualifiedList.slice(0, itemIndex),
          item,
          ...state.lessQualifiedList.slice(itemIndex),
        ]);
        const pagination = Pagination.clone(state.lessQualifiedListPagination);
        pagination.total += 1;
        commit(ConsultationRequestsMutations.SET_LIST_PAGINATION, pagination);

        return dispatch(RootActions.ERROR, error, { root: true });
      });
  },
  [ConsultationsActions.RESTORE]({ commit, dispatch }, { id = 0 }: { id?: number } = {}): Promise<void> {
    const isViewRoute = router.currentRoute.name === 'view-consultation';
    if (isViewRoute) {
      commit(ConsultationRequestsMutations.SET_EDITABLE_STATUS, EditableStatus.Saving);
    } else {
      commit(ConsultationRequestsMutations.SET_LIST_STATUS, ListStatus.Loading);
    }

    return new Promise<void>((resolve, reject) => {
      if (!id) {
        return reject(new Error('Invalid data!'));
      }

      resolve();
    })
      .then(() => api.consultations.restore(id))
      .then(() => {
        if (isViewRoute) {
          commit(ConsultationRequestsMutations.UPDATE_INITIAL_EDITABLE, {
            status: ConsultationRequestStatus.Active,
          });
          commit(ConsultationRequestsMutations.UPDATE_EDITABLE, {
            status: ConsultationRequestStatus.Active,
          });
          commit(ConsultationRequestsMutations.SET_EDITABLE_STATUS, EditableStatus.Saved);
        } else {
          dispatch(ConsultationsActions.LOAD_LIST, {
            ...router.currentRoute.params,
            ...router.currentRoute.query,
          });
        }
      })
      .then(() =>
        dispatch(
          'notifications/' + NotificationsActions.SHOW_SUCCESS,
          {
            body: i18next.t('The consultation request was restored successfully.'),
          },
          { root: true },
        ),
      )
      .catch((error) => {
        enhanceErrorWithMessage(error, ErrorMessages.DATA_LOAD_ERROR);

        if (isViewRoute) {
          commit(ConsultationRequestsMutations.SET_EDITABLE_STATUS, EditableStatus.Failed);
        } else {
          commit(ConsultationRequestsMutations.SET_LIST_STATUS, ListStatus.Failed);
        }

        return dispatch(RootActions.ERROR, error, { root: true });
      });
  },
  [ConsultationsActions.BULK_RESTORE](
    { commit, dispatch },
    { ids = [] }: { ids: number[] } = { ids: [] },
  ): Promise<void> {
    commit(ConsultationRequestsMutations.SET_LIST_STATUS, ListStatus.Loading);

    return new Promise<void>((resolve, reject) => {
      if (!ids || !ids.length) {
        return reject(new Error('Invalid data!'));
      }

      resolve();
    })
      .then(() => Promise.all(ids.map((id) => api.consultations.restore(id))))
      .then(() => {
        dispatch(ConsultationsActions.LOAD_LIST, {
          ...router.currentRoute.params,
          ...router.currentRoute.query,
        });

        return dispatch(
          'notifications/' + NotificationsActions.SHOW_SUCCESS,
          {
            body: i18next.t('%(count)% consultation request was restored successfully', { count: ids.length }),
          },
          { root: true },
        );
      })
      .catch((error) => {
        enhanceErrorWithMessage(error, ErrorMessages.DATA_LOAD_ERROR);

        commit(ConsultationRequestsMutations.SET_LIST_STATUS, ListStatus.Failed);

        return dispatch(RootActions.ERROR, error, { root: true });
      });
  },
  [ConsultationsActions.BULK_DELETE](
    { commit, dispatch },
    { ids = [] }: { ids: number[] } = { ids: [] },
  ): Promise<void> {
    commit(ConsultationRequestsMutations.SET_LIST_STATUS, ListStatus.Loading);

    return new Promise<void>((resolve, reject) => {
      if (!ids || !ids.length) {
        return reject(new Error('Invalid data!'));
      }

      resolve();
    })
      .then(() => Promise.all(ids.map((id) => api.consultations.remove(id))))
      .then(() => {
        dispatch(ConsultationsActions.LOAD_LIST, {
          ...router.currentRoute.params,
          ...router.currentRoute.query,
        });

        return dispatch(
          'notifications/' + NotificationsActions.SHOW_SUCCESS,
          {
            body: i18next.t('%(count)% consultation request was deleted successfully', { count: ids.length }),
          },
          { root: true },
        );
      })
      .catch((error) => {
        enhanceErrorWithMessage(error, ErrorMessages.DATA_LOAD_ERROR);

        commit(ConsultationRequestsMutations.SET_LIST_STATUS, ListStatus.Failed);

        return dispatch(RootActions.ERROR, error, { root: true });
      });
  },
  [ConsultationsActions.LOAD_USER_STATS]({ commit, dispatch }, { practiceId }: { practiceId: number }): Promise<void> {
    commit(ConsultationRequestsMutations.SET_USER_STATS_STATUS, ListStatus.Loading);

    return api.practices
      .getConsultationStats(practiceId)
      .then((response) => {
        commit(ConsultationRequestsMutations.SET_USER_STATS, ConsultationStats.fromJSON(response.data));
        commit(ConsultationRequestsMutations.SET_USER_STATS_STATUS, ListStatus.Loaded);
      })
      .catch((error) => {
        enhanceErrorWithMessage(error, ErrorMessages.DATA_LOAD_ERROR);

        commit(ConsultationRequestsMutations.SET_USER_STATS_STATUS, ListStatus.Failed);

        return dispatch(RootActions.ERROR, error, { root: true });
      });
  },
};

export default actions;
