
import Vue from 'vue';
import { Route } from 'vue-router';
import { mapActions, mapState, mapGetters } from 'vuex';

import '@/icons';
import config from '@/config';
import { formatNumber } from '@/i18n';

import { ResourcesActions } from '@/store/modules/resources/types';
import { ReportsActions } from '@/store/modules/reports/types';

import NotificationBanner from '@/components/blocks/notification-banner.vue';
import ResourceBlock from '@/components/blocks/resource.vue';
import PaginationControl from '@/components/controls/pagination.vue';
import SortOrderControl from '@/components/controls/sort-order.vue';
import CheckboxControl from '@/components/controls/checkbox.vue';
import Dropdown, { DropdownItems } from '@/components/controls/dropdown.vue';
import ResourcesFilterForm from '@/components/forms/resources-filter.vue';
import ResourceBlockFeaturedSkeleton from '@/components/skeletons/resource-block-featured.vue';
import ResourcesListSkeleton from '@/components/skeletons/resources-list.vue';
import { Resource } from '@/store/modules/resources/resource';
import PersonalizeResource from '@/components/forms/personalize-resource.vue';

export default Vue.extend({
  name: 'ClinicalSupportView',
  components: {
    PaginationControl,
    SortOrderControl,
    ResourcesFilterForm,
    ResourceBlock,
    NotificationBanner,
    ResourceBlockFeaturedSkeleton,
    ResourcesListSkeleton,
    Dropdown,
    CheckboxControl,
    PersonalizeResource,
  },
  data() {
    return {
      selectedItems: [],
      showPersonalizeModal: false,
    };
  },
  computed: {
    ...mapState('user/profile', ['currentProfile']),
    ...mapState('resources', [
      'resourcesList',
      'resourcesListFilters',
      'laserHighlightedItems',
      'trainingResourcesItems',
      'featured',
      'pagination',
    ]),
    ...mapGetters('resources', [
      'listIsLoading',
      'listIsLoaded',
      'listIsEmpty',
      'featuredIsLoading',
      'featuredIsLoaded',
    ]),
    ...mapGetters('user/profile', ['isSuperAdmin', 'isAdmin', 'isClinical', 'isInNorthAmerica', 'isInCA', 'isSales']),
    areAllItemsSelected(): boolean {
      return this.selectedItems.length === this.resourcesList.length;
    },
    createResourceUrl(): string {
      return config.DRUPAL_URL + 'node/add';
    },
    totalResources(): string {
      const totalItems = !this.pagination || !this.pagination.total ? 0 : this.pagination.total;

      return `${formatNumber(totalItems)} ${this.$t('Result', {
        count: totalItems,
      })}`;
    },
    canResetFilters(): boolean {
      return (
        (this.$route.query && this.$route.query.length) ||
        (this.$route.params && this.$route.params && this.$route.params.page !== '1')
      );
    },
    dropdownItems() {
      const items: DropdownItems = {};

      if (this.isSuperAdmin) {
        items.report = {
          label: this.$t('Generate Report'),
          icon: 'document',
        };
      }

      if (this.canResetFilters) {
        items.reset = {
          label: this.$t('Reset Filters'),
          icon: 'close',
        };
      }

      if (this.isSuperAdmin || this.isAdmin) {
        if (!this.areAllItemsSelected) {
          items.select_all = {
            hasTopBorder: true,
            label: this.$t('Select All'),
          };
        } else {
          items.select_none = {
            hasTopBorder: true,
            label: this.$t('Select None'),
          };
        }
      }
      if ((this.isSuperAdmin || this.isAdmin) && this.selectedItems && this.selectedItems.length) {
        items.restore = {
          label: this.$t('Restore'),
          count: this.selectedItems.length,
          disabled: !this.selectedItems.length,
        };

        items.remove = {
          label: this.$t('Delete'),
          count: this.selectedItems.length,
          disabled: !this.selectedItems.length,
        };
      }

      return items;
    },
    showFeaturedAdSlot(): boolean {
      return true;
      // return Boolean(this.$route.query) && Boolean(this.$route.query.product);
    },
    bannerList() {
      const productOwnedBanners: { [key: string]: any }[] = [];
      const banners = {
        'Potenza': [
          {
            'image': require('@/assets/images/clinical-support-banners/potenza/device.png'),
            'link': 'https://www.brainshark.com/cynosure/vu?pi=zJ6ziYYlTzfrnHz0',
            'link_ca': 'https://www.brainshark.com/cynosure/vu?pi=zHMzXh0O2zfrnHz0',
            'link_ca_fr': 'https://www.brainshark.com/1/player/cynosure?pi=zHMzXh0O2zfrnHz0&r3f1=&fb=0',
            'text': 'New User Training',
            'title': 'Potenza',
          },
          {
            'image': require('@/assets/images/clinical-support-banners/potenza/video.png'),
            'link': 'https://www.brainshark.com/cynosure/vu?pi=zJWzjYboazfrnHz0',
            'text': 'Hear it From Your Peers',
            'title': 'Potenza',
            'framed': true,
          },
        ],
        'PicoSure Pro': [
          {
            'image': require('@/assets/images/clinical-support-banners/picosure-pro/device.png'),
            'link': 'https://www.brainshark.com/cynosure/vu?pi=zImz10nPDezfrnHz0',
            'text': 'New User Training',
            'title': 'PicoSure Pro',
          },
          {
            'image': require('@/assets/images/clinical-support-banners/picosure-pro/video.png'),
            'link': 'https://www.brainshark.com/cynosure/vu?pi=zHwzSDcGTzfrnHz0',
            'text': 'Hear it From Your Peers',
            'title': 'PicoSure Pro',
            'framed': true,
          },
        ],
        'Elite iQ': [
          {
            'image': require('@/assets/images/clinical-support-banners/elite-iq/device.png'),
            'link': 'https://www.brainshark.com/cynosure/vu?pi=zINzUJQiVzfrnHz0',
            'link_ca_fr': 'https://www.brainshark.com/cynosure/vu?pi=zGtz5vF31zfrnHz0',
            'text': 'New User Training',
            'title': 'Elite iQ',
          },
          {
            'image': require('@/assets/images/clinical-support-banners/elite-iq/video.png'),
            'link': 'https://www.brainshark.com/cynosure/vu?pi=zIczExYYOzfrnHz0',
            'text': 'Hear it From Your Peers',
            'title': 'Elite iQ',
            'framed': true,
          },
        ],
        'TempSure® RF': [
          {
            'image': require('@/assets/images/clinical-support-banners/tempsure/device.png'),
            'link': 'https://www.brainshark.com/cynosure/vu?pi=zIKzsT0rCzfrnHz0',
            'text': 'New User Training',
            'title': 'Tempsure',
          },
        ],
      };

      const productOwned = this.currentProfile.products
        .filter((product) => product.owned)
        .map((product) => product.title);

      if (productOwned.length) {
        productOwned.forEach((product) => {
          if (banners.hasOwnProperty(product)) {
            if (this.isInCA) {
              banners[product].forEach(banner => {
                  if (this.currentProfile.languageCode === 'fr' && banner.hasOwnProperty('link_ca_fr')) {
                      banner.link = banner.link_ca_fr;
                  } else if (banner.hasOwnProperty('link_ca')) {
                      banner.link = banner.link_ca;
                  }
              });
            }
            productOwnedBanners.push(...banners[product]);
          }
        });
      }

      return productOwnedBanners;
    },
    showLaserHighlightedItems(): boolean {
      return (
        this.listIsLoaded &&
        !this.listIsEmpty &&
        this.pagination &&
        this.pagination.currentPage === 1 &&
        this.laserHighlightedItems.length &&
        this.resourcesListFilters &&
        (!this.resourcesListFilters.product || !this.resourcesListFilters.product.length) &&
        (!this.resourcesListFilters.treatments || !this.resourcesListFilters.treatments.length) &&
        (!this.resourcesListFilters.categories || !this.resourcesListFilters.categories.length)
      );
    },
    showTrainingResourcesItems(): boolean {
      return (
        this.listIsLoaded &&
        !this.listIsEmpty &&
        this.pagination &&
        this.pagination.currentPage === 1 &&
        this.trainingResourcesItems.length &&
        this.resourcesListFilters &&
        this.resourcesListFilters.categories &&
        this.resourcesListFilters.categories.includes(9)
      );
    },
    canPersonalize(): boolean {
      return (
        typeof this.currentProfile !== 'undefined' &&
        Array.isArray(this.currentProfile.personalization) &&
        this.currentProfile.personalization.length > 0
      );
    },
  },
  methods: {
    ...mapActions('reports', {
      requestReport: ReportsActions.REQUEST,
    }),
    ...mapActions('clinical-support', {
      deleteResources: ResourcesActions.BULK_DELETE,
      restoreResources: ResourcesActions.BULK_RESTORE,
    }),
    handleRequestSort(sort: string) {
      this.$router.push({
        name: 'clinical-support',
        query: {
          ...this.$route.query,
          sort,
        },
      });
    },
    handleDropdownClick(action: string): void {
      if ('reset' === action) {
        this.$router.push({
          params: {
            page: '1',
          },
          query: {},
        });
      }

      if ('report' === action) {
        this.requestReport();
      }

      if ('remove' === action) {
        this.deleteResources({ ids: this.selectedItems });
      }

      if ('restore' === action) {
        this.restoreResources({ ids: this.selectedItems });
      }

      if ('select_all' === action) {
        this.selectAll();
      }

      if ('select_none' === action) {
        this.selectAll();
      }
    },
    isSelected(resource: Resource): boolean {
      return this.selectedItems.indexOf(resource.id) !== -1;
    },

    selectItem(resource: Resource) {
      if (this.isSelected(resource)) {
        this.selectedItems = this.selectedItems.filter((item) => item !== resource.id);
        return;
      }

      this.selectedItems.push(resource.id);
    },

    selectAll() {
      if (this.areAllItemsSelected) {
        this.selectedItems = [];
      } else {
        this.selectedItems = this.resourcesList.map((resource: Resource) => resource.id);
      }
    },

    closePersonalizeModal() {
      this.$router.push({ hash: undefined });
      this.showPersonalizeModal = false;
    },
  },
  watch: {
    $route: {
      handler(val: Route) {
        if (val.hash === '#personalize-modal') {
          this.showPersonalizeModal = true;
        } else {
          this.showPersonalizeModal = false;
        }
      },
      immediate: true,
    },
  },
});
