/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'email-send': {
    width: 24,
    height: 11,
    viewBox: '0 0 24 11',
    data: '<g data-name="Layer 2"><g data-name="Send Mail 1"><g data-name="&lt;Group&gt;"><g data-name="&lt;Group&gt;"><path pid="0" data-name="&lt;Compound Path&gt;" d="M18.62 11H5.45a1.5 1.5 0 01-1.39-2.06L7 1.57A2.49 2.49 0 019.33 0H22.5a1.5 1.5 0 011.39 2.06l-2.95 7.37A2.49 2.49 0 0118.62 11zM9.33 1a1.49 1.49 0 00-1.39.94L5 9.31a.5.5 0 00.46.69h13.16A1.49 1.49 0 0020 9.06l3-7.37a.5.5 0 00-.5-.69z"/></g><g data-name="&lt;Group&gt;"><path pid="1" data-name="&lt;Path&gt;" d="M14 6a3.48 3.48 0 01-2.14-.74L9.17 2.87a.5.5 0 01.66-.75L12.5 4.5a2.48 2.48 0 002.57.27l5.2-2.71a.5.5 0 01.46.89l-5.22 2.72A3.57 3.57 0 0114 6z"/></g><g data-name="&lt;Group&gt;"><path pid="2" data-name="&lt;Path&gt;" d="M6.5 9a.5.5 0 01-.19-1l5-2a.5.5 0 11.37.93l-5 2A.5.5 0 016.5 9z"/></g><g data-name="&lt;Group&gt;"><path pid="3" data-name="&lt;Path&gt;" d="M18.5 9a.5.5 0 01-.31-.11l-2.5-2a.5.5 0 01.62-.78l2.5 2a.5.5 0 01-.31.89z"/></g><g data-name="&lt;Group&gt;"><path pid="4" data-name="&lt;Path&gt;" d="M4.5 2h-4a.5.5 0 010-1h4a.5.5 0 010 1z"/></g><g data-name="&lt;Group&gt;"><path pid="5" data-name="&lt;Path&gt;" d="M1.5 10h-1a.5.5 0 010-1h1a.5.5 0 010 1z"/></g><g data-name="&lt;Group&gt;"><path pid="6" data-name="&lt;Path&gt;" d="M2.5 6h-2a.5.5 0 010-1h2a.5.5 0 010 1z"/></g></g></g></g>'
  }
})
