import { JSONModel } from '@/store/models/jsonModel';

export interface PractitionerWorkingHour {
  day: 0 | 1 | 2 | 3 | 4 | 5 | 6;
  start: string;
  end: string;
  notes: string;
}

export interface PractitionerRelationshipJSON {
  id: number;
  title: string;
  specialty: string;
  thumbnail?: string;
  hours?: PractitionerWorkingHour[];
  active?: number;
}

export default class PractitionerRelationship extends JSONModel<PractitionerRelationshipJSON> {
  public id: number = 0;
  public title: string = '';
  public specialty: string = '';
  public thumbnail?: string;
  public hours: PractitionerWorkingHour[] = [];
  public active: 0 | 1 = 0;

  public fromJSON(json: Partial<PractitionerRelationshipJSON>) {
    if (json.id) {
      this.id = Number(json.id);
    }

    if (json.title) {
      this.title = String(json.title);
    }

    if (json.specialty) {
      this.specialty = String(json.specialty);
    }

    if (json.thumbnail) {
      this.thumbnail = String(json.thumbnail);
    }

    if (json.hours && Array.isArray(json.hours)) {
      this.hours = json.hours.map((item) => {
        return {
          day: item.day,
          start: String(item.start),
          end: String(item.end),
          notes: String(item.notes),
        };
      });
    }

    if (json.active) {
      this.active = Boolean(Number(json.active)) ? 1 : 0;
    }
  }

  public get avatar() {
    if (this.thumbnail) {
      return this.thumbnail + '?fit=facearea&facepad=3&w=150&h=150';
    }

    return require('@/assets/images/profile-empty-150.png');
  }

  public get fullName() {
    return this.title;
  }

  public get listName() {
    return this.title;
  }
}
