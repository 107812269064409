import { Module } from 'vuex';

import { ProfileState, TERMS_AGREE_KEY } from '@/store/modules/user/profile/types';
import { EditableStatus, ListStatus, RootState } from '@/store/types';

import actions from './actions';
import mutations from './mutations';
import getters from './getters';
import { Profile } from './profile';

export const getInitialState = (): ProfileState => ({
  status: ListStatus.Initial,
  currentProfile: new Profile(),
  editableProfile: undefined,
  initialEditableProfile: undefined,
  editableStatus: EditableStatus.Loading,
  local_terms_agree: localStorage.getItem(TERMS_AGREE_KEY) === 'true' ? 1 : 0,
  show_terms_agree_modal: 0,
});

export const state: ProfileState = getInitialState();

const profile: Module<ProfileState, RootState> = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

export default profile;
