import { PrivacyRequestStatus } from './types';
import { JSONModel } from '@/store/models/jsonModel';
import UserRelationship, { UserRelationshipJSON } from '@/store/modules/users/user-relationship';
import PracticeRelationship, { PracticeRelationshipJSON } from '@/store/modules/practices/practice-relationship';
import { prepareAPIUrl } from '@/utils/api';

const availableStatuses = Object.keys(PrivacyRequestStatus).map((key) => PrivacyRequestStatus[key]);

export interface PrivacyRequestJSON {
  id: number;
  status?: number;
  first_name?: string;
  last_name?: string;
  email?: string;
  country_id: number;
  type: string;
  details: string;
  created_at?: string;
  updated_at?: string;
  user_id?: number;
  user?: UserRelationshipJSON;
  practice_id?: number;
  practice?: PracticeRelationshipJSON;
  download_url?: string;
}

export default class PrivacyRequest extends JSONModel<PrivacyRequestJSON> {
  public id: number = 0;
  public status: PrivacyRequestStatus = PrivacyRequestStatus.Pending;
  public firstName: string = '';
  public lastName: string = '';
  public email: string = '';
  public countryId: number = 0;
  public type: string = '';
  public details: string = '';
  public createdAt: string = '';
  public updatedAt?: string;
  public userId: number = 0;
  public user: UserRelationship = new UserRelationship();
  public practiceId: number = 0;
  public practice?: PracticeRelationship;
  public downloadUrl: string = '';

  public fromJSON(json: PrivacyRequestJSON) {
    this.id = Number(json.id);
    if (typeof json.status !== 'undefined' && availableStatuses.indexOf(json.status) !== -1) {
      this.status = json.status;
    }
    this.firstName = String(json.first_name);
    this.lastName = String(json.last_name);
    if (json.email) {
      this.email = String(json.email);
    }
    this.countryId = Number(json.country_id);
    this.type = String(json.type);
    this.details = String(json.details);
    if (json.updated_at) {
      this.createdAt = String(json.created_at);
    }
    if (json.updated_at) {
      this.updatedAt = String(json.updated_at);
    }
    if (json.user_id) {
      this.userId = Number(json.user_id);
    }
    if (json.user && json.user_id) {
      this.user = UserRelationship.fromJSON(json.user);
    }
    if (json.practice_id) {
      this.practiceId = Number(json.practice_id);
    }
    if (json.practice && json.practice_id) {
      this.practice = PracticeRelationship.fromJSON(json.practice);
    }
    this.downloadUrl = prepareAPIUrl(String(json.download_url));
  }
}
