import { CancelTokenSource } from 'axios';
import { ListStatus, EditableStatus } from '@/store/types';
import Pagination from '@/store/models/pagination';
import Location from './location';

export enum StarStatus {
  NotStarred,
  Starred,
}

export enum LocationsActions {
  /**
   * List
   */
  LOAD_LOCATIONS_LIST = 'LOAD_LOCATIONS_LIST',
  LOAD_LOCATIONS_MENU_LIST = 'LOAD_LOCATIONS_MENU_LIST',
  LOAD_PENDING_LIST = 'LOAD_PENDING_LIST',
  LOAD_PUBLISHED_LIST = 'LOAD_PUBLISHED_LIST',

  /**
   * Edit
   */
  LOAD_LOCATION = 'LOAD_LOCATION',
  UPDATE_EDITABLE = 'UPDATE_EDITABLE',
  SAVE_EDITABLE = 'SAVE_EDITABLE',
  AUTOSAVE = 'AUTOSAVE',
  AUTOSAVE_DEBOUNCE = 'AUTOSAVE_DEBOUNCE',
  AUTOSAVE_STOP = 'AUTOSAVE_STOP',
  DELETE = 'DELETE',
  RESTORE = 'RESTORE',
  SET_IS_PREVIEW_FROM_EDIT_SCREEN = 'SET_IS_PREVIEW_FROM_EDIT_SCREEN',

  /**
   * Bulk
   */
  BULK_DELETE = 'BULK_DELETE',
  BULK_RESTORE = 'BULK_RESTORE',

  /**
   * Quick Review
   */
  LOAD_LOCATIONS_QUICK_REVIEW = 'LOAD_LOCATIONS_QUICK_REVIEW',
  SAVE_LOCATIONS_QUICK_REVIEW = 'SAVE_LOCATIONS_QUICK_REVIEW',
}

export enum LocationsMutations {
  /**
   * List
   */
  SET_LIST = 'SET_LIST',
  SET_LIST_PAGINATION = 'SET_LIST_PAGINATION',
  SET_LIST_STATUS = 'SET_LIST_STATUS',

  /**
   * Menu list
   */
  SET_MENU_LIST = 'SET_MENU_LIST',
  SET_MENU_LIST_TOTAL = 'SET_MENU_LIST_TOTAL',
  SET_MENU_LIST_STATUS = 'SET_MENU_LIST_STATUS',

  /**
   * Pending list
   */
  SET_PENDING_LIST = 'SET_PENDING_LIST',
  SET_PENDING_LIST_TOTAL = 'SET_PENDING_LIST_TOTAL',
  SET_PENDING_LIST_STATUS = 'SET_PENDING_LIST_STATUS',

  /**
   * Published list
   */
  SET_PUBLISHED_LIST_TOTAL = 'SET_PUBLISHED_LIST_TOTAL',
  SET_PUBLISHED_LIST_STATUS = 'SET_PUBLISHED_LIST_STATUS',

  /**
   * Edit
   */
  SET_EDITABLE = 'SET_EDITABLE',
  SET_EDITABLE_STATUS = 'SET_EDITABLE_STATUS',
  SET_AUTOSAVE = 'SET_AUTOSAVE',
  SET_AUTOSAVE_STATUS = 'SET_AUTOSAVE_STATUS',
  SET_AUTOSAVE_TIMEOUT = 'SET_AUTOSAVE_TIMEOUT',
  SET_AUTOSAVE_CANCEL_TOKEN = 'SET_AUTOSAVE_CANCEL_TOKEN',
  SET_HAS_AUTOSAVE = 'SET_HAS_AUTOSAVE',
  UPDATE_EDITABLE = 'UPDATE_EDITABLE',
  UPDATE_INITIAL_EDITABLE = 'UPDATE_INITIAL_EDITABLE',

  /**
   * Preview flag
   */
  SET_IS_PREVIEW_FROM_EDIT_SCREEN = 'SET_IS_PREVIEW_FROM_EDIT_SCREEN',

  /**
   * Quick Review
   */
  SET_QUICK_REVIEW_STATUS = 'SET_QUICK_REVIEW_STATUS',
  SET_QUICK_REVIEW_LOCATION = 'SET_QUICK_REVIEW_LOCATION',
  SET_QUICK_REVIEW_TOTAL = 'SET_QUICK_REVIEW_TOTAL',
}

export interface LocationsState {
  /**
   * Preview flag
   */
  isPreviewFromEditScreen: boolean;

  /**
   * List
   */
  listStatus: ListStatus;
  locationsList: Location[];
  pagination: Pagination;

  /**
   * Menu list
   */
  menuLocationsList: Location[];
  menuListTotalItems: number;
  menuListStatus: ListStatus;

  /**
   * Pending list
   */
  pendingListTotalItems: number;
  pendingList: Location[];
  pendingListStatus: ListStatus;

  /**
   * Published list
   */
  publishedListTotalItems: number;
  publishedListStatus: ListStatus;

  /**
   * Edit
   */
  editableStatus: EditableStatus;
  editable?: Location;
  initialEditable?: Location;
  autosaveEditable?: Location;
  autosaveStatus: EditableStatus;
  hasAutosave: boolean;
  autosaveTimeout?: number;
  autosaveCancelToken?: CancelTokenSource;

  /**
   * Quick Review
   */
  quickReviewStatus: EditableStatus;
  quickReviewTotal: number;
  quickReviewLatestEditable?: Location;
  quickReviewPublishedEditable?: Location;
}
