
import Vue from 'vue';
import { mapState } from 'vuex';

import i18n from '@/i18n';
import SelectInput from './select-input.vue';

export default Vue.extend({
  name: 'LanguagePicker',
  inheritAttrs: false,
  inject: ['$validator'],
  components: {
    SelectInput,
  },
  props: {
    value: {
      type: String,
    },
    label: {
      type: String,
      default: i18n.t('Language'),
    },
    name: {
      type: String,
      default: 'language',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    disabledOptions: {
      type: Array as () => string[],
      default: () => [] as string[],
    },
  },
  computed: {
    ...mapState(['languages']),
  },
});
