import { PluginFunction } from 'vue';
import VueRouter, { Route } from 'vue-router';
import store from '@/store';
import { RootState } from '@/store/types';

let installed = false;
let isLoaded = false;
let isLoading = false;
let router: VueRouter;

/**
 * Maybe load the script
 */
const loadScript = (): void => {
  // Check if the script is already loaded or is in process of loading
  if (isLoaded || isLoading) {
    return;
  }

  isLoading = true;

  const mkoScript = document.createElement('script');
  const scriptUrl = 'https://assets.adoberesources.net/loader.js?orgId=B45C35276284218E0A495C49%40AdobeOrg&instanceId=cynosure1&env=prod&geo=va7';

  mkoScript.setAttribute('src', scriptUrl);
  mkoScript.setAttribute('type', 'text/javascript');
  mkoScript.setAttribute('async', 'true');

  mkoScript.onload = () => {
    isLoaded = true;
    isLoading = false;
  };

  mkoScript.onerror = () => {
    mkoScript.remove();
    isLoading = false;
  };

  document.body.appendChild(mkoScript);
};

const mkoPlugin: PluginFunction<{ router: VueRouter }> = (vue) => {
  // Make sure the plugin is only installed once
  if (installed) {
    return;
  }

  installed = true;

  window.addEventListener('load', () => {
    store.watch(
      (state: RootState, getters) => ({
        modal: getters['user/profile/showTermsModal'] as boolean,
        banner: getters['user/profile/showTermsBanner'] as boolean,
      }),
      (val) => {
          if (val.modal || val.banner) {
            return;
          }else{
            loadScript();
          }
      },
      { immediate: true },
    );
  });
};

export default mkoPlugin;
