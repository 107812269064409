
import Vue from 'vue';
import SystemBanner from '@/components/blocks/system-banner.vue';
import LoadingIndicator from '@/components/layout/loading-indicator.vue';

export default Vue.extend({
  name: 'Basic',
  components: {
    SystemBanner,
    LoadingIndicator,
  },
});
