
import { mapState } from 'vuex';
import mixins from 'vue-typed-mixins';

import imageSizeMixin from '@/components/mixins/image-size';
import domUtils from '@/components/mixins/dom-utils';

export default mixins(imageSizeMixin, domUtils).extend({
  name: 'PreviewLocationView',
  computed: {
    ...mapState('treatments', ['treatmentsTree']),
    ...mapState('locations', {
      location: 'editable',
    }),
    ...mapState('locations', ['isPreviewFromEditScreen']),
    ...mapState('practitioners', ['practitionersList']),
    ...mapState('practices', ['editablePractice']),
    hasLogo(): boolean {
      return Boolean(this.editablePractice.thumbnail);
    },
    logo(): string | undefined {
      return this.hasLogo ? this.sizedPracticeLogo(this.editablePractice.thumbnail) : undefined;
    },
    intro() {
      const intro = /^(.*?)[.?!]\s/.exec(this.location.description);
      return intro && intro.length > 0 ? intro[0] : '';
    },
    description() {
      const restOfTheText = this.location.description.replace(/^(.*?)[.?!]\s/, '');
      return this.convertToParagraphs(restOfTheText);
    },
    address() {
      return [
        this.location.street,
        this.location.additional,
        [[this.location.city, this.location.state].filter(Boolean).join(', '), this.location.postalCode]
          .filter(Boolean)
          .join(' '),
        this.phone,
      ]
        .filter(Boolean)
        .join('<br />');
    },
    phone() {
      return this.location.phone ? this.$t('Phone:') + ' ' + this.location.phone : '';
    },
    map() {
      return this.location.mapUrl
        ? this.sizedLocationMap(this.location.mapUrl)
        : require('@/assets/images/location-empty.png');
    },
    treatments() {
      const flattenedAllTreatments = this.treatmentsTree.reduce((list, product) => {
        product.treatments.forEach((treatment) => {
          list[treatment.id] = treatment.title;
        });
        return list;
      }, {});
      return this.location.treatments.map((treatment) => flattenedAllTreatments[treatment.id]);
    },
    practitioners() {
      const keyedPractitionersList = this.practitionersList.reduce((list, practitioner) => {
        list[practitioner.id] = { ...practitioner };
        return list;
      }, {});
      return this.location.practitioners.map((practitioner) => keyedPractitionersList[practitioner.id]).filter(Boolean);
    },
  },
  methods: {
    convertToParagraphs(text: string) {
      return text
        .split(/\r\n|\r|\n/g)
        .map((paragraph) => '<p>' + paragraph + '</p>')
        .join('');
    },
    goBack(): void {
      this.$router.back();
    },
  },
  mounted() {
    this.addBodyClass('u-overflow-hidden');
  },
  destroyed() {
    this.removeBodyClass('u-overflow-hidden');
  },
});
