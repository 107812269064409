/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'user-admin': {
    width: 23.4,
    height: 24,
    viewBox: '0 0 23.4 24',
    data: '<g data-name="Layer 2"><g data-name="Outline Icons"><path pid="0" d="M12.5 15a.5.5 0 01-.5-.5v-2.8a.5.5 0 111 0v2.8a.5.5 0 01-.5.5zM12.5 22H.5a.5.5 0 01-.5-.5v-2.39a3.51 3.51 0 012.32-3.3L7 14.15V11.7a.5.5 0 111 0v2.8a.5.5 0 01-.33.47l-5 1.79A2.51 2.51 0 001 19.11V21h11.5a.5.5 0 010 1z"/><path pid="1" d="M10 13c-3 0-5.5-2.92-5.5-6.5S7 0 10 0s5.5 2.92 5.5 6.5S13 13 10 13zm0-12C7.52 1 5.5 3.47 5.5 6.5S7.52 12 10 12s4.5-2.47 4.5-5.5S12.48 1 10 1z"/><path pid="2" d="M13.67 6.59A3.44 3.44 0 0110.73 5 5.49 5.49 0 017 6.5 4.5 4.5 0 014.83 6l.46-.89A3.49 3.49 0 007 5.5a4.13 4.13 0 003.32-1.66.5.5 0 01.43-.27.48.48 0 01.44.25c.85 1.49 1.75 2 3.21 1.69h.48l.12 1h-.42a4.91 4.91 0 01-.91.08zM19.5 24h-2a.5.5 0 01-.5-.5v-.81a4 4 0 01-1-.54l-.69.4a.51.51 0 01-.38 0 .5.5 0 01-.3-.23l-1-1.73a.5.5 0 01.18-.68l.69-.4a3.52 3.52 0 010-1.1l-.69-.4a.5.5 0 01-.18-.68l1-1.73a.5.5 0 01.68-.18l.69.4a4 4 0 011-.54v-.78a.5.5 0 01.5-.5h2a.5.5 0 01.5.5v.81a4 4 0 011 .54l.69-.4a.5.5 0 01.68.18l1 1.73a.5.5 0 01-.18.68l-.69.4a3.52 3.52 0 010 1.1l.69.4a.5.5 0 01.18.68l-1 1.73a.5.5 0 01-.3.23.51.51 0 01-.38 0l-.69-.4a4 4 0 01-1 .54v.81a.5.5 0 01-.5.47zM18 23h1v-.66a.5.5 0 01.36-.48 3 3 0 001.2-.68.5.5 0 01.59-.07l.57.33.5-.87-.57-.33a.5.5 0 01-.24-.55 2.75 2.75 0 000-1.38.5.5 0 01.24-.55l.57-.33-.5-.87-.57.33a.5.5 0 01-.59-.07 3 3 0 00-1.2-.68.5.5 0 01-.36-.48V15h-1v.66a.5.5 0 01-.36.48 3 3 0 00-1.2.68.5.5 0 01-.59.07l-.57-.33-.5.87.57.33a.5.5 0 01.24.55 2.75 2.75 0 000 1.38.5.5 0 01-.24.55l-.57.33.5.87.57-.33a.5.5 0 01.59.07 3 3 0 001.2.68.5.5 0 01.36.48z"/><path pid="3" d="M18.5 21a2 2 0 112-2 2 2 0 01-2 2zm0-3a1 1 0 101 1 1 1 0 00-1-1z"/></g></g>'
  }
})
