import Vue from 'vue';
import { MutationTree } from 'vuex';

import { EditableStatus, ListStatus } from '@/store/types';
import { SupportState, SupportMutations } from '@/store/modules/support/types';
import FAQ from '@/store/modules/support/faq';

export const mutations: MutationTree<SupportState> = {
  /**
   * FAQ List
   */
  [SupportMutations.SET_FAQ_LIST](state, categories: FAQ[]) {
    state.faqList = categories;
  },
  [SupportMutations.SET_FAQ_LIST_STATUS](state, status: ListStatus) {
    state.faqListStatus = status;
  },

  /**
   * Edit
   */
  [SupportMutations.SET_FAQ_EDITABLE](state, { faqs, updateInitial = true }: { faqs: FAQ[]; updateInitial?: boolean }) {
    Vue.set(state, 'faqEditable', faqs);
    if (updateInitial) {
      Vue.set(
        state,
        'initialFAQEditable',
        faqs.map((faq) => FAQ.clone(faq)),
      );
    }
  },
  [SupportMutations.SET_FAQ_EDITABLE_STATUS](state, editableStatus: EditableStatus) {
    state.faqEditableStatus = editableStatus;
  },
  [SupportMutations.UPDATE_FAQ_EDITABLE]: (state, changes: Partial<FAQ> & { id: FAQ['id'] }) => {
    if (!state.faqEditable) {
      throw new Error('Invalid data');
    }

    const faq = state.faqEditable.find((item) => item.id === changes.id);

    if (!faq) {
      throw new Error('Invalid data');
    }

    Object.keys(changes)
      // Don't update ID key
      .filter((key) => key !== 'id')
      .forEach((key) => {
        // Add new reactive property to the object
        Vue.set(faq, key, changes[key]);
      });
  },
  [SupportMutations.SET_FAQ_TRANSLATIONS_STATUS](state, translationsStatus: EditableStatus) {
    state.faqTranslationsStatus = translationsStatus;
  },
};

export default mutations;
