import { createCRUDEndpoints } from '@/api/factory/crud';
import { PrivacyRequestJSON } from '@/store/modules/privacy_requests/privacy_request';
import { PrivacyRequestStatus } from '@/store/modules/privacy_requests/types';
import httpClient from '../http-client';
import { RequestConfig } from '../types';

type ListParams =
  | {
      user_id?: number;
      practice_id?: number;
      search?: string;
      status?: PrivacyRequestStatus;
      page?: string | number;
      sort?: string;
      direction?: 'asc' | 'desc';
    }
  | undefined;

export const { list, get, store, remove } = createCRUDEndpoints<PrivacyRequestJSON, ListParams>({
  slug: 'privacy_request',
});

export function download(requestId: PrivacyRequestJSON['id'], config?: RequestConfig) {
  return httpClient.get<PrivacyRequestJSON>(`privacy_request/${requestId}/download`, config);
}
