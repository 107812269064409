
import Vue from 'vue';
import { mapState, mapGetters, mapActions } from 'vuex';
import values from 'lodash/values';

import FormInput from '@/components/controls/form-input.vue';
import NotificationBanner from '@/components/blocks/notification-banner.vue';
import Publish from '@/components/forms/publish.vue';
import CountrySelection from '@/components/controls/country-selection.vue';

import { EditableStatus } from '@/store/types';
import Practice from '@/store/modules/practices/practice';
import { PracticesActions, PracticeStatus } from '@/store/modules/practices/types';
import Autocomplete, {
  AutocompleteOption,
  AutocompleteResponse,
  AutocompletePromise,
} from '@/components/controls/autocomplete.vue';
import Product from '@/store/modules/products/product';
import ProductRelationship from '@/store/modules/products/product-relationship';
import Taxonomy from '@/store/models/taxonomy';
import TreatmentEnhancement from '@/store/modules/treatments/enhancement';

export default Vue.extend({
  inject: {
    $validator: '$validator',
  },
  name: 'EditPracticeView',
  components: {
    FormInput,
    NotificationBanner,
    Publish,
    Autocomplete,
    CountrySelection,
  },
  props: {
    add: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      productsInput: '',
      productAccessoriesInput: '',
      enhancementsInput: '',
      resourceProductsInput: '',
    };
  },
  computed: {
    ...mapState('practices', {
      practice: 'editablePractice',
      editableStatus: 'editableStatus',
    }),
    ...mapGetters('practices', {
      isLoading: 'editableIsLoading',
      isSaving: 'editableIsSaving',
      hasUnsavedChanges: 'editableHasUnsavedChanges',
    }),
    ...mapState('products', {
      productsTree: 'tree',
    }),
    ...mapState('treatments', {
      enhancementsTree: 'enhancementsTree',
    }),
    isFetching() {
      return this.isLoading || this.isSaving;
    },
    isInactive() {
      return this.practice.status === PracticeStatus.Inactive;
    },
    getProducts(): AutocompleteOption[] {
      const productsIds = this.practice.products.map((product) => product.id);

      return this.productsTree
        .filter((product) => productsIds.indexOf(product.id) === -1)
        .map((product) => ({
          label: product.title,
          value: product.id,
        }))
        .filter((option) => option.label.toLowerCase().indexOf(this.productsInput.toLowerCase()) > -1);
    },
    getResourceProducts(): AutocompleteOption[] {
      const productsIds = this.practice.products.map((product) => product.id);
      const resourceProductsIds = this.practice.resourceProducts.map((product) => product.id);

      return this.productsTree
        .filter((product) => resourceProductsIds.indexOf(product.id) === -1)
        .filter((product) => productsIds.indexOf(product.id) === -1)
        .map((product) => ({
          label: product.title,
          value: product.id,
        }))
        .filter((option) => option.label.toLowerCase().indexOf(this.resourceProductsInput.toLowerCase()) > -1);
    },

    practiceResourceProducts() {
      const productsIds = this.practice.products.map((product) => product.id);
      return this.practice.resourceProducts.filter((product) => productsIds.indexOf(product.id) === -1);
    },
    allProductAccessories() {
      const productsIds = this.practice.products.map((product) => product.id);
      const initial: { [key: number]: Taxonomy } = {};

      return values(
        this.productsTree
          .filter((product) => productsIds.indexOf(product.id) !== -1)
          .map((product) => product.accessories)
          .reduce((res, accessories) => {
            accessories.forEach((accessory) => {
              res[accessory.id] = accessory;
            });

            return res;
          }, initial),
      );
    },

    getProductAccessories(): Taxonomy[] {
      const ids = this.selectedProductAccessories.map(({ id }) => id);
      return this.allProductAccessories.filter(({ id }) => ids.indexOf(id) === -1);
    },

    getProductAccessoriesOptions(): AutocompleteOption[] {
      return this.getProductAccessories
        .map((accessory) => ({
          label: accessory.title,
          value: accessory.id,
        }))
        .filter(({ label }) => label.toLowerCase().indexOf(this.productAccessoriesInput.toLowerCase()) > -1);
    },

    hasProductAccessories(): boolean {
      return Boolean(this.allProductAccessories.length);
    },

    hasProductAccessoriesOptions(): boolean {
      return Boolean(this.getProductAccessories.length);
    },

    selectedProductAccessories(): Taxonomy[] {
      const availableIds = this.allProductAccessories.map(({ id }) => id);

      return this.practice.accessories.filter(({ id }) => availableIds.indexOf(id) !== -1);
    },
    getTreatmentEnhancements(): AutocompleteOption[] {
      const ids = this.practice.enhancements.map(({ id }) => id);

      return this.enhancementsTree
        .filter(({ id }) => ids.indexOf(id) === -1)
        .map(({ id, title }) => ({
          label: title,
          value: id,
        }))
        .filter(({ label }) => label.toLowerCase().indexOf(this.enhancementsInput.toLowerCase()) > -1);
    },
  },
  methods: {
    ...mapActions('practices', {
      updatePractice: PracticesActions.UPDATE_EDITABLE,
      save: PracticesActions.SAVE_EDITABLE,
      removePractice: PracticesActions.DELETE,
      restorePractice: PracticesActions.RESTORE,
    }),
    addProduct(option: AutocompleteOption) {
      const products = [...this.practice.products];
      const product = this.productsTree.find((item) => item.id === option.value);
      if (!product) {
        return;
      }

      products.push(ProductRelationship.fromJSON(product.toJSON()));

      this.updatePractice({ products });
    },

    toggleTrackingCheckbox(event: InputEvent) {
      const target = event.target! as HTMLInputElement;
      const tracking = target.checked ? 1 : 0;

      this.updatePractice({ liineTracking: tracking });
    },

    removeProduct(productId: number) {
      const products = this.practice.products.filter((product) => product.id !== productId);

      this.updatePractice({ products });
    },

    addResourceProduct(option: AutocompleteOption) {
      const resourceProducts = [...this.practice.resourceProducts];
      const product = this.productsTree.find((item) => item.id === option.value);
      if (!product) {
        return;
      }

      resourceProducts.push(ProductRelationship.fromJSON(product.toJSON()));

      this.updatePractice({ resourceProducts });
    },

    removeResourceProduct(productId: number) {
      const resourceProducts = this.practice.resourceProducts.filter((product) => product.id !== productId);

      this.updatePractice({ resourceProducts });
    },

    addProductAccessory(option: AutocompleteOption) {
      const accessories = [...this.practice.accessories];
      const accessory = this.allProductAccessories.find((item) => item.id === option.value);
      if (!accessory) {
        return;
      }

      accessories.push(accessory);

      this.updatePractice({ accessories });
    },

    removeProductAccessory(accessoryId: number) {
      const accessories = this.selectedProductAccessories.filter(({ id }) => id !== accessoryId);

      this.updatePractice({ accessories });
    },

    addTreatmentEnhancement(option: AutocompleteOption) {
      const enhancements = [...this.practice.enhancements];
      const enhancement = this.enhancementsTree.find((item) => item.id === option.value);
      if (!enhancement) {
        return;
      }

      enhancements.push(Taxonomy.fromJSON(enhancement.toJSON()));

      this.updatePractice({ enhancements });
    },

    removeTreatmentEnhancement(enhancementId: number) {
      const enhancements = this.practice.enhancements.filter(({ id }) => id !== enhancementId);

      this.updatePractice({ enhancements });
    },

    remove() {
      this.removePractice({ id: this.practice.id });
    },

    restore() {
      this.restorePractice({ id: this.practice.id });
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.hasUnsavedChanges) {
      const answer = window.confirm(this.$t('Do you really want to leave? You have unsaved changes!'));

      if (!answer) {
        next(false);
        return;
      }
    }

    next();
  },
});
