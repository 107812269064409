
import Vue, { PropOptions } from 'vue';
import formInput from '@/components/controls/form-input.vue';

// Based off https://medium.com/@logaretm/authoring-validatable-custom-vue-input-components-1583fcc68314
export default Vue.extend({
  name: 'FormInputArrayControl',
  components: {
    formInput,
  },
  inject: {
    $validator: '$validator',
  },
  props: {
    validate: String,
    value: {
      type: Array,
      default: () => [],
    } as PropOptions,
    name: String,
    label: String,
    autocomplete: String,
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      focusItem: undefined,
    };
  },
  computed: {
    items() {
      return this.value && this.value.length > 0 ? this.value : [''];
    },
  },
  methods: {
    removeItem(index) {
      if (this.disabled) {
        return;
      }

      this.$emit(
        'input',
        (this.items as string[]).filter((item, i) => i !== index),
      );
    },
    addItem() {
      if (this.disabled) {
        return;
      }

      this.focusItem = this.items.length;
      this.$emit('input', [...this.items, '']);
    },
    changeItem(index, value) {
      if (this.disabled) {
        return;
      }

      const items = [...this.items];
      items[index] = value;
      this.$emit('input', items);
    },
  },
});
