/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'user-load': {
    width: 24,
    height: 23.93,
    viewBox: '0 0 24 23.93',
    data: '<g data-name="Layer 2"><g data-name="Outline Icons"><path pid="0" d="M23.5 17.93H21a.5.5 0 010-1h2v-2a.5.5 0 011 0v2.5a.5.5 0 01-.5.5z"/><path pid="1" d="M23.5 17.93a.5.5 0 01-.47-.33 4.38 4.38 0 00-8.06 0 .5.5 0 01-.94-.33 5.37 5.37 0 019.94 0 .5.5 0 01-.47.67zM14.5 23.43a.5.5 0 01-.5-.5v-2.5a.5.5 0 01.5-.5H17a.5.5 0 010 1h-2v2a.5.5 0 01-.5.5z"/><path pid="2" d="M19 23.93a5.34 5.34 0 01-5-3.33.5.5 0 01.94-.33 4.38 4.38 0 008.06 0 .5.5 0 01.94.33A5.34 5.34 0 0119 23.93zM11 19.93H.5a.5.5 0 01-.5-.51 17.4 17.4 0 01.53-4.15c.38-1.16 2.24-1.77 4.6-2.55.62-.2 1.26-.42 1.88-.64v-1.86a3.6 3.6 0 01-1.5-2.93 1.55 1.55 0 01-.6-1.36A2 2 0 015.12 5a1.17 1.17 0 01.29-.37c-.22-.76-.59-2.11.08-2.84a1.41 1.41 0 011.2-.42C7.27.37 8.91 0 10.25 0c1.52 0 3.4.47 3.74 1.81a4.64 4.64 0 01-.37 2.79 1.72 1.72 0 01.47 1.29 1.6 1.6 0 01-.6 1.39A3.6 3.6 0 0112 10.22v1.86c.68.25 1.35.47 2 .68h.13a.5.5 0 01-.31.95h-.14c-.74-.25-1.54-.51-2.35-.82a.5.5 0 01-.32-.47V9.93a.5.5 0 01.31-.46s1.19-.55 1.19-2.54a.5.5 0 01.5-.5 2.05 2.05 0 00.09-.54.87.87 0 00-.1-.55.45.45 0 01-.45-.48 1.55 1.55 0 01.16-.55A3.89 3.89 0 0013 2c-.15-.6-1.34-1-2.76-1s-2.62.45-2.76 1a.5.5 0 01-.58.37c-.33-.07-.58 0-.68.06-.3.33 0 1.54.16 1.94a2 2 0 01.11.51.5.5 0 01-.5.5 1.6 1.6 0 00-.09.5.8.8 0 00.1.56.45.45 0 01.46.49c0 2 1.15 2.52 1.2 2.54a.51.51 0 01.3.46v2.5a.5.5 0 01-.32.47c-.73.28-1.5.53-2.24.78-1.75.58-3.74 1.23-4 1.91a14.46 14.46 0 00-.4 3.34h10a.5.5 0 010 1z"/></g></g>'
  }
})
