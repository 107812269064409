
import { mapGetters } from 'vuex';
import mixins from 'vue-typed-mixins';

import createSlug from '@/utils/slug';

import { PublishStatus } from '@/store/types';

import { imageSizeMixin } from '@/components/mixins/image-size';
import Taxonomy from '@/store/models/taxonomy';
import { Resource } from 'i18next';

const associatedCategoryIcons = {
  'Before & After Images': 'before-after',
  'Email Marketing': 'email',
  'Event Collateral': 'calendar',
  'In-Office Materials': 'book',
  'Laser Safety & Laser Science': 'laser',
  'Product Support': 'medicine',
  'Seasonal Promotions': 'tag',
  'Social Media': 'share',
  'Staff Training': 'user',
  'Videos': 'video',
  'Website Materials': 'application',
};

export default mixins(imageSizeMixin).extend({
  name: 'ResourceBlock',
  props: {
    resource: {
      type: Object as () => Resource,
      required: true,
    },
    featured: {
      type: Boolean,
      default: false,
    },
    home: {
      type: Boolean,
      default: false,
    },
    noImage: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters(['languageName']),
    ...mapGetters('user/profile', ['isInNorthAmerica', 'isSuperAdmin', 'isAdmin', 'isSales', 'isClinical']),
    thumbnail() {
      if (this.resource.thumbnail && this.resource.thumbnail.thumbnail) {
        return this.sizedResourceThumbnail(this.resource.thumbnail.thumbnail);
      }

      return require('@/assets/images/paper-cover-default.png');
    },
    count() {
      let count = 0;
      if (this.resource.filesCount) {
        count += this.resource.filesCount;
      }

      if (this.resource.linksCount) {
        count += this.resource.linksCount;
      }

      if (this.resource.video && this.resource.video.length > 0) {
        count += 1;
      }

      return this.$t('%(count)% Asset', { count });
    },
    slug() {
      return createSlug(this.resource.title);
    },
    detailsLink() {
      const resourceRoute = (this.$route.name === 'clinical-support') ? 'clinical-support-resource' : 'resource';
      return {
        name: resourceRoute,
        params: { slug: this.slug, id: this.resource.id },
      };
    },
    image() {
        if(this.noImage) return false;
        return true;
    },
    isFeatured() {
      return this.featured || false;
    },
    isDeleted() {
      return this.resource.status === PublishStatus.Deleted;
    },
    isHome() {
      return this.home || false;
    },
    categories() {
      return this.resource.categories.map((category: Taxonomy) => category.title).join(', ');
    },
    categoryIcon() {
      const primaryCategory: Taxonomy | undefined = this.resource.categories[0];

      if (primaryCategory && primaryCategory.title && associatedCategoryIcons[primaryCategory.title]) {
        return associatedCategoryIcons[primaryCategory.title];
      }

      return 'gear';
    },
    classObject() {
      const classes = ['c-card', 'c-resource-card'];

      if (this.isFeatured) {
        classes.push('c-resource-card--hor', 'c-card__title--larger');
      } else if (this.isHome) {
        classes.push('c-resource-card--hor');
      } else {
        classes.push('c-figure-fan--disable');
      }
      if (
        this.resource.personalize &&
        (this.isInNorthAmerica || this.isSuperAdmin || this.isAdmin || this.isClinical || this.isSales)
      ) {
        classes.push('c-resource-card--personalizable');
      }

      return classes;
    },
    headerClass() {
      const classes = ['c-card__header'];
      if (
        this.resource.personalize &&
        (this.isInNorthAmerica || this.isSuperAdmin || this.isAdmin || this.isClinical || this.isSales)
      ) {
        classes.push('u-bg--yellow-light');
      }
      return classes;
    },
    descriptionExcerpt() {
      return this.createExcerpt(this.resource.description);
    },
    globeItems() {
      let items = [this.$t('All Countries')];
      if (this.resource.countries && this.resource.countries.length) {
        items = this.resource.countries.map((item) => item.name);
      }

      const languageName: string | undefined = this.languageName(this.resource.languageCode);

      if (languageName) {
        items.push(languageName);
      }

      return items.join(', ');
    },
  },
  methods: {
    createExcerpt(excerpt) {
      return excerpt.length > 80 ? excerpt.substring(0, 80) + '...' : excerpt;
    },
  },
});
