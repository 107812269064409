
import Vue from 'vue';
import { mapActions, mapGetters } from 'vuex';

import { ResourcesActions } from '@/store/modules/resources/types';

import Checkbox from '@/components/controls/checkbox.vue';

export default Vue.extend({
  name: 'ResourceAssetCompleteTrainingForm',
  components: {
    Checkbox,
  },
  props: {
    asset: {
      type: Object,
      required: true,
    },
    assetType: {
      type: String as () => 'file' | 'link',
      required: true,
    },
  },
  computed: {
    ...mapGetters('resources', {
      isLoading: 'completeAssetTrainingIsLoading',
    }),
  },
  methods: {
    ...mapActions('resources', {
      completeTraining: ResourcesActions.COMPLETE_ASSET_TRAINING,
    }),
    update() {
      return this.completeTraining({
        assetType: this.assetType,
        assetId: this.asset.id,
      });
    },
  },
});
