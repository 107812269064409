import { createCRUDEndpoints } from '@/api/factory/crud';
import { ConsultationStatsJSON } from '@/store/modules/consultations/stats';
import { PracticeJSON } from '@/store/modules/practices/practice';
import { PublishStatus } from '@/store/types';
import httpClient from '../http-client';
import { RequestConfig } from '../types';

type ListParams =
  | {
      title?: string;
      search?: string;
      status?: PublishStatus;
      page?: string | number;
      sort?: string;
      direction?: 'asc' | 'desc';
    }
  | undefined;

export const { list, get, store, remove, restore } = createCRUDEndpoints<PracticeJSON, ListParams>({
  slug: 'practice',
});

export function getConsultationStats(id: PracticeJSON['id'], config?: RequestConfig) {
  return httpClient.get<ConsultationStatsJSON>(`practice/${id}/consultation/stats`, config);
}

export function search(input: string, config?: RequestConfig) {
  return httpClient.get<PracticeJSON[]>(`/search/practice/${input}`, config);
}
