import { JSONModel } from './jsonModel';

export interface ImageJSON {
  id: number;
  name: string;
  mime: string;
  uri: string;
  title: string;
  alt: string;
  preview: string;
  s3: string;
  thumbnail: string;
  created_at: string;
  updated_at: string;
  user_id?: number;
}

export default class Image extends JSONModel<ImageJSON> {
  public id: number = 0;
  public name: string = '';
  public mime: string = '';
  public uri: string = '';
  public title: string = '';
  public alt: string = '';
  public preview: string = '';
  public s3: string = '';
  public thumbnail: string = '';
  public createdAt: string = '';
  public updatedAt: string = '';
  public userId: number = 0;

  protected _clientProperties = ['mime', 'uri', 'preview', 's3', 'thumbnail', 'createdAt', 'updatedAt', 'userId'];

  public fromJSON(json: Partial<ImageJSON>) {
    this.id = Number(json.id);
    this.name = String(json.name);
    this.mime = String(json.mime);
    this.uri = String(json.uri);
    this.alt = String(json.alt);
    this.preview = String(json.preview);
    this.s3 = String(json.s3);
    this.thumbnail = String(json.thumbnail);
    this.createdAt = String(json.created_at);
    this.updatedAt = String(json.updated_at);

    if (json.user_id) {
      this.userId = Number(json.user_id);
    }
  }
}
