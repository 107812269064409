import { JSONModel } from '@/store/models/jsonModel';

export interface LegalJSON {
  id: number;
  language_id: number;
  status: number;
  type: string;
  body: string;
  created_at: string;
  updated_at: string;
  translations: {
    body: Record<string, string>;
  };
}

export const enum LegalStatus {
  Inactive,
  Active,
}

export const enum LegalType {
  Modal = 'modal',
  Banner = 'banner',
  Privacy = 'privacy',
}

export default class Legal extends JSONModel<LegalJSON> {
  public id = 0;
  public languageId = 0;
  public status = LegalStatus.Active;
  public type = LegalType.Modal;
  public body = '';
  public translations: {
    body: Record<string, string>;
  } = { body: {} };
  protected _clientProperties = ['translations'];

  public fromJSON(json: Partial<LegalJSON>) {
    if (json.id) {
      this.id = Number(json.id);
    }

    if (json.language_id) {
      this.languageId = Number(json.language_id);
    }

    if (json.status === LegalStatus.Inactive || json.status === LegalStatus.Active) {
      this.status = json.status;
    }

    if (json.type === LegalType.Modal || json.type === LegalType.Banner || json.type === LegalType.Privacy) {
      this.type = json.type;
    }

    if (json.body) {
      this.body = String(json.body);
    }

    if (json.translations) {
      this.translations = json.translations;
    }

    return this;
  }
}
