import { getEnumValue } from '@/utils/enum';
import { JSONModel } from '@/store/models/jsonModel';
import LocationRelationship, { LocationRelationshipJSON } from '@/store/modules/locations/location-relationship';
import Image, { ImageJSON } from '@/store/models/image';
import { PracticeRelationshipJSON } from '@/store/modules/practices/practice-relationship';
import Practice from '@/store/modules/practices/practice';
import { PublishStatus } from '@/store/types';
import { fixLineEndings } from '@/utils/api';
import { createExcerpt } from '@/utils/excerpt';

export interface PractitionerJSON {
  id: number;
  practice_id: number;
  // language_id: number;
  // language_code: string;
  status: number;
  title: string;
  first_name: string;
  middle_name: string;
  last_name: string;
  specialty: string;
  bio: string;
  // log: string;
  // path: string;
  created_at: string;
  updated_at: string;
  deleted_at?: string;
  // index_pending: number;
  // practice_title: string;
  listings: LocationRelationshipJSON[];
  image?: ImageJSON | ImageJSON[];
  practice: PracticeRelationshipJSON;
  state: string;
  country_id: number;
}

export default class Practitioner extends JSONModel<PractitionerJSON> {
  public id: number = 0;
  public practiceId: number = 0;
  public practice?: Practice;
  public status: PublishStatus = PublishStatus.Pending;
  public title: string = '';
  public firstName: string = '';
  public middleName: string = '';
  public lastName: string = '';
  public specialty: string = '';
  public bio: string = '';
  // log: string;
  // path: string;
  public createdAt: string = '';
  public updatedAt: string = '';
  public deletedAt?: string = '';
  // index_pending: number;
  // practice_title: string;
  public locations: LocationRelationship[] = [];
  public image?: Image;
  public state: string = '';
  public countryId: number = 0;

  protected _alias = {
    listings: 'locations',
  };

  public fromJSON(json: Partial<PractitionerJSON>) {
    if (json.id) {
      this.id = Number(json.id);
    }

    if (json.practice_id) {
      this.practiceId = Number(json.practice_id);

      if (json.practice) {
        this.practice = Practice.fromJSON(json.practice);
      }
    }

    const status = getEnumValue<PublishStatus>(PublishStatus, Number(json.status));
    if (typeof status !== 'undefined') {
      this.status = status;
    }

    if (json.title) {
      this.title = String(json.title).trim();
    }

    if (json.first_name) {
      this.firstName = String(json.first_name).trim();
    }

    if (json.middle_name) {
      this.middleName = String(json.middle_name).trim();
    }

    if (json.last_name) {
      this.lastName = String(json.last_name).trim();
    }

    if (json.specialty) {
      this.specialty = String(json.specialty);
    }

    if (json.bio) {
      this.bio = fixLineEndings(String(json.bio));
    }

    if (json.created_at) {
      this.createdAt = String(json.created_at);
    }

    if (json.updated_at) {
      this.updatedAt = String(json.updated_at);
    }

    if (json.deleted_at) {
      this.deletedAt = String(json.deleted_at);
    }

    if (json.listings && Array.isArray(json.listings)) {
      this.locations = json.listings.map((item) => LocationRelationship.fromJSON(item));
    }

    if (json.image) {
      this.image = Image.fromJSON(Array.isArray(json.image) ? json.image[0] : json.image);
    }

    if (json.state) {
      this.state = json.state;
    }

    if (json.country_id) {
      this.countryId = Number(json.country_id);
    }
  }

  public get avatar() {
    if (this.image) {
      return this.image.thumbnail + '?fit=facearea&facepad=3&w=150&h=150';
    }

    return require('@/assets/images/profile-empty-150.png');
  }

  public get fullName() {
    return [[this.firstName, this.middleName, this.lastName].filter(Boolean).join(' '), this.title]
      .filter(Boolean)
      .join(', ');
  }

  public get listName() {
    let lastName = this.lastName;

    if (lastName) {
      lastName = `<strong>${lastName}</strong>`;
    }

    return [[this.firstName, this.middleName, lastName].filter(Boolean).join(' '), this.title]
      .filter(Boolean)
      .join(', ');
  }

  public get excerpt() {
    return createExcerpt(this.bio);
  }
}
