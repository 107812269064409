import Vue from 'vue';
import isEqual from 'lodash/isEqual';
import { EditableStatus } from '@/store/types';

const createEditableGetters = <S extends { [key: string]: any }>(
  statusKey: keyof S,
  editableKey?: keyof S,
  initialEditableKey?: keyof S,
) => ({
  isLoading: (state: S) => state[statusKey] === EditableStatus.Loading,
  isLoaded: (state: S) => state[statusKey] === EditableStatus.Loaded,
  isSaving: (state: S) => state[statusKey] === EditableStatus.Saving,
  isSaved: (state: S) => state[statusKey] === EditableStatus.Saved,
  isFailed: (state: S) => state[statusKey] === EditableStatus.Failed,
  isFetching: (state: S) => state[statusKey] === EditableStatus.Loading || state[statusKey] === EditableStatus.Saving,
  hasUnsavedChanges:
    editableKey && initialEditableKey
      ? (state: S) => {
          if (state[statusKey] === EditableStatus.Saving) {
            return true;
          }

          if (!state[editableKey] || !state[initialEditableKey]) {
            return false;
          }

          if (Array.isArray(state[initialEditableKey])) {
            if (!Array.isArray(state[editableKey])) {
              return true;
            }

            const editableValue = state[editableKey].map((item) => (item.toJSON ? item.toJSON() : item));
            const initialValue = state[initialEditableKey].map((item) => (item.toJSON ? item.toJSON() : item));

            return !isEqual(editableValue, initialValue);
          }

          if (!state[editableKey].toJSON || !state[initialEditableKey].toJSON) {
            return false;
          }

          return !isEqual(state[editableKey].toJSON(), state[initialEditableKey].toJSON());
        }
      : (state: S) => state[statusKey] === EditableStatus.Saving,
});

const updateEditable =
  <S extends { [key: string]: any }, P = any>(editableKey: keyof S) =>
  (state: S, payload: Partial<P>) => {
    if (!state[editableKey]) {
      Vue.set<Partial<P>>(state as any, editableKey as string, payload);
      return;
    }

    Object.keys(payload).forEach((key) => {
      // Add new reactive property to the object
      Vue.set(state[editableKey], key, payload[key]);
    });
  };

export { createEditableGetters, updateEditable };
