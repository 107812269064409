import { ListStatus, EditableStatus } from '@/store/types';
import Pagination from '@/store/models/pagination';
import ConsultationRequest from './consultation-request';
import ConsultationStats from './stats';
import i18n from '@/i18n';

export enum ConsultationRequestStatus {
  Inactive = 0,
  Active = 1,
}

export const ConsultationRequestStatuses = {
  [ConsultationRequestStatus.Inactive]: i18n.t('Inactive'),
  [ConsultationRequestStatus.Active]: i18n.t('Active'),
};

export enum ConsultationsActions {
  /**
   * List
   */
  LOAD_LIST = 'LOAD_LIST',
  LOAD_LESS_QUALIFIED_LIST = 'LOAD_LESS_QUALIFIED_LIST',

  /**
   * Edit
   */
  LOAD_EDITABLE = 'LOAD_EDITABLE',
  UPDATE_EDITABLE = 'UPDATE_EDITABLE',
  SAVE_EDITABLE = 'SAVE_EDITABLE',

  /**
   * Actions
   */
  DELETE = 'DELETE',
  RESTORE = 'RESTORE',
  COMPLETE_LESS_QUALIFIED = 'COMPLETE_LESS_QUALIFIED',

  /**
   * Bulk actions
   */
  BULK_DELETE = 'BULK_DELETE',
  BULK_RESTORE = 'BULK_RESTORE',

  /**
   * User Stats
   */
  LOAD_USER_STATS = 'LOAD_USER_STATS',
}

export enum ConsultationRequestsMutations {
  /**
   * List
   */
  SET_LIST = 'SET_LIST',
  SET_LIST_STATUS = 'SET_LIST_STATUS',
  SET_LIST_PAGINATION = 'SET_LIST_PAGINATION',
  SET_LESS_QUALIFIED_LIST = 'SET_LESS_QUALIFIED_LIST',
  SET_LESS_QUALIFIED_LIST_STATUS = 'SET_LESS_QUALIFIED_LIST_STATUS',
  SET_LESS_QUALIFIED_LIST_PAGINATION = 'SET_LESS_QUALIFIED_LIST_PAGINATION',

  /**
   * Edit
   */
  SET_EDITABLE = 'SET_EDITABLE',
  SET_EDITABLE_STATUS = 'SET_EDITABLE_STATUS',
  UPDATE_EDITABLE = 'UPDATE_EDITABLE',
  UPDATE_INITIAL_EDITABLE = 'UPDATE_INITIAL_EDITABLE',

  /**
   * User Stats
   */
  SET_USER_STATS = 'SET_USER_STATS',
  SET_USER_STATS_STATUS = 'SET_USER_STATS_STATUS',
}

export interface ConsultationRequestsState {
  /**
   * List
   */
  listStatus: ListStatus;
  listPagination: Pagination;
  list: ConsultationRequest[];
  lessQualifiedListStatus: ListStatus;
  lessQualifiedListPagination: Pagination;
  lessQualifiedList: ConsultationRequest[];

  /**
   * Edit
   */
  editable?: ConsultationRequest;
  initialEditable?: ConsultationRequest;
  editableStatus: EditableStatus;

  /**
   * User Stats
   */
  userStats?: ConsultationStats;
  userStatsStatus: ListStatus;
}
