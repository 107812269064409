import { ListStatus, MutationsTree } from '@/store/types';
import Pagination from '@/store/models/pagination';
import { ReportsState, ReportsMutations } from './types';
import Report from './report';

const mutations: MutationsTree<ReportsState, typeof ReportsMutations> = {
  /**
   * List
   */
  [ReportsMutations.SET_LIST](state, payload: Report[]) {
    state.list = payload;
  },
  [ReportsMutations.SET_LIST_STATUS](state, payload: ListStatus) {
    state.listStatus = payload;
  },
  [ReportsMutations.SET_LIST_PAGINATION](state, payload: Pagination) {
    state.listPagination = payload;
  },

  /**
   * Request
   */
  [ReportsMutations.START_CHECKING_REQUESTED](state) {
    state.isReportsCheckRunning = true;
  },
};

export default mutations;
