/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'home': {
    width: 16,
    height: 14,
    viewBox: '0 0 16 14',
    data: '<g data-name="Layer 2"><g data-name="Isolation Mode"><path pid="0" d="M12.5 14h-9a.5.5 0 01-.5-.5v-5a.5.5 0 011 0V13h8V8.5a.5.5 0 011 0v5a.5.5 0 01-.5.5zM15.5 7.5a.5.5 0 01-.33-.12L8 1.16.83 7.38a.5.5 0 01-.66-.76l7.5-6.5a.5.5 0 01.66 0l7.5 6.5a.5.5 0 01-.33.88z"/><path pid="1" d="M9.5 14h-3a.5.5 0 01-.5-.5v-4a.5.5 0 01.5-.5h3a.5.5 0 01.5.5v4a.5.5 0 01-.5.5zM7 13h2v-3H7z"/></g></g>'
  }
})
