import { GetterTree } from 'vuex';
import { RootState, ListStatus, EditableStatus, PublishStatus } from '@/store/types';
import { LocationsState } from '@/store/modules/locations/types';
import { PracticeStatus } from '@/store/modules/practices/types';
import { createEditableGetters } from '@/store/utils/editable';
import i18next from '@/i18n';

const { hasUnsavedChanges: editableHasUnsavedChanges } = createEditableGetters<LocationsState>(
  'editableStatus',
  'editable',
  'initialEditable',
);
const { hasUnsavedChanges: autosaveHasUnsavedChanges } = createEditableGetters<LocationsState>(
  'editableStatus',
  'editable',
  'autosaveEditable',
);

const {
  isLoading: quickReviewIsLoading,
  isLoaded: quickReviewIsLoaded,
  isFailed: quickReviewIsFailed,
  isFetching: quickReviewIsFetching,
  isSaved: quickReviewIsSaved,
  isSaving: quickReviewIsSaving,
} = createEditableGetters<LocationsState>(
  'quickReviewStatus',
  'quickReviewLatestEditable',
  'quickReviewPublishedEditable',
);

export const moduleGetters: GetterTree<LocationsState, RootState> = {
  /**
   * List
   */
  listIsLoading: (state) => (state.listStatus === ListStatus.Loading ? true : false),
  listIsLoaded: (state) => (state.listStatus === ListStatus.Loaded ? true : false),
  listIsError: (state) => (state.listStatus === ListStatus.Failed ? true : false),
  listIsEmpty: (state) =>
    !(state.locationsList && state.locationsList.length > 0 && state.listStatus !== ListStatus.Loading) ? true : false,

  /**
   * Menu list
   */
  menuListIsLoading: (state) => (state.menuListStatus === ListStatus.Loading ? true : false),
  menuListIsLoaded: (state) => (state.menuListStatus === ListStatus.Loaded ? true : false),
  menuListIsError: (state) => (state.menuListStatus === ListStatus.Failed ? true : false),
  menuListIsEmpty: (state) =>
    !(state.menuLocationsList && state.menuLocationsList.length > 0 && state.menuListStatus !== ListStatus.Loading)
      ? true
      : false,

  /**
   * Pending locations
   */
  pendingListIsLoading: (state) => state.pendingListStatus === ListStatus.Loading,
  pendingListIsLoaded: (state) => state.pendingListStatus === ListStatus.Loaded,
  pendingListIsError: (state) => state.pendingListStatus === ListStatus.Failed,

  /**
   * Published locations
   */
  publishedListIsLoading: (state) => state.publishedListStatus === ListStatus.Loading,
  publishedListIsLoaded: (state) => state.publishedListStatus === ListStatus.Loaded,
  publishedListIsError: (state) => state.publishedListStatus === ListStatus.Failed,

  /**
   * Edit
   */
  editableIsLoading: (state) => (state.editableStatus === EditableStatus.Loading ? true : false),
  editableIsLoaded: (state) => (state.editableStatus === EditableStatus.Loaded ? true : false),
  editableIsSaving: (state) => (state.editableStatus === EditableStatus.Saving ? true : false),
  editableIsSaved: (state) => (state.editableStatus === EditableStatus.Saved ? true : false),
  editableIsError: (state) => (state.editableStatus === EditableStatus.Failed ? true : false),
  editableIsSavingDraft: (state) =>
    state.editableStatus === EditableStatus.Saving && state.editable && state.editable.status === PublishStatus.Draft
      ? true
      : false,
  editableIsApprovingPending: (state) =>
    state.editableStatus === EditableStatus.Saving &&
    state.quickReviewPublishedEditable &&
    state.quickReviewPublishedEditable.status === PublishStatus.Pending &&
    state.editable &&
    state.editable.status === PublishStatus.Published,
  editableIsDecliningPending: (state) =>
    state.editableStatus === EditableStatus.Saving &&
    state.quickReviewPublishedEditable &&
    state.quickReviewPublishedEditable.status === PublishStatus.Pending &&
    state.editable &&
    state.editable.status === PublishStatus.Declined,
  editableIsSavedDraft: (state) =>
    state.editableStatus === EditableStatus.Saved && state.editable && state.editable.status === PublishStatus.Draft
      ? true
      : false,
  editableIsUpdating: (state) =>
    state.editableStatus === EditableStatus.Saving && state.editable && state.editable.status === PublishStatus.Pending
      ? true
      : false,
  editableIsUpdated: (state) =>
    state.editableStatus === EditableStatus.Saved && state.editable && state.editable.status === PublishStatus.Pending
      ? true
      : false,
  editableIsPublishing: (state) =>
    state.editableStatus === EditableStatus.Saving &&
    state.editable &&
    state.editable.status === PublishStatus.Published
      ? true
      : false,
  editableIsPublished: (state) =>
    state.editableStatus === EditableStatus.Saved && state.editable && state.editable.status === PublishStatus.Published
      ? true
      : false,
  editablePracticeId: (state) => (state.editable && state.editable.practiceId ? state.editable.practiceId : 0),
  hasPracticeAssigned: (state, getters) => (getters.editablePracticeId !== 0 ? true : false),
  editableHasUnsavedChanges,
  editableStatusLabel: (state) => {
    let label = '';
    switch (state.editable && state.editable.status) {
      case PublishStatus.Published:
        label = i18next.t('Published');
        break;
      case PublishStatus.Pending:
        label = i18next.t('Review Pending');
        break;
      case PublishStatus.Deleted:
        label = i18next.t('Deleted');

        if (
          state.editable &&
          state.editable.practice &&
          state.editable &&
          state.editable.practice.status === PracticeStatus.Inactive
        ) {
          label = i18next.t('Practice Deleted');
        }

        break;
      case PublishStatus.Declined:
        label = i18next.t('Requires Changes');
        break;
      case PublishStatus.Draft:
      default:
        label = i18next.t('Draft');
        break;
    }
    return label;
  },
  autosaveHasUnsavedChanges,

  /**
   * Quick Review
   */
  quickReviewIsLoading,
  quickReviewIsLoaded,
  quickReviewIsFailed,
  quickReviewIsFetching,
  quickReviewIsSaved,
  quickReviewIsSaving,
  quickReviewIsApproving: (state, getters) =>
    getters.quickReviewIsSaving &&
    state.quickReviewLatestEditable &&
    state.quickReviewLatestEditable.status === PublishStatus.Published,
  quickReviewIsDeclining: (state, getters) =>
    getters.quickReviewIsSaving &&
    state.quickReviewLatestEditable &&
    state.quickReviewLatestEditable.status === PublishStatus.Declined,
};

export default moduleGetters;
