
import Vue from 'vue';
import { mapGetters } from 'vuex';

export default Vue.extend({
  name: 'FAQWidgetBlock',
  computed: {
    ...mapGetters('support', ['faqFeaturedList']),
  },
});
