
import Vue, { PropType, PropOptions } from 'vue';

export default Vue.extend({
  name: 'ViewToggleControl',
  props: {
    value: {
      type: String,
      default: 'grid',
      validator: (value: string) => {
        return ['grid', 'table'].indexOf(value) !== -1;
      },
    },
  },
  computed: {
    isGrid() {
      return this.value === 'grid';
    },
    isTable() {
      return this.value === 'table';
    },
  },
  methods: {
    toggle() {
      const returnValue = this.value === 'grid' ? 'table' : 'grid';
      this.$emit('input', returnValue);
    },
  },
});
