
import Vue from 'vue';
import Practitioner from '@/store/modules/practitioners/practitioner';
import PublishStatusBlock from '@/components/blocks/publish-status.vue';

export default Vue.extend({
  name: 'PractitionerBlock',
  components: {
    PublishStatusBlock,
  },
  props: {
    practitioner: {
      type: Object as () => Practitioner,
    },
  },
});
