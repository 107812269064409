import { JSONModel } from '@/store/models/jsonModel';

export interface BlogAuthorJSON {
  id: number;
  name: string;
  slug: string;
  image: string;
  bio: string;
}

export default class BlogAuthor extends JSONModel<BlogAuthorJSON> {
  public id: number = 0;
  public name: string = '';
  public slug: string = '';
  public image: string = '';
  public bio: string = '';

  public fromJSON(json: BlogAuthorJSON) {
    if (json.id) {
      this.id = Number(json.id);
    }

    if (json.name) {
      this.name = String(json.name);
    }

    if (json.slug) {
      this.slug = String(json.slug);
    }

    if (json.image) {
      this.image = String(json.image);
    }

    if (json.bio) {
      this.bio = String(json.bio);
    }
  }
}
