var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.isAdmin || _vm.isSuperAdmin)?_c('div',[_c('div',{staticClass:"u-flex"},[_c('a',{class:[
        'c-button',
        'c-button--primary-transparent',
        'c-button--small',
        'u-ml-auto',
        { 'c-button--disabled': _vm.disabled },
      ],attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.openModal.apply(null, arguments)}}},[_c('svgicon',{attrs:{"name":"translate"}}),_vm._v(" Translate "),_c('small',[_vm._v(_vm._s(_vm.completedTranslations.length)+"/"+_vm._s(_vm.languages.length))])],1)]),(_vm.isModalOpen)?_c('modal',{attrs:{"isTranslateModal":""},on:{"close":function($event){_vm.isModalOpen = false}}},[_c('template',{slot:"content"},[_c('h3',[_vm._v(_vm._s(_vm.title))]),_c('notification-banner'),_c('div',{staticClass:"c-form-element--side-by-side"},[_c('language-picker',{attrs:{"required":"","name":"source","disabledOptions":[_vm.targetLanguage, ..._vm.notCompletedTranslations]},model:{value:(_vm.sourceLanguage),callback:function ($$v) {_vm.sourceLanguage=$$v},expression:"sourceLanguage"}}),_c('div',{staticStyle:{"width":"3rem"}}),_c('language-picker',{attrs:{"required":"","name":"target","value":_vm.targetLanguage,"disabledOptions":[_vm.sourceLanguage]},on:{"input":_vm.validateAndUpdateTargetLanguage}})],1),_c('form',{attrs:{"data-vv-scope":"translations"}},_vm._l((_vm.languages),function(language){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.targetLanguage === language.code),expression:"targetLanguage === language.code"}],key:language.code},[_vm._t("default",null,null,_vm.getSlotProps(language.code))],2)}),0)],1),_c('template',{slot:"footer"},[_c('div'),_c('submit-button',{attrs:{"disabled":_vm.isSaving,"loading":_vm.isSaving,"failed":_vm.isFailed,"success":_vm.isSaved,"full-width":false},on:{"click":_vm.save}},[_vm._v(" "+_vm._s(_vm.$t('Save Translations'))+" ")])],1)],2):_vm._e()],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }