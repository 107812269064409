/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'book': {
    width: 23.5,
    height: 21.5,
    viewBox: '0 0 23.5 21.5',
    data: '<g data-name="Layer 2"><g data-name="Outline Icons"><path pid="0" d="M11.75 21.5a.75.75 0 01-.75-.75C11 19.78 7.11 18 .75 18a.75.75 0 01-.75-.75V.75A.75.75 0 01.75 0c4.49 0 9.21.93 11 2.67C13.54.93 18.26 0 22.75 0a.75.75 0 01.75.75v16.5a.75.75 0 01-.75.75c-6.36 0-10.25 1.78-10.25 2.75a.75.75 0 01-.75.75zm-10.25-5c4.26.1 8.56 1 10.25 2.66 1.69-1.65 6-2.57 10.25-2.66v-15c-5.91.13-9.5 1.81-9.5 2.74a.75.75 0 01-1.5 0c0-.93-3.59-2.61-9.5-2.74z"/><path pid="1" d="M11.75 21.38a.75.75 0 01-.75-.75V4.25a.75.75 0 011.5 0v16.38a.75.75 0 01-.75.75z"/></g></g>'
  }
})
