import { JSONModel } from '@/store/models/jsonModel';
import UserRelationship, { UserRelationshipJSON } from '@/store/modules/users/user-relationship';
import { createCounter } from '@/store/utils/counter';

const getLocalFileId = createCounter();

export interface ResourceFileJSON {
  id: number;
  asset_id: number;
  asset_type: string;
  display: string;
  fid: number;
  name?: string;
  mime: string;
  uri: string;
  url: string;
  size: number;
  title: string;
  alt: string;
  preview: string;
  created_at: string;
  updated_at: string;
  thumbnail: string;
  user?: UserRelationshipJSON;
  downloads_total_count: number;
  downloads_weekly_count: number;
  trainings_weekly_count: number;
  trainings_total_count: number;
  training_complete: boolean;
  weight: number;
}

export default class ResourceFile extends JSONModel<ResourceFileJSON> {
  public id: number = 0;
  public localId = getLocalFileId();
  public assetId = 0;
  public assetType = 'file';
  public display = '';
  public fid = 0;
  public name = '';
  public mime = '';
  public uri = '';
  public url = '';
  public size = 0;
  public title = '';
  public alt = '';
  public preview = '';
  public createdAt = '';
  public updatedAt = '';
  public thumbnail = '';
  public user?: UserRelationship = undefined;
  public downloadsTotalCount = 0;
  public downloadsWeeklyCount = 0;
  public trainingsWeeklyCount = 0;
  public trainingsTotalCount = 0;
  public trainingComplete = false;
  public weight = 0;

  protected _clientProperties = ['localId'];

  public fromJSON(json: Partial<ResourceFileJSON>) {
    if (json.id) {
      this.id = Number(json.id);
    }

    if (json.asset_id) {
      this.assetId = Number(json.asset_id);
    }

    if (json.asset_type) {
      this.assetType = String(json.asset_type);
    }

    if (json.display) {
      this.display = String(json.display);
    }

    if (json.fid) {
      this.fid = Number(json.fid);
    }

    if (json.name) {
      this.name = String(json.name);
    }

    if (json.mime) {
      this.mime = String(json.mime);
    }

    if (json.uri) {
      this.uri = String(json.uri);
    }

    if (json.url) {
      this.url = String(json.url);
    }

    if (json.size) {
      this.size = Number(json.size);
    }

    if (json.title) {
      this.title = String(json.title);
    }

    if (json.alt) {
      this.alt = String(json.alt);
    }

    if (json.preview) {
      this.preview = String(json.preview);
    }

    if (json.created_at) {
      this.createdAt = String(json.created_at);
    }

    if (json.updated_at) {
      this.updatedAt = String(json.updated_at);
    }

    if (json.thumbnail) {
      this.thumbnail = String(json.thumbnail);
    }

    if (json.user) {
      this.user = UserRelationship.fromJSON(json.user);
    }

    if (json.downloads_total_count) {
      this.downloadsTotalCount = Number(json.downloads_total_count);
    }

    if (json.downloads_weekly_count) {
      this.downloadsWeeklyCount = Number(json.downloads_weekly_count);
    }

    if (json.trainings_total_count) {
      this.trainingsTotalCount = Number(json.trainings_total_count);
    }

    if (json.trainings_weekly_count) {
      this.trainingsWeeklyCount = Number(json.trainings_weekly_count);
    }

    if (json.training_complete) {
      this.trainingComplete = json.training_complete === true ? true : false;
    }

    if (json.weight) {
      this.weight = Number(json.weight);
    }
  }
}
