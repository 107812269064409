/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'camera': {
    width: 24.5,
    height: 14.5,
    viewBox: '0 0 24.5 14.5',
    data: '<g data-name="Layer 2"><g data-name="Outline Icons"><path pid="0" d="M14.75 14.25h-13a1.5 1.5 0 01-1.5-1.5v-11a1.5 1.5 0 011.5-1.5h13a1.5 1.5 0 011.5 1.5v11a1.5 1.5 0 01-1.5 1.5zm-13-13a.5.5 0 00-.5.5v11a.5.5 0 00.5.5h13a.5.5 0 00.5-.5v-11a.5.5 0 00-.5-.5z"/><path pid="1" d="M14.75 14.5h-13A1.75 1.75 0 010 12.75v-11A1.75 1.75 0 011.75 0h13a1.75 1.75 0 011.75 1.75v11a1.75 1.75 0 01-1.75 1.75zm-13-14A1.25 1.25 0 00.5 1.75v11A1.25 1.25 0 001.75 14h13A1.25 1.25 0 0016 12.75v-11A1.25 1.25 0 0014.75.5zm13 13h-13a.75.75 0 01-.75-.75v-11A.75.75 0 011.75 1h13a.75.75 0 01.75.75v11a.75.75 0 01-.75.75zm-13-12a.25.25 0 00-.25.25v11a.25.25 0 00.25.25h13a.25.25 0 00.25-.25v-11a.25.25 0 00-.25-.25zM23.75 13.25a.5.5 0 01-.22-.05l-6-3a.5.5 0 01-.28-.45v-5a.5.5 0 01.28-.45l6-3a.5.5 0 01.72.45v11a.5.5 0 01-.5.5zm-5.5-3.81l5 2.5V2.56l-5 2.5z"/><path pid="2" d="M23.75 13.5a.74.74 0 01-.33-.08l-6-3a.75.75 0 01-.42-.67v-5a.75.75 0 01.41-.67l6-3a.75.75 0 011.09.67v11a.75.75 0 01-.75.75zm0-12h-.11l-6 3a.25.25 0 00-.14.22v5a.25.25 0 00.14.22l6 3a.25.25 0 00.36-.22v-11a.25.25 0 00-.12-.21.25.25 0 00-.13-.01zm-.25 10.85L18 9.6V4.9l5.5-2.75zm-5-3.06l4.5 2.25V3l-4.5 2.21z"/></g></g>'
  }
})
