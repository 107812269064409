import { ListStatus, EditableStatus } from '@/store/types';
import Pagination from '@/store/models/pagination';
import User from '@/store/models/user';

export enum UsersActions {
  /**
   * List
   */
  LOAD_LIST = 'LOAD_LIST',

  /**
   * Edit
   */
  LOAD_EDITABLE = 'LOAD_EDITABLE',
  UPDATE_EDITABLE = 'UPDATE_EDITABLE',
  SAVE_EDITABLE = 'SAVE_EDITABLE',

  /**
   * Actions
   */
  DELETE = 'DELETE',
  RESTORE = 'RESTORE',
  SEND_WELCOME_MAIL = 'SEND_WELCOME_MAIL',

  /**
   * Bulk actions
   */
  BULK_DELETE = 'BULK_DELETE',
  BULK_RESTORE = 'BULK_RESTORE',
}

export enum UsersMutations {
  /**
   * List
   */
  SET_LIST = 'SET_LIST',
  SET_LIST_STATUS = 'SET_LIST_STATUS',
  SET_LIST_PAGINATION = 'SET_LIST_PAGINATION',

  /**
   * Edit
   */
  SET_EDITABLE = 'SET_EDITABLE',
  SET_EDITABLE_STATUS = 'SET_EDITABLE_STATUS',
  UPDATE_EDITABLE = 'UPDATE_EDITABLE',
  SET_SENDING_WELCOME = 'SET_SENDING_WELCOME',
  UPDATE_INITIAL_EDITABLE = 'UPDATE_INITIAL_EDITABLE',
}

export interface UsersState {
  /**
   * List
   */
  listStatus: ListStatus;
  listPagination: Pagination;
  list: User[];

  /**
   * Edit
   */
  editable?: User;
  initialEditable?: User;
  editableStatus: EditableStatus;
  sendingWelcome: null | boolean;
}
