/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'user': {
    width: 20,
    height: 21.5,
    viewBox: '0 0 20 21.5',
    data: '<g data-name="Layer 2"><g data-name="Outline Icons"><path pid="0" d="M19.5 21.5H.5A.5.5 0 010 21v-1.89a3.51 3.51 0 012.32-3.3L7 14.15V11.7h1v2.8a.5.5 0 01-.33.47l-5 1.79A2.51 2.51 0 001 19.11v1.39h18v-1.39a2.51 2.51 0 00-1.66-2.35l-5-1.79a.5.5 0 01-.34-.47v-2.8h1v2.45l4.68 1.67A3.51 3.51 0 0120 19.11V21a.5.5 0 01-.5.5z"/><path pid="1" d="M10 13c-3 0-5.5-2.92-5.5-6.5S7 0 10 0s5.5 2.92 5.5 6.5S13 13 10 13zm0-12C7.52 1 5.5 3.47 5.5 6.5S7.52 12 10 12s4.5-2.47 4.5-5.5S12.48 1 10 1z"/><path pid="2" d="M13.67 6.59A3.44 3.44 0 0110.73 5 5.49 5.49 0 017 6.5 4.5 4.5 0 014.83 6l.46-.89A3.49 3.49 0 007 5.5a4.13 4.13 0 003.32-1.66.5.5 0 01.43-.27.48.48 0 01.44.25c.85 1.49 1.75 2 3.21 1.69h.48l.12 1h-.42a4.91 4.91 0 01-.91.08z"/></g></g>'
  }
})
