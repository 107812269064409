/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'out': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M13.25 11.5a.74.74 0 01-.53-.22.75.75 0 010-1.06l10-10a.75.75 0 011.06 1.06l-10 10a.74.74 0 01-.53.22z"/><path pid="1" d="M23.25 11.5a.76.76 0 01-.75-.75V1.5h-9.25a.76.76 0 01-.75-.75.76.76 0 01.75-.75h10a.76.76 0 01.75.75v10a.76.76 0 01-.75.75zM18.5 11.5v11h-17v-17h11V4H0v20h20V11.5h-1.5z"/>'
  }
})
