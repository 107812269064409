import Image, { ImageJSON } from '@/store/models/image';
import Taxonomy, { TaxonomyJSON } from '@/store/models/taxonomy';
import { JSONModel } from '@/store/models/jsonModel';
import Country, { CountryJSON } from '@/store/models/country';

export enum ProductStatus {
  Inactive = 0,
  Active = 1,
}

export enum ProductPriority {
  Normal = 0,
  Featured = 1,
}

export interface ProductJSON {
  id: number;
  status: number;
  title?: string;
  description?: string;
  sculpsure: number;
  image?: ImageJSON | ImageJSON[];
  accessories?: TaxonomyJSON[];
  treatments?: TaxonomyJSON[];
  treatments_count: number;
  assets_count: number;
  owned?: boolean;
  priority?: number;
  downloads_total_count: number;
  downloads_weekly_count: number;
  countries: CountryJSON[];
  translations: {
    title: Record<string, string>;
    description: Record<string, string>;
  };
}

const availableStatuses = Object.keys(ProductStatus).map((key) => ProductStatus[key]);

export default class Product extends JSONModel<ProductJSON> {
  public id: number = 0;
  public status: ProductStatus = ProductStatus.Inactive;
  public title: string = '';
  public description: string = '';
  public sculpsure: number = 0;
  public image?: Image;
  public accessories: Taxonomy[] = [];
  public treatments: Taxonomy[] = [];
  public treatmentsCount: number = 0;
  public assetsCount: number = 0;
  public owned: boolean = true;
  public priority: ProductPriority = ProductPriority.Normal;
  public downloadsTotalCount: number = 0;
  public downloadsWeeklyCount: number = 0;
  public countries: Country[] = [];
  public translations: {
    title: Record<string, string>;
    description: Record<string, string>;
  } = { title: {}, description: {} };
  protected _clientProperties = ['translations'];

  public fromJSON(json: Partial<ProductJSON>) {
    this.id = Number(json.id);

    if (typeof json.status !== 'undefined' && availableStatuses.indexOf(json.status) !== -1) {
      this.status = json.status;
    }

    if (json.title) {
      this.title = String(json.title);
    }

    if (json.description) {
      this.description = String(json.description);
    }

    if (json.sculpsure) {
      this.sculpsure = Number(json.sculpsure);
    }

    if (json.image) {
      const image = Array.isArray(json.image) ? json.image[0] : json.image;

      if (image) {
        this.image = Image.fromJSON(image);
      }
    }

    if (json.accessories) {
      this.accessories = json.accessories.map((item) => Taxonomy.fromJSON(item));
    }

    if (json.treatments) {
      this.treatments = json.treatments.map((item) => Taxonomy.fromJSON(item));
    }

    if (json.treatments_count) {
      this.treatmentsCount = Number(json.treatments_count);
    }

    if (json.assets_count) {
      this.assetsCount = Number(json.assets_count);
    }

    if (typeof json.owned !== 'undefined' && !Boolean(json.owned)) {
      this.owned = false;
    }

    if (json.priority && json.priority === ProductPriority.Featured) {
      this.priority = ProductPriority.Featured;
    }

    if (json.downloads_total_count) {
      this.downloadsTotalCount = Number(json.downloads_total_count);
    }

    if (json.downloads_weekly_count) {
      this.downloadsWeeklyCount = Number(json.downloads_weekly_count);
    }

    if (json.countries) {
      this.countries = json.countries.map((item) => Country.fromJSON(item));
    }

    if (json.translations) {
      this.translations = json.translations;
    }

    return this;
  }
}
