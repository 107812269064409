/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'social/realself': {
    width: 523.3,
    height: 280.71,
    viewBox: '0 0 523.3 280.71',
    data: '<g data-name="Layer 2"><path pid="0" d="M300.38 280.69c-37.43 0-76.59-12.94-107.68-35.53l33.94-50.06c18.47 16.24 46.8 26.29 74.31 26.29 20.81 0 35.93-10.16 35.93-24.15 0-17.44-22.41-25.13-48.34-34-37.14-12.75-83.36-28.63-83.36-79.72 0-30.66 17.12-50.06 31.48-60.94C255.54 8.23 281.63 0 308.23 0c36 0 73.07 13.57 90.36 26.57l-33 50.76c-10-7.05-29.25-18-51.81-18-22.84 0-38.16 9.24-38.16 23 0 14.25 19.13 20.59 43.37 28.61 39.36 13 88.33 29.29 88.33 86.83 0 24.34-11.12 45.3-32.16 60.64-19.71 14.36-46.29 22.27-74.85 22.27z" _fill="#0e0e0e"/><path pid="1" d="M475.13 280.69a47.08 47.08 0 01-47.57-46.59v-1c0-27 20.91-48.15 47.59-48.15A47.62 47.62 0 01523.29 232v1.06c0 26.7-21.15 47.61-48.15 47.61"/><path pid="2" d="M0 275.1h70.54V5.61H0zM138 95.74a47.08 47.08 0 01-47.59-46.57v-1C90.41 21.15 111.32 0 138 0a47.63 47.63 0 0148.15 47.1v1.06c0 26.7-21.15 47.59-48.15 47.59" _fill="#0e0e0e"/></g>'
  }
})
