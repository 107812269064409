import moment, { Moment } from 'moment';
import { formatTime } from '@/i18n';

export interface TimeChoice {
  value: string;
  label: string;
}

// TODO: rewrite to remove moment dependency
export const genTimeChoices = (
  stepMinutes: number = 30,
  start: Moment = moment('00:00:00', 'HH:mm:ss'),
  end: Moment = moment('00:00:00', 'HH:mm:ss').add(1, 'day'),
): TimeChoice[] => {
  const out: TimeChoice[] = [];

  while (start.isBefore(end)) {
    const time = start.format('HH:mm:ss');

    out.push({
      value: time,
      label: formatTime(time),
    });

    start.add(stepMinutes, 'minutes');
  }

  return out;
};

// TODO: rewrite to remove moment dependency
export const genWeekDays = (): string[] => {
  return [0, 1, 2, 3, 4, 5, 6].map((d) =>
    moment()
      .isoWeekday(d + 1)
      .format('ddd'),
  );
};
