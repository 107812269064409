/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'restore': {
    width: 24.6,
    height: 20.7,
    viewBox: '0 0 24.6 20.7',
    data: '<g data-name="Layer 2"><g data-name="Outline Icons"><path pid="0" d="M4.77 13.7h-.05a.85.85 0 01-.65-.38L.14 7.44a.85.85 0 011.41-.94l3.31 5 3.85-4.4A.85.85 0 0110 8.18l-4.59 5.23a.85.85 0 01-.64.29z"/><path pid="1" d="M14.25 20.7a.85.85 0 010-1.7 8.65 8.65 0 10-8.65-8.65v1.49a.85.85 0 11-1.7 0v-1.49A10.35 10.35 0 1114.25 20.7z"/></g></g>'
  }
})
