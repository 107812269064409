
import Vue from 'vue';
import { mapGetters, mapActions } from 'vuex';

import SubmitButton from '@/components/controls/submit-button.vue';
import TreatmentSelectionControl from '@/components/controls/treatment-selection.vue';
import Dropdown, { DropdownItems } from '@/components/controls/dropdown.vue';
import { ProductStatus } from '@/store/modules/products/product';
import { ReportsActions } from '@/store/modules/reports/types';
import { ProductsActions } from '@/store/modules/products/types';

export default Vue.extend({
  name: 'ProductsFilterForm',
  components: {
    SubmitButton,
    TreatmentSelectionControl,
    Dropdown,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    reports: {
      type: Boolean,
      default: false,
    },
    selectedItems: {
      type: Array,
      default: () => [] as number[],
    },
    selectedAccessories: {
      type: Array,
      default: () => [] as number[],
    },
  },
  data() {
    return {
      availableStatuses: {
        [ProductStatus.Inactive]: this.$t('Inactive'),
        [ProductStatus.Active]: this.$t('Active'),
      },
      search: '',
      status: '',
      treatment: undefined,
    };
  },
  computed: {
    ...mapGetters('user/profile', ['isSuperAdmin']),
    dropdownItems() {
      const items: DropdownItems = {};

      if (this.reports) {
        items.report = {
          label: this.$t('Generate Report'),
          icon: 'document',
        };
      }

      items.reset = {
        label: this.$t('Reset Filters'),
        icon: 'close',
      };

      if ((this.isSuperAdmin || this.isAdmin) && this.selectedItems && this.selectedItems.length) {
        items.restore = {
          hasTopBorder: true,
          label: this.$t('Restore'),
          count: this.selectedItems.length,
          disabled: !this.selectedItems.length,
        };

        items.remove = {
          label: this.$t('Delete'),
          count: this.selectedItems.length,
          disabled: !this.selectedItems.length,
        };
      }

      if ((this.isSuperAdmin || this.isAdmin) && this.selectedAccessories && this.selectedAccessories.length) {
        items.restore_accessories = {
          hasTopBorder: true,
          label: this.$t('Restore'),
          count: this.selectedAccessories.length,
          disabled: !this.selectedAccessories.length,
        };

        items.remove_accessories = {
          label: this.$t('Delete'),
          count: this.selectedAccessories.length,
          disabled: !this.selectedAccessories.length,
        };
      }

      return items;
    },
  },
  methods: {
    ...mapActions('reports', {
      requestReport: ReportsActions.REQUEST,
    }),
    ...mapActions('products', {
      deleteProducts: ProductsActions.BULK_DELETE,
      restoreProducts: ProductsActions.BULK_RESTORE,
      deleteAccessories: ProductsActions.BULK_DELETE_ACCESSORY,
      restoreAccessories: ProductsActions.BULK_RESTORE_ACCESSORY,
    }),
    initFilters(): void {
      this.search = this.$router.currentRoute.query.search || '';
      this.status = this.$router.currentRoute.query.status || '';

      let newTreatmentValue;

      if (this.$router.currentRoute.query.treatment_id) {
        newTreatmentValue = Number(this.$router.currentRoute.query.treatment_id);

        if (!newTreatmentValue || Number.isNaN(newTreatmentValue)) {
          newTreatmentValue = undefined;
        }
      }

      if (this.treatment !== newTreatmentValue) {
        this.treatment = newTreatmentValue;
      }
    },
    applyFilters(): void {
      const { sort, direction, ...queryParams } = this.$route.query;

      this.$router.push({
        params: {
          page: 1,
        },
        query: {
          ...queryParams,
          search: this.search,
          status: this.status,
          treatment_id: this.treatment,
        },
      });
    },
    handleDropdownClick(action: string): void {
      if ('reset' === action) {
        this.$router.push({
          params: {
            page: 1,
          },
          query: {},
        });
      }

      if ('remove' === action) {
        this.deleteProducts({ ids: this.selectedItems });
      }

      if ('restore' === action) {
        this.restoreProducts({ ids: this.selectedItems });
      }

      if ('remove_accessories' === action) {
        this.deleteAccessories({ ids: this.selectedAccessories });
      }

      if ('restore_accessories' === action) {
        this.restoreAccessories({ ids: this.selectedAccessories });
      }

      if ('report' === action) {
        this.requestReport();
      }
    },
  },
  created(): void {
    this.initFilters();
  },
  watch: {
    $route(): void {
      this.initFilters();
    },
  },
});
