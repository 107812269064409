import { ActionTree } from 'vuex';
import { RootState } from '@/store/types';
import {
  NotificationsState,
  NotificationsActions,
  NotificationType,
  NotificationsMutations,
} from '@/store/modules/notifications/types';
import { Snackbar } from './snackbar';

export const actions: ActionTree<NotificationsState, RootState> = {
  async [NotificationsActions.SHOW_NOTIFICATION]({ commit }, { message, type }) {
    commit(NotificationsMutations.SET_NOTIFICATION, { message, type });
    commit(NotificationsMutations.SET_VISIBILITY, true);
  },
  async [NotificationsActions.SHOW_ERROR]({ dispatch }, message) {
    dispatch(NotificationsActions.SHOW_NOTIFICATION, {
      message,
      type: NotificationType.Error,
    });
  },
  async [NotificationsActions.SHOW_WARNING]({ dispatch }, message) {
    dispatch(NotificationsActions.SHOW_NOTIFICATION, {
      message,
      type: NotificationType.Warning,
    });
  },
  async [NotificationsActions.SHOW_NOTICE]({ dispatch }, message) {
    dispatch(NotificationsActions.SHOW_NOTIFICATION, {
      message,
      type: NotificationType.Notice,
    });
  },
  async [NotificationsActions.SHOW_SUCCESS]({ dispatch }, message) {
    dispatch(NotificationsActions.SHOW_NOTIFICATION, {
      message,
      type: NotificationType.Success,
    });
  },
  async [NotificationsActions.HIDE_NOTIFICATION]({ commit }) {
    commit(NotificationsMutations.SET_VISIBILITY, false);
  },
  async [NotificationsActions.ADD_SNACKBAR]({ commit }, snackbar: Snackbar) {
    commit(NotificationsMutations.ADD_SNACKBAR, snackbar);
  },
  async [NotificationsActions.REMOVE_SNACKBAR]({ commit }, snackbar: Snackbar) {
    commit(NotificationsMutations.REMOVE_SNACKBAR, snackbar);
  },
};

export default actions;
