
import Vue from 'vue';
import { mapActions, mapState } from 'vuex';
import mixins from 'vue-typed-mixins';

import api from '@/api';

import i18n from '@/components/i18n';
import { imageSizeMixin } from '@/components/mixins/image-size';
import ModalWithSidebar from '@/components/layout/modal-with-sidebar.vue';
import Modal from '@/components/layout/modal.vue';
import WistiaVideo from '@/components/layout/wistia-video.vue';
import SelectInput from '@/components/controls/select-input.vue';
import FormInput from '@/components/controls/form-input.vue';
import CheckboxInput from '@/components/controls/checkbox.vue';
import SubmitButton from '@/components/controls/submit-button.vue';
import ImageUploadControl from '@/components/controls/image-upload.vue';
import { EventTypes, RootActions } from '@/store/types';

enum devices {
  'Potenza' = 'Potenza',
  'Icon (Skin)' = 'Icon (Skin)',
  'Icon (Hair)' = 'Icon (Hair)',
  'Elite iQ (Skin)' = 'Elite iQ (Skin)',
  'Elite iQ (Hair)' = 'Elite iQ (Hair)',
  'FlexSure' = 'FlexSure',
  'SculpSure' = 'SculpSure',
  'Picosure (Skin)' = 'Picosure (Skin)',
  'Picosure (Tattoo Removal)' = 'Picosure (Tattoo Removal)',
  'Picosure Pro (Skin)' = 'Picosure Pro (Skin)',
  'Picosure Pro (Tattoo Removal)' = 'Picosure Pro (Tattoo Removal)',
  'TempSure Envi' = 'TempSure Envi',
  'TempSure (Firm)' = 'TempSure (Firm)',
  'ViaSure' = 'ViaSure',
  'MyEllevate' = 'MyEllevate',
}

enum assetTypes {
  'Seasonal Promotion (Flyer)' = 'Seasonal Promotion (Flyer)',
  'Email Template' = 'Email Template',
  'Takeaway Card' = 'Takeaway Card',
  'Social Post' = 'Social Post',
  'Poster' = 'Poster',
  'Event Flyer' = 'Event Flyer',
  'Gift Certificate' = 'Gift Certificate',
  'Referral Card' = 'Referral Card',
  'Banner Stand' = 'Banner Stand',
  'Brochure' = 'Brochure',
}

const headlines = {
  [devices['Picosure (Tattoo Removal)']]: {
    [assetTypes['Seasonal Promotion (Flyer)']]: [
      'Get your confidence back',
      'You deserve a fresh start',
      'Nothing\'s better than a clean canvas',
      'You don\'t have to live with regrets',
      'Coming Soon',
      'Now Offering​',
    ],
    [assetTypes['Email Template']]: [
      'Get your confidence back',
      'You deserve a fresh start',
      'Nothing\'s better than a clean canvas',
      'You don\'t have to live with regrets',
      'Coming Soon',
      'Now Offering​',
    ],
    [assetTypes['Takeaway Card']]: [
      'Get your confidence back',
      'You deserve a fresh start',
      'Nothing\'s better than a clean canvas',
      'You don\'t have to live with regrets',
      'Coming Soon',
      'Now Offering​',
    ],
    [assetTypes['Social Post']]: [
      'Get your confidence back',
      'You deserve a fresh start',
      'Nothing\'s better than a clean canvas',
      'You don\'t have to live with regrets',
      'Coming Soon',
      'Now Offering​',
    ],
    [assetTypes.Poster]: [
      'Get your confidence back',
      'You deserve a fresh start',
      'Nothing\'s better than a clean canvas',
      'You don\'t have to live with regrets',
      'Coming Soon',
      'Now Offering​',
    ],
    [assetTypes['Event Flyer']]: [
      'Open House Event',
      'Launch Event',
      'Join us for our Open House Event',
      'Join us for our Launch Event',
      'VIP Consult Day',
    ],
    [assetTypes['Gift Certificate']]: [
      'Out of sight, Out of mind',
      'Undo Your Tattoo',
      'Start with a Clean Slate',
      'Don’t Just Cover Up',
      'No headline',
    ],
  },
  [devices['Picosure (Skin)']]: {
    [assetTypes['Seasonal Promotion (Flyer)']]: [
      'Revitalize your skin, revive your confidence',
      'Refresh and renew, discover your best skin yet',
      'Brighter, healthier looking skin',
      'Illuminate your complexion',
      'Coming Soon',
      'Now Offering',
    ],
    [assetTypes['Email Template']]: [
      'Revitalize your skin, revive your confidence',
      'Refresh and renew, discover your best skin yet',
      'Brighter, healthier looking skin',
      'Illuminate your complexion',
      'Coming Soon',
      'Now Offering',
    ],
    [assetTypes['Takeaway Card']]: [
      'Revitalize your skin, revive your confidence',
      'Refresh and renew, discover your best skin yet',
      'Brighter, healthier looking skin',
      'Illuminate your complexion',
      'Coming Soon',
      'Now Offering',
    ],
    [assetTypes['Social Post']]: [
      'Revitalize your skin, revive your confidence',
      'Refresh and renew, discover your best skin yet',
      'Brighter, healthier looking skin',
      'Illuminate your complexion',
      'Coming Soon',
      'Now Offering',
    ],
    [assetTypes.Poster]: [
      'Revitalize your skin, revive your confidence',
      'Refresh and renew, discover your best skin yet',
      'Brighter, healthier looking skin',
      'Illuminate your complexion',
      'Coming Soon',
      'Now Offering',
    ],
    [assetTypes['Event Flyer']]: [
      'Open House Event',
      'Launch Event',
      'Join us for our Open House Event',
      'Join us for our Launch Event',
      'VIP Consult Day',
    ],
    [assetTypes['Gift Certificate']]: [
      'Feel Natural, Be Natural',
      'Glow Like Never Before',
      'Your Skin Deserves the Best',
      'Fall Back in Love with Your Skin',
      'No headline',
    ],
  },
  [devices['Picosure Pro (Tattoo Removal)']]: {
    [assetTypes['Seasonal Promotion (Flyer)']]: [
      'Get your confidence back',
      'You deserve a fresh start',
      'Nothing\'s better than a clean canvas',
      'You don\'t have to live with regrets',
      'Coming Soon',
      'Now Offering​',
    ],
    [assetTypes['Email Template']]: [
      'Get your confidence back',
      'You deserve a fresh start',
      'Nothing\'s better than a clean canvas',
      'You don\'t have to live with regrets',
      'Coming Soon',
      'Now Offering​',
    ],
    [assetTypes['Takeaway Card']]: [
      'Get your confidence back',
      'You deserve a fresh start',
      'Nothing\'s better than a clean canvas',
      'You don\'t have to live with regrets',
      'Coming Soon',
      'Now Offering​',
    ],
    [assetTypes['Social Post']]: [
      'Get your confidence back',
      'You deserve a fresh start',
      'Nothing\'s better than a clean canvas',
      'You don\'t have to live with regrets',
      'Coming Soon',
      'Now Offering​',
    ],
    [assetTypes.Poster]: [
      'Get your confidence back',
      'You deserve a fresh start',
      'Nothing\'s better than a clean canvas',
      'You don\'t have to live with regrets',
      'Coming Soon',
      'Now Offering​',
    ],
    [assetTypes['Event Flyer']]: [
      'Open House Event',
      'Launch Event',
      'Join us for our Open House Event',
      'Join us for our Launch Event',
      'VIP Consult Day',
    ],
    [assetTypes['Gift Certificate']]: [
      'Out of sight, Out of mind',
      'Undo Your Tattoo',
      'Start with a Clean Slate',
      'Don’t Just Cover Up',
      'No headline',
    ],
    [assetTypes['Referral Card']]: [
      'Out of sight, Out of mind',
      'Undo Your Tattoo',
      'Start with a Clean Slate',
      'Don’t Just Cover Up',
      'No headline',
    ],
  },
  [devices['Picosure Pro (Skin)']]: {
    [assetTypes['Seasonal Promotion (Flyer)']]: [
      'Revitalize your skin, revive your confidence',
      'Refresh and renew, discover your best skin yet',
      'Brighter, healthier looking skin',
      'Illuminate your complexion',
      'Coming Soon',
      'Now Offering',
    ],
    [assetTypes['Email Template']]: [
      'Revitalize your skin, revive your confidence',
      'Refresh and renew, discover your best skin yet',
      'Brighter, healthier looking skin',
      'Illuminate your complexion',
      'Coming Soon',
      'Now Offering',
    ],
    [assetTypes['Takeaway Card']]: [
      'Revitalize your skin, revive your confidence',
      'Refresh and renew, discover your best skin yet',
      'Brighter, healthier looking skin',
      'Illuminate your complexion',
      'Coming Soon',
      'Now Offering',
    ],
    [assetTypes['Social Post']]: [
      'Revitalize your skin, revive your confidence',
      'Refresh and renew, discover your best skin yet',
      'Brighter, healthier looking skin',
      'Illuminate your complexion',
      'Coming Soon',
      'Now Offering',
    ],
    [assetTypes.Poster]: [
      'Revitalize your skin, revive your confidence',
      'Refresh and renew, discover your best skin yet',
      'Brighter, healthier looking skin',
      'Illuminate your complexion',
      'Coming Soon',
      'Now Offering',
    ],
    [assetTypes['Event Flyer']]: [
      'Open House Event',
      'Launch Event',
      'Join us for our Open House Event',
      'Join us for our Launch Event',
      'VIP Consult Day',
    ],
    [assetTypes['Gift Certificate']]: [
      'Feel Natural, Be Natural',
      'Glow Like Never Before',
      'Your Skin Deserves the Best',
      'Fall Back in Love with Your Skin',
      'No headline',
    ],
    [assetTypes['Referral Card']]: [
      'Feel Natural, Be Natural',
      'Glow Like Never Before',
      'Your Skin Deserves the Best',
      'Fall Back in Love with Your Skin',
      'No headline',
    ],
  },
  [devices.MyEllevate]: {
    [assetTypes['Seasonal Promotion (Flyer)']]: [
      'The Non-Invasive Lift for a Redefined You!',
      'Contour Your Jawline, Revitalize Your Confidence',
      'Your Non-Surgical Solution to a Stunning Neckline',
      'Lift and Define Without the Downtime',
      'Coming Soon',
      'Now Offering',
    ],
    [assetTypes['Email Template']]: [
      'The Non-Invasive Lift for a Redefined You!',
      'Contour Your Jawline, Revitalize Your Confidence',
      'Your Non-Surgical Solution to a Stunning Neckline',
      'Lift and Define Without the Downtime',
      'Coming Soon',
      'Now Offering',
    ],
    [assetTypes['Takeaway Card']]: [
      'The Non-Invasive Lift for a Redefined You!',
      'Contour Your Jawline, Revitalize Your Confidence',
      'Your Non-Surgical Solution to a Stunning Neckline',
      'Lift and Define Without the Downtime',
      'Coming Soon',
      'Now Offering',
    ],
    [assetTypes['Social Post']]: [
      'The Non-Invasive Lift for a Redefined You!',
      'Contour Your Jawline, Revitalize Your Confidence',
      'Your Non-Surgical Solution to a Stunning Neckline',
      'Lift and Define Without the Downtime',
      'Coming Soon',
      'Now Offering',
    ],
    [assetTypes.Poster]: [
      'The Non-Invasive Lift for a Redefined You!',
      'Contour Your Jawline, Revitalize Your Confidence',
      'Your Non-Surgical Solution to a Stunning Neckline',
      'Lift and Define Without the Downtime',
      'Coming Soon',
      'Now Offering',
    ],
    [assetTypes['Event Flyer']]: [
      'Open House Event',
      'Launch Event',
      'Join us for our Open House Event',
      'Join us for our Launch Event',
      'VIP Consult Day',
    ],
    [assetTypes['Gift Certificate']]: [
      'New You In No Time',
      'Elevate Your Profile',
      'I Came. I Saw. I Contoured.',
      'Define And Shine',
      'No headline',
    ],
    [assetTypes['Referral Card']]: [
      'New You In No Time',
      'Elevate Your Profile',
      'I Came. I Saw. I Contoured.',
      'Define And Shine',
      'No headline',
    ],
  },
  [devices.ViaSure]: {
    [assetTypes['Seasonal Promotion (Flyer)']]: [
      'Get your confidence back',
      'Rise above and rediscover your intimacy',
      'Unlock your potential',
      'Ignite your passion',
      'Coming Soon',
      'Now Offering',
    ],
    [assetTypes['Email Template']]: [
      'Get your confidence back',
      'Rise above and rediscover your intimacy',
      'Unlock your potential',
      'Ignite your passion',
      'Coming Soon',
      'Now Offering',
    ],
    [assetTypes['Takeaway Card']]: [
      'Get your confidence back',
      'Rise above and rediscover your intimacy',
      'Unlock your potential',
      'Ignite your passion',
      'Coming Soon',
      'Now Offering',
    ],
    [assetTypes['Social Post']]: [
      'Get your confidence back',
      'Rise above and rediscover your intimacy',
      'Unlock your potential',
      'Ignite your passion',
      'Coming Soon',
      'Now Offering',
    ],
    [assetTypes.Poster]: [
      'Get your confidence back',
      'Rise above and rediscover your intimacy',
      'Unlock your potential',
      'Ignite your passion',
      'Coming Soon',
      'Now Offering',
    ],
    [assetTypes['Event Flyer']]: [
      'Open House Event',
      'Launch Event',
      'Join us for our Open House Event',
      'Join us for our Launch Event',
      'VIP Consult Day',
    ],
    [assetTypes['Gift Certificate']]: [
      'Gentlemen, Start Your Engines',
      'Be Up For Any Challenge',
      'Your Time To Shine',
      'Get Back In The Game',
      'No headline',
    ],
    [assetTypes['Referral Card']]: [
      'Gentlemen, Start Your Engines',
      'Be Up For Any Challenge',
      'Your Time To Shine',
      'Get Back In The Game',
      'No headline',
    ],
  },
  [devices.Potenza]: {
    [assetTypes['Seasonal Promotion (Flyer)']]: [
      'Rediscover your skin\'s full potential',
      'RF microneedling harnesses the power of heat and precision for a revitalized glow',
      'Experience the skin-transforming benefits of our advanced RF microneedling solution',
      'Get the GLOW you\'ve always wanted!',
      'Coming Soon',
      'Now Offering',
    ],
    [assetTypes['Email Template']]: [
      'Rediscover your skin\'s full potential',
      'RF microneedling harnesses the power of heat and precision for a revitalized glow',
      'Experience the skin-transforming benefits of our advanced RF microneedling solution',
      'Get the GLOW you\'ve always wanted!',
      'Coming Soon',
      'Now Offering',
    ],
    [assetTypes['Takeaway Card']]: [
      'Rediscover your skin\'s full potential',
      'RF microneedling harnesses the power of heat and precision for a revitalized glow',
      'Experience the skin-transforming benefits of our advanced RF microneedling solution',
      'Get the GLOW you\'ve always wanted!',
      'Coming Soon',
      'Now Offering',
    ],
    [assetTypes['Social Post']]: [
      'Rediscover your skin\'s full potential',
      'RF microneedling harnesses the power of heat and precision for a revitalized glow',
      'Experience the skin-transforming benefits of our advanced RF microneedling solution',
      'Get the GLOW you\'ve always wanted!',
      'Coming Soon',
      'Now Offering',
    ],
    [assetTypes.Poster]: [
      'Rediscover your skin\'s full potential',
      'RF microneedling harnesses the power of heat and precision for a revitalized glow',
      'Experience the skin-transforming benefits of our advanced RF microneedling solution',
      'Get the GLOW you\'ve always wanted!',
      'Coming Soon',
      'Now Offering',
    ],
    [assetTypes['Event Flyer']]: [
      'Open House Event',
      'Launch Event',
      'Join us for our Open House Event',
      'Join us for our Launch Event',
      'VIP Consult Day',
    ],
    [assetTypes['Gift Certificate']]: [
      'Dare to Bare',
      'Beautiful Skin Starts Here',
      'Your Best Kept Secret',
      'Lunch Time Facelift',
      'No headline',
    ],
    [assetTypes['Referral Card']]: ['Exclusive Friends and Family Offer', 'Exclusive Offer', 'No headline'],
  },
  [devices['TempSure Envi']]: {
    [assetTypes['Seasonal Promotion (Flyer)']]: [
      'Glow On: Elevate your skincare routine',
      'Radiance Revealed: Discover Your Best Skin Ever',
      'Refresh and Renew: Your perfect skin is waiting',
      'Flawless Skin Starts Here',
      'Coming Soon',
      'Now Offering',
    ],
    [assetTypes['Email Template']]: [
      'Glow On: Elevate your skincare routine',
      'Radiance Revealed: Discover Your Best Skin Ever',
      'Refresh and Renew: Your perfect skin is waiting',
      'Flawless Skin Starts Here',
      'Coming Soon',
      'Now Offering',
    ],
    [assetTypes['Takeaway Card']]: [
      'Glow On: Elevate your skincare routine',
      'Radiance Revealed: Discover Your Best Skin Ever',
      'Refresh and Renew: Your perfect skin is waiting',
      'Flawless Skin Starts Here',
      'Coming Soon',
      'Now Offering',
    ],
    [assetTypes['Social Post']]: [
      'Glow On: Elevate your skincare routine',
      'Radiance Revealed: Discover Your Best Skin Ever',
      'Refresh and Renew: Your perfect skin is waiting',
      'Flawless Skin Starts Here',
      'Coming Soon',
      'Now Offering',
    ],
    [assetTypes.Poster]: [
      'Glow On: Elevate your skincare routine',
      'Radiance Revealed: Discover Your Best Skin Ever',
      'Refresh and Renew: Your perfect skin is waiting',
      'Flawless Skin Starts Here',
      'Coming Soon',
      'Now Offering',
    ],
    [assetTypes['Event Flyer']]: [
      'Open House Event',
      'Launch Event',
      'Join us for our Open House Event',
      'Join us for our Launch Event',
      'VIP Consult Day',
    ],
    [assetTypes['Gift Certificate']]: [
      'Dare to Bare',
      'Beautiful Skin Starts Here',
      'Your Best Kept Secret',
      'Lunch Time Facelift',
      'No headline',
    ],
    [assetTypes['Referral Card']]: ['Exclusive Friends and Family Offer', 'Exclusive Offer', 'No headline'],
  },
  [devices['Icon (Skin)']]: {
    [assetTypes['Seasonal Promotion (Flyer)']]: [
      'Revitalize your skin, renew your confidence',
      'Revive your skin\'s youthful essence: Effortlessly achieve a luminous, revitalized glow',
      'Rejuvenate and restore with our transformative solution',
      'Rediscover your complexion\'s prime: Our skin revitalization treatment turns back the clock',
      'Coming Soon',
      'Now Offering',
    ],
    [assetTypes['Email Template']]: [
      'Revitalize your skin, renew your confidence',
      'Revive your skin\'s youthful essence: Effortlessly achieve a luminous, revitalized glow',
      'Rejuvenate and restore with our transformative solution',
      'Rediscover your complexion\'s prime: Our skin revitalization treatment turns back the clock',
      'Coming Soon',
      'Now Offering',
    ],
    [assetTypes['Takeaway Card']]: [
      'Revitalize your skin, renew your confidence',
      'Revive your skin\'s youthful essence: Effortlessly achieve a luminous, revitalized glow',
      'Rejuvenate and restore with our transformative solution',
      'Rediscover your complexion\'s prime: Our skin revitalization treatment turns back the clock',
      'Coming Soon',
      'Now Offering',
    ],
    [assetTypes['Social Post']]: [
      'Revitalize your skin, renew your confidence',
      'Revive your skin\'s youthful essence: Effortlessly achieve a luminous, revitalized glow',
      'Rejuvenate and restore with our transformative solution',
      'Rediscover your complexion\'s prime: Our skin revitalization treatment turns back the clock',
      'Coming Soon',
      'Now Offering',
    ],
    [assetTypes.Poster]: [
      'Revitalize your skin, renew your confidence',
      'Revive your skin\'s youthful essence: Effortlessly achieve a luminous, revitalized glow',
      'Rejuvenate and restore with our transformative solution',
      'Rediscover your complexion\'s prime: Our skin revitalization treatment turns back the clock',
      'Coming Soon',
      'Now Offering',
    ],
    [assetTypes['Event Flyer']]: [
      'Open House Event',
      'Launch Event',
      'Join us for our Open House Event',
      'Join us for our Launch Event',
      'VIP Consult Day',
    ],
    [assetTypes['Gift Certificate']]: [
      'Feel Natural, Be Natural',
      'Glow Like Never Before',
      'Your Skin Deserves the Best',
      'Fall Back in Love with Your Skin',
      'No headline',
    ],
    [assetTypes['Referral Card']]: ['Exclusive Friends and Family Offer', 'Exclusive Offer', 'No headline'],
  },
  [devices['Elite iQ (Skin)']]: {
    [assetTypes['Seasonal Promotion (Flyer)']]: [
      'Revitalize Your Skin, Revive Your Confidence',
      'Refresh and renew, discover your best skin yet',
      'Brighter, healthier looking skin',
      'Illuminate your complexion',
      'Coming Soon',
      'Now Offering',
    ],
    [assetTypes['Email Template']]: [
      'Revitalize Your Skin, Revive Your Confidence',
      'Refresh and renew, discover your best skin yet',
      'Brighter, healthier looking skin',
      'Illuminate your complexion',
      'Coming Soon',
      'Now Offering',
    ],
    [assetTypes['Takeaway Card']]: [
      'Revitalize Your Skin, Revive Your Confidence',
      'Refresh and renew, discover your best skin yet',
      'Brighter, healthier looking skin',
      'Illuminate your complexion',
      'Coming Soon',
      'Now Offering',
    ],
    [assetTypes['Social Post']]: [
      'Revitalize Your Skin, Revive Your Confidence',
      'Refresh and renew, discover your best skin yet',
      'Brighter, healthier looking skin',
      'Illuminate your complexion',
      'Coming Soon',
      'Now Offering',
    ],
    [assetTypes.Poster]: [
      'Revitalize Your Skin, Revive Your Confidence',
      'Refresh and renew, discover your best skin yet',
      'Brighter, healthier looking skin',
      'Illuminate your complexion',
      'Coming Soon',
      'Now Offering',
    ],
    [assetTypes['Event Flyer']]: [
      'Open House Event',
      'Launch Event',
      'Join us for our Open House Event',
      'Join us for our Launch Event',
      'VIP Consult Day',
    ],
    [assetTypes['Gift Certificate']]: [
      'Feel Natural, Be Natural',
      'Glow Like Never Before',
      'Your Skin Deserves the Best',
      'Fall Back in Love with Your Skin',
      'No headline',
    ],
    [assetTypes['Referral Card']]: ['Exclusive Friends and Family Offer', 'Exclusive Offer', 'No headline'],
  },
  [devices.SculpSure]: {
    [assetTypes['Seasonal Promotion (Flyer)']]: [
      'Transform your shape',
      'Revitalize your silhouette',
      'Rediscover your confidence with SculpSure',
      'Sculpt your shape, feel your best',
      'Coming Soon',
      'Now Offering',
    ],
    [assetTypes['Email Template']]: [
      'Transform your shape',
      'Revitalize your silhouette',
      'Rediscover your confidence with SculpSure',
      'Sculpt your shape, feel your best',
      'Coming Soon',
      'Now Offering',
    ],
    [assetTypes['Takeaway Card']]: [
      'Transform your shape',
      'Revitalize your silhouette',
      'Rediscover your confidence with SculpSure',
      'Sculpt your shape, feel your best',
      'Coming Soon',
      'Now Offering',
    ],
    [assetTypes['Social Post']]: [
      'Transform your shape',
      'Revitalize your silhouette',
      'Rediscover your confidence with SculpSure',
      'Sculpt your shape, feel your best',
      'Coming Soon',
      'Now Offering',
    ],
    [assetTypes.Poster]: [
      'Transform your shape',
      'Revitalize your silhouette',
      'Rediscover your confidence with SculpSure',
      'Sculpt your shape, feel your best',
      'Coming Soon',
      'Now Offering',
    ],
    [assetTypes['Event Flyer']]: ['Join Us for our Event', 'Join Us for our Open House', 'VIP Consult Day'],
    [assetTypes['Gift Certificate']]: [
      'Show off your shape',
      'Shed the layers',
      'Tackle that fat',
      'Get your body back with confidence',
      'No headline',
    ],
    [assetTypes['Referral Card']]: [
      'Show off your shape',
      'Shed the layers',
      'Tackle that fat',
      'Get your body back with confidence',
      'No headline',
    ],
  },
  [devices.FlexSure]: {
    [assetTypes['Seasonal Promotion (Flyer)']]: [
      'Transform your shape',
      'Revitalize your silhouette',
      'Rediscover your confidence with Flexsure',
      'Sculpt your shape, feel your best',
      'Coming Soon',
      'Now Offering​​',
    ],
    [assetTypes['Email Template']]: [
      'Transform your shape',
      'Revitalize your silhouette',
      'Rediscover your confidence with Flexsure',
      'Sculpt your shape, feel your best',
      'Coming Soon',
      'Now Offering​​',
    ],
    [assetTypes['Takeaway Card']]: [
      'Transform your shape',
      'Revitalize your silhouette',
      'Rediscover your confidence with Flexsure',
      'Sculpt your shape, feel your best',
      'Coming Soon',
      'Now Offering​​',
    ],
    [assetTypes['Social Post']]: [
      'Transform your shape',
      'Revitalize your silhouette',
      'Rediscover your confidence with Flexsure',
      'Sculpt your shape, feel your best',
      'Coming Soon',
      'Now Offering​​',
    ],
    [assetTypes.Poster]: [
      'Transform your shape',
      'Revitalize your silhouette',
      'Rediscover your confidence with Flexsure',
      'Sculpt your shape, feel your best',
      'Coming Soon',
      'Now Offering​​',
    ],
    [assetTypes['Event Flyer']]: ['Join Us for our Event', 'Join Us for our Open House', 'VIP Consult Day'],
    [assetTypes['Gift Certificate']]: [
      "Cellulite Doesn't Discriminate",
      'Your Smooth Solution',
      'Full Body Fix',
      'Dare to Bare',
      'No headline',
    ],
    [assetTypes['Referral Card']]: [
      "Cellulite Doesn't Discriminate",
      'Your Smooth Solution',
      'Full Body Fix',
      'Dare to Bare',
      'No headline',
    ],
  },
  [devices['TempSure (Firm)']]: {
    [assetTypes['Seasonal Promotion (Flyer)']]: [
      'Say goodbye to cellulite',
      'Smooth out and embrace confidence',
      'Stand firm against cellulite',
      'Reveal a smoother you',
      'Coming Soon',
      'Now Offering​​',
    ],
    [assetTypes['Email Template']]: [
      'Say goodbye to cellulite',
      'Smooth out and embrace confidence',
      'Stand firm against cellulite',
      'Reveal a smoother you',
      'Coming Soon',
      'Now Offering​​',
    ],
    [assetTypes['Takeaway Card']]: [
      'Say goodbye to cellulite',
      'Smooth out and embrace confidence',
      'Stand firm against cellulite',
      'Reveal a smoother you',
      'Coming Soon',
      'Now Offering​​',
    ],
    [assetTypes['Social Post']]: [
      'Say goodbye to cellulite',
      'Smooth out and embrace confidence',
      'Stand firm against cellulite',
      'Reveal a smoother you',
      'Coming Soon',
      'Now Offering​​',
    ],
    [assetTypes.Poster]: [
      'Say goodbye to cellulite',
      'Smooth out and embrace confidence',
      'Stand firm against cellulite',
      'Reveal a smoother you',
      'Coming Soon',
      'Now Offering​​',
    ],
    [assetTypes['Event Flyer']]: ['Join Us for our Event', 'Join Us for our Open House', 'VIP Consult Day'],
    [assetTypes['Gift Certificate']]: [
      "Cellulite Doesn't Discriminate",
      'Your Smooth Solution',
      'Full Body Fix',
      'Dare to Bare',
      'No headline',
    ],
    [assetTypes['Referral Card']]: [
      "Cellulite Doesn't Discriminate",
      'Your Smooth Solution',
      'Full Body Fix',
      'Dare to Bare',
      'No headline',
    ],
  },
  [devices['Icon (Hair)']]: {
    [assetTypes['Seasonal Promotion (Flyer)']]: [
      'Experience lasting smoothness with our revolutionary hair removal solution',
      'Say goodbye to unwanted hair and hello to a smoother, more refined complexion',
      'Banish unwanted hair and embrace a rejuvenated, hair-free glow',
      'Redefine your look with effortless hair removal',
      'Coming Soon',
      'Now Offering​',
    ],
    [assetTypes['Email Template']]: [
      'Experience lasting smoothness with our revolutionary hair removal solution',
      'Say goodbye to unwanted hair and hello to a smoother, more refined complexion',
      'Banish unwanted hair and embrace a rejuvenated, hair-free glow',
      'Redefine your look with effortless hair removal',
      'Coming Soon',
      'Now Offering​',
    ],
    [assetTypes['Takeaway Card']]: [
      'Experience lasting smoothness with our revolutionary hair removal solution',
      'Say goodbye to unwanted hair and hello to a smoother, more refined complexion',
      'Banish unwanted hair and embrace a rejuvenated, hair-free glow',
      'Redefine your look with effortless hair removal',
      'Coming Soon',
      'Now Offering​',
    ],
    [assetTypes['Social Post']]: [
      'Experience lasting smoothness with our revolutionary hair removal solution',
      'Say goodbye to unwanted hair and hello to a smoother, more refined complexion',
      'Banish unwanted hair and embrace a rejuvenated, hair-free glow',
      'Redefine your look with effortless hair removal',
      'Coming Soon',
      'Now Offering​',
    ],
    [assetTypes.Poster]: [
      'Experience lasting smoothness with our revolutionary hair removal solution',
      'Say goodbye to unwanted hair and hello to a smoother, more refined complexion',
      'Banish unwanted hair and embrace a rejuvenated, hair-free glow',
      'Redefine your look with effortless hair removal',
      'Coming Soon',
      'Now Offering​',
    ],
    [assetTypes['Event Flyer']]: ['Join Us for our Event', 'Join Us for our Open House', 'VIP Consult Day'],
    [assetTypes['Gift Certificate']]: [
      "We've Got Your Back",
      'Because You Have Better Things To Do',
      'Ditch Your Hair, Not The Date',
      'Reveal Your Smoother Side',
      'No headline',
    ],
    [assetTypes['Referral Card']]: [
      "We've Got Your Back",
      'Because You Have Better Things To Do',
      'Ditch Your Hair, Not The Date',
      'Reveal Your Smoother Side',
      'No headline',
    ],
  },
  [devices['Elite iQ (Hair)']]: {
    [assetTypes['Seasonal Promotion (Flyer)']]: [
      'Experience lasting smoothness with our revolutionary hair removal solution',
      'Say goodbye to unwanted hair and hello to a smoother, more refined complexion',
      'Banish unwanted hair and embrace a rejuvenated, hair-free glow',
      'Redefine your look with effortless hair removal',
      'Coming Soon',
      'Now Offering​',
    ],
    [assetTypes['Email Template']]: [
      'Experience lasting smoothness with our revolutionary hair removal solution',
      'Say goodbye to unwanted hair and hello to a smoother, more refined complexion',
      'Banish unwanted hair and embrace a rejuvenated, hair-free glow',
      'Redefine your look with effortless hair removal',
      'Coming Soon',
      'Now Offering​',
    ],
    [assetTypes['Takeaway Card']]: [
      'Experience lasting smoothness with our revolutionary hair removal solution',
      'Say goodbye to unwanted hair and hello to a smoother, more refined complexion',
      'Banish unwanted hair and embrace a rejuvenated, hair-free glow',
      'Redefine your look with effortless hair removal',
      'Coming Soon',
      'Now Offering​',
    ],
    [assetTypes['Social Post']]: [
      'Experience lasting smoothness with our revolutionary hair removal solution',
      'Say goodbye to unwanted hair and hello to a smoother, more refined complexion',
      'Banish unwanted hair and embrace a rejuvenated, hair-free glow',
      'Redefine your look with effortless hair removal',
      'Coming Soon',
      'Now Offering​',
    ],
    [assetTypes.Poster]: [
      'Experience lasting smoothness with our revolutionary hair removal solution',
      'Say goodbye to unwanted hair and hello to a smoother, more refined complexion',
      'Banish unwanted hair and embrace a rejuvenated, hair-free glow',
      'Redefine your look with effortless hair removal',
      'Coming Soon',
      'Now Offering​',
    ],
    [assetTypes['Event Flyer']]: ['Join Us for our Event', 'Join Us for our Open House', 'VIP Consult Day'],
    [assetTypes['Gift Certificate']]: [
      "We've Got Your Back",
      'Because You Have Better Things To Do',
      'Ditch Your Hair, Not The Date',
      'Reveal Your Smoother Side',
      'No headline',
    ],
    [assetTypes['Referral Card']]: [
      "We've Got Your Back",
      'Because You Have Better Things To Do',
      'Ditch Your Hair, Not The Date',
      'Reveal Your Smoother Side',
      'No headline',
    ],
  },
};

const eventContents = {
  live_demonstrations: 'Live Demonstrations',
  light_bites_and_refreshments: 'Light Bites and Refreshments',
  special_event_pricing: 'Special Event Pricing',
  raffle_prices_giveaways_more: 'Raffle Prizes Giveaways & More!',
};

const viasureEventContents = {
  presentations: 'Presentations',
  light_bites_and_refreshments: 'Light Bites and Refreshments',
  special_event_pricing: 'Special Event Pricing',
  raffle_prices_giveaways_more: 'Raffle Prizes Giveaways & More!',
}

const ctas = {
  [devices.Potenza]: {
    [assetTypes['Seasonal Promotion (Flyer)']]: [
      'Schedule your free consultation today',
      'Ask us for more information',
    ],
    [assetTypes['Email Template']]: ['Schedule your free consultation today', 'Ask us for more information'],
    [assetTypes['Takeaway Card']]: ['Schedule your free consultation today', 'Ask us for more information'],
    [assetTypes.Poster]: ['Schedule your free consultation today', 'Ask us for more information'],
    [assetTypes['Event Flyer']]: ['Schedule your free consultation today', 'Ask us for more information'],
    [assetTypes['Referral Card']]: ['Schedule your free consultation today', 'Ask us for more information'],
  },
  [devices.ViaSure]: {
    [assetTypes['Seasonal Promotion (Flyer)']]: [
      'Schedule your free consultation today',
      'Ask us for more information',
    ],
    [assetTypes['Email Template']]: ['Schedule your free consultation today', 'Ask us for more information'],
    [assetTypes['Takeaway Card']]: ['Schedule your free consultation today', 'Ask us for more information'],
    [assetTypes.Poster]: ['Schedule your free consultation today', 'Ask us for more information'],
    [assetTypes['Event Flyer']]: [
      'RSVP required!',
      'Reserve your spot today!',
      'RSVP today! Call (xxx) xxx-xxxx',
    ],
    [assetTypes['Referral Card']]: ['Schedule your free consultation today', 'Ask us for more information'],
  },
  [devices.MyEllevate]: {
    [assetTypes['Seasonal Promotion (Flyer)']]: [
      'Schedule your free consultation today',
      'Ask us for more information',
    ],
    [assetTypes['Email Template']]: ['Schedule your free consultation today', 'Ask us for more information'],
    [assetTypes['Takeaway Card']]: ['Schedule your free consultation today', 'Ask us for more information'],
    [assetTypes.Poster]: ['Schedule your free consultation today', 'Ask us for more information'],
    [assetTypes['Event Flyer']]: [
      'RSVP required!',
      'Reserve your spot today!',
      'RSVP today! Call (xxx) xxx-xxxx',
    ],
    [assetTypes['Referral Card']]: ['Schedule your free consultation today', 'Ask us for more information'],
  },
  [devices['Picosure (Skin)']]: {
    [assetTypes['Seasonal Promotion (Flyer)']]: [
      'Schedule your free consultation today',
      'Ask us for more information',
    ],
    [assetTypes['Email Template']]: ['Schedule your free consultation today', 'Ask us for more information'],
    [assetTypes['Takeaway Card']]: ['Schedule your free consultation today', 'Ask us for more information'],
    [assetTypes.Poster]: ['Schedule your free consultation today', 'Ask us for more information'],
    [assetTypes['Event Flyer']]: [
      'RSVP required!',
      'Reserve your spot today!',
      'RSVP today! Call (xxx) xxx-xxxx',
    ],
    [assetTypes['Referral Card']]: ['Schedule your free consultation today', 'Ask us for more information'],
  },
  [devices['Picosure Pro (Skin)']]: {
    [assetTypes['Seasonal Promotion (Flyer)']]: [
      'Schedule your free consultation today',
      'Ask us for more information',
    ],
    [assetTypes['Email Template']]: ['Schedule your free consultation today', 'Ask us for more information'],
    [assetTypes['Takeaway Card']]: ['Schedule your free consultation today', 'Ask us for more information'],
    [assetTypes.Poster]: ['Schedule your free consultation today', 'Ask us for more information'],
    [assetTypes['Event Flyer']]: [
      'RSVP required!',
      'Reserve your spot today!',
      'RSVP today! Call (xxx) xxx-xxxx',
    ],
    [assetTypes['Referral Card']]: ['Schedule your free consultation today', 'Ask us for more information'],
  },
  [devices['Picosure Pro (Tattoo Removal)']]: {
    [assetTypes['Seasonal Promotion (Flyer)']]: [
      'Schedule your free consultation today',
      'Ask us for more information',
    ],
    [assetTypes['Email Template']]: ['Schedule your free consultation today', 'Ask us for more information'],
    [assetTypes['Takeaway Card']]: ['Schedule your free consultation today', 'Ask us for more information'],
    [assetTypes.Poster]: ['Schedule your free consultation today', 'Ask us for more information'],
    [assetTypes['Event Flyer']]: [
      'RSVP required!',
      'Reserve your spot today!',
      'RSVP today! Call (xxx) xxx-xxxx',
    ],
    [assetTypes['Referral Card']]: ['Schedule your free consultation today', 'Ask us for more information'],
  },
  [devices['Picosure (Tattoo Removal)']]: {
    [assetTypes['Seasonal Promotion (Flyer)']]: [
      'Schedule your free consultation today',
      'Ask us for more information',
    ],
    [assetTypes['Email Template']]: ['Schedule your free consultation today', 'Ask us for more information'],
    [assetTypes['Takeaway Card']]: ['Schedule your free consultation today', 'Ask us for more information'],
    [assetTypes.Poster]: ['Schedule your free consultation today', 'Ask us for more information'],
    [assetTypes['Event Flyer']]: [
      'RSVP required!',
      'Reserve your spot today!',
      'RSVP today! Call (xxx) xxx-xxxx',
    ],
    [assetTypes['Referral Card']]: ['Schedule your free consultation today', 'Ask us for more information'],
  },
  [devices['TempSure Envi']]: {
    [assetTypes['Seasonal Promotion (Flyer)']]: [
      'Schedule your free consultation today',
      'Ask us for more information',
    ],
    [assetTypes['Email Template']]: ['Schedule your free consultation today', 'Ask us for more information'],
    [assetTypes['Takeaway Card']]: ['Schedule your free consultation today', 'Ask us for more information'],
    [assetTypes.Poster]: ['Schedule your free consultation today', 'Ask us for more information'],
    [assetTypes['Event Flyer']]: ['Schedule your free consultation today', 'Ask us for more information'],
    [assetTypes['Referral Card']]: ['Schedule your free consultation today', 'Ask us for more information'],
  },
  [devices['Icon (Skin)']]: {
    [assetTypes['Seasonal Promotion (Flyer)']]: [
      'Schedule your free consultation today',
      'Ask us for more information',
    ],
    [assetTypes['Email Template']]: ['Schedule your free consultation today', 'Ask us for more information'],
    [assetTypes['Takeaway Card']]: ['Schedule your free consultation today', 'Ask us for more information'],
    [assetTypes.Poster]: ['Schedule your free consultation today', 'Ask us for more information'],
    [assetTypes['Event Flyer']]: ['RSVP required! Reserve your spot today!', 'RSVP today! Call (xxx) xxx-xxxx'],
    [assetTypes['Referral Card']]: ['Schedule your free consultation today', 'Ask us for more information'],
  },
  [devices['Elite iQ (Skin)']]: {
    [assetTypes['Seasonal Promotion (Flyer)']]: [
      'Schedule your free consultation today',
      'Ask us for more information',
    ],
    [assetTypes['Email Template']]: ['Schedule your free consultation today', 'Ask us for more information'],
    [assetTypes['Takeaway Card']]: ['Schedule your free consultation today', 'Ask us for more information'],
    [assetTypes.Poster]: ['Schedule your free consultation today', 'Ask us for more information'],
    [assetTypes['Event Flyer']]: ['RSVP required! Reserve your spot today!', 'RSVP today! Call (xxx) xxx-xxxx'],
    [assetTypes['Referral Card']]: ['Schedule your free consultation today', 'Ask us for more information'],
  },
  [devices.SculpSure]: {
    [assetTypes['Seasonal Promotion (Flyer)']]: [
      'Schedule your free consultation today',
      'Ask us for more information',
    ],
    [assetTypes['Email Template']]: ['Schedule your free consultation today', 'Ask us for more information'],
    [assetTypes['Takeaway Card']]: ['Schedule your free consultation today', 'Ask us for more information'],
    [assetTypes.Poster]: ['Schedule your free consultation today', 'Ask us for more information'],
    [assetTypes['Event Flyer']]: ['RSVP required! Reserve your spot today!', 'RSVP today! Call (xxx) xxx-xxxx'],
    [assetTypes['Referral Card']]: ['Schedule your free consultation today', 'Ask us for more information'],
  },
  [devices.FlexSure]: {
    [assetTypes['Seasonal Promotion (Flyer)']]: [
      'Schedule your free consultation today',
      'Ask us for more information',
    ],
    [assetTypes['Email Template']]: ['Schedule your free consultation today', 'Ask us for more information'],
    [assetTypes['Takeaway Card']]: ['Schedule your free consultation today', 'Ask us for more information'],
    [assetTypes.Poster]: ['Schedule your free consultation today', 'Ask us for more information'],
    [assetTypes['Event Flyer']]: ['RSVP required! Reserve your spot today!', 'RSVP today! Call (xxx) xxx-xxxx'],
    [assetTypes['Referral Card']]: ['Schedule your free consultation today', 'Ask us for more information'],
  },
  [devices['TempSure (Firm)']]: {
    [assetTypes['Seasonal Promotion (Flyer)']]: [
      'Schedule your free consultation today',
      'Ask us for more information',
    ],
    [assetTypes['Email Template']]: ['Schedule your free consultation today', 'Ask us for more information'],
    [assetTypes['Takeaway Card']]: ['Schedule your free consultation today', 'Ask us for more information'],
    [assetTypes.Poster]: ['Schedule your free consultation today', 'Ask us for more information'],
    [assetTypes['Event Flyer']]: ['RSVP required! Reserve your spot today!', 'RSVP today! Call (xxx) xxx-xxxx'],
    [assetTypes['Referral Card']]: ['Schedule your free consultation today', 'Ask us for more information'],
  },
  [devices['Icon (Hair)']]: {
    [assetTypes['Seasonal Promotion (Flyer)']]: [
      'Schedule your free consultation today',
      'Ask us for more information',
    ],
    [assetTypes['Email Template']]: ['Schedule your free consultation today', 'Ask us for more information'],
    [assetTypes['Takeaway Card']]: ['Schedule your free consultation today', 'Ask us for more information'],
    [assetTypes.Poster]: ['Schedule your free consultation today', 'Ask us for more information'],
    [assetTypes['Event Flyer']]: ['RSVP required! Reserve your spot today!', 'RSVP today! Call (xxx) xxx-xxxx'],
    [assetTypes['Referral Card']]: ['Schedule your free consultation today', 'Ask us for more information'],
  },
  [devices['Elite iQ (Hair)']]: {
    [assetTypes['Seasonal Promotion (Flyer)']]: [
      'Schedule your free consultation today',
      'Ask us for more information',
    ],
    [assetTypes['Email Template']]: ['Schedule your free consultation today', 'Ask us for more information'],
    [assetTypes['Takeaway Card']]: ['Schedule your free consultation today', 'Ask us for more information'],
    [assetTypes.Poster]: ['Schedule your free consultation today', 'Ask us for more information'],
    [assetTypes['Event Flyer']]: ['RSVP required! Reserve your spot today!', 'RSVP today! Call (xxx) xxx-xxxx'],
    [assetTypes['Referral Card']]: ['Schedule your free consultation today', 'Ask us for more information'],
  },
};

const modelImageAssetTypes = [
  assetTypes['Seasonal Promotion (Flyer)'],
  assetTypes['Email Template'],
  assetTypes['Takeaway Card'],
  assetTypes['Social Post'],
  assetTypes.Poster,
  assetTypes['Event Flyer'],
];

const beforeAfterAssetTypes = [
  assetTypes['Seasonal Promotion (Flyer)'],
  assetTypes['Email Template'],
  assetTypes['Takeaway Card'],
  assetTypes.Poster,
  assetTypes['Event Flyer'],
];

enum beforeAfterAssetTypesExclude {
  'ViaSure' = 'ViaSure'
};

const eventDataAssetTypes = [assetTypes['Event Flyer']];

const discounts = {
  [devices.Potenza]: {
    [assetTypes['Gift Certificate']]: ['$100', '$150', '$200', '$250'],
    [assetTypes['Referral Card']]: ['$100', '$150', '$200', '$250', '10%', '15%', '20%'],
  },
  [devices.ViaSure]: {
    [assetTypes['Gift Certificate']]: ['$100', '$150', '$200', '$250'],
    [assetTypes['Referral Card']]: ['$100', '$150', '$200', '$250', '10%', '15%', '20%'],
  },
  [devices.MyEllevate]: {
    [assetTypes['Gift Certificate']]: ['$100', '$150', '$200', '$250'],
    [assetTypes['Referral Card']]: ['$100', '$150', '$200', '$250', '10%', '15%', '20%'],
  },
  [devices['Picosure (Skin)']]: {
    [assetTypes['Gift Certificate']]: ['$100', '$150', '$200', '$250'],
    [assetTypes['Referral Card']]: ['$100', '$150', '$200', '$250', '10%', '15%', '20%'],
  },
  [devices['Picosure Pro (Skin)']]: {
    [assetTypes['Gift Certificate']]: ['$100', '$150', '$200', '$250'],
    [assetTypes['Referral Card']]: ['$100', '$150', '$200', '$250', '10%', '15%', '20%'],
  },
  [devices['Picosure Pro (Tattoo Removal)']]: {
    [assetTypes['Gift Certificate']]: ['$100', '$150', '$200', '$250'],
    [assetTypes['Referral Card']]: ['$100', '$150', '$200', '$250', '10%', '15%', '20%'],
  },
  [devices['Picosure (Tattoo Removal)']]: {
    [assetTypes['Gift Certificate']]: ['$100', '$150', '$200', '$250'],
    [assetTypes['Referral Card']]: ['$100', '$150', '$200', '$250', '10%', '15%', '20%'],
  },
  [devices['TempSure Envi']]: {
    [assetTypes['Gift Certificate']]: ['$100', '$150', '$200', '$250'],
    [assetTypes['Referral Card']]: ['$100', '$150', '$200', '$250', '10%', '15%', '20%'],
  },
  [devices['Icon (Skin)']]: {
    [assetTypes['Gift Certificate']]: ['$100', '$150', '$200', '$250'],
    [assetTypes['Referral Card']]: ['$100', '$150', '$200', '$250', '10%', '15%', '20%'],
  },
  [devices['Elite iQ (Skin)']]: {
    [assetTypes['Gift Certificate']]: ['$100', '$150', '$200', '$250'],
    [assetTypes['Referral Card']]: ['$100', '$150', '$200', '$250', '10%', '15%', '20%'],
  },
  [devices.SculpSure]: {
    [assetTypes['Gift Certificate']]: ['$100', '$150', '$200', '$250'],
    [assetTypes['Referral Card']]: ['$100', '$150', '$200', '$250', '10%', '15%', '20%'],
  },
  [devices.FlexSure]: {
    [assetTypes['Gift Certificate']]: ['$100', '$150', '$200', '$250'],
    [assetTypes['Referral Card']]: ['$100', '$150', '$200', '$250', '10%', '15%', '20%'],
  },
  [devices['TempSure (Firm)']]: {
    [assetTypes['Gift Certificate']]: ['$100', '$150', '$200', '$250'],
    [assetTypes['Referral Card']]: ['$100', '$150', '$200', '$250', '10%', '15%', '20%'],
  },
  [devices['Icon (Hair)']]: {
    [assetTypes['Gift Certificate']]: ['$100', '$150', '$200', '$250'],
    [assetTypes['Referral Card']]: ['$100', '$150', '$200', '$250', '10%', '15%', '20%'],
  },
  [devices['Elite iQ (Hair)']]: {
    [assetTypes['Gift Certificate']]: ['$100', '$150', '$200', '$250'],
    [assetTypes['Referral Card']]: ['$100', '$150', '$200', '$250', '10%', '15%', '20%'],
  },
};

const practiceInfoAssetTypes = [
  assetTypes['Seasonal Promotion (Flyer)'],
  assetTypes['Email Template'],
  assetTypes['Takeaway Card'],
  assetTypes['Event Flyer'],
  assetTypes['Gift Certificate'],
  assetTypes['Referral Card'],
  assetTypes['Banner Stand'],
  assetTypes.Poster,
  assetTypes.Brochure,
];

const skinTypes = {
  'Type 1': {
    image: {
      src: require('@/assets/images/skin_1.jpg'),
      width: 200,
      height: 200,
    },
  },
  'Type 2': {
    image: {
      src: require('@/assets/images/skin_2.jpg'),
      width: 200,
      height: 200,
    },
  },
  'Type 3': {
    image: {
      src: require('@/assets/images/skin_3.jpg'),
      width: 200,
      height: 200,
    },
  },
  'Type 4': {
    image: {
      src: require('@/assets/images/skin_4.jpg'),
      width: 200,
      height: 200,
    },
  },
  'Type 5': {
    image: {
      src: require('@/assets/images/skin_5.jpg'),
      width: 200,
      height: 200,
    },
  },
  'Type 6': {
    image: {
      src: require('@/assets/images/skin_6.jpg'),
      width: 200,
      height: 200,
    },
  },
};

export default mixins(imageSizeMixin).extend({
  name: 'ResourcePersonalize',
  $_veeValidate: {
    validator: 'new',
  },
  components: {
    i18n,
    ModalWithSidebar,
    Modal,
    WistiaVideo,
    SelectInput,
    FormInput,
    CheckboxInput,
    SubmitButton,
    ImageUploadControl,
  },
  data() {
    return {
      formStep: 1,
      isSubmitting: false,
      isSuccess: false,
      isError: false,
      device: '',
      asset_type: '',
      headline: '',
      use_ba_images: '',
      call_to_action: '',
      event_date: '',
      event_location: '',
      event_content: [],
      practice_logo: null,
      practice_address: '',
      practice_phone: '',
      model_gender: '',
      model_age: '',
      model_body: '',
      model_skin: '',
      discount: '',
      isHowToModalOpen: false,
    };
  },
  computed: {
    ...mapState('user/profile', ['currentProfile']),
    skinTypes() {
      return skinTypes;
    },
    devices() {
      if (this.currentProfile && Array.isArray(this.currentProfile.personalization)) {
        return Object.keys(devices)
          .filter((deviceKey) => this.currentProfile.personalization.includes(devices[deviceKey]))
          .reduce((obj, key) => {
            obj[key] = devices[key];
            return obj;
          }, {});
      }
      return {};
    },
    assetTypes() {
      return assetTypes;
    },
    headlines() {
      return headlines;
    },
    eventContents() {
      if (this.device == devices.ViaSure) {
        return viasureEventContents;
      }
      return eventContents;
    },
    ctas() {
      return ctas;
    },
    modelImageAssetTypes() {
      return modelImageAssetTypes;
    },
    beforeAfterAssetTypes() {
      return beforeAfterAssetTypes;
    },
    beforeAfterAssetTypesExclude() {
      return beforeAfterAssetTypesExclude;
    },
    eventDataAssetTypes() {
      return eventDataAssetTypes;
    },
    practiceInfoAssetTypes() {
      return practiceInfoAssetTypes;
    },
    discounts() {
      return discounts;
    },
  },
  methods: {
    ...mapActions([RootActions.DISPATCH_EVENT]),
    submitFirstStep() {
      this.isSubmitting = true;
      this.isSuccess = false;
      this.isError = false;

      this.$validator
        .validateAll('firstStep')
        .then((result) => {
          this.isSubmitting = false;
          if (result) {
            this.formStep = 2;
          }
        })
        .catch((err) => {
          this.isSubmitting = false;
          throw err;
        });
    },
    submitSecondStep() {
      this.isSubmitting = true;
      this.isSuccess = false;
      this.isError = false;

      this.$validator
        .validateAll('secondStep')
        .then((result) => {
          if (result) {
            return api.personalize
              .personalize({
                product: this.device,
                template: this.asset_type,
                headline: this.headline || null,
                model_gender: this.model_gender || null,
                model_age: this.model_age || null,
                model_skin: this.model_skin || null,
                model_body: this.model_body || null,
                use_ba_images: this.use_ba_images || null,
                event_date: this.event_date || null,
                event_location: this.event_location || null,
                address: this.practice_address || null,
                phone: this.practice_phone || null,
                call_to_action: this.call_to_action || null,
                logo: this.practice_logo && this.practice_logo.id ? { id: this.practice_logo.id } : null,
                event_content: {
                  presentations: this.event_content.includes('presentations'),
                  live_demonstrations: this.event_content.includes('live_demonstrations'),
                  light_bites_and_refreshments: this.event_content.includes('light_bites_and_refreshments'),
                  special_event_pricing: this.event_content.includes('special_event_pricing'),
                  raffle_prices_giveaways_more: this.event_content.includes('raffle_prices_giveaways_more'),
                },
                discount: this.discount || null,
              })
              .then(() => {
                this.isSuccess = true;
                this[RootActions.DISPATCH_EVENT]({
                  type: EventTypes.PERSONALIZE,
                  eventObj: { product: this.device, asset_type: this.asset_type },
                });
              });
          }
        })
        .then(() => {
          this.isSubmitting = false;
        })
        .catch((err) => {
          this.isSubmitting = false;
          this.isError = true;
          throw err;
        });
    },
    onClose() {
      if (!this.isSubmitting) {
        this.$emit('close');
      }
    },
    resetValues(device: keyof devices, assetType: keyof assetTypes) {
      if (
        typeof this.headlines[device] === 'undefined' ||
        typeof this.headlines[device][assetType] === 'undefined' ||
        !Array.isArray(this.headlines[device][assetType]) ||
        !this.headlines[device][assetType].includes(this.headline)
      ) {
        this.headline = '';
      }

      if (
        typeof this.ctas[device] === 'undefined' ||
        typeof this.ctas[device][assetType] === 'undefined' ||
        !Array.isArray(this.ctas[device][assetType]) ||
        !this.ctas[device][assetType].includes(this.call_to_action)
      ) {
        this.call_to_action = '';
      }

      if (
        typeof this.discounts[device] === 'undefined' ||
        typeof this.discounts[device][assetType] === 'undefined' ||
        !Array.isArray(this.discounts[device][assetType]) ||
        !this.discounts[device][assetType].includes(this.discount)
      ) {
        this.discount = '';
      }
    },
    openHowToModal() {
      this.isHowToModalOpen = true;
    },
  },
  watch: {
    device(value) {
      this.resetValues(value, this.asset_type);
    },
    asset_type(value) {
      this.resetValues(this.device, value);
    },
  },
  created() {
    const keys = Object.keys(this.devices);
    if (keys.length === 1) {
      this.device = this.devices[keys[0]];
    }
  },
});
