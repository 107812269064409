
import Vue, { PropOptions } from 'vue';
import { DIFF_DELETE, DIFF_INSERT, DIFF_EQUAL, Diff } from 'diff-match-patch';

const tags = {
  [DIFF_DELETE]: 'del',
  [DIFF_INSERT]: 'ins',
  [DIFF_EQUAL]: 'span',
};

export default Vue.extend({
  name: 'TextDiffBlock',
  props: {
    tag: {
      type: String,
      required: true,
    },
    value: {
      type: Array,
      required: true,
    } as PropOptions<Diff[]>,
  },
  computed: {
    els() {
      return (this.value as Diff[]).reduce((res, [type, text]) => {
        const tag = tags[type];
        const lines = text.split('\n');

        lines.forEach((line, index) => {
          if (index) {
            res.push({
              tag,
              text: '↵',
            });
            res.push({
              tag: 'br',
            });
          }

          if (line) {
            res.push({
              tag,
              text: line,
            });
          }
        });

        return res;
      }, [] as { tag: string; text?: string; fullWidth?: boolean }[]);
    },
  },
});
