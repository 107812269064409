import Vue from 'vue';
import _i18next, { TFunction } from 'i18next';
import { isValid as isValidDate, parse as parseDate, parseISO } from 'date-fns';

// Intl API
import '@formatjs/intl-getcanonicallocales/polyfill';
import '@formatjs/intl-locale/polyfill';
import '@formatjs/intl-pluralrules/polyfill';
import '@formatjs/intl-pluralrules/locale-data/en';
import '@formatjs/intl-listformat/polyfill';
import '@formatjs/intl-listformat/locale-data/en';
import '@formatjs/intl-numberformat/polyfill';
import '@formatjs/intl-numberformat/locale-data/en';
import '@formatjs/intl-datetimeformat/polyfill';
import '@formatjs/intl-datetimeformat/locale-data/en';
import '@formatjs/intl-datetimeformat/add-all-tz';

import enTranslation from '@/lang/en.json';
import { setLocale as setVeeValidateLocale } from '@/plugins/vee-validate/i18n';

declare module 'vue/types/vue' {
  interface Vue {
    $t: TFunction;
  }
}

let isInit = false;
let translate: TFunction;

export const i18next: typeof _i18next = Object.create(_i18next);

if (process.env.VUE_APP_MODE !== 'production' && localStorage.getItem('amps-i18n-debug') === '1') {
  translate = (...args: Parameters<TFunction>) => {
    const result = _i18next.t.apply(i18next, args);

    if (typeof result === 'string') {
      return `[[${result}]]`;
    }

    return result;
  };
} else {
  translate = _i18next.t.bind(i18next);
}

i18next.t = translate;

Vue.use((_Vue) => {
  _Vue.prototype.$t = i18next.t;

  _Vue.filter('formatNumber', (value: any) => {
    return formatNumber(value);
  });

  _Vue.filter('formatTime', (value: any) => {
    return formatTime(value);
  });

  _Vue.filter('formatDate', (value: any) => {
    return formatDate(value);
  });

  _Vue.filter('formatList', (value: any) => {
    if (!Array.isArray(value)) {
      return value;
    }

    return formatList(value);
  });
});

i18next.init({
  lng: process.env.VUE_APP_I18N_LOCALE || 'en',
  fallbackLng: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  resources: {
    en: {
      translation: enTranslation,
    },
  },
  interpolation: {
    prefix: '%(',
    suffix: ')%',
  },
  nsSeparator: false,
  fallbackNS: false,
  keySeparator: false,
});

async function _setLocale(locale: string) {
  await i18next.changeLanguage(locale);
  setVeeValidateLocale(locale);
  document.querySelector('html')?.setAttribute('lang', locale);
}

function mapLocaleToIntlRegionLocale(locale: string) {
  if (locale === 'en-gb') {
    return 'en-GB';
  }

  return locale;
}

function mapLocaleToIntlLocale(locale: string) {
  if (locale === 'en-gb') {
    return 'en';
  }

  return locale;
}

export async function setLocale(locale: string) {
  if (process.env.VUE_APP_MODE !== 'production' && localStorage.getItem('amps-i18n-debug') === '1') {
    // tslint:disable-next-line: no-console
    console.log('setLocale', locale);
  }

  if (i18next.language === locale && isInit) {
    return;
  }

  isInit = true;

  if (i18next.languages.includes(locale)) {
    return _setLocale(locale);
  }

  let translation = {};
  const intlRegionLocale = mapLocaleToIntlRegionLocale(locale);
  const intlLocale = mapLocaleToIntlLocale(locale);

  // TODO: figure out how to automatize generation of code bellow
  await Promise.all(
    [
      import(
        /* webpackChunkName: "lang-[request]" */
        /* webpackInclude: /\/(en|en-gb|es|fr|de|ko|pt).json$/ */
        /* tslint:disable-next-line trailing-comma */
        `@/lang/${locale}.json`
      ).then((result) => {
        translation = result;
      }),
      import(
        /* webpackChunkName: "intl-pluralrules-locale-[request]" */
        /* webpackInclude: /\/(en|es|fr|de|pt).js$/ */
        /* tslint:disable-next-line trailing-comma */
        `@formatjs/intl-pluralrules/locale-data/${intlLocale}.js`
      ),
      import(
        /* webpackChunkName: "intl-listformat-locale-[request]" */
        /* webpackInclude: /\/(en|en-gb|es|fr|de|ko|pt).js$/ */
        /* tslint:disable-next-line trailing-comma */
        `@formatjs/intl-listformat/locale-data/${intlRegionLocale}.js`
      ),
      import(
        /* webpackChunkName: "intl-numberformat-locale-[request]" */
        /* webpackInclude: /\/(en|en-gb|es|fr|de|ko|pt).js$/ */
        /* tslint:disable-next-line trailing-comma */
        `@formatjs/intl-numberformat/locale-data/${intlRegionLocale}.js`
      ),
      import(
        /* webpackChunkName: "intl-datetimeformat-locale-[request]" */
        /* webpackInclude: /\/(en|en-gb|es|fr|de|ko|pt).js$/ */
        /* tslint:disable-next-line trailing-comma */
        `@formatjs/intl-datetimeformat/locale-data/${intlRegionLocale}.js`
      ),
    ].map((p) =>
      p.catch((e) => {
        // tslint:disable-next-line: no-console
        console.error(e);
      }),
    ),
  );

  try {
    i18next.addResourceBundle(locale, 'translation', translation);
  } catch (e) {
    // tslint:disable-next-line: no-console
    console.error(e);
  }

  return _setLocale(locale);
}

export const formatNumber = (value: any): string => {
  return Number(value).toLocaleString(i18next.language);
};

export const formatTime = (
  time: string,
  options: Intl.DateTimeFormatOptions = { hour: 'numeric', minute: 'numeric' },
): string => {
  const d = new Date();
  const [hours = 0, minutes = 0, seconds = 0] = time.split(':').map((item) => {
    const num = Number(item);

    if (Number.isNaN(num) || !Number.isInteger(num)) {
      return 0;
    }

    return num;
  });

  d.setHours(hours);
  d.setMinutes(minutes);
  d.setSeconds(seconds);

  return d.toLocaleTimeString(i18next.language, options);
};

export const parseToDate = (date: any) => {
  if (typeof date === 'string') {
    const parsed = parseDate(date, 'yyyy-MM-dd HH:mm:ss', new Date());
    if (isValidDate(parsed)) {
      return parsed;
    }

    const parsedISO = parseISO(date);
    if (isValidDate(parsedISO)) {
      return parsedISO;
    }
  }

  return String(date);
};

export const formatDate = (date: Date | string): string => {
  let d: Date;

  if (!(date instanceof Date)) {
    const parsed = parseToDate(date);

    if (!(parsed instanceof Date)) {
      return parsed;
    }

    d = parsed;
  } else {
    d = date;
  }

  return d.toLocaleDateString(i18next.language, {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
    formatMatcher: 'basic',
  });
};

let listFormatterLocale = i18next.language;
let listFormatter = new Intl.ListFormat(listFormatterLocale);

export const formatList = (list: string[]): string => {
  if (listFormatterLocale !== i18next.language) {
    listFormatter = new Intl.ListFormat(i18next.language);
    listFormatterLocale = i18next.language;
  }

  return listFormatter.format(list.filter(Boolean));
};

export default i18next;
