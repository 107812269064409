import { GetterTree } from 'vuex';
import { RootState, ListStatus } from '@/store/types';
import { PrivacyRequestState } from '@/store/modules/privacy_requests/types';
import { createEditableGetters } from '@/store/utils/editable';

const editableGetters = createEditableGetters<PrivacyRequestState>('editableStatus', 'editable');

export const moduleGetters: GetterTree<PrivacyRequestState, RootState> = {
  isLoading: (state) => state.status === ListStatus.Loading,
  isLoaded: (state) => state.status === ListStatus.Loaded,
  isError: (state) => state.status === ListStatus.Failed,
  isEmpty: (state, getters) => getters.isLoaded && !state.list.length,

  /**
   * Edit
   */
  editableIsLoading: editableGetters.isLoading,
  editableIsLoaded: editableGetters.isLoaded,
  editableIsSaving: editableGetters.isSaving,
  editableIsSaved: editableGetters.isSaved,
  editableIsFailed: editableGetters.isFailed,
};

export default moduleGetters;
