
import Vue from 'vue';
import { mapGetters } from 'vuex';

import NewPasswordForm from '@/components/forms/new-password.vue';

export default Vue.extend({
  name: 'ConfirmPasswordView',
  components: {
    NewPasswordForm,
  },
  computed: {
    ...mapGetters('user', ['isResetPending', 'isResetSuccess']),
  },
  created() {
    return this.$store.dispatch('user/VERIFY_RESET', this.$route.query);
  },
});
