import Vue from 'vue';
import { MutationTree } from 'vuex';

import { setAccessToken, removeAccessToken } from '@/api/utils';

import { getInitialState } from '@/store/modules/user';
import {
  UserState,
  UserMutations,
  LoginStatus,
  SupportStatus,
  SubmitNewPasswordStatus,
} from '@/store/modules/user/types';

export const mutations: MutationTree<UserState> = {
  [UserMutations.LOGIN_PENDING](state) {
    state.loginStatus = LoginStatus.Pending;
  },
  [UserMutations.LOGIN_SUCCESS](state) {
    state.loginStatus = LoginStatus.Success;
  },
  [UserMutations.LOGIN_FAILED](state, message) {
    Vue.set(state, 'loginErrorMessage', message);
    state.loginStatus = LoginStatus.Failed;
  },
  [UserMutations.RESET_PENDING](state) {
    state.resetStatus = LoginStatus.Pending;
  },
  [UserMutations.RESET_SUCCESS](state, token) {
    state.resetStatus = LoginStatus.Success;
  },
  [UserMutations.RESET_FAILED](state, message) {
    state.resetStatus = LoginStatus.Failed;
  },
  [UserMutations.LOGOUT_PENDING](state) {
    state.loginStatus = LoginStatus.Pending;
  },
  [UserMutations.RESET_STORE_STATE](state) {
    Object.assign(state, getInitialState());
  },
  [UserMutations.SUPPORT_PENDING](state) {
    state.supportStatus = SupportStatus.Pending;
  },
  [UserMutations.SUPPORT_SUCCESS](state, token) {
    state.supportStatus = SupportStatus.Success;
  },
  [UserMutations.SUPPORT_FAILED](state, message) {
    state.supportStatus = SupportStatus.Failed;
  },
  [UserMutations.SET_TOKEN](state, token) {
    if (token) {
      setAccessToken(token);
    } else {
      removeAccessToken();
    }

    state.currentToken = token;
  },
  // this stores the admin token for later so that we can
  [UserMutations.CACHE_IMPERSONATE_TOKEN](state) {
    if (state.currentToken) {
      localStorage.setItem('admin-token', state.currentToken);
    }
  },
  [UserMutations.RESTORE_IMPERSONATE_TOKEN](state) {
    const token = localStorage.getItem('admin-token');
    if (token) {
      setAccessToken(token);
      state.currentToken = token;
      localStorage.removeItem('admin-token');
    }
  },
  [UserMutations.SET_SUBMIT_NEW_PASSWORD_STATUS](state, status: SubmitNewPasswordStatus) {
    state.submitNewPasswordStatus = status;
  },
};

export default mutations;
