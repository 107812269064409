import { JSONModel } from './jsonModel';
import CountryState, { CountryStateJSON } from './country-state';
import { createCounter } from '@/store/utils/counter';

const getLocalId = createCounter();
const localIdPrefix = 'local_country_';

export function isCountryLocalId(val: any): val is string {
  if (typeof val === 'string' && val.startsWith(localIdPrefix)) {
    return true;
  }

  return false;
}

export interface CountryJSON {
  id: number;
  code: string;
  name: string;
  status?: 0 | 1;
  states?: CountryStateJSON[];
  admin_email: string[];
}

export default class Country extends JSONModel<CountryJSON> {
  public id: string | number = 0;
  public status: 0 | 1 = 1;
  public code: string = '';
  public name: string = '';
  public states: CountryState[] = [];
  public adminEmail: string[] = [];

  protected _clientProperties = ['states'];

  constructor() {
    super();

    this.id = localIdPrefix + getLocalId();
  }

  get isLocal() {
    return isCountryLocalId(this.id);
  }

  public fromJSON(json: Partial<CountryJSON>) {
    if (isCountryLocalId(json.id)) {
      this.id = json.id;
    } else {
      this.id = Number(json.id);
    }

    if (json.code) {
      this.code = String(json.code);
      this.name = this.code;
    }

    if (json.name) {
      this.name = String(json.name);
    }

    if (json.states && Array.isArray(json.states) && json.states.length) {
      this.states = json.states.map((item) => CountryState.fromJSON(item));
    }

    if (json.status === 0 || (json.status as unknown as string) === '0') {
      this.status = 0;
    }

    if (json.admin_email && Array.isArray(json.admin_email)) {
      this.adminEmail = json.admin_email;
    }
  }
}
