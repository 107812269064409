/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'table': {
    width: 21.5,
    height: 13.91,
    viewBox: '0 0 21.5 13.91',
    data: '<g data-name="Layer 2"><g data-name="Alphabet List"><g data-name="&lt;Group&gt;"><g data-name="&lt;Group&gt;"><path pid="0" d="M20.62 1.26H7.71a.38.38 0 110-.76h12.91a.38.38 0 110 .76z"/><path pid="1" d="M20.62 1.76H7.71a.88.88 0 110-1.76h12.91a.88.88 0 110 1.76z"/></g><g data-name="&lt;Group&gt;"><path pid="2" d="M20.62 7.33H7.71a.38.38 0 110-.76h12.91a.38.38 0 110 .76z"/><path pid="3" d="M20.62 7.83H7.71a.88.88 0 110-1.76h12.91a.88.88 0 110 1.76z"/></g><g data-name="&lt;Group&gt;"><path pid="4" d="M20.62 13.41H7.71a.38.38 0 010-.76h12.91a.38.38 0 010 .76z"/><path pid="5" d="M20.62 13.91H7.71a.88.88 0 110-1.76h12.91a.88.88 0 110 1.76z"/></g><g data-name="&lt;Group&gt;"><g data-name="&lt;Group&gt;"><path pid="6" d="M4.19 1.26H.61C.55 1.26.5 1.09.5.88S.55.5.61.5h3.58c.06 0 .11.17.11.38s-.05.38-.11.38z"/><path pid="7" d="M4.19 1.76H.61C.46 1.76 0 1.7 0 .88S.46 0 .61 0h3.58c.14 0 .61.06.61.88s-.47.88-.61.88z"/></g><g data-name="&lt;Group&gt;"><path pid="8" d="M4.19 7.33H.61C.55 7.33.5 7.16.5 7s0-.38.11-.38h3.58c.06 0 .11.17.11.38s-.05.33-.11.33z"/><path pid="9" d="M4.19 7.83H.61C.46 7.83 0 7.77 0 7s.46-.88.61-.88h3.58c.14 0 .61.06.61.88s-.47.83-.61.83z"/></g><g data-name="&lt;Group&gt;"><path pid="10" d="M4.19 13.41H.61c-.06 0-.11-.17-.11-.41s0-.38.11-.38h3.58c.06 0 .11.17.11.38s-.05.41-.11.41z"/><path pid="11" d="M4.19 13.91H.61c-.15 0-.61-.07-.61-.91s.46-.88.61-.88h3.58c.14 0 .61.06.61.88s-.47.91-.61.91z"/></g></g></g></g></g>'
  }
})
