import { Module } from 'vuex';

import { RootState } from '@/store/types';
import { UserState, LoginStatus, SupportStatus, SubmitNewPasswordStatus } from '@/store/modules/user/types';

import actions from './actions';
import mutations from './mutations';
import getters from './getters';
import profile from './profile';
import { getAccessToken, setAccessToken } from '@/api/utils';

export const getInitialState = (checkLocalStorage: boolean = false): UserState => {
  let userToken: string | null = null;

  if (checkLocalStorage) {
    userToken = getAccessToken();

    if (userToken) {
      setAccessToken(userToken);
    }
  }

  return {
    loginStatus: userToken ? LoginStatus.Success : LoginStatus.Loggedout,
    resetStatus: LoginStatus.Failed,
    supportStatus: SupportStatus.Initial,
    currentToken: userToken || '',
    submitNewPasswordStatus: SubmitNewPasswordStatus.Initial,
  };
};

export const state: UserState = getInitialState(true);

const user: Module<UserState, RootState> = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
  modules: {
    profile,
  },
};

export default user;
