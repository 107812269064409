/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'plus': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M10.53 0h2.93v24h-2.93z"/><path pid="1" d="M0 13.47v-2.93h24v2.93z"/>'
  }
})
