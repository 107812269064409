import { createCRUDEndpoints } from '@/api/factory/crud';
import { TaxonomyJSON } from '@/store/models/taxonomy';
import Practice from '@/store/modules/practices/practice';
import { ProductJSON } from '@/store/modules/products/product';
import { TreatmentJSON, TreatmentStatus } from '@/store/modules/treatments/treatment';
import { createTranslateEndpoint } from '../factory/translate';
import httpClient from '../http-client';
import { RequestConfig } from '../types';

type ListParams =
  | {
      title?: string;
      search?: string;
      status?: string | number | TreatmentStatus;
      page?: string | number;
      sort?: string;
      direction?: 'asc' | 'desc';
    }
  | undefined;

export const { list, get, store, remove, restore } = createCRUDEndpoints<TreatmentJSON, ListParams>({
  slug: 'treatment',
});

export const translate = createTranslateEndpoint<TreatmentJSON>({ slug: 'treatment' });

export function listTree(params: { practice_id?: Practice['id'] }, config?: RequestConfig) {
  return httpClient.get<ProductJSON[]>('treatments/tree', { params, ...config });
}

export function listAll(config?: RequestConfig) {
  return httpClient.get<TaxonomyJSON[]>('treatments/all', config);
}
