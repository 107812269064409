import { ActionTree } from 'vuex';

import api from '@/api';
import router from '@/router';
import i18next from '@/i18n';

import Pagination from '@/store/models/pagination';
import { RootState, ErrorMessages, RootActions, EditableStatus, ListStatus } from '@/store/types';

import { NotificationsActions } from '@/store/modules/notifications/types';
import PrivacyRequest from '@/store/modules/privacy_requests/privacy_request';
import {
  PrivacyRequestState,
  PrivacyRequestActions,
  PrivacyRequestMutations,
} from '@/store/modules/privacy_requests/types';
import { enhanceErrorWithMessage } from '@/utils/errors';

export const actions: ActionTree<PrivacyRequestState, RootState> = {
  [PrivacyRequestActions.LOAD](
    { commit, dispatch },
    { status, practice_id, page = 1, sort, direction } = {},
  ): Promise<void> {
    commit(PrivacyRequestMutations.SET_STATUS, ListStatus.Loading);

    return api.privacy_requests
      .list({ status, practice_id, page, sort, direction })
      .then((response) => response.data)
      .then((response) => {
        const privacyRequests = response.data.map((privacyRequest) => PrivacyRequest.fromJSON(privacyRequest));
        commit(PrivacyRequestMutations.SET_LIST_PAGINATION, Pagination.fromJSON(response));
        commit(PrivacyRequestMutations.SET_LIST, privacyRequests);
        commit(PrivacyRequestMutations.SET_STATUS, ListStatus.Loaded);
      })
      .catch((error) => {
        enhanceErrorWithMessage(error, ErrorMessages.DATA_LOAD_ERROR);

        commit(PrivacyRequestMutations.SET_STATUS, ListStatus.Failed);
        return dispatch(RootActions.ERROR, error, { root: true });
      });
  },

  [PrivacyRequestActions.LOAD_EDITABLE]({ commit, dispatch }, { id } = {}): Promise<void> {
    commit(PrivacyRequestMutations.SET_EDITABLE_STATUS, EditableStatus.Loading);

    if (!id) {
      const error = new Error('Invalid data!');
      enhanceErrorWithMessage(error, ErrorMessages.DATA_LOAD_ERROR);
      commit(PrivacyRequestMutations.SET_EDITABLE_STATUS, EditableStatus.Failed);

      return dispatch(RootActions.ERROR, error, { root: true });
    }

    return api.privacy_requests
      .get(id)
      .then((response) => response.data)
      .then((privacyRequest) => {
        commit(PrivacyRequestMutations.SET_EDITABLE, PrivacyRequest.fromJSON(privacyRequest));
        commit(PrivacyRequestMutations.SET_EDITABLE_STATUS, EditableStatus.Loaded);
      })
      .catch((error) => {
        enhanceErrorWithMessage(error, ErrorMessages.DATA_LOAD_ERROR);

        commit(PrivacyRequestMutations.SET_EDITABLE_STATUS, EditableStatus.Failed);
        return dispatch(RootActions.ERROR, error, { root: true });
      });
  },

  [PrivacyRequestActions.DELETE]({ commit, dispatch }, { id = 0 }: { id?: number } = {}): Promise<void> {
    const isList = router.currentRoute.name === 'privacy-requests';
    if (isList) {
      commit(PrivacyRequestMutations.SET_STATUS, ListStatus.Loading);
    } else {
      commit(PrivacyRequestMutations.SET_EDITABLE_STATUS, EditableStatus.Saving);
    }

    return new Promise<void>((resolve, reject) => {
      if (!id) {
        return reject(new Error('Invalid data!'));
      }

      resolve();
    })
      .then(() => api.privacy_requests.remove(id))
      .then(() => {
        if (isList) {
          return dispatch(PrivacyRequestActions.LOAD, {
            page: router.currentRoute.params.page,
          });
        } else {
          return dispatch(PrivacyRequestActions.LOAD_EDITABLE, { id });
        }
      })
      .then(() => {
        return dispatch(
          'notifications/' + NotificationsActions.SHOW_SUCCESS,
          {
            body: i18next.t('The privacy request was closed successfully.'),
          },
          { root: true },
        );
      })
      .catch((error) => {
        enhanceErrorWithMessage(error, ErrorMessages.DATA_LOAD_ERROR);

        commit(PrivacyRequestMutations.SET_STATUS, ListStatus.Failed);
        return dispatch(RootActions.ERROR, error, { root: true });
      });
  },
};

export default actions;
