import { isObject, isNull, isBoolean, isNumber, isString, isArray } from '@/utils/is';

const snakeCase = (val: string) => {
  return val
    .split(/(?=[A-Z])/)
    .join('_')
    .toLowerCase();
};

const isPrimitiveType = (value: any): boolean => {
  return isNull(value) || isBoolean(value) || isNumber(value) || isString(value);
};

const toJSON = (props: any[] | { [key: string]: any }, context: toJSONContext) => {
  const isPropsArray = isArray(props);
  const json = isPropsArray ? [] : Object.create(null);
  const keys = Object.keys(props);

  for (const key of keys) {
    if (key === 'prototype' || key[0] === '_') {
      continue;
    }

    const value = props[key];
    const outKey = snakeCase(key);

    if (value instanceof JSONModel) {
      json[outKey] = value.toJSON(context);
    } else if (isPrimitiveType(value)) {
      json[outKey] = value;
    } else if (isArray(value) || isObject(value)) {
      json[outKey] = toJSON(value, context);
    } else {
      json[outKey] = null;
    }
  }

  return json;
};

export type toJSONContext = 'server' | 'clone';

export abstract class JSONModel<SourceType, OutputType = SourceType> {
  public static fromJSON<SourceType, Model extends JSONModel<SourceType, OutputType>, OutputType = SourceType>(
    this: new () => Model,
    json: SourceType,
  ): Model {
    const obj = new this();
    obj.fromJSON(json);

    return obj;
  }

  public static clone<
    SourceType,
    Model extends JSONModel<SourceType, OutputType>,
    SourceModel extends JSONModel<SourceType, OutputType>,
    OutputType = SourceType,
  >(this: new () => Model, source: SourceModel): Model {
    const json = source.toJSON('clone');
    const obj = new this();
    obj.fromJSON(json);

    return obj;
  }

  protected _alias: { [key: string]: string } = {};
  protected _clientProperties: string[] = [];

  protected get _aliasInverse() {
    const out: { [key: string]: string } = {};
    const keys = Object.keys(this._alias);

    for (const key of keys) {
      out[this._alias[key]] = key;
    }

    return out;
  }

  public abstract fromJSON(json: SourceType): void;

  public toJSON(context: 'clone'): SourceType;
  public toJSON(context?: 'server'): OutputType;
  public toJSON(context: toJSONContext): SourceType | OutputType;
  public toJSON(context: toJSONContext = 'server'): SourceType | OutputType {
    const props = Object.create(null);
    const keys = Object.getOwnPropertyNames(this);

    for (const key of keys) {
      const keyAlias = this._aliasInverse[key];

      if (
        'server' === context &&
        (this._clientProperties.indexOf(key) !== -1 || this._clientProperties.indexOf(keyAlias) !== -1)
      ) {
        continue;
      }

      props[keyAlias ? keyAlias : key] = this[key];
    }

    return toJSON(props, context);
  }

  public clone() {
    const json = this.toJSON('clone');
    const obj: this = new (this.constructor as { new () })();
    obj.fromJSON(json as any as SourceType);

    return obj;
  }
}
