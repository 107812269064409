
import Vue from 'vue';
import { mapState, mapGetters, mapActions } from 'vuex';

import FormInput from '@/components/controls/form-input.vue';
import SubmitButton from '@/components/controls/submit-button.vue';
import NotificationBanner from '@/components/blocks/notification-banner.vue';
import Publish from '@/components/forms/publish.vue';
import ImageUploadControl from '@/components/controls/image-upload.vue';
import TranslationsControl from '@/components/controls/translations.vue';

import { TreatmentEnhancementStatus } from '@/store/modules/treatments/enhancement';
import { TreatmentActions } from '@/store/modules/treatments/types';

export default Vue.extend({
  name: 'EditEnhancementView',
  inject: {
    $validator: '$validator',
  },
  components: {
    FormInput,
    SubmitButton,
    NotificationBanner,
    Publish,
    ImageUploadControl,
    TranslationsControl,
  },
  props: {
    add: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapState('treatments', {
      enhancement: 'enhancement',
      editableStatus: 'enhancementStatus',
      translationsStatus: 'enhancementTranslationsStatus',
    }),
    ...mapGetters('treatments', {
      isLoading: 'enhancementIsLoading',
      isSaving: 'enhancementIsSaving',
      hasUnsavedChanges: 'enhancementHasUnsavedChanges',
    }),
    isFetching() {
      return this.isLoading || this.isSaving;
    },
    isInactive() {
      return this.enhancement.status === TreatmentEnhancementStatus.Inactive;
    },
  },
  methods: {
    ...mapActions('treatments', {
      updateEnhancement: TreatmentActions.UPDATE_ENHANCEMENT,
      save: TreatmentActions.SAVE_ENHANCEMENT,
      removeEnhancement: TreatmentActions.DELETE_ENHANCEMENT,
      restoreEnhancement: TreatmentActions.RESTORE_ENHANCEMENT,
      saveTranslations: TreatmentActions.SAVE_ENHANCEMENT_TRANSLATIONS,
    }),
    remove() {
      this.removeEnhancement({ id: this.enhancement.id });
    },
    restore() {
      this.restoreEnhancement({ id: this.enhancement.id });
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.hasUnsavedChanges) {
      const answer = window.confirm(this.$t('Do you really want to leave? You have unsaved changes!'));

      if (!answer) {
        next(false);
        return;
      }
    }

    next();
  },
});
