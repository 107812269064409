import { JSONModel } from '@/store/models/jsonModel';
import { createCounter } from '@/store/utils/counter';

const getLocalLinkId = createCounter();

export interface ResourceLinkJSON {
  id: number;
  title: string;
  url: string;
  created_at: string;
  updated_at: string;
  trainings_weekly_count: number;
  trainings_total_count: number;
  training_complete: boolean;
  weight: number;
}

export default class ResourceLink extends JSONModel<ResourceLinkJSON> {
  public id: number = 0;
  public localId = getLocalLinkId();
  public title = '';
  public url = '';
  public alt = '';
  public createdAt = '';
  public updatedAt = '';
  public trainingsWeeklyCount = 0;
  public trainingsTotalCount = 0;
  public trainingComplete = false;
  public weight = 0;

  protected _clientProperties = ['localId'];

  public fromJSON(json: Partial<ResourceLinkJSON>) {
    if (json.id) {
      this.id = Number(json.id);
    }

    if (json.title) {
      this.title = String(json.title);
    }

    if (json.url) {
      this.url = String(json.url);
    }

    if (json.created_at) {
      this.createdAt = String(json.created_at);
    }

    if (json.updated_at) {
      this.updatedAt = String(json.updated_at);
    }

    if (json.trainings_total_count) {
      this.trainingsTotalCount = Number(json.trainings_total_count);
    }

    if (json.trainings_weekly_count) {
      this.trainingsWeeklyCount = Number(json.trainings_weekly_count);
    }

    if (json.training_complete) {
      this.trainingComplete = json.training_complete === true ? true : false;
    }

    if (json.weight) {
      this.weight = Number(json.weight);
    }
  }
}
