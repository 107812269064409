/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'search': {
    width: 21,
    height: 21,
    viewBox: '0 0 21 21',
    data: '<path pid="0" d="M20.71 19.29l-5.54-5.54a8.53 8.53 0 10-1.42 1.42l5.54 5.54a1 1 0 001.42 0 1 1 0 000-1.42zM2 8.5A6.5 6.5 0 118.5 15 6.51 6.51 0 012 8.5z"/>'
  }
})
