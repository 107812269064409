import plur from 'plur';

import httpClient from '../http-client';
import { Paginated, RequestConfig } from '../types';

type ListFunctionType<ListParams> = ListParams extends {} | undefined
  ? (params?: ListParams | undefined, config?: RequestConfig) => any
  : (params: ListParams, config?: RequestConfig) => any;

export function createCRUDEndpoints<
  ModelJSON extends Record<string, any>,
  ListParams = {},
  IdentityKey extends keyof ModelJSON = 'id',
  ListJSON = Paginated<ModelJSON[]>,
  RestoreWithData extends Partial<Omit<ModelJSON, IdentityKey>> = Partial<Omit<ModelJSON, IdentityKey>>,
>(options: {
  slug: string | { base: string; list?: string; get?: string; store?: string; remove?: string };
  identityKey?: IdentityKey;
  restoreWithData?: RestoreWithData;
}) {
  const slugBase = typeof options.slug === 'string' ? options.slug : options.slug.base;

  const listEndpoint =
    typeof options.slug === 'string' || typeof options.slug.list === 'undefined'
      ? plur(slugBase, 2)
      : options.slug.list;
  const getEndpoint =
    typeof options.slug === 'string' || typeof options.slug.get === 'undefined' ? slugBase : options.slug.get;
  const storeEndpoint =
    typeof options.slug === 'string' || typeof options.slug.store === 'undefined' ? slugBase : options.slug.store;
  const removeEndpoint =
    typeof options.slug === 'string' || typeof options.slug.remove === 'undefined' ? slugBase : options.slug.remove;

  const list = (...args: Parameters<ListFunctionType<ListParams>>) => {
    return httpClient.get<ListJSON>(listEndpoint, { params: args[0], ...args[1] });
  };

  const get = (id: Required<ModelJSON[IdentityKey]>, config?: RequestConfig) => {
    return httpClient.get<ModelJSON>(`${getEndpoint}/${id}`, config);
  };

  const store = (
    { id, ...data }: Omit<ModelJSON, IdentityKey> & { id?: ModelJSON[IdentityKey] },
    config?: RequestConfig,
  ) => {
    return httpClient.post<ModelJSON>(id ? `${storeEndpoint}/${id}` : storeEndpoint, { id, ...data }, config);
  };

  const remove = (id: Required<ModelJSON[IdentityKey]>, config?: RequestConfig) => {
    return httpClient.delete<ModelJSON>(`${removeEndpoint}/${id}`, config);
  };

  const restore = async (id: Required<ModelJSON[IdentityKey]>, config?: RequestConfig) => {
    const res = await get(id, config);
    const restoreData = options.restoreWithData || { status: 1 };

    return store({ ...res.data, ...restoreData }, config);
  };

  return { list, get, store, remove, restore };
}
