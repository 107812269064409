<template>
  <div class="c-main">
    <div class="o-container-fluid">
      <div class="o-row u-justify-center">
        <div class="o-col-8@md">
          <article class="c-entry">
            <div class="c-entry__headline c-divider">
              <div class="c-entry__container@md">
                <div class="c-entry__group">
                  <h1 class="c-entry-title">{{ post.title }}</h1>
                  <blog-post-meta :post="post" />
                </div>
                <div class="c-entry__author c-entry__item--shift">
                  <div class="c-user__item">
                    <span class="c-user__avatar" v-if="post.author.image"
                      ><img :src="post.author.image" :alt="post.author.name"
                    /></span>
                    <div class="c-user__content">
                      <p class="u-text--small">{{ $t('WRITTEN BY') }}</p>
                      <p>
                        <router-link
                          :to="{
                            name: 'blog-author',
                            params: { author: post.author.slug },
                          }"
                          >{{ post.author.name }}</router-link
                        >
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <figure v-if="post.featuredImage">
              <img :src="post.featuredImage" :alt="post.title" />
            </figure>
            <div class="c-entry__content" v-html="post.body"></div>
          </article>
        </div>
        <div class="o-col-8@md c-card-vertical" v-if="post.relatedPosts.length">
          <hr />
          <h4>{{ $t('Related Articles') }}</h4>
          <div class="o-row">
            <div class="o-col-4@sm" v-for="relatedPost in post.relatedPosts" :key="relatedPost.id">
              <blog-list-item related :post="relatedPost" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { mapState } from 'vuex';
import BlogPostMeta from '@/components/blocks/blog-post-meta.vue';
import BlogListItem from '@/components/blocks/blog-list-item.vue';

export default Vue.extend({
  name: 'BlogSingleView',
  components: {
    BlogPostMeta,
    BlogListItem,
  },
  computed: {
    ...mapState('blog', ['post']),
  },
});
</script>
