import Taxonomy, { TaxonomyJSON } from '@/store/models/taxonomy';
import { JSONModel } from '@/store/models/jsonModel';
import ProductRelationship, { ProductRelationshipJSON } from '@/store/modules/products/product-relationship';

export enum TreatmentStatus {
  Inactive = 0,
  Active = 1,
}

export interface TreatmentJSON {
  id: number;
  status: number;
  title?: string;
  description?: string;
  accessories?: TaxonomyJSON[];
  accessories_count: number;
  products?: ProductRelationshipJSON[];
  multiple_products?: boolean;
  products_count: number;
  assets_count: number;
  translations: {
    title: Record<string, string>;
    description: Record<string, string>;
  };
}

const availableStatuses = Object.keys(TreatmentStatus).map((key) => TreatmentStatus[key]);

export default class Treatment extends JSONModel<TreatmentJSON> {
  public id: number = 0;
  public status: TreatmentStatus = TreatmentStatus.Inactive;
  public title: string = '';
  public description: string = '';
  public accessories: Taxonomy[] = [];
  public accessoriesCount: number = 0;
  public products: Taxonomy[] = [];
  public productsCount: number = 0;
  public assetsCount: number = 0;
  public multipleProducts: boolean = false;
  public translations: {
    title: Record<string, string>;
    description: Record<string, string>;
  } = { title: {}, description: {} };
  protected _clientProperties = ['translations'];

  public fromJSON(json: Partial<TreatmentJSON>) {
    this.id = Number(json.id);

    if (typeof json.status !== 'undefined' && availableStatuses.indexOf(json.status) !== -1) {
      this.status = json.status;
    }

    if (json.title) {
      this.title = String(json.title);
    }

    if (json.description) {
      this.description = String(json.description);
    }

    if (json.accessories) {
      this.accessories = json.accessories.map((item) => Taxonomy.fromJSON(item));
    }

    if (json.products) {
      this.products = json.products.map((item) => ProductRelationship.fromJSON(item));
    }

    if (json.accessories_count) {
      this.accessoriesCount = Number(json.accessories_count);
    }

    if (json.products_count) {
      this.productsCount = Number(json.products_count);
    }

    if (json.assets_count) {
      this.assetsCount = Number(json.assets_count);
    }

    if (json.multiple_products) {
      this.multipleProducts = Boolean(json.multiple_products);
    }

    if (json.translations) {
      this.translations = json.translations;
    }

    return this;
  }
}
