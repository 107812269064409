import { createCRUDEndpoints } from '@/api/factory/crud';
import { createTranslateEndpoint } from '@/api/factory/translate';
import Practice from '@/store/modules/practices/practice';
import { TreatmentEnhancementJSON, TreatmentEnhancementStatus } from '@/store/modules/treatments/enhancement';
import httpClient from '../http-client';
import { RequestConfig } from '../types';

type ListParams =
  | {
      title?: string;
      search?: string;
      status?: string | number | TreatmentEnhancementStatus;
      page?: string | number;
      sort?: string;
      direction?: 'asc' | 'desc';
    }
  | undefined;

export const { list, get, store, remove, restore } = createCRUDEndpoints<TreatmentEnhancementJSON, ListParams>({
  slug: 'enhancement',
});

export const translate = createTranslateEndpoint<TreatmentEnhancementJSON>({ slug: 'enhancement' });

export function listTree(params?: { practice_id?: Practice['id'] }, config?: RequestConfig) {
  return httpClient.get<TreatmentEnhancementJSON[]>('enhancements/tree', { params, ...config });
}
