
import Vue from 'vue';
import { mapState, mapGetters } from 'vuex';
import { diff_match_patch as DiffMatchPatch, DIFF_DELETE, DIFF_INSERT, Diff } from 'diff-match-patch';

import { formatDate } from '@/i18n';

import i18n from '@/components/i18n';
import TextDiff from '@/components/blocks/text-diff.vue';

export default Vue.extend({
  name: 'LocationReviewForm',
  inject: {
    $validator: '$validator',
  },
  components: {
    TextDiff,
    i18n,
  },
  props: {
    explanation: {
      type: String,
      required: true,
    },
    isDeclining: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dmpInstance: new DiffMatchPatch(),
    };
  },
  computed: {
    ...mapState('locations', {
      latest: 'quickReviewLatestEditable',
      published: 'quickReviewPublishedEditable',
    }),
    ...mapGetters('locations', {
      isLoading: 'quickReviewIsLoading',
    }),
    titleDiffs(): Diff[] {
      if (!this.isLoading && this.published && this.latest) {
        const diff = this.dmpInstance.diff_main(this.published.title, this.latest.title);
        this.dmpInstance.diff_cleanupSemantic(diff);

        return diff;
      }

      return [];
    },
    publishedTitle() {
      return (this.titleDiffs as Diff[]).filter(([type, text]) => type !== DIFF_INSERT);
    },
    latestTitle() {
      return (this.titleDiffs as Diff[]).filter(([type, text]) => type !== DIFF_DELETE);
    },
    descriptionDiffs(): Diff[] {
      if (!this.isLoading && this.published && this.latest) {
        const diff = this.dmpInstance.diff_main(this.published.description, this.latest.description);
        this.dmpInstance.diff_cleanupSemantic(diff);

        return diff;
      }

      return [];
    },
    publishedDescription() {
      return (this.descriptionDiffs as Diff[]).filter(([type, text]) => type !== DIFF_INSERT);
    },
    latestDescription() {
      return (this.descriptionDiffs as Diff[]).filter(([type, text]) => type !== DIFF_DELETE);
    },
    explanationFieldError() {
      return this.errors.items.find((item) => item.field === 'explanation');
    },
    explanationFieldErrorRule(): string {
      if (!this.explanationFieldError || !this.explanationFieldError.rule) {
        return '';
      } else {
        return this.explanationFieldError.rule;
      }
    },
    explanationFieldErrorMsg(): string {
      if (!this.explanationFieldError || !this.explanationFieldError.msg) {
        return '';
      } else {
        return this.explanationFieldError.msg.replace('explanation', this.$t('Explanation'));
      }
    },
    showExplanationFieldError(): boolean {
      return Boolean(this.explanationFieldError);
    },
  },
  methods: {
    formatDate,
  },
});
