/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'gear': {
    width: 14.87,
    height: 15,
    viewBox: '0 0 14.87 15',
    data: '<g data-name="Layer 2"><g data-name="Isolation Mode"><path pid="0" d="M7.44 10.5a3 3 0 113-3 3 3 0 01-3 3zm0-5a2 2 0 102 2 2 2 0 00-2-2z"/><path pid="1" d="M9.19 15h-3.5a.5.5 0 01-.5-.5v-1.44a6 6 0 01-1.44-.83l-1.25.72a.5.5 0 01-.68-.18l-1.75-3a.5.5 0 01.18-.68l1.25-.76a5.93 5.93 0 010-1.66L.25 5.95a.5.5 0 01-.18-.68l1.75-3a.5.5 0 01.68-.18l1.25.72a5.92 5.92 0 011.44-.83V.5a.5.5 0 01.5-.5h3.5a.5.5 0 01.5.5v1.44a6 6 0 011.44.83l1.25-.72a.5.5 0 01.68.18l1.75 3a.5.5 0 01-.18.68l-1.25.72a5.92 5.92 0 010 1.66l1.25.72a.5.5 0 01.18.68l-1.75 3a.5.5 0 01-.68.18l-1.25-.72a5.94 5.94 0 01-1.44.83v1.52a.5.5 0 01-.5.5zm-3-1h2.5v-1.29a.5.5 0 01.31-.48 5 5 0 001.71-1 .5.5 0 01.58-.06l1.12.65 1.25-2.17L12.57 9a.5.5 0 01-.24-.53 4.94 4.94 0 000-2 .5.5 0 01.24-.47l1.12-.65-1.25-2.18-1.12.65a.5.5 0 01-.58-.06 5 5 0 00-1.74-1 .5.5 0 01-.34-.47V1H6.19v1.29a.5.5 0 01-.34.47 4.94 4.94 0 00-1.71 1 .5.5 0 01-.58.06l-1.13-.65-1.25 2.16L2.3 6a.5.5 0 01.24.53 5 5 0 000 2A.5.5 0 012.3 9l-1.12.65 1.25 2.17 1.12-.64a.5.5 0 01.58.06 5 5 0 001.72 1 .5.5 0 01.34.47z"/></g></g>'
  }
})
