
import Vue from 'vue';
import { mapState, mapGetters, mapActions } from 'vuex';
import Tabs from '@/components/layout/tabs.vue';
import { ConsultationsActions } from '@/store/modules/consultations/types';

export default Vue.extend({
  name: 'ConsultationsWidget',
  components: {
    Tabs,
  },
  data() {
    return {
      completingLessQualifedNum: 0,
    };
  },
  computed: {
    ...mapState('consultations', {
      consultations: 'list',
      pagination: 'listPagination',
      userStats: 'userStats',
      lessQualified: 'lessQualifiedList',
      lessQualifiedPagination: 'lessQualifiedListPagination',
    }),
    ...mapGetters('consultations', [
      'listIsLoaded',
      'listIsEmpty',
      'lessQualifiedListIsLoaded',
      'lessQualifiedListIsEmpty',
      'userStatsIsLoaded',
    ]),
    shouldShowCallsStats() {
      if (!this.userStatsIsLoaded) {
        return false;
      }

      if (!this.userStats.calls || !this.userStats.calls.total) {
        return false;
      }

      return true;
    },
    callsStatsMessage() {
      if (this.userStats.calls.month) {
        return this.$t('This year you had %(total)% qualified calls, %(month)%', {
          total: `<strong>${this.userStats.calls.total}</strong>`,
          month: `<strong>${this.userStats.calls.month}</strong>`,
          interpolation: { escapeValue: false },
        });
      }

      return this.$t('This year you had %(total)% qualified calls.', {
        total: `<strong>${this.userStats.calls.total}</strong>`,
        interpolation: { escapeValue: false },
      });
    },
    tabs() {
      const out: Record<string, { title: string; count?: number }> = {};

      if (this.listIsLoaded && !this.listIsEmpty) {
        out.leads = { title: this.$t('Leads'), count: this.pagination.total };
      }

      if (this.lessQualifiedListIsLoaded && !this.lessQualifiedListIsEmpty) {
        out.less = { title: this.$t('Less Qualified'), count: this.lessQualifiedPagination.total };
      }

      return out;
    },
  },
  methods: {
    ...mapActions('consultations', {
      completeLessQualified: ConsultationsActions.COMPLETE_LESS_QUALIFIED,
      reloadLessQualified: ConsultationsActions.LOAD_LESS_QUALIFIED_LIST,
    }),
    checkLessQualifiedComplete(id: number) {
      this.completingLessQualifedNum = Math.max(0, this.completingLessQualifedNum) + 1;
      this.completeLessQualified({ id }).then(() => {
        this.completingLessQualifedNum = Math.max(1, this.completingLessQualifedNum) - 1;

        if (this.completingLessQualifedNum === 0) {
          this.reloadLessQualified();
        }
      });
    },
  },
});
