import { createCRUDEndpoints } from '@/api/factory/crud';
import { ConsultationRequestJSON } from '@/store/modules/consultations/consultation-request';

type ListParams =
  | {
      qualified?: boolean;
      practice_id?: number;
      search?: string;
      status?: string | number;
      page?: string | number;
      sort?: string;
      direction?: 'asc' | 'desc';
    }
  | undefined;

export const { list, get, store, remove, restore } = createCRUDEndpoints<ConsultationRequestJSON, ListParams>({
  slug: 'consultation',
});
