import { GetterTree } from 'vuex';

import { RootState, ListStatus } from '@/store/types';
import { createEditableGetters } from '@/store/utils/editable';

import { UsersState } from './types';

const {
  isLoading: editableIsLoading,
  isLoaded: editableIsLoaded,
  isSaving: editableIsSaving,
  isSaved: editableIsSaved,
  isFailed: editableIsFailed,
  isFetching: editableIsFetching,
  hasUnsavedChanges: editableHasUnsavedChanges,
} = createEditableGetters<UsersState>('editableStatus', 'editable', 'initialEditable');

export const moduleGetters: GetterTree<UsersState, RootState> = {
  /**
   * List
   */
  listIsLoading: (state) => state.listStatus === ListStatus.Loading,
  listIsLoaded: (state) => state.listStatus === ListStatus.Loaded,
  listIsError: (state) => state.listStatus === ListStatus.Failed,
  listIsEmpty: (state, getters) => getters.listIsLoaded && !state.list.length,

  /**
   * Edit
   */
  editableIsLoading,
  editableIsLoaded,
  editableIsSaving,
  editableIsSaved,
  editableIsFailed,
  editableHasUnsavedChanges,
  editableIsFetching,
  editablePracticeId: (state) => (state.editable && state.editable.practice ? state.editable.practice.id : 0),
};

export default moduleGetters;
