import { GetterTree } from 'vuex';

import { RootState, ListStatus } from '@/store/types';
import { TreatmentState } from '@/store/modules/treatments/types';
import { createEditableGetters } from '@/store/utils/editable';

const {
  isLoading: editableIsLoading,
  isLoaded: editableIsLoaded,
  isSaving: editableIsSaving,
  isSaved: editableIsSaved,
  isFailed: editableIsFailed,
  isFetching: editableIsFetching,
  hasUnsavedChanges: editableHasUnsavedChanges,
} = createEditableGetters<TreatmentState>('editableStatus', 'editable', 'initialEditable');

const {
  isLoading: enhancementIsLoading,
  isLoaded: enhancementIsLoaded,
  isSaving: enhancementIsSaving,
  isSaved: enhancementIsSaved,
  isFailed: enhancementIsFailed,
  isFetching: enhancementIsFetching,
  hasUnsavedChanges: enhancementHasUnsavedChanges,
} = createEditableGetters<TreatmentState>('enhancementStatus', 'enhancement', 'initialEnhancement');

const {
  isLoading: publicTreatmentIsLoading,
  isLoaded: publicTreatmentIsLoaded,
  isSaving: publicTreatmentIsSaving,
  isSaved: publicTreatmentIsSaved,
  isFailed: publicTreatmentIsFailed,
  isFetching: publicTreatmentIsFetching,
  hasUnsavedChanges: publicTreatmentHasUnsavedChanges,
} = createEditableGetters<TreatmentState>('publicTreatmentStatus', 'publicTreatment', 'initialPublicTreatment');

const {
  isLoading: publicSiteIsLoading,
  isLoaded: publicSiteIsLoaded,
  isSaving: publicSiteIsSaving,
  isSaved: publicSiteIsSaved,
  isFailed: publicSiteIsFailed,
  isFetching: publicSiteIsFetching,
  hasUnsavedChanges: publicSiteHasUnsavedChanges,
} = createEditableGetters<TreatmentState>('publicSiteStatus', 'publicSite', 'initialPublicSite');

export const moduleGetters: GetterTree<TreatmentState, RootState> = {
  /**
   * List
   */
  listIsLoading: (state) => state.listStatus === ListStatus.Loading,
  listIsLoaded: (state) => state.listStatus === ListStatus.Loaded,
  listIsError: (state) => state.listStatus === ListStatus.Failed,
  listIsEmpty: (state, getters) => getters.listIsLoaded && !state.list.length,

  /**
   * Edit
   */
  editableIsLoading,
  editableIsLoaded,
  editableIsSaving,
  editableIsSaved,
  editableIsFailed,
  editableIsFetching,
  editableHasUnsavedChanges,

  /**
   * Tree
   */
  treatmentsTreeIsLoading: (state) => (state.treatmentsTreeStatus === ListStatus.Loading ? true : false),
  treatmentsTreeIsLoaded: (state) => (state.treatmentsTreeStatus === ListStatus.Loaded ? true : false),
  treatmentsTreeIsError: (state) => (state.treatmentsTreeStatus === ListStatus.Failed ? true : false),
  treatmentsTreeIsEmpty: (state) =>
    state.treatmentsTreeStatus === ListStatus.Loaded && state.treatmentsTree.length === 0,
  allTreatmentsIsLoading: (state) => (state.allTreatmentsStatus === ListStatus.Loading ? true : false),
  allTreatmentsIsLoaded: (state) => (state.allTreatmentsStatus === ListStatus.Loaded ? true : false),
  allTreatmentsIsError: (state) => (state.allTreatmentsStatus === ListStatus.Failed ? true : false),
  allTreatmentsIsEmpty: (state) => state.allTreatmentsStatus === ListStatus.Loaded && state.allTreatments.length === 0,

  /**
   * Enhancements list
   */
  enhancementListIsLoading: (state) => state.enhancementListStatus === ListStatus.Loading,
  enhancementListIsLoaded: (state) => state.enhancementListStatus === ListStatus.Loaded,
  enhancementListIsFailed: (state) => state.enhancementListStatus === ListStatus.Failed,
  enhancementListIsEmpty: (state) =>
    state.enhancementListStatus === ListStatus.Loaded && state.enhancementList.length === 0,

  /**
   * Enhancements Edit
   */
  enhancementIsLoading,
  enhancementIsLoaded,
  enhancementIsSaving,
  enhancementIsSaved,
  enhancementIsFailed,
  enhancementIsFetching,
  enhancementHasUnsavedChanges,

  /**
   * Public Treatments list
   */
  publicTreatmentListIsLoading: (state) => state.publicTreatmentListStatus === ListStatus.Loading,
  publicTreatmentListIsLoaded: (state) => state.publicTreatmentListStatus === ListStatus.Loaded,
  publicTreatmentListIsFailed: (state) => state.publicTreatmentListStatus === ListStatus.Failed,
  publicTreatmentListIsEmpty: (state) =>
    state.publicTreatmentListStatus === ListStatus.Loaded && state.publicTreatmentList.length === 0,

  /**
   * Public Treatments Edit
   */
  publicTreatmentIsLoading,
  publicTreatmentIsLoaded,
  publicTreatmentIsSaving,
  publicTreatmentIsSaved,
  publicTreatmentIsFailed,
  publicTreatmentIsFetching,
  publicTreatmentHasUnsavedChanges,

  /**
   * Public Sites list
   */
  publicSiteListIsLoading: (state) => state.publicSiteListStatus === ListStatus.Loading,
  publicSiteListIsLoaded: (state) => state.publicSiteListStatus === ListStatus.Loaded,
  publicSiteListIsFailed: (state) => state.publicSiteListStatus === ListStatus.Failed,
  publicSiteListIsEmpty: (state) =>
    state.publicSiteListStatus === ListStatus.Loaded && state.publicSiteList.length === 0,

  /**
   * Public Site Edit
   */
  publicSiteIsLoading,
  publicSiteIsLoaded,
  publicSiteIsSaving,
  publicSiteIsSaved,
  publicSiteIsFailed,
  publicSiteIsFetching,
  publicSiteHasUnsavedChanges,
};

export default moduleGetters;
