import 'url-polyfill';
import config from '@/config';

export type URLParamsArray = { key: string; value?: string }[];
export type URLParamsObject = { [key: string]: string };

export const convertToURLParamsArray = (params: { [key: string]: string }): URLParamsArray => {
  return Object.entries(params).map(([key, value]) => ({ key, value }));
};

export const addURLParams = (url: string, params: URLParamsArray | URLParamsObject) => {
  const paramsArray = Array.isArray(params) ? params : convertToURLParamsArray(params);

  if (!paramsArray.length || !url.length) {
    return url;
  }

  const obj = getURLObject(url);

  paramsArray.forEach(({ key, value }) => {
    if (!key) {
      return;
    }

    obj.searchParams.set(key, value ? value : '');
  });

  return obj.toString();
};

export const getURLObject = (url: string) => {
  if (url.indexOf('http://') !== 0 && url.indexOf('https://') !== 0) {
    if (url.indexOf('/api') === 0) {
      return new URL(`${config.API_HOSTNAME}${url.substring(4)}`);
    }

    return new URL(`${config.APP_URL}${url}`);
  }

  return new URL(url);
};
