import Vue from 'vue';
import { MutationTree } from 'vuex';

import { ListStatus, EditableStatus } from '@/store/types';
import Pagination from '@/store/models/pagination';
import { updateEditable } from '@/store/utils/editable';

import { EventsState, EventsMutations } from './types';
import Event from './event';

const mutations: MutationTree<EventsState> = {
  /**
   * List
   */
  [EventsMutations.SET_LIST](state, payload: Event[]) {
    state.list = payload;
  },
  [EventsMutations.SET_LIST_STATUS](state, payload: ListStatus) {
    state.listStatus = payload;
  },
  [EventsMutations.SET_LIST_PAGINATION](state, payload: Pagination) {
    state.listPagination = payload;
  },

  /**
   * Edit
   */
  [EventsMutations.SET_EDITABLE](state, event: Event) {
    Vue.set(state, 'editable', event);
    Vue.set(state, 'initialEditable', Event.clone(event));
  },
  [EventsMutations.SET_EDITABLE_STATUS](state, editableStatus: EditableStatus) {
    state.editableStatus = editableStatus;
  },
  [EventsMutations.UPDATE_EDITABLE]: updateEditable<EventsState>('editable'),
  [EventsMutations.UPDATE_INITIAL_EDITABLE]: updateEditable<EventsState>('initialEditable'),
};

export default mutations;
