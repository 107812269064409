
import Vue from 'vue';
import { mapGetters, mapActions } from 'vuex';

import { UserActions } from '@/store/modules/user/types';

import FormInput from '@/components/controls/form-input.vue';
import SubmitButton from '@/components/controls/submit-button.vue';

export default Vue.extend({
  name: 'LostPasswordForm',
  components: {
    FormInput,
    SubmitButton,
  },
  inject: {
    $validator: '$validator',
  },
  data() {
    return {
      email: '',
    };
  },
  computed: {
    ...mapGetters('user', ['isResetPending', 'isResetSuccess', 'isResetError']),
  },
  methods: {
    ...mapActions('user', {
      resetPasswordAction: UserActions.RESET_PASSWORD,
    }),
    resetPassword(): void {
      this.resetPasswordAction({
        email: this.email,
      });
    },
  },
});
