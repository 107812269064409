import actions from './actions';
import mutations from './mutations';
import getters from './getters';
import { Module } from 'vuex';
import { ResourcesState, ResourcesStatus } from '@/store/modules/resources/types';
import { RootState, EditableStatus, ListStatus } from '@/store/types';

export const getInitialState = (): ResourcesState => {
  return {
    /**
     * Featured list
     */
    featured: [],
    featuredStatus: ResourcesStatus.Initial,
    featuredFilters: [],

    /**
     * View
     */
    completeAssetTrainingStatus: ListStatus.Initial,

    /**
     * Edit
     */
    editableStatus: EditableStatus.Loading,
    /**
     * TODO: categorize resources initial state
     */
    recentlyViewed: [],
    recentlyViewedResources: [],
    recentlyViewedStatus: ResourcesStatus.Initial,
    resourcesList: [],
    categories: [],
    related: [],
    currentResource: undefined,
    listStatus: ResourcesStatus.Loading,
    currentStatus: ResourcesStatus.Loading,
    relatedStatus: ResourcesStatus.Loading,
    personalizeStatus: ResourcesStatus.Loading,
    pagination: undefined,
    isResourceAccessedDirectly: false,
    laserHighlightedItems: [],
    laserHighlightedStatus: ListStatus.Loading,
    laserCategoryId: undefined,
    trainingResourcesItems: [],
    trainingResourcesStatus: ListStatus.Loading,
  };
};

export const state: ResourcesState = getInitialState();

export const resources: Module<ResourcesState, RootState> = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

export default resources;
