
import Vue from 'vue';
import LostPasswordForm from '@/components/forms/lost-password.vue';
import NotificationBanner from '@/components/blocks/notification-banner.vue';

export default Vue.extend({
  name: 'ResetPasswordView',
  components: {
    LostPasswordForm,
    NotificationBanner,
  },
});
