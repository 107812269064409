/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'status': {
    width: 18.71,
    height: 12.8,
    viewBox: '0 0 18.71 12.8',
    data: '<g data-name="Layer 2"><g data-name="Isolation Mode"><path pid="0" d="M15.68 7.09a.5.5 0 01-.35-.15 8.39 8.39 0 00-6-2.47 8.39 8.39 0 00-6 2.47.5.5 0 01-.71-.71 9.39 9.39 0 016.74-2.76A9.38 9.38 0 0116 6.24a.5.5 0 01-.35.85z"/><path pid="1" d="M18.21 4.66a.5.5 0 01-.35-.15 12 12 0 00-17 0 .5.5 0 01-.71-.71 13 13 0 0118.42 0 .5.5 0 01-.35.85zM13.15 9.51a.5.5 0 01-.35-.15 4.87 4.87 0 00-6.89 0 .5.5 0 01-.71-.71 5.88 5.88 0 018.3 0 .5.5 0 01-.35.85z"/><path pid="2" d="M15.68 7.09a.5.5 0 01-.35-.15 8.39 8.39 0 00-6-2.47 8.39 8.39 0 00-6 2.47.5.5 0 01-.71-.71 9.38 9.38 0 016.74-2.76A9.38 9.38 0 0116 6.24a.5.5 0 01-.35.85z"/><path pid="3" d="M13.15 9.51a.5.5 0 01-.35-.15 4.88 4.88 0 00-6.89 0 .5.5 0 01-.71-.71 5.88 5.88 0 018.3 0 .5.5 0 01-.35.85z"/><circle pid="4" cx="9.36" cy="11.75" r="1.05"/></g></g>'
  }
})
