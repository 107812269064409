
import Vue from 'vue';
import { NotificationType } from '@/store/modules/notifications/types';

export default Vue.extend({
  name: 'NotificationBlock',
  props: {
    title: {
      type: String,
      default: '',
    },
    titleIcon: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: '',
    },
    type: {
      type: Number,
      default: NotificationType.Notice,
    },
    dismissible: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      isVisible: true,
    };
  },
  computed: {
    notificationClass(): string {
      switch (this.type) {
        case NotificationType.Error:
          return 'c-toolbar--error';

        case NotificationType.Warning:
          return 'c-toolbar--warning';

        case NotificationType.Notice:
          return 'c-toolbar--notice';

        case NotificationType.Success:
          return 'c-toolbar--success';
      }

      return 'c-toolbar--error';
    },
  },
  methods: {
    hide() {
      this.isVisible = false;

      this.$emit('hide');
    },
  },
});
