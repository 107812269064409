/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'percentage': {
    width: 14.25,
    height: 14.25,
    viewBox: '0 0 14.25 14.25',
    data: '<g data-name="Layer 2"><g data-name="Outline Icons"><path pid="0" d="M1.35 13.4a.5.5 0 01-.35-.85L12.55 1a.5.5 0 01.71.71L1.7 13.26a.5.5 0 01-.35.14z"/><path pid="1" d="M1.35 13.53a.63.63 0 01-.44-1.07L12.46.91a.63.63 0 11.88.88L1.79 13.34a.62.62 0 01-.44.19zM12.9 1a.37.37 0 00-.26.11L1.08 12.64a.37.37 0 000 .53.38.38 0 00.53 0L13.17 1.61A.38.38 0 0012.9 1zM2.79 5.46a2.67 2.67 0 112.67-2.67 2.67 2.67 0 01-2.67 2.67zm0-4.33a1.67 1.67 0 101.67 1.66 1.67 1.67 0 00-1.67-1.66z"/><path pid="2" d="M2.79 5.58a2.79 2.79 0 112.79-2.79 2.8 2.8 0 01-2.79 2.79zm0-5.33a2.54 2.54 0 102.54 2.54A2.54 2.54 0 002.79.25zm0 4.33a1.79 1.79 0 111.79-1.79 1.79 1.79 0 01-1.79 1.79zm0-3.33a1.54 1.54 0 101.54 1.54 1.54 1.54 0 00-1.54-1.54zM11.46 14.13a2.67 2.67 0 112.67-2.67 2.67 2.67 0 01-2.67 2.67zm0-4.33a1.67 1.67 0 101.67 1.67 1.67 1.67 0 00-1.67-1.68z"/><path pid="3" d="M11.46 14.25a2.79 2.79 0 112.79-2.79 2.79 2.79 0 01-2.79 2.79zm0-5.33A2.54 2.54 0 1014 11.46a2.54 2.54 0 00-2.54-2.54zm0 4.33a1.79 1.79 0 111.79-1.79 1.79 1.79 0 01-1.79 1.79zm0-3.33A1.54 1.54 0 1013 11.46a1.54 1.54 0 00-1.54-1.54z"/></g></g>'
  }
})
