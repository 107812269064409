
import Vue from 'vue';
import { mapGetters, mapActions } from 'vuex';
import SubmitButton from '@/components/controls/submit-button.vue';
import Dropdown, { DropdownItems } from '@/components/controls/dropdown.vue';
import { PublicTreatmentStatus } from '@/store/modules/treatments/public-treatment';
import { TreatmentActions } from '@/store/modules/treatments/types';

export default Vue.extend({
  name: 'PublicTreatmentsFilterForm',
  components: {
    SubmitButton,
    Dropdown,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    selectedItems: {
      type: Array,
      default: () => [] as number[],
    },
  },
  data() {
    return {
      availableStatuses: {
        [PublicTreatmentStatus.Inactive]: this.$t('Inactive'),
        [PublicTreatmentStatus.Active]: this.$t('Active'),
      },
      search: '',
      status: '',
    };
  },
  computed: {
    ...mapGetters('user/profile', ['isSuperAdmin']),
    dropdownItems() {
      const items: DropdownItems = {};
      items.reset = {
        label: this.$t('Reset Filters'),
        icon: 'close',
      };
      if ((this.isSuperAdmin || this.isAdmin) && this.selectedItems && this.selectedItems.length) {
        items.restore = {
          hasTopBorder: true,
          label: this.$t('Restore'),
          count: this.selectedItems.length,
          disabled: !this.selectedItems.length,
        };

        items.remove = {
          label: this.$t('Delete'),
          count: this.selectedItems.length,
          disabled: !this.selectedItems.length,
        };
      }
      return items;
    },
  },
  methods: {
    ...mapActions('treatments', {
      deleteAction: TreatmentActions.BULK_DELETE_PUBLIC_TREATMENT,
      restoreAction: TreatmentActions.BULK_RESTORE_PUBLIC_TREATMENT,
    }),
    initFilters(): void {
      this.search = this.$router.currentRoute.query.search || '';
      this.status = this.$router.currentRoute.query.status || '';
    },
    applyFilters(): void {
      const { sort, direction, ...queryParams } = this.$route.query;

      this.$router.push({
        params: {
          page: 1,
        },
        query: {
          ...queryParams,
          search: this.search,
          status: this.status,
        },
      });
    },
    handleDropdownClick(action: string): void {
      if ('reset' === action) {
        this.$router.push({
          params: {
            page: 1,
          },
          query: {},
        });
      }

      if ('remove' === action) {
        this.deleteAction({ ids: this.selectedItems });
      }

      if ('restore' === action) {
        this.restoreAction({ ids: this.selectedItems });
      }
    },
  },
  created(): void {
    this.initFilters();
  },
  watch: {
    $route(): void {
      this.initFilters();
    },
  },
});
