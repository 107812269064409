import moment from 'moment';
import { JSONModel } from '@/store/models/jsonModel';
import BlogTaxonomy, { BlogTaxonomyJSON } from './taxonomy';
import BlogAuthor, { BlogAuthorJSON } from './author';

export interface BlogPostJSON {
  id: number;
  title: string;
  slug: string;
  banner_image: string;
  featured_image: string;
  body?: string;
  teaser?: string;
  publication_date: number;
  author: BlogAuthorJSON;
  categories: BlogTaxonomyJSON[];
  products: BlogTaxonomyJSON[];
  related_posts: BlogPostJSON[];
}

export default class BlogPost extends JSONModel<BlogPostJSON> {
  public id: number = 0;
  public title: string = '';
  public slug: string = '';
  public bannerImage: string = '';
  public featuredImage: string = '';
  public body: string = '';
  public teaser: string = '';
  public publicationDate: number = 0;
  public author?: BlogAuthor;
  public categories: BlogTaxonomy[] = [];
  public products: BlogTaxonomy[] = [];
  public relatedPosts: BlogPost[] = [];

  public get date() {
    return moment(this.publicationDate, 'X').format('MMMM Do YYYY');
  }

  public fromJSON(json: Partial<BlogPostJSON>) {
    if (json.id) {
      this.id = Number(json.id);
    }

    if (json.title) {
      this.title = String(json.title);
    }

    if (json.slug) {
      this.slug = String(json.slug);
    }

    if (json.banner_image) {
      this.bannerImage = String(json.banner_image);
    }

    if (json.featured_image) {
      this.featuredImage = String(json.featured_image);
    }

    if (json.body) {
      this.body = String(json.body);
    }

    if (json.teaser) {
      this.teaser = String(json.teaser);
    }

    if (json.publication_date) {
      this.publicationDate = Number(json.publication_date);
    }

    if (json.author) {
      this.author = BlogAuthor.fromJSON(json.author);
    }

    if (json.categories && Array.isArray(json.categories) && json.categories.length) {
      this.categories = json.categories.map((item) => BlogTaxonomy.fromJSON(item));
    }

    if (json.products && Array.isArray(json.products) && json.products.length) {
      this.products = json.products.map((item) => BlogTaxonomy.fromJSON(item));
    }

    if (json.related_posts && Array.isArray(json.related_posts) && json.related_posts.length) {
      this.relatedPosts = json.related_posts.map((item) => BlogPost.fromJSON(item));
    }
  }
}
