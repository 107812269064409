import { Module } from 'vuex';

import { RootState, ListStatus, EditableStatus } from '@/store/types';

import { SettingsState } from './types';
import actions from './actions';
import mutations from './mutations';
import getters from './getters';
import Settings from './settings';

const getInitialState = (): SettingsState => ({
  settings: new Settings(),
  countries: [],
  emails: [],
  settingsStatus: ListStatus.Loading,
  editableSettings: undefined,
  editableSettingsStatus: EditableStatus.Loading,
  topBannerClosed: '',
  topBannerTranslationsStatus: EditableStatus.Loaded,
  legalTranslationsStatus: EditableStatus.Loaded,
  emailTranslationsStatus: EditableStatus.Loaded,
});

const state = getInitialState();

const module: Module<SettingsState, RootState> = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};

export default module;
