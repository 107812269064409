/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'logout': {
    width: 22,
    height: 22,
    viewBox: '0 0 22 22',
    data: '<path pid="0" d="M0 0v22h15v-6.5h-1V21H1V1h13v5.5h1V0zm17.05 7.46l.71-.7L22 11l-4.24 4.24-.71-.7 3-3H7v-1h13.09z"/>'
  }
})
