import Taxonomy, { TaxonomyJSON } from '@/store/models/taxonomy';
import { JSONModel } from '@/store/models/jsonModel';
import ProductRelationship, { ProductRelationshipJSON } from './product-relationship';

export enum AccessoryStatus {
  Inactive = 0,
  Active = 1,
}

export interface AccessoryJSON {
  id: number;
  status: number;
  title?: string;
  treatments?: TaxonomyJSON[];
  treatments_count: number;
  product?: ProductRelationshipJSON;
  translations: {
    title: Record<string, string>;
    description: Record<string, string>;
  };
}

const availableStatuses = Object.keys(AccessoryStatus).map((key) => AccessoryStatus[key]);

export default class Accessory extends JSONModel<AccessoryJSON> {
  public id: number = 0;
  public status: AccessoryStatus = AccessoryStatus.Inactive;
  public title: string = '';
  public treatments: Taxonomy[] = [];
  public treatmentsCount: number = 0;
  public product: ProductRelationship | null = null;
  public translations: {
    title: Record<string, string>;
    description: Record<string, string>;
  } = { title: {}, description: {} };
  protected _clientProperties = ['translations'];

  public fromJSON(json: Partial<AccessoryJSON>) {
    this.id = Number(json.id);

    if (typeof json.status !== 'undefined' && availableStatuses.indexOf(json.status) !== -1) {
      this.status = json.status;
    }

    if (json.title) {
      this.title = String(json.title);
    }

    if (json.treatments) {
      this.treatments = json.treatments.map((item) => Taxonomy.fromJSON(item));
    }

    if (json.treatments_count) {
      this.treatmentsCount = Number(json.treatments_count);
    }

    if (json.product) {
      this.product = ProductRelationship.fromJSON(json.product);
    }

    if (json.translations) {
      this.translations = json.translations;
    }

    return this;
  }
}
