import { ActionTree } from 'vuex';
import { setUser } from '@sentry/browser';

import i18next, { setLocale } from '@/i18n';
import api from '@/api';

import { ProfileState, ProfileMutations, ProfileActions, UserRole } from '@/store/modules/user/profile/types';
import { RootState, EventTypes, RootActions, ListStatus, EditableStatus } from '@/store/types';
import { NotificationsActions } from '@/store/modules/notifications/types';
import { UserActions, UserMutations } from '@/store/modules/user/types';
import { Profile, ProfileJSON } from './profile';
import blogApi from '@/store/utils/blog-api';

export const actions: ActionTree<ProfileState, RootState> = {
  [ProfileActions.LOAD_PROFILE]({ commit, getters, dispatch }): Promise<void> {
    if (getters.isLoading) {
      return Promise.resolve();
    }

    commit(ProfileMutations.SET_CURRENT_PROFILE_STATUS, ListStatus.Loading);
    commit(ProfileMutations.SET_EDITABLE_PROFILE_STATUS, EditableStatus.Loading);

    return api.auth
      .me()
      .then(async (response) => {
        const profile = Profile.fromJSON(response.data);

        setUser({
          email: profile.email,
          id: String(profile.id),
          extra: {
            isAdmin: profile.role === UserRole.SuperAdmin,
          },
        });

        await setLocale(profile.languageCode);

        return dispatch(ProfileActions.SET_PROFILE, profile);
      })
      .then(() => {
        commit(ProfileMutations.SET_CURRENT_PROFILE_STATUS, ListStatus.Loaded);
        commit(ProfileMutations.SET_EDITABLE_PROFILE_STATUS, EditableStatus.Loaded);
        return dispatch(RootActions.DISPATCH_EVENT, { type: EventTypes.SESSION }, { root: true });
      })
      .catch((error) => {
        commit(ProfileMutations.SET_CURRENT_PROFILE_STATUS, ListStatus.Failed);
        commit(ProfileMutations.SET_EDITABLE_PROFILE_STATUS, EditableStatus.Failed);
        return dispatch(RootActions.ERROR, error, { root: true });
      });
  },
  [ProfileActions.SAVE_PROFILE]({ state, commit, getters, dispatch }) {
    if (getters.isSaving || !state.editableProfile) {
      return Promise.resolve();
    }

    const { first_name, last_name, email, image_id, password, language_code } = state.editableProfile.toJSON('server');

    commit(ProfileMutations.SET_EDITABLE_PROFILE_STATUS, EditableStatus.Saving);
    return api.auth
      .updateMe({ first_name, last_name, email, image_id, password, language_code })
      .then(async (response) => {
        const profile = Profile.fromJSON(response.data);
        await dispatch(RootActions.LOAD_LANGUAGES, { force: true }, { root: true });
        await setLocale(profile.languageCode);

        setUser({
          email: profile.email,
          id: String(profile.id),
          extra: {
            isAdmin: profile.role === UserRole.SuperAdmin,
          },
        });

        blogApi.setBlogLang(profile);

        return dispatch(ProfileActions.SET_PROFILE, profile);
      })
      .then(() => {
        commit(ProfileMutations.SET_EDITABLE_PROFILE_STATUS, EditableStatus.Saved);

        return dispatch(
          'notifications/' + NotificationsActions.SHOW_SUCCESS,
          {
            body: i18next.t('Your profile was successfully updated.'),
          },
          { root: true },
        );
      })
      .catch((error) => {
        commit(ProfileMutations.SET_EDITABLE_PROFILE_STATUS, EditableStatus.Failed);
        return dispatch(RootActions.ERROR, error, { root: true });
      });
  },
  [ProfileActions.UPDATE_EDITABLE_PROFILE]({ commit }, profile: Partial<ProfileJSON>): void {
    commit(ProfileMutations.UPDATE_EDITABLE_PROFILE, profile);
  },
  async [ProfileActions.SET_PROFILE]({ commit }, profile: Profile) {
    commit(ProfileMutations.SET_CURRENT_PROFILE, profile);
    commit(ProfileMutations.SET_EDITABLE_PROFILE, profile.clone());
  },
  [ProfileActions.SAVE_TERMS_AGREE]({ state, getters, commit, dispatch }, termsAgree: boolean): Promise<void> {
    if (!getters.isLoaded) {
      return Promise.resolve();
    }

    const newValue = termsAgree ? 1 : 0;
    const shouldUpdateAPI = newValue !== state.currentProfile.termsAgree;

    commit(ProfileMutations.SET_TERMS_AGREE, newValue);

    if (!shouldUpdateAPI) {
      if (!newValue) {
        return dispatch('user/' + UserActions.LOGOUT, undefined, {
          root: true,
        });
      }

      return Promise.resolve();
    }

    // Show spinner, because we are waiting for profile update request
    if (!newValue) {
      window.loader(true);
      commit('user/' + UserMutations.LOGOUT_PENDING, undefined, { root: true });
    }

    return api.auth
      .updateMe({ terms_agree: newValue })
      .then((response) => {
        const profile = Profile.fromJSON(response.data);

        setUser({
          email: profile.email,
          id: String(profile.id),
          extra: {
            isAdmin: profile.role === UserRole.SuperAdmin,
          },
        });

        return dispatch(ProfileActions.SET_PROFILE, profile);
      })
      .then(() => {
        if (!newValue) {
          return dispatch('user/' + UserActions.LOGOUT, undefined, {
            root: true,
          });
        }

        commit(ProfileMutations.SET_TERMS_AGREE, newValue);
      })
      .catch((error) => {
        return dispatch(RootActions.ERROR, error, { root: true });
      });
  },
  async [ProfileActions.SHOW_TERMS_AGREE_MODAL]({ commit }) {
    commit(ProfileMutations.SHOW_TERMS_AGREE_MODAL);
  },
  [ProfileActions.SET_LANGUAGE]({ state, commit, dispatch }, locale) {
    if (state.currentProfile.languageCode === locale) {
      return Promise.resolve();
    }

    commit(ProfileMutations.SET_EDITABLE_PROFILE_STATUS, EditableStatus.Saving);

    return api.auth
      .updateMe({ language_code: locale })
      .then((response) => {
        const profile = Profile.fromJSON(response.data);

        setUser({
          email: profile.email,
          id: String(profile.id),
          extra: {
            isAdmin: profile.role === UserRole.SuperAdmin,
          },
        });

        return dispatch(ProfileActions.SET_PROFILE, profile);
      })
      .then(() => {
        commit(ProfileMutations.SET_EDITABLE_PROFILE_STATUS, EditableStatus.Saved);
      })
      .catch((error) => {
        commit(ProfileMutations.SET_EDITABLE_PROFILE_STATUS, EditableStatus.Failed);
        return dispatch(RootActions.ERROR, error, { root: true });
      });
  },
};

export default actions;
