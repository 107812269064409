type Ranges = [number, number][];
type ContentWithHighlightedRanges = { value: string; highlight: boolean }[];

const getHighlightWordRanges = (value: string, highlightWord: string) => {
  const ranges: Ranges = [];
  let index: number | undefined;
  const wordLength = highlightWord.length;
  const valueLength = value.length;

  index = value.indexOf(highlightWord);
  while (index !== -1) {
    ranges.push([index, Math.min(index + wordLength, valueLength)]);
    index = value.indexOf(highlightWord, index + wordLength);
  }

  return ranges;
};

export const getContentHighlightedRanges = (value: string, highlight: string[], caseSensitive: boolean = false) => {
  let content = value;
  if (!caseSensitive) {
    content = value.toLowerCase();
  }
  return (
    highlight
      .map((str) => (caseSensitive ? str : str.toLowerCase()))
      // Get highlight ranges for each highlight word
      .map((highlightWord) => getHighlightWordRanges(content, highlightWord))
      // Flatten into ranges array.
      .reduce((acc, current) => acc.concat(current), [] as Ranges)
      // Sort ranges.
      .sort((a, b) => {
        if (a[0] === b[0]) {
          return b[1] - a[1];
        }

        return a[0] - b[0];
      })
      // Merge ranges.
      .reduce((res, current) => {
        if (!res.length) {
          return [current];
        }

        const lastEntry = res[res.length - 1];

        if (current[0] > lastEntry[1]) {
          res.push(current);
        } else {
          lastEntry[0] = Math.min(lastEntry[0], current[0]);
          lastEntry[1] = Math.max(lastEntry[1], current[1]);
        }

        return res;
      }, [] as Ranges)
  );
};

export const getContentWithHighlightedRanges = (value: any, highlight: string[], caseSensitive: boolean = false) => {
  if (typeof value !== 'string' || !value.length) {
    return [];
  }

  return (
    getContentHighlightedRanges(value, highlight, caseSensitive)
      // Return content as array of objects.
      .reduce((res, current, index, ranges) => {
        let last = [0, 0];
        if (index) {
          last = ranges[index - 1];
        }

        res.push({
          value: value.substring(last[1], current[0]),
          highlight: false,
        });

        res.push({
          value: value.substring(current[0], current[1]),
          highlight: true,
        });

        if (index === ranges.length - 1) {
          res.push({
            value: value.substring(current[1]),
            highlight: false,
          });
        }

        return res;
      }, [] as ContentWithHighlightedRanges)
  );
};

export const getContentHighlightedWords = (value: any, highlight: string[], caseSensitive: boolean = false) => {
  return (
    getContentWithHighlightedRanges(value, highlight, caseSensitive)
      // Keep only highlighted ranges.
      .filter((range) => range.highlight)
      // Pull out values.
      .map((range) => range.value)
      // Keep only unique values.
      .filter((word, index, words) => words.indexOf(word) === index)
  );
};
