import httpClient from '../http-client';
import { RequestConfig } from '../types';

export function createTranslateEndpoint<
  ModelJSON extends Record<string, any> & { translations: Record<string, Record<string, string>> },
  IdentityKey extends keyof ModelJSON = 'id',
>(options: { slug: string; identityKey?: IdentityKey }) {
  return function translate(
    id: Exclude<ModelJSON[IdentityKey], undefined | null | ''>,
    translations: Partial<ModelJSON['translations']>,
    config?: RequestConfig,
  ) {
    return httpClient.post<ModelJSON>(`${options.slug}/${id}/translate`, { translations }, config);
  };
}
