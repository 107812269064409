/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'profile': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<g data-name="Layer 2"><g data-name="Male 2"><g data-name="&lt;Group&gt;"><path pid="0" data-name="&lt;Path&gt;" d="M23.5 24a.5.5 0 01-.49-.38l-.6-2.4a2.49 2.49 0 00-1.63-1.77L16.34 18c-1.76-.76-2.34-3.36-2.34-4a.5.5 0 01.18-.39 3.41 3.41 0 001.32-2.11 1.54 1.54 0 01.6-1.36 6.51 6.51 0 00.4-1.57.33.33 0 00-.06-.09A.44.44 0 0116 8V5c0-1.47-.47-1.77-.67-1.9a.65.65 0 01-.33-.48.51.51 0 010-.19C14.92 2 14.32 1 12 1a4.23 4.23 0 00-4 4v3a.5.5 0 01-.5.5v.07a6.48 6.48 0 00.4 1.57 1.54 1.54 0 01.6 1.36 3.41 3.41 0 001.32 2.11.5.5 0 01.18.39c0 .64-.58 3.24-2.31 4l-4.47 1.45a2.49 2.49 0 00-1.63 1.77L1 23.62a.5.5 0 01-1-.24L.62 21a3.49 3.49 0 012.28-2.5L7.34 17c1-.44 1.53-2.16 1.64-2.82A4.16 4.16 0 017.5 11.5c0-.35-.11-.5-.16-.53a.42.42 0 01-.3-.29A7.44 7.44 0 016.5 8.5a.51.51 0 010-.12 1.29 1.29 0 01.5-.73V5a5.21 5.21 0 015-5c3.06 0 3.89 1.57 4 2.34.52.38 1 1 1 2.66v2.65a1.29 1.29 0 01.49.73.51.51 0 010 .12 7.44 7.44 0 01-.49 2.19.5.5 0 01-.34.3.82.82 0 00-.12.52 4.16 4.16 0 01-1.54 2.7c.11.66.6 2.38 1.67 2.83l4.4 1.47A3.49 3.49 0 0123.39 21l.6 2.4a.5.5 0 01-.36.61z"/></g></g></g>'
  }
})
