import { ListStatus, EditableStatus } from '@/store/types';
import Product from './product';
import Pagination from '@/store/models/pagination';
import Accessory from './accessory';
import AccessoryTree from './accessory-tree';

export enum ProductsActions {
  /**
   * List
   */
  LOAD_LIST = 'LOAD_LIST',

  /**
   * Tree
   */
  LOAD_TREE = 'LOAD_TREE',

  /**
   * Edit
   */
  LOAD_EDITABLE = 'LOAD_EDITABLE',
  UPDATE_EDITABLE = 'UPDATE_EDITABLE',
  SAVE_EDITABLE = 'SAVE_EDITABLE',
  SAVE_TRANSLATIONS = 'SAVE_TRANSLATIONS',

  /**
   * Actions
   */
  DELETE = 'DELETE',
  RESTORE = 'RESTORE',

  /**
   * Bulk actions
   */
  BULK_DELETE = 'BULK_DELETE',
  BULK_RESTORE = 'BULK_RESTORE',

  /**
   * Accessories list
   */
  LOAD_ACCESSORIES_LIST = 'LOAD_ACCESSORIES_LIST',

  /**
   * Accessories tree
   */
  LOAD_ACCESSORIES_TREE = 'LOAD_ACCESSORIES_TREE',

  /**
   * Accessories edit
   */
  LOAD_ACCESSORY = 'LOAD_ACCESSORY',
  UPDATE_ACCESSORY = 'UPDATE_ACCESSORY',
  SAVE_ACCESSORY = 'SAVE_ACCESSORY',
  SAVE_ACCESSORY_TRANSLATIONS = 'SAVE_ACCESSORY_TRANSLATIONS',

  /**
   * Accessories actions
   */
  DELETE_ACCESSORY = 'DELETE_ACCESSORY',
  RESTORE_ACCESSORY = 'RESTORE_ACCESSORY',

  /**
   * Bulk actions
   */
  BULK_DELETE_ACCESSORY = 'BULK_DELETE_ACCESSORY',
  BULK_RESTORE_ACCESSORY = 'BULK_RESTORE_ACCESSORY',
}

export enum ProductsMutations {
  /**
   * List
   */
  SET_LIST = 'SET_LIST',
  SET_LIST_STATUS = 'SET_LIST_STATUS',
  SET_LIST_PAGINATION = 'SET_LIST_PAGINATION',

  /**
   * Tree
   */
  SET_TREE = 'SET_TREE',
  SET_TREE_STATUS = 'SET_TREE_STATUS',

  /**
   * Edit
   */
  SET_EDITABLE = 'SET_EDITABLE',
  SET_EDITABLE_STATUS = 'SET_EDITABLE_STATUS',
  UPDATE_EDITABLE = 'UPDATE_EDITABLE',
  UPDATE_INITIAL_EDITABLE = 'UPDATE_INITIAL_EDITABLE',
  SET_TRANSLATIONS_STATUS = 'SET_TRANSLATIONS_STATUS',

  /**
   * Accessories list
   */
  SET_ACCESSORIES_LIST = 'SET_ACCESSORIES_LIST',
  SET_ACCESSORIES_LIST_STATUS = 'SET_ACCESSORIES_LIST_STATUS',
  SET_ACCESSORIES_LIST_PAGINATION = 'SET_ACCESSORIES_LIST_PAGINATION',

  /**
   * Accessories tree
   */
  SET_ACCESSORIES_TREE = 'SET_ACCESSORIES_TREE',
  SET_ACCESSORIES_TREE_STATUS = 'SET_ACCESSORIES_TREE_STATUS',

  /**
   * Accessories Edit
   */
  SET_ACCESSORY = 'SET_ACCESSORY',
  SET_ACCESSORY_STATUS = 'SET_ACCESSORY_STATUS',
  UPDATE_ACCESSORY = 'UPDATE_ACCESSORY',
  UPDATE_INITIAL_ACCESSORY = 'UPDATE_INITIAL_ACCESSORY',
  SET_ACCESSORY_TRANSLATIONS_STATUS = 'SET_ACCESSORY_TRANSLATIONS_STATUS',
}

export interface ProductsState {
  /**
   * List
   */
  list: Product[];
  listStatus: ListStatus;
  listPagination: Pagination;

  /**
   * Tree
   */
  tree: Product[];
  treeStatus: ListStatus;

  /**
   * Edit
   */
  editable?: Product;
  initialEditable?: Product;
  editableStatus: EditableStatus;
  translationsStatus: EditableStatus;

  /**
   * Accessories list
   */
  accessoriesList: Accessory[];
  accessoriesListStatus: ListStatus;
  accessoriesListPagination: Pagination;

  /**
   * Accessories tree
   */
  accessoriesTree: AccessoryTree[];
  accessoriesTreeStatus: ListStatus;

  /**
   * Accessories Edit
   */
  accessory?: Accessory;
  initialAccessory?: Accessory;
  accessoryStatus: EditableStatus;
  accessoryTranslationsStatus: EditableStatus;
}
