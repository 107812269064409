import { ListStatus, EditableStatus } from '@/store/types';
import Pagination from '@/store/models/pagination';
import Country from '@/store/models/country';
import i18n from '@/i18n';

export enum CountryStatus {
  Inactive = 0,
  Active = 1,
}

export const CountryStatuses = {
  [CountryStatus.Inactive]: i18n.t('Inactive'),
  [CountryStatus.Active]: i18n.t('Active'),
};

export enum CountriesActions {
  /**
   * Edit
   */
  LOAD_EDITABLE = 'LOAD_EDITABLE',
  UPDATE_EDITABLE = 'UPDATE_EDITABLE',
  SAVE_EDITABLE = 'SAVE_EDITABLE',
}

export enum CountriesMutations {
  /**
   * Edit
   */
  SET_EDITABLE = 'SET_EDITABLE',
  SET_EDITABLE_STATUS = 'SET_EDITABLE_STATUS',
  UPDATE_EDITABLE = 'UPDATE_EDITABLE',
  UPDATE_INITIAL_EDITABLE = 'UPDATE_INITIAL_EDITABLE',
}

export interface CountriesState {
  /**
   * Edit
   */
  editable?: Country[];
  initialEditable?: Country[];
  editableStatus: EditableStatus;
}
