export enum LoginStatus {
  Loggedout = 'Loggedout',
  Pending = 'Pending',
  Success = 'Success',
  Failed = 'Failed',
  Error = 'Error',
}

export enum LoginError {
  Default,
  InactiveUser,
}

export enum ResetStatus {
  Pending = 'Pending',
  Success = 'Success',
  Failed = 'Failed',
  Error = 'Error',
}

export enum SubmitNewPasswordStatus {
  Initial = 'Initial',
  Pending = 'Pending',
  Success = 'Success',
  Failed = 'Failed',
}

export enum SupportStatus {
  Initial = 'Initial',
  Pending = 'Pending',
  Success = 'Success',
  Failed = 'Failed',
  Error = 'Error',
}

export enum UserActions {
  LOGIN = 'LOGIN',
  LOGOUT = 'LOGOUT',
  RESET_PASSWORD = 'RESET_PASSWORD',
  VERIFY_RESET = 'VERIFY_RESET',
  SUBMIT_NEW_PASSWORD = 'SUBMIT_NEW_PASSWORD',
  REQUEST_SUPPORT = 'REQUEST_SUPPORT',
  IMPERSONATE = 'IMPERSONATE',
  END_IMPERSONATION = 'END_IMPERSONATION',
}

export enum UserMutations {
  LOGIN_PENDING = 'LOGIN_PENDING',
  LOGIN_SUCCESS = 'LOGIN_SUCCESS',
  LOGIN_FAILED = 'LOGIN_FAILED',
  RESET_SUCCESS = 'RESET_SUCCESS',
  RESET_PENDING = 'RESET_PENDING',
  RESET_FAILED = 'RESET_FAILED',
  LOGOUT_PENDING = 'LOGOUT_PENDING',
  RESET_STORE_STATE = 'RESET_STORE_STATE',
  SUPPORT_SUCCESS = 'SUPPORT_SUCCESS',
  SUPPORT_PENDING = 'SUPPORT_PENDING',
  SUPPORT_FAILED = 'SUPPORT_FAILED',
  SET_TOKEN = 'SET_TOKEN',
  CACHE_IMPERSONATE_TOKEN = 'CACHE_IMPERSONATE_TOKEN',
  RESTORE_IMPERSONATE_TOKEN = 'RESTORE_IMPERSONATE_TOKEN',
  SET_SUBMIT_NEW_PASSWORD_STATUS = 'SET_SUBMIT_NEW_PASSWORD_STATUS',
}

export interface UserState {
  loginStatus: LoginStatus;
  resetStatus: LoginStatus;
  currentToken: string | undefined;
  loginErrorMessage?: string;
  supportStatus: SupportStatus;
  submitNewPasswordStatus: SubmitNewPasswordStatus;
}
