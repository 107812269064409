import { EmailJSON } from '@/store/modules/settings/email';
import { LegalJSON } from '@/store/modules/settings/legal';
import { SettingsJSON } from '@/store/modules/settings/settings';
import { FAQJSON } from '@/store/modules/support/faq';
import { createCRUDEndpoints } from '../factory/crud';
import { createTranslateEndpoint } from '../factory/translate';
import httpClient from '../http-client';
import { RequestConfig } from '../types';

export function get(config?: RequestConfig) {
  return httpClient.get<SettingsJSON>('settings', config);
}

export function store(data: SettingsJSON, config?: RequestConfig) {
  return httpClient.post<SettingsJSON>('settings', data, config);
}

export function translate(
  translations: { top_banner: { translations: Partial<SettingsJSON['top_banner']['translations']> } },
  config?: RequestConfig,
) {
  return httpClient.post<Pick<SettingsJSON, 'top_banner'>>('settings/translate', translations, config);
}

export function getLegals(config?: RequestConfig) {
  return httpClient.get<LegalJSON[]>('settings/legal', config);
}

export function storeLegal({ id, ...data }: Omit<LegalJSON, 'id'> & { id?: LegalJSON['id'] }, config?: RequestConfig) {
  return httpClient.post<LegalJSON>(id ? `settings/legal/${id}` : 'settings/legal', { id, ...data }, config);
}

export const translateLegal = createTranslateEndpoint<LegalJSON>({ slug: 'settings/legal' });

export function getEmails(config?: RequestConfig) {
  return httpClient.get<EmailJSON[]>('settings/emails', config);
}

export function storeEmail({ id, ...data }: EmailJSON, config?: RequestConfig) {
  return httpClient.post<EmailJSON>(`settings/email/${id}`, { id, ...data }, config);
}

export const translateEmail = createTranslateEndpoint<EmailJSON>({ slug: 'settings/email' });

const faqCRUD = createCRUDEndpoints<FAQJSON, {}, 'id', FAQJSON[]>({ slug: 'settings/faq' });

export const listFaqs = faqCRUD.list;
export const getFaq = faqCRUD.get;
export const storeFaq = faqCRUD.store;
export const removeFaq = faqCRUD.remove;
export const restoreFaq = faqCRUD.restore;
export const translateFaq = createTranslateEndpoint<FAQJSON>({ slug: 'settings/faq' });
