import { Module } from 'vuex';

import { ListStatus, RootState, EditableStatus } from '@/store/types';
import Pagination from '@/store/models/pagination';

import { EventsState } from './types';
import actions from './actions';
import mutations from './mutations';
import getters from './getters';

const getInitialState = (): EventsState => ({
  /**
   * List
   */
  listStatus: ListStatus.Loading,
  listPagination: new Pagination(),
  list: [],

  /**
   * Edit
   */
  editableStatus: EditableStatus.Loading,
});

const state = getInitialState();

const module: Module<EventsState, RootState> = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};

export default module;
