const toString = Object.prototype.toString;

export const isString = (value: any): boolean => {
  return typeof value === 'string' || toString.call(value) === '[object String]';
};

export const isNumber = (value: any): boolean => {
  return typeof value === 'number' || toString.call(value) === '[object String]';
};

export const isObject = (value: any): boolean => {
  return typeof value === 'object' && value !== null;
};

export const isNull = (value: any): boolean => value === null;

export const isBoolean = (value: any): boolean => {
  return value === true || value === false || toString.call(value) === '[object Boolean]';
};

export const isArray = (value: any): boolean => {
  return Array.isArray(value);
};
