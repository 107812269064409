import actions from './actions';
import mutations from './mutations';
import getters from './getters';
import { RootState, ListStatus } from '@/store/types';
import { Module } from 'vuex';
import Pagination from '@/store/models/pagination';
import { BlogState } from './types';

export const getInitialState = (): BlogState => ({
  /**
   * List
   */
  status: ListStatus.Loading,
  list: [],
  listPagination: new Pagination(),

  /**
   * Categories
   */
  categoriesStatus: ListStatus.Loading,
  categories: [],

  /**
   * Post
   */
  postStatus: ListStatus.Loading,

  /**
   * Author
   */
  authorStatus: ListStatus.Loading,
});

export const state: BlogState = getInitialState();

const blog: Module<BlogState, RootState> = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

export default blog;
