
import { mapGetters, mapActions, mapState } from 'vuex';
import mixins from 'vue-typed-mixins';

import LocationsMenuBlock from '@/components/blocks/locations-menu.vue';
import PractitionersMenuBlock from '@/components/blocks/practitioners-menu.vue';
import { UserActions } from '@/store/modules/user/types';
import { imageSizeMixin } from '@/components/mixins/image-size';

export default mixins(imageSizeMixin).extend({
  name: 'MobileMenuLayout',
  components: {
    LocationsMenuBlock,
    PractitionersMenuBlock,
  },
  data() {
    return {
      myPracticeIsOpen: false,
      myProfileIsOpen: false,
    };
  },
  computed: {
    ...mapState('user/profile', {
      currentUser: 'currentProfile',
    }),
    ...mapGetters('user/profile', ['displayName', 'profileImage', 'isSuperAdmin', 'isSales', 'isInUK', 'isClinical', 'isAdmin', 'isInNorthAmerica']),
    sizedProfileImage() {
      return this.sizedUserProfileImage(this.profileImage);
    },
  },
  methods: {
    ...mapActions('user', {
      logout: UserActions.LOGOUT,
    }),
    toggleMyPractice() {
      this.myPracticeIsOpen = !this.myPracticeIsOpen;
      this.myProfileIsOpen = false;
    },
    toggleMyProfile() {
      this.myProfileIsOpen = !this.myProfileIsOpen;
      this.myPracticeIsOpen = false;
    },
  },
});
