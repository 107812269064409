/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'location': {
    width: 16.2,
    height: 24.2,
    viewBox: '0 0 16.2 24.2',
    data: '<g data-name="Layer 2"><g data-name="Layer 1"><g data-name="Layer 2"><g data-name="Outline Icons"><path pid="0" d="M8.1 24.2a.6.6 0 01-.5-.27C7.29 23.46 0 12.37 0 8.1a8.1 8.1 0 1116.2 0c0 4.27-7.29 15.36-7.6 15.83a.6.6 0 01-.5.27zm0-23a6.91 6.91 0 00-6.9 6.9c0 3.31 5.24 11.78 6.9 14.39C9.76 19.88 15 11.41 15 8.1a6.91 6.91 0 00-6.9-6.9z"/><path pid="1" d="M8.1 11.7a3.6 3.6 0 113.6-3.6 3.6 3.6 0 01-3.6 3.6zm0-6a2.4 2.4 0 102.4 2.4 2.4 2.4 0 00-2.4-2.4z"/></g></g></g></g>'
  }
})
