import * as moment from 'moment-timezone';
import { JSONModel } from '@/store/models/jsonModel';
import UserRelationship, { UserRelationshipJSON } from '@/store/modules/users/user-relationship';
import { prepareAPIUrl } from '@/utils/api';

export enum ReportStatus {
  Processing,
  Processed,
  Failed,
}

export enum ReportType {
  Practice = 'practice',
  User = 'user',
  Location = 'listing',
  Practitioner = 'practitioner',
  Resource = 'asset',
  Product = 'product',
  Consultation = 'consultation',
  Download = 'download',
}

export interface ReportJSON {
  id: number;
  type: string;
  status: number;
  filename: string;
  created_at: string;
  updated_at: string;
  url: string;
  user: UserRelationshipJSON;
}

const availableStatuses = Object.keys(ReportStatus).map((key) => ReportStatus[key]);
const availableTypes = Object.keys(ReportType).map((key) => ReportType[key]);

export default class Report extends JSONModel<ReportJSON> {
  public id: number = 0;
  public status: ReportStatus = ReportStatus.Processing;
  public type: ReportType = ReportType.Practice;
  public filename: string = '';
  public createdAt: moment.Moment = moment();
  public updatedAt: moment.Moment = moment();
  public url: string = '';
  public user?: UserRelationship;

  public fromJSON(json: Partial<ReportJSON>) {
    this.id = Number(json.id);

    if (typeof json.status !== 'undefined' && availableStatuses.indexOf(json.status) !== -1) {
      this.status = json.status;
    }

    if (typeof json.type !== 'undefined' && availableTypes.indexOf(json.type) !== -1) {
      this.type = json.type as ReportType;
    }

    if (json.filename) {
      this.filename = String(json.filename);
    }

    if (json.created_at) {
      this.createdAt = moment.tz(String(json.created_at), 'UTC');
    }

    if (json.updated_at) {
      this.updatedAt = moment.tz(String(json.updated_at), 'UTC');
    }

    if (json.url) {
      this.url = prepareAPIUrl(String(json.url));
    }

    if (json.user) {
      this.user = UserRelationship.fromJSON(json.user);
    }

    return this;
  }
}
