import { EditableStatus, ListStatus } from '@/store/types';
import { Resource } from './resource';
import Pagination from '@/store/models/pagination';

export const RECENTLY_VIEWED_STORAGE_KEY_PREFIX: string = 'recentlyViewedResources';

export enum ResourcesStatus {
  Initial = 'Initial',
  Loading = 'Loading',
  Loaded = 'Loaded',
  Failed = 'Failed',
}

export enum ResourcesActions {
  /**
   * List
   */
  UPDATE_RESOURCES_FILTERS = 'UPDATE_RESOURCES_FILTERS',
  LOAD = 'LOAD',
  LOAD_FEATURED_RESOURCES = 'LOAD_FEATURED_RESOURCES',
  LOAD_LASER_CATEGORY_ID = 'LOAD_LASER_CATEGORY_ID',
  LOAD_LASER_HIGHLIGHTED_RESOURCES = 'LOAD_LASER_HIGHLIGHTED_RESOURCES',
  LOAD_TRAINING_RESOURCES = 'LOAD_TRAINING_RESOURCES',
  RAISE_LIST_ERROR = 'RAISE_LIST_ERROR',

  /**
   * View
   */
  LOAD_RESOURCE = 'LOAD_RESOURCE',
  REGISTER_RESOURCE_VIEW = 'REGISTER_RESOURCE_VIEW',
  RAISE_CURRENT_ERROR = 'RAISE_CURRENT_ERROR',
  LOAD_RELATED = 'LOAD_RELATED',
  RAISE_RELATED_ERROR = 'RAISE_RELATED_ERROR',
  COMPLETE_ASSET_TRAINING = 'COMPLETE_ASSET_TRAINING',

  /**
   * Edit
   */
  LOAD_EDITABLE = 'LOAD_EDITABLE',
  LOAD_EMPTY_EDITABLE = 'LOAD_EMPTY_EDITABLE',
  SAVE_EDITABLE = 'SAVE_EDITABLE',
  DELETE_EDITABLE = 'DELETE_EDITABLE',
  RESTORE_EDITABLE = 'RESTORE_EDITABLE',
  UPDATE_EDITABLE = 'UPDATE_EDITABLE',

  /**
   * TODO: categorize resources actions
   */
  LOAD_RECENTLY_VIEWED_RESOURCES = 'LOAD_RECENTLY_VIEWED_RESOURCES',
  LOAD_LOCAL_RECENTLY_VIEWED_RESOURCES = 'LOAD_LOCAL_RECENTLY_VIEWED_RESOURCES',
  PERSONALIZE = 'PERSONALIZE',
  RAISE_PERSONALIZE_ERROR = 'RAISE_PERSONALIZE_ERROR',

  /**
   * Bulk actions
   */
  BULK_DELETE = 'BULK_DELETE',
  BULK_RESTORE = 'BULK_RESTORE',
}

export enum ResourcesMutations {
  /**
   * List
   */
  LIST_LOADING = 'LIST_LOADING',
  LIST_LOADED = 'LIST_LOADED',
  LIST_LOADING_FAILED = 'LIST_LOADING_FAILED',
  SET_RESOURCES = 'SET_RESOURCES',
  SET_RESOURCES_FILTERS = 'SET_RESOURCES_FILTERS',
  SET_LASER_HIGHLIGHTED_STATUS = 'SET_LASER_HIGHLIGHTED_STATUS',
  SET_LASER_HIGHLIGHTED_RESOURCES = 'SET_LASER_HIGHLIGHTED_RESOURCES',
  SET_LASER_CATEGORY_ID = 'SET_LASER_CATEGORY_ID',
  SET_TRAINING_RESOURCES_STATUS = 'SET_TRAINING_RESOURCES_STATUS',
  SET_TRAINING_RESOURCES = 'SET_TRAINING_RESOURCES',

  /**
   * Featured
   */
  SET_FEATURED_RESOURCES = 'SET_FEATURED_RESOURCES',
  SET_FEATURED_RESOURCES_STATUS = 'SET_FEATURED_RESOURCES_STATUS',
  SET_FEATURED_RESOURCES_FILTERS = 'SET_FEATURED_RESOURCES_FILTERS',

  /**
   * View
   */
  CURRENT_LOADING = 'CURRENT_LOADING',
  CURRENT_LOADED = 'CURRENT_LOADED',
  CURRENT_LOADING_FAILED = 'CURRENT_LOADING_FAILED',
  RELATED_LOADING = 'RELATED_LOADING',
  RELATED_LOADED = 'RELATED_LOADED',
  RELATED_LOADING_FAILED = 'RELATED_LOADING_FAILED',
  PERSONALIZE_LOADING = 'PERSONALIZE_LOADING',
  PERSONALIZE_LOADED = 'PERSONALIZE_LOADED',
  PERSONALIZE_LOADING_FAILED = 'PERSONALIZE_LOADING_FAILED',
  SET_CURRENT_RESOURCE = 'SET_CURRENT_RESOURCE',
  SET_RELATED_RESOURCE = 'SET_RELATED_RESOURCE',
  SET_PAGINATION = 'SET_PAGINATION',
  SET_RECENTLY_VIEWED = 'SET_RECENTLY_VIEWED',
  SET_COMPLETE_ASSET_TRAINING = 'SET_COMPLETE_ASSET_TRAINING',
  SET_COMPLETE_ASSET_TRAINING_STATUS = 'SET_COMPLETE_ASSET_TRAINING_STATUS',

  /**
   * Edit
   */
  SET_EDITABLE = 'SET_EDITABLE',
  UPDATE_EDITABLE = 'UPDATE_EDITABLE',
  SET_EDITABLE_STATUS = 'SET_EDITABLE_STATUS',

  /**
   * TODO: categorize resources mutations
   */
  CLEAR_RESOURCES = 'CLEAR_RESOURCES',
  RECENTLY_VIEWED_RESOURCES_LOADING = 'RECENTLY_VIEWED_RESOURCES_LOADING',
  RECENTLY_VIEWED_RESOURCES_LOADED = 'RECENTLY_VIEWED_RESOURCES_LOADED',
  RECENTLY_VIEWED_RESOURCES_FAILED = 'RECENTLY_VIEWED_RESOURCES_FAILED',
  SET_IS_RESOURCE_DIRECTLY_ACCESSED = 'SET_IS_RESOURCE_DIRECTLY_ACCESSED',
}

export interface ResourcesState {
  /**
   * Featured list
   */
  featured: Resource[];
  featuredStatus: ResourcesStatus;
  featuredFilters: number[];

  /**
   * Recently viewed resources
   */
  recentlyViewed: number[];
  recentlyViewedResources: Resource[];
  recentlyViewedStatus: ResourcesStatus;

  /**
   * List
   */
  listStatus: ResourcesStatus;
  pagination?: Pagination;
  laserHighlightedStatus: ListStatus;
  laserHighlightedItems: Resource[];
  laserCategoryId?: number;
  trainingResourcesStatus: ListStatus;
  trainingResourcesItems: Resource[];
  resourcesList: Resource[];
  resourcesListFilters?: ResourcesRequestFilters;
  categories: number[];

  /**
   * View
   */
  currentStatus: ResourcesStatus;
  relatedStatus: ResourcesStatus;
  personalizeStatus: ResourcesStatus;
  currentResource?: Resource;
  related: Resource[];
  isResourceAccessedDirectly: boolean;
  completeAssetTrainingStatus: ListStatus;

  /**
   * Edit
   */
  editableStatus: EditableStatus;
  editable?: Resource;
  initialEditable?: Resource;
}

export interface ResourcesURLFilters {
  product?: number | string;
  treatments?: number[] | string;
  categories?: number[] | string;
  [key: string]: any;
}

export interface ResourcesRequestFilters {
  products?: number[];
  treatments?: number[];
  categories?: number[];
  page?: number;
  [key: string]: any;
}
