
import Vue from 'vue';
import { mapState, mapActions } from 'vuex';

import { Snackbar } from '@/store/modules/notifications/snackbar';
import { NotificationsActions, NotificationType } from '@/store/modules/notifications/types';
import Timeago from '@/components/blocks/timeago.vue';

export default Vue.extend({
  name: 'SnackbarsBlock',
  components: {
    Timeago,
  },
  computed: {
    ...mapState('notifications', ['snackbars']),
    types() {
      return {
        [NotificationType.Success]: 'success',
        [NotificationType.Error]: 'error',
        [NotificationType.Notice]: 'notice',
        [NotificationType.Warning]: 'warning',
      };
    },
  },
  methods: {
    ...mapActions('notifications', {
      removeSnackbar: NotificationsActions.REMOVE_SNACKBAR,
    }),
    snackbarContent(snackbar: Snackbar): string {
      let out = '';

      if (snackbar.title) {
        out += `<strong>${snackbar.title}</strong> - `;
      }

      out += snackbar.message;

      return out;
    },
  },
});
