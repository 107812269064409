
import Vue from 'vue';
import { mapState, mapGetters, mapActions } from 'vuex';

import Taxonomy from '@/store/models/taxonomy';
import { TreatmentActions } from '@/store/modules/treatments/types';
import { PublicTreatmentStatus } from '@/store/modules/treatments/public-treatment';

import FormInput from '@/components/controls/form-input.vue';
import SubmitButton from '@/components/controls/submit-button.vue';
import NotificationBanner from '@/components/blocks/notification-banner.vue';
import Publish from '@/components/forms/publish.vue';
import Checkbox from '@/components/controls/checkbox.vue';
import ImageUploadControl from '@/components/controls/image-upload.vue';
import Autocomplete, {
  AutocompleteOption,
  AutocompleteResponse,
  AutocompletePromise,
} from '@/components/controls/autocomplete.vue';
import CountriesSelection from '@/components/controls/countries-selection.vue';
import TreatmentsSelection from '@/components/controls/treatments-selection.vue';
import PublicSitesSelection from '@/components/controls/public-site-selection.vue';
import TranslationsControl from '@/components/controls/translations.vue';

export default Vue.extend({
  name: 'EditPublicTreatmentView',
  inject: {
    $validator: '$validator',
  },
  components: {
    FormInput,
    SubmitButton,
    NotificationBanner,
    Publish,
    Checkbox,
    ImageUploadControl,
    Autocomplete,
    CountriesSelection,
    TreatmentsSelection,
    PublicSitesSelection,
    TranslationsControl,
  },
  props: {
    add: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState('treatments', {
      treatments: 'allTreatments',
      publicTreatment: 'publicTreatment',
      editableStatus: 'publicTreatmentStatus',
      translationsStatus: 'publicTreatmentTranslationStatus',
    }),
    ...mapGetters('treatments', {
      isLoading: 'publicTreatmentIsLoading',
      isSaving: 'publicTreatmentIsSaving',
      hasUnsavedChanges: 'publicTreatmentHasUnsavedChanges',
    }),
    isFetching() {
      return this.isLoading || this.isSaving;
    },
    isInactive() {
      return this.publicTreatment.status === PublicTreatmentStatus.Inactive;
    },
  },
  methods: {
    ...mapActions('treatments', {
      updatePublicTreatment: TreatmentActions.UPDATE_PUBLIC_TREATMENT,
      save: TreatmentActions.SAVE_PUBLIC_TREATMENT,
      removePublicTreatment: TreatmentActions.DELETE_PUBLIC_TREATMENT,
      restorePublicTreatment: TreatmentActions.RESTORE_PUBLIC_TREATMENT,
      saveTranslations: TreatmentActions.SAVE_PUBLIC_TRANSLATIONS,
    }),
    remove() {
      this.removePublicTreatment({ id: this.publicTreatment.id });
    },
    restore() {
      this.restorePublicTreatment({ id: this.publicTreatment.id });
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.hasUnsavedChanges) {
      const answer = window.confirm(this.$t('Do you really want to leave? You have unsaved changes!'));

      if (!answer) {
        next(false);
        return;
      }
    }

    next();
  },
});
