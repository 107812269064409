
import Vue from 'vue';
import FormInput from '@/components/controls/form-input.vue';
import { mapState, mapActions, mapGetters } from 'vuex';
import { PracticesActions } from '@/store/modules/practices/types';
import SubmitButton from '@/components/controls/submit-button.vue';

export default Vue.extend({
  name: 'PracticeForm',
  inject: {
    $validator: '$validator',
  },
  components: {
    SubmitButton,
    FormInput,
  },
  computed: {
    ...mapState('practices', ['editablePractice']),
    ...mapGetters('practices', [
      'editableIsLoaded',
      'editableIsLoading',
      'editableIsSaving',
      'editableIsSaved',
      'editableIsError',
    ]),
  },
  methods: {
    ...mapActions('practices', {
      savePractice: PracticesActions.SAVE_EDITABLE,
      updatePractice: PracticesActions.UPDATE_EDITABLE,
    }),
    update() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.savePractice()
            .then(() => {
              this.$emit('close');
            })
            .catch((error) => {
              this.errorMessage({
                title: this.$t('Error'),
                body: error && error.message ? error.message : this.$t('Network issue. Please try again.'),
              });
            });
        } else {
          this.errorMessage({
            title: this.$t('Error'),
            body: this.$t('Please correct the errors noted next to each field and try again.'),
          });
        }
      });
    },
  },
});
