
import { PropOptions } from 'vue';
import mixins from 'vue-typed-mixins';

import CustomInputComponent from '@/components/mixins/custom-input-component';

export default mixins(CustomInputComponent).extend({
  name: 'FormTreatmentSelectionControl',
  props: {
    productId: Number,
    productName: String,
    value: {
      type: Array,
      default: () => [],
    } as PropOptions,
    availableTreatments: {
      type: Array,
      default: () => [],
    } as PropOptions,
  },
  data() {
    return {
      open: false,
    };
  },
  methods: {
    checkboxClickHandler(id: number): void {
      let result = this.value;

      if (this.isSelected(id)) {
        result = result.filter((treatment) => treatment.id !== id);
      } else {
        result.push({ id });
      }
      this.$emit('input', result);
    },
    isSelected(id: number): boolean {
      return this.value.find((treatment) => treatment.id === id) ? true : false;
    },
    toggle() {
      this.open = !this.open;
    },
  },
});
