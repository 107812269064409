/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'edit': {
    width: 24.01,
    height: 24,
    viewBox: '0 0 24.01 24',
    data: '<g data-name="Layer 2"><g data-name="Layer 1"><g data-name="Layer 2"><g data-name="Outline Icons"><path pid="0" d="M19.5 24H.5a.5.5 0 01-.5-.5v-21A.5.5 0 01.5 2h14a.5.5 0 010 1H1v20h18V11.46a.5.5 0 111 0v12a.5.5 0 01-.46.54z"/><path pid="1" d="M7.88 16.63a.5.5 0 01-.46-.7l2.12-5a.5.5 0 01.11-.16L20.32.15a.5.5 0 01.71 0L23.86 3a.5.5 0 010 .71L13.18 14.36a.5.5 0 01-.16.11l-5 2.12a.5.5 0 01-.14.04zm2.54-5.16l-1.59 3.71 3.71-1.59L22.79 3.33l-2.12-2.12zm2.4 2.53z"/><path pid="2" d="M21.67 5.66a.5.5 0 01-.35-.15l-2.84-2.82a.5.5 0 01.71-.69L22 4.81a.5.5 0 01-.35.85zM12.82 14.51a.5.5 0 01-.35-.15l-2.83-2.83a.5.5 0 01.71-.71l2.83 2.83a.5.5 0 01-.35.85z"/></g></g></g></g>'
  }
})
