import api from '@/api';

import router from '@/router';
import i18next from '@/i18n';
import {
  EventTypes,
  RootActions,
  ErrorMessages,
  ActionsTree,
  ListStatus,
  EditableStatus,
  PublishStatus,
} from '@/store/types';

import {
  PractitionersState,
  PractitionersActions,
  PractitionersMutations,
  PractitionersListOptions,
} from '@/store/modules/practitioners/types';
import { NotificationsActions } from '@/store/modules/notifications/types';
import { prepareFilters } from '@/store/utils/filters';
import Pagination from '@/store/models/pagination';
import Practitioner, { PractitionerJSON } from './practitioner';
import PractitionerRelationship from './practitioner-relationship';
import { enhanceErrorWithMessage } from '@/utils/errors';

export const actions: ActionsTree<PractitionersState, typeof PractitionersActions> = {
  [PractitionersActions.LOAD_PRACTITIONERS_LIST](
    { commit, dispatch },
    options: PractitionersListOptions,
  ): Promise<void> {
    commit(PractitionersMutations.SET_LIST_STATUS, ListStatus.Loading);

    return api.practitioners
      .list(prepareFilters(options, 'practitioners'))
      .then((response) => {
        commit(PractitionersMutations.SET_LIST_PAGINATION, Pagination.fromJSON(response.data));
        commit(
          PractitionersMutations.SET_LIST,
          response.data.data.map((item) => Practitioner.fromJSON(item)),
        );
        commit(PractitionersMutations.SET_LIST_STATUS, ListStatus.Loaded);
      })
      .catch((error) => {
        enhanceErrorWithMessage(error, ErrorMessages.DATA_LOAD_ERROR);
        commit(PractitionersMutations.SET_LIST_STATUS, ListStatus.Failed);
        return dispatch(RootActions.ERROR, error, { root: true });
      });
  },
  [PractitionersActions.LOAD_PENDING_LIST]({ commit, dispatch, rootGetters }): Promise<void> {
    // Pending list is only shown to users and admins
    if (
      !rootGetters['user/profile/isUser'] &&
      !rootGetters['user/profile/isAdmin'] &&
      !rootGetters['user/profile/isSuperAdmin']
    ) {
      return Promise.resolve();
    }

    let practiceId: number | undefined;
    if (rootGetters['user/profile/isUser']) {
      practiceId = rootGetters['user/profile/practiceId'];
    }

    commit(PractitionersMutations.SET_PENDING_LIST_STATUS, ListStatus.Loading);
    return api.practitioners
      .list({
        practice_id: practiceId,
        status: PublishStatus.Pending,
      })
      .then((response) => {
        const pagination = Pagination.fromJSON(response.data);
        commit(
          PractitionersMutations.SET_PENDING_LIST,
          response.data.data.map((item) => Practitioner.fromJSON(item)),
        );
        commit(PractitionersMutations.SET_PENDING_LIST_TOTAL, pagination.total);
        commit(PractitionersMutations.SET_PENDING_LIST_STATUS, ListStatus.Loaded);
      })
      .catch((error) => {
        enhanceErrorWithMessage(error, ErrorMessages.DATA_LOAD_ERROR);
        commit(PractitionersMutations.SET_PENDING_LIST_STATUS, ListStatus.Failed);
        return dispatch(RootActions.ERROR, error, { root: true });
      });
  },
  [PractitionersActions.LOAD_PRACTITIONERS_MENU_LIST]({ commit, dispatch, rootGetters }): Promise<void> {
    // Practitioners menu is shown only to users
    if (!rootGetters['user/profile/isUser']) {
      return Promise.resolve();
    }

    const practiceId = rootGetters['user/profile/practiceId'];

    commit(PractitionersMutations.SET_MENU_LIST_STATUS, ListStatus.Loading);
    return api.practitioners
      .list({ practice_id: practiceId })
      .then((response) => {
        const pagination = Pagination.fromJSON(response.data);
        commit(
          PractitionersMutations.SET_MENU_LIST,
          response.data.data.map((item) => Practitioner.fromJSON(item)),
        );
        commit(PractitionersMutations.SET_MENU_LIST_TOTAL, pagination.total);
        commit(PractitionersMutations.SET_MENU_LIST_STATUS, ListStatus.Loaded);
      })
      .catch((error) => {
        enhanceErrorWithMessage(error, ErrorMessages.DATA_LOAD_ERROR);
        commit(PractitionersMutations.SET_MENU_LIST_STATUS, ListStatus.Failed);
        return dispatch(RootActions.ERROR, error, { root: true });
      });
  },

  [PractitionersActions.LOAD_PRACTITIONERS_HOURS_LIST](
    { commit, dispatch },
    { practiceId = 0, locationId = 0 } = {},
  ): Promise<void> {
    commit(PractitionersMutations.SET_PRACTITIONERS_HOURS_LIST_STATUS, ListStatus.Loading);

    return api.practitioners
      .listLocationPractitioners(practiceId, locationId)
      .then((response) => {
        commit(
          PractitionersMutations.SET_PRACTITIONERS_HOURS_LIST,
          response.data.map((item) => PractitionerRelationship.fromJSON(item)),
        );
        commit(PractitionersMutations.SET_PRACTITIONERS_HOURS_LIST_STATUS, ListStatus.Loaded);
      })
      .catch((error) => {
        enhanceErrorWithMessage(error, ErrorMessages.DATA_LOAD_ERROR);
        commit(PractitionersMutations.SET_PRACTITIONERS_HOURS_LIST_STATUS, ListStatus.Failed);
        return dispatch(RootActions.ERROR, error, { root: true });
      });
  },

  [PractitionersActions.LOAD_PRACTITIONER]({ commit, dispatch, rootGetters }, { id, practice_id } = {}): Promise<any> {
    commit(PractitionersMutations.SET_EDITABLE_STATUS, EditableStatus.Loading);

    let practitionerPromise;

    if (!id) {
      const practitioner = new Practitioner();

      if (rootGetters['user/profile/isUser']) {
        practitioner.practiceId = rootGetters['user/profile/practiceId'];
      } else {
        const practiceId = Number(practice_id);
        if (practiceId && Number.isFinite(practiceId)) {
          practitioner.practiceId = practiceId;
        }
      }

      practitionerPromise = Promise.resolve(practitioner);
    } else {
      practitionerPromise = api.practitioners
        .get(id, 'latest')
        .then((response) => Practitioner.fromJSON(response.data));
    }

    return practitionerPromise
      .then((practitioner: Practitioner) => {
        commit(PractitionersMutations.SET_EDITABLE, practitioner);
      })
      .then(() => {
        commit(PractitionersMutations.SET_EDITABLE_STATUS, EditableStatus.Loaded);
      })
      .catch((error) => {
        enhanceErrorWithMessage(error, ErrorMessages.DATA_LOAD_ERROR);
        commit(PractitionersMutations.SET_EDITABLE_STATUS, EditableStatus.Failed);
        return dispatch(RootActions.ERROR, error, { root: true });
      });
  },
  [PractitionersActions.SAVE_EDITABLE]({ dispatch, commit, state, rootGetters }): Promise<void> {
    commit(PractitionersMutations.SET_EDITABLE_STATUS, EditableStatus.Saving);

    if (!state.editablePractitioner) {
      return Promise.reject(new Error('Internal Error: Editable practitioner not available.'));
    }

    const practitioner: PractitionerJSON = state.editablePractitioner.toJSON();
    const isUpdate = Boolean(practitioner.id);

    return api.practitioners
      .store(practitioner)
      .then((response): Promise<Practitioner> => {
        const savedPractitioner = Practitioner.fromJSON(response.data);
        commit(PractitionersMutations.SET_EDITABLE, savedPractitioner);
        commit(PractitionersMutations.SET_EDITABLE_STATUS, EditableStatus.Saved);

        if (!isUpdate) {
          return new Promise((resolve, reject) => {
            if (!savedPractitioner.id) {
              return reject(new Error('Invalid data.'));
            }

            router.replace(
              {
                name: 'edit-practitioner',
                params: {
                  id: String(savedPractitioner.id),
                },
              },
              () => resolve(savedPractitioner),
              (error: any) => reject(error),
            );
          });
        }

        return Promise.resolve(savedPractitioner);
      })
      .then((savedPractitioner) => {
        dispatch(PractitionersActions.LOAD_PRACTITIONERS_MENU_LIST);
        dispatch(
          RootActions.DISPATCH_EVENT,
          {
            type: EventTypes.UPDATE_PRACTITIONER,
            eventObj: { practitioner: savedPractitioner },
          },
          { root: true },
        );

        const isUnderReview =
          !rootGetters['user/profile/isAdmin'] &&
          !rootGetters['user/profile/isSuperAdmin'] &&
          savedPractitioner.status === PublishStatus.Pending;
        let successMsg = i18next.t('A new practitioner was added successfully.');

        if (isUnderReview) {
          successMsg = i18next.t(
            'The practitioner has been submitted to the AMPS team for review.' +
              " You will be notified when it's published.",
          );
        }

        if (isUpdate) {
          successMsg = i18next.t('The practitioner was successfully updated.');

          if (isUnderReview) {
            successMsg = i18next.t(
              'The practitioner has been submitted to the AMPS team for review.' +
                ' You will be notified when edits are published.',
            );
          }
        }

        return dispatch(
          'notifications/' + (isUnderReview ? NotificationsActions.SHOW_WARNING : NotificationsActions.SHOW_SUCCESS),
          {
            body: successMsg,
          },
          { root: true },
        );
      })
      .catch((error) => {
        enhanceErrorWithMessage(error, ErrorMessages.DATA_LOAD_ERROR);
        commit(PractitionersMutations.SET_EDITABLE_STATUS, EditableStatus.Failed);
        return dispatch(RootActions.ERROR, error, { root: true });
      });
  },

  [PractitionersActions.UPDATE_EDITABLE]({ commit }, changes): Promise<void> {
    commit(PractitionersMutations.UPDATE_EDITABLE, changes);
    return Promise.resolve();
  },

  [PractitionersActions.DELETE]({ commit, dispatch }, { id = 0 }: { id?: number } = {}): Promise<void> {
    const isEdit = router.currentRoute.name === 'edit-practitioner';
    if (isEdit) {
      commit(PractitionersMutations.SET_EDITABLE_STATUS, EditableStatus.Saving);
    } else {
      commit(PractitionersMutations.SET_LIST_STATUS, ListStatus.Loading);
    }

    return new Promise<void>((resolve, reject) => {
      if (!id) {
        return reject(new Error('Invalid data!'));
      }

      resolve();
    })
      .then(() => api.practitioners.remove(id))
      .then(() => {
        if (isEdit) {
          commit(PractitionersMutations.UPDATE_INITIAL_EDITABLE, {
            status: PublishStatus.Deleted,
          });
          commit(PractitionersMutations.UPDATE_EDITABLE, {
            status: PublishStatus.Deleted,
          });
          commit(PractitionersMutations.SET_EDITABLE_STATUS, EditableStatus.Saved);
        } else {
          dispatch(PractitionersActions.LOAD_PRACTITIONERS_LIST, {
            ...router.currentRoute.params,
            ...router.currentRoute.query,
          });
        }
      })
      .then(() => {
        return dispatch(
          'notifications/' + NotificationsActions.SHOW_SUCCESS,
          {
            body: i18next.t('The practitioner was deleted successfully.'),
          },
          { root: true },
        );
      })
      .catch((error) => {
        enhanceErrorWithMessage(error, ErrorMessages.DATA_LOAD_ERROR);

        if (isEdit) {
          commit(PractitionersMutations.SET_EDITABLE_STATUS, EditableStatus.Failed);
        } else {
          commit(PractitionersMutations.SET_LIST_STATUS, ListStatus.Failed);
        }

        return dispatch(RootActions.ERROR, error, { root: true });
      });
  },

  [PractitionersActions.RESTORE]({ commit, dispatch }, { id = 0 }: { id?: number } = {}): Promise<void> {
    const isEdit = router.currentRoute.name === 'edit-practitioner';
    if (isEdit) {
      commit(PractitionersMutations.SET_EDITABLE_STATUS, EditableStatus.Saving);
    } else {
      commit(PractitionersMutations.SET_LIST_STATUS, ListStatus.Loading);
    }

    return new Promise<void>((resolve, reject) => {
      if (!id) {
        return reject(new Error('Invalid data!'));
      }

      resolve();
    })
      .then(() => api.practitioners.restore(id))
      .then(() => {
        if (isEdit) {
          commit(PractitionersMutations.UPDATE_INITIAL_EDITABLE, {
            status: PublishStatus.Published,
          });
          commit(PractitionersMutations.UPDATE_EDITABLE, {
            status: PublishStatus.Published,
          });
          commit(PractitionersMutations.SET_EDITABLE_STATUS, EditableStatus.Saved);
        } else {
          dispatch(PractitionersActions.LOAD_PRACTITIONERS_LIST, {
            ...router.currentRoute.params,
            ...router.currentRoute.query,
          });
        }
      })
      .then(() =>
        dispatch(
          'notifications/' + NotificationsActions.SHOW_SUCCESS,
          {
            body: i18next.t('The practitioner was restored successfully.'),
          },
          { root: true },
        ),
      )
      .catch((error) => {
        enhanceErrorWithMessage(error, ErrorMessages.DATA_LOAD_ERROR);

        if (isEdit) {
          commit(PractitionersMutations.SET_EDITABLE_STATUS, EditableStatus.Failed);
        } else {
          commit(PractitionersMutations.SET_LIST_STATUS, ListStatus.Failed);
        }

        return dispatch(RootActions.ERROR, error, { root: true });
      });
  },

  [PractitionersActions.BULK_RESTORE](
    { commit, dispatch },
    { ids = [] }: { ids: number[] } = { ids: [] },
  ): Promise<void> {
    commit(PractitionersMutations.SET_LIST_STATUS, ListStatus.Loading);

    return new Promise<void>((resolve, reject) => {
      if (!ids || !ids.length) {
        return reject(new Error('Invalid data!'));
      }

      resolve();
    })
      .then(() => Promise.all(ids.map(async (id) => api.practitioners.restore(id))))
      .then(() => {
        dispatch(PractitionersActions.LOAD_PRACTITIONERS_LIST, {
          ...router.currentRoute.params,
          ...router.currentRoute.query,
        });

        return dispatch(
          'notifications/' + NotificationsActions.SHOW_SUCCESS,
          {
            body: i18next.t('%(count)% practitioner was published successfully', { count: ids.length }),
          },
          { root: true },
        );
      })
      .catch((error) => {
        enhanceErrorWithMessage(error, ErrorMessages.DATA_LOAD_ERROR);

        commit(PractitionersMutations.SET_LIST_STATUS, ListStatus.Failed);

        return dispatch(RootActions.ERROR, error, { root: true });
      });
  },

  [PractitionersActions.BULK_DELETE](
    { commit, dispatch },
    { ids = [] }: { ids: number[] } = { ids: [] },
  ): Promise<void> {
    commit(PractitionersMutations.SET_LIST_STATUS, ListStatus.Loading);

    return new Promise<void>((resolve, reject) => {
      if (!ids || !ids.length) {
        return reject(new Error('Invalid data!'));
      }

      resolve();
    })
      .then(() => Promise.all(ids.map((id) => api.practitioners.remove(id))))
      .then(() => {
        dispatch(PractitionersActions.LOAD_PRACTITIONERS_LIST, {
          ...router.currentRoute.params,
          ...router.currentRoute.query,
        });

        return dispatch(
          'notifications/' + NotificationsActions.SHOW_SUCCESS,
          {
            body: i18next.t('%(count)% practitioner was deleted successfully', { count: ids.length }),
          },
          { root: true },
        );
      })
      .catch((error) => {
        enhanceErrorWithMessage(error, ErrorMessages.DATA_LOAD_ERROR);

        commit(PractitionersMutations.SET_LIST_STATUS, ListStatus.Failed);

        return dispatch(RootActions.ERROR, error, { root: true });
      });
  },
};

export default actions;
