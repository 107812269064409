import { EditableStatus, ListStatus } from '@/store/types';
import Pagination from '@/store/models/pagination';
import PrivacyRequest from './privacy_request';

export enum PrivacyRequestActions {
  LOAD = 'LOAD',

  /**
   * Edit
   */
  LOAD_EDITABLE = 'LOAD_EDITABLE',
  DELETE = 'DELETE',
}

export enum PrivacyRequestMutations {
  SET_LIST = 'SET_LIST',
  SET_LIST_PAGINATION = 'SET_LIST_PAGINATION',
  SET_STATUS = 'SET_STATUS',

  /**
   * Edit
   */
  SET_EDITABLE = 'SET_EDITABLE',
  SET_EDITABLE_STATUS = 'SET_EDITABLE_STATUS',
}

export enum PrivacyRequestStatus {
  Closed,
  Pending,
}

export interface PrivacyRequestState {
  /**
   * List
   */
  status: ListStatus;
  list: PrivacyRequest[];
  listPagination: Pagination;

  /**
   * Edit
   */
  editable?: PrivacyRequest;
  editableStatus: EditableStatus;
}
