import { createCRUDEndpoints } from '@/api/factory/crud';
import { LocationJSON } from '@/store/modules/locations/location';
import { PublishStatus } from '@/store/types';
import httpClient from '../http-client';
import { RequestConfig } from '../types';

type ListParams =
  | {
      practice_id?: number;
      title?: string;
      search?: string;
      status?: PublishStatus;
      page?: string | number;
      sort?: string;
      direction?: 'asc' | 'desc';
    }
  | undefined;

export const { list, remove, restore } = createCRUDEndpoints<LocationJSON, ListParams>({
  slug: 'listing',
});

export type ListingVersion = 'latest' | 'published' | 'autosave';

export function get(id: LocationJSON['id'], version: ListingVersion = 'published', config?: RequestConfig) {
  return httpClient.get(`listing/${id}/${version}`, config);
}

export function store(
  { id, ...data }: Omit<LocationJSON, 'id'> & { id?: LocationJSON['id'] },
  version?: ListingVersion,
  config?: RequestConfig,
) {
  let endpoint = 'listing';

  if (id) {
    endpoint += `/${id}`;
  }

  if (version) {
    endpoint += `/${version}`;
  }

  return httpClient.post<LocationJSON>(endpoint, { id, ...data }, config);
}
