import { JSONModel } from './jsonModel';
import { getEnumValue } from '@/utils/enum';

export interface BannerJSON {
  id: number;
  type: number;
  message: string;
  url: string;
  link: string;
  status: BannerStatus;
  hash: string;
  external: boolean;
  translations: {
    message: Record<string, string>;
  };
}

export enum BannerStatus {
  Inactive = 0,
  Active = 1,
}

export default class Banner extends JSONModel<BannerJSON> {
  public id: number = 0;
  public type: string = '';
  public message: string = '';
  public url: string = '';
  public link: string = '';
  public status: BannerStatus = BannerStatus.Inactive;
  public hash: string = '';
  public external: boolean = false;
  public translations: {
    message: Record<string, string>;
  } = { message: {} };

  protected _clientProperties = ['translations'];

  public fromJSON(json: Partial<BannerJSON>) {
    this.id = Number(json.id);

    if (json.message) {
      this.message = String(json.message);
    }

    if (json.type) {
      this.type = String(json.type);
    }

    if (json.link) {
      this.link = String(json.link);
    }

    if (json.url) {
      this.url = String(json.url);
    }

    if (json.hash) {
      this.hash = String(json.hash);
    }

    if (json.external) {
      this.external = Boolean(json.external);
    }

    const status = getEnumValue<BannerStatus>(BannerStatus, json.status);
    if (typeof status !== 'undefined') {
      this.status = status;
    }

    if (json.translations) {
      this.translations = json.translations;
    }
  }
}
