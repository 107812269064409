import { ResourcesURLFilters } from '@/store/modules/resources/types';
import { EditableStatus, ListStatus } from '@/store/types';
import Category from './category';
import Pagination from '@/store/models/pagination';

export enum CategoryActions {
  LOAD = 'LOAD',

  /**
   * All categories
   */
  LOAD_ALL = 'LOAD_ALL',

  /**
   * Edit
   */
  LOAD_EDITABLE = 'LOAD_EDITABLE',
  SAVE_EDITABLE = 'SAVE_EDITABLE',
  UPDATE_EDITABLE = 'UPDATE_EDITABLE',
  SAVE_TRANSLATIONS = 'SAVE_TRANSLATIONS',
  DELETE = 'DELETE',
  RESTORE = 'RESTORE',
}

export enum CategoryMutations {
  SET_LIST = 'SET_LIST',
  SET_LIST_PAGINATION = 'SET_LIST_PAGINATION',
  SET_STATUS = 'SET_STATUS',

  SET_ALL = 'SET_ALL',
  SET_ALL_STATUS = 'SET_ALL_STATUS',
  SET_ALL_FILTERS = 'SET_ALL_FILTERS',

  /**
   * Edit
   */
  SET_EDITABLE = 'SET_EDITABLE',
  UPDATE_EDITABLE = 'UPDATE_EDITABLE',
  SET_EDITABLE_STATUS = 'SET_EDITABLE_STATUS',
  SET_TRANSLATIONS_STATUS = 'SET_TRANSLATIONS_STATUS',
}

export enum CategoryStatus {
  Inactive,
  Active,
}

export interface CategoryState {
  /**
   * List
   */
  status: ListStatus;
  list: Category[];
  listPagination: Pagination;

  /**
   * All categories
   */
  allStatus: ListStatus;
  allList: Category[];
  allFilters: ResourcesURLFilters;

  /**
   * Edit
   */
  editable?: Category;
  initialEditable?: Category;
  editableStatus: EditableStatus;
  translationsStatus: EditableStatus;
}
