import _Vue from 'vue';
import Modal from './Modal.vue';
import ModalsContainer from './ModalsContainer.vue';

const defaultComponentName = 'modal';
const unmountedRootErrorMessage =
  '[vue-js-modal] In order to render dynamic modals, a <modals-container> ' + 'component must be present on the page';

declare module 'vue/types/vue' {
  interface Vue {
    $modal: {
      show(modal: any, paramsOrProps?: any, params?: any, events?: any): void;
      hide(name: string, params?: any): void;
      toggle(name: string, params?: any): void;
    };
  }
}

const Plugin = {
  installed: false,
  event: {} as any,
  rootInstance: null,

  install(Vue) {
    /**
     * Makes sure that plugin can be installed only once
     */
    if (this.installed) {
      return;
    }

    this.installed = true;
    this.event = new Vue();
    this.rootInstance = null;
    this.componentName = defaultComponentName;

    Vue.prototype.$modal = {
      show(modal, paramsOrProps, params, events = {}) {
        if (typeof modal === 'string') {
          Plugin.event.$emit('toggle', modal, true, paramsOrProps);
          return;
        }

        const root = params && params.root ? params.root : Plugin.rootInstance;

        const container = getModalsContainer(Vue, root);

        if (container) {
          container.add(modal, paramsOrProps, params, events);
          return;
        }
      },
      hide(name, params) {
        Plugin.event.$emit('toggle', name, false, params);
      },

      toggle(name, params) {
        Plugin.event.$emit('toggle', name, undefined, params);
      },
    };

    /**
     * Sets custom component name (if provided)
     */
    Vue.component(this.componentName, Modal);

    /**
     * Registration of <ModalsContainer/> component
     *
     * Install it at the root
     */
    Vue.component('modals-container', ModalsContainer);
    Vue.mixin({
      beforeMount() {
        if (Plugin.rootInstance === null) {
          Plugin.rootInstance = this.$root;
        }
      },
    });
  },
};

function getModalsContainer(Vue, root) {
  if (!root._dynamicContainer) {
    const modalsContainer = document.createElement('div');
    document.body.appendChild(modalsContainer);

    new Vue({
      parent: root,
      render: (h) => h(ModalsContainer),
    }).$mount(modalsContainer);
  }

  return root._dynamicContainer;
}

export default Plugin;
