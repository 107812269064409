
import Vue from 'vue';
import { mapState, mapGetters, mapActions } from 'vuex';
import mixins from 'vue-typed-mixins';

import { formatNumber } from '@/i18n';

import CheckboxControl from '@/components/controls/checkbox.vue';

import tableViewMixin from '@/components/mixins/table-view';
import NotificationBanner from '@/components/blocks/notification-banner.vue';
import TableListSkeleton from '@/components/skeletons/table-list.vue';
import Dropdown, { DropdownItems } from '@/components/controls/dropdown.vue';
import PaginationControl from '@/components/controls/pagination.vue';
import ProductsFilter from '@/components/forms/products-filter.vue';
import Accessory, { AccessoryStatus } from '@/store/modules/products/accessory';
import { ProductsActions } from '@/store/modules/products/types';

export default mixins(tableViewMixin).extend({
  name: 'AccessoriesView',
  components: {
    NotificationBanner,
    TableListSkeleton,
    Dropdown,
    PaginationControl,
    ProductsFilter,
    CheckboxControl,
  },
  data() {
    return {
      selectedItems: [] as number[],
    };
  },
  computed: {
    ...mapGetters('user/profile', ['isSuperAdmin', 'isAdmin']),
    ...mapState('products', {
      accessories: 'accessoriesList',
      pagination: 'accessoriesListPagination',
    }),
    ...mapGetters('products', {
      isLoading: 'accessoriesListIsLoading',
      isLoaded: 'accessoriesListIsLoaded',
      isEmpty: 'accessoriesListIsEmpty',
    }),
    AccessoryStatus() {
      return AccessoryStatus;
    },
    areAllItemsSelected(): boolean {
      return this.selectedItems.length === this.accessories.length;
    },
  },
  methods: {
    formatNumber,
    ...mapActions('products', {
      restoreAction: ProductsActions.RESTORE_ACCESSORY,
      removeAction: ProductsActions.DELETE_ACCESSORY,
    }),
    dropdownItems(accessory: Accessory) {
      const items: DropdownItems = {
        edit: {
          label: this.$t('Edit'),
          icon: 'pencil',
        },
      };

      if (accessory.status === AccessoryStatus.Inactive) {
        items.restore = {
          label: this.$t('Restore'),
          icon: 'restore',
        };
      } else {
        items.remove = {
          label: this.$t('Delete'),
          hasTopBorder: true,
          icon: 'trash',
        };
      }

      return items;
    },
    handleClick(accessory: Accessory, action: string) {
      if ('edit' === action) {
        this.$router.push({
          name: 'edit-accessory',
          params: { id: String(accessory.id) },
        });
      }

      if ('restore' === action) {
        const answer = window.confirm(this.$t('Do you really want to restore this accessory?'));

        if (answer) {
          this.restoreAction({ id: accessory.id });
        }
      }

      if ('remove' === action) {
        const answer = window.confirm(this.$t('Do you really want to delete this accessory?'));

        if (answer) {
          this.removeAction({ id: accessory.id });
        }
      }
    },
    isSelected(accessory: Accessory): boolean {
      return this.selectedItems.indexOf(accessory.id) !== -1;
    },
    selectItem(accessory: Accessory) {
      if (this.isSelected(accessory)) {
        this.selectedItems = this.selectedItems.filter((item) => item !== accessory.id);
        return;
      }

      this.selectedItems.push(accessory.id);
    },
    selectAll() {
      if (this.areAllItemsSelected) {
        this.selectedItems = [];
      } else {
        this.selectedItems = this.accessories.map((accessory: Accessory) => accessory.id);
      }
    },
  },
});
