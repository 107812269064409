/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'calendar': {
    width: 24.7,
    height: 24.7,
    viewBox: '0 0 24.7 24.7',
    data: '<g data-name="Layer 2"><g data-name="Outline Icons"><path pid="0" d="M23.85 24.7h-23a.85.85 0 01-.85-.85v-21A.85.85 0 01.85 2h4a.85.85 0 010 1.7H1.7V23H23V3.7h-3.15a.85.85 0 110-1.7h4a.85.85 0 01.85.85v21a.85.85 0 01-.85.85z"/><path pid="1" d="M7.85 5.7h-3A.85.85 0 014 4.85v-4A.85.85 0 014.85 0h3a.85.85 0 01.85.85v4a.85.85 0 01-.85.85zM5.7 4H7V1.7H5.7zM19.85 5.7h-3a.85.85 0 01-.85-.85v-4a.85.85 0 01.85-.85h3a.85.85 0 01.85.85v4a.85.85 0 01-.85.85zM17.7 4H19V1.7h-1.3z"/><path pid="2" d="M16.85 3.7h-9a.85.85 0 010-1.7h9a.85.85 0 010 1.7zM23.85 8.7h-23a.85.85 0 110-1.7h23a.85.85 0 010 1.7z"/></g></g>'
  }
})
