
import Vue from 'vue';
import { mapState, mapGetters } from 'vuex';

export default Vue.extend({
  name: 'PractitionersMenuBlock',
  computed: {
    hasMoreMenuPractitioners() {
      return this.menuListTotalItems > 2;
    },
    numberOfPractitioners() {
      return this.isLoaded && this.menuListTotalItems > 0 ? '(' + this.menuListTotalItems + ')' : '';
    },
    ...mapState('practitioners', ['menuPractitionersList', 'menuListTotalItems']),
    ...mapGetters('practitioners', {
      isLoading: 'menuListIsLoading',
      isLoaded: 'menuListIsLoaded',
    }),
  },
});
