import { JSONModel } from '@/store/models/jsonModel';
import Banner, { BannerJSON } from '@/store/models/banner';
import { LegalType } from './legal';

export interface SettingsJSON {
  top_banner: BannerJSON;
  banned_words: string[];
  legal: Record<LegalType, string>;
}

export default class Settings extends JSONModel<SettingsJSON> {
  public topBanner: Banner = new Banner();
  public bannedWords: string[] = [];
  public legal: Record<LegalType, string> = {
    [LegalType.Modal]: '',
    [LegalType.Banner]: '',
    [LegalType.Privacy]: '',
  };

  protected _clientProperties = ['legal'];

  public fromJSON(json: Partial<SettingsJSON>) {
    if (json.top_banner) {
      this.topBanner = Banner.fromJSON(json.top_banner);
    }

    if (json.banned_words) {
      this.bannedWords = json.banned_words.filter(Boolean).map(String);
    }

    if (json.legal) {
      this.legal = json.legal;
    }
  }
}
