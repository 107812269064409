
import Vue from 'vue';
import { mapState, mapGetters, mapActions } from 'vuex';

import Modal from '@/components/layout/modal.vue';
import FormInput from '@/components/controls/form-input.vue';
import SubmitButton from '@/components/controls/submit-button.vue';
import NotificationBanner from '@/components/blocks/notification-banner.vue';
import TranslationsControl from '@/components/controls/translations.vue';

import Category from '@/store/modules/categories/category';
import { CategoryActions } from '@/store/modules/categories/types';
import { HistoryEntry } from '@/store/types';

export default Vue.extend({
  name: 'EditCategoryView',
  inject: {
    $validator: '$validator',
  },
  components: {
    Modal,
    FormInput,
    SubmitButton,
    NotificationBanner,
    TranslationsControl,
  },
  props: {
    add: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters(['isDirect', 'currentRoute']),

    /**
     * Categories
     */
    ...mapState('categories', {
      category: 'editable',
      translationsStatus: 'translationsStatus',
    }),
    ...mapGetters('categories', {
      isLoading: 'editableIsLoading',
      isLoaded: 'editableIsLoaded',
      isSaving: 'editableIsSaving',
      isSaved: 'editableIsSaved',
      isFailed: 'editableIsFailed',
      hasUnsavedChanges: 'editableHasUnsavedChanges',
    }),
    categoryType() {
      if(
        this.currentRoute.to.name == 'clinical-support-add-category' ||
        this.currentRoute.to.name == 'clinical-support-edit-category' ||
        this.currentRoute.to.name == 'clinical-support-add-resource/add-category' ||
        this.currentRoute.to.name == 'clinical-support-edit-resource/add-category')
      {
        return 'clinical-support';
      }
      return 'category';
    },
  },
  methods: {
    ...mapActions('categories', {
      updateCategory: CategoryActions.UPDATE_EDITABLE,
      saveCategory: CategoryActions.SAVE_EDITABLE,
      saveTranslations: CategoryActions.SAVE_TRANSLATIONS,
    }),
    goBack(): void {
      if (this.isDirect) {
        let parent = 'categories';

        if (this.currentRoute.to.name === 'edit-resource/add-category') {
          parent = 'edit-resource';
        } else if (this.currentRoute.to.name === 'add-resource/add-category') {
          parent = 'add-resource';
        }

        this.$router.push({
          name: parent,
        });
      } else {
        this.$router.back();
      }
    },
    close(): void {
      if (this.hasUnsavedChanges) {
        const answer = window.confirm(this.$t('Do you really want to leave? You have unsaved changes!'));

        if (answer) {
          this.goBack();
        }
      } else {
        this.goBack();
      }
    },
  },
});
