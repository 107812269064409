/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'preview': {
    width: 27.5,
    height: 20.5,
    viewBox: '0 0 27.5 20.5',
    data: '<g data-name="Layer 2"><g data-name="play"><g data-name="&lt;Group&gt;"><path pid="0" d="M21.06 20.25H6.44C3 20.25.25 17.78.25 14.75v-9c0-3 2.78-5.5 6.19-5.5h14.62c3.41 0 6.19 2.47 6.19 5.5v9c0 3.03-2.78 5.5-6.19 5.5zm-14.62-19a4.81 4.81 0 00-5.06 4.5v9a4.81 4.81 0 005.06 4.5h14.62a4.81 4.81 0 005.06-4.5v-9a4.81 4.81 0 00-5.06-4.5z"/><path pid="1" d="M21.06 20.5H6.44C2.89 20.5 0 17.92 0 14.75v-9C0 2.58 2.89 0 6.44 0h14.62c3.55 0 6.44 2.58 6.44 5.75v9c0 3.17-2.89 5.75-6.44 5.75zM6.44.5C3.16.5.5 2.85.5 5.75v9C.5 17.65 3.16 20 6.44 20h14.62c3.28 0 5.94-2.35 5.94-5.25v-9C27 2.85 24.34.5 21.06.5zm14.63 19H6.44a5.06 5.06 0 01-5.31-4.75v-9A5.06 5.06 0 016.44 1h14.62a5.06 5.06 0 015.31 4.75v9a5.06 5.06 0 01-5.31 4.75zM6.44 1.5a4.56 4.56 0 00-4.81 4.25v9A4.56 4.56 0 006.44 19h14.62a4.56 4.56 0 004.81-4.25v-9a4.56 4.56 0 00-4.81-4.25z"/></g></g><path pid="2" d="M19 14.43l-2.65-2.65a4.08 4.08 0 10-.68.68l2.65 2.65a.48.48 0 00.68 0 .48.48 0 000-.68zm-8.94-5.16a3.11 3.11 0 113.11 3.11 3.11 3.11 0 01-3.11-3.11z"/><path pid="3" d="M18.66 15.5a.73.73 0 01-.52-.21l-2.49-2.49a4.33 4.33 0 111-1l2.5 2.5a.73.73 0 010 1 .72.72 0 01-.49.2zm-3-3.37l2.8 2.8a.23.23 0 00.32 0 .23.23 0 000-.31l.17-.18-.18.18L16 11.8l.14-.17a3.83 3.83 0 00-.64-5.37 3.83 3.83 0 100 6zm-2.53.5a3.36 3.36 0 01-3.32-3.36 3.36 3.36 0 113.36 3.36zm-2.82-3.36a2.86 2.86 0 102.86-2.86 2.86 2.86 0 00-2.86 2.86z"/></g>'
  }
})
