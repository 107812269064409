import { NotificationType } from './types';
import { createCounter } from '@/store/utils/counter';
import * as moment from 'moment-timezone';

const getSnackbarId = createCounter();

export interface SnackbarOptions {
  icon?: string;
  title?: string;
  message: string;
  type?: NotificationType;
  time?: string | moment.Moment;
  timePrefix?: string;
}

export class Snackbar {
  public id: number;
  public icon?: string;
  public title?: string;
  public message: string;
  public type: NotificationType = NotificationType.Success;
  public time: moment.Moment;
  public timePrefix: string = '';

  constructor(options: SnackbarOptions) {
    this.id = getSnackbarId();

    if (options.icon) {
      this.icon = options.icon;
    }

    if (options.title) {
      this.title = options.title;
    }

    if (options.type) {
      this.type = options.type;
    }

    if (options.time) {
      this.time = moment.tz(options.time, 'UTC');
    } else {
      this.time = moment();
    }

    if (options.timePrefix) {
      this.timePrefix = options.timePrefix;
    }

    this.message = options.message;
  }
}
