import Vue from 'vue';
import { TreatmentState, TreatmentMutations } from '@/store/modules/treatments/types';
import TreatmentEnhancement from './enhancement';
import { ListStatus, MutationsTree, EditableStatus } from '@/store/types';
import Taxonomy from '@/store/models/taxonomy';
import Treatment from './treatment';
import Pagination from '@/store/models/pagination';
import { updateEditable } from '@/store/utils/editable';
import Product from '@/store/modules/products/product';
import PublicTreatment from './public-treatment';
import PublicSite from './public-site';

export const mutations: MutationsTree<TreatmentState, typeof TreatmentMutations> = {
  /**
   * List
   */
  [TreatmentMutations.SET_LIST](state, payload: Treatment[]) {
    state.list = payload;
  },
  [TreatmentMutations.SET_LIST_STATUS](state, payload: ListStatus) {
    state.listStatus = payload;
  },
  [TreatmentMutations.SET_LIST_PAGINATION](state, payload: Pagination) {
    state.listPagination = payload;
  },

  /**
   * Tree
   */
  [TreatmentMutations.SET_TREE](state, payload: Product[]) {
    state.treatmentsTree = payload;
  },
  [TreatmentMutations.SET_TREE_STATUS](state, payload: ListStatus) {
    state.treatmentsTreeStatus = payload;
  },
  [TreatmentMutations.SET_ALL_TREE](state, payload: Taxonomy[]) {
    state.allTreatments = payload;
  },
  [TreatmentMutations.SET_ALL_TREE_STATUS](state, payload: ListStatus) {
    state.allTreatmentsStatus = payload;
  },

  /**
   * Edit
   */
  [TreatmentMutations.SET_EDITABLE](state, treatment: Treatment) {
    Vue.set(state, 'editable', treatment);
    Vue.set(state, 'initialEditable', Treatment.clone(treatment));
  },
  [TreatmentMutations.SET_EDITABLE_STATUS](state, editableStatus: EditableStatus) {
    state.editableStatus = editableStatus;
  },
  [TreatmentMutations.UPDATE_EDITABLE]: updateEditable<TreatmentState>('editable'),
  [TreatmentMutations.UPDATE_INITIAL_EDITABLE]: updateEditable<TreatmentState>('initialEditable'),
  [TreatmentMutations.SET_TRANSLATIONS_STATUS](state, translationsStatus: EditableStatus) {
    state.translationsStatus = translationsStatus;
  },

  /**
   * Enhancements list
   */
  [TreatmentMutations.SET_ENHANCEMENT_LIST](state, data) {
    state.enhancementList = data;
  },
  [TreatmentMutations.SET_ENHANCEMENT_LIST_PAGINATION](state, data) {
    state.enhancementListPagination = data;
  },
  [TreatmentMutations.SET_ENHANCEMENT_LIST_STATUS](state, data) {
    state.enhancementListStatus = data;
  },

  /**
   * Enhancements tree
   */
  [TreatmentMutations.SET_ENHANCEMENTS_TREE](state, payload: TreatmentEnhancement[]) {
    state.enhancementsTree = payload;
  },
  [TreatmentMutations.SET_ENHANCEMENTS_TREE_STATUS](state, payload: ListStatus) {
    state.enhancementsTreeStatus = payload;
  },

  /**
   * Enhancements Edit
   */
  [TreatmentMutations.SET_ENHANCEMENT](state, enhancement: TreatmentEnhancement) {
    Vue.set(state, 'enhancement', enhancement);
    Vue.set(state, 'initialEnhancement', TreatmentEnhancement.clone(enhancement));
  },
  [TreatmentMutations.SET_ENHANCEMENT_STATUS](state, editableStatus: EditableStatus) {
    state.enhancementStatus = editableStatus;
  },
  [TreatmentMutations.UPDATE_ENHANCEMENT]: updateEditable<TreatmentState>('enhancement'),
  [TreatmentMutations.UPDATE_INITIAL_ENHANCEMENT]: updateEditable<TreatmentState>('initialEnhancement'),
  [TreatmentMutations.SET_ENHANCEMENT_TRANSLATIONS_STATUS](state, translationsStatus: EditableStatus) {
    state.enhancementTranslationsStatus = translationsStatus;
  },

  /**
   * Public Treatments list
   */
  [TreatmentMutations.SET_PUBLIC_TREATMENT_LIST](state, data) {
    state.publicTreatmentList = data;
  },
  [TreatmentMutations.SET_PUBLIC_TREATMENT_LIST_PAGINATION](state, data) {
    state.publicTreatmentListPagination = data;
  },
  [TreatmentMutations.SET_PUBLIC_TREATMENT_LIST_STATUS](state, data) {
    state.publicTreatmentListStatus = data;
  },

  /**
   * Public Treatment Edit
   */
  [TreatmentMutations.SET_PUBLIC_TREATMENT](state, publicTreatment: PublicTreatment) {
    Vue.set(state, 'publicTreatment', publicTreatment);
    Vue.set(state, 'initialPublicTreatment', PublicTreatment.clone(publicTreatment));
  },
  [TreatmentMutations.SET_PUBLIC_TREATMENT_STATUS](state, editableStatus: EditableStatus) {
    state.publicTreatmentStatus = editableStatus;
  },
  [TreatmentMutations.UPDATE_PUBLIC_TREATMENT]: updateEditable<TreatmentState>('publicTreatment'),
  [TreatmentMutations.UPDATE_INITIAL_PUBLIC_TREATMENT]: updateEditable<TreatmentState>('initialPublicTreatment'),
  [TreatmentMutations.SET_PUBLIC_TRANSLATIONS_STATUS](state, translationsStatus: EditableStatus) {
    state.publicTreatmentTranslationsStatus = translationsStatus;
  },

  /**
   * Public Sites list
   */
  [TreatmentMutations.SET_PUBLIC_SITE_LIST](state, data) {
    state.publicSiteList = data;
  },
  [TreatmentMutations.SET_PUBLIC_SITE_LIST_PAGINATION](state, data) {
    state.publicSiteListPagination = data;
  },
  [TreatmentMutations.SET_PUBLIC_SITE_LIST_STATUS](state, data) {
    state.publicSiteListStatus = data;
  },

  /**
   * Public Treatment Edit
   */
  [TreatmentMutations.SET_PUBLIC_SITE](state, publicSite: PublicSite) {
    Vue.set(state, 'publicSite', publicSite);
    Vue.set(state, 'initialPublicSite', PublicSite.clone(publicSite));
  },
  [TreatmentMutations.SET_PUBLIC_SITE_STATUS](state, editableStatus: EditableStatus) {
    state.publicSiteStatus = editableStatus;
  },
  [TreatmentMutations.UPDATE_PUBLIC_SITE]: updateEditable<TreatmentState>('publicSite'),
  [TreatmentMutations.UPDATE_INITIAL_PUBLIC_SITE]: updateEditable<TreatmentState>('initialPublicSite'),
};

export default mutations;
