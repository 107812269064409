/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'laser': {
    width: 21.74,
    height: 16.73,
    viewBox: '0 0 21.74 16.73',
    data: '<g data-name="Layer 2"><g data-name="Click 2"><g data-name="&lt;Group&gt;"><g data-name="&lt;Group&gt;"><path pid="0" data-name="&lt;Path&gt;" d="M16.76 9.25a.5.5 0 01.61-.36l3.87 1a.5.5 0 11-.25 1l-3.87-1a.5.5 0 01-.36-.64z" _stroke="#000" stroke-miterlimit="10" stroke-width=".25"/></g><g data-name="&lt;Group&gt;"><path pid="1" data-name="&lt;Path&gt;" d="M13.91 4.37l1-3.87a.5.5 0 011 .25l-1 3.87a.5.5 0 01-1-.25z" _stroke="#000" stroke-miterlimit="10" stroke-width=".25"/></g><g data-name="&lt;Group&gt;"><path pid="2" data-name="&lt;Path&gt;" d="M10.86 16l1-3.87a.5.5 0 011 .25l-1 3.87a.5.5 0 01-1-.25z" _stroke="#000" stroke-miterlimit="10" stroke-width=".25"/></g><g data-name="&lt;Group&gt;"><path pid="3" data-name="&lt;Path&gt;" d="M16.34 6.22a.5.5 0 01.23-.3L20 3.9a.5.5 0 01.5.86l-3.45 2a.5.5 0 01-.74-.56z" _stroke="#000" stroke-miterlimit="10" stroke-width=".25"/></g><g data-name="&lt;Group&gt;"><path pid="4" data-name="&lt;Path&gt;" d="M8.85 1.33a.5.5 0 01.92-.13l2 3.45a.5.5 0 01-.86.5l-2-3.45a.5.5 0 01-.06-.37z" _stroke="#000" stroke-miterlimit="10" stroke-width=".25"/></g><g data-name="&lt;Group&gt;"><path pid="5" data-name="&lt;Path&gt;" d="M14.91 11.69a.5.5 0 01.92-.13l2 3.45a.5.5 0 11-.86.5l-2-3.45a.5.5 0 01-.06-.37z" _stroke="#000" stroke-miterlimit="10" stroke-width=".25"/></g><path pid="6" d="M.13 8.31h0a.46.46 0 01.41-.49h13.18a.45.45 0 01.41.48h0a.46.46 0 01-.41.49L.54 8.8a.45.45 0 01-.41-.49z" _stroke="#000" stroke-miterlimit="10" stroke-width=".25"/></g></g></g>'
  }
})
