/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'arrow-thin': {
    width: 7,
    height: 5,
    viewBox: '0 0 7 5',
    data: '<g data-name="Layer 2"><g data-name="Isolation Mode"><path pid="0" d="M6.5 3h-6a.5.5 0 01-.5-.5.52.52 0 01.5-.5h6a.5.5 0 010 1z"/><path pid="1" d="M4.49 5a.5.5 0 01-.35-.85l1.65-1.64L4.15.85a.5.5 0 11.71-.7l2 2a.5.5 0 010 .71l-2 2a.5.5 0 01-.37.14z"/></g></g>'
  }
})
