/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'translate': {
    width: 16,
    height: 16,
    viewBox: '0 0 272 318',
    data: '<g _stroke="#000" fill-rule="evenodd"><path pid="0" d="M45.686 19.451c-14.817 0-26.873 12.055-26.873 26.872V227.04c0 14.82 12.056 26.87 26.873 26.87H68.39c4.15 0 7.5 3.36 7.5 7.5v28.89l39.35-34.53a7.513 7.513 0 014.95-1.86H226.4c14.82 0 26.87-12.05 26.87-26.87V46.323c0-14.817-12.05-26.872-26.87-26.872H45.686zM68.39 314.36c-1.05 0-2.1-.22-3.09-.67a7.494 7.494 0 01-4.41-6.83v-37.95H45.686c-23.089 0-41.873-18.78-41.873-41.87V46.323c0-23.087 18.784-41.872 41.873-41.872H226.4c23.09 0 41.87 18.785 41.87 41.872V227.04c0 23.09-18.78 41.87-41.87 41.87H123.01L73.34 312.5a7.526 7.526 0 01-4.95 1.86h0z" stroke-width="7"/><path pid="1" d="M190.15 210.8c-2.96 0-5.77-1.77-6.95-4.68L135.94 89.788 88.68 206.12a7.492 7.492 0 01-9.77 4.12c-3.84-1.55-5.69-5.93-4.13-9.77l54.21-133.432a7.505 7.505 0 016.95-4.676c3.05 0 5.8 1.849 6.95 4.676L197.1 200.47c1.55 3.84-.29 8.22-4.13 9.77-.92.38-1.88.56-2.82.56" stroke-width="6"/><path pid="2" d="M171.18 167.46H99.34c-4.15 0-7.5-3.36-7.5-7.501 0-4.142 3.35-7.5 7.5-7.5h71.84a7.501 7.501 0 010 15.001" stroke-width="6"/></g>'
  }
})
