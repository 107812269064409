import { JSONModel } from '@/store/models/jsonModel';

export interface CountryRelationshipJSON {
  id: number;
  code: string;
  name: string;
}

export default class CountryRelationship extends JSONModel<CountryRelationshipJSON> {
  public id: number = 0;
  public code: string = '';
  public name: string = '';

  public fromJSON(json: Partial<CountryRelationshipJSON>) {
    if (json.id) {
      this.id = Number(json.id);
    }

    if (json.code) {
      this.code = String(json.code);
      this.name = this.code;
    }

    if (json.name) {
      this.name = String(json.name);
    }
  }
}
