
import Vue from 'vue';
import mixins from 'vue-typed-mixins';
import { mapGetters, mapState, mapMutations, mapActions } from 'vuex';

import LocationsList from '@/components/blocks/locations-list.vue';
import PractitionersList from '@/components/blocks/practitioners-list.vue';
import ViewToggleControl from '@/components/controls/view-toggle.vue';
import { PracticesActions } from '@/store/modules/practices/types';
import FormModal from '@/components/layout/modal-form.vue';
import EditPracticeForm from '@/components/forms/practice.vue';
import { imageSizeMixin } from '@/components/mixins/image-size';

export default mixins(imageSizeMixin).extend({
  name: 'MyPracticeView',
  components: {
    LocationsList,
    PractitionersList,
    ViewToggleControl,
  },
  data() {
    return {
      locationsListView: 'grid',
      practitionersListView: 'grid',
    };
  },
  computed: {
    ...mapState('locations', {
      locationsPagination: 'pagination',
    }),
    ...mapState('practitioners', {
      practitionersPagination: 'pagination',
    }),
    ...mapState('practices', ['currentPractice']),
    ...mapGetters('locations', {
      locationsIsLoaded: 'listIsLoaded',
    }),
    ...mapGetters('practitioners', {
      practitionersIsLoaded: 'listIsLoaded',
    }),
    ...mapGetters('practices', {
      practiceIsReady: 'editableIsReady',
      practiceIsSaving: 'editableIsSaving',
    }),
  },
  methods: {
    defaultView(numberOfItems) {
      return numberOfItems > 5 ? 'table' : 'grid';
    },
    initDefaults() {
      this.locationsListView = this.defaultView(this.locationsPagination.totalItems);
      this.practitionersListView = this.defaultView(this.practitionersPagination.totalItems);
    },
    editPractice() {
      this.$modal.show(FormModal, { form: EditPracticeForm }, { name: 'edit-practice' });
    },
  },
  watch: {
    locationsIsLoaded: 'initDefaults',
    practitionersIsLoaded: 'initDefaults',
  },
  mounted() {
    this.initDefaults();
  },
});
