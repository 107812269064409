/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'phone': {
    width: 24.49,
    height: 24.5,
    viewBox: '0 0 24.49 24.5',
    data: '<g data-name="Layer 2"><g data-name="Layer 1"><path pid="0" d="M15.9 24.5a3.74 3.74 0 01-2.07-.62A47.18 47.18 0 01.62 10.66a3.76 3.76 0 01.47-4.72l1.17-1.16a2.82 2.82 0 013.89 0L8.27 6.9a2.76 2.76 0 010 3.89L8 11a46.65 46.65 0 005.44 5.44l.22-.22a2.75 2.75 0 013.89 0l2.12 2.12a2.75 2.75 0 010 3.89l-1.12 1.17a3.74 3.74 0 01-2.65 1.1zM4.2 5.48a1.25 1.25 0 00-.88.37L2.15 7a2.26 2.26 0 00-.28 2.83 45.67 45.67 0 0012.79 12.79 2.25 2.25 0 002.83-.28l1.17-1.16a1.25 1.25 0 000-1.77l-2.12-2.12a1.28 1.28 0 00-1.77 0l-.71.71a.75.75 0 01-1 0 48 48 0 01-6.6-6.6.75.75 0 010-1l.71-.71A1.25 1.25 0 007.21 8L5.08 5.84a1.25 1.25 0 00-.88-.36zm3.54 4.79zM23.74 13a.75.75 0 01-.75-.75A10.76 10.76 0 0012.24 1.5a.75.75 0 010-1.5 12.26 12.26 0 0112.25 12.25.75.75 0 01-.75.75z"/><path pid="1" d="M19.74 13a.75.75 0 01-.75-.75 6.76 6.76 0 00-6.75-6.75.75.75 0 110-1.5 8.26 8.26 0 018.25 8.25.75.75 0 01-.75.75z"/><path pid="2" d="M15.74 13a.75.75 0 01-.75-.75 2.75 2.75 0 00-2.75-2.75.75.75 0 110-1.5 4.25 4.25 0 014.25 4.25.75.75 0 01-.75.75z"/></g></g>'
  }
})
