import Vue from 'vue';
import * as domMethods from '@/utils/dom';

export const domUtils = Vue.extend({
  methods: {
    ...domMethods,
  },
});

export default domUtils;
