/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'video': {
    width: 14,
    height: 14,
    viewBox: '0 0 14 14',
    data: '<g data-name="Layer 2"><g data-name="Isolation Mode"><path pid="0" d="M4.53 10a.5.5 0 01-.5-.5v-5a.5.5 0 01.71-.46l5.5 2.5a.5.5 0 010 .91L4.74 10a.5.5 0 01-.21 0zM5 5.29v3.45L8.82 7z"/><path pid="1" d="M13.5 14H.5a.5.5 0 01-.5-.5V.5A.5.5 0 01.5 0h13a.5.5 0 01.5.5v13a.5.5 0 01-.5.5zM1 13h12V1H1z"/><path pid="2" d="M13.5 3H.5a.5.5 0 010-1h13a.5.5 0 010 1zM13.5 12H.5a.5.5 0 010-1h13a.5.5 0 010 1z"/></g></g>'
  }
})
