import isValid from 'date-fns/isValid';

import Image, { ImageJSON } from '@/store/models/image';
import { JSONModel, toJSONContext } from '@/store/models/jsonModel';
import LocationRelationship, { LocationRelationshipJSON } from '@/store/modules/locations/location-relationship';
import UserPractice, { UserPracticeJSON } from '@/store/modules/practices/user-practice';
import PractitionerRelationship, {
  PractitionerRelationshipJSON,
} from '@/store/modules/practitioners/practitioner-relationship';
import ProductRelationship, { ProductRelationshipJSON } from '@/store/modules/products/product-relationship';
import { ProfileStatus, UserRole } from './types';
import { CountryJSON } from '@/store/models/country';

export interface ProfileJSON {
  id: number;
  status: ProfileStatus;
  terms_agree: 0 | 1;
  uid: number;
  practice_id?: number;
  language_id: number;
  language_code: string;
  name: string;
  email: string;
  first_name: string;
  last_name: string;
  log?: string;
  login: string;
  last_login: string;
  created_at: string;
  updated_at: string;
  login_count: number;
  login_monthly_count: number;
  blog_token: string;
  blog_token_expires: number;
  rss_token: string;
  agreed_to_upgrade: 0 | 1;
  role: UserRole;
  impersonating: 0 | 1;
  origin_user: string;
  locale: string;
  practice?: UserPracticeJSON;
  image_id?: number | null;
  image?: ImageJSON;
  listings: LocationRelationshipJSON[];
  practitioners: PractitionerRelationshipJSON[];
  products?: ProductRelationshipJSON[];
  personalization?: string[];
  countries: CountryJSON[];
}

export class Profile extends JSONModel<ProfileJSON & { password?: string }> {
  public id: number = 0;
  public status: ProfileStatus = ProfileStatus.Initial;
  public termsAgree: 0 | 1 = 0;
  public uid: number = 0;
  public practiceId?: number;
  public languageId: number = 0;
  public languageCode: string = 'en';
  public name: string = '';
  public email: string = '';
  public firstName: string = '';
  public lastName: string = '';
  public log?: string;
  public login: string = '';
  public lastLogin = new Date();
  public createdAt = new Date();
  public updatedAt = new Date();
  public loginCount: number = 0;
  public loginMonthlyCount: number = 0;
  public blogToken: string = '';
  public blogTokenExpires: number = 0;
  public rssToken: string = '';
  public agreedToUpgrade: 0 | 1 = 0;
  public role: UserRole = UserRole.User;
  public impersonating: 0 | 1 = 0;
  public originUser?: string;
  public locale: string = 'en';
  public practice?: UserPracticeJSON;
  public image?: Image;
  public listings: LocationRelationship[] = [];
  public practitioners: PractitionerRelationship[] = [];
  public products?: ProductRelationship[] = [];
  public password?: string;
  public personalization: string[] = [];
  public countries: CountryJSON[] = [];

  get thumbnail(): string {
    if (this.image && this.image.thumbnail) {
      return this.image.thumbnail;
    }

    return require('@/assets/images/profile-empty-150.png');
  }

  get displayName() {
    return [this.firstName, this.lastName].filter((item) => item.trim().length).join(' ');
  }

  fromJSON(json: ProfileJSON & { password?: string }) {
    if (json.id) {
      this.id = Number(json.id);
    }

    if (json.status) {
      this.status = Number(json.status);
    }

    if (json.terms_agree === 1) {
      this.termsAgree = 1;
    }

    if (json.uid) {
      this.uid = Number(json.uid);
    }

    if (json.practice_id) {
      this.practiceId = Number(json.practice_id);
    }

    if (json.language_id) {
      this.languageId = Number(json.language_id);
    }

    if (json.language_code) {
      this.languageCode = String(json.language_code);
    }

    if (json.name) {
      this.name = String(json.name);
    }

    if (json.email) {
      this.email = String(json.email);
    }

    if (json.first_name) {
      this.firstName = String(json.first_name);
    }

    if (json.last_name) {
      this.lastName = String(json.last_name);
    }

    if (json.log) {
      this.log = String(json.log);
    }

    if (json.login) {
      this.login = String(json.login);
    }

    if (json.last_login) {
      this.lastLogin = new Date(json.last_login);
    }

    if (json.created_at) {
      this.createdAt = new Date(json.created_at);
    }

    if (json.updated_at) {
      this.updatedAt = new Date(json.updated_at);
    }

    if (json.login_count) {
      this.loginCount = Number(json.login_count);
    }

    if (json.login_monthly_count) {
      this.loginMonthlyCount = Number(json.login_monthly_count);
    }

    if (json.blog_token) {
      this.blogToken = String(json.blog_token);
    }

    if (json.blog_token_expires) {
      this.blogTokenExpires = Number(json.blog_token_expires);
    }

    if (json.rss_token) {
      this.rssToken = String(json.rss_token);
    }

    if (json.agreed_to_upgrade === 1) {
      this.agreedToUpgrade = 1;
    }

    if (json.role) {
      this.role = json.role;
    }

    if (json.impersonating === 1) {
      this.impersonating = 1;
    }

    if (json.origin_user) {
      this.originUser = String(json.origin_user);
    }

    if (json.locale) {
      this.locale = String(json.locale);
    }

    if (json.practice && !Array.isArray(json.practice)) {
      this.practice = UserPractice.fromJSON(json.practice);
    }

    if (json.image && !Array.isArray(json.image)) {
      this.image = Image.fromJSON(json.image);
    }

    if (json.listings && Array.isArray(json.listings)) {
      this.listings = json.listings.map((item) => LocationRelationship.fromJSON(item));
    }

    if (json.practitioners && Array.isArray(json.practitioners)) {
      this.practitioners = json.practitioners.map((item) => PractitionerRelationship.fromJSON(item));
    }

    if (json.products && Array.isArray(json.products)) {
      this.products = json.products.map((item) => ProductRelationship.fromJSON(item));
    }

    if (json.password) {
      this.password = json.password;
    }

    if (json.personalization && Array.isArray(json.personalization)) {
      this.personalization = json.personalization;
    }

    if (json.countries && Array.isArray(json.countries)) {
      this.countries = json.countries;
    }

  }

  public toJSON(context: toJSONContext = 'server') {
    const json = super.toJSON(context);

    if (json.image) {
      json.image_id = json.image.id;
    }

    if (isValid(this.lastLogin)) {
      json.last_login = this.lastLogin.toISOString();
    } else {
      json.last_login = '';
    }

    if (isValid(this.createdAt)) {
      json.created_at = this.createdAt.toISOString();
    } else {
      json.created_at = '';
    }

    if (isValid(this.updatedAt)) {
      json.updated_at = this.updatedAt.toISOString();
    } else {
      json.updated_at = '';
    }

    return json;
  }
}
