import { UserJSON } from '@/store/models/user';
import { DownloadJSON } from '@/store/modules/statistics/download';
import { LoginJSON } from '@/store/modules/statistics/login';
import { TrainingJSON } from '@/store/modules/statistics/training';
import httpClient from '../http-client';
import { Paginated, RequestConfig } from '../types';

export function getDownloads(params: any, config?: RequestConfig) {
  return httpClient.get<Paginated<DownloadJSON[]>>('statistics/downloads', { params, ...config });
}

export function getLogins(params: any, config?: RequestConfig) {
  return httpClient.get<Paginated<LoginJSON[]>>('statistics/logins', { params, ...config });
}

export function getUsers(params: any, config?: RequestConfig) {
  return httpClient.get<Paginated<UserJSON[]>>('statistics/users', { params, ...config });
}

export function getTrainings(params: any, config?: RequestConfig) {
  return httpClient.get<Paginated<TrainingJSON[]>>('statistics/trainings', { params, ...config });
}
