import { ListStatus, EditableStatus } from '@/store/types';
import Taxonomy from '@/store/models/taxonomy';
import Pagination from '@/store/models/pagination';
import Product from '@/store/modules/products/product';

import Treatment from './treatment';
import TreatmentEnhancement from './enhancement';
import PublicTreatment from './public-treatment';
import PublicSite from './public-site';

export enum TreatmentActions {
  /**
   * List
   */
  LOAD_LIST = 'LOAD_LIST',

  /**
   * Tree
   */
  LOAD_TREE = 'LOAD_TREE',
  LOAD_ALL_TREE = 'LOAD_ALL_TREE',

  /**
   * Edit
   */
  LOAD_EDITABLE = 'LOAD_EDITABLE',
  UPDATE_EDITABLE = 'UPDATE_EDITABLE',
  SAVE_EDITABLE = 'SAVE_EDITABLE',
  SAVE_TRANSLATIONS = 'SAVE_TRANSLATIONS',

  /**
   * Actions
   */
  DELETE = 'DELETE',
  RESTORE = 'RESTORE',

  /**
   * Bulk actions
   */
  BULK_DELETE = 'BULK_DELETE',
  BULK_RESTORE = 'BULK_RESTORE',

  /**
   * Enhancements list
   */
  LOAD_ENHANCEMENT_LIST = 'LOAD_ENHANCEMENT_LIST',

  /**
   * Enhancements tree
   */
  LOAD_ENHANCEMENTS_TREE = 'LOAD_ENHANCEMENTS_TREE',

  /**
   * Enhancements edit
   */
  LOAD_ENHANCEMENT = 'LOAD_ENHANCEMENT',
  UPDATE_ENHANCEMENT = 'UPDATE_ENHANCEMENT',
  SAVE_ENHANCEMENT = 'SAVE_ENHANCEMENT',
  SAVE_ENHANCEMENT_TRANSLATIONS = 'SAVE_ENHANCEMENT_TRANSLATIONS',

  /**
   * Enhancements actions
   */
  DELETE_ENHANCEMENT = 'DELETE_ENHANCEMENT',
  RESTORE_ENHANCEMENT = 'RESTORE_ENHANCEMENT',

  /**
   * Bulk actions
   */
  BULK_DELETE_ENHANCEMENT = 'BULK_DELETE_ENHANCEMENT',
  BULK_RESTORE_ENHANCEMENT = 'BULK_RESTORE_ENHANCEMENT',

  /**
   * Public Treatments list
   */
  LOAD_PUBLIC_TREATMENT_LIST = 'LOAD_PUBLIC_TREATMENT_LIST',

  /**
   * Public Treatments edit
   */
  LOAD_PUBLIC_TREATMENT = 'LOAD_PUBLIC_TREATMENT',
  UPDATE_PUBLIC_TREATMENT = 'UPDATE_PUBLIC_TREATMENT',
  SAVE_PUBLIC_TREATMENT = 'SAVE_PUBLIC_TREATMENT',
  SAVE_PUBLIC_TRANSLATIONS = 'SAVE_PUBLIC_TRANSLATIONS',

  /**
   * Public Treatments actions
   */
  DELETE_PUBLIC_TREATMENT = 'DELETE_PUBLIC_TREATMENT',
  RESTORE_PUBLIC_TREATMENT = 'RESTORE_PUBLIC_TREATMENT',

  /**
   * Public Treatments bulk actions
   */
  BULK_DELETE_PUBLIC_TREATMENT = 'BULK_DELETE_PUBLIC_TREATMENT',
  BULK_RESTORE_PUBLIC_TREATMENT = 'BULK_RESTORE_PUBLIC_TREATMENT',

  /**
   * Public Sites list
   */
  LOAD_PUBLIC_SITE_LIST = 'LOAD_PUBLIC_SITE_LIST',

  /**
   * Public Sites edit
   */
  LOAD_PUBLIC_SITE = 'LOAD_PUBLIC_SITE',
  UPDATE_PUBLIC_SITE = 'UPDATE_PUBLIC_SITE',
  SAVE_PUBLIC_SITE = 'SAVE_PUBLIC_SITE',

  /**
   * Public Sites actions
   */
  DELETE_PUBLIC_SITE = 'DELETE_PUBLIC_SITE',
  RESTORE_PUBLIC_SITE = 'RESTORE_PUBLIC_SITE',

  /**
   * Public Sites bulk actions
   */
  BULK_DELETE_PUBLIC_SITE = 'BULK_DELETE_PUBLIC_SITE',
  BULK_RESTORE_PUBLIC_SITE = 'BULK_RESTORE_PUBLIC_SITE',
}

export enum TreatmentMutations {
  /**
   * List
   */
  SET_LIST = 'SET_LIST',
  SET_LIST_STATUS = 'SET_LIST_STATUS',
  SET_LIST_PAGINATION = 'SET_LIST_PAGINATION',

  /**
   * Tree
   */
  SET_TREE = 'SET_TREE',
  SET_TREE_STATUS = 'SET_TREE_STATUS',
  SET_ALL_TREE = 'SET_ALL_TREE',
  SET_ALL_TREE_STATUS = 'SET_ALL_TREE_STATUS',

  /**
   * Edit
   */
  SET_EDITABLE = 'SET_EDITABLE',
  SET_EDITABLE_STATUS = 'SET_EDITABLE_STATUS',
  UPDATE_EDITABLE = 'UPDATE_EDITABLE',
  UPDATE_INITIAL_EDITABLE = 'UPDATE_INITIAL_EDITABLE',
  SET_TRANSLATIONS_STATUS = 'SET_TRANSLATIONS_STATUS',

  /**
   * Enhancements list
   */
  SET_ENHANCEMENT_LIST = 'SET_ENHANCEMENT_LIST',
  SET_ENHANCEMENT_LIST_STATUS = 'SET_ENHANCEMENT_LIST_STATUS',
  SET_ENHANCEMENT_LIST_PAGINATION = 'SET_ENHANCEMENT_LIST_PAGINATION',

  /**
   * Enhancements tree
   */
  SET_ENHANCEMENTS_TREE = 'SET_ENHANCEMENTS_TREE',
  SET_ENHANCEMENTS_TREE_STATUS = 'SET_ENHANCEMENTS_TREE_STATUS',

  /**
   * Enhancements Edit
   */
  SET_ENHANCEMENT = 'SET_ENHANCEMENT',
  SET_ENHANCEMENT_STATUS = 'SET_ENHANCEMENT_STATUS',
  UPDATE_ENHANCEMENT = 'UPDATE_ENHANCEMENT',
  UPDATE_INITIAL_ENHANCEMENT = 'UPDATE_INITIAL_ENHANCEMENT',
  SET_ENHANCEMENT_TRANSLATIONS_STATUS = 'SET_ENHANCEMENT_TRANSLATIONS_STATUS',

  /**
   * Public Treatments list
   */
  SET_PUBLIC_TREATMENT_LIST = 'SET_PUBLIC_TREATMENT_LIST',
  SET_PUBLIC_TREATMENT_LIST_STATUS = 'SET_PUBLIC_TREATMENT_LIST_STATUS',
  SET_PUBLIC_TREATMENT_LIST_PAGINATION = 'SET_PUBLIC_TREATMENT_LIST_PAGINATION',

  /**
   * Public Treatments Edit
   */
  SET_PUBLIC_TREATMENT = 'SET_PUBLIC_TREATMENT',
  SET_PUBLIC_TREATMENT_STATUS = 'SET_PUBLIC_TREATMENT_STATUS',
  UPDATE_PUBLIC_TREATMENT = 'UPDATE_PUBLIC_TREATMENT',
  UPDATE_INITIAL_PUBLIC_TREATMENT = 'UPDATE_INITIAL_PUBLIC_TREATMENT',
  SET_PUBLIC_TRANSLATIONS_STATUS = 'SET_PUBLIC_TRANSLATIONS_STATUS',

  /**
   * Public Sites list
   */
  SET_PUBLIC_SITE_LIST = 'SET_PUBLIC_SITE_LIST',
  SET_PUBLIC_SITE_LIST_STATUS = 'SET_PUBLIC_SITE_LIST_STATUS',
  SET_PUBLIC_SITE_LIST_PAGINATION = 'SET_PUBLIC_SITE_LIST_PAGINATION',

  /**
   * Public Sites Edit
   */
  SET_PUBLIC_SITE = 'SET_PUBLIC_SITE',
  SET_PUBLIC_SITE_STATUS = 'SET_PUBLIC_SITE_STATUS',
  UPDATE_PUBLIC_SITE = 'UPDATE_PUBLIC_SITE',
  UPDATE_INITIAL_PUBLIC_SITE = 'UPDATE_INITIAL_PUBLIC_SITE',
}

export interface TreatmentState {
  /**
   * List
   */
  list: Treatment[];
  listStatus: ListStatus;
  listPagination: Pagination;

  /**
   * Tree
   */
  treatmentsTreeStatus: ListStatus;
  treatmentsTree: Product[];
  allTreatments: Taxonomy[];
  allTreatmentsStatus: ListStatus;

  /**
   * Edit
   */
  editable?: Treatment;
  initialEditable?: Treatment;
  editableStatus: EditableStatus;
  translationsStatus: EditableStatus;

  /**
   * Enhancements list
   */
  enhancementList: TreatmentEnhancement[];
  enhancementListStatus: ListStatus;
  enhancementListPagination: Pagination;

  /**
   * Enahancements tree
   */
  enhancementsTree: TreatmentEnhancement[];
  enhancementsTreeStatus: ListStatus;

  /**
   * Enahancements Edit
   */
  enhancement?: TreatmentEnhancement;
  initialEnhancement?: TreatmentEnhancement;
  enhancementStatus: EditableStatus;
  enhancementTranslationsStatus: EditableStatus;

  /**
   * Public Treatments list
   */
  publicTreatmentList: PublicTreatment[];
  publicTreatmentListStatus: ListStatus;
  publicTreatmentListPagination: Pagination;

  /**
   * Public Treatment Edit
   */
  publicTreatment?: PublicTreatment;
  initialPublicTreatment?: PublicTreatment;
  publicTreatmentStatus: EditableStatus;
  publicTreatmentTranslationsStatus: EditableStatus;

  /**
   * Public Sites list
   */
  publicSiteList: PublicSite[];
  publicSiteListStatus: ListStatus;
  publicSiteListPagination: Pagination;

  /**
   * Public Site Edit
   */
  publicSite?: PublicSite;
  initialPublicSite?: PublicSite;
  publicSiteStatus: EditableStatus;
}
