
import Vue from 'vue';
import { mapGetters, mapActions, mapState } from 'vuex';

import { formatNumber } from '@/i18n';

import Product, { ProductStatus } from '@/store/modules/products/product';
import { ProductsActions } from '@/store/modules/products/types';

import CheckboxControl from '@/components/controls/checkbox.vue';
import tableViewMixin from '@/components/mixins/table-view';
import NotificationBanner from '@/components/blocks/notification-banner.vue';
import TableListSkeleton from '@/components/skeletons/table-list.vue';
import Dropdown, { DropdownItems } from '@/components/controls/dropdown.vue';
import PaginationControl from '@/components/controls/pagination.vue';
import ProductsFilter from '@/components/forms/products-filter.vue';
import i18n from '@/components/i18n';
import mixins from 'vue-typed-mixins';

export default mixins(tableViewMixin).extend({
  name: 'ProductsView',
  components: {
    NotificationBanner,
    TableListSkeleton,
    Dropdown,
    PaginationControl,
    ProductsFilter,
    CheckboxControl,
    i18n,
  },
  data() {
    return {
      selectedItems: [],
    };
  },
  computed: {
    ProductStatus() {
      return ProductStatus;
    },
    ...mapGetters('user/profile', ['isSuperAdmin', 'isAdmin']),
    ...mapState('products', {
      products: 'list',
      pagination: 'listPagination',
    }),
    ...mapGetters('products', {
      isLoading: 'listIsLoading',
      isLoaded: 'listIsLoaded',
      isEmpty: 'listIsEmpty',
    }),
    areAllItemsSelected(): boolean {
      return this.selectedItems.length === this.products.length;
    },
  },
  methods: {
    formatNumber,
    ...mapActions('products', {
      restoreAction: ProductsActions.RESTORE,
    }),
    dropdownItems(product: Product) {
      const items: DropdownItems = {
        edit: {
          label: this.$t('Edit'),
          icon: 'pencil',
        },
      };

      if (product.status === ProductStatus.Inactive) {
        items.restore = {
          label: this.$t('Restore'),
          icon: 'restore',
        };
      }

      return items;
    },
    handleClick(product: Product, action: string) {
      if ('edit' === action) {
        this.$router.push({
          name: 'edit-product',
          params: { id: String(product.id) },
        });
      }

      if ('restore' === action) {
        const answer = window.confirm(this.$t('Do you really want to restore this product?'));

        if (answer) {
          this.restoreAction({ id: product.id });
        }
      }
    },
    isSelected(product: Product): boolean {
      return this.selectedItems.indexOf(product.id) !== -1;
    },
    selectItem(product: Product) {
      if (this.isSelected(product)) {
        this.selectedItems = this.selectedItems.filter((item) => item !== product.id);
        return;
      }

      this.selectedItems.push(product.id);
    },
    selectAll() {
      if (this.areAllItemsSelected) {
        this.selectedItems = [];
      } else {
        this.selectedItems = this.products.map((product: Product) => product.id);
      }
    },
  },
});
