
import Vue from 'vue';

export default Vue.extend({
  name: 'SubmitButtonControl',
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    failed: {
      type: Boolean,
      default: false,
    },
    success: {
      type: Boolean,
      default: false,
    },
    primary: {
      type: Boolean,
      default: true,
    },
    transparent: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: '',
      validator: (value: string) => ['submit', '', 'button'].indexOf(value) !== -1,
    },
    fullWidth: {
      type: Boolean,
      default: true,
    },
    small: {
      type: Boolean,
      default: false,
    },
    form: {
      type: String,
    },
  },
  computed: {
    currentClasses(): object {
      return {
        'c-button': true,
        'c-button--full': this.fullWidth,
        'c-button--error': this.failed ? true : false,
        'c-button--success': this.success ? true : false,
        'c-button--loading': this.loading ? true : false,
        'c-button--disabled': this.disabled ? true : false,
        'c-button--primary-transparent': this.primary && this.transparent ? true : false,
        'c-button--primary': this.primary && !this.transparent ? true : false,
        'c-button--secondary-transparent': this.secondary && this.transparent ? true : false,
        'c-button--secondary': this.secondary && !this.transparent ? true : false,
        'c-button--small': this.small,
      };
    },
    secondary(): boolean {
      return !this.primary;
    },
    componentTag(): string {
      return this.type === 'submit' || this.type === 'button' ? 'button' : 'a';
    },
  },
  methods: {
    clickHandler(event) {
      if (this.$listeners.click) {
        event.preventDefault();

        if (!this.disabled) {
          this.$emit('click');
        }
      }
    },
  },
});
