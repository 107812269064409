
import Vue from 'vue';

export default Vue.extend({
  props: {
    tabs: {
      type: Object as () => Record<string, string | { title: string; count?: number }>,
      required: true,
    },
  },
  data() {
    return {
      activeTab: Object.keys(this.tabs)[0],
    };
  },
});
