import actions from './actions';
import mutations from './mutations';
import getters from './getters';
import { LocationsState } from '@/store/modules/locations/types';
import { RootState, ListStatus, EditableStatus } from '@/store/types';
import { Module } from 'vuex';
import Pagination from '@/store/models/pagination';

export const getInitialState = (): LocationsState => ({
  /**
   * Preview flag
   */
  isPreviewFromEditScreen: false,
  /**
   * List
   */
  listStatus: ListStatus.Loading,
  locationsList: [],
  pagination: new Pagination(),

  /**
   * Menu list
   */
  menuListStatus: ListStatus.Loading,
  menuLocationsList: [],
  menuListTotalItems: 0,

  /**
   * Pending list
   */
  pendingListStatus: ListStatus.Loading,
  pendingList: [],
  pendingListTotalItems: 0,

  /**
   * Published list
   */
  publishedListStatus: ListStatus.Loading,
  publishedListTotalItems: 0,

  /**
   * Edit
   */
  editableStatus: EditableStatus.Loading,
  autosaveStatus: EditableStatus.Loading,
  hasAutosave: false,

  /**
   * Quick Review
   */
  quickReviewStatus: EditableStatus.Loading,
  quickReviewTotal: 0,
});

export const state: LocationsState = getInitialState();

const locations: Module<LocationsState, RootState> = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

export default locations;
