import { GetterTree } from 'vuex';
import { RootState, ListStatus } from '@/store/types';
import { CategoryState } from '@/store/modules/categories/types';
import { createEditableGetters } from '@/store/utils/editable';

const editableGetters = createEditableGetters<CategoryState>('editableStatus', 'editable', 'initialEditable');

export const moduleGetters: GetterTree<CategoryState, RootState> = {
  isLoading: (state) => state.status === ListStatus.Loading,
  isLoaded: (state) => state.status === ListStatus.Loaded,
  isError: (state) => state.status === ListStatus.Failed,
  isEmpty: (state, getters) => getters.isLoaded && !state.list.length,

  /**
   * All categories
   */
  isAllLoading: (state) => state.allStatus === ListStatus.Loading,
  isAllLoaded: (state) => state.allStatus === ListStatus.Loaded,
  isAllError: (state) => state.allStatus === ListStatus.Failed,
  isAllEmpty: (state, getters) => getters.isAllLoaded && !state.allList.length,

  /**
   * Edit
   */
  editableIsLoading: editableGetters.isLoading,
  editableIsLoaded: editableGetters.isLoaded,
  editableIsSaving: editableGetters.isSaving,
  editableIsSaved: editableGetters.isSaved,
  editableIsFailed: editableGetters.isFailed,
  editableHasUnsavedChanges: editableGetters.hasUnsavedChanges,
};

export default moduleGetters;
