import Email from '@/store/modules/settings/email';
import EasyMDE from 'easymde';

interface ArrayOneOrMore<T> extends Array<T> {
  0: T;
}

export interface EmailEditorOptions extends EasyMDE.Options {
  toolbar: Exclude<EasyMDE.Options['toolbar'], boolean | undefined>;
}

function insertText(editor: EasyMDE, text: string) {
  const cm = editor.codemirror;

  if (/editor-preview-active/.test((cm.getWrapperElement()!.lastChild! as HTMLElement).className)) {
    return;
  }

  const startPoint = cm.getCursor('start');
  const endPoint = cm.getCursor('end');
  const selectionText = cm.getSelection();
  cm.replaceSelection(selectionText + text);

  endPoint.ch += text.length;

  cm.setSelection(startPoint, endPoint);
  cm.focus();
}

const sharedEditorOptions: EmailEditorOptions = {
  sideBySideFullscreen: false,
  spellChecker: false,
  status: false,
  toolbar: ['bold', 'italic', 'heading', '|', 'unordered-list', 'link', '|', 'clean-block', 'preview', 'guide'],
  toolbarTips: true,
};

export function getEmailEditorOptions(email?: Email) {
  const options: EmailEditorOptions = {
    ...sharedEditorOptions,
  };

  if (!email) {
    return options;
  }

  const substitutions = Object.values(email.substitutions);

  if (!substitutions.length) {
    return options;
  }

  options.toolbar = options.toolbar.concat(['|']);

  const substitutionsToolbarList: (EasyMDE.ToolbarIcon & { icon?: string })[] = substitutions.map((substitution) => ({
    name: substitution.key,
    title: substitution.readable,
    className: 'easymde-text-button',
    action: (editor) => {
      insertText(editor, substitution.key);
    },
    icon: substitution.readable,
  }));

  const substitutionsToolbarDropdown: EasyMDE.ToolbarDropdownIcon & {
    icon?: string;
    children: ArrayOneOrMore<EasyMDE.ToolbarIcon & { icon?: string }>;
  } = {
    name: 'substitions',
    title: 'Substitions',
    className: 'easymde-text-button',
    children: substitutionsToolbarList as ArrayOneOrMore<EasyMDE.ToolbarIcon & { icon?: string }>,
    icon: 'Substitions',
  };

  options.toolbar = options.toolbar.concat([substitutionsToolbarDropdown]);

  return options;
}
