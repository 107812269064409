import Taxonomy, { TaxonomyJSON } from '@/store/models/taxonomy';
import { JSONModel } from '@/store/models/jsonModel';

export interface AccessoryTreeItemJSON {
  id: number;
  title?: string;
  treatments?: TaxonomyJSON[];
}

export default class AccessoryTree extends JSONModel<AccessoryTreeItemJSON> {
  public id: number = 0;
  public title: string = '';
  public treatments: Taxonomy[] = [];

  public fromJSON(json: AccessoryTreeItemJSON) {
    this.id = Number(json.id);

    if (json.title) {
      this.title = String(json.title);
    }

    if (json.treatments) {
      this.treatments = json.treatments.map((item) => Taxonomy.fromJSON(item));
    }

    return this;
  }
}
