import { MutationTree } from 'vuex';
import { PracticesMutations, PracticesState } from '@/store/modules/practices/types';
import Practice from './practice';
import Vue from 'vue';
import { EditableStatus, ListStatus } from '@/store/types';
import Pagination from '@/store/models/pagination';
import { updateEditable } from '@/store/utils/editable';

export const mutations: MutationTree<PracticesState> = {
  /**
   * List
   */
  [PracticesMutations.SET_LIST](state, payload: Practice[]) {
    state.list = payload;
  },
  [PracticesMutations.SET_LIST_PAGINATION](state, payload: Pagination) {
    state.listPagination = payload;
  },
  [PracticesMutations.SET_LIST_STATUS](state, payload: ListStatus) {
    state.listStatus = payload;
  },

  /**
   * View & Edit
   */
  [PracticesMutations.SET_PRACTICE](state, practice: Practice) {
    Vue.set(state, 'editablePractice', practice);
    Vue.set(state, 'currentPractice', Practice.clone(practice));
  },
  [PracticesMutations.SET_EDITABLE_STATUS](state, editableStatus: EditableStatus) {
    state.editableStatus = editableStatus;
  },
  [PracticesMutations.UPDATE_EDITABLE]: updateEditable<PracticesState>('editablePractice'),
  [PracticesMutations.UPDATE_INITIAL_EDITABLE]: updateEditable<PracticesState>('currentPractice'),

  /**
   * User practice
   */
  [PracticesMutations.SET_USER_PRACTICE](state, data?: Practice) {
    state.userPractice = data;
  },
  [PracticesMutations.SET_USER_PRACTICE_STATUS](state, data: ListStatus) {
    state.userPracticeStatus = data;
  },
};

export default mutations;
