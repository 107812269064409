/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'practitioner': {
    width: 23.8,
    height: 24.3,
    viewBox: '0 0 23.8 24.3',
    data: '<g data-name="Layer 2"><g data-name="Outline Icons"><path pid="0" d="M6.15 8.3a4.15 4.15 0 114.15-4.15A4.15 4.15 0 016.15 8.3zm0-7A2.85 2.85 0 109 4.15 2.85 2.85 0 006.15 1.3zM8.15 24.3h-4a.65.65 0 01-.65-.65v-6.14C1.27 16.06 0 13.22 0 9.65A.65.65 0 01.65 9h11a.65.65 0 01.65.65c0 3.57-1.27 6.41-3.5 7.86v6.14a.65.65 0 01-.65.65zM4.8 23h2.7v-5.85a.65.65 0 01.33-.56c1.88-1.07 3-3.34 3.16-6.29H1.32c.14 2.95 1.28 5.22 3.16 6.29a.65.65 0 01.33.56zM23.15 16.3h-9.5a.65.65 0 010-1.3h8.85V2.3h-9.85a.65.65 0 010-1.3h10.5a.65.65 0 01.65.65v14a.65.65 0 01-.65.65z"/><path pid="1" d="M14.09 12.74a.65.65 0 01-.61-.88l1.88-5a.65.65 0 01.91-.35l2.39 1.25 1.26-3.78a.65.65 0 111.23.41l-1.5 4.5a.65.65 0 01-.92.37L16.31 8l-1.61 4.31a.65.65 0 01-.61.43z"/></g></g>'
  }
})
