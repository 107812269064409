import { Module } from 'vuex';

import { RootState, ListStatus, EditableStatus } from '@/store/types';
import Pagination from '@/store/models/pagination';
import { TreatmentState } from '@/store/modules/treatments/types';

import actions from './actions';
import mutations from './mutations';
import getters from './getters';

export const getInitialState = (): TreatmentState => ({
  /**
   * List
   */
  list: [],
  listStatus: ListStatus.Loading,
  listPagination: new Pagination(),

  /**
   * Tree
   */
  treatmentsTreeStatus: ListStatus.Loading,
  treatmentsTree: [],
  allTreatments: [],
  allTreatmentsStatus: ListStatus.Loading,

  /**
   * Edit
   */
  editableStatus: EditableStatus.Loading,
  translationsStatus: EditableStatus.Loaded,

  /**
   * Enhancements list
   */
  enhancementList: [],
  enhancementListStatus: ListStatus.Loading,
  enhancementListPagination: new Pagination(),

  /**
   * Enahancements tree
   */
  enhancementsTree: [],
  enhancementsTreeStatus: ListStatus.Loading,

  /**
   * Enahancements Edit
   */
  enhancementStatus: EditableStatus.Loading,
  enhancementTranslationsStatus: EditableStatus.Loaded,

  /**
   * Public Treatments list
   */
  publicTreatmentList: [],
  publicTreatmentListStatus: ListStatus.Loading,
  publicTreatmentListPagination: new Pagination(),

  /**
   * Public Treatments Edit
   */
  publicTreatmentStatus: EditableStatus.Loading,
  publicTreatmentTranslationsStatus: EditableStatus.Loaded,

  /**
   * Public Sites list
   */
  publicSiteList: [],
  publicSiteListStatus: ListStatus.Loading,
  publicSiteListPagination: new Pagination(),

  /**
   * Public Site Edit
   */
  publicSiteStatus: EditableStatus.Loading,
});

export const state: TreatmentState = getInitialState();

const Treatments: Module<TreatmentState, RootState> = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

export default Treatments;
