import { MutationTree } from 'vuex';

import { ListStatus } from '@/store/types';
import Pagination from '@/store/models/pagination';
import User from '@/store/models/user';

import { StatisticsState, StatisticsMutations } from './types';
import Download from './download';
import Login from './login';
import Training from './training';

const mutations: MutationTree<StatisticsState> = {
  /**
   * Downloads
   */
  [StatisticsMutations.SET_DOWNLOADS](state, payload: Download[]) {
    state.downloads = payload;
  },
  [StatisticsMutations.SET_DOWNLOADS_STATUS](state, payload: ListStatus) {
    state.downloadsStatus = payload;
  },
  [StatisticsMutations.SET_DOWNLOADS_PAGINATION](state, payload: Pagination) {
    state.downloadsPagination = payload;
  },
  [StatisticsMutations.SET_TOTAL_DOWNLOADS](state, payload: number) {
    state.totalDownloads = payload;
  },

  /**
   * Logins
   */
  [StatisticsMutations.SET_LOGINS](state, payload: Login[]) {
    state.logins = payload;
  },
  [StatisticsMutations.SET_LOGINS_STATUS](state, payload: ListStatus) {
    state.loginsStatus = payload;
  },
  [StatisticsMutations.SET_LOGINS_PAGINATION](state, payload: Pagination) {
    state.loginsPagination = payload;
  },

  /**
   * Users
   */
  [StatisticsMutations.SET_USERS](state, payload: User[]) {
    state.users = payload;
  },
  [StatisticsMutations.SET_USERS_STATUS](state, payload: ListStatus) {
    state.usersStatus = payload;
  },
  [StatisticsMutations.SET_USERS_PAGINATION](state, payload: Pagination) {
    state.usersPagination = payload;
  },

  /**
   * Trainings
   */
  [StatisticsMutations.SET_TRAININGS](state, payload: Training[]) {
    state.trainings = payload;
  },
  [StatisticsMutations.SET_TRAININGS_STATUS](state, payload: ListStatus) {
    state.trainingsStatus = payload;
  },
  [StatisticsMutations.SET_TRAININGS_PAGINATION](state, payload: Pagination) {
    state.trainingsPagination = payload;
  },
  [StatisticsMutations.SET_TOTAL_TRAININGS](state, payload: number) {
    state.totalTrainings = payload;
  },
};

export default mutations;
