import { ProductsState } from './types';
import Pagination from '@/store/models/pagination';
import { ListStatus, RootState, EditableStatus } from '@/store/types';
import mutations from './mutations';
import actions from './actions';
import getters from './getters';
import { Module } from 'vuex';

const getInitialState = (): ProductsState => ({
  list: [],
  listPagination: new Pagination(),
  listStatus: ListStatus.Initial,

  /**
   * Tree
   */
  tree: [],
  treeStatus: ListStatus.Initial,

  /**
   * Edit
   */
  editableStatus: EditableStatus.Loading,
  translationsStatus: EditableStatus.Loaded,

  /**
   * Accessories list
   */
  accessoriesList: [],
  accessoriesListStatus: ListStatus.Loading,
  accessoriesListPagination: new Pagination(),

  /**
   * Accessories tree
   */
  accessoriesTree: [],
  accessoriesTreeStatus: ListStatus.Loading,

  /**
   * Accessories Edit
   */
  accessoryStatus: EditableStatus.Loading,
  accessoryTranslationsStatus: EditableStatus.Loaded,
});

const state = getInitialState();
const module: Module<ProductsState, RootState> = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};

export default module;
