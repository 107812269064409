import Vue from 'vue';

// Based off https://medium.com/@logaretm/authoring-validatable-custom-vue-input-components-1583fcc68314
export const customInputComponent = Vue.extend({
  inject: {
    $validator: '$validator',
  },
  $_veeValidate: {
    name() {
      return this.name;
    },
    value() {
      return this.value;
    },
  },
  props: {
    value: [String, Array],
    name: {
      type: String,
    },
    label: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    error: {
      type: String,
      required: false,
    },
    required: {
      type: Boolean,
      required: false,
    },
  },
  methods: {
    updateValue(event) {
      const newValue = event.target.value;

      if (newValue !== (this as any).value) {
        this.$emit('input', event.target.value);
      }
    },
  },
});

export default customInputComponent;
