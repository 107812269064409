var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('div',{staticClass:"c-main"},[_c('div',{staticClass:"o-container-fluid"},[_c('div',{staticClass:"c-divider"},[_c('h3',[_vm._v(_vm._s(_vm.$t('Settings')))])]),_c('notification-banner'),_c('div',{staticClass:"o-row"},[_c('div',{staticClass:"o-col-8@md u-pr-spacer-section@lg"},[_c('tabs',{attrs:{"tabs":{
              general: _vm.$t('General'),
              countries: _vm.$t('Countries'),
              legal: _vm.$t('Legal Language'),
              support: _vm.$t('Support Page'),
              emails: _vm.$t('Emails'),
            }},scopedSlots:_vm._u([{key:"general",fn:function(){return [_c('general-settings-form')]},proxy:true},{key:"countries",fn:function(){return [_c('country-settings-form')]},proxy:true},{key:"legal",fn:function(slotProps){return [_c('legal-settings-form',_vm._b({},'legal-settings-form',slotProps,false))]}},{key:"support",fn:function(){return [_c('support-settings-form')]},proxy:true},{key:"emails",fn:function(){return [_c('email-settings-form')]},proxy:true}])})],1),_c('div',{staticClass:"o-col-4@md u-pl-spacer-section@lg c-sidebar"},[_c('div',{staticClass:"c-widget c-tile c-tile--item u-order--first@md"},[_c('publish',{attrs:{"title":_vm.$t('Settings'),"save-label":_vm.$t('Save all settings'),"description":_vm.$t('These settings apply site wide and allow AMPS admins to manage communication with users.'),"status":_vm.editableStatus,"can-preview":false,"can-delete":false,"can-restore":false},on:{"save":_vm.save}})],1)])])],1)]),_c('router-view')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }