import router from '@/router';
import api from '@/api';
import i18next from '@/i18n';

import { ListStatus, ActionsTree, ErrorMessages, RootActions, EditableStatus } from '@/store/types';
import { TreatmentActions, TreatmentMutations, TreatmentState } from '@/store/modules/treatments/types';
import Taxonomy from '@/store/models/taxonomy';
import Pagination from '@/store/models/pagination';
import { NotificationsActions } from '@/store/modules/notifications/types';
import Product from '@/store/modules/products/product';

import TreatmentEnhancement, { TreatmentEnhancementStatus } from './enhancement';
import Treatment, { TreatmentStatus } from './treatment';
import PublicTreatment, { PublicTreatmentStatus } from './public-treatment';
import PublicSite, { PublicSiteStatus } from './public-site';
import { enhanceErrorWithMessage } from '@/utils/errors';

export const actions: ActionsTree<TreatmentState, typeof TreatmentActions> = {
  async [TreatmentActions.LOAD_LIST]({ commit, dispatch }, filters = {}) {
    commit(TreatmentMutations.SET_LIST_STATUS, ListStatus.Loading);

    try {
      const res = await api.treatments.list(filters);
      commit(TreatmentMutations.SET_LIST_PAGINATION, Pagination.fromJSON(res.data));
      commit(
        TreatmentMutations.SET_LIST,
        res.data.data.map((item) => Treatment.fromJSON(item)),
      );
      commit(TreatmentMutations.SET_LIST_STATUS, ListStatus.Loaded);
    } catch (error) {
      enhanceErrorWithMessage(error, ErrorMessages.DATA_LOAD_ERROR);

      commit(TreatmentMutations.SET_LIST_STATUS, ListStatus.Failed);
      return dispatch(RootActions.ERROR, error, { root: true });
    }
  },

  async [TreatmentActions.LOAD_TREE]({ commit, dispatch }, { practice_id } = {}): Promise<void> {
    commit(TreatmentMutations.SET_TREE_STATUS, ListStatus.Loading);
    try {
      const response = await api.treatments.listTree({ practice_id });
      commit(
        TreatmentMutations.SET_TREE,
        response.data.map((item) => Product.fromJSON(item)).sort((a, b) => b.priority - a.priority),
      );
      commit(TreatmentMutations.SET_TREE_STATUS, ListStatus.Loaded);
    } catch (error) {
      enhanceErrorWithMessage(error, ErrorMessages.DATA_LOAD_ERROR);

      commit(TreatmentMutations.SET_TREE_STATUS, ListStatus.Failed);
      return dispatch(RootActions.ERROR, error, { root: true });
    }
  },

  async [TreatmentActions.LOAD_ALL_TREE]({ commit, dispatch }): Promise<void> {
    commit(TreatmentMutations.SET_ALL_TREE_STATUS, ListStatus.Loading);
    try {
      const response = await api.treatments.listAll();
      commit(
        TreatmentMutations.SET_ALL_TREE,
        response.data.map((item) => Taxonomy.fromJSON(item)),
      );
      commit(TreatmentMutations.SET_ALL_TREE_STATUS, ListStatus.Loaded);
    } catch (error) {
      enhanceErrorWithMessage(error, ErrorMessages.DATA_LOAD_ERROR);

      commit(TreatmentMutations.SET_ALL_TREE_STATUS, ListStatus.Failed);
      return dispatch(RootActions.ERROR, error, { root: true });
    }
  },

  async [TreatmentActions.LOAD_EDITABLE]({ commit, dispatch }, { id } = {}): Promise<void> {
    commit(TreatmentMutations.SET_EDITABLE_STATUS, EditableStatus.Loading);

    if (!id) {
      const treatment = new Treatment();
      treatment.status = TreatmentStatus.Active;
      commit(TreatmentMutations.SET_EDITABLE, treatment);
      commit(TreatmentMutations.SET_EDITABLE_STATUS, EditableStatus.Loaded);
      return Promise.resolve();
    }

    try {
      const response = await api.treatments.get(id);
      commit(TreatmentMutations.SET_EDITABLE, Treatment.fromJSON(response.data));
      commit(TreatmentMutations.SET_EDITABLE_STATUS, EditableStatus.Loaded);
    } catch (error) {
      enhanceErrorWithMessage(error, ErrorMessages.DATA_LOAD_ERROR);
      commit(TreatmentMutations.SET_EDITABLE_STATUS, EditableStatus.Failed);
      return dispatch(RootActions.ERROR, error, { root: true });
    }
  },

  [TreatmentActions.UPDATE_EDITABLE]({ commit }, changes): Promise<void> {
    commit(TreatmentMutations.UPDATE_EDITABLE, changes);
    return Promise.resolve();
  },

  [TreatmentActions.SAVE_EDITABLE]({ state, commit, dispatch }): Promise<void> {
    let isUpdate: boolean;
    let url: string;
    let successMsg: string;

    return new Promise<void>((resolve, reject) => {
      if (state.editable) {
        return resolve();
      }

      reject(new Error('Invalid data!'));
    })
      .then(() => {
        commit(TreatmentMutations.SET_EDITABLE_STATUS, EditableStatus.Saving);

        isUpdate = Boolean(state.editable!.id);
        url = 'treatment';
        successMsg = i18next.t('A new treatment was added successfully.');

        if (isUpdate) {
          url += `/${state.editable!.id}`;
          successMsg = i18next.t('The treatment was successfully updated.');
        }
      })
      .then(() => api.treatments.store(state.editable!.toJSON()))
      .then((response) => response.data)
      .then((response): Promise<Treatment> => {
        const treatment = Treatment.fromJSON(response);

        commit(TreatmentMutations.SET_EDITABLE, treatment);
        commit(TreatmentMutations.SET_EDITABLE_STATUS, EditableStatus.Saved);

        if (!isUpdate) {
          return new Promise((resolve, reject) => {
            if (!treatment.id) {
              return reject(new Error('Invalid data.'));
            }

            router.replace(
              {
                name: 'edit-treatment',
                params: {
                  id: String(treatment.id),
                },
              },
              () => resolve(treatment),
              (error: any) => reject(error),
            );
          });
        }

        return Promise.resolve(treatment);
      })
      .then(() => {
        return dispatch(
          'notifications/' + NotificationsActions.SHOW_SUCCESS,
          {
            body: successMsg,
          },
          { root: true },
        );
      })
      .catch((error) => {
        enhanceErrorWithMessage(error, ErrorMessages.DATA_LOAD_ERROR);

        commit(TreatmentMutations.SET_EDITABLE_STATUS, EditableStatus.Failed);
        return dispatch(RootActions.ERROR, error, { root: true });
      });
  },
  [TreatmentActions.SAVE_TRANSLATIONS]({ state, commit, dispatch }, translations): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (state.editable) {
        return resolve();
      }

      reject(new Error('Invalid data!'));
    })
      .then(() => {
        commit(TreatmentMutations.SET_TRANSLATIONS_STATUS, EditableStatus.Saving);
        return api.treatments.translate(state.editable!.id, translations);
      })
      .then((response) => response.data)
      .then(async (response) => {
        const product = Treatment.fromJSON(response);

        await dispatch(TreatmentActions.UPDATE_EDITABLE, { translations: product.translations });

        commit(TreatmentMutations.SET_TRANSLATIONS_STATUS, EditableStatus.Saved);
      })
      .catch((error) => {
        enhanceErrorWithMessage(error, ErrorMessages.DATA_LOAD_ERROR);

        commit(TreatmentMutations.SET_TRANSLATIONS_STATUS, EditableStatus.Failed);
        return dispatch(RootActions.ERROR, error, { root: true });
      });
  },
  [TreatmentActions.DELETE]({ commit, dispatch }, { id = 0 }: { id?: number } = {}): Promise<void> {
    const isEdit = router.currentRoute.name === 'edit-treatment';
    if (isEdit) {
      commit(TreatmentMutations.SET_EDITABLE_STATUS, EditableStatus.Saving);
    } else {
      commit(TreatmentMutations.SET_LIST_STATUS, ListStatus.Loading);
    }

    return new Promise<void>((resolve, reject) => {
      if (!id) {
        return reject(new Error('Invalid data!'));
      }

      resolve();
    })
      .then(() => api.treatments.remove(id))
      .then(() => {
        if (isEdit) {
          commit(TreatmentMutations.UPDATE_INITIAL_EDITABLE, {
            status: TreatmentStatus.Inactive,
          });
          commit(TreatmentMutations.UPDATE_EDITABLE, {
            status: TreatmentStatus.Inactive,
          });
          commit(TreatmentMutations.SET_EDITABLE_STATUS, EditableStatus.Saved);
        } else {
          dispatch(TreatmentActions.LOAD_LIST, {
            ...router.currentRoute.params,
            ...router.currentRoute.query,
          });
        }
      })
      .then(() => {
        return dispatch(
          'notifications/' + NotificationsActions.SHOW_SUCCESS,
          {
            body: i18next.t('The treatment was deleted successfully.'),
          },
          { root: true },
        );
      })
      .catch((error) => {
        enhanceErrorWithMessage(error, ErrorMessages.DATA_LOAD_ERROR);

        if (isEdit) {
          commit(TreatmentMutations.SET_EDITABLE_STATUS, EditableStatus.Failed);
        } else {
          commit(TreatmentMutations.SET_LIST_STATUS, ListStatus.Failed);
        }

        return dispatch(RootActions.ERROR, error, { root: true });
      });
  },
  [TreatmentActions.RESTORE]({ commit, dispatch }, { id = 0 }: { id?: number } = {}): Promise<void> {
    const isEdit = router.currentRoute.name === 'edit-treatment';
    if (isEdit) {
      commit(TreatmentMutations.SET_EDITABLE_STATUS, EditableStatus.Saving);
    } else {
      commit(TreatmentMutations.SET_LIST_STATUS, ListStatus.Loading);
    }

    return new Promise<void>((resolve, reject) => {
      if (!id) {
        return reject(new Error('Invalid data!'));
      }

      resolve();
    })
      .then(() => api.treatments.restore(id))
      .then(() => {
        if (isEdit) {
          commit(TreatmentMutations.UPDATE_INITIAL_EDITABLE, {
            status: TreatmentStatus.Active,
          });
          commit(TreatmentMutations.UPDATE_EDITABLE, {
            status: TreatmentStatus.Active,
          });
          commit(TreatmentMutations.SET_EDITABLE_STATUS, EditableStatus.Saved);
        } else {
          dispatch(TreatmentActions.LOAD_LIST, {
            ...router.currentRoute.params,
            ...router.currentRoute.query,
          });
        }
      })
      .then(() =>
        dispatch(
          'notifications/' + NotificationsActions.SHOW_SUCCESS,
          {
            body: i18next.t('The treatment was restored successfully.'),
          },
          { root: true },
        ),
      )
      .catch((error) => {
        enhanceErrorWithMessage(error, ErrorMessages.DATA_LOAD_ERROR);

        if (isEdit) {
          commit(TreatmentMutations.SET_EDITABLE_STATUS, EditableStatus.Failed);
        } else {
          commit(TreatmentMutations.SET_LIST_STATUS, ListStatus.Failed);
        }

        return dispatch(RootActions.ERROR, error, { root: true });
      });
  },

  async [TreatmentActions.LOAD_ENHANCEMENT_LIST]({ commit, dispatch }, filters = {}): Promise<void> {
    commit(TreatmentMutations.SET_ENHANCEMENT_LIST_STATUS, ListStatus.Loading);

    try {
      const res = await api.enhancements.list(filters);
      commit(TreatmentMutations.SET_ENHANCEMENT_LIST_PAGINATION, Pagination.fromJSON(res.data));
      commit(
        TreatmentMutations.SET_ENHANCEMENT_LIST,
        res.data.data.map((item) => TreatmentEnhancement.fromJSON(item)),
      );
      commit(TreatmentMutations.SET_ENHANCEMENT_LIST_STATUS, ListStatus.Loaded);
    } catch (error) {
      enhanceErrorWithMessage(error, ErrorMessages.DATA_LOAD_ERROR);

      commit(TreatmentMutations.SET_ENHANCEMENT_LIST_STATUS, ListStatus.Failed);
      return dispatch(RootActions.ERROR, error, { root: true });
    }
  },

  async [TreatmentActions.LOAD_ENHANCEMENTS_TREE]({ commit, dispatch }, { practice_id } = {}) {
    commit(TreatmentMutations.SET_ENHANCEMENTS_TREE_STATUS, ListStatus.Loading);

    try {
      const res = await api.enhancements.listTree(practice_id);
      commit(
        TreatmentMutations.SET_ENHANCEMENTS_TREE,
        res.data.map((item) => TreatmentEnhancement.fromJSON(item)),
      );
      commit(TreatmentMutations.SET_ENHANCEMENTS_TREE_STATUS, ListStatus.Loaded);
    } catch (error) {
      enhanceErrorWithMessage(error, ErrorMessages.DATA_LOAD_ERROR);

      commit(TreatmentMutations.SET_ENHANCEMENTS_TREE_STATUS, ListStatus.Failed);
      return dispatch(RootActions.ERROR, error, { root: true });
    }
  },

  async [TreatmentActions.LOAD_ENHANCEMENT]({ commit, dispatch }, { id } = {}): Promise<void> {
    commit(TreatmentMutations.SET_ENHANCEMENT_STATUS, EditableStatus.Loading);

    if (!id) {
      const enhancement = new TreatmentEnhancement();
      enhancement.status = TreatmentEnhancementStatus.Active;

      commit(TreatmentMutations.SET_ENHANCEMENT, enhancement);
      commit(TreatmentMutations.SET_ENHANCEMENT_STATUS, EditableStatus.Loaded);

      return Promise.resolve();
    }

    try {
      const response = await api.enhancements.get(id);
      commit(TreatmentMutations.SET_ENHANCEMENT, TreatmentEnhancement.fromJSON(response.data));
      commit(TreatmentMutations.SET_ENHANCEMENT_STATUS, EditableStatus.Loaded);
    } catch (error) {
      enhanceErrorWithMessage(error, ErrorMessages.DATA_LOAD_ERROR);
      commit(TreatmentMutations.SET_ENHANCEMENT_STATUS, EditableStatus.Failed);
      return dispatch(RootActions.ERROR, error, { root: true });
    }
  },

  [TreatmentActions.UPDATE_ENHANCEMENT]({ commit }, changes): Promise<void> {
    commit(TreatmentMutations.UPDATE_ENHANCEMENT, changes);
    return Promise.resolve();
  },

  [TreatmentActions.SAVE_ENHANCEMENT]({ state, commit, dispatch }): Promise<void> {
    let isUpdate: boolean;
    let url: string;
    let successMsg: string;

    return new Promise<void>((resolve, reject) => {
      if (state.enhancement) {
        return resolve();
      }

      reject(new Error('Invalid data!'));
    })
      .then(() => {
        commit(TreatmentMutations.SET_ENHANCEMENT_STATUS, EditableStatus.Saving);

        isUpdate = Boolean(state.enhancement!.id);
        url = 'enhancement';
        successMsg = i18next.t('A new enhancement was added successfully.');

        if (isUpdate) {
          url += `/${state.enhancement!.id}`;
          successMsg = i18next.t('The enhancement was successfully updated.');
        }
      })
      .then(() => api.enhancements.store(state.enhancement!.toJSON()))
      .then((response) => response.data)
      .then((response): Promise<TreatmentEnhancement> => {
        const enhancement = TreatmentEnhancement.fromJSON(response);

        commit(TreatmentMutations.SET_ENHANCEMENT, enhancement);
        commit(TreatmentMutations.SET_ENHANCEMENT_STATUS, EditableStatus.Saved);

        if (!isUpdate) {
          return new Promise((resolve, reject) => {
            if (!enhancement.id) {
              return reject(new Error('Invalid data.'));
            }

            router.replace(
              {
                name: 'edit-enhancement',
                params: {
                  id: String(enhancement.id),
                },
              },
              () => resolve(enhancement),
              (error: any) => reject(error),
            );
          });
        }

        return Promise.resolve(enhancement);
      })
      .then(() => {
        return dispatch(
          'notifications/' + NotificationsActions.SHOW_SUCCESS,
          {
            body: successMsg,
          },
          { root: true },
        );
      })
      .catch((error) => {
        enhanceErrorWithMessage(error, ErrorMessages.DATA_LOAD_ERROR);

        commit(TreatmentMutations.SET_ENHANCEMENT_STATUS, EditableStatus.Failed);
        return dispatch(RootActions.ERROR, error, { root: true });
      });
  },
  [TreatmentActions.SAVE_ENHANCEMENT_TRANSLATIONS]({ state, commit, dispatch }, translations): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (state.enhancement) {
        return resolve();
      }

      reject(new Error('Invalid data!'));
    })
      .then(() => {
        commit(TreatmentMutations.SET_ENHANCEMENT_TRANSLATIONS_STATUS, EditableStatus.Saving);
        return api.enhancements.translate(state.enhancement!.id, translations);
      })
      .then((response) => response.data)
      .then(async (response) => {
        const enhancement = TreatmentEnhancement.fromJSON(response);

        await dispatch(TreatmentActions.UPDATE_ENHANCEMENT, { translations: enhancement.translations });

        commit(TreatmentMutations.SET_ENHANCEMENT_TRANSLATIONS_STATUS, EditableStatus.Saved);
      })
      .catch((error) => {
        enhanceErrorWithMessage(error, ErrorMessages.DATA_LOAD_ERROR);

        commit(TreatmentMutations.SET_ENHANCEMENT_TRANSLATIONS_STATUS, EditableStatus.Failed);
        return dispatch(RootActions.ERROR, error, { root: true });
      });
  },
  [TreatmentActions.DELETE_ENHANCEMENT]({ commit, dispatch }, { id = 0 }: { id?: number } = {}): Promise<void> {
    const isEdit = router.currentRoute.name === 'edit-enhancement';
    if (isEdit) {
      commit(TreatmentMutations.SET_ENHANCEMENT_STATUS, EditableStatus.Saving);
    } else {
      commit(TreatmentMutations.SET_ENHANCEMENT_LIST_STATUS, ListStatus.Loading);
    }

    return new Promise<void>((resolve, reject) => {
      if (!id) {
        return reject(new Error('Invalid data!'));
      }

      resolve();
    })
      .then(() => api.enhancements.remove(id))
      .then(() => {
        if (isEdit) {
          commit(TreatmentMutations.UPDATE_INITIAL_ENHANCEMENT, {
            status: TreatmentEnhancementStatus.Inactive,
          });
          commit(TreatmentMutations.UPDATE_ENHANCEMENT, {
            status: TreatmentEnhancementStatus.Inactive,
          });
          commit(TreatmentMutations.SET_ENHANCEMENT_STATUS, EditableStatus.Saved);
        } else {
          dispatch(TreatmentActions.LOAD_ENHANCEMENT_LIST, {
            ...router.currentRoute.params,
            ...router.currentRoute.query,
          });
        }
      })
      .then(() => {
        return dispatch(
          'notifications/' + NotificationsActions.SHOW_SUCCESS,
          {
            body: i18next.t('The enhancement was deleted successfully.'),
          },
          { root: true },
        );
      })
      .catch((error) => {
        enhanceErrorWithMessage(error, ErrorMessages.DATA_LOAD_ERROR);

        if (isEdit) {
          commit(TreatmentMutations.SET_ENHANCEMENT_STATUS, EditableStatus.Failed);
        } else {
          commit(TreatmentMutations.SET_ENHANCEMENT_LIST_STATUS, ListStatus.Failed);
        }

        return dispatch(RootActions.ERROR, error, { root: true });
      });
  },
  [TreatmentActions.RESTORE_ENHANCEMENT]({ commit, dispatch }, { id = 0 }: { id?: number } = {}): Promise<void> {
    const isEdit = router.currentRoute.name === 'edit-enhancement';
    if (isEdit) {
      commit(TreatmentMutations.SET_ENHANCEMENT_STATUS, EditableStatus.Saving);
    } else {
      commit(TreatmentMutations.SET_ENHANCEMENT_LIST_STATUS, ListStatus.Loading);
    }

    return new Promise<void>((resolve, reject) => {
      if (!id) {
        return reject(new Error('Invalid data!'));
      }

      resolve();
    })
      .then(() => api.enhancements.restore(id))
      .then(() => {
        if (isEdit) {
          commit(TreatmentMutations.UPDATE_INITIAL_ENHANCEMENT, {
            status: TreatmentEnhancementStatus.Active,
          });
          commit(TreatmentMutations.UPDATE_ENHANCEMENT, {
            status: TreatmentEnhancementStatus.Active,
          });
          commit(TreatmentMutations.SET_ENHANCEMENT_STATUS, EditableStatus.Saved);
        } else {
          dispatch(TreatmentActions.LOAD_ENHANCEMENT_LIST, {
            ...router.currentRoute.params,
            ...router.currentRoute.query,
          });
        }
      })
      .then(() =>
        dispatch(
          'notifications/' + NotificationsActions.SHOW_SUCCESS,
          {
            body: i18next.t('The enhancement was restored successfully.'),
          },
          { root: true },
        ),
      )
      .catch((error) => {
        enhanceErrorWithMessage(error, ErrorMessages.DATA_LOAD_ERROR);

        if (isEdit) {
          commit(TreatmentMutations.SET_ENHANCEMENT_STATUS, EditableStatus.Failed);
        } else {
          commit(TreatmentMutations.SET_ENHANCEMENT_LIST_STATUS, ListStatus.Failed);
        }

        return dispatch(RootActions.ERROR, error, { root: true });
      });
  },
  [TreatmentActions.BULK_RESTORE]({ commit, dispatch }, { ids = [] }: { ids: number[] } = { ids: [] }): Promise<void> {
    commit(TreatmentMutations.SET_LIST_STATUS, ListStatus.Loading);

    return new Promise<void>((resolve, reject) => {
      if (!ids || !ids.length) {
        return reject(new Error('Invalid data!'));
      }

      resolve();
    })
      .then(() => Promise.all(ids.map(async (id) => api.treatments.restore(id))))
      .then(() => {
        dispatch(TreatmentActions.LOAD_LIST, {
          ...router.currentRoute.params,
          ...router.currentRoute.query,
        });

        return dispatch(
          'notifications/' + NotificationsActions.SHOW_SUCCESS,
          {
            body: i18next.t('%(count)% treatment was restored successfully', { count: ids.length }),
          },
          { root: true },
        );
      })
      .catch((error) => {
        enhanceErrorWithMessage(error, ErrorMessages.DATA_LOAD_ERROR);

        commit(TreatmentMutations.SET_LIST_STATUS, ListStatus.Failed);

        return dispatch(RootActions.ERROR, error, { root: true });
      });
  },
  [TreatmentActions.BULK_DELETE]({ commit, dispatch }, { ids = [] }: { ids: number[] } = { ids: [] }): Promise<void> {
    commit(TreatmentMutations.SET_LIST_STATUS, ListStatus.Loading);

    return new Promise<void>((resolve, reject) => {
      if (!ids || !ids.length) {
        return reject(new Error('Invalid data!'));
      }

      resolve();
    })
      .then(() => Promise.all(ids.map((id) => api.treatments.remove(id))))
      .then(() => {
        dispatch(TreatmentActions.LOAD_LIST, {
          ...router.currentRoute.params,
          ...router.currentRoute.query,
        });

        return dispatch(
          'notifications/' + NotificationsActions.SHOW_SUCCESS,
          {
            body: i18next.t('%(count)% treatment was deleted successfully', { count: ids.length }),
          },
          { root: true },
        );
      })
      .catch((error) => {
        enhanceErrorWithMessage(error, ErrorMessages.DATA_LOAD_ERROR);

        commit(TreatmentMutations.SET_LIST_STATUS, ListStatus.Failed);

        return dispatch(RootActions.ERROR, error, { root: true });
      });
  },
  [TreatmentActions.BULK_RESTORE_ENHANCEMENT](
    { commit, dispatch },
    { ids = [] }: { ids: number[] } = { ids: [] },
  ): Promise<void> {
    commit(TreatmentMutations.SET_ENHANCEMENT_LIST_STATUS, ListStatus.Loading);

    return new Promise<void>((resolve, reject) => {
      if (!ids || !ids.length) {
        return reject(new Error('Invalid data!'));
      }

      resolve();
    })
      .then(() => Promise.all(ids.map(async (id) => api.enhancements.restore(id))))
      .then(() => {
        dispatch(TreatmentActions.LOAD_ENHANCEMENT_LIST, {
          ...router.currentRoute.params,
          ...router.currentRoute.query,
        });

        return dispatch(
          'notifications/' + NotificationsActions.SHOW_SUCCESS,
          {
            body: i18next.t('%(count)% enhancement was restored successfully', { count: ids.length }),
          },
          { root: true },
        );
      })
      .catch((error) => {
        enhanceErrorWithMessage(error, ErrorMessages.DATA_LOAD_ERROR);

        commit(TreatmentMutations.SET_ENHANCEMENT_LIST_STATUS, ListStatus.Failed);

        return dispatch(RootActions.ERROR, error, { root: true });
      });
  },
  [TreatmentActions.BULK_DELETE_ENHANCEMENT](
    { commit, dispatch },
    { ids = [] }: { ids: number[] } = { ids: [] },
  ): Promise<void> {
    commit(TreatmentMutations.SET_ENHANCEMENT_LIST_STATUS, ListStatus.Loading);

    return new Promise<void>((resolve, reject) => {
      if (!ids || !ids.length) {
        return reject(new Error('Invalid data!'));
      }

      resolve();
    })
      .then(() => Promise.all(ids.map((id) => api.enhancements.remove(id))))
      .then(() => {
        dispatch(TreatmentActions.LOAD_ENHANCEMENT_LIST, {
          ...router.currentRoute.params,
          ...router.currentRoute.query,
        });

        return dispatch(
          'notifications/' + NotificationsActions.SHOW_SUCCESS,
          {
            body: i18next.t('%(count)% enhancement was deleted successfully', { count: ids.length }),
          },
          { root: true },
        );
      })
      .catch((error) => {
        enhanceErrorWithMessage(error, ErrorMessages.DATA_LOAD_ERROR);

        commit(TreatmentMutations.SET_ENHANCEMENT_LIST_STATUS, ListStatus.Failed);

        return dispatch(RootActions.ERROR, error, { root: true });
      });
  },

  async [TreatmentActions.LOAD_PUBLIC_TREATMENT_LIST]({ commit, dispatch }, filters = {}): Promise<void> {
    commit(TreatmentMutations.SET_PUBLIC_TREATMENT_LIST_STATUS, ListStatus.Loading);

    try {
      const res = await api.public_treatments.list(filters);
      commit(TreatmentMutations.SET_PUBLIC_TREATMENT_LIST_PAGINATION, Pagination.fromJSON(res.data));
      commit(
        TreatmentMutations.SET_PUBLIC_TREATMENT_LIST,
        res.data.data.map((item) => PublicTreatment.fromJSON(item)),
      );
      commit(TreatmentMutations.SET_PUBLIC_TREATMENT_LIST_STATUS, ListStatus.Loaded);
    } catch (error) {
      enhanceErrorWithMessage(error, ErrorMessages.DATA_LOAD_ERROR);

      commit(TreatmentMutations.SET_PUBLIC_TREATMENT_LIST_STATUS, ListStatus.Failed);
      return dispatch(RootActions.ERROR, error, { root: true });
    }
  },

  async [TreatmentActions.LOAD_PUBLIC_TREATMENT]({ commit, dispatch, rootGetters }, { id } = {}): Promise<void> {
    commit(TreatmentMutations.SET_PUBLIC_TREATMENT_STATUS, EditableStatus.Loading);

    if (!id) {
      const publicTreatment = new PublicTreatment();
      publicTreatment.status = PublicTreatmentStatus.Active;
      publicTreatment.languageCode = rootGetters['user/profile/currentProfile'].languageCode;
      publicTreatment.languageId = rootGetters['user/profile/currentProfile'].languageId;

      commit(TreatmentMutations.SET_PUBLIC_TREATMENT, publicTreatment);
      commit(TreatmentMutations.SET_PUBLIC_TREATMENT_STATUS, EditableStatus.Loaded);

      return Promise.resolve();
    }

    try {
      const response = await api.public_treatments.get(id);
      commit(TreatmentMutations.SET_PUBLIC_TREATMENT, PublicTreatment.fromJSON(response.data));
      commit(TreatmentMutations.SET_PUBLIC_TREATMENT_STATUS, EditableStatus.Loaded);
    } catch (error) {
      enhanceErrorWithMessage(error, ErrorMessages.DATA_LOAD_ERROR);
      commit(TreatmentMutations.SET_PUBLIC_TREATMENT_STATUS, EditableStatus.Failed);
      return dispatch(RootActions.ERROR, error, { root: true });
    }
  },

  [TreatmentActions.UPDATE_PUBLIC_TREATMENT]({ commit }, changes): Promise<void> {
    commit(TreatmentMutations.UPDATE_PUBLIC_TREATMENT, changes);
    return Promise.resolve();
  },

  [TreatmentActions.SAVE_PUBLIC_TREATMENT]({ state, commit, dispatch }): Promise<void> {
    let isUpdate: boolean;
    let successMsg: string;

    return new Promise<void>((resolve, reject) => {
      if (state.publicTreatment) {
        return resolve();
      }

      reject(new Error('Invalid data!'));
    })
      .then(() => {
        commit(TreatmentMutations.SET_PUBLIC_TREATMENT_STATUS, EditableStatus.Saving);

        isUpdate = Boolean(state.publicTreatment!.id);
        successMsg = i18next.t('A new public treatment was added successfully.');

        if (isUpdate) {
          successMsg = i18next.t('The public treatment was successfully updated.');
        }
      })
      .then(() => api.public_treatments.store(state.publicTreatment!.toJSON()))
      .then((response) => response.data)
      .then((response): Promise<PublicTreatment> => {
        const publicTreatment = PublicTreatment.fromJSON(response);

        commit(TreatmentMutations.SET_PUBLIC_TREATMENT, publicTreatment);
        commit(TreatmentMutations.SET_PUBLIC_TREATMENT_STATUS, EditableStatus.Saved);

        if (!isUpdate) {
          return new Promise((resolve, reject) => {
            if (!publicTreatment.id) {
              return reject(new Error('Invalid data.'));
            }

            router.replace(
              {
                name: 'edit-public-treatment',
                params: {
                  id: String(publicTreatment.id),
                },
              },
              () => resolve(publicTreatment),
              (error: any) => reject(error),
            );
          });
        }

        return Promise.resolve(publicTreatment);
      })
      .then(() => {
        return dispatch(
          'notifications/' + NotificationsActions.SHOW_SUCCESS,
          {
            body: successMsg,
          },
          { root: true },
        );
      })
      .catch((error) => {
        enhanceErrorWithMessage(error, ErrorMessages.DATA_LOAD_ERROR);

        commit(TreatmentMutations.SET_PUBLIC_TREATMENT_STATUS, EditableStatus.Failed);
        return dispatch(RootActions.ERROR, error, { root: true });
      });
  },
  [TreatmentActions.DELETE_PUBLIC_TREATMENT]({ commit, dispatch }, { id = 0 }: { id?: number } = {}): Promise<void> {
    const isEdit = router.currentRoute.name === 'edit-public-treatment';
    if (isEdit) {
      commit(TreatmentMutations.SET_PUBLIC_TREATMENT_STATUS, EditableStatus.Saving);
    } else {
      commit(TreatmentMutations.SET_PUBLIC_TREATMENT_LIST_STATUS, ListStatus.Loading);
    }

    return new Promise<void>((resolve, reject) => {
      if (!id) {
        return reject(new Error('Invalid data!'));
      }

      resolve();
    })
      .then(() => api.public_treatments.remove(id))
      .then(() => {
        if (isEdit) {
          commit(TreatmentMutations.UPDATE_INITIAL_PUBLIC_TREATMENT, {
            status: PublicTreatmentStatus.Inactive,
          });
          commit(TreatmentMutations.UPDATE_PUBLIC_TREATMENT, {
            status: PublicTreatmentStatus.Inactive,
          });
          commit(TreatmentMutations.SET_PUBLIC_TREATMENT_STATUS, EditableStatus.Saved);
        } else {
          dispatch(TreatmentActions.LOAD_PUBLIC_TREATMENT_LIST, {
            ...router.currentRoute.params,
            ...router.currentRoute.query,
          });
        }
      })
      .then(() => {
        return dispatch(
          'notifications/' + NotificationsActions.SHOW_SUCCESS,
          {
            body: i18next.t('The public treatment was deleted successfully.'),
          },
          { root: true },
        );
      })
      .catch((error) => {
        enhanceErrorWithMessage(error, ErrorMessages.DATA_LOAD_ERROR);

        if (isEdit) {
          commit(TreatmentMutations.SET_PUBLIC_TREATMENT_STATUS, EditableStatus.Failed);
        } else {
          commit(TreatmentMutations.SET_PUBLIC_TREATMENT_LIST_STATUS, ListStatus.Failed);
        }

        return dispatch(RootActions.ERROR, error, { root: true });
      });
  },
  [TreatmentActions.RESTORE_PUBLIC_TREATMENT]({ commit, dispatch }, { id = 0 }: { id?: number } = {}): Promise<void> {
    const isEdit = router.currentRoute.name === 'edit-public-treatment';
    if (isEdit) {
      commit(TreatmentMutations.SET_PUBLIC_TREATMENT_STATUS, EditableStatus.Saving);
    } else {
      commit(TreatmentMutations.SET_PUBLIC_TREATMENT_LIST_STATUS, ListStatus.Loading);
    }

    return new Promise<void>((resolve, reject) => {
      if (!id) {
        return reject(new Error('Invalid data!'));
      }

      resolve();
    })
      .then(() => api.public_treatments.restore(id))
      .then(() => {
        if (isEdit) {
          commit(TreatmentMutations.UPDATE_INITIAL_PUBLIC_TREATMENT, {
            status: PublicTreatmentStatus.Active,
          });
          commit(TreatmentMutations.UPDATE_PUBLIC_TREATMENT, {
            status: PublicTreatmentStatus.Active,
          });
          commit(TreatmentMutations.SET_PUBLIC_TREATMENT_STATUS, EditableStatus.Saved);
        } else {
          dispatch(TreatmentActions.LOAD_PUBLIC_TREATMENT_LIST, {
            ...router.currentRoute.params,
            ...router.currentRoute.query,
          });
        }
      })
      .then(() =>
        dispatch(
          'notifications/' + NotificationsActions.SHOW_SUCCESS,
          {
            body: i18next.t('The public treatment was restored successfully.'),
          },
          { root: true },
        ),
      )
      .catch((error) => {
        enhanceErrorWithMessage(error, ErrorMessages.DATA_LOAD_ERROR);

        if (isEdit) {
          commit(TreatmentMutations.SET_PUBLIC_TREATMENT_STATUS, EditableStatus.Failed);
        } else {
          commit(TreatmentMutations.SET_PUBLIC_TREATMENT_LIST_STATUS, ListStatus.Failed);
        }

        return dispatch(RootActions.ERROR, error, { root: true });
      });
  },
  [TreatmentActions.BULK_RESTORE_PUBLIC_TREATMENT](
    { commit, dispatch },
    { ids = [] }: { ids: number[] } = { ids: [] },
  ): Promise<void> {
    commit(TreatmentMutations.SET_PUBLIC_TREATMENT_LIST_STATUS, ListStatus.Loading);

    return new Promise<void>((resolve, reject) => {
      if (!ids || !ids.length) {
        return reject(new Error('Invalid data!'));
      }

      resolve();
    })
      .then(() => Promise.all(ids.map(async (id) => api.public_treatments.restore(id))))
      .then(() => {
        dispatch(TreatmentActions.LOAD_PUBLIC_TREATMENT_LIST, {
          ...router.currentRoute.params,
          ...router.currentRoute.query,
        });

        return dispatch(
          'notifications/' + NotificationsActions.SHOW_SUCCESS,
          {
            body: i18next.t('%(count)% public treatment was restored successfully', { count: ids.length }),
          },
          { root: true },
        );
      })
      .catch((error) => {
        enhanceErrorWithMessage(error, ErrorMessages.DATA_LOAD_ERROR);

        commit(TreatmentMutations.SET_PUBLIC_TREATMENT_LIST_STATUS, ListStatus.Failed);

        return dispatch(RootActions.ERROR, error, { root: true });
      });
  },
  [TreatmentActions.BULK_DELETE_PUBLIC_TREATMENT](
    { commit, dispatch },
    { ids = [] }: { ids: number[] } = { ids: [] },
  ): Promise<void> {
    commit(TreatmentMutations.SET_PUBLIC_TREATMENT_LIST_STATUS, ListStatus.Loading);

    return new Promise<void>((resolve, reject) => {
      if (!ids || !ids.length) {
        return reject(new Error('Invalid data!'));
      }

      resolve();
    })
      .then(() => Promise.all(ids.map((id) => api.public_treatments.remove(id))))
      .then(() => {
        dispatch(TreatmentActions.LOAD_PUBLIC_TREATMENT_LIST, {
          ...router.currentRoute.params,
          ...router.currentRoute.query,
        });

        return dispatch(
          'notifications/' + NotificationsActions.SHOW_SUCCESS,
          {
            body: i18next.t('%(count)% public treatment was deleted successfully', { count: ids.length }),
          },
          { root: true },
        );
      })
      .catch((error) => {
        enhanceErrorWithMessage(error, ErrorMessages.DATA_LOAD_ERROR);

        commit(TreatmentMutations.SET_PUBLIC_TREATMENT_LIST_STATUS, ListStatus.Failed);

        return dispatch(RootActions.ERROR, error, { root: true });
      });
  },
  [TreatmentActions.SAVE_PUBLIC_TRANSLATIONS]({ state, commit, dispatch }, translations): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (state.publicTreatment) {
        return resolve();
      }

      reject(new Error('Invalid data!'));
    })
      .then(() => {
        commit(TreatmentMutations.SET_PUBLIC_TRANSLATIONS_STATUS, EditableStatus.Saving);
        return api.public_treatments.translate(state.publicTreatment!.id, translations);
      })
      .then((response) => response.data)
      .then(async (response) => {
        const publicTreatment = PublicTreatment.fromJSON(response);

        await dispatch(TreatmentActions.UPDATE_PUBLIC_TREATMENT, { translations: publicTreatment.translations });

        commit(TreatmentMutations.SET_PUBLIC_TRANSLATIONS_STATUS, EditableStatus.Saved);
      })
      .catch((error) => {
        error.errorMessage = ErrorMessages.DATA_LOAD_ERROR;

        commit(TreatmentMutations.SET_PUBLIC_TRANSLATIONS_STATUS, EditableStatus.Failed);
        return dispatch(RootActions.ERROR, error, { root: true });
      });
  },
  /**
   * Public Sites
   */
  async [TreatmentActions.LOAD_PUBLIC_SITE_LIST]({ commit, dispatch }, filters = {}): Promise<void> {
    commit(TreatmentMutations.SET_PUBLIC_SITE_LIST_STATUS, ListStatus.Loading);

    try {
      const res = await api.public_sites.list(filters);
      commit(TreatmentMutations.SET_PUBLIC_SITE_LIST_PAGINATION, Pagination.fromJSON(res.data));
      commit(
        TreatmentMutations.SET_PUBLIC_SITE_LIST,
        res.data.data.map((item) => PublicSite.fromJSON(item)),
      );
      commit(TreatmentMutations.SET_PUBLIC_SITE_LIST_STATUS, ListStatus.Loaded);
    } catch (error) {
      enhanceErrorWithMessage(error, ErrorMessages.DATA_LOAD_ERROR);

      commit(TreatmentMutations.SET_PUBLIC_SITE_LIST_STATUS, ListStatus.Failed);
      return dispatch(RootActions.ERROR, error, { root: true });
    }
  },

  async [TreatmentActions.LOAD_PUBLIC_SITE]({ commit, dispatch }, { id } = {}): Promise<void> {
    commit(TreatmentMutations.SET_PUBLIC_SITE_STATUS, EditableStatus.Loading);

    if (!id) {
      const publicSite = new PublicSite();
      publicSite.status = PublicSiteStatus.Active;

      commit(TreatmentMutations.SET_PUBLIC_SITE, publicSite);
      commit(TreatmentMutations.SET_PUBLIC_SITE_STATUS, EditableStatus.Loaded);

      return Promise.resolve();
    }

    try {
      const response = await api.public_sites.get(id);
      commit(TreatmentMutations.SET_PUBLIC_SITE, PublicSite.fromJSON(response.data));
      commit(TreatmentMutations.SET_PUBLIC_SITE_STATUS, EditableStatus.Loaded);
    } catch (error) {
      enhanceErrorWithMessage(error, ErrorMessages.DATA_LOAD_ERROR);
      commit(TreatmentMutations.SET_PUBLIC_SITE_STATUS, EditableStatus.Failed);
      return dispatch(RootActions.ERROR, error, { root: true });
    }
  },

  [TreatmentActions.UPDATE_PUBLIC_SITE]({ commit }, changes): Promise<void> {
    commit(TreatmentMutations.UPDATE_PUBLIC_SITE, changes);
    return Promise.resolve();
  },

  [TreatmentActions.SAVE_PUBLIC_SITE]({ state, commit, dispatch }): Promise<void> {
    let isUpdate: boolean;
    let successMsg: string;

    return new Promise<void>((resolve, reject) => {
      if (state.publicSite) {
        return resolve();
      }

      reject(new Error('Invalid data!'));
    })
      .then(() => {
        commit(TreatmentMutations.SET_PUBLIC_SITE_STATUS, EditableStatus.Saving);

        isUpdate = Boolean(state.publicSite!.id);
        successMsg = i18next.t('A new public site was added successfully.');

        if (isUpdate) {
          successMsg = i18next.t('The public site was successfully updated.');
        }
      })
      .then(() => api.public_sites.store(state.publicSite!.toJSON()))
      .then((response) => {
        const publicSite = PublicSite.fromJSON(response.data);

        commit(TreatmentMutations.SET_PUBLIC_SITE, publicSite);
        commit(TreatmentMutations.SET_PUBLIC_SITE_STATUS, EditableStatus.Saved);

        if (!isUpdate) {
          return new Promise((resolve, reject) => {
            if (!publicSite.id) {
              return reject(new Error('Invalid data.'));
            }

            router.replace(
              {
                name: 'edit-public-site',
                params: {
                  id: String(publicSite.id),
                },
              },
              () => resolve(publicSite),
              (error: any) => reject(error),
            );
          });
        }

        return Promise.resolve(publicSite);
      })
      .then(() => {
        return dispatch(
          'notifications/' + NotificationsActions.SHOW_SUCCESS,
          {
            body: successMsg,
          },
          { root: true },
        );
      })
      .catch((error) => {
        error.errorMessage = ErrorMessages.DATA_LOAD_ERROR;

        commit(TreatmentMutations.SET_PUBLIC_SITE_STATUS, EditableStatus.Failed);
        return dispatch(RootActions.ERROR, error, { root: true });
      });
  },
  [TreatmentActions.DELETE_PUBLIC_SITE]({ commit, dispatch }, { id = 0 }: { id?: number } = {}): Promise<void> {
    const isEdit = router.currentRoute.name === 'edit-public-site';
    if (isEdit) {
      commit(TreatmentMutations.SET_PUBLIC_SITE_STATUS, EditableStatus.Saving);
    } else {
      commit(TreatmentMutations.SET_PUBLIC_SITE_LIST_STATUS, ListStatus.Loading);
    }

    return new Promise<void>((resolve, reject) => {
      if (!id) {
        return reject(new Error('Invalid data!'));
      }

      resolve();
    })
      .then(() => api.public_sites.remove(id))
      .then(() => {
        if (isEdit) {
          commit(TreatmentMutations.UPDATE_INITIAL_PUBLIC_SITE, {
            status: PublicSiteStatus.Inactive,
          });
          commit(TreatmentMutations.UPDATE_PUBLIC_SITE, {
            status: PublicSiteStatus.Inactive,
          });
          commit(TreatmentMutations.SET_PUBLIC_SITE_STATUS, EditableStatus.Saved);
        } else {
          dispatch(TreatmentActions.LOAD_PUBLIC_SITE_LIST, {
            ...router.currentRoute.params,
            ...router.currentRoute.query,
          });
        }
      })
      .then(() => {
        return dispatch(
          'notifications/' + NotificationsActions.SHOW_SUCCESS,
          {
            body: i18next.t('The public site was deleted successfully.'),
          },
          { root: true },
        );
      })
      .catch((error) => {
        error.errorMessage = ErrorMessages.DATA_LOAD_ERROR;

        if (isEdit) {
          commit(TreatmentMutations.SET_PUBLIC_SITE_STATUS, EditableStatus.Failed);
        } else {
          commit(TreatmentMutations.SET_PUBLIC_SITE_LIST_STATUS, ListStatus.Failed);
        }

        return dispatch(RootActions.ERROR, error, { root: true });
      });
  },
  [TreatmentActions.RESTORE_PUBLIC_SITE]({ commit, dispatch }, { id = 0 }: { id?: number } = {}): Promise<void> {
    const isEdit = router.currentRoute.name === 'edit-public-site';
    if (isEdit) {
      commit(TreatmentMutations.SET_PUBLIC_SITE_STATUS, EditableStatus.Saving);
    } else {
      commit(TreatmentMutations.SET_PUBLIC_SITE_LIST_STATUS, ListStatus.Loading);
    }

    return new Promise<void>((resolve, reject) => {
      if (!id) {
        return reject(new Error('Invalid data!'));
      }

      resolve();
    })
      .then(() => api.public_sites.restore(id))
      .then(() => {
        if (isEdit) {
          commit(TreatmentMutations.UPDATE_INITIAL_PUBLIC_SITE, {
            status: PublicSiteStatus.Active,
          });
          commit(TreatmentMutations.UPDATE_PUBLIC_SITE, {
            status: PublicSiteStatus.Active,
          });
          commit(TreatmentMutations.SET_PUBLIC_SITE_STATUS, EditableStatus.Saved);
        } else {
          dispatch(TreatmentActions.LOAD_PUBLIC_SITE_LIST, {
            ...router.currentRoute.params,
            ...router.currentRoute.query,
          });
        }
      })
      .then(() =>
        dispatch(
          'notifications/' + NotificationsActions.SHOW_SUCCESS,
          {
            body: i18next.t('The public site was restored successfully.'),
          },
          { root: true },
        ),
      )
      .catch((error) => {
        error.errorMessage = ErrorMessages.DATA_LOAD_ERROR;

        if (isEdit) {
          commit(TreatmentMutations.SET_PUBLIC_SITE_STATUS, EditableStatus.Failed);
        } else {
          commit(TreatmentMutations.SET_PUBLIC_SITE_LIST_STATUS, ListStatus.Failed);
        }

        return dispatch(RootActions.ERROR, error, { root: true });
      });
  },
  [TreatmentActions.BULK_RESTORE_PUBLIC_SITE](
    { commit, dispatch },
    { ids = [] }: { ids: number[] } = { ids: [] },
  ): Promise<void> {
    commit(TreatmentMutations.SET_PUBLIC_SITE_LIST_STATUS, ListStatus.Loading);

    return new Promise<void>((resolve, reject) => {
      if (!ids || !ids.length) {
        return reject(new Error('Invalid data!'));
      }

      resolve();
    })
      .then(() => Promise.all(ids.map(async (id) => api.public_sites.restore(id))))
      .then(() => {
        dispatch(TreatmentActions.LOAD_PUBLIC_SITE_LIST, {
          ...router.currentRoute.params,
          ...router.currentRoute.query,
        });

        return dispatch(
          'notifications/' + NotificationsActions.SHOW_SUCCESS,
          {
            body: i18next.t('%(count)% public site was restored successfully', { count: ids.length }),
          },
          { root: true },
        );
      })
      .catch((error) => {
        error.errorMessage = ErrorMessages.DATA_LOAD_ERROR;

        commit(TreatmentMutations.SET_PUBLIC_SITE_LIST_STATUS, ListStatus.Failed);

        return dispatch(RootActions.ERROR, error, { root: true });
      });
  },
  [TreatmentActions.BULK_DELETE_PUBLIC_SITE](
    { commit, dispatch },
    { ids = [] }: { ids: number[] } = { ids: [] },
  ): Promise<void> {
    commit(TreatmentMutations.SET_PUBLIC_SITE_LIST_STATUS, ListStatus.Loading);

    return new Promise<void>((resolve, reject) => {
      if (!ids || !ids.length) {
        return reject(new Error('Invalid data!'));
      }

      resolve();
    })
      .then(() => Promise.all(ids.map((id) => api.public_sites.remove(id))))
      .then(() => {
        dispatch(TreatmentActions.LOAD_PUBLIC_SITE_LIST, {
          ...router.currentRoute.params,
          ...router.currentRoute.query,
        });

        return dispatch(
          'notifications/' + NotificationsActions.SHOW_SUCCESS,
          {
            body: i18next.t('%(count)% public site was deleted successfully', { count: ids.length }),
          },
          { root: true },
        );
      })
      .catch((error) => {
        error.errorMessage = ErrorMessages.DATA_LOAD_ERROR;

        commit(TreatmentMutations.SET_PUBLIC_SITE_LIST_STATUS, ListStatus.Failed);

        return dispatch(RootActions.ERROR, error, { root: true });
      });
  },
};

export default actions;
