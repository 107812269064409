import { ReportsState } from './types';
import Pagination from '@/store/models/pagination';
import { ListStatus, RootState } from '@/store/types';
import mutations from './mutations';
import actions from './actions';
import getters from './getters';
import { Module } from 'vuex';

const getInitialState = (): ReportsState => ({
  /**
   * List
   */
  list: [],
  listPagination: new Pagination(),
  listStatus: ListStatus.Loading,

  /**
   * Request
   */
  isReportsCheckRunning: false,
});

const state = getInitialState();
const module: Module<ReportsState, RootState> = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};

export default module;
