
import Vue from 'vue';
import { mapGetters, mapActions } from 'vuex';
import LoginForm from '@/components/forms/login.vue';
import { UserActions } from '@/store/modules/user/types';

export default Vue.extend({
  name: 'LoginView',
  components: {
    LoginForm,
  },
  computed: {
    ...mapGetters('user/profile', ['isUserWithoutPractice', 'firstName']),
  },
  methods: {
    ...mapActions('user', [UserActions.LOGOUT]),
    backToLogin() {
      this[UserActions.LOGOUT]();
    },
    handleDocumentClick(event: MouseEvent) {
      if (!event) {
        return;
      }

      const target = event.target;

      if (!target || !(target instanceof HTMLAnchorElement) || target.getAttribute('href') !== '#backtologin') {
        return;
      }

      event.preventDefault();

      this.backToLogin();
    },
  },
  data() {
    return {
      userWithoutPracticeCopy: this.$t(
        'If you wish to contact us you may do so by clicking: %(supportEmailLinkUS)% or %(supportEmailLinkUK)%, or go %(backToLoginLink)%.',
        {
          supportEmailLinkUS: '<a href="mailto:ampssupport@cynosure.com">ampssupport@cynosure.com</a>',
          supportEmailLinkUK: '<a href="mailto:ampsuk@cynosure.com">ampsuk@cynosure.com</a>',
          backToLoginLink: '<a href="#backtologin">$t(back to login)</a>',
          interpolation: { escapeValue: false },
        },
      ),
    };
  },
  mounted() {
    document.addEventListener('click', this.handleDocumentClick);
  },
  beforeDestroy() {
    document.removeEventListener('click', this.handleDocumentClick);
  },
});
