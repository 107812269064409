import { createCRUDEndpoints } from '@/api/factory/crud';
import { EventJSON } from '@/store/modules/events/event';

type ListParams =
  | {
      search?: string;
      status?: '' | '0' | '1';
      page?: string | number;
      sort?: string;
      direction?: 'asc' | 'desc';
    }
  | undefined;

export const { list, get, store, remove, restore } = createCRUDEndpoints<EventJSON, ListParams>({
  slug: 'event',
});
