import { EditableStatus, ListStatus } from '@/store/types';
import { Profile } from './profile';

export const TERMS_AGREE_KEY = 'terms_agree';

export enum UserRole {
  User = 'user',
  Sales = 'sales',
  Clinical = 'clinical',
  Admin = 'admin',
  SuperAdmin = 'superadmin',
}

export enum ProfileStatus {
  Initial,
  Loading,
  Loaded,
  Saving,
  Saved,
  Error,
}

export enum ProfileActions {
  LOAD_PROFILE = 'LOAD_PROFILE',
  SAVE_PROFILE = 'SAVE_PROFILE',
  UPDATE_EDITABLE_PROFILE = 'UPDATE_EDITABLE_PROFILE',
  SET_PROFILE = 'SET_CURRENT_PROFILE',
  SAVE_TERMS_AGREE = 'SAVE_TERMS_AGREE',
  SHOW_TERMS_AGREE_MODAL = 'SHOW_TERMS_AGREE_MODAL',
  SET_LANGUAGE = 'SET_LANGUAGE',
}

export enum ProfileMutations {
  SET_CURRENT_PROFILE = 'SET_CURRENT_PROFILE',
  SET_CURRENT_PROFILE_STATUS = 'SET_CURRENT_PROFILE_STATUS',
  SET_EDITABLE_PROFILE = 'SET_EDITABLE_PROFILE',
  UPDATE_EDITABLE_PROFILE = 'UPDATE_EDITABLE_PROFILE',
  SET_EDITABLE_PROFILE_STATUS = 'SET_EDITABLE_PROFILE_STATUS',
  SET_TERMS_AGREE = 'SET_TERMS_AGREE',
  SHOW_TERMS_AGREE_MODAL = 'SHOW_TERMS_AGREE_MODAL',
}

export interface ProfileState {
  status: ListStatus;
  currentProfile: Profile;
  editableProfile?: Profile;
  initialEditableProfile?: Profile;
  editableStatus: EditableStatus;
  local_terms_agree: 0 | 1;
  show_terms_agree_modal: 0 | 1;
}
