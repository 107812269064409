import actions from './actions';
import mutations from './mutations';
import getters from './getters';
import { Module } from 'vuex';
import { RootState, ListStatus, EditableStatus } from '@/store/types';
import { PracticesState } from '@/store/modules/practices/types';
import Pagination from '@/store/models/pagination';

export const getInitialState = (): PracticesState => ({
  /**
   * List
   */
  list: [],
  listPagination: new Pagination(),
  listStatus: ListStatus.Loading,

  /**
   * View & Edit
   */
  editableStatus: EditableStatus.Loading,
  currentPractice: undefined,
  editablePractice: undefined,

  /**
   * User practice
   */
  userPracticeStatus: ListStatus.Loading,
  userPractice: undefined,
});

export const state: PracticesState = getInitialState();

const profile: Module<PracticesState, RootState> = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

export default profile;
