import { Module } from 'vuex';

import { ListStatus, RootState, EditableStatus } from '@/store/types';
import Pagination from '@/store/models/pagination';

import { ConsultationRequestsState } from './types';
import actions from './actions';
import mutations from './mutations';
import getters from './getters';

const getInitialState = (): ConsultationRequestsState => ({
  /**
   * List
   */
  listStatus: ListStatus.Loading,
  listPagination: new Pagination(),
  list: [],

  lessQualifiedListStatus: ListStatus.Loading,
  lessQualifiedListPagination: new Pagination(),
  lessQualifiedList: [],

  /**
   * Edit
   */
  editableStatus: EditableStatus.Loading,

  /**
   * User stats
   */
  userStatsStatus: ListStatus.Loading,
});

const state = getInitialState();

const module: Module<ConsultationRequestsState, RootState> = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};

export default module;
