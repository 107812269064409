import Vue, { PluginObject } from 'vue';

export type LoadingListener = (data: { link: string; target?: string }) => Promise<void>;

export interface TrackOutboundVueObject {
  addLoadingListener: (cb: LoadingListener) => void;
  removeLoadingListener: (cb: LoadingListener) => void;
}

class TrackOutboundPlugin implements PluginObject<{}> {
  public installed: boolean = false;

  public install(vue: typeof Vue) {
    if (this.installed) {
      return;
    }

    const listeners: LoadingListener[] = [];

    vue.trackOutbound = {
      addLoadingListener(cb) {
        listeners.push(cb);
      },
      removeLoadingListener(cb) {
        listeners.splice(listeners.indexOf(cb), 1);
      },
    };

    vue.directive('track-outbound', {
      bind: (el) => {
        // Check is anchor element
        if (el.tagName !== 'A') {
          throw new TypeError('Track outbound directive only works with anchor elements.');
        }

        // Check is href attribute defined
        if (!el.hasAttribute('href') || !el.getAttribute('href')) {
          throw new TypeError("Can not track outbound event if anchor doesn't have defined href attribute.");
        }

        // Check is external link
        if (el.getAttribute('href')!.indexOf('http') === -1) {
          throw new TypeError('Track outbound link must start with "http".');
        }

        // Attach click event listener
        el.addEventListener('click', (event) => {
          event.preventDefault();

          let target: string | undefined;
          const link = el.getAttribute('href')!;

          if (el.hasAttribute('target') && el.getAttribute('target')) {
            target = el.getAttribute('target')!;
          }

          document.body.classList.add('is-inline-loading');

          Promise.all(listeners.map((cb) => cb({ link, target }))).then(() => {
            document.body.classList.remove('is-inline-loading');

            // Open new window/tab
            const win = window.open(link, target);

            // If it's sucessfully opened then move focus on it
            if (win) {
              win.focus();
            }
          });
        });
      },
    });
  }
}

const trackOutboundPlugin = new TrackOutboundPlugin();

export default trackOutboundPlugin;
