/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'add': {
    width: 11,
    height: 11,
    viewBox: '0 0 11 11',
    data: '<g data-name="Layer 2"><g data-name="Isolation Mode"><path pid="0" d="M5.5 11A5.5 5.5 0 1111 5.5 5.51 5.51 0 015.5 11zm0-10A4.5 4.5 0 1010 5.5 4.5 4.5 0 005.5 1z"/><path pid="1" d="M5.5 8.5A.5.5 0 015 8V3a.5.5 0 011 0v5a.5.5 0 01-.5.5z"/><path pid="2" d="M8 6H3a.5.5 0 010-1h5a.5.5 0 010 1z"/></g></g>'
  }
})
