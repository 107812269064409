/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'star': {
    width: 12,
    height: 11.98,
    viewBox: '0 0 12 11.98',
    data: '<g data-name="Layer 2"><path pid="0" d="M10 12a.5.5 0 01-.28-.09L6 9.34l-3.7 2.55a.5.5 0 01-.76-.57l1.39-4.18L.18 4.86A.5.5 0 01.5 4h3.21L5.56.25a.52.52 0 01.89 0L8.31 4h3.19a.5.5 0 01.32.88L9.08 7.14l1.39 4.18A.5.5 0 0110 12zM6 8.24a.5.5 0 01.28.09l2.76 1.9L8 7.14a.5.5 0 01.15-.54L10.12 5H8a.5.5 0 01-.45-.28L6 1.6 4.46 4.7A.5.5 0 014 5H1.89l1.95 1.59a.5.5 0 01.16.55l-1 3.08 2.76-1.9A.5.5 0 016 8.24z" data-name="Isolation Mode"/></g>'
  }
})
