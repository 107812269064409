/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'email': {
    width: 16,
    height: 12,
    viewBox: '0 0 16 12',
    data: '<g data-name="Layer 2"><g data-name="Outline Icons"><path pid="0" d="M14 12H2a2 2 0 01-2-2V2a2 2 0 012-2h12a2 2 0 012 2v8a2 2 0 01-2 2zM2 1a1 1 0 00-1 1v8a1 1 0 001 1h12a1 1 0 001-1V2a1 1 0 00-1-1z"/><path pid="1" d="M8 7.5a.5.5 0 01-.32-.11l-5.5-4.5a.5.5 0 01.63-.77L8 6.35l5.18-4.24a.5.5 0 01.63.77l-5.5 4.5A.5.5 0 018 7.5z"/><path pid="2" d="M13.5 9a.5.5 0 01-.26-.08l-4-2.5a.5.5 0 01.53-.85l4 2.5a.5.5 0 01-.27.93zM2.5 9a.5.5 0 01-.27-.92l4-2.5a.5.5 0 01.53.85l-4 2.5A.5.5 0 012.5 9z"/></g></g>'
  }
})
