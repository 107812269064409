let apiURL = '';
let drupalURL = '';
let blogURL = '';
let appURL = '';

switch (process.env.VUE_APP_API) {
  case 'development':
    apiURL = `http://${process.env.VUE_APP_LOCAL_API_HOST ?? 'localhost'}:81/api`;
    drupalURL = 'https://test-amps20.pantheonsite.io/';
    blogURL = `https://${process.env.VUE_APP_LOCAL_API_HOST ?? 'localhost'}/api/v1`;
    appURL = 'http://localhost:8081';
    break;
  case 'staging':
    apiURL = 'https://stage-api.amps.cynosure.com/api';
    drupalURL = 'https://test-amps20.pantheonsite.io/';
    appURL = 'https://stage.customer.cynosure.com';
    break;
  case 'production':
    apiURL = 'https://api.amps.cynosure.com/api';
    drupalURL = 'https://ampsadmin.cynosure.com/';
    blogURL = 'https://ampsblog.wpengine.com/api/v1';
    appURL = 'https://customer.cynosure.com';
    break;
}

switch (process.env.VUE_APP_BLOG_API) {
  case 'development':
    blogURL = 'https://localhost/api/v1';
    break;
  case 'staging':
    blogURL = 'https://ampsblogstg.wpengine.com/api/v1';
    break;
  case 'production':
    blogURL = 'https://ampsblog.wpengine.com/api/v1';
    break;
}

export default {
  APP_URL: appURL,
  API_HOSTNAME: apiURL,
  BLOG_API: blogURL,
  DRUPAL_URL: drupalURL,
  BUILD: process.env.VUE_APP_BUILD ? process.env.VUE_APP_BUILD : '0',
  WEBHOOK_TOKEN: process.env.VUE_WEBHOOK_TOKEN ? process.env.WEBHOOK_TOKEN : '',
  FEATURES: {
    translatableFAQs: false,
  },
};
